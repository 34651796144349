 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {EjecucionIds} from './Ejecucion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const EjecucionCol = Object.freeze({
    tarea : new LookupColumn(EjecucionIds.tarea, Tables.Tarea, 'ref'),
    sistema : new LookupColumn(EjecucionIds.sistema, Tables.Sistema, 'sistema'),
    procedencia : new LookupColumn(EjecucionIds.procedencia, Tables.Procedencia, 'procedencia'),
    idEjecucion : new SimpleColumn(EjecucionIds.idEjecucion, 11, 11, Align.RIGHT),
    m2Calculados : new SimpleColumn(EjecucionIds.m2Calculados, 11, 11, Align.RIGHT),
    m2Efectivos : new SimpleColumn(EjecucionIds.m2Efectivos, 11, 11, Align.RIGHT),
    m2Totales : new SimpleColumn(EjecucionIds.m2Totales, 11, 11, Align.RIGHT),
    competencia : new SimpleColumn(EjecucionIds.competencia, 11, 11, Align.RIGHT),
    idAutor : new SimpleColumn(EjecucionIds.idAutor, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(EjecucionIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(EjecucionIds.fechaCreacion, 11, 11, Align.LEFT),
    odooCambiado : new SimpleColumn(EjecucionIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Ejecucion] = EjecucionCol
