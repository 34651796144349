import {boundMethod} from 'autobind-decorator'

export class AproObjectInfo {
    
    _id
    _fields
    
    @boundMethod
    _getId(){
        return this._id
    }

    @boundMethod
    _getFields(){
        return this._fields
    }

    get id(){
        return this._getId()
    }
    
    get fields(){
        return this._getFields()
    }
}


export class AproInfoForm extends AproObjectInfo{

    /** @type {FormPiece} */
    _form
    
    /**
     * @param {FormPiece} form
     */
    constructor(form) {
        super()
        this._form = form
    }
    
    _getId() {
        return this._form.id
    }
    
    _getFields() {
        return this._form.fields
    }
}