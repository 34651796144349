 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SageDomicilioIds = Object.freeze({
    codigoEmpresa: Symbol('codigoEmpresa'),
    codigoCliente: Symbol('codigoCliente'),
    tipoDomicilio: Symbol('tipoDomicilio'),
    numeroDomicilio: Symbol('numeroDomicilio'),
    domicilio: Symbol('domicilio'),
    municipio: Symbol('municipio'),
    codigoPostal: Symbol('codigoPostal'),
    provincia: Symbol('provincia'),
    nacion: Symbol('nacion'),
})

export const SageDomicilio = Object.freeze({
    codigoEmpresa: 'codigoEmpresa',
    codigoCliente: 'codigoCliente',
    tipoDomicilio: 'tipoDomicilio',
    numeroDomicilio: 'numeroDomicilio',
    domicilio: 'domicilio',
    municipio: 'municipio',
    codigoPostal: 'codigoPostal',
    provincia: 'provincia',
    nacion: 'nacion',
})
