 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UserRoleIds} from './UserRole'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const UserRoleEdt = Object.freeze({
    idRole : new SimpleEditor(UserRoleIds.idRole, 11, 11),
    role : new SimpleEditor(UserRoleIds.role, 12, 10),
})

tableEditors[Tables.UserRole] = UserRoleEdt
