 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ProvinciaIds} from './Provincia'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const ProvinciaCol = Object.freeze({
    idProvincia : new SimpleColumn(ProvinciaIds.idProvincia, 11, 11, Align.RIGHT),
    cp : new SimpleColumn(ProvinciaIds.cp, 2, 2, Align.LEFT),
    provincia : new SimpleColumn(ProvinciaIds.provincia, 22, 10, Align.LEFT),
})

tableColumns[Tables.Provincia] = ProvinciaCol
