 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const TareaIds = Object.freeze({
    tareas: Symbol('tareas'),
    liquidaciones: Symbol('liquidaciones'),
    avisos: Symbol('avisos'),
    aprobaciones: Symbol('aprobaciones'),
    aproestas: Symbol('aproestas'),
    aproposiciones: Symbol('aproposiciones'),
    ejecuciones: Symbol('ejecuciones'),
    documentos: Symbol('documentos'),
    solicitudList: Symbol('solicitudList'),
    sistemaList: Symbol('sistemaList'),
    estado: Symbol('estado'),
    autor: Symbol('autor'),
    responsable: Symbol('responsable'),
    obra: Symbol('obra'),
    procedencia: Symbol('procedencia'),
    tipo: Symbol('tipo'),
    padre: Symbol('padre'),
    aprobacion: Symbol('aprobacion'),
    aproesta: Symbol('aproesta'),
    aproposicion: Symbol('aproposicion'),
    idTarea: Symbol('idTarea'),
    idEstado: Symbol('idEstado'),
    ordenEstudio: Symbol('ordenEstudio'),
    ordenValoracion: Symbol('ordenValoracion'),
    descripcion: Symbol('descripcion'),
    idAutor: Symbol('idAutor'),
    idResponsable: Symbol('idResponsable'),
    idObra: Symbol('idObra'),
    idProcedencia: Symbol('idProcedencia'),
    fechaVencimiento: Symbol('fechaVencimiento'),
    fechaCreacion: Symbol('fechaCreacion'),
    ref: Symbol('ref'),
    refCliente: Symbol('refCliente'),
    idTipo: Symbol('idTipo'),
    m2Totales: Symbol('m2Totales'),
    urgente: Symbol('urgente'),
    ratio: Symbol('ratio'),
    fechaFinalizada: Symbol('fechaFinalizada'),
    desplazada: Symbol('desplazada'),
    idPadre: Symbol('idPadre'),
    idAprobacion: Symbol('idAprobacion'),
    m2Completos: Symbol('m2Completos'),
    m2Repetidos: Symbol('m2Repetidos'),
    m2Simetricos: Symbol('m2Simetricos'),
    m2Fijos: Symbol('m2Fijos'),
    detalle: Symbol('detalle'),
    justificacion: Symbol('justificacion'),
    m2Efectivos: Symbol('m2Efectivos'),
    precioSinRegulacion: Symbol('precioSinRegulacion'),
    idAproesta: Symbol('idAproesta'),
    idAproposicion: Symbol('idAproposicion'),
    refE: Symbol('refE'),
    refC: Symbol('refC'),
    refV: Symbol('refV'),
    refT: Symbol('refT'),
    odooCambiado: Symbol('odooCambiado'),
    asumible: Symbol('asumible'),
    aclReadOnly: Symbol('aclReadOnly'),
})

export const Tarea = Object.freeze({
    tareas: 'tareas',
    liquidaciones: 'liquidaciones',
    avisos: 'avisos',
    aprobaciones: 'aprobaciones',
    aproestas: 'aproestas',
    aproposiciones: 'aproposiciones',
    ejecuciones: 'ejecuciones',
    documentos: 'documentos',
    solicitudList: 'solicitudList',
    sistemaList: 'sistemaList',
    estado: 'estado',
    autor: 'autor',
    responsable: 'responsable',
    obra: 'obra',
    procedencia: 'procedencia',
    tipo: 'tipo',
    padre: 'padre',
    aprobacion: 'aprobacion',
    aproesta: 'aproesta',
    aproposicion: 'aproposicion',
    idTarea: 'idTarea',
    idEstado: 'idEstado',
    ordenEstudio: 'ordenEstudio',
    ordenValoracion: 'ordenValoracion',
    descripcion: 'descripcion',
    idAutor: 'idAutor',
    idResponsable: 'idResponsable',
    idObra: 'idObra',
    idProcedencia: 'idProcedencia',
    fechaVencimiento: 'fechaVencimiento',
    fechaCreacion: 'fechaCreacion',
    ref: 'ref',
    refCliente: 'refCliente',
    idTipo: 'idTipo',
    m2Totales: 'm2Totales',
    urgente: 'urgente',
    ratio: 'ratio',
    fechaFinalizada: 'fechaFinalizada',
    desplazada: 'desplazada',
    idPadre: 'idPadre',
    idAprobacion: 'idAprobacion',
    m2Completos: 'm2Completos',
    m2Repetidos: 'm2Repetidos',
    m2Simetricos: 'm2Simetricos',
    m2Fijos: 'm2Fijos',
    detalle: 'detalle',
    justificacion: 'justificacion',
    m2Efectivos: 'm2Efectivos',
    precioSinRegulacion: 'precioSinRegulacion',
    idAproesta: 'idAproesta',
    idAproposicion: 'idAproposicion',
    refE: 'refE',
    refC: 'refC',
    refV: 'refV',
    refT: 'refT',
    odooCambiado: 'odooCambiado',
    asumible: 'asumible',
    aclReadOnly: 'aclReadOnly',
})
