import React from 'react'
import {ModalContent} from 'sopix/modal/ModalContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'
import {LoginScreen} from 'sop/login/loginState'
import Typography from '@material-ui/core/Typography'
import {useKeyPressed} from 'sopix/hooks/use-key-pressed'
import QRCode from "react-qr-code";
import {userAuth} from "sopix/session/userAuth";

export const AskOtpSuccessForm = observer(
/**
 * 
 * @param {LoginState} login
 * @param children
 * @returns {JSX.Element}
 */
({login, children}) => {
        
    useKeyPressed(key => {
        if (key === 'Enter' || key === 'Escape'){
            login.setLoginScreen()
        }
    })
    
    return (
        <>
            <ModalContent>
                <Typography>
                    Instala Google Authenticator en tu móvil y añade nueva cuenta (botón "+") con este QR:
                </Typography>
                <br />
                <Typography align="center" >
                    <QRCode value={`otpauth://totp/${userAuth.username}?secret=${login.totpSecret}&issuer=Polytherm SOP`} />
                </Typography>
                <br />
                <Typography>
                    Si no te funciona el QR, también puedes teclear este código: <strong>{login.totpSecret}</strong>.
                </Typography>

            </ModalContent>
            <DialogActions>
                <Button
                    onClick={() => login.setLoginScreen()}
                    color="primary"
                    defaultChecked={true}
                >
                    Ya he completado las instrucciones
                </Button>
            </DialogActions>
        </>
    )
})
