import React, {useCallback} from 'react'
import {getColumnWidthStyle} from 'sopix/boundless/boundless-utils'
import {OrderCycle, OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {BlCell} from 'sopix/boundless-table/BlCell'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    headerText: {
        position: 'relative',
        maxWidth: '100%',
    },
    directionIcon: {
        position: 'absolute',
        color: theme.palette.secondary.main,
        bottom: -15,
        left: '50%',
        transform: 'translateX(-50%)',
    },
    directionIconDense: {
        bottom: -12,
    },
    dense: {
    },
})) 



/**
 *
 * @param {TableColumn} column
 * @param {OrderEntry[]} order
 * @param setOrder
 * @returns {JSX.Element}
 * @constructor
 */
export function BlHeaderCell({className, classes, column, order, onOrderChange, dense, 
                                 verticalAlign, global, noMargin, children}) {

    const cls = useStyles()
    const direction = order && order.length > 0 && order[0].fieldId === column.id ? order[0].direction : undefined

    const handleSetOrder = useCallback(() => {
        const nextOrder = OrderCycle.get(direction)
        onOrderChange && onOrderChange(nextOrder === undefined ? [] : [new OrderEntry(column.id, nextOrder)])
    }, [column.id, direction, onOrderChange])

    const active = direction !== undefined
    const Icon = direction === OrderDirection.ASC ? ArrowDropUpIcon : ArrowDropDownIcon
    
    return (
        <BlCell
            className={clsx(
                dense && cls.dense,
                className
            )}
            classes={classes}
            align={column.align}
            head={true}
            style={getColumnWidthStyle(column)}
            size={dense ? 'small' : undefined}
            verticalAlign={verticalAlign}
            noMargin={noMargin}
        >
            <Typography
                className={cls.headerText}
                variant="subtitle2"
                onClick={handleSetOrder}
            >
                {children}
                {!active ? null : <Icon className={clsx(
                    cls.directionIcon,
                    dense && cls.directionIconDense,
                )} />}
            </Typography>
        </BlCell>
    )
}
