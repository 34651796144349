import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {WorldObject} from 'sopix/piece/worldObject'
import {boundMethod} from 'autobind-decorator'
import {ObserverHub} from 'sopix/utils/observers'

export class RowDeleter extends WorldObject{
    
    onDelete = new ObserverHub()
    
    constructor(world, table, deleteMutation) {
        super(world)
        this._deleteMutation = !deleteMutation || !table ? undefined :
            new SimpleMutation(table.graphqlUrl, deleteMutation, ['success'])
    }
    
    @boundMethod
    async delete(...ids){
        await this._deleteMutation.query({ids: ids})
        this.onDelete.notify(...ids)
    }
    __delete = this._asyncAction(this.delete)
}