import React from 'react'
import {TabBar} from 'sopix/formComps/TabFrame'
import {observer} from 'mobx-react'
import {Tab} from '@material-ui/core'
import {chartPieces} from 'sop/componentes/stats/chart-consts'


export const ChartTab = observer(
    /**
     * @param {StatsPagePiece} page
     */
    ({page}) => {

        const tabs = []
        for (let [id, info] of chartPieces.entries()) {
            tabs.push(<Tab key={id} label={info.title} />)
        }

        return (
            <TabBar
                value={page.id}
                onChange={page.__setId}
                tabs={tabs}
            />
        )
    })
