import React, {forwardRef} from 'react'
import {getLoggers} from 'sopix/log'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {makeStyles} from '@material-ui/core/styles'
import {BlRowCell} from 'sopix/boundless-table/BlRowCell'
import {BlRow} from 'sopix/boundless-table/BlRow'
import clsx from 'clsx'
import {icons} from 'sopix/icon/icons'
import {Tarea} from 'sop/db/Tarea'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {cimStyles} from 'sopix/theme/cimStyles'
import {TipoTarea} from 'sop/db/TipoTarea'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {TrabajoClase, TrabajoView} from 'sop/db-config/row-consts'
import {getTrabajoViewName} from 'sop/db-config/sop-db-utils'
import {TareaEstado} from 'sop/db/TareaEstado'
import {getTableProps, tableStyles} from 'sopix/piece-components/render-table'
import {
    BUTTONS_TAREA_LIST_LEFT,
    BUTTONS_TAREA_LIST_RIGHT,
    TAREA_LIST_DRAG,
} from 'sop/componentes/obra-trabajo/obraTrabajoListPiece'
import {TRABAJO_VIEW} from 'sop/componentes/trabajo/TrabajoFormPiece'
import {TrabajoRef} from 'sop/componentes/trabajo/trabajoPainter'
import {observer} from 'mobx-react'
import {usePiece} from 'sopix/piece/use-piece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Bar} from 'sopix/formComps/Bar'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {TrabajoForm} from 'sop/componentes/trabajo/TrabajoForm'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {Frame} from 'sopix/formComps/Frame'
import {IconTab} from 'sopix/formComps/IconTab'
import {Push} from 'sopix/formComps/Push'
import {Tag} from 'sopix/formComps/Tag'
import {Solicitud} from 'sop/db/Solicitud'
import {Cuenta} from 'sop/db/Cuenta'
import {Separator} from 'sopix/formComps/Separator'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {ALERT_CANCEL, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'


const {debug}=getLoggers('ObraTrabajoList')



const useStyles = makeStyles(theme => ({
    table: tableStyles.table,
    row: {
        '&:hover $cellButtons': {
            visibility: 'inherit',
        }    
    },
    
    treeDescendant: {
        background: sopColors.yellowLightBackground,
    },
    treeAncestor: {
        background: sopColors.blueLightBackground,
    },
    treeSelected: {
    },
    
    treeChild: {
        
    },
    treeParent: {
    },
    
    treeRoot: {
    },
    treeLeaf: {
        
    },
    
    buttonsCell: {
        padding: 0,    
    },
    cellButtons: {
        display: 'flex',
        visibility: 'hidden',
        flexWrap: 'wrap',
        alignSelf: 'center',
        margin: 5,
        '& > *': {
            margin: '-5px !important', //Issue: React CSS diferente en producción
        }
    },
    dragArea: {
        color: theme.palette.grey['200'], 
    },
    buttonsBackground: {
        background: theme.palette.background.paper,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 60,
    },
    tareaRealizada: cimStyles.dimmed,
}))


/**
 * @param {ObraTrabajoListPiece} list
 */
function getRenderAction(list, {idPadre, idResponsable, responsable} = {}) {
    function renderAction(view) {
        
        if (view === TrabajoView.COTIZACION && !userAuth.isAllowed(Permiso.crearCotizaciones)) {
            return null
        }

        if (view === TrabajoView.TAREA && !userAuth.isAllowed(Permiso.crearTareas)) {
            return null
        }

        const views = {
            [TrabajoView.COTIZACION]: {Icon: sopIcons.Cotizacion, title: "Nueva cotizacion"},
            [TrabajoView.TAREA]: {Icon: sopIcons.Tarea, title: "Nueva tarea"},
        }
        const newFields = {
            [Tarea.idPadre]: idPadre,
            [TRABAJO_VIEW]: view,
            [Tarea.idResponsable]: undefined,
            [Tarea.responsable]: undefined,
        }
        
        if (view === TrabajoView.COTIZACION) {
            if (idResponsable) {
                newFields[Tarea.idResponsable] = idResponsable
                newFields[Tarea.responsable] = responsable
            }
        }
        
        return (
            <HintIconButton key={`${idPadre}.${getTrabajoViewName(view)}`} badge='+' 
                badgeColor={sopColors.badgeAdd} {...views[view]}
                onClick={() => list.poper.__createWith(newFields)}
            />
        )
    }
    return renderAction    
}

/**
 * 
 * @param {Map<Any,Node>} nodes
 * @param idTarea
 * @param idSelected
 * @returns {[]}
 */
function getKinStyleNames(nodes, idTarea, idSelected){
    const result = [[]]

    if (!idSelected) {
        return result 
    }
    const selectedNode = nodes.get(idSelected)
    if (!selectedNode) {
        return result
    }
    
    const currentNode = nodes.get(idTarea)
    const descendant = selectedNode.getDescendantLevel(currentNode)
    const selectedAncestorCount = selectedNode.getAncestorCount()

    if (descendant > 0) {
        result.push('treeDescendant')
    } else if (descendant < 0) {
        result.push('treeAncestor')
    } else if (descendant === 0) {
        result.push('treeSelected')
    } else {
        return result
    }

    if (descendant === 1) {
        result.push('treeChild')
    } else if (descendant === -1) {
        result.push('treeParent')
    }

    if (descendant !== 0) {
        if (!currentNode.parent) {
            result.push('treeRoot')
        }
        if (!currentNode.children.size){
            result.push('treeLeaf')
        }
    }
    
    return [result, selectedAncestorCount, descendant]
}


const Row = forwardRef((props, ref) => {
    const cls = useStyles()

    return <BlRow ref={ref} className={cls.row} {...props} />
})


const Cell = observer(

    /**
     * @param {ObraTrabajoListPiece} list
     */
    
    ({children, rowId, data, global: {list}, ...otherProps}) => {
    const cls = useStyles()

    const idTarea = data[Tarea.idTarea]
    const fld = getFieldExtractor(data)

    const renderAction = getRenderAction(list, {
        idPadre: fld(Tarea.idTarea), idResponsable: fld(Tarea.idResponsable), responsable: fld(Tarea.responsable)})

    const [kinStyles, selectedAncestorCount, descendantsToSelected] 
        = getKinStyleNames(list.trabajoTree.nodes, idTarea, list.selection.id)
    const badgeNumber =  descendantsToSelected > 0 || selectedAncestorCount === undefined ? undefined : selectedAncestorCount + 1 + descendantsToSelected
    

    const clase = fld(Tarea.tipo, TipoTarea.clase)
    const tipo = fld(Tarea.tipo, TipoTarea.tipo)
    
    const grayedCell = ![BUTTONS_TAREA_LIST_LEFT, BUTTONS_TAREA_LIST_RIGHT, TAREA_LIST_DRAG].includes(rowId)
    const grayed = grayedCell && fld(Tarea.estado, TareaEstado.finalizado)
    
    const puedeEliminar = clase === TrabajoClase.TAREA
        ? userAuth.isAllowed(Permiso.eliminarTareas)
        : userAuth.isAllowed(Permiso.eliminarCotizaciones)
        
    return (
        <BlRowCell
            className={clsx(
                rowId === BUTTONS_TAREA_LIST_LEFT && cls.buttonsCell,
                rowId === TAREA_LIST_DRAG && cls.dragArea,
                cls.cell,
                grayed && cls.tareaRealizada,
            )}
            classes={{
                container: kinStyles.map(name => cls[name]),
            }}
            rowId={rowId} data={data} {...otherProps}
        >
            {(()=>{
                switch (rowId){
                    case Tarea.ref:
                        return (
                            <TrabajoRef clase={clase} tipo={tipo} descendantsToSelected={descendantsToSelected}
                                badgeNumber={badgeNumber} onClick={() => list.poper.__edit(idTarea)}
                            >{data[Tarea.ref]}</TrabajoRef>
                        )
                    case BUTTONS_TAREA_LIST_LEFT: return (
                        <div className={cls.cellButtons}>
                            {[
                                renderAction(TrabajoView.COTIZACION),
                                renderAction(TrabajoView.TAREA),
                            ]}
                        </div>
                    )
                    case BUTTONS_TAREA_LIST_RIGHT:
                        return (
                            <div className={cls.cellButtons}>
                                {!puedeEliminar ? null :
                                    <HintIconButton Icon={icons.Delete} onClick={async () => {
                                        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
                                            `¿Eliminar ${fld(Tarea.tipo, TipoTarea.tipo).toLowerCase()} ${fld(Tarea.ref)}?`,
                                            `Descripción: "${fld(Tarea.descripcion) || ''}"`, [
                                                [ALERT_ELIMINAR, 'Eliminar'],
                                                [ALERT_CANCEL, 'Cancelar'],
                                            ]))
                                        ) {
                                            await list.rowDeleter.__delete(idTarea)
                                        }
                                    }}/>
                                }
                            </div>
                        )
                    case TAREA_LIST_DRAG: return (
                        <div className={cls.dragArea} onMouseDown={undefined} >
                            <HintIcon Icon={icons.Drag} />
                        </div>
                    )
                    default:
                        return children
            }})()}
        </BlRowCell>
    )
})



export const ObraTrabajoList = observer(
    /**
     * @param {ObraTrabajoListPiece} list
     */
    ({list}) => {
    debug(()=>`render`)

        
    usePiece(list)

    const cls = useStyles()
        
    const asa = list._asyncAction 
        
    const renderAction = getRenderAction(list)
        
    const filtrator = list.filtrator
        
    const filterSolicitud = filtrator.filterSolicitud
    const cuenta = !filterSolicitud ? undefined : getFieldExtractor(filterSolicitud)(Solicitud.cuenta,Cuenta.cuenta)
        
    const filterFamilia = !!list.filtrator.filterFamilia
        
    return <PieceFrame row piece={list} renderPiece={()=> {
        return (
            <>
                <Bar direction="column">
                    <IconTab key="trabajos" icon={sopIcons.Trabajo} toolTip="Trabajos" color={sopColors.mainIcon}
                             badge={list.dbSource.data.length || ''} badgeColor={sopColors.badgeCount} />
                    <Push />
                    {!filterSolicitud || !filtrator.enabled ? null :
                        <Tag
                            variant="outlined"
                            className={cls.chip}
                            orientation="vertical"
                            label={cuenta}
                            tooltip={`filtro: "${cuenta}"`}
                        />
                    }
                    <HintIconButton
                        Icon={icons.Filter}
                        title={`Filtro por solicitante: ${filtrator.enabled ? "Activado" : "Desactivado"}`}
                        color={filtrator.enabled ? sopColors.enabled : sopColors.disabled}
                        onClick={() => filtrator.__setEnabled(!filtrator.enabled)}
                    />
                    <HintIconButton
                        Icon={SelectAllIcon}
                        color={filterFamilia ? sopColors.enabled : sopColors.disabled}
                        title={`Mostrar solo selección y relacionados: ${filterFamilia ? 'Activado' : 'Desactivado'}`}
                        onClick={asa(list.toggleFilterFamilia)}
                    />
                    <Separator horizontal />
                    <div className={cls.buttonsBackground}>
                        {[
                            renderAction(TrabajoView.COTIZACION),
                            renderAction(TrabajoView.TAREA),
                        ]}
                    </div>
                </Bar>
                <Frame>
                    <BlTable fitContainer dense
                        global={{list}}
                        Row={Row}
                        RowCell={Cell}
                        verticalAlign="center"
                        {...getTableProps(list)}
                        data={filtrator.data}
                    />
                </Frame>
                <PoperFrame
                    poper={list.poper}
                    maxWidth="md"
                >
                    <TrabajoForm form={list.form} sinObra/>
                </PoperFrame>
            </>
        )
    }} />
})
