 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const NotaIds = Object.freeze({
    autor: Symbol('autor'),
    obra: Symbol('obra'),
    solicitud: Symbol('solicitud'),
    idNota: Symbol('idNota'),
    nota: Symbol('nota'),
    idAutor: Symbol('idAutor'),
    idObra: Symbol('idObra'),
    idSolicitud: Symbol('idSolicitud'),
    contexto: Symbol('contexto'),
    fechaCreacion: Symbol('fechaCreacion'),
    destacada: Symbol('destacada'),
    privada: Symbol('privada'),
})

export const Nota = Object.freeze({
    autor: 'autor',
    obra: 'obra',
    solicitud: 'solicitud',
    idNota: 'idNota',
    nota: 'nota',
    idAutor: 'idAutor',
    idObra: 'idObra',
    idSolicitud: 'idSolicitud',
    contexto: 'contexto',
    fechaCreacion: 'fechaCreacion',
    destacada: 'destacada',
    privada: 'privada',
})
