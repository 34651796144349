import {GraphqlEndPoint} from 'sopix/db/graphqlEndPoint'
import {DbTable} from 'sopix/db/dbTable'
import {CachedApiEndPoint} from 'sopix/db-access/cachedApiEndPoint'
import {ApiEndPoint} from 'sopix/db/apiEndPoint'

export class TableGraphql extends GraphqlEndPoint{

    constructor(table, {graphqlId, cached, excludedErrors} = {}) {
        if (! (table instanceof DbTable)) {
            throw new Error(`Graphql tabla incorrecta: ${JSON.stringify(table)}`)
        }

        const url = table.graphqlUrl
        const endPointOptions = {error_label: table.graphqlId}
        const endpoint = cached ? new CachedApiEndPoint(url, endPointOptions) : new ApiEndPoint(url, endPointOptions)
        super(graphqlId ? graphqlId : table.graphqlId, endpoint,
            {excludedErrors: excludedErrors})
    }

}