 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {StatusIds} from './Status'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const StatusCol = Object.freeze({
    idStatus : new SimpleColumn(StatusIds.idStatus, 11, 11, Align.RIGHT),
    status : new SimpleColumn(StatusIds.status, 20, 10, Align.LEFT),
})

tableColumns[Tables.Status] = StatusCol
