import React, {useState} from 'react'
import {useLocationLocker} from 'sopix/router/useRouter'
import {pushUrl} from 'sopix/router/router'
import {useNotSavedAlert} from 'sopix/alert/NonSavedChanges'
import {getLog} from 'sopix/log'
import {boundMethod} from 'autobind-decorator'

const log = getLog('LockedLocation')

class LocationLockerState{
    isDirty
    wantedURL

    _locked = true
    
    get locked(){
        return this.isDirty && this._locked 
    }
    
    @boundMethod
    discardData(){
        this._locked = false
        pushUrl(this.wantedURL, {force: true})
        //emulateBack(this.wantedURL, {force: true})
    }
}

export function LocationLocker({children, isDirty}) {

    const [notSavedAlert, withNotSavedAlert] = useNotSavedAlert()
    
    const stateProps = useState(() => new LocationLockerState())
    /** @type {LocationLockerState} */
    const state = stateProps[0]
    
    state.isDirty = isDirty
    
    useLocationLocker((previousLocation, newLocation) => {
        if (state.isDirty) {
            state.wantedURL = newLocation.href
            withNotSavedAlert(state.discardData)
            return false
        }
    })
    
    return (
        <>
            {children}
            {notSavedAlert}
        </>
    )
}