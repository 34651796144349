 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AlmacenIds} from './Almacen'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AlmacenEdt = Object.freeze({
    idAlmacen : new SimpleEditor(AlmacenIds.idAlmacen, 11, 11),
    almacen : new SimpleEditor(AlmacenIds.almacen, 15, 10),
})

tableEditors[Tables.Almacen] = AlmacenEdt
