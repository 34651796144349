import {action, observable} from 'mobx'
import {boundMethod} from 'autobind-decorator'
import {DataSource} from 'sopix/data-source/dataSource'
import {getFieldExtractor} from 'sopix/data/data-utils'

export class Filtrator extends DataSource{

    @observable.ref
    _currentFilter = {}

    @observable.ref
    _currentOrder = []

    
    constructor(world, {defaultFilter, defaultOrder} = {}) {
        super(world)
        this._defaultOrder = defaultOrder || []
        this._defaultFilter = defaultFilter || {}
        this.clearFilters()
    }
    
    
    get currentOrder() {
        return this._currentOrder
    }

    get currentFilter() {
        return this._currentFilter
    }
    
    get getFilter(){
        return getFieldExtractor(this.currentFilter)
    }
    
    setCurrentFilter(filter){
        this._currentFilter = {...filter}
    }
    
    modifyCurrentFilter(filterChanges){
        this._currentFilter = {...this._currentFilter, ...filterChanges}
    }
    
    
    setCurrentOrder(order){
        this._currentOrder = [...order]
    }


    @action.bound
    clearFilters(){
        this._currentOrder = [...this._defaultOrder]
        this._currentFilter = {...this._defaultFilter}
    }
    __clearFilters = this._action(this.clearFilters)
    
    
    @action.bound
    async _doFilter(filter){}
    
    @action.bound
    async filter(filter, {mix} = {}) {
        const newFilter = mix? {...this.currentFilter, ...filter} : filter
        this._currentFilter = {...newFilter}
        await this._doFilter(newFilter)
        this.onRefresh.notify()
    }
    __filter = this._asyncAction(this.filter)
    
    @boundMethod
    async _doSort(order) {}

    @boundMethod
    async sort(order) {
        this._currentOrder = [...order]
        await this._doSort(order)
        this.onRefresh.notify()
    }
    __sort = this._asyncAction(this.sort)


    @boundMethod
    async _doFilterAndSort(filter, order){}

    @boundMethod
    async filterAndSort(filter, order) {
        this._currentFilter = {...filter}
        this._currentOrder = order
        await this._doFilterAndSort(filter, order)
        this.onRefresh.notify()
    }
    __filterAndSort = this._asyncAction(this.filterAndSort)
    

    @action.bound
    async _doRefresh() {
        await this._doFilterAndSort(this._currentFilter, this._currentOrder)
    }


    @boundMethod
    __orderChanged(order) {
        this.__sort(order).then()
    }

    @boundMethod
    __searchChanged({fields}){
        this.__filter(fields).then()
    }
}