import {ListState} from 'sopix/react-state/listState'
import {RepresentanteFld, RepresentanteTable} from 'sop/db/RepresentanteTable'
import {Representante} from 'sop/db/Representante'
import {sopIcons} from 'sop/sop-icons'
import {calcGraphqlOrderMulti} from 'sopix/db/graphql-utils'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {boundMethod} from 'autobind-decorator'
import {action, computed, observable, runInAction} from 'mobx'
import {find, reduce} from 'lodash-es'
import {Solicitud} from 'sop/db/Solicitud'
import {User} from 'sop/db/User'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {fieldErrorsAsSimpleMessage} from 'sopix/db-access/db-access-utils'
import {UserFld, UserTable} from 'sop/db/UserTable'
import {Direccion} from 'sop/db/Direccion'
import {Cuenta} from 'sop/db/Cuenta'
import {PaisFld, PaisTable} from 'sop/db/PaisTable'
import {ProvinciaFld, ProvinciaTable} from 'sop/db/ProvinciaTable'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {ObraFld, ObraTable} from 'sop/db/ObraTable'
import {Obra} from 'sop/db/Obra'
import {getZona} from 'sop/utils/representante-utils'
import {InitState} from 'sopix/utils/InitState'
import {CuentaFld} from 'sop/db/CuentaTable'
import {DireccionFld} from 'sop/db/DireccionTable'
import {FORBIDDEN_ROW_CONFIG} from 'sopix/db/graphql-defs'
import {RowDeleter} from 'sopix/db-access/rowDeleter'
import {getFieldExtractor} from 'sopix/data/data-utils'

const MAX_RECORDS = 500


export class CandidatoCuenta {

    constructor(cuenta) {
        this.cuenta = cuenta
        this.solicitudesZonas = new Map()
    }
}

export class Candidato{
    
    /** @type {Map<int, CandidatoCuenta>} */
    cuentas
    
    constructor(candidato, cuentas, obra, representante) {
        this.candidato = candidato
        this.cuentas = cuentas
        this.obra = obra
        this.representante = representante
    }
    
    hasZona(zona){
        for (let cuenta of this.cuentas.values()){
            for (let cuentaZona of cuenta.solicitudesZonas.values()){
                if (cuentaZona === zona) {
                    return true
                }
            }
        }
        return false
    }
}

export class RepresentanteListState extends ListState{

    _paisesQL = new TableGraphql(PaisTable, {cached: true})
    _provinciasQL = new TableGraphql(ProvinciaTable, {cached: true})
    _userQL = new TableGraphql(UserTable, {cached: true})

    _representanteQL = new TableGraphql(RepresentanteTable)
    _solicitudQL = new TableGraphql(SolicitudTable)
    _obraQL = new TableGraphql(ObraTable)
    
    saveRepresentante = new SaveMutation(RepresentanteTable, 'SaveRepresentanteObra')

    @observable
    _filterSolicitud

    @observable
    filterEnabled = true

    @observable
    _searchMode = false
    
    paises
    provincias
    allRepresentantes
    
    solicitudesObra
    obra
    zonaObra
    
    @observable
    /** @type {Map<int, Candidato>} */
    candidatos = new Map()
    
    /** @type {RowDeleter} */
    deleter
    
    @computed
    get searchMode(){
        return this._searchMode
    }
    
    @computed
    get filterSolicitud(){
        return this._filterSolicitud
    }

    @computed
    get isFiltered(){
        return this.filterEnabled && this._filterSolicitud !== undefined
    }

    constructor(idObra, options) {
        
        const TABLE = RepresentanteTable
        
        super(idObra, {
            name: 'Representantes de obra',
            idField: Representante.idObra,
            itemIdField: Representante.idOr,
            icon: sopIcons.Representante,
            table: TABLE,
            ...options,
        })
        
        this.deleter = new RowDeleter(this.world, TABLE, 'DeleteRepresentanteObra')
    }

    async __init() {
        const [paises, provincias, representantes] = await Promise.all([
            this._paisesQL.query([
                PaisFld.iso,
                PaisFld.nombre,
            ]),
            this._provinciasQL.query([
                ProvinciaFld.cp,
                ProvinciaFld.provincia,
            ]),
            this._userQL.query([
                    UserFld.id,
                    UserFld.username,
                    UserFld.nombreCompleto,
                    UserFld.zona
                ],undefined, {roleUsuario: 3, inactivo: 0}
            ),
        ])
        this.paises = paises.rows
        this.provincias = provincias.rows
        this.allRepresentantes = representantes.rows

        await super.__init()
    }

    @boundMethod
    _afterApply(){
        if (!this.id) return
        
        this._calcCandidatos()
    }
    
    @boundMethod
    async _onMount(){
        if (!this._searchMode && this.rows.length === 0) {
            await this._toggleSearchMode()
        }
    }
    onMount = this._locked_catched_async(this._onMount)

    @action.bound
    async _toggleSearchMode(){
        this._searchMode = !this._searchMode
    }
    toggleSearchMode = this._locked_catched_async(this._toggleSearchMode)

    
    _findCandidato(idRepresentante){
        for (let candidato of this.candidatos.values()){
            if (candidato.representante && candidato.representante[Representante.idRepresentante] === idRepresentante) {
                return candidato
            }
        }
    }
    
    @action
    _calcCandidatos(){
        this.candidatos.clear()
        
        if (!this.allRepresentantes) return 
        if (!this.obra) return
        
        for (let candidato of this.allRepresentantes){
            const cuentas = new Map()
            let obra = undefined

            const add = (zona, solicitud) => {
                const cuenta = solicitud[Solicitud.cuenta]
                if (!cuenta) return
                const idCuenta = cuenta[Cuenta.idCuenta]
                if (!idCuenta) return
                let cuentaCandidato = cuentas.get(idCuenta)
                if (!cuentaCandidato) {
                    cuentaCandidato = new CandidatoCuenta(cuenta)
                    cuentas.set(idCuenta, cuentaCandidato)
                }
                cuentaCandidato.solicitudesZonas.set(solicitud[Solicitud.direccion], zona)
            }
            
            const userZona = candidato[User.zona]
            const zonas = !userZona ? [] : candidato[User.zona].split(',')
            
            const enZona = (zona, pais, cp) => {
                if (!zona) {
                    return false
                }
                
                return zona === getZona(pais, cp, this.paises)
            }

            for (let zona of zonas) {
                if (enZona(zona, this.obra[Obra.pais], this.obra[Obra.cp])){
                    obra = true
                }
                for (let solicitud of this.solicitudesObra){
                    const sol = getFieldExtractor(solicitud)
                    if (enZona(zona, sol(Solicitud.direccion,Direccion.pais), 
                        sol(Solicitud.direccion, Direccion.cp)))
                    {
                        add(zona, solicitud)    
                    }
                }
            }
        
            if (cuentas.size > 0 || obra) {
                this.candidatos.set(candidato[User.id], new Candidato(
                    candidato,
                    cuentas,
                    obra,
                    find(this.rows, {[Representante.idRepresentante]: candidato[User.id]}),
                ))
            }
        }        
    }

    async __loadData(id) {
        const [representantes, solicitudes, obras] = await Promise.all([
            this._representanteQL.query(
                [
                    ...RepresentanteTable.regularFields,
                    [RepresentanteFld.representante, [UserFld.username, UserFld.nombreCompleto, UserFld.zona]],
                    [RepresentanteFld.autor, [UserFld.username, UserFld.nombreCompleto]],
                ],
                {first: MAX_RECORDS, sort: calcGraphqlOrderMulti([
                        new OrderEntry(Representante.fechaCreacion, OrderDirection.DESC),
                    ])},
                {[Representante.idObra]: parseInt(id)}
            ),
            
            this._solicitudQL.query([
                [SolicitudFld.cuenta, [
                    CuentaFld.idCuenta,
                    CuentaFld.cuenta
                ]],
                [SolicitudFld.direccion, [
                    DireccionFld.idDireccion,
                    DireccionFld.direccion,
                    DireccionFld.cp,
                    DireccionFld.pais,
                ]],
                ],undefined, {idObra: parseInt(id)}
            ),

            this._obraQL.query([
                    ObraFld.cp,
                    ObraFld.pais,
                ],undefined, {idObra: parseInt(id)}
            ),

        ])
        
        return {
            representantes: representantes.rows, 
            solicitudes: solicitudes.rows,
            obra: obras.rows[0],
        }
    }
    
    __apply({representantes, solicitudes, obra}) {
        if (!obra) {
            return
        }

        super.__apply(representantes)

        this.solicitudesObra = solicitudes
        this.obra = obra
        this.zonaObra = getZona(obra[Obra.pais], obra[Obra.cp], this.paises)
    }
    
    
    @boundMethod
    async _delete(...ids){
        await this.deleter.delete(...ids)
        await this._reload()
    }
    delete = this._locked_catched_async(this._delete)


    @boundMethod
    async _add(idRepresentante){
        await this.saveRepresentante.query({
            [Representante.idObra]: this.id,
            [Representante.idRepresentante]: idRepresentante,
        })
        await this._reload()
    }
    add = this._locked_catched_async(this._add)
    

    @action
    filter(solicitud){
        runInAction(() => this._filterSolicitud = solicitud)
    }

    @computed
    get filteredRows(){
        if (!this.isFiltered) {
            return this.rows
        } else {
            return reduce(this.rows, (result, representante, index) => {
                if (this._filterSolicitud === undefined || !this.filterEnabled
                    || this._filterSolicitud[Solicitud.idSolicitud] === representante[Representante.idSolicitud]) {
                    result.push(representante)
                }
                return result
            }, [])
        }
    }

    @computed
    get candidatosVisibles(){
        
        const result = []

        const addRepresentantes = () => {
            for (let row of this.rows){
                const candidato = this._findCandidato(row[Representante.idRepresentante])
                if (candidato) {
                    result.push(candidato)
                } else {
                    result.push(new Candidato(undefined, new Map(), undefined, row))
                }
            }
        }

        const candidatos = this.candidatos
        const addCandidatos = rep => {
            for (let candidato of candidatos.values()) {
                if (candidato.representante === undefined) {
                    result.push(candidato)
                }
            }
        }
        
        addRepresentantes()
        
        if (this.searchMode){
            addCandidatos()    
        } 
        
        return result
    }
    

    @boundMethod
    async _addObra(idObra){
        const {success, errorList, result} = await this.saveRepresentante.query({
            [Representante.idObra]: this.id,
            [Representante.idOr]: idObra,
        })

        if (!success) {
            throw new Error(fieldErrorsAsSimpleMessage(RepresentanteTable, errorList))
        }
    }
    addObra = this._locked_catched_async(this._addObra)

    
    _getNoZoneIds(){
        const result = []
        for (let representante of this.rows){
            if (this._findCandidato(representante[Representante.idRepresentante]) === undefined) {
                result.push(representante[Representante.idOr])
            }
        }
        return result
    }
    
    @computed
    get hasNoZoneRepresentantes(){
        return this._getNoZoneIds().length > 0
    }

    @computed
    get noZoneRepresentantesCount(){
        return this._getNoZoneIds().length
    }
    
    
    @boundMethod
    async _deleteNoZoneRepresentantes(){
        const ids = this._getNoZoneIds()
        await this._delete(...ids)
    }
    deleteNoZoneRepresentantes = this._locked_catched_async(this._deleteNoZoneRepresentantes)

    @boundMethod
    obraApplyReaction(fields){
        const newZona = getZona(fields[Obra.pais], fields[Obra.cp], this.paises)
        if (this.initialized === InitState.YES && newZona && newZona !== this.zonaObra) {
            this._reload().then()
        }
    }   
}