 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AprobacionTipoIds} from './AprobacionTipo'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const AprobacionTipoTable = new DbTable(
    Tables.AprobacionTipo,
    'AprobacionTipos',
    graphqlUrl,
    'AprobacionTipo',
    [AprobacionTipoIds.idTipo],
    [AprobacionTipoIds.idTipo, AprobacionTipoIds.tipo, AprobacionTipoIds.permisoAprobar],
    [],
    [AprobacionTipoIds.aprobaciones],
    ['verAprobaciones'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const AprobacionTipoFld = Object.freeze({
    aprobaciones : new DbObjectList(Tables.AprobacionTipo, AprobacionTipoIds.aprobaciones, Tables.Aprobacion),
    idTipo : new DbIntId(Tables.AprobacionTipo, AprobacionTipoIds.idTipo, 'Id tipo'),
    tipo : new DbText(Tables.AprobacionTipo, AprobacionTipoIds.tipo, 'Tipo', 30),
    permisoAprobar : new DbText(Tables.AprobacionTipo, AprobacionTipoIds.permisoAprobar, 'Permiso aprobar', 100),
})

registerTableFields(Tables.AprobacionTipo, AprobacionTipoFld) 
