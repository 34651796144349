 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const DispositivoIds = Object.freeze({
    idDispositivo: Symbol('idDispositivo'),
    token: Symbol('token'),
    fechaCreacion: Symbol('fechaCreacion'),
    fechaFin: Symbol('fechaFin'),
})

export const Dispositivo = Object.freeze({
    idDispositivo: 'idDispositivo',
    token: 'token',
    fechaCreacion: 'fechaCreacion',
    fechaFin: 'fechaFin',
})
