import {StateBasics} from 'sopix/react-state/stateBasics'
import {sopIcons} from 'sop/sop-icons'
import {boundMethod} from 'autobind-decorator'
import {Obra} from 'sop/db/Obra'
import {ObserverHub} from 'sopix/utils/observers'
import {action, observable} from 'mobx'
import {getElevationForLocations, getGeocode, getMapsLoader} from 'sopix/google/maps'
import {snackbarNotifications} from 'sopix/snackbar/snackbarNotifications'
import {values} from 'lodash-es'

const MAPS_KEY= process.env.NODE_ENV === 'development'
    ? 'AIzaSyAPI4Ova8kNsSnT1qyb2g5z2BfDsiDtezs' // david@cimaware.com DevSop2
    : 'AIzaSyCip6JvtrZhoK-cwBsB5kW7lUiVFsnw0AM' // copiada de DevSop

const INITIAL_ZOOM = 15
const NULL_LAT = 0
const NULL_LNG = 0


function latLngString(latLng){
    return JSON.stringify([[latLng[Obra.lat]], latLng[Obra.lng]])
}


// Está aquí porque hace referencias a Obra
export function extractAddress(address_components){
    const result = {}
    let number, route

    for (let entry of address_components) {
        for (let type of entry.types){
            switch(type){
                case 'street_number':
                    number = entry["long_name"]
                    break
                case 'route':
                    route = entry["long_name"]
                    break
                case 'locality':
                    result[Obra.localidad] = entry["long_name"]
                    break
                case 'administrative_area_level_2':
                    result[Obra.provincia] = entry["long_name"]
                    break
                case 'country':
                    result[Obra.pais] = entry["long_name"]
                    break
                case 'postal_code':
                    result[Obra.cp] = entry["long_name"]
                    break
                default:
            }
        }
    }

    result[Obra.direccion] = number ? `${route}, ${number}` : route

    return result
}



export class ObraMapState extends StateBasics{

    obra
    markerData
    
    center
    zoom
    typeId
    markerPos

    marker
    map

    @observable
    sync
    
    onMarkerSyncObservers = new ObserverHub()
    
    constructor({errorManager}) {
        super({title:'Mapa de obra', icon: sopIcons.Mapa, errorManager})
        
        //El loader de davidkudera es el original más avanzado que fue forkeado por Google en @googlemaps/js-api-loader 
        this.loader = getMapsLoader(MAPS_KEY, {
            region: 'ES',
            language: 'es',
        })
    }


    async __init() {
        this.google = await this.loader.load()
        const maps = this.google.maps
        this.geocode = getGeocode(maps)
        this.elevationForLocations = getElevationForLocations(maps)
        
        if  (!this.obra) {
            return
        }
        
        this._resetMapSettings()
        this._updateMap()
    }

    @action.bound
    toggleSync(){
        this.sync = !this.sync
        
        this.onMarkerSyncObservers.notify({
            [Obra.locked]: this.sync ? 1 : 0,
        })
    }
    
    @boundMethod
    async mountReaction(){
        await this.initialize()
        
        if (!this.obra) {
            return
        }
        
        if (!this.typeId) {
            this._resetMapSettings()            
        }
        
        this._updateMap()
    }
    
    @boundMethod
    unmountReaction(){
        this.map = undefined
        this.marker = undefined
    }
    
    
    _resetMapSettings(){
        if (!this.google) return
        
        const maps = this.google.maps
        
        this.typeId = maps.MapTypeId.ROADMAP
        this.zoom = INITIAL_ZOOM
        
        const lat = this.obra[Obra.lat]
        const lng = this.obra[Obra.lng]
        const getPos = () => !this.obra ? undefined : new maps.LatLng(lat || NULL_LAT, lng || NULL_LNG)
        this.center = getPos()
        this.markerPos = getPos()
    }
    
    _updateMap(){
        if (!this.google) return

        const maps = this.google.maps
        const title = !this.obra ? undefined : this.obra[Obra.cod]
        
        //Update
        if (this.map) {
            const map = this.map
            
            map.setCenter(this.center)
            map.setZoom(this.zoom)
            map.setMapTypeId(this.typeId)
            
            this.marker.setPosition(this.markerPos)
            this.marker.title = title
            
            //Create
        } else {
            const mapDiv = document.getElementById('obra-map')
            if (!mapDiv) return
            
            const map = this.map = new maps.Map(mapDiv, {
                center: this.center,
                zoom: this.zoom,
                mapTypeId: this.typeId,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: maps.MapTypeControlStyle.DROPDOWN_MENU
                }
            })
            this.map.addListener('center_changed', this._centerReaction)
            this.map.addListener('zoom_changed', this._zoomChanged)
            this.map.addListener('maptypeid_changed', this._typeIdChanged)

            const marker = this.marker = new maps.Marker({
                position: this.markerPos,
                map,
                title: title,
                draggable: true,
            })
            marker.addListener('dragend', this._markerReaction)
        }
        
    }
    
    @action.bound
    obraLoadReaction(fields){
        this.obra = this.markerData = {...fields}
        this._resetMapSettings()
        this._updateMap()
        this.sync = fields[Obra.locked]
    }

    @boundMethod
    obraBlurReaction({fieldName, fields, changed}){
        if (!changed || !this.sync) return
        
        if (
            fields[Obra.direccion] === this.markerData[Obra.direccion] &&
            fields[Obra.localidad] === this.markerData[Obra.localidad] &&
            fields[Obra.provincia] === this.markerData[Obra.provincia] &&
            fields[Obra.cp] === this.markerData[Obra.cp] &&
            fields[Obra.pais] === this.markerData[Obra.pais]
        ) return
        
        this._syncAddress(fields, fieldName)
    }

    @boundMethod
    async __syncAddress(fields, fieldName){
        
        let address = ''
        for (let entry of [Obra.direccion, Obra.localidad, Obra.provincia, Obra.pais]) {
            const field = fields[entry]
            if (field) {
                address += (address ? ', ' : '') + field
            }
        }

        const geoCodeMessage = snackbarNotifications.notify(`Geocode: ${address}.`)
        let latLng
        let addressFields
        try{
            const [geocodeResult] = await this.geocode({
                address: address
            })

            addressFields = extractAddress(geocodeResult.address_components)
            geoCodeMessage.append(`${values(addressFields).reverse().join(', ')}`, 'success')

            if (!addressFields[Obra.direccion] && this.markerData[Obra.direccion]) {
                geoCodeMessage.append('Resultado sin calle: se ignora el resultado.',  'warning')
                return
            }

            latLng = geocodeResult.geometry.location
            geoCodeMessage.append(`${latLng.lat()}, ${latLng.lng()}`, 'success')
        }catch (errorMessage){
            geoCodeMessage.append(`${errorMessage}`, 'error')
            return
        }

        const latLngData = {
            [Obra.lat]: String(latLng.lat()),
            [Obra.lng]: String(latLng.lng()),
        }

        if (latLngString(this.markerData) === latLngString(latLngData)) {
            geoCodeMessage.append('Localización sin cambios: se ignora el resultado.',  'warning')
            return
        }


        const elevationMessage = snackbarNotifications.notify(`Elevation: ${latLng}.`)
        let elevation
        try{
            const [elevationResult] = await this.elevationForLocations({
                locations: [latLng],
            })
            elevation = Math.round(elevationResult.elevation)
            elevationMessage.append(`${elevation}m altitud`, 'success')
            
        }catch (errorMessage){
            elevationMessage.append(`${errorMessage}`, 'error')
            return
        }

        this.markerData = {
            ...latLngData,
            [Obra.altitud]: elevation,
            ...addressFields,
        } 

        this.onMarkerSyncObservers.notify(this.markerData)

                
        if (this.marker) {
            this.marker.setPosition(latLng)
            this.map.setCenter(latLng)
        }else{
            this.center = latLng
            this.markerPos = latLng 
        }
    }
    _syncAddress = this._catched_async(this.__syncAddress)
    
    
    @boundMethod
    async __syncLatLng(latLng){
        
        const message = snackbarNotifications.notify(
            `Elevation + Geocode: ${latLng.lat()}, ${latLng.lng()}.`)
        let addressFields, elevation
        try{
            const [[elevationResult], [geocodeResult]] = await Promise.all([
                this.elevationForLocations({
                    locations: [latLng],
                }),
                this.geocode({
                    location: latLng,
                })
            ])
            elevation = Math.round(elevationResult.elevation)
            message.append(`${elevation}m altitud`, 'success')

            addressFields = extractAddress(geocodeResult.address_components)
            message.append(`${values(addressFields).reverse().join(', ')}`, 'success')
        
        }catch (errorMessage){
            message.append(`${errorMessage}`, 'error')
            return
        }

        this.markerData = {
            [Obra.lat]: String(latLng.lat()),
            [Obra.lng]: String(latLng.lng()),
            [Obra.altitud]: elevation,
            ...addressFields,
        }

        this.onMarkerSyncObservers.notify(this.markerData)
    }
    _syncLatLng = this._catched_async(this.__syncLatLng)
    
    
    @boundMethod
    _markerReaction(event){
        const latLng = this.markerPos = event.latLng

        if (this.sync){
            this._syncLatLng(latLng)
        }
    }

    @boundMethod
    _centerReaction(event) {
        this.center = this.map.center
    }

    @boundMethod
    _zoomChanged(event) {
        this.zoom = this.map.zoom
    }

    @boundMethod
    _typeIdChanged(event) {
        this.typeId = this.map.mapTypeId
    }
}