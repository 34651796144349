 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SistemaIds} from './Sistema'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const SistemaEdt = Object.freeze({
    idSistema : new SimpleEditor(SistemaIds.idSistema, 11, 11),
    sistema : new SimpleEditor(SistemaIds.sistema, 30, 12),
})

tableEditors[Tables.Sistema] = SistemaEdt
