 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AvisoIds} from './Aviso'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbBool, DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {AvisoTipoIds} from './AvisoTipo'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {TareaIds} from './Tarea'
import {CuentaIds} from './Cuenta'

export const AvisoTable = new DbTable(
    Tables.Aviso,
    'Avisos',
    graphqlUrl,
    'Aviso',
    [AvisoIds.idAviso],
    [AvisoIds.idAviso, AvisoIds.fecha, AvisoIds.idTipo, AvisoIds.aviso, AvisoIds.idUsuario, AvisoIds.idObra, AvisoIds.idTarea, AvisoIds.idPedido, AvisoIds.idCuenta],
    [AvisoIds.tipo, AvisoIds.usuario, AvisoIds.obra, AvisoIds.tarea, AvisoIds.cuenta],
    [AvisoIds.usuariosPendientes],
    ['verAvisos'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const AvisoFld = Object.freeze({
    usuariosPendientes : new DbObjectList(Tables.Aviso, AvisoIds.usuariosPendientes, Tables.User),
    tipo : new DbObject(Tables.Aviso, AvisoIds.tipo, AvisoIds.idTipo, 'Tipo'),
    usuario : new DbObject(Tables.Aviso, AvisoIds.usuario, AvisoIds.idUsuario, 'Usuario'),
    obra : new DbObject(Tables.Aviso, AvisoIds.obra, AvisoIds.idObra, 'Obra'),
    tarea : new DbObject(Tables.Aviso, AvisoIds.tarea, AvisoIds.idTarea, 'Trabajo'),
    cuenta : new DbObject(Tables.Aviso, AvisoIds.cuenta, AvisoIds.idCuenta, 'Cuenta'),
    idAviso : new DbIntId(Tables.Aviso, AvisoIds.idAviso, 'Id aviso'),
    fecha : new DbDateTime(Tables.Aviso, AvisoIds.fecha, 'Fecha'),
    idTipo : new DbObjectId(Tables.Aviso, AvisoIds.idTipo, 'Id tipo', {foreignTableId: Tables.AvisoTipo, foreignKeyFieldId: AvisoTipoIds.idTipo}),
    aviso : new DbText(Tables.Aviso, AvisoIds.aviso, 'Aviso', 1000),
    idUsuario : new DbObjectId(Tables.Aviso, AvisoIds.idUsuario, 'Id usuario', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idObra : new DbObjectId(Tables.Aviso, AvisoIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idTarea : new DbObjectId(Tables.Aviso, AvisoIds.idTarea, 'Id tarea', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    idPedido : new DbInt(Tables.Aviso, AvisoIds.idPedido, 'Id pedido'),
    idCuenta : new DbObjectId(Tables.Aviso, AvisoIds.idCuenta, 'Id cuenta', {foreignTableId: Tables.Cuenta, foreignKeyFieldId: CuentaIds.idCuenta}),
    pendiente : new DbBool(Tables.Aviso, AvisoIds.pendiente, 'Pendiente'),
})

registerTableFields(Tables.Aviso, AvisoFld) 
