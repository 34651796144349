import {useEffect, useState} from 'react'
import {delay} from 'lodash-es'
import transitions from '@material-ui/core/styles/transitions'

export function useDelayedClose(stateObject, onClose){
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(!!stateObject)
    }, [stateObject])
    function closeHandler(...params){
        setOpen(false)
        delay(() => {
            onClose && onClose(...params)
        }, transitions.duration)
    }
    return [open, closeHandler]
}