 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SageEmpresaIds = Object.freeze({
    codigoEmpresa: Symbol('codigoEmpresa'),
    empresa: Symbol('empresa'),
    anagrama: Symbol('anagrama'),
})

export const SageEmpresa = Object.freeze({
    codigoEmpresa: 'codigoEmpresa',
    empresa: 'empresa',
    anagrama: 'anagrama',
})
