 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AnualMesIds} from './AnualMes'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbObject, DbObjectId, DbObjectList} from 'sopix/db/dbField'
import {MesIds} from './Mes'

export const AnualMesTable = new DbTable(
    Tables.AnualMes,
    'AnualMeses',
    graphqlUrl,
    'AnualMes',
    [AnualMesIds.idMes],
    [AnualMesIds.idMes, AnualMesIds.anoNo, AnualMesIds.mesNo],
    [AnualMesIds.mesNoObj],
    [AnualMesIds.liqtecmeses],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const AnualMesFld = Object.freeze({
    liqtecmeses : new DbObjectList(Tables.AnualMes, AnualMesIds.liqtecmeses, Tables.Liqtecmes),
    mesNoObj : new DbObject(Tables.AnualMes, AnualMesIds.mesNoObj, AnualMesIds.mesNo, 'Mes no'),
    idMes : new DbIntId(Tables.AnualMes, AnualMesIds.idMes, 'Id mes'),
    anoNo : new DbInt(Tables.AnualMes, AnualMesIds.anoNo, 'Ano no'),
    mesNo : new DbObjectId(Tables.AnualMes, AnualMesIds.mesNo, 'Mes no', {foreignTableId: Tables.Mes, foreignKeyFieldId: MesIds.idMes}),
})

registerTableFields(Tables.AnualMes, AnualMesFld) 
