import {WorldObject} from 'sopix/piece/worldObject'
import {action, observable} from 'mobx'
import {find} from 'lodash-es'
import {boundMethod} from 'autobind-decorator'
import {pickRow} from 'sopix/db/db-utils'
import {ObserverHub} from 'sopix/utils/observers'

export class RowSelection extends WorldObject{
    idField

    locked = false

    /** @type {DataSource} */
    dataSource
    
    @observable
    _id

    onLockedRejection = new ObserverHub()
    onRowSelected = new ObserverHub()
    onRowClicked = new ObserverHub()
    _deselectOnClick
    
    /**
     * @param {DataSource} dataSource
     */
    constructor(world, dataSource, idField, {deselectOnClick=true} = {}) {
        super(world)
        this.dataSource = dataSource
        this.idField = idField
        this._deselectOnClick = deselectOnClick
    }

    get id(){
        return this._id
    }

    get row(){
        return !this._id ? undefined : find(this.dataSource.data, {[this.idField]: this._id})
    }

    @action.bound
    set(id){
        if (this._id === id) return

        if (this.locked) {
            this.onLockedRejection.notify(id)
            return
        }
        this._id = id

        this.onRowSelected.notify({row: this.row, id: this._id})
    }
    __set=this._action(this.set)

    
    getCloseRow(offset){
        return pickRow(this.dataSource.data, this.idField, this._id, offset)
    }
    
    getCloseId(offset){
        const row = this.getCloseRow(offset)
        return !row ? undefined : row[this.idField] 
    }
    
    @boundMethod
    rowDeleted(id) {
        if (id !== this._id) return
        
        const next = this.getCloseId(+1)
        if (next) {
            this.set(next)
        } else {
            this.set(this.getCloseId(-1))
        }
    }


    @boundMethod
    lock(locked) {
        this.locked = locked
    }


    @boundMethod
    boundlessClicked({data, index, event}) {
        const id = data ? data[this.idField] : undefined
        
        const finalId = id && id === this.id && this._deselectOnClick ? undefined : id
        
        this.onRowClicked.notify({row: data, id: finalId})
        this.set(finalId)
    }
    __boundlessClicked = this._action(this.boundlessClicked)
    
    
    @boundMethod
    rowCreated(){
        this.set()
    }
    
    @boundMethod
    rowSaved(fields){
        this.set(fields[this.idField])
    }

    @boundMethod
    listRefreshed(){
        if (!this.row) {
            this.set()
        }
    }
}