import React, {useEffect, useReducer} from 'react'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('BoundlessRows')

export function Rows({render, register, unregister, afterRender}) {
    debug(() => `render rows`)

    const [, doRender] = useReducer(state => state + 1, 0, undefined)
    register(doRender)

    useEffect(() => () => unregister(), [unregister])

    useEffect(() => {
        afterRender()
    })

    return <>{render(doRender)}</>
}