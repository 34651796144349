import React from 'react'
import {Chip, Tooltip} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: {
        overflow: 'hidden',
        justifyContent: 'left',
        maxWidth: ({maxLarge, horizontal}) => !horizontal || !maxLarge ? 'none' : maxLarge
    },
    
    rootVertical: {
        overflow: 'hidden',
        writingMode: 'vertical-lr',
        transform: 'rotate(180deg)',
        height: 'inherit',
        width: 32,
        maxHeight: ({maxLarge, horizontal}) => horizontal || !maxLarge ? 'none' : maxLarge
    },
    
    sizeSmallVertical: {
        height: 'inherit',
        width: 24,
    },
    
    labelVertical: {
        paddingLeft: 'inherit',
        paddingRight: 'inherit',
        paddingTop: 12,
        paddingBottom: 12,
    },
    
    labelSmallVertical: {
        paddingLeft: 'inherit',
        paddingRight: 'inherit',
        paddingTop: 8,
        paddingBottom: 8,
    }
})) 


export function Tag({className, maxLarge, label, tooltip, orientation, size="small", ...other}) {
    
    const horizontal = orientation !== 'vertical'
    
    const cls = useStyles({maxLarge, horizontal})

    const chip = (
        <Chip
            className={clsx(
                className
            )}
            classes={horizontal ? {
                root: cls.root,
            } : {
                root: cls.rootVertical,
                sizeSmall: cls.sizeSmallVertical,
                label: cls.labelVertical,
                labelSmall: cls.labelSmallVertical,
            }}
            size={size}
            label={label}
            color="primary"
            {...other}
        />
    )

    return !tooltip ? chip : (
        <Tooltip title={tooltip}>
            {chip}
        </Tooltip>
    )
}
