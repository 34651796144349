 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SagePedidoIds} from './SagePedido'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const SagePedidoCol = Object.freeze({
    codigoEmpresa : new SimpleColumn(SagePedidoIds.codigoEmpresa, 11, 11, Align.RIGHT),
    ejercicioPedido : new SimpleColumn(SagePedidoIds.ejercicioPedido, 11, 11, Align.RIGHT),
    seriePedido : new SimpleColumn(SagePedidoIds.seriePedido, 10, 10, Align.LEFT),
    numeroPedido : new SimpleColumn(SagePedidoIds.numeroPedido, 11, 11, Align.RIGHT),
    codigoCliente : new SimpleColumn(SagePedidoIds.codigoCliente, 15, 10, Align.LEFT),
    suPedido : new SimpleColumn(SagePedidoIds.suPedido, 20, 10, Align.LEFT),
    razonSocial : new SimpleColumn(SagePedidoIds.razonSocial, 40, 16, Align.LEFT),
    fechaPedido : new DateTimeColumn(SagePedidoIds.fechaPedido, 16, 16, Align.LEFT),
    fechaPedidoOnlyDate : new DateColumn(SagePedidoIds.fechaPedido, 11, 11, Align.LEFT),
    referencia : new SimpleColumn(SagePedidoIds.referencia, 10, 10, Align.LEFT),
})

tableColumns[Tables.SagePedido] = SagePedidoCol
