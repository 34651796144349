import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {getLog} from 'sopix/log'
import {Bar} from 'sopix/formComps/Bar'
import {getColumnTextFormatter} from 'sopix/db/db-column-utils'
import {SagePedido} from 'sop/db/SagePedido'
import {tableColumnMapFromDb} from 'sopix/boundless-db/boundless-db-utils'
import {useBoundlessTable} from 'sopix/boundless-db/use-boundless-table'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {SagePedidoCol} from 'sop/db/SagePedidoColumns'
import {Align} from 'sopix/db/db-types'
import {BlRowCell71} from 'sop/boundless-custom/BlRowCell71'
import {makeStyles} from '@material-ui/core/styles'

const log = getLog('PedidoList')


const columns = [
    SagePedidoCol.codigoEmpresa,
    SagePedidoCol.numeroPedido,
    SagePedidoCol.suPedido,
    SagePedidoCol.razonSocial,
    SagePedidoCol.fechaPedidoOnlyDate,
]


function fixTableColumn(/* TableColumn */ tableColumn, fieldName, /* DbColumn */ dbColumn){
    switch(fieldName){
        case SagePedido.codigoEmpresa:
            tableColumn.align = Align.LEFT
            tableColumn.title = 'Empresa'
            tableColumn.minWidth = 90
            break
        case SagePedido.numeroPedido:
            tableColumn.minWidth = 70
            break
        case SagePedido.razonSocial:
            tableColumn.minWidth = 100
            break
        case SagePedido.suPedido:
            tableColumn.minWidth = 90
            break
        default:
    }
}

function getFormatted(fieldName, value, /* DbColumn */ column) {
    switch (fieldName) {
        case SagePedido.codigoEmpresa:
            switch(value) {
                case 1:
                    return "Polytherm"
                case 2:
                    return "Thermoconcept"
                case 3:
                    return "Neupex"
                default:
                    return "???"
            }

        default:
            return getColumnTextFormatter(column)(value)
    }
}


function getSortable(fieldName, value, /* DbColumn */ column) {
    switch (fieldName) {
        case SagePedido.fechaPedido:
            return value

        default:
            return getFormatted(fieldName, value, column)
    }
}



const useStyles = makeStyles(() => ({
    pedidoList: {
        flexGrow: 1,
    },
}))


export const PedidoList = observer(

/**
 * @param {PedidoListState} pedidoList
 * @param direction
 * @returns {JSX.Element}
 */
({pedidoList, direction}) => {
    log(()=>`render`)

    const cls = useStyles()

    const [tableColumns] = useState(() => tableColumnMapFromDb(columns, fixTableColumn))
    
    const [orderedData, order, setOrder] =
        useBoundlessTable(pedidoList.rows, columns, SagePedido.numeroPedido, getFormatted, getSortable)

    return <>
        <Bar direction={direction}>
        </Bar>
        <BlTable
            className={cls.pedidoList}
            RowCell={BlRowCell71}
            dense={true}
            columns={tableColumns}
            data={orderedData}
            keyColumnId={SagePedido.numeroPedido}
            order={order}
            onOrderChange={setOrder}
        />
    </>
})
