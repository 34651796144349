 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SolicitudTareaIds} from './SolicitudTarea'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const SolicitudTareaCol = Object.freeze({
    idSolicitud : new SimpleColumn(SolicitudTareaIds.idSolicitud, 11, 11, Align.RIGHT),
    idTarea : new SimpleColumn(SolicitudTareaIds.idTarea, 11, 11, Align.RIGHT),
})

tableColumns[Tables.SolicitudTarea] = SolicitudTareaCol
