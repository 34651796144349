 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageEmpresaIds} from './SageEmpresa'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbText} from 'sopix/db/dbField'

export const SageEmpresaTable = new DbTable(
    Tables.SageEmpresa,
    'Empresas',
    graphqlUrl,
    'SageEmpresa',
    [SageEmpresaIds.codigoEmpresa],
    [SageEmpresaIds.codigoEmpresa, SageEmpresaIds.empresa, SageEmpresaIds.anagrama],
    [],
    [],
    ['verCuentasSage'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SageEmpresaFld = Object.freeze({
    codigoEmpresa : new DbIntId(Tables.SageEmpresa, SageEmpresaIds.codigoEmpresa, 'Codigo empresa'),
    empresa : new DbText(Tables.SageEmpresa, SageEmpresaIds.empresa, 'Empresa', 45),
    anagrama : new DbText(Tables.SageEmpresa, SageEmpresaIds.anagrama, 'Anagrama', 45),
})

registerTableFields(Tables.SageEmpresa, SageEmpresaFld) 
