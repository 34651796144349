import React, {useEffect, useState} from 'react'
import {SolicitudEdt} from 'sop/db/SolicitudEditors'
import {Solicitud} from 'sop/db/Solicitud'
import {getLog} from 'sopix/log'
import {Fields} from 'sopix/formComps/Fields'
import {observer} from 'mobx-react'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {ObraEdt} from 'sop/db/ObraEditors'
import {Obra} from 'sop/db/Obra'
import {FormFieldHelper} from 'sopix/form/formFieldHelper'
import {SolicitudFld} from 'sop/db/SolicitudTable'
import {WithComplexProgress} from 'sopix/progress/WithComplexProgress'
import {UserEdt} from 'sop/db/UserEditors'
import {User} from 'sop/db/User'
import {DateField} from 'sopix/form/editors/DateField'
import {Field} from 'sopix/form/editors/Field'
import {Wrap} from 'sopix/formComps/Wrap'

const log = getLog('SolicitanteForm') 

export const SolicitanteForm = observer(
/**
 * @param {SolicitanteFormState} state
 * @returns {JSX.Element}
 */
function ({solicitante: state}) {
    
    const fieldManager = state.fieldManager
    const fields = fieldManager.fields
    
    useEffect(() => {
        state.initialize()
    }, [state])


    const [fld] = useState(() => new FormFieldHelper(fieldManager))
    
    const isNew = fields[Solicitud.idSolicitud] === undefined
    
    const autor = fields[Solicitud.autor]
    
    const sya = state._catched
    
    return (
        <WithComplexProgress
            initialized={state.initialized}
            locked={state.locked}
            onReplay={state.initialize}
        >
            <Fields>
                {isNew ? null : <>
                    <Field
                        {...fld.edt(ObraEdt.cod, {manager: false})}
                        label={SolicitudFld.obra.title}
                        disabled={true}
                        value={fields[Solicitud.obra][Obra.cod]}
                    />
                    <Field
                        {...fld.edt(UserEdt.username, {manager: false})}
                        label={SolicitudFld.autor.title}
                        disabled={true}
                        value={autor ? autor[User.username] : '-'}
                    />
                    <DateField
                        {...fld.edt(SolicitudEdt.fechaCreacion, {manager: false})}
                        disabled={true}
                        value={fields[Solicitud.fechaCreacion]}
                    />
                    <Wrap />
                </>}
                <DropdownField
                    {...fld.edt(SolicitudEdt.cuenta)}
                    autoFocus={isNew}
                    disabled={!isNew}
                    options={[fields[Solicitud.cuenta]]}
                    optionsSource={state.cuentaOptionsSource}
                    onChange={state.cuentaReaction}
                />
    
                <DropdownField
                    {...fld.edt(SolicitudEdt.direccion)}
                    autoFocus={!isNew}
                    disabled={state.direccionOptions.length === 0}
                    options={state.direccionOptions}
                    onChange={state.direccionReaction}
                    customRender={sya(state.renderDireccion)}
                    getRowLabel={sya(state.renderDireccion)}
                />
    
                <DropdownField
                    {...fld.edt(SolicitudEdt.contacto)}
                    disabled={state.contactoOptions.length === 0}
                    options={state.contactoOptions}
                />
            </Fields>
        </WithComplexProgress>
    )
})



