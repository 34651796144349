 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {RelacionadaIds} from './Relacionada'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbIntId, DbObject, DbObjectId} from 'sopix/db/dbField'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const RelacionadaTable = new DbTable(
    Tables.Relacionada,
    'Relacionadas',
    graphqlUrl,
    'Relacionada',
    [RelacionadaIds.idRelacion],
    [RelacionadaIds.idRelacion, RelacionadaIds.idObra, RelacionadaIds.idRelacionada, RelacionadaIds.fechaCreacion, RelacionadaIds.idAutor],
    [RelacionadaIds.obra, RelacionadaIds.relacionada, RelacionadaIds.autor],
    [],
    ['verObrasRelacionadas'],
    ['modificarObrasRelacionadas'],
    undefined,
    undefined,
    [],
    [],
)


export const RelacionadaFld = Object.freeze({
    obra : new DbObject(Tables.Relacionada, RelacionadaIds.obra, RelacionadaIds.idObra, 'Obra'),
    relacionada : new DbObject(Tables.Relacionada, RelacionadaIds.relacionada, RelacionadaIds.idRelacionada, 'Relacionada'),
    autor : new DbObject(Tables.Relacionada, RelacionadaIds.autor, RelacionadaIds.idAutor, 'Autor'),
    idRelacion : new DbIntId(Tables.Relacionada, RelacionadaIds.idRelacion, 'Id relacion'),
    idObra : new DbObjectId(Tables.Relacionada, RelacionadaIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idRelacionada : new DbObjectId(Tables.Relacionada, RelacionadaIds.idRelacionada, 'Id relacionada', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    fechaCreacion : new DbDateTime(Tables.Relacionada, RelacionadaIds.fechaCreacion, 'Fecha creacion'),
    idAutor : new DbObjectId(Tables.Relacionada, RelacionadaIds.idAutor, 'Creado por', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
})

registerTableFields(Tables.Relacionada, RelacionadaFld) 
