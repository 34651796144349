import React from 'react'
import {Separator} from 'sopix/formComps/Separator'
import {Link} from 'sopix/router/link'
import {Push} from 'sopix/formComps/Push'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {Bar} from 'sopix/formComps/Bar'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useAlert} from 'sopix/alert/use-alert'
import {useNotSavedAlert} from 'sopix/alert/NonSavedChanges'
import {observer} from 'mobx-react'

export const ButtonId = {
    PREV: 'prev',
    NEXT: 'next',
    DELETE: 'delete',
    CANCEL: 'cancel',
    SAVE: 'save',
} 

const useStyles = makeStyles(() => ({
    title: {
        marginLeft: 10,
        whiteSpace: 'nowrap',
    },
}))


export const FormToolbar = observer(
/**
 *
 * @param {FormPageState} formPage
 * @param absolute
 * @returns {JSX.Element}
 * @constructor
 */
({formPage, absolute}) => {
    
    const cls = useStyles()

    const [alert, withAlert] = useAlert()
    const [notSavedAlert, withNotSavedAlert] = useNotSavedAlert()

    function handleNext(){
        withNotSavedAlert(
            formPage.next,
            formPage.dirty
        )
    }

    function handlePrevious(){
        withNotSavedAlert(
            formPage.previous,
            formPage.dirty
        )
    }

    function handleRevert(){
        withAlert(
            formPage.revert,
            "Revertir cambios?",
            "Revertir"
        )
    }

    function handleDelete(){
        withAlert(
            formPage.delete,
            formPage.deleteAlertTitle,
            formPage.deleteAlertButton,
            formPage.deleteAlertBody,
        )        
    }
    
    if (!formPage) return null
    
    return (
        <Bar absoluteRight absolute={absolute}>
            {!formPage.listPath ? null : <>
                <HintIconButton Icon={icons.Back} title="Volver a la lista" component={Link}
                                      href={formPage.listPath} replace={false}/>
                <Separator/>
            </>}
            
            {!formPage.previous ? null : (
                <>
                    <HintIconButton
                        Icon={icons.Left}
                        title="Anterior"
                        disabled={!formPage.prevEnabled}
                        onClick={handlePrevious}
                    />
                    <HintIconButton
                    Icon={icons.Right}
                    title="Siguiente"
                    disabled={!formPage.nextEnabled}
                    onClick={handleNext}
                    />
                    <Separator />
                </>
            )}
            {!formPage.recordName ? null :
                <Typography className={cls.title} component="h1" variant="h6">{formPage.recordName}</Typography>
            }

            <Push />
            
            {!formPage.delete ? null : (
                <HintIconButton 
                    Icon={icons.Delete} 
                    title="Eliminar" 
                    onClick={handleDelete}
                />
            )}
            <HintIconButton
                Icon={icons.Revert} 
                title="Revertir cambios"
                disabled={!formPage.dirty}
                onClick={handleRevert}
            />
            <HintIconButton
                Icon={icons.Save}
                title="Guardar"
                disabled={!formPage.dirty}
                onClick={formPage.save}
            />
            {alert}
            {notSavedAlert}
        </Bar>
    )
})
