import {AproposicionFld, AproposicionTable} from 'sop/db/AproposicionTable'
import {Aproposicion} from 'sop/db/Aproposicion'
import {observer} from 'mobx-react'
import {Fields} from 'sopix/formComps/Fields'
import React from 'react'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {TipoTareaTable} from 'sop/db/TipoTareaTable'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Field} from 'sopix/form/editors/Field'
import {AproposicionEdt} from 'sop/db/AproposicionEditors'
import {sopIcons} from 'sop/sop-icons'
import {boundMethod} from 'autobind-decorator'
import {Tarea} from 'sop/db/Tarea'
import {AproFormPiece} from 'sop/componentes/aprobacion/aproFormPiece'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {NumberField} from 'sopix/form/editors/NumberField'
import {TipoTarea} from 'sop/db/TipoTarea'
import {TrabajoClase} from 'sop/db-config/row-consts'
import {Permiso} from 'sop/db/Permiso'
import {usePiece} from 'sopix/piece/use-piece'

export class AproposicionFormPiece extends AproFormPiece{

    permisoAdmin = Permiso.aprobarCambioPosicion
    permisoSolicitar = Permiso.solicitarCambioPosicion

    constructor(world) {

        const FIELDS = [
            ...AproposicionTable.regularFields,
            [AproposicionFld.aprobacion, [AprobacionFld.estado]],
            [AproposicionFld.trabajo,[
                ...TareaTable.regularFields,
                [TareaFld.tipo, TipoTareaTable.regularFields],
            ]],
        ]

        const TABLE = AproposicionTable
        super(world,
            FIELDS,
            {
                name: 'Solicitud de cambio de posición',
                icon: sopIcons.TrabajoPosicion,
                table: TABLE,
                idField: Aproposicion.idAproposicion,
                saveMutation: 'SaveAproposicion',
            },
        )
        this._actionMutation = new SimpleMutation(graphqlUrl, 'AproposicionAction', ['success'])
    }

    @boundMethod
    async _createRow() {
        const nfld = getFieldExtractor(this.newRowFields) 
        const clase = nfld(Aproposicion.trabajo, Tarea.tipo, TipoTarea.clase)
        this.newRowFields[Aproposicion.posicionOriginal] = clase === TrabajoClase.ESTUDIO
            ? nfld(Aproposicion.trabajo, Tarea.ordenEstudio)
            : nfld(Aproposicion.trabajo, Tarea.ordenValoracion)
        return await super._createRow()
    }

    @boundMethod
    _getTitle(empty) {
        return this.name
    }
}




export const AproposicionForm = observer(
    /**
     * @param {AproposicionFormPiece} form
     */
    ({form}) => {

        usePiece(form)

        return (
            <PieceFrame piece={form} renderPiece={()=> {

                const H = form.fieldHelper
                const fld = form.getField

                return(
                    <Fields margin>
                        <NumberField {...H.obj(AproposicionEdt.posicionOriginal)} />
                        <NumberField {...H.edt(AproposicionEdt.nuevaPosicion)} />
                        <Field {...H.edt(AproposicionEdt.justificacion)} multiline rows={10} width={1000} />
                    </Fields>
                )
            }} />
        )
    })
