import {AppStage} from 'sopix/app/app-utils'

export const shortAppName = "SOP"
export const appName = "Polytherm SOP"
export const appVersion = '2.41'

const sopStagingHost = 'devsop2.polytherm.es'
const sopProductionHost = 'sop2.polytherm.es'

function getSopAppStage(){
    if (process.env.NODE_ENV === 'development'){
        return AppStage.DEVEL
    } else if (window.location.hostname === sopStagingHost) {
        return AppStage.STAGING
    } else if (window.location.hostname === sopProductionHost) {
        return AppStage.PRODUCTION
    } else {
        return AppStage.UNKNOWN
    }
}

export const sopAppStage = getSopAppStage()


