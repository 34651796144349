 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UserIds} from './User'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {BoolEditor, DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserRoleIds} from './UserRole'

export const UserEdt = Object.freeze({
    roleUsuarioObj : new LookupEditor(UserIds.roleUsuarioObj, Tables.UserRole, UserRoleIds.idRole, 'role', {sort: 'ID_ROLE_ASC'}),
    id : new SimpleEditor(UserIds.id, 11, 11),
    email : new SimpleEditor(UserIds.email, 40, 16),
    password : new SimpleEditor(UserIds.password, 40, 16),
    username : new SimpleEditor(UserIds.username, 15, 5),
    nombreCompleto : new SimpleEditor(UserIds.nombreCompleto, 15, 10),
    idAutor : new SimpleEditor(UserIds.idAutor, 11, 11),
    fechaCreacion : new DateTimeEditor(UserIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(UserIds.fechaCreacion, 11, 11),
    zona : new SimpleEditor(UserIds.zona, 40, 16),
    movil : new SimpleEditor(UserIds.movil, 20, 10),
    ausente : new SimpleEditor(UserIds.ausente, 11, 11),
    mueveCotizaciones : new SimpleEditor(UserIds.mueveCotizaciones, 11, 11),
    ausenteDesde : new DateEditor(UserIds.ausenteDesde, 11, 11),
    ausenteHasta : new DateEditor(UserIds.ausenteHasta, 11, 11),
    idModificacion : new SimpleEditor(UserIds.idModificacion, 11, 11),
    fechaModificacion : new DateTimeEditor(UserIds.fechaModificacion, 16, 16),
    fechaModificacionOnlyDate : new DateEditor(UserIds.fechaModificacion, 11, 11),
    inactivo : new SimpleEditor(UserIds.inactivo, 11, 11),
    horas : new SimpleEditor(UserIds.horas, 11, 11),
    resetKey : new SimpleEditor(UserIds.resetKey, 32, 13),
    apiKey : new SimpleEditor(UserIds.apiKey, 40, 15),
    odooCambiado : new SimpleEditor(UserIds.odooCambiado, 11, 11),
    totpSecret : new SimpleEditor(UserIds.totpSecret, 40, 16),
    totpRequerido : new SimpleEditor(UserIds.totpRequerido, 11, 11),
    resetKeyTime : new DateTimeEditor(UserIds.resetKeyTime, 16, 16),
    resetKeyTimeOnlyDate : new DateEditor(UserIds.resetKeyTime, 11, 11),
    loginTryTime : new DateTimeEditor(UserIds.loginTryTime, 16, 16),
    loginTryTimeOnlyDate : new DateEditor(UserIds.loginTryTime, 11, 11),
    loginTryCount : new SimpleEditor(UserIds.loginTryCount, 11, 11),
    aclReadOnly : new BoolEditor(UserIds.aclReadOnly, 13, 13),
})

tableEditors[Tables.User] = UserEdt
