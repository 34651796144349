 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PermisoIds} from './Permiso'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const PermisoEdt = Object.freeze({
    idPermiso : new SimpleEditor(PermisoIds.idPermiso, 11, 11),
    permiso : new SimpleEditor(PermisoIds.permiso, 40, 16),
    principal : new SimpleEditor(PermisoIds.principal, 11, 11),
    verObras : new SimpleEditor(PermisoIds.verObras, 11, 11),
    modificarObras : new SimpleEditor(PermisoIds.modificarObras, 11, 11),
    crearObras : new SimpleEditor(PermisoIds.crearObras, 11, 11),
    eliminarObras : new SimpleEditor(PermisoIds.eliminarObras, 11, 11),
    verSolicitantes : new SimpleEditor(PermisoIds.verSolicitantes, 11, 11),
    modificarSolicitantes : new SimpleEditor(PermisoIds.modificarSolicitantes, 11, 11),
    agregarSolicitantes : new SimpleEditor(PermisoIds.agregarSolicitantes, 11, 11),
    quitarSolicitantes : new SimpleEditor(PermisoIds.quitarSolicitantes, 11, 11),
    verNotas : new SimpleEditor(PermisoIds.verNotas, 11, 11),
    modificarNotas : new SimpleEditor(PermisoIds.modificarNotas, 11, 11),
    accederNotasPrivadas : new SimpleEditor(PermisoIds.accederNotasPrivadas, 11, 11),
    modificarNotasDeOtros : new SimpleEditor(PermisoIds.modificarNotasDeOtros, 11, 11),
    eliminarNotasDeOtros : new SimpleEditor(PermisoIds.eliminarNotasDeOtros, 11, 11),
    verObrasRelacionadas : new SimpleEditor(PermisoIds.verObrasRelacionadas, 11, 11),
    modificarObrasRelacionadas : new SimpleEditor(PermisoIds.modificarObrasRelacionadas, 11, 11),
    verRepresentantesObra : new SimpleEditor(PermisoIds.verRepresentantesObra, 11, 11),
    modificarRepresentantesObra : new SimpleEditor(PermisoIds.modificarRepresentantesObra, 11, 11),
    verUsuariosMinimo : new SimpleEditor(PermisoIds.verUsuariosMinimo, 11, 11),
    verUsuarios : new SimpleEditor(PermisoIds.verUsuarios, 11, 11),
    verUsuariosAdmin : new SimpleEditor(PermisoIds.verUsuariosAdmin, 11, 11),
    modificarUsuarios : new SimpleEditor(PermisoIds.modificarUsuarios, 11, 11),
    modificarUsuariosAdmin : new SimpleEditor(PermisoIds.modificarUsuariosAdmin, 11, 11),
    permisosRepresentantes : new SimpleEditor(PermisoIds.permisosRepresentantes, 11, 11),
    crearUsuarios : new SimpleEditor(PermisoIds.crearUsuarios, 11, 11),
    paginaUsuarios : new SimpleEditor(PermisoIds.paginaUsuarios, 11, 11),
    verListas : new SimpleEditor(PermisoIds.verListas, 11, 11),
    verCotizaciones : new SimpleEditor(PermisoIds.verCotizaciones, 11, 11),
    modificarCotizaciones : new SimpleEditor(PermisoIds.modificarCotizaciones, 11, 11),
    modificarCotizacionesTecnico : new SimpleEditor(PermisoIds.modificarCotizacionesTecnico, 11, 11),
    crearCotizaciones : new SimpleEditor(PermisoIds.crearCotizaciones, 11, 11),
    eliminarCotizaciones : new SimpleEditor(PermisoIds.eliminarCotizaciones, 11, 11),
    verTareas : new SimpleEditor(PermisoIds.verTareas, 11, 11),
    modificarTareas : new SimpleEditor(PermisoIds.modificarTareas, 11, 11),
    crearTareas : new SimpleEditor(PermisoIds.crearTareas, 11, 11),
    eliminarTareas : new SimpleEditor(PermisoIds.eliminarTareas, 11, 11),
    paginaTrabajos : new SimpleEditor(PermisoIds.paginaTrabajos, 11, 11),
    verCuentas : new SimpleEditor(PermisoIds.verCuentas, 11, 11),
    modificarCuentas : new SimpleEditor(PermisoIds.modificarCuentas, 11, 11),
    crearCuentas : new SimpleEditor(PermisoIds.crearCuentas, 11, 11),
    eliminarCuentas : new SimpleEditor(PermisoIds.eliminarCuentas, 11, 11),
    paginaCuentas : new SimpleEditor(PermisoIds.paginaCuentas, 11, 11),
    verPedidosSage : new SimpleEditor(PermisoIds.verPedidosSage, 11, 11),
    verCuentasSage : new SimpleEditor(PermisoIds.verCuentasSage, 11, 11),
    verPermisos : new SimpleEditor(PermisoIds.verPermisos, 11, 11),
    modificarPermisos : new SimpleEditor(PermisoIds.modificarPermisos, 11, 11),
    verConfiguracion : new SimpleEditor(PermisoIds.verConfiguracion, 11, 11),
    modificarConfiguracion : new SimpleEditor(PermisoIds.modificarConfiguracion, 11, 11),
    paginaConfiguracion : new SimpleEditor(PermisoIds.paginaConfiguracion, 11, 11),
    verLiquidaciones : new SimpleEditor(PermisoIds.verLiquidaciones, 11, 11),
    modificarLiquidaciones : new SimpleEditor(PermisoIds.modificarLiquidaciones, 11, 11),
    verAvisos : new SimpleEditor(PermisoIds.verAvisos, 11, 11),
    verAprobaciones : new SimpleEditor(PermisoIds.verAprobaciones, 11, 11),
    verEjecuciones : new SimpleEditor(PermisoIds.verEjecuciones, 11, 11),
    modificarEjecuciones : new SimpleEditor(PermisoIds.modificarEjecuciones, 11, 11),
    verDocumentos : new SimpleEditor(PermisoIds.verDocumentos, 11, 11),
    verArchivosDocumento : new SimpleEditor(PermisoIds.verArchivosDocumento, 11, 11),
    modificarDocumentos : new SimpleEditor(PermisoIds.modificarDocumentos, 11, 11),
    eliminarUsuarios : new SimpleEditor(PermisoIds.eliminarUsuarios, 11, 11),
    repartirValoraciones : new SimpleEditor(PermisoIds.repartirValoraciones, 11, 11),
    solicitarCambioEstado : new SimpleEditor(PermisoIds.solicitarCambioEstado, 11, 11),
    solicitarCambioPosicion : new SimpleEditor(PermisoIds.solicitarCambioPosicion, 11, 11),
    solicitarEnvioPlanos : new SimpleEditor(PermisoIds.solicitarEnvioPlanos, 11, 11),
    solicitarLiquidacion : new SimpleEditor(PermisoIds.solicitarLiquidacion, 11, 11),
    verM2Efectivos : new SimpleEditor(PermisoIds.verM2Efectivos, 11, 11),
    modificarM2Efectivos : new SimpleEditor(PermisoIds.modificarM2Efectivos, 11, 11),
    aprobarCambioEstado : new SimpleEditor(PermisoIds.aprobarCambioEstado, 11, 11),
    aprobarCambioPosicion : new SimpleEditor(PermisoIds.aprobarCambioPosicion, 11, 11),
    aprobarLiquidacion : new SimpleEditor(PermisoIds.aprobarLiquidacion, 11, 11),
    aprobarLiquidacionManual : new SimpleEditor(PermisoIds.aprobarLiquidacionManual, 11, 11),
    aprobarEnvioPlanos : new SimpleEditor(PermisoIds.aprobarEnvioPlanos, 11, 11),
    cambiarResponsableCotizacion : new SimpleEditor(PermisoIds.cambiarResponsableCotizacion, 11, 11),
    enviarPlanos : new SimpleEditor(PermisoIds.enviarPlanos, 11, 11),
    cambiarSeguimientoPlanos : new SimpleEditor(PermisoIds.cambiarSeguimientoPlanos, 11, 11),
})

tableEditors[Tables.Permiso] = PermisoEdt
