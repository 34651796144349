import {getILikeFromKeyphrase, getILikeFromKeyword} from 'sopix/db/graphql-utils'
import {Tarea} from 'sop/db/Tarea'
import {TrabajoEstadoFinalizado, TrabajoTiposValoracion, TrabajoTipoTarea, UserRoles} from 'sop/db-config/row-consts'
import {userAuth} from 'sopix/session/userAuth'
import {TrabajoListMode} from 'sop/componentes/trabajo/trabajo-defs'
import {Representante} from 'sop/db/Representante'


export const TrabajoFilter = Object.freeze({
    MODE : 'MODE',
    
    RESPONSABLE_TRABAJOS : 'RESPONSABLE_TRABA',
    RESPONSABLE_COTIZACIONES : 'RESPONSABLE_COTIZ',
    ESTADO_TRABAJOS : 'ESTADO_TRABA',
    ESTADO_COTIZACIONES : 'ESTADO_COTIZ',
    
    YO : -1,
    ASIGNADO : -2,
    SIN_ASIGNAR : -3,
    
    ESTADO_ACTIVOS : -1,
    ESTADO_PLANIFICADOR : -2,
    ESTADO_ASIGNADOS : -3,
    ESTADO_FINALIZADOS : -4,
    
    TIPO_VALO : 'TIPO_VALO',
    TIPO_ESTU : 'TIPO_ESTU',
    TIPO_TRABA : 'TIPO_TRABA',
    
    TIPO_TAREAS : -1,
    TIPO_COTIZACIONES : -2,
    TIPO_ESTUDIOS : -3,
    TIPO_VALORACIONES : -4,
    
    BUSCAR : 'BUSCAR',
    PLANOS : 'planos',
    PROVINCIA : 'PROVINCIA',
    CUENTA: 'cuenta',
    
    PLANOS_TIPO_CUENTA: 'planosIdTipoCuenta',
    PLANOS_COMERCIAL: 'planosIdComercial',
    PLANOS_PROVINCIA: 'planosCpCuentaILike',
    PLANOS_COMERCIAL_YO : -1,
    
})

/**
 *
 * @param fields
 * @returns {Object<String>}
 */
export const trabajoFilter = (fields) => {
    const result = {}
    const and = []
    const mode = fields[TrabajoFilter.MODE]

    const filterEstudios = () => result[`${Tarea.idTipo}NotIn`] = [TrabajoTipoTarea,...TrabajoTiposValoracion]

    const filterValoraciones = () => result[`${Tarea.idTipo}In`] = TrabajoTiposValoracion

    switch (mode){
        case TrabajoListMode.ESTUDIOS:
            const tipoEstu = fields[TrabajoFilter.TIPO_ESTU] 
            if (tipoEstu) {
                result[Tarea.idTipo] = tipoEstu
            } else {
                filterEstudios()
            }
            break;
        case TrabajoListMode.VALORACIONES:
            const tipoValo = fields[TrabajoFilter.TIPO_VALO]
            if (tipoValo) {
                result[Tarea.idTipo] = tipoValo
            } else {
                filterValoraciones()
            }
            break;
        default:
            const tipoTraba = fields[TrabajoFilter.TIPO_TRABA]
            if (tipoTraba) {
                if (tipoTraba === TrabajoFilter.TIPO_TAREAS){
                    result[Tarea.idTipo] = TrabajoTipoTarea
                } else if (tipoTraba === TrabajoFilter.TIPO_COTIZACIONES){
                    result[`${Tarea.idTipo}NotIn`] = [TrabajoTipoTarea]
                } else if (tipoTraba === TrabajoFilter.TIPO_ESTUDIOS){
                    filterEstudios() 
                } else if (tipoTraba === TrabajoFilter.TIPO_VALORACIONES) {
                    filterValoraciones()
                } else {
                    result[Tarea.idTipo] = tipoTraba
                }
            }
    }

    function filter_responsable(value){
        if (value === TrabajoFilter.ASIGNADO) {
            result[`${Tarea.idResponsable}Gte`] = 0
        } else if (value === TrabajoFilter.SIN_ASIGNAR) {
            result[`${Tarea.idResponsable}IsNull`] = true
        } else {
            result[Tarea.idResponsable] = value === TrabajoFilter.YO ? userAuth.userId : value
        }
    }

    function filter_estado(value){
        if (value === TrabajoFilter.ESTADO_ACTIVOS) {
            result[`${Tarea.idEstado}NotIn`] = TrabajoEstadoFinalizado
        } else if (value === TrabajoFilter.ESTADO_FINALIZADOS) {
            result[`${Tarea.idEstado}In`] = TrabajoEstadoFinalizado
        } else if (value === TrabajoFilter.ESTADO_ASIGNADOS) {
            and.push(...[
                {[`${Tarea.idResponsable}Gte`]: 0},
                {[`${Tarea.idEstado}NotIn`]: TrabajoEstadoFinalizado},
            ])
        } else if (value === TrabajoFilter.ESTADO_PLANIFICADOR) {
            result['or'] = [
                {[`${Tarea.ordenValoracion}Gt`]: 0},
                {[`${Tarea.ordenEstudio}Gt`]: 0},
            ]
        } else {
            result[Tarea.idEstado] = value
        }
    }
    
    for (let [key, value] of Object.entries(fields)){
        if (value === null || value === '') {
            continue
        }

        switch (key) {
            case TrabajoFilter.BUSCAR:
                /*
                const words = value.split(' ')
                const descripcionEntries = words.map(word => (
                    {[`${Tarea.descripcion}Ilike`]: getILikeFromKeyword(word)}
                ))
                and.push({
                    or: [
                        {[`${Tarea.ref}Ilike`]: getILikeFromKeyword(value)},
                        {[`${Tarea.refCliente}Ilike`]: getILikeFromKeyword(value)},
                        {[TrabajoFilter.CUENTA]: getILikeFromKeyword(value)},
                        {and: descripcionEntries},
                        getILikeFromKeyphrase('obraNombreILike', value),
                    ]
                })
                */
                and.push(...getILikeFromKeyphrase(value, [
                    `${Tarea.ref}Ilike`,
                    `${Tarea.refCliente}Ilike`,
                    TrabajoFilter.CUENTA,
                    `${Tarea.descripcion}Ilike`,
                    'obraNombreILike',
                ]))
                
                break

            case TrabajoFilter.RESPONSABLE_TRABAJOS:
                if (mode === TrabajoListMode.TRABAJOS) {
                    filter_responsable(value)               
                }
                break
            case TrabajoFilter.RESPONSABLE_COTIZACIONES:
                if (mode !== TrabajoListMode.TRABAJOS) {
                    filter_responsable(value)
                }
                break
            case TrabajoFilter.ESTADO_TRABAJOS:
                if (mode === TrabajoListMode.TRABAJOS) {
                    filter_estado(value)
                }
                break
            case TrabajoFilter.ESTADO_COTIZACIONES:
                if (mode !== TrabajoListMode.TRABAJOS) {
                    filter_estado(value)
                }
                break
            case TrabajoFilter.PLANOS:
                if (value) {
                    result[TrabajoFilter.PLANOS] = 1
                
                    const idComercial = fields[TrabajoFilter.PLANOS_COMERCIAL] 
                    if (idComercial) {
                        result[TrabajoFilter.PLANOS_COMERCIAL] = idComercial !== TrabajoFilter.PLANOS_COMERCIAL_YO
                            ? idComercial : userAuth.userId
                    }
                    
                    const idTipo = fields[TrabajoFilter.PLANOS_TIPO_CUENTA]
                    if (idTipo) {
                        result[TrabajoFilter.PLANOS_TIPO_CUENTA] = idTipo
                    }
                    
                    const cpCuenta = fields[TrabajoFilter.PLANOS_PROVINCIA]
                    if (cpCuenta) {
                        result[TrabajoFilter.PLANOS_PROVINCIA] = `${cpCuenta}%`
                    }
                }
                
                break
            case TrabajoFilter.PROVINCIA:
                result['cpProvincia'] = value
                break
            default:
        }
    }
    
    if (userAuth.role === UserRoles.REPRESENTANTE) {
        result[Representante.idRepresentante] = userAuth.userId
    }
    
    if (and.length) {
        result.and = and
    }
    
    return result
}