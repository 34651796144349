 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PermisoTransposedIds} from './PermisoTransposed'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const PermisoTransposedEdt = Object.freeze({
    idPermiso : new SimpleEditor(PermisoTransposedIds.idPermiso, 11, 11),
    permiso : new SimpleEditor(PermisoTransposedIds.permiso, 26, 10),
})

tableEditors[Tables.PermisoTransposed] = PermisoTransposedEdt
