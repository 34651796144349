import React from 'react'
import {userAuth} from 'sopix/session/userAuth'
import {observer} from 'mobx-react'

export const AccessControl = observer(
({permissions, loginComponent, render}) => {
    const authorized = userAuth.isAllowed(permissions) 
    return authorized
            ? render()
            : loginComponent
})
