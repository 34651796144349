 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {LiqtecmesIds} from './Liqtecmes'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbFloat, DbIntId, DbObject, DbObjectId} from 'sopix/db/dbField'
import {AnualMesIds} from './AnualMes'
import {UserIds} from './User'

export const LiqtecmesTable = new DbTable(
    Tables.Liqtecmes,
    'Liqtecmeses',
    graphqlUrl,
    'Liqtecmes',
    [LiqtecmesIds.idLiqtecmes],
    [LiqtecmesIds.idLiqtecmes, LiqtecmesIds.idMes, LiqtecmesIds.idTecnico, LiqtecmesIds.m2],
    [LiqtecmesIds.mes, LiqtecmesIds.tecnico],
    [],
    ['verLiquidaciones'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const LiqtecmesFld = Object.freeze({
    mes : new DbObject(Tables.Liqtecmes, LiqtecmesIds.mes, LiqtecmesIds.idMes, 'Mes'),
    tecnico : new DbObject(Tables.Liqtecmes, LiqtecmesIds.tecnico, LiqtecmesIds.idTecnico, 'Tecnico'),
    idLiqtecmes : new DbIntId(Tables.Liqtecmes, LiqtecmesIds.idLiqtecmes, 'Id liqtecmes'),
    idMes : new DbObjectId(Tables.Liqtecmes, LiqtecmesIds.idMes, 'Id mes', {foreignTableId: Tables.AnualMes, foreignKeyFieldId: AnualMesIds.idMes}),
    idTecnico : new DbObjectId(Tables.Liqtecmes, LiqtecmesIds.idTecnico, 'Id tecnico', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    m2 : new DbFloat(Tables.Liqtecmes, LiqtecmesIds.m2, 'M2'),
})

registerTableFields(Tables.Liqtecmes, LiqtecmesFld) 
