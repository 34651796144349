import React, {useEffect} from 'react'
import {ModalFrame} from 'sopix/modal/ModalFrame'
import {WithComplexProgress} from 'sopix/progress/WithComplexProgress'
import {ModalContent} from 'sopix/modal/ModalContent'
import {observer} from 'mobx-react'
import {ErrorList} from 'sopix/errors/ErrorList'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {getLoggers} from 'sopix/log'
import {runInAction} from 'mobx'
import {useDelayedClose} from 'sopix/modal-state/modal-utils'

const {log} = getLoggers('ModalFormFrameOld')

export const ModalFormFrameOld = observer(
/**
 * 
 * @param {FormState} formState
 * @param children
 * @param onClose
 * @param modalFrameProps
 * @returns {JSX.Element|null}
 */
function({formState, children, onClose, ...modalFrameProps}) {

    log(()=>`render: ${formState}`)
    
    useEffect(() => {
        formState && formState.initialize()
    }, [formState])

    const [open, closeHandler] = useDelayedClose(formState, onClose)
    
    if (formState === undefined) return null

    async function saveHandler(){
        if (await formState.save()) {
            runInAction(()=>closeHandler(true))
        }
    }

    function cancelHandler(){
        closeHandler(false)
    }
    
    return (
        <ModalFrame
                title={formState.title}
                Icon={formState.icon}
                open={open}
                onClose={cancelHandler}
                {...modalFrameProps}
        >
            <ErrorList errorManager={formState.errorManager} />
            <WithComplexProgress
                initialized={formState.initialized}
                locked={formState.locked}
                onReplay={() => formState.initialize()}
            >
                <ModalContent>
                    {children}
                </ModalContent>
                <DialogActions>
                    <Button
                        onClick={cancelHandler}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={!formState.dirty}
                        onClick={saveHandler}
                        color="primary"
                        defaultChecked={true}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </WithComplexProgress>
        </ModalFrame>
    )
})
