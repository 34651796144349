 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const ProvinciaIds = Object.freeze({
    idProvincia: Symbol('idProvincia'),
    cp: Symbol('cp'),
    provincia: Symbol('provincia'),
})

export const Provincia = Object.freeze({
    idProvincia: 'idProvincia',
    cp: 'cp',
    provincia: 'provincia',
})
