 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const PlanoEstadoIds = Object.freeze({
    documentos: Symbol('documentos'),
    idEstado: Symbol('idEstado'),
    estado: Symbol('estado'),
})

export const PlanoEstado = Object.freeze({
    documentos: 'documentos',
    idEstado: 'idEstado',
    estado: 'estado',
})
