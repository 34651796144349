import React, {forwardRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {BlRowCell} from 'sopix/boundless-table/BlRowCell'
import {Field} from 'sopix/form/editors/Field'
import {action} from 'mobx'
import {observer} from 'mobx-react'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {find} from 'lodash-es'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'


const useStyles = makeStyles(theme => ({
    cellContainer: {
        borderBottom: 'none',
    },
    cell: {
        alignItems: 'stretch',
    },
    
}))

const Row = forwardRef(({index, data, ...props}, ref) => {
    return <BlRow ref={ref} index={index} data={data} {...props} />
})


function Cell({children, rowId, ...otherProps}) {

    const cls = useStyles()

    return (
        <BlRowCell className={cls.cell} classes={{container: cls.cellContainer}} rowId={rowId} {...otherProps}>
            {children}
        </BlRowCell>
    )
}



const Content = observer(
/**
 * 
 * @param {ConfigProcedenciasState} procedencias
 * @param content
 * @param index
 * @param id
 * @param rowId
 * @returns {JSX.Element}
 */
({procedencias, content, index, id, rowId}) => {

    const row = find(procedencias.rows, {key: rowId})
    
    if (id === 'actions') {
        return !userAuth.isAllowed(Permiso.modificarConfiguracion) ? null : (
            <HintIconButton 
                fit
                Icon={icons.Delete}
                title={'Eliminar'}
                onClick={() => procedencias.delete(rowId)}
            />
        )
    }

    function handleChange(value) {
        row[id] = value
    }

    const common = {
        value: row[id],
        onChange: action(handleChange),
        disabled: !userAuth.isAllowed(Permiso.modificarConfiguracion),
    }

    let field
    switch(id){
        default:
            field = <Field {...common} />
    }
    return field
})



export const ConfigProcedencias = observer(
/**
 *
 * @param {ConfigProcedenciasState} procedencias
 * @param direction
 * @returns {JSX.Element}
 * @constructor
 */
({procedencias, direction="rowReverse"}) => {
    
    function renderCellContent(props){
        return <Content procedencias={procedencias} {...props} />
    }

    return (
        <BlTable
            renderCellContent={renderCellContent}
            Row={Row}
            RowCell={Cell}
            dense={true}
            columns={procedencias.columns}
            data={procedencias.rows}
            keyColumnId="key"
            order={procedencias.order}
        />
    )
})
