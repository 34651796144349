import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {sopIcons} from 'sop/sop-icons'
import {DireccionFld, DireccionTable} from 'sop/db/DireccionTable'
import {Direccion} from 'sop/db/Direccion'
import {FormPiece} from 'sopix/pieces/formPiece'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {DireccionEdt} from 'sop/db/DireccionEditors'
import {CuentaFld} from 'sop/db/CuentaTable'
import {CuentaEdt} from 'sop/db/CuentaEditors'


export class DireccionFormPiece extends FormPiece{
    constructor(world) {
        const FIELDS = [
            ...DireccionTable.regularFields,
            [DireccionFld.cuenta, [CuentaFld.cuenta]],
        ]

        super(world,
            FIELDS,
            {
                name: 'Dirección',
                icon: sopIcons.Direccion,
                table: DireccionTable,
                idField: Direccion.idDireccion,
                displayField: Direccion.direccion,
                saveMutation: 'SaveDireccion',
                deleteMutation: 'DeleteDireccion',
            },
        )
    }

}


export const DireccionForm = getPieceContainer('form', observer(
    /**
     * @param {DireccionFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField
        
        return (
            <Fields>
                {!fld(Direccion.cuenta) ? null :
                    <>
                        <Field {...H.obj(Direccion.cuenta, CuentaEdt.cuenta)} />
                    </>
                }
                <Field {...H.edt(DireccionEdt.direccion)} autoFocus/>
                <Field {...H.edt(DireccionEdt.localidad)} />
                <Field {...H.edt(DireccionEdt.provincia)} />
                <Field {...H.edt(DireccionEdt.cp)} />
                <Field {...H.edt(DireccionEdt.pais)} />
            </Fields>
        )
}))
