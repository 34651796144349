import {Aprobacion} from 'sop/db/Aprobacion'
import {AprobacionEstado, AprobacionFilter} from 'sop/componentes/aprobacion/aprobacion-consts'
import {userAuth} from 'sopix/session/userAuth'
import {getILikeFromKeyword} from 'sopix/db/graphql-utils'


// MISTERIO: Si llamo al método xxxListFilter el breakpoint no se detiene!!!
/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export function aprobacionFilter(searchFields) {
    const and = []
    const result = {}

    const estado = searchFields[AprobacionFilter.ESTADO] 
    if (estado) {
        if (estado === AprobacionFilter.ESTADO_PENDIENTES) {
            and.push({or: [
                {[`${Aprobacion.estado}`]: AprobacionEstado.NECESITA_CORRECCION},
                {[`${Aprobacion.estado}IsNull`]: true},
            ]})
        } else if (estado === AprobacionFilter.ESTADO_FINALIZADOS) {
            and.push({[`${Aprobacion.estado}In`]: [AprobacionEstado.DENEGADA, AprobacionEstado.APROBADA]})            
        }
    }

    const buscar = searchFields[AprobacionFilter.BUSCAR]
    if (buscar) {
        const words = buscar.split(' ')
        const textoEntries = words.map(word => ({[`${Aprobacion.solicitud}Ilike`]: getILikeFromKeyword(word)}))
        and.push({and: textoEntries})
    }
    
    const or = []
    const userId = userAuth.userId
    
    const solicitante = searchFields[AprobacionFilter.SOLICITANTE]
    if (solicitante) {
        or.push({[`${Aprobacion.idSolicitante}`]: userId})
    }

    const aprobador = searchFields[AprobacionFilter.APROBADOR]
    if (aprobador) {
        or.push({and: [
            {[`${Aprobacion.estado}IsNull`]: true},
            {[AprobacionFilter.APROBADOR]: userId},
        ]})
    }
    
    if (or.length) {
        and.push({or: or})
    }

    if (and.length) {
        return {and: and}
    } else {
        return undefined
    }
}