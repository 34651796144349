import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {BoolField} from 'sopix/form/editors/BoolField'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {usePiece} from 'sopix/piece/use-piece'


/**
 * 
 * @param {SearchPiece} search
 * @returns {JSX.Element}
 * @constructor
 */
export const AvisoSearch = ({search}) => {

    usePiece(search)

    return (
        <PieceFrame piece={search} renderPiece={()=> {

            const fld = search.fieldHelper
            const man = search.fieldManager

            return (
                <>
                    <Field manager={man} name="buscar" label="buscar" autoFocus/>
                    <BoolField manager={man} name="pendientes" label="Sólo pendientes" />
                    <Push />
                </>
            )

        }} />
    )
}