 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageDomicilioIds} from './SageDomicilio'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const SageDomicilioEdt = Object.freeze({
    codigoEmpresa : new SimpleEditor(SageDomicilioIds.codigoEmpresa, 11, 11),
    codigoCliente : new SimpleEditor(SageDomicilioIds.codigoCliente, 15, 10),
    tipoDomicilio : new SimpleEditor(SageDomicilioIds.tipoDomicilio, 1, 1),
    numeroDomicilio : new SimpleEditor(SageDomicilioIds.numeroDomicilio, 11, 11),
    domicilio : new SimpleEditor(SageDomicilioIds.domicilio, 40, 16),
    municipio : new SimpleEditor(SageDomicilioIds.municipio, 25, 10),
    codigoPostal : new SimpleEditor(SageDomicilioIds.codigoPostal, 8, 8),
    provincia : new SimpleEditor(SageDomicilioIds.provincia, 20, 10),
    nacion : new SimpleEditor(SageDomicilioIds.nacion, 25, 10),
})

tableEditors[Tables.SageDomicilio] = SageDomicilioEdt
