import React from 'react'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {Liquidacion} from 'sop/db/Liquidacion'
import {LiquidacionCol} from 'sop/db/LiquidacionColumns'
import {LiquidacionFld, LiquidacionTable} from 'sop/db/LiquidacionTable'
import {UserCol} from 'sop/db/UserColumns'
import {TareaCol} from 'sop/db/TareaColumns'
import {UserFld} from 'sop/db/UserTable'
import {TareaFld} from 'sop/db/TareaTable'
import {liquidacionFilter} from 'sop/componentes/liquidacion/liquidacionFilter'
import {User} from 'sop/db/User'
import {Tarea} from 'sop/db/Tarea'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'


class LiquidacionPainter extends TablePainter{
    
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds}) {
        switch(id){
            case Liquidacion.responsable:
                return fld(User.nombreCompleto)
            case Liquidacion.tarea:
                return fld(Tarea.ref)
            default:
                return formatter(value)
        }
    }
    
    _fixColumn(col, id, dbCol) {
        switch(id){
            case Liquidacion.responsable:
                return LiquidacionFld.responsable.title
            default:
                super._fixColumn(col, id, dbCol)
        }
    }
}

export class LiquidacionListPiece extends TablePiece{

    constructor(world) {

        const TABLE = LiquidacionTable
        const ID_FIELD = Liquidacion.idLiquidacion
        const DEFAULT_FILTER = {}
        const DEFAULT_ORDER = [new OrderEntry(Liquidacion.fecha, OrderDirection.DESC)]

        const COLUMNS = [
            LiquidacionCol.fechaOnlyDate,
            [Liquidacion.responsable, UserCol.nombreCompleto],
            LiquidacionCol.m2Efectivos,
            [Liquidacion.tarea, TareaCol.ref],
            LiquidacionCol.descripcion,
        ]
        
        const FIELDS = [
            LiquidacionFld.idLiquidacion,
            LiquidacionFld.fecha,
            LiquidacionFld.fechaCreacion,
            LiquidacionFld.idResponsable,
            LiquidacionFld.m2Efectivos,
            LiquidacionFld.descripcion,
            [LiquidacionFld.responsable, [UserFld.nombreCompleto]],
            [LiquidacionFld.tarea, [TareaFld.idTarea, TareaFld.ref, TareaFld.descripcion]],
            [LiquidacionFld.autor, [UserFld.nombreCompleto]],
        ]
        
        const painter = new LiquidacionPainter(world, COLUMNS)
        
        const filtrator = graphqlFiltratorBuilder(world, TABLE, FIELDS, {
            graphqlFilterCalculator: liquidacionFilter, defaultFilter: DEFAULT_FILTER, defaultOrder: DEFAULT_ORDER})
        
        const selection = new RowSelection(world, filtrator, ID_FIELD) 
        
        super(world, ID_FIELD, filtrator, painter, selection)
    }

}

