 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const ConfiguracionIds = Object.freeze({
    idConfig: Symbol('idConfig'),
    ratioRepeticion: Symbol('ratioRepeticion'),
    ratioSimetria: Symbol('ratioSimetria'),
    asumirEstudioPropio: Symbol('asumirEstudioPropio'),
})

export const Configuracion = Object.freeze({
    idConfig: 'idConfig',
    ratioRepeticion: 'ratioRepeticion',
    ratioSimetria: 'ratioSimetria',
    asumirEstudioPropio: 'asumirEstudioPropio',
})
