 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaSistemaIds} from './TareaSistema'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt} from 'sopix/db/dbField'

export const TareaSistemaTable = new DbTable(
    Tables.TareaSistema,
    'TareaSistemas',
    graphqlUrl,
    'TareaSistema',
    [TareaSistemaIds.None],
    [TareaSistemaIds.idTarea, TareaSistemaIds.idSistema],
    [],
    [],
    undefined,
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const TareaSistemaFld = Object.freeze({
    idTarea : new DbInt(Tables.TareaSistema, TareaSistemaIds.idTarea, 'Id tarea'),
    idSistema : new DbInt(Tables.TareaSistema, TareaSistemaIds.idSistema, 'Id sistema'),
})

registerTableFields(Tables.TareaSistema, TareaSistemaFld) 
