import {boundMethod} from 'autobind-decorator'
import {getLoggers} from 'sopix/log'
import {arrayToGraphQLText, dictToGraphQLText} from 'sopix/db/graphql-utils'

const {log} = getLoggers('graphqlMutation')

export class GraphqlMutation{

    constructor(apiEndPoint, mutationName, response) {
        this.apiEndPoint = apiEndPoint
        this.mutationName = mutationName
        this.response = response
    }
    
    @boundMethod
    async query(params){
        
        const paramText = !params ? '' : `(${dictToGraphQLText(params)})`
        
        const resposeText = !this.response ? undefined : arrayToGraphQLText(this.response,
            {arrayBrackets: '{}', dictBrackets: '', stringify: false, dictPairSeparator: ''})

        const responseTextEx = !resposeText ? '{success}' : `{${resposeText}}`
        
        const query = `mutation{${this.mutationName}${paramText}${responseTextEx}}`

        log(()=>`Mutation: ${query}`)

        // AWAIT
        const result = await this.apiEndPoint.query(query)

        if (result.errors) {
            throw new Error(result.errors[0].message)
        }

        return result['data'][this.mutationName]
    }
        
}