import {tables} from 'sopix/db/dbTable'

export const tableFields = {}

function register(tableId, fields){
    tableFields[tableId] = fields
}

export class TableFields{
    constructor(tableId) {
        register(tableId, this)
    }
}

export function registerTableFields(tableId, fields) {
    for (let [fieldName, field] of Object.entries(fields)) {
        field._init(fieldName)
    }
    tableFields[tableId] = fields
    
    tables[tableId].fields = fields
}
