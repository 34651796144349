import React from 'react'
import {Typography} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    field: {
        marginLeft: 8,
        
        '&:first-child': {
            marginLeft: 0,
        }
    },
    
    line: {
        display: 'block',
    },
    
    singleLine: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}))


export function TextSpan({className, children, ...props}) {
    const cls = useStyles()
    return <span className={clsx(cls.field, className)} {...props}>
        {children}
    </span>
}

export function Text({className, children, ...props}) {
    const cls = useStyles()
    return <Typography className={clsx(cls.field, className)} component="span" {...props}>
        {children}
    </Typography>
}

export function MiniText({className, children, ...props}) {
    const cls = useStyles()
    return <Typography className={clsx(cls.field, className)} color="textSecondary" component="span" {...props}>
        {children}
    </Typography>
}

export function MicroText({className, children, ...props}) {
    const cls = useStyles()
    return <Typography className={clsx(cls.field, className)} color="textSecondary" variant="caption" component="span" {...props}>
        {children}
    </Typography>
}

export function MicroTextLine({className, children, singleLine, ...props}) {
    const cls = useStyles()
    return <Typography
        className={clsx(cls.line,singleLine && cls.singleLine)}
        color="textSecondary"
        variant="caption"
        component="span"
        {...props}
    >
        {children}
    </Typography>
}


export function BodyText({className, children, ...props}) {
    return <Typography className={className} variant="body2" {...props}>
        {children}
    </Typography>
}
