 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AlmacenIds} from './Almacen'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const AlmacenCol = Object.freeze({
    idAlmacen : new SimpleColumn(AlmacenIds.idAlmacen, 11, 11, Align.RIGHT),
    almacen : new SimpleColumn(AlmacenIds.almacen, 15, 10, Align.LEFT),
})

tableColumns[Tables.Almacen] = AlmacenCol
