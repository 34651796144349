 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SolicitudIds} from './Solicitud'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {CuentaIds} from './Cuenta'
import {DireccionIds} from './Direccion'
import {ContactoIds} from './Contacto'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const SolicitudEdt = Object.freeze({
    cuenta : new LookupEditor(SolicitudIds.cuenta, Tables.Cuenta, CuentaIds.idCuenta, 'cuenta', {sort: 'ID_CUENTA_ASC'}),
    direccion : new LookupEditor(SolicitudIds.direccion, Tables.Direccion, DireccionIds.idDireccion, 'direccion', {sort: 'ID_DIRECCION_ASC'}),
    contacto : new LookupEditor(SolicitudIds.contacto, Tables.Contacto, ContactoIds.idContacto, 'nombre', {sort: 'ID_CONTACTO_ASC'}),
    obra : new LookupEditor(SolicitudIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    autor : new LookupEditor(SolicitudIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idSolicitud : new SimpleEditor(SolicitudIds.idSolicitud, 11, 11),
    fechaCreacion : new DateTimeEditor(SolicitudIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(SolicitudIds.fechaCreacion, 11, 11),
    tipoCuenta : new SimpleEditor(SolicitudIds.tipoCuenta, 30, 12),
    idTipo : new SimpleEditor(SolicitudIds.idTipo, 11, 11),
    odooCambiado : new SimpleEditor(SolicitudIds.odooCambiado, 11, 11),
    odooDireccionTecnicoId : new SimpleEditor(SolicitudIds.odooDireccionTecnicoId, 11, 11),
    odooCuentaCotizaciones : new SimpleEditor(SolicitudIds.odooCuentaCotizaciones, 11, 11),
})

tableEditors[Tables.Solicitud] = SolicitudEdt
