import {useAlert} from 'sopix/alert/use-alert'

const TITLE = "¿Descartar cambios?"
const TEXT = "Hay cambios sin guardar. Si continua se perderán."
const ACCEPT_LABEL = "Descartar cambios"


export function useNotSavedAlert(){
    const [alert, withAlert] = useAlert()
    
    function withNotSavedAlert(func, showAlert = true){
        return withAlert(func, TITLE, ACCEPT_LABEL, TEXT, showAlert)
    }
    
    return [alert, withNotSavedAlert]
}