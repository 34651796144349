 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageClienteIds} from './SageCliente'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, SimpleEditor} from 'sopix/db/dbEditor'

export const SageClienteEdt = Object.freeze({
    codigoEmpresa : new SimpleEditor(SageClienteIds.codigoEmpresa, 11, 11),
    codigoCliente : new SimpleEditor(SageClienteIds.codigoCliente, 15, 10),
    cifDni : new SimpleEditor(SageClienteIds.cifDni, 13, 10),
    fechaAlta : new DateTimeEditor(SageClienteIds.fechaAlta, 16, 16),
    fechaAltaOnlyDate : new DateEditor(SageClienteIds.fechaAlta, 11, 11),
    razonSocial : new SimpleEditor(SageClienteIds.razonSocial, 40, 16),
    nombre : new SimpleEditor(SageClienteIds.nombre, 35, 14),
    domicilio : new SimpleEditor(SageClienteIds.domicilio, 40, 16),
    municipio : new SimpleEditor(SageClienteIds.municipio, 25, 10),
    provincia : new SimpleEditor(SageClienteIds.provincia, 20, 10),
    actividad : new SimpleEditor(SageClienteIds.actividad, 40, 16),
    tipoCliente : new SimpleEditor(SageClienteIds.tipoCliente, 10, 10),
    codigoPostal : new SimpleEditor(SageClienteIds.codigoPostal, 8, 8),
    nacion : new SimpleEditor(SageClienteIds.nacion, 25, 10),
    telefono : new SimpleEditor(SageClienteIds.telefono, 15, 10),
    comentarios_ : new SimpleEditor(SageClienteIds.comentarios_, 40, 16),
})

tableEditors[Tables.SageCliente] = SageClienteEdt
