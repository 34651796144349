 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {RelacionadaIds} from './Relacionada'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const RelacionadaCol = Object.freeze({
    obra : new LookupColumn(RelacionadaIds.obra, Tables.Obra, 'cod'),
    relacionada : new LookupColumn(RelacionadaIds.relacionada, Tables.Obra, 'cod'),
    autor : new LookupColumn(RelacionadaIds.autor, Tables.User, 'nombre_completo'),
    idRelacion : new SimpleColumn(RelacionadaIds.idRelacion, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(RelacionadaIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(RelacionadaIds.fechaCreacion, 11, 11, Align.LEFT),
})

tableColumns[Tables.Relacionada] = RelacionadaCol
