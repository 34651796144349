 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaEstadoIds} from './TareaEstado'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const TareaEstadoTable = new DbTable(
    Tables.TareaEstado,
    'TareaEstados',
    graphqlUrl,
    'TareaEstado',
    [TareaEstadoIds.idEstado],
    [TareaEstadoIds.idEstado, TareaEstadoIds.estado, TareaEstadoIds.finalizado, TareaEstadoIds.verbo],
    [],
    [TareaEstadoIds.tareas, TareaEstadoIds.aproestasEstadoOriginal, TareaEstadoIds.aproestasEstado],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const TareaEstadoFld = Object.freeze({
    tareas : new DbObjectList(Tables.TareaEstado, TareaEstadoIds.tareas, Tables.Tarea),
    aproestasEstadoOriginal : new DbObjectList(Tables.TareaEstado, TareaEstadoIds.aproestasEstadoOriginal, Tables.Aproesta),
    aproestasEstado : new DbObjectList(Tables.TareaEstado, TareaEstadoIds.aproestasEstado, Tables.Aproesta),
    idEstado : new DbIntId(Tables.TareaEstado, TareaEstadoIds.idEstado, 'Id estado'),
    estado : new DbText(Tables.TareaEstado, TareaEstadoIds.estado, 'Estado', 20),
    finalizado : new DbInt(Tables.TareaEstado, TareaEstadoIds.finalizado, 'Finalizado'),
    verbo : new DbText(Tables.TareaEstado, TareaEstadoIds.verbo, 'Verbo', 30),
})

registerTableFields(Tables.TareaEstado, TareaEstadoFld) 
