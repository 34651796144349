 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproestaIds} from './Aproesta'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {TareaEstadoIds} from './TareaEstado'

export const AproestaEdt = Object.freeze({
    estadoOriginal : new LookupEditor(AproestaIds.estadoOriginal, Tables.TareaEstado, TareaEstadoIds.idEstado, 'estado', {sort: 'ID_ESTADO_ASC'}),
    estado : new LookupEditor(AproestaIds.estado, Tables.TareaEstado, TareaEstadoIds.idEstado, 'estado', {sort: 'ID_ESTADO_ASC'}),
    idAproesta : new SimpleEditor(AproestaIds.idAproesta, 11, 11),
    justificacion : new SimpleEditor(AproestaIds.justificacion, 40, 16),
})

tableEditors[Tables.Aproesta] = AproestaEdt
