import {boundMethod} from 'autobind-decorator'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {FORBIDDEN_ROW_CONFIG} from 'sopix/db/graphql-defs'

export class RowNotFound extends Error{
}

export class RowFetcher {
    
    /** @member {GraphqlEndPoint} endPoint */
    endPoint
    
    /**
     * 
     * @param {DbTable} table
     * @param {DbField[]} fields
     */
    constructor(table, fields) {
        this.table = table
        
        this.fields = fields
        
        this.endPoint = new TableGraphql(this.table)
    }

    @boundMethod
    async fetch(filters, {exceptionIfNotFound=true} = {}){
        const {rows} = await this.endPoint.query(this.fields, {first: 1}, filters)
        
        if (rows.length === 0) {
            if (exceptionIfNotFound) {
                return Promise.reject(new RowNotFound(`No existe el registro`))
            } else {
                return undefined
            }
        }
        return rows[0]
    }
}
