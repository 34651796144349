 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AlmacenIds} from './Almacen'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const AlmacenTable = new DbTable(
    Tables.Almacen,
    'Almacenes',
    graphqlUrl,
    'Almacen',
    [AlmacenIds.idAlmacen],
    [AlmacenIds.idAlmacen, AlmacenIds.almacen],
    [],
    [AlmacenIds.cuentas],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const AlmacenFld = Object.freeze({
    cuentas : new DbObjectList(Tables.Almacen, AlmacenIds.cuentas, Tables.Cuenta),
    idAlmacen : new DbIntId(Tables.Almacen, AlmacenIds.idAlmacen, 'Id almacen'),
    almacen : new DbText(Tables.Almacen, AlmacenIds.almacen, 'Almacen', 50),
})

registerTableFields(Tables.Almacen, AlmacenFld) 
