 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DocumentoIds} from './Documento'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {SolicitudIds} from './Solicitud'
import {TareaIds} from './Tarea'
import {PlanoEstadoIds} from './PlanoEstado'
import {AproplanoIds} from './Aproplano'

export const DocumentoTable = new DbTable(
    Tables.Documento,
    'Documentos',
    graphqlUrl,
    'Documento',
    [DocumentoIds.idDocumento],
    [DocumentoIds.idDocumento, DocumentoIds.idAutor, DocumentoIds.idObra, DocumentoIds.idSolicitud, DocumentoIds.idCotizacion, DocumentoIds.documento, DocumentoIds.fechaCreacion, DocumentoIds.borrado, DocumentoIds.fechaModificacion, DocumentoIds.idModificacion, DocumentoIds.idPlanosEstado, DocumentoIds.idUsuarioPlanos, DocumentoIds.planosFechaEnvio, DocumentoIds.idAproplano],
    [DocumentoIds.autor, DocumentoIds.obra, DocumentoIds.solicitud, DocumentoIds.cotizacion, DocumentoIds.planosEstado, DocumentoIds.usuarioPlanos, DocumentoIds.aproplano],
    [DocumentoIds.aprobaciones, DocumentoIds.aproplanos, DocumentoIds.files],
    ['verDocumentos'],
    ['modificarDocumentos'],
    undefined,
    undefined,
    [],
    [],
)


export const DocumentoFld = Object.freeze({
    aprobaciones : new DbObjectList(Tables.Documento, DocumentoIds.aprobaciones, Tables.Aprobacion),
    aproplanos : new DbObjectList(Tables.Documento, DocumentoIds.aproplanos, Tables.Aproplano),
    files : new DbObjectList(Tables.Documento, DocumentoIds.files, Tables.File),
    autor : new DbObject(Tables.Documento, DocumentoIds.autor, DocumentoIds.idAutor, 'Autor'),
    obra : new DbObject(Tables.Documento, DocumentoIds.obra, DocumentoIds.idObra, 'Obra'),
    solicitud : new DbObject(Tables.Documento, DocumentoIds.solicitud, DocumentoIds.idSolicitud, 'Solicitud'),
    cotizacion : new DbObject(Tables.Documento, DocumentoIds.cotizacion, DocumentoIds.idCotizacion, 'Cotizacion'),
    planosEstado : new DbObject(Tables.Documento, DocumentoIds.planosEstado, DocumentoIds.idPlanosEstado, 'Envío de planos'),
    usuarioPlanos : new DbObject(Tables.Documento, DocumentoIds.usuarioPlanos, DocumentoIds.idUsuarioPlanos, 'Enviados por'),
    aproplano : new DbObject(Tables.Documento, DocumentoIds.aproplano, DocumentoIds.idAproplano, 'Aproplano'),
    idDocumento : new DbIntId(Tables.Documento, DocumentoIds.idDocumento, 'Id documento'),
    idAutor : new DbObjectId(Tables.Documento, DocumentoIds.idAutor, 'Creado por', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idObra : new DbObjectId(Tables.Documento, DocumentoIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idSolicitud : new DbObjectId(Tables.Documento, DocumentoIds.idSolicitud, 'Id solicitud', {foreignTableId: Tables.Solicitud, foreignKeyFieldId: SolicitudIds.idSolicitud}),
    idCotizacion : new DbObjectId(Tables.Documento, DocumentoIds.idCotizacion, 'Id cotizacion', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    documento : new DbText(Tables.Documento, DocumentoIds.documento, 'Documento', 200),
    fechaCreacion : new DbDateTime(Tables.Documento, DocumentoIds.fechaCreacion, 'Fecha creacion'),
    borrado : new DbInt(Tables.Documento, DocumentoIds.borrado, 'Borrado'),
    fechaModificacion : new DbDateTime(Tables.Documento, DocumentoIds.fechaModificacion, 'Fecha modificacion'),
    idModificacion : new DbInt(Tables.Documento, DocumentoIds.idModificacion, 'Id modificacion'),
    idPlanosEstado : new DbObjectId(Tables.Documento, DocumentoIds.idPlanosEstado, 'Id planos estado', {foreignTableId: Tables.PlanoEstado, foreignKeyFieldId: PlanoEstadoIds.idEstado}),
    idUsuarioPlanos : new DbObjectId(Tables.Documento, DocumentoIds.idUsuarioPlanos, 'Id usuario planos', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    planosFechaEnvio : new DbDateTime(Tables.Documento, DocumentoIds.planosFechaEnvio, 'Fecha de envío'),
    idAproplano : new DbObjectId(Tables.Documento, DocumentoIds.idAproplano, 'Id aproplano', {foreignTableId: Tables.Aproplano, foreignKeyFieldId: AproplanoIds.idAproplano}),
})

registerTableFields(Tables.Documento, DocumentoFld) 
