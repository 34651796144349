import {ModalContent} from 'sopix/modal/ModalContent'
import {Fields} from 'sopix/formComps/Fields'
import DialogActions from '@material-ui/core/DialogActions'
import {Push} from 'sopix/formComps/Push'
import Button from '@material-ui/core/Button'
import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {userAuth} from 'sopix/session/userAuth'
import {DateField, PickerType} from 'sopix/form/editors/DateField'

/**
 * @param {SesionPageState} sesionPage
 */
export function SesionForm({sesionPage}){
    
    return (
        <>
            <ModalContent>
                <Fields>
                    <Field label="Usuario" value={userAuth.username} disabled />
                    <Field label="Permisos" value={userAuth.pemissionGroupsAsText} disabled />
                    <DateField label="Caducidad de la sesión" value={userAuth.expiry} pickerType={PickerType.DATE_TIME}
                               disabled />
                </Fields>
            </ModalContent>
            <DialogActions>
                <Push/>
                <Button
                    onClick={sesionPage.logout}
                    color="primary"
                    defaultChecked={true}
                >
                    Cerrar sesión
                </Button>
            </DialogActions>
        </>    
    )
}