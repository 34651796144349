import React from 'react'
import {observer} from 'mobx-react'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {DisplayStatus} from 'sopix/piece/piece-enums'
import {askAlert} from 'sopix/alert/asyncAlert'
import {ALERT_DESCARTAR, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'
import {canDeleteTable, canWriteTable} from 'sopix/db-auth/db-auth-utils'

export const FormControl = observer(
    /**
     * 
     * @param {FormPiece} form
     * @returns {JSX.Element}
     */
    ({form, skipSavePermissions, onRefresh}) => {

        if (!form) {
            return null
        }
        
        const invalid = form.displayStatus !== DisplayStatus.VALID
        
        const table = form._saveMutation && form._saveMutation.dbTable
        
        const canSave = skipSavePermissions || (table && canWriteTable(table))
        const canDelete = (form.canDelete && (!table || canDeleteTable(table))) 
        
        async function handleRevert(){
            if (ALERT_DESCARTAR === await askAlert(form.revertAlert())){
                form.__revert()
            }
        }

        async function handleDelete(){
            if (ALERT_ELIMINAR === await askAlert(form.deleteAlert())){
                form.__delete()
            }
        }

        return (
            <>
                {!canDelete ? null : (
                    <HintIconButton
                        Icon={icons.Delete}
                        title="Eliminar"
                        onClick={handleDelete}
                        disabled={invalid}
                    />
                )}
                {!canSave ? null : (
                    <>
                        <HintIconButton
                            Icon={icons.Cancel}
                            title="Revertir cambios"
                            disabled={!form.dirty || invalid}
                            onClick={handleRevert}
                        />
                        <HintIconButton
                            Icon={icons.Save}
                            title={"Guardar"}
                            disabled={!form.dirty || invalid}
                            onClick={form.__save}
                            color={form.dirty ? 'secondary' : undefined}
                        />
                        <HintIconButton
                            Icon={icons.Reload}
                            title={"Actualizar"}
                            onClick={onRefresh || form.__refresh}
                        />
                    </>
                )}
            </>
        )

})
