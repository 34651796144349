import {sopIcons} from 'sop/sop-icons'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {Tarea} from 'sop/db/Tarea'
import {observer} from 'mobx-react'
import {TipoTarea} from 'sop/db/TipoTarea'
import {Fields} from 'sopix/formComps/Fields'
import {NumberField} from 'sopix/form/editors/NumberField'
import {Field} from 'sopix/form/editors/Field'
import {TipoTareaEdt} from 'sop/db/TipoTareaEditors'
import React from 'react'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {TareaEdt} from 'sop/db/TareaEditors'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {boundMethod} from 'autobind-decorator'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {ListFetcher, ListQueryEntry} from 'sopix/db-access/listFetcher'
import {ConfiguracionTable} from 'sop/db/ConfiguracionTable'
import {Configuracion} from 'sop/db/Configuracion'
import {graphqlUrl} from 'sop/db-config/db'
import {TipoTareaTable} from 'sop/db/TipoTareaTable'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {Permiso} from 'sop/db/Permiso'
import {DateField} from 'sopix/form/editors/DateField'
import {usePiece} from 'sopix/piece/use-piece'
import {AproFormPiece} from 'sop/componentes/aprobacion/aproFormPiece'

export class CalculadoraFormPiece extends AproFormPiece{

    permisoAdmin = Permiso.aprobarLiquidacion
    sePuedeCorregir = true
    sePuedeDenegar = false

    _listFetcher = new ListFetcher([
        new ListQueryEntry(undefined, ConfiguracionTable.regularFields,
            {[Configuracion.idConfig]: 1})
    ])
    
    constructor(world) {

        const FIELDS = [
            ...TareaTable.regularFields,
            [TareaFld.tipo, TipoTareaTable.regularFields],
            [TareaFld.aprobacion, [AprobacionFld.estado]],
        ]
        
        const TABLE = TareaTable
        super(world,
            FIELDS,
            {
                name: 'Cálculo de m² efectivos',
                icon: sopIcons.Liquidacion,
                table: TABLE,
                idField: Tarea.idTarea,
                saveMutation: 'SaveTrabajo',
            },
        )
        
        this._actionMutation = new SimpleMutation(graphqlUrl, 'TrabajoAction', ['success'])
    }

    async _saveRow(fields) {
        fields[Tarea.m2Efectivos] = this.calcM2Efectivos() 
        return await super._saveRow(fields)
    }

    calcM2Efectivos() {
        
        const fld = this.getField
        const tip = getFieldExtractor(fld(Tarea.tipo))
        const config = this.lists[ConfiguracionTable.graphqlId].rows[0]

        return (fld(Tarea.m2Completos) || 0) * tip(TipoTarea.ratio) / 100 +
            (fld(Tarea.m2Simetricos) || 0) * (config[Configuracion.ratioSimetria] || 0) / 100 +
            (fld(Tarea.m2Repetidos) || 0) * (config[Configuracion.ratioRepeticion] || 0) / 100 +
            (fld(Tarea.m2Fijos) || 0)

    }
    
    @boundMethod
    async _init() {
        this.setLists(await this._listFetcher.fetch())
    }

    @boundMethod
    _getTitle(empty) {
        return this.name
    }
}




export const CalculadoraForm = observer(
    /**
     * @param {CalculadoraFormPiece} form
     */
    ({form, editable, calculadora}) => {

        usePiece(form)

        return (
            <PieceFrame piece={form} renderPiece={()=> {

                const H = form.fieldHelper
                const fld = form.getField
                
                const estado = fld(Tarea.idEstado)
                
                if (editable !== undefined) {
                    form.fieldManager.setReadOnly(!editable)
                }
                
                const m2Efectivos = form.calcM2Efectivos()

                return(
                    <>
                        <Fields>
                            <NumberField {...H.edt(TareaEdt.m2Completos)} suffix=" m²" />
                            <NumberField {...H.edt(TareaEdt.m2Repetidos)} suffix=" m²" />
                            <NumberField {...H.edt(TareaEdt.m2Simetricos)} suffix=" m²" />
                        </Fields>
                        <Fields>
                            <NumberField {...H.edt(TareaEdt.m2Fijos)} suffix=" m²" />
                            <Field {...H.edt(TareaEdt.detalle)} />
                        </Fields>
                        <Fields>
                            <NumberField {...H.obj(Tarea.tipo, TipoTareaEdt.m2)} suffix=" m²" />
                            <Field {...H.obj(Tarea.tipo, TipoTareaEdt.detalle)} />

                            <Field {...H.edt(TareaEdt.justificacion)} multiline rows={10} width={1000} />
                            <NumberField {...H.obj(TareaEdt.tipo, TipoTareaEdt.m2)} label="M² efectivos"
                                         value={m2Efectivos} suffix=" m²" />
                            <NumberField {...H.obj(TareaEdt.m2Totales)} label="M² totales" suffix=" m²" />
                            {!fld(Tarea.fechaFinalizada) || calculadora ? null:
                                <DateField disabled {...H.edt(TareaEdt.fechaFinalizadaOnlyDate)} />}
                        </Fields>
                    </>
                )
            }} />
        )
})

