import {action, observable} from 'mobx'
import {getGraphqlError} from 'sopix/db/graphql-utils'
import {CancelError} from 'sopix/db/apiEndPoint'

export class ErrorEntry{
    constructor(manager, message) {
        this.manager = manager
        this.message = message
    }
}


export class ErrorStore{
    
    @observable.shallow
    /** @type {ErrorEntry[]} */
    errors = []

    @observable.shallow
    failedStates = new Map()
    
    
    @action
    add(error){
        if (error instanceof CancelError) return false
        
        const message = getGraphqlError(error, false)
        
        this.errors = [new ErrorEntry(this, message), ...this.errors]
        //snackbarNotifications.notify(message, 'error')
        
        if (process.env.NODE_ENV === 'development') {
            //throw error
        }
    }
    
    @action.bound
    clear(){
        this.errors = []
        this.failedStates = new Map()
    }

    
    @action.bound
    retry(){
        const failedStates = this.failedStates
        this.clear()

        for (let state of failedStates.values()) {
            state.retryFunc(state)
        }
    }

    /**
     * @param {FuncState} state
     */
    addState(state){
        this.deleteState(state.obj, state.stateId)
        this.failedStates.set(state.func, state)
    }
    
    hasFailedStates(/** FuncState */ obj = undefined){
        for (let state of this.failedStates.values()){
            if (!obj || obj === state.obj) {
                return true
            }
        }
        return false
    }
    
    getFailedStates(obj){
        const result = new Map()
        for (/** @type FuncState */ let state of this.failedStates.values()){
            if (state.obj === obj) {
                result.set(state.stateId, state)
            }
        }
        return result
    }
    
    clearFailedStates(obj) {
        for (/** @type FuncState */ let state of this.failedStates){
            if (state.obj === obj) {
                this.failedStates.delete(state.func)
            }
        }
    }

    deleteState(obj, stateId) {
        for (/** @type FuncState */ let state of this.failedStates.values()){
            if (state.obj === obj && state.stateId === stateId) {
                this.failedStates.delete(state.func)
            }
        }
    }
}

export const errorStore = new ErrorStore()