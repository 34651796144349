import {Filtrator} from 'sopix/filtrator/filtrator'
import {action, runInAction} from 'mobx'
import {boundMethod} from 'autobind-decorator'
import {ObserverHub} from 'sopix/utils/observers'


export class StaticFiltrator extends Filtrator{
    
    _unorderedCache = []

    onFilter = new ObserverHub()
    
    /** @type {DataSource} */
    source
    
    /** @param {PieceWorld} world */
    /** @param {DataSource} dataSource */
    constructor(world, dataSource, {defaultFilter, defaultOrder} = {}) {
        super(world, {defaultOrder, defaultFilter})
        this.source = dataSource
    }

    @action.bound
    async _doFilter(filter) {
        await this._doFilterAndSort(filter, this._currentOrder)
    }

    @action.bound
    async _doSort(order) {
        this._setData(await this._sortData(this._unorderedCache, this._currentOrder))
    }

    @action.bound
    async _doFilterAndSort(filter, order) {
        this._unorderedCache = await this._filterData(this.source.data, filter)
        const data = await this._sortData(this._unorderedCache, order)
        runInAction(() => {
            this._setData(data)
        })
        this.onFilter.notify(filter)
    }
    
    @boundMethod
    _filterData(data, filter){
        return data
    }
    
    @boundMethod
    _sortData(data, order){
        return data
    }
}