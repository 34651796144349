import {useEffect} from 'react'

/**
 * @param {Piece} piece
 */
export function usePiece(piece){

    if (!piece) {
        throw new Error('Piece is undefined')
    }

    useEffect(() => {
        piece.__componentMounted().then()

        return () => {
            piece.__componentUnmounted().then()
        }
        
    }, [piece])
}