import {DataSource} from 'sopix/data-source/dataSource'
import {boundMethod} from 'autobind-decorator'
import {runInAction} from 'mobx'

export class DbSource extends DataSource {

    /** @type {GraphqlEndPoint} */
    _rowsQL

    _fieldDescriptors

    graphqlFilter
    _graphqlParams
    
    constructor(world, graphqlEndPoint, fieldDescriptors, 
                {defaultGraphqlFilter, defaultGraphqlParams} = {}) {
        super(world)
        this._rowsQL = graphqlEndPoint
        this._fieldDescriptors = fieldDescriptors
        this.graphqlFilter = defaultGraphqlFilter || {}
        this._graphqlParams = defaultGraphqlParams
    }

    _setGraphqlFilter(graphqlFilter) {
        this.graphqlFilter = graphqlFilter
    }

    @boundMethod
    async _doRefresh() {
        const result = await this._rowsQL.query(this._fieldDescriptors, this._graphqlParams, this.graphqlFilter)
        runInAction(()=>{
            this._setData(result.rows)
        })
    }
}