import {fields} from 'sopix/db/dbField'
import {tableEditors} from 'sopix/db/TableEditors'
import {tables} from 'sopix/db/dbTable'
import {ListQueryEntry} from 'sopix/db-access/listFetcher'
import {DbFieldView} from 'sopix/db/dbFieldView'


export class Editor extends DbFieldView{
}

export class SimpleEditor extends Editor{
    
}


export class DateEditor extends Editor{
}


export class DateTimeEditor extends Editor{
}


export class BoolEditor extends Editor{
}


export class LookupEditor extends Editor{
    constructor(fieldId, foreignTableId, foreignKeyFieldId, foreignEditorId, graphqlParams, {title} = {}) {
        super(fieldId)
        this.foreignTableId = foreignTableId
        this.foreignKeyFieldId = foreignKeyFieldId
        this.foreignEditorId = foreignEditorId
        this.graphqlParams = graphqlParams
        this._title = title
    }

    get foreignTable(){
        return tables[this.foreignTableId]
    }
    
    get foreignKeyField(){
        return fields[this.foreignKeyFieldId]
    }

    get foreignDisplayFieldId(){
        return this.foreignEditor.fieldId
    }

    get foreignDisplayField(){
        return this.foreignEditor.field
    }

    /**
     * @returns {Editor}
     */
    get foreignEditor(){
        return tableEditors[this.foreignTableId][this.foreignEditorId]
    }

    get maxWidth(){
        return this.foreignEditor.maxWidth
    }

    getListQueryEntry(listKey = undefined){
        return new ListQueryEntry(this.graphqlParams, [this.foreignDisplayField], listKey)
    }
}