 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TipologiaIds} from './Tipologia'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const TipologiaCol = Object.freeze({
    idTipologia : new SimpleColumn(TipologiaIds.idTipologia, 11, 11, Align.RIGHT),
    tipologia : new SimpleColumn(TipologiaIds.tipologia, 16, 10, Align.LEFT),
    orden : new SimpleColumn(TipologiaIds.orden, 11, 11, Align.RIGHT),
    subtipologias : new SimpleColumn(TipologiaIds.subtipologias, 40, 16, Align.LEFT),
})

tableColumns[Tables.Tipologia] = TipologiaCol
