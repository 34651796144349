 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const CuentaTipoIds = Object.freeze({
    cuentas: Symbol('cuentas'),
    idTipo: Symbol('idTipo'),
    tipo: Symbol('tipo'),
})

export const CuentaTipo = Object.freeze({
    cuentas: 'cuentas',
    idTipo: 'idTipo',
    tipo: 'tipo',
})
