 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PlanoEstadoIds} from './PlanoEstado'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const PlanoEstadoTable = new DbTable(
    Tables.PlanoEstado,
    'PlanosEstados',
    graphqlUrl,
    'PlanoEstado',
    [PlanoEstadoIds.idEstado],
    [PlanoEstadoIds.idEstado, PlanoEstadoIds.estado],
    [],
    [PlanoEstadoIds.documentos],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const PlanoEstadoFld = Object.freeze({
    documentos : new DbObjectList(Tables.PlanoEstado, PlanoEstadoIds.documentos, Tables.Documento),
    idEstado : new DbIntId(Tables.PlanoEstado, PlanoEstadoIds.idEstado, 'Id estado'),
    estado : new DbText(Tables.PlanoEstado, PlanoEstadoIds.estado, 'Estado', 100),
})

registerTableFields(Tables.PlanoEstado, PlanoEstadoFld) 
