import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker} from '@material-ui/pickers'
import React from 'react'
import Moment from 'moment'
import {FieldController} from 'sopix/form/FieldController'
import {getInputLabelProps, getInputProps, useFieldStyle} from 'sopix/form/field-style'
import {doNothing} from 'sopix/utils/misc'
import {Field} from 'sopix/form/editors/Field'

export const DATE_EXTRA_SIZE = 35
export const DATE_TIME_EXTRA_SIZE = 50

export const PickerType = Object.freeze({
    DATE: 'date',
    TIME: 'time',
    DATE_TIME: 'dateTime',
}) 

export const DateField = ({manager, value, dataType, name, width: _width, pickerType, disabled: disabledBase, 
                              format: formatProp, autoClear, nullValue=undefined, small, 
                              onChange, staticText, nullify, ...otherProps}) => {

    const disabled = disabledBase || (manager && manager.readOnly)
    
    const format = formatProp !== undefined ? formatProp : (
        pickerType === PickerType.DATE_TIME ? "dd/MM/yyyy hh:mm:ss"
            : pickerType === PickerType.TIME ? "hh:mm:ss" : "dd/MM/yyyy" 
    )

    const Picker =
        pickerType === PickerType.DATE_TIME ? KeyboardDateTimePicker 
            : pickerType === PickerType.TIME ? KeyboardTimePicker : KeyboardDatePicker

    const width = disabled ? _width : format.length * 15
        //_width + (pickerType === PickerType.DATE_TIME ? DATE_TIME_EXTRA_SIZE : DATE_EXTRA_SIZE)

    const fieldCls = useFieldStyle({width, small})
    
    if (nullify) {
        return null
    }

    function formatDate(date){
        let format
        if (pickerType === PickerType.DATE_TIME) {
            format = ''
        } else if (pickerType === PickerType.TIME) {
            format = 'h:mm:ss'
        } else {
            format = 'YYYY-MM-DD'
        }
        return Moment(date).startOf('day').format(format)
    }
    
    function handleChange(date, value, onChange){
        if (!isNaN(date)) {
            onChange && onChange(date === null 
                ? nullValue 
                : formatDate(date)
            )
        }
    }

    function handleChangeAutoClear(date, value, onChange){
        const newDate = isNaN(date) || date === null ? null : date
        if (newDate !== value) {
            onChange && onChange(newDate === null
                ? nullValue 
                : formatDate(newDate)
            )
        }
    }

    function render({onChange: ctlOnChange, onBlur: ctlOnBlur, value: ctlValue, error}) {
        if (staticText) {
            return <Field value={staticText} name={name} width={width} disabled={true} small={small} {...otherProps} />
        } else {
            return (
                <Picker
                    {...otherProps}
                    className={fieldCls.field}
                    autoOk
                    disabled={disabled}
                    KeyboardButtonProps={{
                        classes: {
                            disabled: fieldCls.pickerButtonDisabled
                        }
                    }}
                    error={error !== undefined}
                    helperText={error}
                    variant="inline"
                    format={format}
                    invalidDateMessage="Fecha inválida"
                    value={ctlValue === undefined || ctlValue === nullValue ? null : ctlValue}
                    onChange={date => autoClear
                        ? handleChangeAutoClear(date, ctlValue, ctlOnChange)
                        : handleChange(date, ctlValue, ctlOnChange)
                    }
                    inputProps={{onBlur: ctlOnBlur}}
                    InputLabelProps={getInputLabelProps(fieldCls, small)}
                    InputProps={getInputProps(fieldCls, small)}
                />
            )
        }
    }

    if (manager === undefined) {
        return render({value: value, onBlur: doNothing, onChange})
    
    } else {
        return (
            <FieldController
                fieldManager={manager}
                name={name}
                render={render}
            />
        )
    }
}
