 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproasignarIds} from './Aproasignar'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbObject, DbObjectId, DbText} from 'sopix/db/dbField'
import {AprobacionIds} from './Aprobacion'

export const AproasignarTable = new DbTable(
    Tables.Aproasignar,
    'Aproasignar',
    graphqlUrl,
    'Aproasignar',
    [AproasignarIds.idAproasignar],
    [AproasignarIds.idAproasignar, AproasignarIds.idAprobacion, AproasignarIds.excluidos, AproasignarIds.valoracionesPorTecnico, AproasignarIds.sorteadas, AproasignarIds.justificacion],
    [AproasignarIds.aprobacion],
    [],
    ['verAprobaciones'],
    ['repartirValoraciones'],
    undefined,
    undefined,
    [],
    [],
)


export const AproasignarFld = Object.freeze({
    aprobacion : new DbObject(Tables.Aproasignar, AproasignarIds.aprobacion, AproasignarIds.idAprobacion, 'Aprobacion'),
    idAproasignar : new DbIntId(Tables.Aproasignar, AproasignarIds.idAproasignar, 'Id aproasignar'),
    idAprobacion : new DbObjectId(Tables.Aproasignar, AproasignarIds.idAprobacion, 'Id aprobacion', {foreignTableId: Tables.Aprobacion, foreignKeyFieldId: AprobacionIds.idAprobacion}),
    excluidos : new DbText(Tables.Aproasignar, AproasignarIds.excluidos, 'Excluidos', 1000),
    valoracionesPorTecnico : new DbInt(Tables.Aproasignar, AproasignarIds.valoracionesPorTecnico, 'Valoraciones por tecnico'),
    sorteadas : new DbInt(Tables.Aproasignar, AproasignarIds.sorteadas, 'Sorteadas'),
    justificacion : new DbText(Tables.Aproasignar, AproasignarIds.justificacion, 'Justificacion', 1000),
})

registerTableFields(Tables.Aproasignar, AproasignarFld) 
