import {
    arrayToGraphQLText,
    dictToGraphQLText,
    excludeGraphqlErrors,
    getGraphqlFields,
    processGraphqlResult,
} from 'sopix/db/graphql-utils'
import {getLoggers} from 'sopix/log'
import {boundMethod} from 'autobind-decorator'
import {isString} from 'lodash-es'
import {graphqlConstructorOptions, graphqlOnError} from 'sopix/db/graphqlMiddleware'
import {apiEndPointOnError} from 'sopix/db/endPointMiddleware'

const {log, debug} = getLoggers('GraphqlEndPoint')

export class GraphqlEndPoint{

    /** @member {ApiEndPoint} apiEndPoint */
     apiEndPoint

    /**
     * 
     * @param queryField
     * @param {ApiEndPoint} apiEndPoint
     */
    constructor(queryField, apiEndPoint, {excludedErrors} = {}) {
        this.queryField = queryField
        this.apiEndPoint = apiEndPoint
        
        const middlewareOptions = graphqlConstructorOptions.get(this) || {}
        const options = {excludedErrors, ...middlewareOptions}
        
        this.excludedErrors = options.excludedErrors
    }

    /**
     * 
     * @param fieldDescriptors
     * @param {Object} params
     * @param {Object|string} filters
     * @returns {Promise<{page: *, rowHeights: *}>}
     */
    @boundMethod
    async query(fieldDescriptors, params = undefined, filters= undefined) {
        const filtersString = isString(filters) ? filters : dictToGraphQLText(filters)
        const paramsWithFilters = filters === undefined ? params : {...params, filters: `{${filtersString}}`}
        const paramList = paramsWithFilters ===  undefined ? '' 
            : `(${dictToGraphQLText(paramsWithFilters, {stringify: false})})`  //antes era paramsToText
        
        const graphqlFields = getGraphqlFields(fieldDescriptors)
        const fieldList = arrayToGraphQLText(graphqlFields,
            {stringify: false, dictPairSeparator: '', dictBrackets: '', arrayBrackets: '{}'})
        const query = `{${this.queryField}${paramList}{pageInfo{endCursor hasPreviousPage hasNextPage}edges{cursor node{${fieldList}}}}}`

        log(()=>`Query: ${query}`, `table:${this.queryField}`)

        // AWAIT
        debug(() => `Query START`)
        let result = await this.apiEndPoint.query(query)

        if (this.excludedErrors) {
            result = excludeGraphqlErrors(result, this.excludedErrors)    
        }
        
        if (result.errors) {
            const errorMessage = result.errors[0].message
            graphqlOnError.notify(errorMessage)
            throw new Error(errorMessage)
        }

        debug(()=>`Query COMPLETE`)

        const data = result['data'][this.queryField]
        const edges = data['edges']
        const page = data['pageInfo']
        
        const rows = processGraphqlResult(fieldDescriptors, edges)
        const cursors = edges.map(edge => edge['cursor'])

        return {page: page, rows: rows, cursors: cursors}
    }

    @boundMethod
    cancel(){
        this.apiEndPoint.cancelQueries()
    }    
}