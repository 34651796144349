import React, {useEffect} from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Field} from 'sopix/form/editors/Field'
import {observer} from 'mobx-react'
import {DateField} from 'sopix/form/editors/DateField'
import {AprobacionEdt} from 'sop/db/AprobacionEditors'
import {Aprobacion} from 'sop/db/Aprobacion'
import {UserEdt} from 'sop/db/UserEditors'
import {sopUrls} from 'sop/sop-pages'
import {TareaEdt} from 'sop/db/TareaEditors'
import {ObraEdt} from 'sop/db/ObraEditors'
import {FormPiece} from 'sopix/pieces/formPiece'
import {sopIcons} from 'sop/sop-icons'
import {AprobacionFld, AprobacionTable} from 'sop/db/AprobacionTable'
import {ObraFld} from 'sop/db/ObraTable'
import {TareaFld} from 'sop/db/TareaTable'
import {UserFld} from 'sop/db/UserTable'
import {AprobacionTipoFld} from 'sop/db/AprobacionTipoTable'
import {Fields} from 'sopix/formComps/Fields'
import {AproestaFld} from 'sop/db/AproestaTable'
import {AproSelector} from 'sop/componentes/aprobacion/AproSelector'
import {AproposicionFld} from 'sop/db/AproposicionTable'
import {AproasignarFld} from 'sop/db/AproasignarTable'
import {AproliqmanFld} from 'sop/db/AproliqmanTable'
import {AprobacionEstado} from 'sop/componentes/aprobacion/aprobacion-consts'
import {userAuth} from 'sopix/session/userAuth'
import {UserRoles} from 'sop/db-config/row-consts'
import {AproplanoFld} from 'sop/db/AproplanoTable'
import {usePiece} from 'sopix/piece/use-piece'

export class AprobacionFormPiece extends FormPiece{

    /** @type {AproSelectorObject} */
    apro
    
    /**
     * @param {AproSelectorObject} apro
     */
    constructor(world, apro) {
        const APROBACION_FIELDS = [
            ...AprobacionTable.regularFields,
            [AprobacionFld.obra, [ObraFld.idObra, ObraFld.cod, ObraFld.nombre]],
            [AprobacionFld.tarea, [TareaFld.idTarea, TareaFld.ref, TareaFld.descripcion]],
            [AprobacionFld.solicitante, [UserFld.id, UserFld.nombreCompleto, UserFld.username]],
            [AprobacionFld.tipo, [AprobacionTipoFld.idTipo, AprobacionTipoFld.tipo]],
            [AprobacionFld.aproestas, [AproestaFld.idAproesta]],
            [AprobacionFld.aproposiciones, [AproposicionFld.idAproposicion]],
            [AprobacionFld.tareas, [TareaFld.idTarea]],
            [AprobacionFld.aproasignar, [AproasignarFld.idAproasignar]],
            [AprobacionFld.aproliqmans, [AproliqmanFld.idAproliqman]],
            [AprobacionFld.aproplanos, [AproplanoFld.idAproplano]],
        ]

        super(world,
            APROBACION_FIELDS,
            {
                name: 'Aprobacion',
                icon: sopIcons.Aprobacion,
                table: AprobacionTable,
                idField: 'idAprobacion',
                displayField: 'idAprobacion',
                saveMutation: 'SaveAprobacion',
                deleteMutation: 'DeleteAprobacion',
            },
        )

        this.apro = apro
        this.fieldManager.setReadOnly(true)
    }

    async invalidate() {
        return await super.invalidate()
    }

    get editable(){
        const estado = this.getField(Aprobacion.estado)
        const idResponsable = this.getField(Aprobacion.idSolicitante)
        if (estado === AprobacionEstado.NECESITA_CORRECCION && idResponsable === userAuth.userId) {
            return true
        }
        
        if (userAuth.role === UserRoles.ADMINISTRADOR) {
            if (estado === AprobacionEstado.NECESITA_CORRECCION || estado === AprobacionEstado.PENDIENTE) {
                return true
            }
        }
        
        return false
    }
    
}


export const AprobacionForm = observer(
    /**
     * @param {AprobacionFormPiece} form
     */
    ({form, ...pieceProps}) => {

    usePiece(form)

    return (
        <PieceFrame {...pieceProps} piece={form} renderPiece={()=> {

            const H = form.fieldHelper
            const fld = form.getField

            const estado = fld(Aprobacion.estado)

            return (
                <>
                    <Fields>
                        <DateField disabled {...H.edt(AprobacionEdt.fechaCreacion)} />
                        {!fld(Aprobacion.tarea) ? null :
                            <>
                                <Field {...H.obj(AprobacionEdt.tarea, TareaEdt.ref)} 
                                       {...H.btn(AprobacionEdt.tarea, sopUrls.trabajo, sopIcons.Trabajo)} />
                                <Field {...H.obj(AprobacionEdt.tarea, TareaEdt.descripcion)} multiline rowsMax={5} />
                            </>
                        }
                        {!fld(Aprobacion.obra) ? null :
                            <Field {...H.obj(AprobacionEdt.obra, ObraEdt.nombre)}
                                   {...H.btn(AprobacionEdt.obra, sopUrls.obra, sopIcons.Obra)} />
                        }

                        {!fld(Aprobacion.solicitante) ? null :
                            <Field {...H.obj(AprobacionEdt.solicitante, UserEdt.nombreCompleto)}
                                   {...H.btn(AprobacionEdt.solicitante, sopUrls.usuario, sopIcons.Usuario)} />
                        }

                        {!fld(Aprobacion.solicitud) ? null :
                            <Field {...H.edt(AprobacionEdt.solicitud)} multiline width={1000} />
                        }
    
                        {estado === AprobacionEstado.APROBADA || !fld(Aprobacion.razonRechazo)
                            ? null
                            : <Field {...H.edt(AprobacionEdt.razonRechazo)} multiline width={1000} />
                        }
                    </Fields>
                    <AproSelector selector={form.apro} editable={form.editable} />
                </>            
            )

        }} />
    )
})
