export function getColumnWidthStyle(column) {

    const {width, minWidth, maxWidth} = column
    
    let result = {}

    if (width !== undefined) result['flex'] = `${width} ${width} 0`

    if (minWidth !== undefined) result['minWidth'] = minWidth

    if (maxWidth !== undefined) result['maxWidth'] = maxWidth

    return result
}