 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {NotaIds} from './Nota'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbText} from 'sopix/db/dbField'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {SolicitudIds} from './Solicitud'

export const NotaTable = new DbTable(
    Tables.Nota,
    'Notas',
    graphqlUrl,
    'Nota',
    [NotaIds.idNota],
    [NotaIds.idNota, NotaIds.nota, NotaIds.idAutor, NotaIds.idObra, NotaIds.idSolicitud, NotaIds.contexto, NotaIds.fechaCreacion, NotaIds.destacada, NotaIds.privada],
    [NotaIds.autor, NotaIds.obra, NotaIds.solicitud],
    [],
    ['verNotas'],
    ['modificarNotas'],
    undefined,
    undefined,
    [[2, ['accederNotasPrivadas'], [NotaIds.privada]]],
    [],
)


export const NotaFld = Object.freeze({
    autor : new DbObject(Tables.Nota, NotaIds.autor, NotaIds.idAutor, 'Autor'),
    obra : new DbObject(Tables.Nota, NotaIds.obra, NotaIds.idObra, 'Obra'),
    solicitud : new DbObject(Tables.Nota, NotaIds.solicitud, NotaIds.idSolicitud, 'Solicitud'),
    idNota : new DbIntId(Tables.Nota, NotaIds.idNota, 'Id nota'),
    nota : new DbText(Tables.Nota, NotaIds.nota, 'Nota', 1000),
    idAutor : new DbObjectId(Tables.Nota, NotaIds.idAutor, 'Creado por', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idObra : new DbObjectId(Tables.Nota, NotaIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idSolicitud : new DbObjectId(Tables.Nota, NotaIds.idSolicitud, 'Id solicitud', {foreignTableId: Tables.Solicitud, foreignKeyFieldId: SolicitudIds.idSolicitud}),
    contexto : new DbText(Tables.Nota, NotaIds.contexto, 'Contexto', 100),
    fechaCreacion : new DbDateTime(Tables.Nota, NotaIds.fechaCreacion, 'Fecha creacion'),
    destacada : new DbInt(Tables.Nota, NotaIds.destacada, 'Destacada'),
    privada : new DbInt(Tables.Nota, NotaIds.privada, 'Privada'),
})

registerTableFields(Tables.Nota, NotaFld) 
