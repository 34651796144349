import {useDidMount} from 'sopix/hooks/use-did-mount'
import {useWillUnmount} from 'sopix/hooks/use-will-unmount'
import {useEffect, useReducer, useState} from 'react'
import {locationLockers, locationObservers, routers} from 'sopix/router/router'
import {getLog} from 'sopix/log'

const log = getLog('use-router')

let observerNo = 0

export const useRouter = () => {
    
    const [id] = useState(() => ++observerNo)
    
    const [, update] = useReducer(state => state + 1, 0)
    
    useDidMount(() => {
        routers.subscribe(id, update)        
    })
    
    useWillUnmount(() => {
        routers.unsubscribe(id)    
    })
}

export const useLocationObserver = (observerFunc) => {

    const [id] = useState(() => ++observerNo)

    useDidMount(() => {
        locationObservers.subscribe(id, observerFunc)
    })

    useWillUnmount(() => {
        locationObservers.unsubscribe(id)
    })
}


export const useLocationLocker = (locker) => {

    const [id] = useState(() => ++observerNo)

    const handleAgent = (previousLocation, currentLocation) => {
        log(()=>`locker call: ${id}`)
        if (locker(previousLocation, currentLocation) === false) {
            return false
        }
    }
    
    useEffect(() => {
        log(()=>`locker subscribe: ${id}`)
        locationLockers.subscribe(id, handleAgent)
        
        return () => {
            log(()=>`locker unsubscribe: ${id}`)
            locationLockers.unsubscribe(id)
        }
    })
}