 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {NotaIds} from './Nota'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const NotaCol = Object.freeze({
    autor : new LookupColumn(NotaIds.autor, Tables.User, 'nombre_completo'),
    obra : new LookupColumn(NotaIds.obra, Tables.Obra, 'cod'),
    idNota : new SimpleColumn(NotaIds.idNota, 11, 11, Align.RIGHT),
    nota : new SimpleColumn(NotaIds.nota, 40, 16, Align.LEFT),
    contexto : new SimpleColumn(NotaIds.contexto, 40, 16, Align.LEFT),
    fechaCreacion : new DateTimeColumn(NotaIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(NotaIds.fechaCreacion, 11, 11, Align.LEFT),
    destacada : new SimpleColumn(NotaIds.destacada, 11, 11, Align.RIGHT),
    privada : new SimpleColumn(NotaIds.privada, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Nota] = NotaCol
