 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AprobacionIds = Object.freeze({
    tareas: Symbol('tareas'),
    aproestas: Symbol('aproestas'),
    aproposiciones: Symbol('aproposiciones'),
    aproasignar: Symbol('aproasignar'),
    aproliqmans: Symbol('aproliqmans'),
    aproplanos: Symbol('aproplanos'),
    tipo: Symbol('tipo'),
    solicitante: Symbol('solicitante'),
    obra: Symbol('obra'),
    tarea: Symbol('tarea'),
    documento: Symbol('documento'),
    idAprobacion: Symbol('idAprobacion'),
    fechaCreacion: Symbol('fechaCreacion'),
    idTipo: Symbol('idTipo'),
    solicitud: Symbol('solicitud'),
    idSolicitante: Symbol('idSolicitante'),
    idObra: Symbol('idObra'),
    idTarea: Symbol('idTarea'),
    idDocumento: Symbol('idDocumento'),
    estado: Symbol('estado'),
    fechaResolucion: Symbol('fechaResolucion'),
    razonRechazo: Symbol('razonRechazo'),
})

export const Aprobacion = Object.freeze({
    tareas: 'tareas',
    aproestas: 'aproestas',
    aproposiciones: 'aproposiciones',
    aproasignar: 'aproasignar',
    aproliqmans: 'aproliqmans',
    aproplanos: 'aproplanos',
    tipo: 'tipo',
    solicitante: 'solicitante',
    obra: 'obra',
    tarea: 'tarea',
    documento: 'documento',
    idAprobacion: 'idAprobacion',
    fechaCreacion: 'fechaCreacion',
    idTipo: 'idTipo',
    solicitud: 'solicitud',
    idSolicitante: 'idSolicitante',
    idObra: 'idObra',
    idTarea: 'idTarea',
    idDocumento: 'idDocumento',
    estado: 'estado',
    fechaResolucion: 'fechaResolucion',
    razonRechazo: 'razonRechazo',
})
