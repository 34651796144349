import {ConfigLiquidacionState} from 'sop/componentes/config-S/configLiquidacionState'
import {FieldsState} from 'sopix/react-state/fieldsState'
import {sopIcons} from 'sop/sop-icons'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {ContadorTable} from 'sop/db/ContadorTable'
import {Contador} from 'sop/db/Contador'
import {action, computed, observable, runInAction} from 'mobx'
import {ConfiguracionTable} from 'sop/db/ConfiguracionTable'
import {Configuracion} from 'sop/db/Configuracion'
import {boundMethod} from 'autobind-decorator'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {ConfigProcedenciasState} from 'sop/componentes/config-S/configProcedenciasState'

export class ConfigPageState extends FieldsState{
    contadorQL = new TableGraphql(ContadorTable)
    configuracionQL = new TableGraphql(ConfiguracionTable)
    
    contadorMutation = new SaveMutation(ContadorTable, 'SaveContador')
    configuracionMutation = new SaveMutation(ConfiguracionTable, 'SaveConfiguracion')
    
    @observable
    test

    constructor({errorManager}) {
        super({
            name: '',
            icon: sopIcons.Cuenta,
            errorManager: errorManager,
        } );

        this.liquidaciones = new ConfigLiquidacionState({errorManager})
        this.procedencias = new ConfigProcedenciasState({errorManager})
    }

    @action.bound
    changeTestReaction(value){
        this.test = value
    }

    async __init() {
        const [contadorResult, configuracionResult] = await Promise.all([
            this.contadorQL.query(ContadorTable.regularFields, undefined, {[Contador.contador]: 'obras'}),
            this.configuracionQL.query(ConfiguracionTable.regularFields, undefined, 
                {[Configuracion.idConfig]: 1}),
            this.liquidaciones._init(),
            this.procedencias.__init(),
        ])
        this.fieldManager.load({...contadorResult.rows[0], ...configuracionResult.rows[0]})
    }
    
    @computed
    get dirty(){
        return this._dirty || this.liquidaciones.dirty || this.procedencias.dirty 
    }
    
    @boundMethod
    async _save(){
        await Promise.all([
            this.fieldManager._dirty && this.contadorMutation.query(this.fieldManager.fields, 
                {initialData: this.fieldManager.initialFields, idField: Contador.idContador}),
            this.fieldManager._dirty && this.configuracionMutation.query(this.fieldManager.fields,
                {initialData: this.fieldManager.initialFields, idField: Configuracion.idConfig}),
            this.liquidaciones._save(),
            this.procedencias._save(),
        ])
        runInAction(() => this.fieldManager.undirty())
    }
    save = this._locked_catched_async(this._save)
    
    
    @boundMethod
    async _revert(){
        this.fieldManager.revert()
        this.liquidaciones._revert()
        this.procedencias._revert()
    }
    revert = this._locked_catched_async(this._revert)
}