import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {FormPiece} from 'sopix/pieces/formPiece'
import {NumberField} from 'sopix/form/editors/NumberField'
import {Ejecucion} from 'sop/db/Ejecucion'
import {EjecucionTable} from 'sop/db/EjecucionTable'
import {EjecucionEdt} from 'sop/db/EjecucionEditors'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {CuentaFld, CuentaTable} from 'sop/db/CuentaTable'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {Solicitud} from 'sop/db/Solicitud'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {Tarea} from 'sop/db/Tarea'
import {CuentaTipos, TrabajoTipoTarea} from 'sop/db-config/row-consts'
import {Fields} from 'sopix/formComps/Fields'
import {Cuenta} from 'sop/db/Cuenta'
import {CuentaEdt} from 'sop/db/CuentaEditors'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {sopIcons} from 'sop/sop-icons'
import {Field} from 'sopix/form/editors/Field'
import {SistemaFld} from 'sop/db/SistemaTable'
import {find} from 'lodash-es'
import {Sistema} from 'sop/db/Sistema'
import {boundMethod} from 'autobind-decorator'
import {action, observable, runInAction} from 'mobx'
import {ConfiguracionFld, ConfiguracionTable} from 'sop/db/ConfiguracionTable'
import {Configuracion} from 'sop/db/Configuracion'


const EJECUCION_ADJUDICATARIO = 'idAdjudicatario'
const EJECUCION_COMPETIDOR = 'idCompetidor'
const EJECUCION_COMENTARIOS = 'comentarios'

export class EjecucionFormPiece extends FormPiece{

    _solicitudesQL = new TableGraphql(SolicitudTable)
    _trabajosQL = new TableGraphql(TareaTable)
    _configQL = new TableGraphql(ConfiguracionTable)
    _cuentaQL = new TableGraphql(CuentaTable)

    _listFetcher = new ListFetcher([
        EjecucionEdt.procedencia.getListQueryEntry(),
        EjecucionEdt.sistema.getListQueryEntry(),
    ])

    @observable
    sistemaRows
    
    constructor(world) {
        const FIELDS = [
                ...EjecucionTable.regularFields,
        ]

        super(world,
            FIELDS,
            {
                name: 'Ejecución',
                icon: sopIcons.Ejecucion,
                table: EjecucionTable,
                idField: Ejecucion.idEjecucion,
                saveMutation: 'SaveEjecucion',
                extraFields: [EJECUCION_ADJUDICATARIO, EJECUCION_COMENTARIOS, EJECUCION_COMPETIDOR],
                deleteMutation: 'DeleteEjecucion',
            },
        )
    }

    async _init() {
        this.setLists(await this._listFetcher.fetch())
        this.config = (await this._configQL.query(
            [ConfiguracionFld.ratioRepeticion, ConfiguracionFld.ratioSimetria], undefined,
            {[Configuracion.idConfig]: 1})).rows
    }

    @boundMethod
    async cuentaOptionsSource(search){
        const list = await this._cuentaQL.query(
            [CuentaFld.idCuenta, CuentaFld.cuenta],
            {first: 50},
            {[`${Cuenta.cuenta}Ilike`]:  `%${search}%`, [Cuenta.idTipo]: CuentaTipos.COMPETENCIA}
        )
        return list.rows
    }
    __cuentaOptionsSource = this._asyncAction(this.cuentaOptionsSource)


    @boundMethod
    _getTitle() {
        if (!this.getField(Ejecucion.competencia)) {
            return 'Ejecutar'
        } else {
            return 'Ejecutar por la competencia'
        }
    }

    @action.bound
    async _applyRow(data) {
        const idObra = data[Ejecucion.idObra]
        
        const [solicitudRows, cotizacionRows] = await Promise.all([
            this._solicitudesQL.query([
                SolicitudFld.idSolicitud,
                [SolicitudFld.cuenta, [
                    CuentaFld.idCuenta,
                    CuentaFld.cuenta,
                ]],
            ], undefined, {[Solicitud.idObra]: idObra}),

            this._trabajosQL.query([
                TareaFld.idTarea,
                TareaFld.ref,
                TareaFld.m2Totales,
                TareaFld.m2Efectivos,
                TareaFld.idProcedencia,
                [TareaFld.sistemaList, [SistemaFld.idSistema, SistemaFld.sistema]],
                [TareaFld.solicitudList, [SolicitudFld.cuenta, [CuentaFld.idCuenta, CuentaFld.cuenta]]],
            ], undefined, {[Tarea.idObra]: idObra, [`${Tarea.idTipo}NotIn`]: TrabajoTipoTarea}),
            
        ])
        
        const competencia = data[Ejecucion.competencia]
        
        if (!competencia) {
            runInAction(()=>{
                this.cotizacionRows = cotizacionRows.rows

                this.cuentaRows = this.cotizacionRows.length ? [] : solicitudRows.rows.map(sol => sol[Solicitud.cuenta])

                if (!data[EJECUCION_ADJUDICATARIO] && this.cuentaRows.length) {
                    data[EJECUCION_ADJUDICATARIO] = this.cuentaRows[0][Cuenta.idCuenta]
                }

            })
        }
        
        await super._applyRow(data)

        if (!competencia) {
            runInAction(() => {
                if (this.cotizacionRows.length === 1) {
                    const cotiz = this.cotizacionRows[0]
                    this.setField(Ejecucion.idTarea, cotiz[Tarea.idTarea])
                    this.cotizacionChanged(cotiz)
                }
            })
        }
    }

    @boundMethod
    cotizacionChanged(cotiz){
        
        const sistemaRows = !cotiz ? [] : cotiz[Tarea.sistemaList] 
        const idSistema = this.getField(Ejecucion.idSistema) 
        if (idSistema && !find(sistemaRows, {[Sistema.idSistema]: idSistema})){
            this.setField(Ejecucion.idSistema, undefined)
        }
        this.sistemaRows = sistemaRows
        if (sistemaRows.length === 1) {
            this.setField(Ejecucion.idSistema, sistemaRows[0][Sistema.idSistema])
        }
        
        const cuentaRows = !cotiz ? [] : cotiz[Tarea.solicitudList].map(sol => sol[Solicitud.cuenta])
        const idCuenta = this.getField(EJECUCION_ADJUDICATARIO)
        if (idCuenta && !find(cuentaRows, {[Cuenta.idCuenta]: idCuenta})){
            this.setField(EJECUCION_ADJUDICATARIO, undefined)
        }
        this.cuentaRows = cuentaRows
        if (cuentaRows.length === 1) {
            this.setField(EJECUCION_ADJUDICATARIO, cuentaRows[0][Cuenta.idCuenta])
        }
        
        this.setField(Ejecucion.m2Totales, !cotiz ? 0 : cotiz[Tarea.m2Totales])
        this.setField(Ejecucion.m2Efectivos, !cotiz ? 0 : cotiz[Tarea.m2Efectivos])
        
        
        this.setField(Ejecucion.idProcedencia, !cotiz ? undefined : cotiz[Tarea.idProcedencia])
    }
    __cotizacionChanged = this._asyncAction(this.cotizacionChanged)
}


export const EjecucionForm = getPieceContainer('form', observer(
    /**
     * @param {EjecucionFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField
        const man = form.fieldManager

        return (
            <Fields>
                
                {!fld(Ejecucion.competencia)
                    ? <>
                        {!form.cotizacionRows.length? null :
                            <DropdownField {...H.edt(EjecucionEdt.tarea)} label="Cotización" options={form.cotizacionRows}
                                           onChange={form.__cotizacionChanged}
                            />
                        }
                        <DropdownField manager={man} name={EJECUCION_ADJUDICATARIO} label="Adjudicatario" idField={Cuenta.idCuenta}
                                       displayField={Cuenta.cuenta} options={form.cuentaRows}
                                       width={CuentaEdt.cuenta.minWidth}
                        />
                        <DropdownField {...H.edt(EjecucionEdt.sistema)} 
                                       {... (!form.cotizacionRows.length ? {} : {options: form.sistemaRows})} />
                        
                        <DropdownField {...H.edt(EjecucionEdt.procedencia)} />
                    </>
                    : <>
                        <DropdownField manager={man} name={EJECUCION_COMPETIDOR} label="Competidor" 
                            idField={Cuenta.idCuenta} displayField={Cuenta.cuenta} width={CuentaEdt.cuenta.minWidth}
                            optionsSource={form.__cuentaOptionsSource}
                        />
                    </>
                }
                <NumberField {...H.edt(EjecucionEdt.m2Totales)} />
                <NumberField {...H.edt(EjecucionEdt.m2Efectivos)} />
                <Field manager={man} name={EJECUCION_COMENTARIOS} multiline rows={10} width={1000} />
            </Fields>
        )
}))
