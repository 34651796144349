 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproposicionIds} from './Aproposicion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const AproposicionCol = Object.freeze({
    idAproposicion : new SimpleColumn(AproposicionIds.idAproposicion, 11, 11, Align.RIGHT),
    posicionOriginal : new SimpleColumn(AproposicionIds.posicionOriginal, 11, 11, Align.RIGHT),
    nuevaPosicion : new SimpleColumn(AproposicionIds.nuevaPosicion, 11, 11, Align.RIGHT),
    justificacion : new SimpleColumn(AproposicionIds.justificacion, 40, 16, Align.LEFT),
})

tableColumns[Tables.Aproposicion] = AproposicionCol
