import {linkSearchFiltrator} from 'sopix/piece-linkers/search-list'
import {linkFormListWithApply, linkFormListWithLoad} from 'sopix/piece-linkers/form-list'
import {linkUrlForm} from 'sopix/piece-linkers/url-form'
import {linkUrlSelection} from 'sopix/piece-linkers/url-selection'
import {linkFiltratorSelection} from 'sopix/piece-linkers/filtrator-selection'

/**
 * @param {PagePiece} page
 * @param {TablePiece} list
 * @param {SearchPiece} search
 * @param {FormPiece} form
 * @param {UrlManager} urlManager
 */
export function linkPageListSearchFormUrlWithApply(page, list, search, form, urlManager){
    linkSearchFiltrator(search, list.filtrator)
    linkFormListWithApply(list, form)
    linkUrlForm(page, urlManager, form)
    linkUrlSelection(urlManager, list.selection)
    linkFiltratorSelection(list.filtrator, list.selection)
}


/**
 * @param {PagePiece} page
 * @param {TablePiece} list
 * @param {SearchPiece} search
 * @param {FormPiece} form
 * @param {UrlManager} urlManager
 */
export function linkPageListSearchFormUrlWithLoad(page, list, search, form, urlManager){
    linkSearchFiltrator(search, list.filtrator)
    linkFormListWithLoad(list, form)
    linkUrlForm(page, urlManager, form)
    linkUrlSelection(urlManager, list.selection)
    linkFiltratorSelection(list.filtrator, list.selection)
}
