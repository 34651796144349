import {makeStyles} from '@material-ui/core/styles'
import {DraggableDialog} from 'sopix/modal/DraggableDialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, {useState} from 'react'

const useStyles = makeStyles(() => ({
    icon: {
        position: 'relative',
        top: 5,
        left: -5,
        marginRight: 8, 
    },
    title: {
        cursor: 'default',
    }
}))

let modalFrameCount = 0

export function ModalFrame({open, title, Icon, onClose, maxWidth, children, ...otherDialogProps}) {

    const [id] = useState(() => `modal-form-${++modalFrameCount}`)
    const titleId = `${id}-title`
    
    const cls = useStyles()
    
    const icon = Icon === undefined ? '' : <Icon className={cls.icon}/>
    
    return (
        <DraggableDialog
            draggableComponentId={titleId}
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            {...otherDialogProps}
        >
            <DialogTitle id={titleId} className={cls.title}>{icon}{title}</DialogTitle>
            {children}
        </DraggableDialog>
    )
}