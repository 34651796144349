import {boundMethod} from 'autobind-decorator'
import {BoundlessManager, DataSide} from 'sopix/boundless/boundless-classes'
import {action} from 'mobx'
import {GraphqlCache} from 'sopix/db/graphqlCache'
import {getLoggers} from 'sopix/log'
import {DataSource} from 'sopix/data-source/dataSource'

const {debug} = getLoggers('GraphqlDataSource')

export class GraphqlDataSource extends DataSource{

    manager = new BoundlessManager()

    /** @type {GraphqlCache} */
    cache
    
    //ATENCION: si el cache es demasiado pequeño se pueden desincronizar los datos
    _disposeSize
    _prefetchSize
    _minRowsRequested

    _prefetchCount = 0
    get isSearching(){
        return this._prefetchCount > 0
    }
    
    /** @type {DbField[]} */
    fieldDescriptors
    
    
    constructor(world, graphqlEndPoint, fieldDescriptors, defaultGraphqlFilter, defaultGraphqlOrder,
                {disposeSize = 100, prefetchSize = 20,
                    minRowsRequested = 10, empty} = {}) {
        super(world)
        
        this.graphqlEndPoint = graphqlEndPoint
        this.fieldDescriptors = fieldDescriptors
        this._disposeSize = disposeSize
        this._prefetchSize = prefetchSize
        this._minRowsRequested = minRowsRequested
        this.cache = this._createCache(defaultGraphqlFilter, defaultGraphqlOrder, {empty})
    }
    
    _cacheChanged(cache){
        this._setData(cache.data.data)
    }
    
    
    @boundMethod
    /**
     * @param {DataSide} side
     * @returns {DataBlock}
     */
    async _extend(len, side){
        if (side === DataSide.TOP) {
            const extension = await this.cache.extendTop(this.cache.data.index, len)
            this._cacheChanged(this.cache)

            debug(() => `TOP EXTENSION: ${extension.asText()}`)
            debug(() => `   cache: ${this.cache.data.length}`)
            return extension
        }else{
            const extension = await this.cache.extendBottom(this.cache.data.end, len)
            this._cacheChanged(this.cache)
            debug(()=>`BOTTOM EXTENSION: ${extension.asText()}`)
            debug(()=>`   cache: ${this.cache.data.length}`)
            return extension
        }
    }
    
    @boundMethod
    async dataProvider(/** DataSide */ side,
                      index, height, /** BoundlessStats */ stats) {

        const len = 1 + ~~(height / stats.avgHeight)

        debug(()=>`REQUEST: side: ${side}, index: ${index}, heigh: ${height}, len: ${len}`)
        
        return await this.getData(index, len, side, {
            topRestart: stats.scrollTop === 0 && this.cache.index > len
        })
    }
    
    
    @boundMethod
    /**
     * @param {DataSide} side
     * @returns {DataBlock}
     */
    async getData(index, len = this._prefetchSize, side = DataSide.BOTTOM, {topRestart} = {}){

        // Esto es importante porque un redibujado podría cancelar un cambio de filtro o orden en proceso
        if (this.isSearching) {
            return
        }
        
        const data = this.cache.data.getSubBlock(index, len, side)
        if (data !== undefined && data.length > 0) {
            return data
        }

        if (topRestart) {
            const cache = this._createCache(this.cache.filter, this.cache.order)
            const extension = await cache.extendBottom(0, this._prefetchSize)
            this.cache = cache  // Esta linea la he añadido ¿bien?
            this._cacheChanged(this.cache)
            return extension
        }

        if (side === DataSide.TOP) {
            
            if (index !== this.cache.data.index) {
                throw Error('Graphql extension error')
            }
            return await this._extend(len, side)

        } else {

            if (index !== this.cache.data.end) {
                throw Error('Graphql extension error')
            }
            return await this._extend(len, side)
        }
    }
    __dataProvider = this._asyncAction(this.dataProvider)

    @action
    /** @param {GraphqlCache} cache */
    _reset(cache){
        this.cache = cache
        this.manager.reset(cache.data)
        this._cacheChanged(this.cache)
    }

    /** @returns {GraphqlCache} */
    _createCache(graphqlFilter, graphqlOrder, {empty} = {}){
        return new GraphqlCache(
            this.graphqlEndPoint, this.fieldDescriptors,
            graphqlFilter,
            graphqlOrder,
            {
                maxSize: this._disposeSize,
                minRequest: this._minRowsRequested,
                empty: empty
            }
        )
    }

    @boundMethod
    async fetchAndReset(graphqlFilter, graphqlOrder) {
        this._prefetchCount++
        try{
            const cache = this._createCache(
                graphqlFilter || this.cache.filter,
                graphqlOrder || this.cache.order,
            )
            await cache.extendBottom(0, this._prefetchSize)
            this._reset(cache)
        }finally {
            this._prefetchCount--
        }
    }

    @boundMethod
    async _doRefresh(){
        await this.fetchAndReset(this.cache.filter, this.cache.order)
    }

    @boundMethod
    async _doClearData() {
        this._reset(this._createCache(this.cache.filter, this.cache.order, {empty: true}))
    }

}