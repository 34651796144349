import {ErrorStore} from 'sopix/errors/ErrorStore'

export class PieceWorld{
    
    /** @type {ErrorStore} */
    errorManager

    /**
     * @param {ErrorStore} errorManager
     */
    constructor(errorManager) {
        this.errorManager = errorManager
    }
    
    clone({errorManager} = {}){
        const result = new PieceWorld(this.errorManager)
        if (errorManager) {
            result.errorManager = errorManager
        }
        return result
    }
    
    cloneWithOwnErrors(){
        return this.clone({errorManager: new ErrorStore()}) 
    }
}