import {sopAppStage} from 'sop/sop'
import {AppStage} from 'sopix/app/app-utils'


function getGraphqlUrl(){
    if (process.env.NODE_ENV === 'development'){
        return 'https://devsopapi.asix.cima/graphql'
    } else if (sopAppStage === AppStage.PRODUCTION){
        return 'https://sopapi.polytherm.es/graphql'
    } else if (sopAppStage === AppStage.STAGING){
        return 'https://devsopapi.polytherm.es/graphql'
    } else {
        return 'https://devsopapi.polytherm.es/graphql'
    }
}

export const graphqlUrl = getGraphqlUrl()
