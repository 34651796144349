 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PermisoTransposedIds} from './PermisoTransposed'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbTextId} from 'sopix/db/dbField'

export const PermisoTransposedTable = new DbTable(
    Tables.PermisoTransposed,
    'PermisosTransposed',
    graphqlUrl,
    'PermisoTransposed',
    [PermisoTransposedIds.idPermiso],
    [PermisoTransposedIds.idPermiso, PermisoTransposedIds.permiso],
    [],
    [],
    ['verPermisos'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const PermisoTransposedFld = Object.freeze({
    idPermiso : new DbIntId(Tables.PermisoTransposed, PermisoTransposedIds.idPermiso, 'Id permiso'),
    permiso : new DbTextId(Tables.PermisoTransposed, PermisoTransposedIds.permiso, 'Permiso', 26),
})

registerTableFields(Tables.PermisoTransposed, PermisoTransposedFld) 
