 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PlanoEstadoIds} from './PlanoEstado'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const PlanoEstadoEdt = Object.freeze({
    idEstado : new SimpleEditor(PlanoEstadoIds.idEstado, 11, 11),
    estado : new SimpleEditor(PlanoEstadoIds.estado, 14, 10),
})

tableEditors[Tables.PlanoEstado] = PlanoEstadoEdt
