 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DireccionIds} from './Direccion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const DireccionCol = Object.freeze({
    cuenta : new LookupColumn(DireccionIds.cuenta, Tables.Cuenta, 'cuenta'),
    autor : new LookupColumn(DireccionIds.autor, Tables.User, 'nombre_completo'),
    modificacion : new LookupColumn(DireccionIds.modificacion, Tables.User, 'nombre_completo'),
    idDireccion : new SimpleColumn(DireccionIds.idDireccion, 11, 11, Align.RIGHT),
    cod : new SimpleColumn(DireccionIds.cod, 11, 11, Align.RIGHT),
    idEmpresa : new SimpleColumn(DireccionIds.idEmpresa, 11, 11, Align.RIGHT),
    direccion : new SimpleColumn(DireccionIds.direccion, 40, 16, Align.LEFT),
    localidad : new SimpleColumn(DireccionIds.localidad, 40, 16, Align.LEFT),
    provincia : new SimpleColumn(DireccionIds.provincia, 40, 16, Align.LEFT),
    pais : new SimpleColumn(DireccionIds.pais, 40, 16, Align.LEFT),
    cp : new SimpleColumn(DireccionIds.cp, 10, 10, Align.LEFT),
    principal : new SimpleColumn(DireccionIds.principal, 11, 11, Align.RIGHT),
    fechaAlta : new DateTimeColumn(DireccionIds.fechaAlta, 16, 16, Align.LEFT),
    fechaAltaOnlyDate : new DateColumn(DireccionIds.fechaAlta, 11, 11, Align.LEFT),
    fechaModificacion : new DateTimeColumn(DireccionIds.fechaModificacion, 16, 16, Align.LEFT),
    fechaModificacionOnlyDate : new DateColumn(DireccionIds.fechaModificacion, 11, 11, Align.LEFT),
    odooCambiado : new SimpleColumn(DireccionIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Direccion] = DireccionCol
