import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    txtList: {
        margin: -6,
    },
    txt: {
        margin: 6,
    },
    maxWidth: {
        textOverflow: 'ellipsis',
        maxWidth: ({maxWidth}) => !maxWidth ? 'auto' : maxWidth,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
})) 


export function TxtList({children}){
    const cls = useStyles()

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <div className={cls.txtList}>
            {children}
        </div>
    )
}


export function Txt({className, label, maxWidth}){
    const cls = useStyles({maxWidth: maxWidth})

    return (
        <div className={clsx(
            cls.txt,
            maxWidth && cls.maxWidth,
            className,
        )}>
            {label}
        </div>
    )
}