 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AvisoTipoIds} from './AvisoTipo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const AvisoTipoCol = Object.freeze({
    idTipo : new SimpleColumn(AvisoTipoIds.idTipo, 11, 11, Align.RIGHT),
    tipo : new SimpleColumn(AvisoTipoIds.tipo, 20, 10, Align.LEFT),
})

tableColumns[Tables.AvisoTipo] = AvisoTipoCol
