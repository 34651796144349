 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproestaIds} from './Aproesta'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const AproestaCol = Object.freeze({
    estadoOriginal : new LookupColumn(AproestaIds.estadoOriginal, Tables.TareaEstado, 'estado'),
    estado : new LookupColumn(AproestaIds.estado, Tables.TareaEstado, 'estado'),
    idAproesta : new SimpleColumn(AproestaIds.idAproesta, 11, 11, Align.RIGHT),
    justificacion : new SimpleColumn(AproestaIds.justificacion, 40, 16, Align.LEFT),
})

tableColumns[Tables.Aproesta] = AproestaCol
