import {FormFieldHelper} from 'sopix/form/formFieldHelper'
import {FieldsPiece} from 'sopix/pieces/fieldsPiece'
import {FrameFlag} from 'sopix/piece/piece'
import {boundMethod} from 'autobind-decorator'

export class SearchPiece extends FieldsPiece{
    
    constructor(world, {defaultFields = {}} = {}) {
        super(world, {deleteUndefinedFields: true, defaultFields})
    }
    
    @boundMethod
    _afterConstructor() {
        super._afterConstructor()
        this.frameFlags = new Set([FrameFlag.FIELDS, FrameFlag.MARGIN, FrameFlag.ROW])
    }

    get fieldHelper(){
        return new FormFieldHelper(this.fieldManager, this.lists)
    }
    

}