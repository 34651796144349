 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const UserRoleIds = Object.freeze({
    users: Symbol('users'),
    idRole: Symbol('idRole'),
    role: Symbol('role'),
})

export const UserRole = Object.freeze({
    users: 'users',
    idRole: 'idRole',
    role: 'role',
})
