 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaIds} from './Tarea'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbBool, DbDateTime, DbFloat, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {TareaEstadoIds} from './TareaEstado'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {ProcedenciaIds} from './Procedencia'
import {TipoTareaIds} from './TipoTarea'
import {AprobacionIds} from './Aprobacion'
import {AproestaIds} from './Aproesta'
import {AproposicionIds} from './Aproposicion'

export const TareaTable = new DbTable(
    Tables.Tarea,
    'Tareas',
    graphqlUrl,
    'Tarea',
    [TareaIds.idTarea],
    [TareaIds.idTarea, TareaIds.idEstado, TareaIds.ordenEstudio, TareaIds.ordenValoracion, TareaIds.descripcion, TareaIds.idAutor, TareaIds.idResponsable, TareaIds.idObra, TareaIds.idProcedencia, TareaIds.fechaVencimiento, TareaIds.fechaCreacion, TareaIds.ref, TareaIds.refCliente, TareaIds.idTipo, TareaIds.m2Totales, TareaIds.urgente, TareaIds.ratio, TareaIds.fechaFinalizada, TareaIds.desplazada, TareaIds.idPadre, TareaIds.idAprobacion, TareaIds.m2Completos, TareaIds.m2Repetidos, TareaIds.m2Simetricos, TareaIds.m2Fijos, TareaIds.detalle, TareaIds.justificacion, TareaIds.m2Efectivos, TareaIds.precioSinRegulacion, TareaIds.idAproesta, TareaIds.idAproposicion, TareaIds.refE, TareaIds.refC, TareaIds.refV, TareaIds.refT, TareaIds.odooCambiado],
    [TareaIds.estado, TareaIds.autor, TareaIds.responsable, TareaIds.obra, TareaIds.procedencia, TareaIds.tipo, TareaIds.padre, TareaIds.aprobacion, TareaIds.aproesta, TareaIds.aproposicion],
    [TareaIds.tareas, TareaIds.liquidaciones, TareaIds.avisos, TareaIds.aprobaciones, TareaIds.aproestas, TareaIds.aproposiciones, TareaIds.ejecuciones, TareaIds.documentos, TareaIds.solicitudList, TareaIds.sistemaList],
    ['verTareas'],
    ['modificarTareas'],
    ['crearTareas'],
    ['eliminarTareas'],
    [[2, ['verM2Efectivos'], [TareaIds.m2Efectivos]]],
    [[0, [], [TareaIds.idAutor, TareaIds.fechaCreacion, TareaIds.ordenEstudio, TareaIds.ordenValoracion]]],
)


export const TareaFld = Object.freeze({
    tareas : new DbObjectList(Tables.Tarea, TareaIds.tareas, Tables.Tarea),
    liquidaciones : new DbObjectList(Tables.Tarea, TareaIds.liquidaciones, Tables.Liquidacion),
    avisos : new DbObjectList(Tables.Tarea, TareaIds.avisos, Tables.Aviso),
    aprobaciones : new DbObjectList(Tables.Tarea, TareaIds.aprobaciones, Tables.Aprobacion),
    aproestas : new DbObjectList(Tables.Tarea, TareaIds.aproestas, Tables.Aproesta),
    aproposiciones : new DbObjectList(Tables.Tarea, TareaIds.aproposiciones, Tables.Aproposicion),
    ejecuciones : new DbObjectList(Tables.Tarea, TareaIds.ejecuciones, Tables.Ejecucion),
    documentos : new DbObjectList(Tables.Tarea, TareaIds.documentos, Tables.Documento),
    solicitudList : new DbObjectList(Tables.Tarea, TareaIds.solicitudList, Tables.Solicitud),
    sistemaList : new DbObjectList(Tables.Tarea, TareaIds.sistemaList, Tables.Sistema),
    estado : new DbObject(Tables.Tarea, TareaIds.estado, TareaIds.idEstado, 'Estado'),
    autor : new DbObject(Tables.Tarea, TareaIds.autor, TareaIds.idAutor, 'Autor'),
    responsable : new DbObject(Tables.Tarea, TareaIds.responsable, TareaIds.idResponsable, 'Responsable'),
    obra : new DbObject(Tables.Tarea, TareaIds.obra, TareaIds.idObra, 'Obra'),
    procedencia : new DbObject(Tables.Tarea, TareaIds.procedencia, TareaIds.idProcedencia, 'Procedencia'),
    tipo : new DbObject(Tables.Tarea, TareaIds.tipo, TareaIds.idTipo, 'Tipo'),
    padre : new DbObject(Tables.Tarea, TareaIds.padre, TareaIds.idPadre, 'Padre'),
    aprobacion : new DbObject(Tables.Tarea, TareaIds.aprobacion, TareaIds.idAprobacion, 'Aprobacion'),
    aproesta : new DbObject(Tables.Tarea, TareaIds.aproesta, TareaIds.idAproesta, 'Aproesta'),
    aproposicion : new DbObject(Tables.Tarea, TareaIds.aproposicion, TareaIds.idAproposicion, 'Aproposicion'),
    idTarea : new DbIntId(Tables.Tarea, TareaIds.idTarea, 'Id tarea'),
    idEstado : new DbObjectId(Tables.Tarea, TareaIds.idEstado, 'Id estado', {foreignTableId: Tables.TareaEstado, foreignKeyFieldId: TareaEstadoIds.idEstado}),
    ordenEstudio : new DbInt(Tables.Tarea, TareaIds.ordenEstudio, 'Pos.'),
    ordenValoracion : new DbInt(Tables.Tarea, TareaIds.ordenValoracion, 'Pos.'),
    descripcion : new DbText(Tables.Tarea, TareaIds.descripcion, 'Descripcion', 1000),
    idAutor : new DbObjectId(Tables.Tarea, TareaIds.idAutor, 'Id autor', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idResponsable : new DbObjectId(Tables.Tarea, TareaIds.idResponsable, 'Id responsable', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idObra : new DbObjectId(Tables.Tarea, TareaIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idProcedencia : new DbObjectId(Tables.Tarea, TareaIds.idProcedencia, 'Id procedencia', {foreignTableId: Tables.Procedencia, foreignKeyFieldId: ProcedenciaIds.idProcedencia}),
    fechaVencimiento : new DbDateTime(Tables.Tarea, TareaIds.fechaVencimiento, 'Vencimiento'),
    fechaCreacion : new DbDateTime(Tables.Tarea, TareaIds.fechaCreacion, 'Fecha creacion'),
    ref : new DbText(Tables.Tarea, TareaIds.ref, 'Ref', 40),
    refCliente : new DbText(Tables.Tarea, TareaIds.refCliente, 'Ref cliente', 20),
    idTipo : new DbObjectId(Tables.Tarea, TareaIds.idTipo, 'Id tipo', {foreignTableId: Tables.TipoTarea, foreignKeyFieldId: TipoTareaIds.idTipo}),
    m2Totales : new DbInt(Tables.Tarea, TareaIds.m2Totales, 'M²'),
    urgente : new DbInt(Tables.Tarea, TareaIds.urgente, 'Urgente'),
    ratio : new DbFloat(Tables.Tarea, TareaIds.ratio, 'Ratio'),
    fechaFinalizada : new DbDateTime(Tables.Tarea, TareaIds.fechaFinalizada, 'Fecha finalizada'),
    desplazada : new DbInt(Tables.Tarea, TareaIds.desplazada, 'Desplazada'),
    idPadre : new DbObjectId(Tables.Tarea, TareaIds.idPadre, 'Id padre', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    idAprobacion : new DbObjectId(Tables.Tarea, TareaIds.idAprobacion, 'Id aprobacion', {foreignTableId: Tables.Aprobacion, foreignKeyFieldId: AprobacionIds.idAprobacion}),
    m2Completos : new DbInt(Tables.Tarea, TareaIds.m2Completos, 'M2 completos'),
    m2Repetidos : new DbInt(Tables.Tarea, TareaIds.m2Repetidos, 'M2 repetidos'),
    m2Simetricos : new DbInt(Tables.Tarea, TareaIds.m2Simetricos, 'M2 simetricos'),
    m2Fijos : new DbInt(Tables.Tarea, TareaIds.m2Fijos, 'M2 fijos'),
    detalle : new DbText(Tables.Tarea, TareaIds.detalle, 'Detalle', 50),
    justificacion : new DbText(Tables.Tarea, TareaIds.justificacion, 'Justificacion', 1000),
    m2Efectivos : new DbFloat(Tables.Tarea, TareaIds.m2Efectivos, 'M² efectivos'),
    precioSinRegulacion : new DbFloat(Tables.Tarea, TareaIds.precioSinRegulacion, 'Precio sin reg.'),
    idAproesta : new DbObjectId(Tables.Tarea, TareaIds.idAproesta, 'Id aproesta', {foreignTableId: Tables.Aproesta, foreignKeyFieldId: AproestaIds.idAproesta}),
    idAproposicion : new DbObjectId(Tables.Tarea, TareaIds.idAproposicion, 'Id aproposicion', {foreignTableId: Tables.Aproposicion, foreignKeyFieldId: AproposicionIds.idAproposicion}),
    refE : new DbText(Tables.Tarea, TareaIds.refE, 'Ref e', 40),
    refC : new DbText(Tables.Tarea, TareaIds.refC, 'Ref c', 40),
    refV : new DbText(Tables.Tarea, TareaIds.refV, 'Ref v', 40),
    refT : new DbText(Tables.Tarea, TareaIds.refT, 'Ref t', 40),
    odooCambiado : new DbInt(Tables.Tarea, TareaIds.odooCambiado, 'Odoo cambiado'),
    asumible : new DbBool(Tables.Tarea, TareaIds.asumible, 'Asumible'),
    aclReadOnly : new DbBool(Tables.Tarea, TareaIds.aclReadOnly, 'Acl read only'),
})

registerTableFields(Tables.Tarea, TareaFld) 
