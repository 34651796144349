import {boundMethod} from 'autobind-decorator'

export class FilePickerStore{
    _input
    _resultFunc
    
    @boundMethod
    _fileSelected(e){
        this._resultFunc([...this._input.files])
        this._input.value = null
        this._resultFunc = undefined
    }
    
    @boundMethod
    open(resultFunc){
        if (!this._input)  {
            throw new Error('File picker no está montado')
        }
        this._resultFunc = resultFunc
        this._input.click()
    }
    
    @boundMethod
    clicked(resultFunc){
        return () => {
            this.open(resultFunc)
        }
    }
}

export const filePicker = new FilePickerStore()
