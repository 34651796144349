import {Piece} from 'sopix/piece/piece'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {RelacionadaFld, RelacionadaTable} from 'sop/db/RelacionadaTable'
import {ObraFld} from 'sop/db/ObraTable'
import {UserFld} from 'sop/db/UserTable'
import {Relacionada} from 'sop/db/Relacionada'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {Poper} from 'sopix/piece-objects/Poper'
import {sopIcons} from 'sop/sop-icons'
import {ObraPickerPiece} from 'sop/componentes/obra/ObraPicker'
import {boundMethod} from 'autobind-decorator'
import {fieldErrorsAsSimpleMessage} from 'sopix/db-access/db-access-utils'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import React from 'react'
import {RowDeleter} from 'sopix/db-access/rowDeleter'
import {getLoggers} from 'sopix/log'
import {linkPieceDataSource} from 'sopix/piece-linkers/piece-data-source'

const {debug} = getLoggers('RelacionadaListPiece')

export class RelacionadaListPiece extends Piece{

    /** @type {SubsetDbSource} */
    dbSource

    saveMutation = new SaveMutation(RelacionadaTable, 'SaveObraRelacionada')
    
    
    /** @param {PieceWorld} world */
    constructor(world) {
        super(world)

        const TABLE = RelacionadaTable
        
        const FIELDS = [
            ...RelacionadaTable.regularFields,
            [RelacionadaFld.relacionada, [ObraFld.cod, ObraFld.nombre]],
            [RelacionadaFld.autor, [UserFld.username, UserFld.nombreCompleto]],
        ]


        this.dbSource = new SubsetDbSource(world,
            Relacionada.idObra,
            new TableGraphql(TABLE),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(Relacionada.fechaCreacion, OrderDirection.DESC)},
        })

        this.picker = new ObraPickerPiece(world.cloneWithOwnErrors())
        
        // Debería hacerse como aquí en todos los subscribe que ejecutan async:
        // Siempre que se pasa de sync a async hay que hacer un wrapper para los errores
        this.picker.list.selection.onRowClicked.subscribe(this.picker._asyncAction(async ({id}) => {
            await this._addObra(id)
            await this.dbSource.refresh()
            await this.poper.close()
        }))
        
        this.poper = new Poper(this.picker, {
            title: 'Selecciona una obra',
            icon: sopIcons.Obra,
        })

        this.selection = new RowSelection(world, this.dbSource, Relacionada.idRelacionada)
        
        const asa = this._asyncAction

        this.deleter = new RowDeleter(world, TABLE, 'DeleteObraRelacionada')
        this.deleter.onDelete.subscribe(asa(async () => {
            await this.dbSource.refresh()
        }))
        
        this.load = this.dbSource.loadSubset
        
        linkPieceDataSource(this, this.dbSource)
    }
    

    @boundMethod
    async _addObra(idObra){
        if (!idObra) {
            throw new Error('Falta idObra')
        }
        
        const {success, errorList} = await this.saveMutation.query({
            [Relacionada.idObra]: this.dbSource.parentId,
            [Relacionada.idRelacionada]: idObra,
        })

        if (!success) {
            throw new Error(fieldErrorsAsSimpleMessage(RelacionadaTable, errorList))
        }
    }
    
    @boundMethod
    async delete(idRelacionada){
        await this.deleter.delete(idRelacionada)
        await this.dbSource.refresh()
    }
    __delete = this._asyncAction(this.delete)
}