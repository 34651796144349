import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import clsx from 'clsx'
import {cimStyles} from 'sopix/theme/cimStyles'
import {HintIcon} from 'sopix/formComps/HintIcon'

const useStyles = makeStyles(() => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        '&:first-child': {
            paddingTop: 0,
        },
        maxWidth: '100%',
        overflow: 'hidden',
    },
    titleTag: cimStyles.singleLine,
    icon: {
        marginRight: 16,
    },
    margin: {
        padding: '24px 0 16px 0',
    },
})) 

export function Title({Icon, margin, iconProps, children}) {
    
    const cls = useStyles()

    const iconTag = !Icon ? '' : <HintIcon className={cls.icon} Icon={Icon} {...iconProps} />
    const titleTag = !children ? '' : <Typography variant="h6" component="h2" className={cls.titleTag} >{children}
        </Typography>
    
    return (
        <div className={clsx(
            cls.title,
            margin && cls.margin,
        )}>
            {iconTag} {titleTag}                
        </div>
    )
}
