import {observer} from 'mobx-react'
import React from 'react'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'

export const ListNavigator = observer(
    /**
     * 
     * @param {TablePiece} list
     * @returns {JSX.Element}
     */
    ({list}) => {
        
        const selection = list.selection
        
        const prevId = selection.getCloseId(-1)
        const nextId = selection.getCloseId(+1)
        
        return (
            <>
                <HintIconButton
                    Icon={icons.Left}
                    title="Anterior"
                    disabled={!prevId}
                    onClick={() => selection.__set(prevId)}
                />
                <HintIconButton
                    Icon={icons.Right}
                    title="Siguiente"
                    disabled={!nextId}
                    onClick={() => selection.__set(nextId)}
                />
            </>
        )    
})