import React, {useEffect, useMemo, useRef} from 'react'
import {getLoggers} from 'sopix/log'
import {defaultTo} from 'lodash-es'

const {debug} = getLoggers('BoundlessRow') 




export function BoundlessRow(props) {

    const {index, onUpdateRow, top, visible, RowCell, Row, columnMap, data, dense, verticalAlign,
        renderCellContent, renderSimpleCellContent, keyColumnId, hover, onClick, rowSelected, global} = props
    
    debug(() => `render row ${index} ${data[keyColumnId]}`)
    
    const ref = useRef()

    const style = useMemo(() => ({
        ...visible ? {} : {visibility: 'hidden'},
        ...top === undefined ? {} : {top: `${top}px`, position: 'absolute', width: '100%'},
    }), [top, visible])

    useEffect(() => {

        const domRow = ref.current

        let lastSize

        function sizeChanged() {
            if (lastSize === domRow.offsetHeight) return
            lastSize = domRow.offsetHeight
            //Register / update
            onUpdateRow(index, lastSize)
            debug(() => `onUpdateRow ${index}: height: ${lastSize}px`)
        }

        debug(() => `row ${index} add observer`)
        sizeChanged()

        let sizeObserver
        try {
            sizeObserver = new ResizeObserver(sizeChanged)
            sizeObserver.observe(domRow)
        }catch(e){
            window.log(e)
        }

        return () => {
            sizeObserver.unobserve(domRow)
        }

    }, [index, ref, onUpdateRow])

    const cells = []
    for (let [id, col] of columnMap) {
        cells.push(
            <RowCell
                key={col.id}
                index={index}
                rowId={id}
                column={col}
                dense={dense}
                verticalAlign={verticalAlign}
                data={data}
                global={global}
                {...col.props}
            >
                {defaultTo(renderCellContent, renderSimpleCellContent)(
                    {content: data[id], index: index, id: col.id, rowId: data[keyColumnId], data, global})}
            </RowCell>,
        )
    }

    return (
        <Row
            ref={ref}
            style={style}
            index={index}
            data={data}
            hover={hover}
            onClick={onClick}
            selected={rowSelected}
            global={global}
            keyColumnId={keyColumnId}
        >
            {cells}
        </Row>
    )
}