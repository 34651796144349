import {ApiEndPoint} from 'sopix/db/apiEndPoint'
import {GraphqlMutation} from 'sopix/db/graphqlMutation'
import {boundMethod} from 'autobind-decorator'

export class FieldsMutation extends GraphqlMutation{

    constructor(graphqlUrl, mutationName, response = []) {
        const apiEndPoint = new ApiEndPoint(graphqlUrl)
        super(apiEndPoint, mutationName, [
            ...response,
            'success',
            {errors: ['field', 'error']},
        ])
    }
    
    @boundMethod
    async query(params = {}){
        
        const regularParams = {}
        const nullParams = []
        for (let [name, value] of Object.entries(params)) {
            if (value === null || value === undefined) {
                nullParams.push(name)
            } else {
                regularParams[name] = value
            }
        }
        
        const {success, errors, ...result} = await super.query({...regularParams, nullParams: nullParams})
        
        const errorList = {}
        for (let {field, error} of errors){
            errorList[field] = error
        }
        
        return {success, errorList, ...result}
    }
    
}