 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {LiqtecmesIds} from './Liqtecmes'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const LiqtecmesCol = Object.freeze({
    tecnico : new LookupColumn(LiqtecmesIds.tecnico, Tables.User, 'nombre_completo'),
    idLiqtecmes : new SimpleColumn(LiqtecmesIds.idLiqtecmes, 11, 11, Align.RIGHT),
    m2 : new SimpleColumn(LiqtecmesIds.m2, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Liqtecmes] = LiqtecmesCol
