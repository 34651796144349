import {observer} from 'mobx-react'
import {AprobacionTipoValues} from 'sop/db-config/row-consts'
import {AproestaForm, AproestaFormPiece} from 'sop/componentes/trabajo/AproestaForm'
import React from 'react'
import {WorldObject} from 'sopix/piece/worldObject'
import {boundMethod} from 'autobind-decorator'
import {Aprobacion} from 'sop/db/Aprobacion'
import {action, observable} from 'mobx'
import {Aproesta} from 'sop/db/Aproesta'
import {ObserverHub} from 'sopix/utils/observers'
import {CalculadoraForm, CalculadoraFormPiece} from 'sop/componentes/trabajo/CalculadoraForm'
import {Aproposicion} from 'sop/db/Aproposicion'
import {AproposicionForm, AproposicionFormPiece} from 'sop/componentes/trabajo/AproposicionForm'
import {AproasignarForm, AproasignarFormPiece} from 'sop/componentes/trabajo/AproasignarForm'
import {Aproasignar} from 'sop/db/Aproasignar'
import {AproliqmanForm, AproliqmanFormPiece} from 'sop/componentes/liquidacion/AproliqmanForm'
import {Aproliqman} from 'sop/db/Aproliqman'
import {Tarea} from 'sop/db/Tarea'
import {AproplanoForm, AproplanoFormPiece} from 'sop/componentes/documento/AproplanoForm'
import {Aproplano} from 'sop/db/Aproplano'

export class AproSelectorObject extends WorldObject{

    @observable
    _idTipo
    
    onDirty = new ObserverHub()
    onSave = new ObserverHub()
    onAction = new ObserverHub()
    
    constructor(world) {
        super(world)

        this.formAproesta = new AproestaFormPiece(this.world)
        this._linkAproForm(this.formAproesta)        

        this.formCalculadora = new CalculadoraFormPiece(this.world)
        this._linkAproForm(this.formCalculadora)

        this.formAproposicion = new AproposicionFormPiece(this.world)
        this._linkAproForm(this.formAproposicion)

        this.formAproasignar = new AproasignarFormPiece(this.world)
        this._linkAproForm(this.formAproasignar)

        this.formAproliqman = new AproliqmanFormPiece(this.world)
        this._linkAproForm(this.formAproliqman)

        this.formAproplano = new AproplanoFormPiece(this.world)
        this._linkAproForm(this.formAproplano)

    }

    /**
     * @param {AproFormPiece} aproForm
     */
    _linkAproForm(aproForm){
        aproForm.frameFlags.delete('margin')
        aproForm.frameFlags.delete('margin2')
        aproForm.fieldManager.setReadOnly(true)
        aproForm.onDirty.subscribe(this.onDirty.notify)
        aproForm.onSave.subscribe(this.onSave.notify)
        aproForm.onAction.subscribe(this.onAction.notify)
    }

    /**
     * @returns {AproFormPiece|undefined}
     */
    get form(){
        switch(this.idTipo) {
            case AprobacionTipoValues.CAMBIAR_ESTADO_TRABAJO:
                return this.formAproesta
            case AprobacionTipoValues.CAMBIAR_POSICION_TRABAJO:
                return this.formAproposicion
            case AprobacionTipoValues.LIQUIDACION_EFECTIVOS:
                return this.formCalculadora
            case AprobacionTipoValues.ASIGNAR_VALORACIONES:
                return this.formAproasignar
            case AprobacionTipoValues.LIQUIDACION_MANUAL:
                return this.formAproliqman
            case AprobacionTipoValues.AUTORIZAR_ENVIO_PLANOS:
                return this.formAproplano
            default:
                return {}
        }
    }
    
    get idTipo(){
        return this._idTipo
    }

    @action.bound
    setIdTipo(idTipo){
        if (idTipo !== this._idTipo) {
            this._idTipo = idTipo
        }
    }


    @boundMethod
    aprobacionApplied(data){
        this.setIdTipo(data[Aprobacion.idTipo])
        
        const load = (aproListField, aproIdField, aproForm) => {
            const apros = data[aproListField]

            // Solo debería haber una
            const id = apros[0][aproIdField]
            aproForm.load(id)
        }
        
        switch (this.idTipo){
            case AprobacionTipoValues.CAMBIAR_ESTADO_TRABAJO:
                load(Aprobacion.aproestas, Aproesta.idAproesta, this.formAproesta)
                break

            case AprobacionTipoValues.CAMBIAR_POSICION_TRABAJO:
                load(Aprobacion.aproposiciones, Aproposicion.idAproposicion, this.formAproposicion)
                break

            case AprobacionTipoValues.LIQUIDACION_EFECTIVOS:
                load(Aprobacion.tareas, Tarea.idTarea, this.formCalculadora)
                break

            case AprobacionTipoValues.ASIGNAR_VALORACIONES:
                load(Aprobacion.aproasignar, Aproasignar.idAproasignar, this.formAproasignar)
                break

            case AprobacionTipoValues.LIQUIDACION_MANUAL:
                load(Aprobacion.aproliqmans, Aproliqman.idAproliqman, this.formAproliqman)
                break

            case AprobacionTipoValues.AUTORIZAR_ENVIO_PLANOS:
                load(Aprobacion.aproplanos, Aproplano.idAproplano, this.formAproplano)
                break

            default:
        }
    }
}


const AproElements = Object.freeze({
    [AprobacionTipoValues.CAMBIAR_ESTADO_TRABAJO]: AproestaForm,
    [AprobacionTipoValues.CAMBIAR_POSICION_TRABAJO]: AproposicionForm,
    [AprobacionTipoValues.LIQUIDACION_EFECTIVOS]: CalculadoraForm,
    [AprobacionTipoValues.ASIGNAR_VALORACIONES]: AproasignarForm,
    [AprobacionTipoValues.LIQUIDACION_MANUAL]: AproliqmanForm,
    [AprobacionTipoValues.AUTORIZAR_ENVIO_PLANOS]: AproplanoForm,
})

export const AproSelector = observer(
    /**
     * @param {AproSelectorObject} selector
     */
    ({selector, editable}) => {

        const idTipo = selector.idTipo
        const Element = AproElements[idTipo]
        return !Element ? null : <Element form={selector.form} editable={editable} />
    })