import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {observer} from 'mobx-react'
import {Field} from 'sopix/form/editors/Field'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {TrabajoView} from 'sop/db-config/row-consts'
import {BoolField} from 'sopix/form/editors/BoolField'
import {Tarea} from 'sop/db/Tarea'
import {TareaEdt} from 'sop/db/TareaEditors'
import {DateField} from 'sopix/form/editors/DateField'
import {ObraEdt} from 'sop/db/ObraEditors'
import {sopUrls} from 'sop/sop-pages'
import {UserEdt} from 'sop/db/UserEditors'
import {SistemaTable} from 'sop/db/SistemaTable'
import {PADRE_IDS, SISTEMA_IDS, SOLICITUD_IDS, TRABAJO_VIEW} from 'sop/componentes/trabajo/TrabajoFormPiece'
import {sopIcons} from 'sop/sop-icons'
import {Fields} from 'sopix/formComps/Fields'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {CalculadoraForm} from 'sop/componentes/trabajo/CalculadoraForm'
import {NumberField} from 'sopix/form/editors/NumberField'
import {TareaEstadoEdt} from 'sop/db/TareaEstadoEditors'
import {AproestaForm} from 'sop/componentes/trabajo/AproestaForm'
import {AproposicionForm} from 'sop/componentes/trabajo/AproposicionForm'
import {Permiso} from 'sop/db/Permiso'
import {userAuth} from 'sopix/session/userAuth'
import {canWriteField} from 'sopix/db-auth/db-auth-utils'
import {TareaFld} from 'sop/db/TareaTable'
import {AproPoperFrame} from 'sop/componentes/aprobacion/AproPoperFrame'
import {UserTable} from 'sop/db/UserTable'
import {filter} from 'lodash-es'
import {User} from 'sop/db/User'
import {usePiece} from 'sopix/piece/use-piece'


/**
 * @param {TrabajoFormPiece} form
 */
function renderEstadoCotiz(form){
    const H = form.fieldHelper
    const fld = form.getField
    
    if (!form.id) {
        return null
    }

    const aproestaProps = form.aproestaPoper.buttonProps
    const aproposicionProps = form.aproposicionPoper.buttonProps
    
    return (
        <>
            <Field {...H.obj(Tarea.estado, TareaEstadoEdt.estado)} {...aproestaProps} />
            {!fld(Tarea.fechaFinalizada) ? null:
                <DateField disabled {...H.edt(TareaEdt.fechaFinalizada)} />}
            {!fld(Tarea.ordenEstudio) ? null :
                <Field disabled {...H.edt(TareaEdt.ordenEstudio)} {...aproposicionProps} />}
            {!fld(Tarea.ordenValoracion) ? null :
                <Field disabled {...H.edt(TareaEdt.ordenValoracion)} {...aproposicionProps} />}

            <AproPoperFrame
                poper={form.aproestaPoper}
                maxWidth="sm"
            >
                <AproestaForm form={form.aproestaForm}/>
            </AproPoperFrame>

            <AproPoperFrame
                poper={form.aproposicionPoper}
                maxWidth="sm"
            >
                <AproposicionForm form={form.aproposicionForm}/>
            </AproPoperFrame>
            
        </>
    )
}

export const TrabajoFormHeader = observer(
    /**
     * @param {TrabajoFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField

        const esTarea = fld(TRABAJO_VIEW) === TrabajoView.TAREA

        const idResponsable = fld(Tarea.idResponsable)
        
        const responsableOptions = filter(form.lists[UserTable.graphqlId].rows, user => {
            return !user[User.inactivo] || idResponsable === user[User.id] 
        })
        
        return <>
            {form.view === TrabajoView.TAREA ? null : <DropdownField {...H.edt(TareaEdt.tipo)} 
                                                                    options={form.tareaTipoOtions} />}

            <DropdownField {...H.edt(TareaEdt.responsable)} options={responsableOptions} />

            {form.view === TrabajoView.TAREA ? null : renderEstadoCotiz(form)}

            <DateField {...H.edt(TareaEdt.fechaVencimiento)} />
            
            <BoolField {...H.edt(TareaEdt.urgente)} />

            <Field {...H.obj(TareaEdt.obra, ObraEdt.nombre)} {...H.btn(TareaEdt.obra, sopUrls.obra, sopIcons.Obra)} />

            <DropdownField multiple disabled={!canWriteField(TareaFld.solicitudList)}
                manager={form.fieldManager}
                {...SOLICITUD_IDS}
                options={form.solicitantesObra}
            />
        </>
    })


export const TrabajoFormFooter = observer(
    /**
     *
     * @param {TrabajoFormPiece} form
     * @returns {JSX.Element}
     * @constructor
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField

        return <>
            <DropdownField manager={form.fieldManager} {...PADRE_IDS} options={form.padreList} />

            {form.view !== TrabajoView.TAREA ? null :
                <DropdownField {...H.edt(TareaEdt.estado)} /> }
            
            {!fld(Tarea.idTarea) ? null : <>
                <DateField {...H.edt(TareaEdt.fechaCreacionOnlyDate)} disabled />
                <Field {...H.obj(TareaEdt.autor, UserEdt.nombreCompleto)} />
            </>}
        </>
    })





export const CotizacionForm = observer(
    /**
     * @param {TrabajoFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField

        const nuevo = !form.id
        const calculadoraButton = <HintIconButton Icon={sopIcons.Liquidacion} title="M² efectivos" 
            onClick={() => form.calcPoper.__apply(form.fields)} />

        const precio = fld(Tarea.precioSinRegulacion)
        const m2Efectivos = fld(Tarea.m2Efectivos)
        let precio_m2
        if (precio && m2Efectivos) {
            precio_m2 = Math.floor(100 * precio / m2Efectivos) / 100
        }

        return (
            <Fields>

                <TrabajoFormHeader form={form}/>

                <Field {...H.edt(TareaEdt.descripcion)} multiline rows={10}/>

                <NumberField {...H.edt(TareaEdt.m2Totales)} suffix=" m²" />
                <NumberField disabled {...H.edt(TareaEdt.m2Efectivos)} endAdornment={nuevo ? undefined : calculadoraButton}
                             suffix=" m²" extraWidth={nuevo ? undefined : 70} />

                <NumberField {...H.edt(TareaEdt.precioSinRegulacion)} suffix="€" />
                <NumberField {...H.obj(TareaEdt.precioSinRegulacion)} name="precio_m2" value={precio_m2} suffix="€/m²"
                             label="Precio/m²" nullify={!userAuth.isAllowed(Permiso.verM2Efectivos)} />

                <NumberField {...H.edt(TareaEdt.ratio)} />

                <DropdownField multiple disabled={!canWriteField(TareaFld.sistemaList)}
                    manager={form.fieldManager}
                    {...SISTEMA_IDS}
                    options={form.lists[SistemaTable.graphqlId].rows}
                />

                <Field {...H.edt(TareaEdt.refCliente)} />
                <DropdownField {...H.edt(TareaEdt.procedencia)} />

                <TrabajoFormFooter form={form}/>
                
                <PoperFrame poper={form.calcPoper} maxWidth="sm" >
                    <CalculadoraForm form={form.calcForm} calculadora={true} /> 
                </PoperFrame>
                
            </Fields>
        )
    })


export const TareaForm = observer(
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField

        return (
            <Fields>
                <TrabajoFormHeader form={form}/>

                <Field {...H.edt(TareaEdt.descripcion)} multiline rows={5}/>

                <TrabajoFormFooter form={form}/>
            </Fields>
        )
    })


export const TrabajoForm = observer(
    /**
     * @param {TrabajoFormPiece} form
     */
    ({form}) => {

    const isTarea = form.view === TrabajoView.TAREA

    usePiece(form)

    return (
        <PieceFrame piece={form} renderPiece={()=> {

            const H = form.fieldHelper
            const fld = form.getField

            return isTarea
            ? <TareaForm form={form} />
            : <CotizacionForm form={form} />

        }} />
    )
})