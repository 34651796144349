import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    wrap: {
        width: '100%',
    },
})) 

export function Wrap({orientation}) {
    
    const cls = useStyles()
    
    return (
        <div className={cls.wrap} />
    )
}
