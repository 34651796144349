import {getILikeFromKeyword} from 'sopix/db/graphql-utils'
import {Cuenta} from 'sop/db/Cuenta'
import {userAuth} from 'sopix/session/userAuth'
import {UserRoles} from 'sop/db-config/row-consts'
import {Representante} from 'sop/db/Representante'


/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export function cuentaFilter(searchFields) {
    if (!searchFields) return {}
    const result = {}
    for (let [key, value] of Object.entries(searchFields)) {
        if (!value) {
            continue
        }

        const filters = {
            buscar: () => {
                const words = value.split(' ')
                const cuentaEntries = words.map(word => ({[`${Cuenta.cuenta}Ilike`]: getILikeFromKeyword(word)}))
                result.or = [
                    {and: cuentaEntries},
                ]
            },
        }

        const filter = filters[key]
        filter && filter()
    }

    if (userAuth.role === UserRoles.REPRESENTANTE) {
        result[Representante.idRepresentante] = userAuth.userId
    }

    return result
}

