 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ObraIds} from './Obra'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbBool, DbDateTime, DbFloat, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {TipologiaIds} from './Tipologia'
import {StatusIds} from './Status'
import {UserIds} from './User'

export const ObraTable = new DbTable(
    Tables.Obra,
    'Obras',
    graphqlUrl,
    'Obra',
    [ObraIds.idObra],
    [ObraIds.idObra, ObraIds.cod, ObraIds.nombre, ObraIds.idTipologia, ObraIds.m2, ObraIds.direccion, ObraIds.cp, ObraIds.localidad, ObraIds.provincia, ObraIds.pais, ObraIds.idStatus, ObraIds.fechaAlta, ObraIds.fechaModificacion, ObraIds.idAutor, ObraIds.idModificacion, ObraIds.lat, ObraIds.lng, ObraIds.altitud, ObraIds.locked, ObraIds.contadorEstudios, ObraIds.contadorTareas, ObraIds.contadorValoraciones, ObraIds.contadorCambioOferta, ObraIds.fechaEjecucion, ObraIds.passiveHouse, ObraIds.obraRepresentativa, ObraIds.odooCambiado],
    [ObraIds.tipologia, ObraIds.status, ObraIds.autor, ObraIds.modificacion],
    [ObraIds.solicitudes, ObraIds.tareas, ObraIds.notas, ObraIds.relacionadasObra, ObraIds.relacionadasRelacionada, ObraIds.representantes, ObraIds.avisos, ObraIds.aprobaciones, ObraIds.ejecuciones, ObraIds.ejecucionesCuentas, ObraIds.documentos],
    ['verObras'],
    ['modificarObras'],
    ['crearObras'],
    ['eliminarObras'],
    [],
    [],
)


export const ObraFld = Object.freeze({
    solicitudes : new DbObjectList(Tables.Obra, ObraIds.solicitudes, Tables.Solicitud),
    tareas : new DbObjectList(Tables.Obra, ObraIds.tareas, Tables.Tarea),
    notas : new DbObjectList(Tables.Obra, ObraIds.notas, Tables.Nota),
    relacionadasObra : new DbObjectList(Tables.Obra, ObraIds.relacionadasObra, Tables.Relacionada),
    relacionadasRelacionada : new DbObjectList(Tables.Obra, ObraIds.relacionadasRelacionada, Tables.Relacionada),
    representantes : new DbObjectList(Tables.Obra, ObraIds.representantes, Tables.Representante),
    avisos : new DbObjectList(Tables.Obra, ObraIds.avisos, Tables.Aviso),
    aprobaciones : new DbObjectList(Tables.Obra, ObraIds.aprobaciones, Tables.Aprobacion),
    ejecuciones : new DbObjectList(Tables.Obra, ObraIds.ejecuciones, Tables.Ejecucion),
    ejecucionesCuentas : new DbObjectList(Tables.Obra, ObraIds.ejecucionesCuentas, Tables.EjecucionCuenta),
    documentos : new DbObjectList(Tables.Obra, ObraIds.documentos, Tables.Documento),
    tipologia : new DbObject(Tables.Obra, ObraIds.tipologia, ObraIds.idTipologia, 'Tipología'),
    status : new DbObject(Tables.Obra, ObraIds.status, ObraIds.idStatus, 'Status'),
    autor : new DbObject(Tables.Obra, ObraIds.autor, ObraIds.idAutor, 'Autor'),
    modificacion : new DbObject(Tables.Obra, ObraIds.modificacion, ObraIds.idModificacion, 'Modificacion'),
    idObra : new DbIntId(Tables.Obra, ObraIds.idObra, 'Id obra'),
    cod : new DbText(Tables.Obra, ObraIds.cod, 'Cod', 25),
    nombre : new DbText(Tables.Obra, ObraIds.nombre, 'Descripción', 200),
    idTipologia : new DbObjectId(Tables.Obra, ObraIds.idTipologia, 'Id tipología', {foreignTableId: Tables.Tipologia, foreignKeyFieldId: TipologiaIds.idTipologia}),
    m2 : new DbFloat(Tables.Obra, ObraIds.m2, 'M²'),
    direccion : new DbText(Tables.Obra, ObraIds.direccion, 'Dirección', 255),
    cp : new DbText(Tables.Obra, ObraIds.cp, 'C.P.', 7),
    localidad : new DbText(Tables.Obra, ObraIds.localidad, 'Localidad', 100),
    provincia : new DbText(Tables.Obra, ObraIds.provincia, 'Provincia', 80),
    pais : new DbText(Tables.Obra, ObraIds.pais, 'País', 30),
    idStatus : new DbObjectId(Tables.Obra, ObraIds.idStatus, 'Id status', {foreignTableId: Tables.Status, foreignKeyFieldId: StatusIds.idStatus}),
    fechaAlta : new DbDateTime(Tables.Obra, ObraIds.fechaAlta, 'Fecha creación'),
    fechaModificacion : new DbDateTime(Tables.Obra, ObraIds.fechaModificacion, 'Fecha edición'),
    idAutor : new DbObjectId(Tables.Obra, ObraIds.idAutor, 'Creado', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idModificacion : new DbObjectId(Tables.Obra, ObraIds.idModificacion, 'Editado', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    lat : new DbText(Tables.Obra, ObraIds.lat, 'Latitud', 20),
    lng : new DbText(Tables.Obra, ObraIds.lng, 'Longitud', 20),
    altitud : new DbInt(Tables.Obra, ObraIds.altitud, 'Altitud'),
    locked : new DbBool(Tables.Obra, ObraIds.locked, 'Vinculado a mapa'),
    contadorEstudios : new DbInt(Tables.Obra, ObraIds.contadorEstudios, 'Contador estudios'),
    contadorTareas : new DbInt(Tables.Obra, ObraIds.contadorTareas, 'Contador tareas'),
    contadorValoraciones : new DbInt(Tables.Obra, ObraIds.contadorValoraciones, 'Contador valoraciones'),
    contadorCambioOferta : new DbInt(Tables.Obra, ObraIds.contadorCambioOferta, 'Contador cambio oferta'),
    fechaEjecucion : new DbDateTime(Tables.Obra, ObraIds.fechaEjecucion, 'Ejecución'),
    passiveHouse : new DbInt(Tables.Obra, ObraIds.passiveHouse, 'Passivhaus'),
    obraRepresentativa : new DbInt(Tables.Obra, ObraIds.obraRepresentativa, 'Obra representativa'),
    odooCambiado : new DbInt(Tables.Obra, ObraIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.Obra, ObraFld) 
