import React from 'react'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {Aprobacion} from 'sop/db/Aprobacion'
import {AprobacionCol} from 'sop/db/AprobacionColumns'
import {AprobacionFld, AprobacionTable} from 'sop/db/AprobacionTable'
import {AprobacionTipoCol} from 'sop/db/AprobacionTipoColumns'
import {UserCol} from 'sop/db/UserColumns'
import {TareaCol} from 'sop/db/TareaColumns'
import {ObraCol} from 'sop/db/ObraColumns'
import {UserFld} from 'sop/db/UserTable'
import {TareaFld} from 'sop/db/TareaTable'
import {ObraFld} from 'sop/db/ObraTable'
import {AprobacionTipoFld} from 'sop/db/AprobacionTipoTable'
import {aprobacionFilter} from 'sop/componentes/aprobacion/aprobacionFilter'
import {User} from 'sop/db/User'
import {Tarea} from 'sop/db/Tarea'
import {Obra} from 'sop/db/Obra'
import {AprobacionTipo} from 'sop/db/AprobacionTipo'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'
import {AprobacionDesc, AprobacionIcon} from 'sop/componentes/aprobacion/aprobacion-consts'
import {HintIcon} from 'sopix/formComps/HintIcon'


class AprobacionPainter extends TablePainter{
    
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds}) {
        switch(id){
            case Aprobacion.solicitante:
                return fld(User.nombreCompleto)
            case Aprobacion.tarea:
                return fld(Tarea.ref)
            case Aprobacion.obra:
                return fld(Obra.cod)
            case Aprobacion.tipo:
                return fld(AprobacionTipo.tipo)
            case Aprobacion.estado:
                const Icon = AprobacionIcon[value]
                return <HintIcon Icon={Icon} title={AprobacionDesc[value]} />
            default:
                return formatter(value)
        }
    }
}

export class AprobacionListPiece extends TablePiece{

    constructor(world, defaultFilter) {

        const TABLE = AprobacionTable
        const ID_FIELD = Aprobacion.idAprobacion
        const DEFAULT_ORDER = [new OrderEntry(Aprobacion.fechaCreacion, OrderDirection.DESC)]

        const COLUMNS = [
            AprobacionCol.fechaCreacion,
            [AprobacionFld.solicitante, UserCol.nombreCompleto],
            AprobacionCol.solicitud,
            [AprobacionFld.tipo, AprobacionTipoCol.tipo],
            [AprobacionFld.tarea, TareaCol.ref],
            [AprobacionFld.obra, ObraCol.cod],
            AprobacionCol.estado,
        ]
        
        const FIELDS = [
            AprobacionFld.idAprobacion,
            AprobacionFld.estado,
            AprobacionFld.fechaCreacion,
            AprobacionFld.solicitud,
            [AprobacionFld.solicitante, [UserFld.id, UserFld.nombreCompleto]],
            [AprobacionFld.tarea, [TareaFld.ref]],
            [AprobacionFld.obra, [ObraFld.idObra, ObraFld.cod]],
            [AprobacionFld.tipo, [AprobacionTipoFld.tipo]],
        ]
        
        const painter = new AprobacionPainter(world, COLUMNS)
        
        const filtrator = graphqlFiltratorBuilder(world, TABLE, FIELDS, {
            graphqlFilterCalculator: aprobacionFilter, defaultFilter: defaultFilter, defaultOrder: DEFAULT_ORDER})
        
        const selection = new RowSelection(world, filtrator, ID_FIELD) 
        
        super(world, ID_FIELD, filtrator, painter, selection)
    }


}

