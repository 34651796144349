import TextField from '@material-ui/core/TextField'
import React, {useMemo} from 'react'
import {FieldController} from 'sopix/form/FieldController'
import {getInputLabelProps, getInputProps, useFieldStyle} from 'sopix/form/field-style'
import {doNothing} from 'sopix/utils/misc'
import {StringCast} from 'sopix/form/form-types'
import {getAdornmentInputProps} from 'sopix/form/editor-utils'

/**
 * @param {FieldManager} manager
 * @param {DataType} dataType
 */
export const Field = ({inputRef, manager, name, value, dataType, width: baseWidth, nullValue, 
              autoFocus, small, onChange, startAdornment, endAdornment, extraWidth = 0, text, textVariant, 
              disabled: disabledBase, nullify, ...otherProps}) => {

    const width = baseWidth + extraWidth
    const disabled = disabledBase || (manager && manager.readOnly)

    const fieldCls = useFieldStyle({width, small})
    
    const cast = useMemo(() => new StringCast(dataType, nullValue), [dataType, nullValue])
    
    if (nullify) {
        return null
    }
    
    function getOnChange(controllerOnChange){
        return function(e) {
            const value = e.target.value
            controllerOnChange && controllerOnChange(cast.fromString(value))
        }
    }

    function render({onChange: controllerOnChange, onBlur, value, error}) {
        return (
            <TextField
                {...otherProps}
                inputRef={inputRef}
                className={fieldCls.field}
                onChange={getOnChange(controllerOnChange)}
                inputProps={{onBlur: onBlur}}
                InputLabelProps={getInputLabelProps(fieldCls, small)}
                InputProps={{
                    ...getInputProps(fieldCls, small), 
                    ...getAdornmentInputProps(startAdornment, endAdornment),
                }}
                value={cast.toString(value)}
                error={error !== undefined}
                helperText={!!error ? error : text}
                autoFocus={autoFocus}
                FormHelperTextProps={textVariant !== 'alert' ? undefined : {error: true}}
                disabled={disabled}
            />
        )
    }

    if (manager === undefined) {
        return render({value: value, onBlur: doNothing, onChange: onChange})  
        
    } else {
        return (
            <FieldController
                fieldManager={manager}
                name={name}
                render={render}
            />
        )
    }
}
