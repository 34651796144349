 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ContactoIds} from './Contacto'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {DireccionIds} from './Direccion'
import {UserIds} from './User'

export const ContactoTable = new DbTable(
    Tables.Contacto,
    'Contactos',
    graphqlUrl,
    'Contacto',
    [ContactoIds.idContacto],
    [ContactoIds.idContacto, ContactoIds.idCuenta, ContactoIds.idDireccion, ContactoIds.nombre, ContactoIds.tel, ContactoIds.correo, ContactoIds.notas, ContactoIds.principal, ContactoIds.fechaAlta, ContactoIds.fechaModificacion, ContactoIds.idAutor, ContactoIds.idModificacion, ContactoIds.odooCambiado],
    [ContactoIds.direccion, ContactoIds.autor, ContactoIds.modificacion],
    [ContactoIds.solicitudes],
    ['verCuentas'],
    ['modificarCuentas'],
    undefined,
    undefined,
    [],
    [],
)


export const ContactoFld = Object.freeze({
    solicitudes : new DbObjectList(Tables.Contacto, ContactoIds.solicitudes, Tables.Solicitud),
    direccion : new DbObject(Tables.Contacto, ContactoIds.direccion, ContactoIds.idDireccion, 'Direccion'),
    autor : new DbObject(Tables.Contacto, ContactoIds.autor, ContactoIds.idAutor, 'Autor'),
    modificacion : new DbObject(Tables.Contacto, ContactoIds.modificacion, ContactoIds.idModificacion, 'Modificacion'),
    idContacto : new DbIntId(Tables.Contacto, ContactoIds.idContacto, 'Id contacto'),
    idCuenta : new DbInt(Tables.Contacto, ContactoIds.idCuenta, 'Id cuenta'),
    idDireccion : new DbObjectId(Tables.Contacto, ContactoIds.idDireccion, 'Id direccion', {foreignTableId: Tables.Direccion, foreignKeyFieldId: DireccionIds.idDireccion}),
    nombre : new DbText(Tables.Contacto, ContactoIds.nombre, 'Nombre', 80),
    tel : new DbText(Tables.Contacto, ContactoIds.tel, 'Tel', 40),
    correo : new DbText(Tables.Contacto, ContactoIds.correo, 'Correo', 70),
    notas : new DbText(Tables.Contacto, ContactoIds.notas, 'Notas', 1000),
    principal : new DbInt(Tables.Contacto, ContactoIds.principal, 'Principal'),
    fechaAlta : new DbDateTime(Tables.Contacto, ContactoIds.fechaAlta, 'Fecha creación'),
    fechaModificacion : new DbDateTime(Tables.Contacto, ContactoIds.fechaModificacion, 'Fecha edición'),
    idAutor : new DbObjectId(Tables.Contacto, ContactoIds.idAutor, 'Creado', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idModificacion : new DbObjectId(Tables.Contacto, ContactoIds.idModificacion, 'Editado', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    odooCambiado : new DbInt(Tables.Contacto, ContactoIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.Contacto, ContactoFld) 
