import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {SageDomicilio} from 'sop/db/SageDomicilio'
import {SageDomicilioFld, SageDomicilioTable} from 'sop/db/SageDomicilioTable'
import {SageDomicilioCol} from 'sop/db/SageDomicilioColumns'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {DbSource} from 'sopix/data-source/dbSource'


export class SageDireccionPainter extends TablePainter{
    
}


export class SageDireccionesPiece extends Piece{
    
    idField = SageDomicilio.numeroDomicilio
    
    constructor(world) {
        super(world)

        const FIELDS = [
            SageDomicilioFld.codigoEmpresa,
            SageDomicilioFld.codigoCliente,
            SageDomicilioFld.numeroDomicilio,
            SageDomicilioFld.domicilio,
            SageDomicilioFld.municipio,
            SageDomicilioFld.provincia,
            SageDomicilioFld.nacion,
        ]
        
        const COLUMNS = [
            SageDomicilioCol.domicilio,
            SageDomicilioCol.municipio,
            SageDomicilioCol.provincia,
            SageDomicilioCol.nacion,
        ]
    
        this.painter = new SageDireccionPainter(world, COLUMNS)
        
        this.dbSource = new DbSource(world,
            new TableGraphql(SageDomicilioTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(SageDomicilio.numeroDomicilio)},
                defaultGraphqlFilter: {[SageDomicilio.tipoDomicilio]: 'E'},
            })

        this.load = this._state(Symbol('LOAD'), async (codigoEmpresa, codigoCliente) => {
            this.dbSource._setGraphqlFilter({
                ...this.dbSource.graphqlFilter,
                [SageDomicilio.codigoEmpresa]: codigoEmpresa,
                [SageDomicilio.codigoCliente]: codigoCliente,
            })
            await this.dbSource.refresh()
        })

    }        
}


export const SageDirecciones = getPieceContainer('list', observer(
    /**
     * @param {SageDireccionesPiece} list
     */
    ({list}) => {

        const sya = list._action
        
        return (
            <Frame>
                <BlTable fitContainer dense
                     global={list}
                     verticalAlign="center"
                     data={list.dbSource.data}
                     keyColumnId={list.idField}
                     columns={list.painter.boundlessColumns}
                     renderCellContent={sya(list.painter.boundlessPainter)}
                     renderHeaderContent={sya(list.painter.headerPainter)}
                />
            </Frame>
        )
}))
