import {observer} from 'mobx-react'
import {TabBar} from 'sopix/formComps/TabFrame'
import React from 'react'
import {SageTabs} from 'sop/componentes/cuenta-sage/sage-cuenta-defs'
import {SageDescuentos} from 'sop/componentes/cuenta-sage/SageDescuentos'
import {SageDirecciones} from 'sop/componentes/cuenta-sage/SageDirecciones'
import {Tab} from '@material-ui/core'
import {Bar} from 'sopix/formComps/Bar'
import {SagePedidos} from 'sop/componentes/cuenta-sage/SagePedidos'

export const SageTab = observer(
    /**
     * @param {SageCuentaInfoPiece} info
     */
    ({info}) => {

        const sya = info._action
        
        const tabs = new Map([
            [SageTabs.DESCUENTOS, [
                <Tab key={SageTabs.DESCUENTOS} label="Descuentos" />,
                <SageDescuentos key={SageTabs.DESCUENTOS} list={info.sageDescuentos} />
            ]],
            [SageTabs.DIRECCIONES, [
                <Tab key={SageTabs.DIRECCIONES} label="Direcciones" />,
                <SageDirecciones key={SageTabs.DIRECCIONES} list={info.sageDirecciones} />
            ]],
            [SageTabs.PEDIDOS, [
                <Tab key={SageTabs.PEDIDOS} label="Pedidos" />,
                <SagePedidos key={SageTabs.PEDIDOS} list={info.sagePedidos} />
            ]],
        ])
        
        const tabIds = Object.values(SageTabs)
        
        const tabIndex = tabIds.indexOf(info.sageTab)
        
        if (!info.sageForm.valid) {
            return null
        }
        
        return (
            <>
                <Bar>
                    <TabBar tabs={[...tabs.values()].map(entry => entry[0])} value={tabIndex}
                            onChange={sya(
                                value =>
                                    info.setSageTab(tabIds[value])
                            )} />
                </Bar>
                {tabs.get(info.sageTab)[1]}
            </>
        )
})
