import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import AssignmentIcon from '@material-ui/icons/Assignment'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import RoomIcon from '@material-ui/icons/Room'
import FaceIcon from '@material-ui/icons/Face'
import EditLocationIcon from '@material-ui/icons/EditLocation'
import LocationOffOutlinedIcon from '@material-ui/icons/LocationOffOutlined'
import {DraftingCompassIcon} from 'sopix/icon/awesome/draftingCompassSolid'
import {HandshakeRegularIcon} from 'sopix/icon/awesome/handshakeRegular'
import PersonIcon from '@material-ui/icons/Person'
import {StickyNoteSolidIcon} from 'sopix/icon/awesome/stickyNoteSolid'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import TrafficIcon from '@material-ui/icons/Traffic'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import TripOriginIcon from '@material-ui/icons/TripOrigin'
import FolderIcon from '@material-ui/icons/Folder'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import PauseIcon from '@material-ui/icons/Pause'
import GridOnIcon from '@material-ui/icons/GridOn'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import NotificationsIcon from '@material-ui/icons/Notifications'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import ViewListIcon from '@material-ui/icons/ViewList'
import PanToolIcon from '@material-ui/icons/PanTool'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MapIcon from '@material-ui/icons/Map'

export const sopIcons = Object.freeze({
    Obra: LocationCityIcon,
    Cuenta: AssignmentIndIcon,
    Solicitante: PersonPinIcon,
    Cotizacion: AssignmentIcon,
    Estudio: DraftingCompassIcon,
    Valoracion: ListAltIcon,
    ValoracionAsignar: PeopleAltIcon, 
    Aprobacion: TrafficIcon,
    Documento: InsertDriveFileIcon,
    Nota: StickyNoteSolidIcon,
    Tarea: PlaylistAddCheckIcon,
    Carpeta: FolderIcon,
    Trabajo: AssignmentTurnedInIcon,
    Aviso: NotificationsIcon,
    Mapa: RoomIcon,
    Representante: FaceIcon,
    Pedido: ReceiptIcon,
    Ejecucion: HandshakeRegularIcon,
    LocationEdit: EditLocationIcon,
    LocationOff: LocationOffOutlinedIcon,
    Usuario: PersonIcon,
    Yo: AccountCircleIcon,
    Sesion: VpnKeyIcon,
    Procedencia: TripOriginIcon,
    Efectivos: GridOnIcon,
    Liquidacion: GridOnIcon,
    Planificador: LowPriorityIcon,
    Tecnico: PersonIcon,
    TrabajoBloqueado: PauseIcon,
    TrabajoEnProgreso: PlayArrowIcon,
    TrabajoCancelado: ClearIcon,
    TrabajoRealizado: DoneIcon,
    TrabajoEstado: BubbleChartIcon,
    TrabajoPosicion: ViewListIcon,
    AsumirEstudio: PanToolIcon,
    Estadisticas: EqualizerIcon,
    Bien: ThumbUpIcon,
    Mal: ThumbDownIcon,
    File: AttachFileIcon,
    Direccion: LocationOnIcon,
    Contacto: PersonOutlineIcon,
    Planos: MapIcon,
})
