 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const ContadorIds = Object.freeze({
    idContador: Symbol('idContador'),
    contador: Symbol('contador'),
    letra: Symbol('letra'),
    numero: Symbol('numero'),
})

export const Contador = Object.freeze({
    idContador: 'idContador',
    contador: 'contador',
    letra: 'letra',
    numero: 'numero',
})
