 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SolicitudIds = Object.freeze({
    notas: Symbol('notas'),
    documentos: Symbol('documentos'),
    tareaList: Symbol('tareaList'),
    cuenta: Symbol('cuenta'),
    direccion: Symbol('direccion'),
    contacto: Symbol('contacto'),
    obra: Symbol('obra'),
    autor: Symbol('autor'),
    idSolicitud: Symbol('idSolicitud'),
    idCuenta: Symbol('idCuenta'),
    idDireccion: Symbol('idDireccion'),
    idContacto: Symbol('idContacto'),
    idObra: Symbol('idObra'),
    fechaCreacion: Symbol('fechaCreacion'),
    idAutor: Symbol('idAutor'),
    tipoCuenta: Symbol('tipoCuenta'),
    idTipo: Symbol('idTipo'),
    odooCambiado: Symbol('odooCambiado'),
    odooDireccionTecnicoId: Symbol('odooDireccionTecnicoId'),
    odooCuentaCotizaciones: Symbol('odooCuentaCotizaciones'),
})

export const Solicitud = Object.freeze({
    notas: 'notas',
    documentos: 'documentos',
    tareaList: 'tareaList',
    cuenta: 'cuenta',
    direccion: 'direccion',
    contacto: 'contacto',
    obra: 'obra',
    autor: 'autor',
    idSolicitud: 'idSolicitud',
    idCuenta: 'idCuenta',
    idDireccion: 'idDireccion',
    idContacto: 'idContacto',
    idObra: 'idObra',
    fechaCreacion: 'fechaCreacion',
    idAutor: 'idAutor',
    tipoCuenta: 'tipoCuenta',
    idTipo: 'idTipo',
    odooCambiado: 'odooCambiado',
    odooDireccionTecnicoId: 'odooDireccionTecnicoId',
    odooCuentaCotizaciones: 'odooCuentaCotizaciones',
})
