 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AvisoTipoIds} from './AvisoTipo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AvisoTipoEdt = Object.freeze({
    idTipo : new SimpleEditor(AvisoTipoIds.idTipo, 11, 11),
    tipo : new SimpleEditor(AvisoTipoIds.tipo, 20, 10),
})

tableEditors[Tables.AvisoTipo] = AvisoTipoEdt
