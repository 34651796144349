 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {CuentaTipoIds} from './CuentaTipo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const CuentaTipoEdt = Object.freeze({
    idTipo : new SimpleEditor(CuentaTipoIds.idTipo, 11, 11),
    tipo : new SimpleEditor(CuentaTipoIds.tipo, 15, 10),
})

tableEditors[Tables.CuentaTipo] = CuentaTipoEdt
