import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import {Push} from 'sopix/formComps/Push'
import {Separator} from 'sopix/formComps/Separator'
import {Frame} from 'sopix/formComps/Frame'
import {Direction, Edge, getTransposedDirection} from 'sopix/formComps/comp-classes'
import {Bar} from 'sopix/formComps/Bar'

const useStyles = makeStyles(theme => ({
    tabs: {
        display: 'flex',
    },
    
    indicatorRowEnd: {
        top: 0,
    },
    
    indicatorColumnEnd: {
        left: 0,
    },
    column: {
        flexDirection: 'column',
    },

    columnReverse: {
        flexDirection: 'column-reverse',
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    
})) 


export function TabBar({value, tabs, direction, absolute, edge, onChange, expand, children}){

    const cls = useStyles()

    const vertical = direction === Direction.COLUMN || direction === Direction.COLUMN_REVERSE

    function handleOnChange(event, newValue){
        onChange(newValue)
    }

    return (
        <Bar direction={direction} absolute={absolute} expand={expand} >
            <Tabs
                className={cls.tabs}
                classes={{
                    flexContainer: cls[direction],
                    ...(edge !== Edge.END ? undefined :
                        {indicator: vertical ? cls.indicatorColumnEnd : cls.indicatorRowEnd}),
                }}
                orientation={vertical ? 'vertical' : undefined}
                value={value}
                onChange={handleOnChange}
            >
                {tabs}
            </Tabs>
            {children}
        </Bar>
    )
}


export function TabFrame({value, onChange, absolute, direction, edge, 
                             tabs, buttons=[], tabButtons, children}) {

    const vertical = direction === Direction.COLUMN || direction === Direction.COLUMN_REVERSE

    return (
        <Frame direction={getTransposedDirection(direction, edge)}>
            <TabBar onChange={onChange} absolute={absolute} value={value} direction={direction} edge={edge} tabs={tabs}>
                {!tabButtons ? null : <Separator key="tabButtons" orientation={vertical ? 'horizontal' : 'vertical'}/>}
                {!tabButtons ? null : tabButtons}
                <Push />
                {buttons}
            </TabBar>
            {children}
        </Frame>
    )
}
