 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproliqmanIds} from './Aproliqman'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbObject, DbObjectId, DbText} from 'sopix/db/dbField'
import {AprobacionIds} from './Aprobacion'
import {UserIds} from './User'

export const AproliqmanTable = new DbTable(
    Tables.Aproliqman,
    'Aproliqmans',
    graphqlUrl,
    'Aproliqman',
    [AproliqmanIds.idAproliqman],
    [AproliqmanIds.idAproliqman, AproliqmanIds.idAprobacion, AproliqmanIds.idResponsable, AproliqmanIds.m2Efectivos, AproliqmanIds.justificacion],
    [AproliqmanIds.aprobacion, AproliqmanIds.responsable],
    [],
    ['verAprobaciones'],
    ['solicitarLiquidacion'],
    undefined,
    undefined,
    [[2, ['aprobarLiquidacionManual'], [AproliqmanIds.m2Efectivos]]],
    [],
)


export const AproliqmanFld = Object.freeze({
    aprobacion : new DbObject(Tables.Aproliqman, AproliqmanIds.aprobacion, AproliqmanIds.idAprobacion, 'Aprobacion'),
    responsable : new DbObject(Tables.Aproliqman, AproliqmanIds.responsable, AproliqmanIds.idResponsable, 'Responsable'),
    idAproliqman : new DbIntId(Tables.Aproliqman, AproliqmanIds.idAproliqman, 'Id aproliqman'),
    idAprobacion : new DbObjectId(Tables.Aproliqman, AproliqmanIds.idAprobacion, 'Id aprobacion', {foreignTableId: Tables.Aprobacion, foreignKeyFieldId: AprobacionIds.idAprobacion}),
    idResponsable : new DbObjectId(Tables.Aproliqman, AproliqmanIds.idResponsable, 'Id responsable', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    m2Efectivos : new DbInt(Tables.Aproliqman, AproliqmanIds.m2Efectivos, 'M² efectivos'),
    justificacion : new DbText(Tables.Aproliqman, AproliqmanIds.justificacion, 'Justificacion', 1000),
})

registerTableFields(Tables.Aproliqman, AproliqmanFld) 
