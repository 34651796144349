 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproplanoIds} from './Aproplano'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const AproplanoCol = Object.freeze({
    idAproplano : new SimpleColumn(AproplanoIds.idAproplano, 11, 11, Align.RIGHT),
    justificacion : new SimpleColumn(AproplanoIds.justificacion, 40, 16, Align.LEFT),
})

tableColumns[Tables.Aproplano] = AproplanoCol
