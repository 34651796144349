import React from 'react'
import {observer} from 'mobx-react'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {Frame} from 'sopix/formComps/Frame'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {AprobacionSearch, AprobacionSearchPiece} from 'sop/componentes/aprobacion/AprobacionSearch'
import {AprobacionListPiece} from 'sop/componentes/aprobacion/AprobacionList'
import {AprobacionForm, AprobacionFormPiece} from 'sop/componentes/aprobacion/AprobacionForm'
import {boundMethod} from 'autobind-decorator'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {Aprobacion} from 'sop/db/Aprobacion'
import {AprobacionTipo} from 'sop/db/AprobacionTipo'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {icons} from 'sopix/icon/icons'
import {AprobacionTable} from 'sop/db/AprobacionTable'
import {listFormSplitDefault} from 'sop/sop-config'
import {ErrorList} from 'sopix/errors/ErrorList'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {UrlManager} from 'sopix/pieces/urlManager'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {aprobacionCheck, setRefreshAprobacionesPage} from 'sop/componentes/aprobacion/aprobacionCheck'
import {AproSelectorObject} from 'sop/componentes/aprobacion/AproSelector'
import {Push} from 'sopix/formComps/Push'
import {
    AproAction,
    AprobacionEstado,
    AprobacionIcon,
    getDefaultAprobacionFilter,
} from 'sop/componentes/aprobacion/aprobacion-consts'
import {refreshTrabajosPage} from 'sop/componentes/trabajo/trabajoCheck'
import {linkPageListSearchFormUrlWithLoad} from 'sopix/piece-linkers/list-form-url'
import {FormControl} from 'sopix/piece-components/FormControl'
import {RechazoForm, RechazoFormPiece} from 'sop/componentes/aprobacion/RechazoForm'
import {Poper} from 'sopix/piece-objects/Poper'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {getAuthSingleton} from 'sopix/session/auth-singletons'
import {userAuth} from 'sopix/session/userAuth'
import {ALERT_CANCEL, ALERT_OK} from 'sopix/alert/alert-defs'

export class AprobacionPagePiece extends PagePiece{

    _aprobacionesQL = new TableGraphql(AprobacionTable)
    
    /** @type {AproSelectorObject} */
    apro
    
    constructor() {
        super()
        this.apro = new AproSelectorObject(this.world)
        this.form = new AprobacionFormPiece(this.world, this.apro)
        this.urlManager = new UrlManager(this.world, this.form.idField, sopUrls.aprobaciones, sopUrls.aprobacion)

        const DEFAULT_FILTER = getDefaultAprobacionFilter()
        
        this.search = new AprobacionSearchPiece(this.world, {
            defaultFields: DEFAULT_FILTER
        })

        this.list = new AprobacionListPiece(this.world, DEFAULT_FILTER)

        this.rechazoForm = new RechazoFormPiece(this.world.cloneWithOwnErrors())
        this.poper = new Poper(this.rechazoForm, {askDiscard: false})
        this.rechazoForm.onDirty.subscribe(this.poper.setDirty)

        linkPageListSearchFormUrlWithLoad(this, this.list, this.search, this.form, this.urlManager)

        this.apro.onDirty.subscribe(this.list.selection.lock)
        this.apro.onDirty.subscribe(this.urlManager.rowDirty)
        this.apro.onSave.subscribe(this.list.filtrator.refresh)
        this.apro.onAction.subscribe(() => {
            this.list.selection.rowDeleted(this.form.id)
            this.refresh()
            refreshTrabajosPage()
            aprobacionCheck.refresh()
        })
        
        this.form.onApply.subscribe(this.apro.aprobacionApplied)

        setRefreshAprobacionesPage(this.externalRefresh)
    }

    async _init() {
        await super._init()
        await aprobacionCheck.refresh()
    }
    
    @boundMethod
    async externalRefresh(){
        await Promise.all([
            this.list.filtrator.refresh(),
            this.list.selection.set(),
        ])
    }
    
    @boundMethod
    async refreshExtra(){
        await Promise.all([
            aprobacionCheck.refresh(),
            this.refresh(),
        ])
    }
    __refreshExtra = this._asyncAction(this.refreshExtra)

    @boundMethod
    async aprobar(){
        if (ALERT_OK !== await askAlert(new AlertDefinition(`¿Aprobar?`,
            `${this.form.fields[Aprobacion.solicitud]}`,[
                [ALERT_OK, 'Aprobar'],
                [ALERT_CANCEL, 'Cancelar'],
            ]))) {
            return
        }

        await this.apro.form.action(AproAction.APROBAR)
    }


    @boundMethod
    async denegar(){
        this.rechazoForm.fieldManager.load({})
        this.poper._title = 'Denegar aprobación'
        this.poper._icon = icons.Deny
        const ok = await this.poper.openAndWait()
        if (!ok) {
            return
        }

        await this.apro.form.action(AproAction.DENEGAR, {
            [Aprobacion.razonRechazo]: this.rechazoForm.getField(Aprobacion.razonRechazo)
        })
    }


    @boundMethod
    async corregir(){
        this.rechazoForm.fieldManager.load({})
        this.poper.acceptButton = 'Pedir corrección'
        this.poper._title = 'Pedir corrección'
        this.poper._icon = icons.Warning
        const ok = await this.poper.openAndWait()
        if (!ok) {
            return
        }

        await this.apro.form.action(AproAction.CORREGIR, {
            [Aprobacion.razonRechazo]: this.rechazoForm.getField(Aprobacion.razonRechazo)
        })
    }
    
}


export const getAprobacionPage = getAuthSingleton(sopPages.aprobaciones.id, () => {
    return new AprobacionPagePiece(sopPages.aprobaciones)
})


export const AprobacionPage = observer(
    /**
     * @param {AprobacionPagePiece} page
     */
    ({page}) => {

        usePiece(page)
        
        const fld = page.form.getField
        
        const pendiente = fld(Aprobacion.estado) === undefined
        const aCorregir = fld(Aprobacion.estado) === AprobacionEstado.NECESITA_CORRECCION

        const asa = page._asyncAction
        
        const apro = page.apro.form
        
        
        const puedeActuar = userAuth.isAllowed(apro.permisoAdmin)
        
        const sePuedeDenegar = pendiente && apro.sePuedeDenegar && puedeActuar
        const sePuedeCorregir = pendiente && apro.sePuedeCorregir && puedeActuar
        const sePuedeAprobar = pendiente && puedeActuar
        
        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame piece={page} renderPiece={()=> {
                    return (
                        <VerticalSplit id="AprobacionPage" defaultSizes={listFormSplitDefault}>
                            <Frame>
                                <Bar>
                                    <AprobacionSearch search={page.search} />
                                    <HintIconButton Icon={icons.Reload}
                                                    large title="Actualizar" onClick={page.__refreshExtra}
                                    />
                                </Bar>
                                <GraphqlTable list={page.list} />
                            </Frame>
                            <Frame direction="column">
                                {!page.form.valid ? null :
                                    <Bar>
                                        <ListNavigator list={page.list}/><Separator rightGap/>
                                        <Title Icon={AprobacionIcon[fld(Aprobacion.estado)]}>
                                            {fld(Aprobacion.tipo, AprobacionTipo.tipo) || ''}
                                        </Title>
                                        <Push/>
                                        <Separator />
                                        {!sePuedeDenegar ? null :
                                            <HintIconButton Icon={icons.Deny} title="Denegar" 
                                                    onClick={asa(page.denegar, {withProgress:false})}/>
                                        }
                                        {!sePuedeCorregir ? null :
                                            <HintIconButton Icon={icons.Warning} title="Pedir corrección" 
                                                    onClick={asa(page.corregir, {withProgress:false})}/>
                                        }
                                        {!sePuedeAprobar ? null :
                                            <HintIconButton Icon={icons.Accept} title="Aprobar"
                                                    onClick={asa(page.aprobar, {withProgress:false})}/>
                                        }
                                        {!sePuedeDenegar && !sePuedeCorregir && !sePuedeAprobar ? null:
                                            <Separator />
                                        }
                                        {!page.form.editable ? null :
                                            <FormControl form={page.apro.form} />
                                        }
                                    </Bar>
                                }
                                <AprobacionForm form={page.form} />
                                <PoperFrame poper={page.poper}>
                                    <RechazoForm form={page.rechazoForm} />
                                </PoperFrame>
                            </Frame>
                        </VerticalSplit>
                    )
                }} />
            </>
        )
})