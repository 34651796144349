/**
 * @enum
 */
export const UserRoles = Object.freeze({
    ADMINISTRADOR: 1,
    TECNICO: 2,
    REPRESENTANTE: 3,
    COMERCIAL: 4,
})

/**
 * @enum
 */
export const TrabajoClase = Object.freeze({
    ESTUDIO: 1,
    VALORACION: 2,
    TAREA: 3,
})

export const TrabajoTiposValoracion = [2,3,4,13]
export const TrabajoTipoTarea = 1

export const TrabajoEstadoFinalizado = [4,5]

export const TrabajoEstadoPlanificador = [1,3]

/**
 * @enum
 */
export const TrabajoView = Object.freeze({
    COTIZACION: Symbol('cotización'),
    TAREA: Symbol('tarea'),
    DESCONOCIDO: Symbol('desconocido'),
})

/**
 * @enum
 */
export const TrabajoEstadoValue = Object.freeze({
    PLANIFICADOR: 1,
    ASIGNADO: 2,
    BLOQUEADO: 3,
    REALIZADO: 4,
    CANCELADO: 5,
})


/**
 * @enum
 */
export const RolesUsuario = Object.freeze({
    ADMINISTRADOR: 1,
    TECNICO: 2,
    REPRESENTANTE: 3,
    COMERCIAL: 4,
})


/**
 * @enum
 */
export const AprobacionTipoValues = Object.freeze({
    CAMBIAR_ESTADO_TRABAJO: 1,
    LIQUIDACION_EFECTIVOS: 2,
    CAMBIAR_POSICION_TRABAJO: 3,
    ASIGNAR_VALORACIONES: 4,
    LIQUIDACION_MANUAL: 5,
    AUTORIZAR_ENVIO_PLANOS: 6,
})



/**
 * @enum
 */
export const CuentaTipos = Object.freeze({
    COMPETENCIA: 11,
})



/**
 * @enum
 */
export const PlanosEstados = Object.freeze({
    NO_AUTORIZADO: 1,
    AUTORIZADO: 2,
    ENVIADOS: 3,
    SEGUIMIENTO: 4,
})
