import React from 'react'
import {ModalContent} from 'sopix/modal/ModalContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'
import {Link, Typography} from '@material-ui/core'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {LoginScreen, PasswordFld} from 'sop/login/loginState'
import {useKeyPressed} from 'sopix/hooks/use-key-pressed'
import {makeStyles} from '@material-ui/core/styles'


export const useFormStyles = makeStyles(theme => ({
    buttonLink: {
        padding: '6px 8px',
    },
}))



export const LoginOtpForm = observer(
/**
 * 
 * @param {LoginState} login
 * @param children
 * @returns {JSX.Element}
 */
({login, children}) => {
    
    const cls = useFormStyles()

    useKeyPressed(key => {
        if (key === 'Enter') {
            login.loginOtp()
        }
    })    
    
    return (
        <>
            <ModalContent>
                <Fields>
                    <Field manager={login.fieldManager} name={PasswordFld.OTP} label="Google Authenticator"
                           autoFocus={true}
                    />
                </Fields>
                <br />
                <Typography>
                    Consulta la clave de 6 números en Google Autenticator, en tu móvil.
                </Typography>
            </ModalContent>
            <DialogActions>
                <Link
                    className={cls.buttonLink}
                    component="button"
                    onClick={() => login.totpSecret ? login.setScreen(LoginScreen.ASK_OTP_SECRET_SUCCESS) : login.setScreen(LoginScreen.ASK_OTP_SECRET)}
                >
                    No tengo Google Authenticator.
                </Link>
                <Push/>
                <Button
                    disabled={!login.otpLoginAllowed}
                    onClick={login.loginOtp}
                    color="primary"
                    defaultChecked={true}
                >
                    Validar dispositivo
                </Button>
            </DialogActions>
        </>
    )
})
