 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproposicionIds} from './Aproposicion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AproposicionEdt = Object.freeze({
    idAproposicion : new SimpleEditor(AproposicionIds.idAproposicion, 11, 11),
    posicionOriginal : new SimpleEditor(AproposicionIds.posicionOriginal, 11, 11),
    nuevaPosicion : new SimpleEditor(AproposicionIds.nuevaPosicion, 11, 11),
    justificacion : new SimpleEditor(AproposicionIds.justificacion, 40, 16),
})

tableEditors[Tables.Aproposicion] = AproposicionEdt
