 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AvisoIds = Object.freeze({
    usuariosPendientes: Symbol('usuariosPendientes'),
    tipo: Symbol('tipo'),
    usuario: Symbol('usuario'),
    obra: Symbol('obra'),
    tarea: Symbol('tarea'),
    cuenta: Symbol('cuenta'),
    idAviso: Symbol('idAviso'),
    fecha: Symbol('fecha'),
    idTipo: Symbol('idTipo'),
    aviso: Symbol('aviso'),
    idUsuario: Symbol('idUsuario'),
    idObra: Symbol('idObra'),
    idTarea: Symbol('idTarea'),
    idPedido: Symbol('idPedido'),
    idCuenta: Symbol('idCuenta'),
    pendiente: Symbol('pendiente'),
})

export const Aviso = Object.freeze({
    usuariosPendientes: 'usuariosPendientes',
    tipo: 'tipo',
    usuario: 'usuario',
    obra: 'obra',
    tarea: 'tarea',
    cuenta: 'cuenta',
    idAviso: 'idAviso',
    fecha: 'fecha',
    idTipo: 'idTipo',
    aviso: 'aviso',
    idUsuario: 'idUsuario',
    idObra: 'idObra',
    idTarea: 'idTarea',
    idPedido: 'idPedido',
    idCuenta: 'idCuenta',
    pendiente: 'pendiente',
})
