import React, {useEffect} from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {getTableProps, useTableStyles} from 'sopix/piece-components/render-table'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {usePiece} from 'sopix/piece/use-piece'

/**
 * @param {TablePiece} list
 */
export const GraphqlTable = ({list, grow, ...props}) => {
    const cls = useTableStyles()

    /** @type {GraphqlDataSource} */
    const graphql = list.filtrator.source
    
    usePiece(list)

    return <PieceFrame grow={grow} piece={list} renderPiece={() => (
        <BlTable
            className={cls.table}
            dense={false}
            hover={true}

            {...getTableProps(list)}
            data={graphql.__dataProvider}
            boundlessManager={graphql.manager}
            
            {...props}
        />
    )}/>
}