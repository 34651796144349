import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import {HintIcon} from 'sopix/formComps/HintIcon'

const useStyles = makeStyles(() => ({
    iconTab: {
        minWidth: 'auto',
    },
})) 

export function IconTab({toolTip, badge, icon, label, mini, color, badgeColor, badgeVariant, large, ...tabProps}) {
    
    const cls = useStyles()

    return (
        <Tab classes={{root: cls.iconTab}} icon={
            <HintIcon Icon={icon} badge={badge} badgeColor={badgeColor} badgeVariant={badgeVariant} color={color} 
                      mini={mini} title={toolTip} large={large} />
        } {...tabProps} />
    )       
}
