 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproasignarIds} from './Aproasignar'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const AproasignarCol = Object.freeze({
    idAproasignar : new SimpleColumn(AproasignarIds.idAproasignar, 11, 11, Align.RIGHT),
    excluidos : new SimpleColumn(AproasignarIds.excluidos, 40, 16, Align.LEFT),
    valoracionesPorTecnico : new SimpleColumn(AproasignarIds.valoracionesPorTecnico, 11, 11, Align.RIGHT),
    sorteadas : new SimpleColumn(AproasignarIds.sorteadas, 11, 11, Align.RIGHT),
    justificacion : new SimpleColumn(AproasignarIds.justificacion, 40, 16, Align.LEFT),
})

tableColumns[Tables.Aproasignar] = AproasignarCol
