import {getILikeFromKeyword} from 'sopix/db/graphql-utils'
import {Liquidacion} from 'sop/db/Liquidacion'
import Moment from 'moment'


// MISTERIO: Si llamo al método xxxListFilter el breakpoint no se detiene!!!
/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export function liquidacionFilter(searchFields) {
    if (!searchFields) return {}
    const result = {}
    for (let [key, value] of Object.entries(searchFields)) {
        if (!value) {
            continue
        }

        const filters = {
            [Liquidacion.idLiquidacion]: () => {
                result[`${Liquidacion.idLiquidacion}Gt`] = parseInt(value) - 10
            },
            buscar: () => {
                const words = value.split(' ')
                const descripcionEntries = words.map(word => ({[`${Liquidacion.descripcion}Ilike`]: getILikeFromKeyword(word)}))
                result.or = [
                    {and: descripcionEntries},
                ]
            },
            'fechaDesde': () => {
                result[`${Liquidacion.fecha}Gte`] = Moment(value).format()
            },
            'fechaHasta': () => {
                result[`${Liquidacion.fecha}Lt`] = Moment(value).format()
            },
        }

        const filter = filters[key]
        filter && filter()
    }
    return result
}

