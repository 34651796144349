 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const PermisoIds = Object.freeze({
    usuarios: Symbol('usuarios'),
    idPermiso: Symbol('idPermiso'),
    permiso: Symbol('permiso'),
    principal: Symbol('principal'),
    verObras: Symbol('verObras'),
    modificarObras: Symbol('modificarObras'),
    crearObras: Symbol('crearObras'),
    eliminarObras: Symbol('eliminarObras'),
    verSolicitantes: Symbol('verSolicitantes'),
    modificarSolicitantes: Symbol('modificarSolicitantes'),
    agregarSolicitantes: Symbol('agregarSolicitantes'),
    quitarSolicitantes: Symbol('quitarSolicitantes'),
    verNotas: Symbol('verNotas'),
    modificarNotas: Symbol('modificarNotas'),
    accederNotasPrivadas: Symbol('accederNotasPrivadas'),
    modificarNotasDeOtros: Symbol('modificarNotasDeOtros'),
    eliminarNotasDeOtros: Symbol('eliminarNotasDeOtros'),
    verObrasRelacionadas: Symbol('verObrasRelacionadas'),
    modificarObrasRelacionadas: Symbol('modificarObrasRelacionadas'),
    verRepresentantesObra: Symbol('verRepresentantesObra'),
    modificarRepresentantesObra: Symbol('modificarRepresentantesObra'),
    verUsuariosMinimo: Symbol('verUsuariosMinimo'),
    verUsuarios: Symbol('verUsuarios'),
    verUsuariosAdmin: Symbol('verUsuariosAdmin'),
    modificarUsuarios: Symbol('modificarUsuarios'),
    modificarUsuariosAdmin: Symbol('modificarUsuariosAdmin'),
    permisosRepresentantes: Symbol('permisosRepresentantes'),
    crearUsuarios: Symbol('crearUsuarios'),
    paginaUsuarios: Symbol('paginaUsuarios'),
    verListas: Symbol('verListas'),
    verCotizaciones: Symbol('verCotizaciones'),
    modificarCotizaciones: Symbol('modificarCotizaciones'),
    modificarCotizacionesTecnico: Symbol('modificarCotizacionesTecnico'),
    crearCotizaciones: Symbol('crearCotizaciones'),
    eliminarCotizaciones: Symbol('eliminarCotizaciones'),
    verTareas: Symbol('verTareas'),
    modificarTareas: Symbol('modificarTareas'),
    crearTareas: Symbol('crearTareas'),
    eliminarTareas: Symbol('eliminarTareas'),
    paginaTrabajos: Symbol('paginaTrabajos'),
    verCuentas: Symbol('verCuentas'),
    modificarCuentas: Symbol('modificarCuentas'),
    crearCuentas: Symbol('crearCuentas'),
    eliminarCuentas: Symbol('eliminarCuentas'),
    paginaCuentas: Symbol('paginaCuentas'),
    verPedidosSage: Symbol('verPedidosSage'),
    verCuentasSage: Symbol('verCuentasSage'),
    verPermisos: Symbol('verPermisos'),
    modificarPermisos: Symbol('modificarPermisos'),
    verConfiguracion: Symbol('verConfiguracion'),
    modificarConfiguracion: Symbol('modificarConfiguracion'),
    paginaConfiguracion: Symbol('paginaConfiguracion'),
    verLiquidaciones: Symbol('verLiquidaciones'),
    modificarLiquidaciones: Symbol('modificarLiquidaciones'),
    verAvisos: Symbol('verAvisos'),
    verAprobaciones: Symbol('verAprobaciones'),
    verEjecuciones: Symbol('verEjecuciones'),
    modificarEjecuciones: Symbol('modificarEjecuciones'),
    verDocumentos: Symbol('verDocumentos'),
    verArchivosDocumento: Symbol('verArchivosDocumento'),
    modificarDocumentos: Symbol('modificarDocumentos'),
    eliminarUsuarios: Symbol('eliminarUsuarios'),
    repartirValoraciones: Symbol('repartirValoraciones'),
    solicitarCambioEstado: Symbol('solicitarCambioEstado'),
    solicitarCambioPosicion: Symbol('solicitarCambioPosicion'),
    solicitarEnvioPlanos: Symbol('solicitarEnvioPlanos'),
    solicitarLiquidacion: Symbol('solicitarLiquidacion'),
    verM2Efectivos: Symbol('verM2Efectivos'),
    modificarM2Efectivos: Symbol('modificarM2Efectivos'),
    aprobarCambioEstado: Symbol('aprobarCambioEstado'),
    aprobarCambioPosicion: Symbol('aprobarCambioPosicion'),
    aprobarLiquidacion: Symbol('aprobarLiquidacion'),
    aprobarLiquidacionManual: Symbol('aprobarLiquidacionManual'),
    aprobarEnvioPlanos: Symbol('aprobarEnvioPlanos'),
    cambiarResponsableCotizacion: Symbol('cambiarResponsableCotizacion'),
    enviarPlanos: Symbol('enviarPlanos'),
    cambiarSeguimientoPlanos: Symbol('cambiarSeguimientoPlanos'),
})

export const Permiso = Object.freeze({
    usuarios: 'usuarios',
    idPermiso: 'idPermiso',
    permiso: 'permiso',
    principal: 'principal',
    verObras: 'verObras',
    modificarObras: 'modificarObras',
    crearObras: 'crearObras',
    eliminarObras: 'eliminarObras',
    verSolicitantes: 'verSolicitantes',
    modificarSolicitantes: 'modificarSolicitantes',
    agregarSolicitantes: 'agregarSolicitantes',
    quitarSolicitantes: 'quitarSolicitantes',
    verNotas: 'verNotas',
    modificarNotas: 'modificarNotas',
    accederNotasPrivadas: 'accederNotasPrivadas',
    modificarNotasDeOtros: 'modificarNotasDeOtros',
    eliminarNotasDeOtros: 'eliminarNotasDeOtros',
    verObrasRelacionadas: 'verObrasRelacionadas',
    modificarObrasRelacionadas: 'modificarObrasRelacionadas',
    verRepresentantesObra: 'verRepresentantesObra',
    modificarRepresentantesObra: 'modificarRepresentantesObra',
    verUsuariosMinimo: 'verUsuariosMinimo',
    verUsuarios: 'verUsuarios',
    verUsuariosAdmin: 'verUsuariosAdmin',
    modificarUsuarios: 'modificarUsuarios',
    modificarUsuariosAdmin: 'modificarUsuariosAdmin',
    permisosRepresentantes: 'permisosRepresentantes',
    crearUsuarios: 'crearUsuarios',
    paginaUsuarios: 'paginaUsuarios',
    verListas: 'verListas',
    verCotizaciones: 'verCotizaciones',
    modificarCotizaciones: 'modificarCotizaciones',
    modificarCotizacionesTecnico: 'modificarCotizacionesTecnico',
    crearCotizaciones: 'crearCotizaciones',
    eliminarCotizaciones: 'eliminarCotizaciones',
    verTareas: 'verTareas',
    modificarTareas: 'modificarTareas',
    crearTareas: 'crearTareas',
    eliminarTareas: 'eliminarTareas',
    paginaTrabajos: 'paginaTrabajos',
    verCuentas: 'verCuentas',
    modificarCuentas: 'modificarCuentas',
    crearCuentas: 'crearCuentas',
    eliminarCuentas: 'eliminarCuentas',
    paginaCuentas: 'paginaCuentas',
    verPedidosSage: 'verPedidosSage',
    verCuentasSage: 'verCuentasSage',
    verPermisos: 'verPermisos',
    modificarPermisos: 'modificarPermisos',
    verConfiguracion: 'verConfiguracion',
    modificarConfiguracion: 'modificarConfiguracion',
    paginaConfiguracion: 'paginaConfiguracion',
    verLiquidaciones: 'verLiquidaciones',
    modificarLiquidaciones: 'modificarLiquidaciones',
    verAvisos: 'verAvisos',
    verAprobaciones: 'verAprobaciones',
    verEjecuciones: 'verEjecuciones',
    modificarEjecuciones: 'modificarEjecuciones',
    verDocumentos: 'verDocumentos',
    verArchivosDocumento: 'verArchivosDocumento',
    modificarDocumentos: 'modificarDocumentos',
    eliminarUsuarios: 'eliminarUsuarios',
    repartirValoraciones: 'repartirValoraciones',
    solicitarCambioEstado: 'solicitarCambioEstado',
    solicitarCambioPosicion: 'solicitarCambioPosicion',
    solicitarEnvioPlanos: 'solicitarEnvioPlanos',
    solicitarLiquidacion: 'solicitarLiquidacion',
    verM2Efectivos: 'verM2Efectivos',
    modificarM2Efectivos: 'modificarM2Efectivos',
    aprobarCambioEstado: 'aprobarCambioEstado',
    aprobarCambioPosicion: 'aprobarCambioPosicion',
    aprobarLiquidacion: 'aprobarLiquidacion',
    aprobarLiquidacionManual: 'aprobarLiquidacionManual',
    aprobarEnvioPlanos: 'aprobarEnvioPlanos',
    cambiarResponsableCotizacion: 'cambiarResponsableCotizacion',
    enviarPlanos: 'enviarPlanos',
    cambiarSeguimientoPlanos: 'cambiarSeguimientoPlanos',
})
