 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageClienteIds} from './SageCliente'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const SageClienteCol = Object.freeze({
    codigoEmpresa : new SimpleColumn(SageClienteIds.codigoEmpresa, 11, 11, Align.RIGHT),
    codigoCliente : new SimpleColumn(SageClienteIds.codigoCliente, 15, 10, Align.LEFT),
    cifDni : new SimpleColumn(SageClienteIds.cifDni, 13, 10, Align.LEFT),
    fechaAlta : new DateTimeColumn(SageClienteIds.fechaAlta, 16, 16, Align.LEFT),
    fechaAltaOnlyDate : new DateColumn(SageClienteIds.fechaAlta, 11, 11, Align.LEFT),
    razonSocial : new SimpleColumn(SageClienteIds.razonSocial, 40, 16, Align.LEFT),
    nombre : new SimpleColumn(SageClienteIds.nombre, 35, 14, Align.LEFT),
    domicilio : new SimpleColumn(SageClienteIds.domicilio, 40, 16, Align.LEFT),
    municipio : new SimpleColumn(SageClienteIds.municipio, 25, 10, Align.LEFT),
    provincia : new SimpleColumn(SageClienteIds.provincia, 20, 10, Align.LEFT),
    actividad : new SimpleColumn(SageClienteIds.actividad, 40, 16, Align.LEFT),
    tipoCliente : new SimpleColumn(SageClienteIds.tipoCliente, 10, 10, Align.LEFT),
    codigoPostal : new SimpleColumn(SageClienteIds.codigoPostal, 8, 8, Align.LEFT),
    nacion : new SimpleColumn(SageClienteIds.nacion, 25, 10, Align.LEFT),
    telefono : new SimpleColumn(SageClienteIds.telefono, 15, 10, Align.LEFT),
    comentarios_ : new SimpleColumn(SageClienteIds.comentarios_, 40, 16, Align.LEFT),
})

tableColumns[Tables.SageCliente] = SageClienteCol
