import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import {isArray, isFunction, isPlainObject} from 'lodash-es'
import {getColumnTextFormatter} from 'sopix/db/db-column-utils'
import {Fields} from 'sopix/formComps/Fields'


/**
 * @param data
 * @param {DbColumn[]} columns
 * @param result
 * @param title
 */
function expandColumnSummaryFields(data, columns, result = [], title = '', customFormatter){
    const cols = !isArray(columns) || isPlainObject(columns[1]) ? [columns] : columns
    for (let column of cols) {
        let left
        let right
        if (isArray(column)) {
            left = column[0]
            right = column[1]
        } else {
            left = column
        }
        
        if (right && !isPlainObject(right)){
            expandColumnSummaryFields(data[left.fieldName], right, result, title  + left.title + ': ')
        } else {
            const formatter = getColumnTextFormatter(left)
            const raw = data && data[left.field.fieldName]
            const value = formatter(raw)
            if (value) {
                let content = value
                let hint = title + left.field.title
                if (isPlainObject(right)) {
                    if (isFunction(right.value)) {
                        content = right.value(content)
                    } else if (right.value) {
                        content = right.value
                    }
                    if (isFunction(right.hint)) {
                        hint = right.value(hint)
                    } else if (right.hint) {
                        hint = right.hint
                    }
                }
                result.push([hint, content])
            }        
        }         
    }
    return result
}



const useStyles = makeStyles(theme => ({
    entry: {
        padding: '0 6px',
        backgroundColor: 'rgba(255,255,255,.5)',
        color: theme.palette.grey['700'],
        cursor: 'default',
        
        '&:hover': {
            background: 'rgba(255,255,255,.9)',
        }
    },
}))

export function ColumnsSummary({className, data, columns}) {
        
    const cls = useStyles()
    
    if (!data) return null
    
    const fields = expandColumnSummaryFields(data,  columns)
    
    return (
        <Fields margin className={className} >
            {fields.map(([title, value], index) => 
                <Tooltip key={index} className={cls.entry} title={title}><Typography >{value}</Typography></Tooltip>
            )}
        </Fields>
    )
}
