import {capitalize, find} from 'lodash-es'
import {Pais} from 'sop/db/Pais'

export function getZona(pais, cp, paises){
    
    const paisRow = !pais ? undefined : find(paises, {[Pais.nombre]: capitalize(pais.toLowerCase())})
    const paisIso = !paisRow ? undefined : paisRow[Pais.iso]
    
    if (!paisIso || paisIso === 'ES') {
        if (!cp) {
            return undefined
        } else {
            const zona = cp.substr(0, 2) 
            return zona.length === 2 ? zona : undefined
        }
    } else {
        return paisIso
    }
}