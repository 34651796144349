import {CuentaFld, CuentaTable} from 'sop/db/CuentaTable'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {ContactoTable} from 'sop/db/ContactoTable'
import {ObraFld, ObraTable} from 'sop/db/ObraTable'
import {NotaFld, NotaTable} from 'sop/db/NotaTable'
import {UserFld} from 'sop/db/UserTable'
import {DireccionFld} from 'sop/db/DireccionTable'
import {TareaEstadoFld} from 'sop/db/TareaEstadoTable'
import {CuentaTipoFld} from 'sop/db/CuentaTipoTable'
import {EjecucionFld} from 'sop/db/EjecucionTable'
import {UserRoleFld} from 'sop/db/UserRoleTable'
import {PermisoFld} from 'sop/db/PermisoTable'


export const USER_FIELDS = [
    UserFld.id,

    UserFld.username,
    UserFld.nombreCompleto,
    UserFld.movil,
    UserFld.inactivo,

    [UserFld.permisos, [PermisoFld.idPermiso, PermisoFld.permiso]],

    UserFld.roleUsuario,
    [UserFld.roleUsuarioObj, [UserRoleFld.role]],

    UserFld.zona,
    UserFld.email,
    UserFld.resetKey,
    UserFld.ausente,
    UserFld.ausenteDesde,
    UserFld.ausenteHasta,

    UserFld.horas,
    UserFld.aclReadOnly,
]

export const USER_FIELDS_MIN = [
    UserFld.id,
    UserFld.username,
    UserFld.nombreCompleto,
]

export const OBRA_FIELDS = [
    ...ObraTable.regularFields,
    [ObraFld.autor, [UserFld.username, UserFld.nombreCompleto]],
    [ObraFld.modificacion, [UserFld.username, UserFld.nombreCompleto]],
    [ObraFld.ejecuciones, [EjecucionFld.competencia]],
]

export const OBRA_FIELDS_MIN = [
    ObraFld.idObra,
    ObraFld.cod,
]

export const CUENTA_FIELDS = [
    ...CuentaTable.regularFields,
    CuentaFld.cuenta,
    [CuentaFld.tipo, [CuentaTipoFld.idTipo, CuentaTipoFld.tipo]]
]

export const CUENTA_FIELDS_MIN = [
    CuentaFld.idCuenta,
    CuentaFld.cuenta,
]

export const DIRECCION_FIELDS_MIN = [
    DireccionFld.idDireccion,
    DireccionFld.direccion,
]

export const TAREA_ESTADO_FIELDS = [
    TareaEstadoFld.idEstado,
    TareaEstadoFld.estado,
    TareaEstadoFld.finalizado,
]

export const SOLICITUD_FIELDS = [
    ...SolicitudTable.regularFields,
    [SolicitudFld.contacto, ContactoTable.regularFields],
    [SolicitudFld.direccion, DIRECCION_FIELDS_MIN],
    [SolicitudFld.cuenta, CUENTA_FIELDS_MIN],
    [SolicitudFld.obra, OBRA_FIELDS_MIN],
    [SolicitudFld.autor, USER_FIELDS_MIN],
]

export const SOLICITUD_FIELDS_MIN = [
    SolicitudFld.idSolicitud,
    [SolicitudFld.cuenta, CUENTA_FIELDS_MIN],
    [SolicitudFld.autor, USER_FIELDS_MIN],
]

export const NOTA_FIELDS = [
    ...NotaTable.regularFields,
    [NotaFld.autor, [UserFld.username, UserFld.nombreCompleto]],
    [NotaFld.solicitud, [SolicitudFld.cuenta]],
    [NotaFld.obra, [ObraFld.cod]]
]
