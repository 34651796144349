// ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ObraTable} from './ObraTable'
import {ProvinciaTable} from './ProvinciaTable'
import {SolicitudTable} from './SolicitudTable'
import {StatusTable} from './StatusTable'
import {TareaTable} from './TareaTable'
import {TipoTareaTable} from './TipoTareaTable'
import {TipologiaTable} from './TipologiaTable'
import {UserTable} from './UserTable'
import {ProcedenciaTable} from './ProcedenciaTable'
import {CuentaTable} from './CuentaTable'
import {DireccionTable} from './DireccionTable'
import {ContactoTable} from './ContactoTable'
import {NotaTable} from './NotaTable'
import {RelacionadaTable} from './RelacionadaTable'
import {RepresentanteTable} from './RepresentanteTable'
import {UserRoleTable} from './UserRoleTable'
import {PaisTable} from './PaisTable'
import {SolicitudTareaTable} from './SolicitudTareaTable'
import {AlmacenTable} from './AlmacenTable'
import {PermisoTable} from './PermisoTable'
import {ContadorTable} from './ContadorTable'
import {ConfiguracionTable} from './ConfiguracionTable'
import {SistemaTable} from './SistemaTable'
import {TareaSistemaTable} from './TareaSistemaTable'
import {TareaEstadoTable} from './TareaEstadoTable'
import {LiquidacionTable} from './LiquidacionTable'
import {AvisoTable} from './AvisoTable'
import {AvisoTipoTable} from './AvisoTipoTable'
import {AprobacionTable} from './AprobacionTable'
import {AprobacionTipoTable} from './AprobacionTipoTable'
import {AproestaTable} from './AproestaTable'
import {AproposicionTable} from './AproposicionTable'
import {AproasignarTable} from './AproasignarTable'
import {AproliqmanTable} from './AproliqmanTable'
import {AproplanoTable} from './AproplanoTable'
import {PlanoEstadoTable} from './PlanoEstadoTable'
import {MesTable} from './MesTable'
import {AnualMesTable} from './AnualMesTable'
import {LiqtecmesTable} from './LiqtecmesTable'
import {EjecucionTable} from './EjecucionTable'
import {EjecucionCuentaTable} from './EjecucionCuentaTable'
import {CuentaTipoTable} from './CuentaTipoTable'
import {DocumentoTable} from './DocumentoTable'
import {FileTable} from './FileTable'
import {UploadTable} from './UploadTable'
import {PermisoTransposedTable} from './PermisoTransposedTable'
import {DispositivoTable} from './DispositivoTable'
import {SagePedidoTable} from './SagePedidoTable'
import {SageClienteTable} from './SageClienteTable'
import {SageEmpresaTable} from './SageEmpresaTable'
import {SageDomicilioTable} from './SageDomicilioTable'
import {SageDescuentoTable} from './SageDescuentoTable'
import {ObraEdt} from './ObraEditors'
import {ProvinciaEdt} from './ProvinciaEditors'
import {SolicitudEdt} from './SolicitudEditors'
import {StatusEdt} from './StatusEditors'
import {TareaEdt} from './TareaEditors'
import {TipoTareaEdt} from './TipoTareaEditors'
import {TipologiaEdt} from './TipologiaEditors'
import {UserEdt} from './UserEditors'
import {ProcedenciaEdt} from './ProcedenciaEditors'
import {CuentaEdt} from './CuentaEditors'
import {DireccionEdt} from './DireccionEditors'
import {ContactoEdt} from './ContactoEditors'
import {NotaEdt} from './NotaEditors'
import {RelacionadaEdt} from './RelacionadaEditors'
import {RepresentanteEdt} from './RepresentanteEditors'
import {UserRoleEdt} from './UserRoleEditors'
import {PaisEdt} from './PaisEditors'
import {SolicitudTareaEdt} from './SolicitudTareaEditors'
import {AlmacenEdt} from './AlmacenEditors'
import {PermisoEdt} from './PermisoEditors'
import {ContadorEdt} from './ContadorEditors'
import {ConfiguracionEdt} from './ConfiguracionEditors'
import {SistemaEdt} from './SistemaEditors'
import {TareaSistemaEdt} from './TareaSistemaEditors'
import {TareaEstadoEdt} from './TareaEstadoEditors'
import {LiquidacionEdt} from './LiquidacionEditors'
import {AvisoEdt} from './AvisoEditors'
import {AvisoTipoEdt} from './AvisoTipoEditors'
import {AprobacionEdt} from './AprobacionEditors'
import {AprobacionTipoEdt} from './AprobacionTipoEditors'
import {AproestaEdt} from './AproestaEditors'
import {AproposicionEdt} from './AproposicionEditors'
import {AproasignarEdt} from './AproasignarEditors'
import {AproliqmanEdt} from './AproliqmanEditors'
import {AproplanoEdt} from './AproplanoEditors'
import {PlanoEstadoEdt} from './PlanoEstadoEditors'
import {MesEdt} from './MesEditors'
import {AnualMesEdt} from './AnualMesEditors'
import {LiqtecmesEdt} from './LiqtecmesEditors'
import {EjecucionEdt} from './EjecucionEditors'
import {EjecucionCuentaEdt} from './EjecucionCuentaEditors'
import {CuentaTipoEdt} from './CuentaTipoEditors'
import {DocumentoEdt} from './DocumentoEditors'
import {FileEdt} from './FileEditors'
import {UploadEdt} from './UploadEditors'
import {PermisoTransposedEdt} from './PermisoTransposedEditors'
import {DispositivoEdt} from './DispositivoEditors'
import {SagePedidoEdt} from './SagePedidoEditors'
import {SageClienteEdt} from './SageClienteEditors'
import {SageEmpresaEdt} from './SageEmpresaEditors'
import {SageDomicilioEdt} from './SageDomicilioEditors'
import {SageDescuentoEdt} from './SageDescuentoEditors'
import {ObraCol} from './ObraColumns'
import {ProvinciaCol} from './ProvinciaColumns'
import {SolicitudCol} from './SolicitudColumns'
import {StatusCol} from './StatusColumns'
import {TareaCol} from './TareaColumns'
import {TipoTareaCol} from './TipoTareaColumns'
import {TipologiaCol} from './TipologiaColumns'
import {UserCol} from './UserColumns'
import {ProcedenciaCol} from './ProcedenciaColumns'
import {CuentaCol} from './CuentaColumns'
import {DireccionCol} from './DireccionColumns'
import {ContactoCol} from './ContactoColumns'
import {NotaCol} from './NotaColumns'
import {RelacionadaCol} from './RelacionadaColumns'
import {RepresentanteCol} from './RepresentanteColumns'
import {UserRoleCol} from './UserRoleColumns'
import {PaisCol} from './PaisColumns'
import {SolicitudTareaCol} from './SolicitudTareaColumns'
import {AlmacenCol} from './AlmacenColumns'
import {PermisoCol} from './PermisoColumns'
import {ContadorCol} from './ContadorColumns'
import {ConfiguracionCol} from './ConfiguracionColumns'
import {SistemaCol} from './SistemaColumns'
import {TareaSistemaCol} from './TareaSistemaColumns'
import {TareaEstadoCol} from './TareaEstadoColumns'
import {LiquidacionCol} from './LiquidacionColumns'
import {AvisoCol} from './AvisoColumns'
import {AvisoTipoCol} from './AvisoTipoColumns'
import {AprobacionCol} from './AprobacionColumns'
import {AprobacionTipoCol} from './AprobacionTipoColumns'
import {AproestaCol} from './AproestaColumns'
import {AproposicionCol} from './AproposicionColumns'
import {AproasignarCol} from './AproasignarColumns'
import {AproliqmanCol} from './AproliqmanColumns'
import {AproplanoCol} from './AproplanoColumns'
import {PlanoEstadoCol} from './PlanoEstadoColumns'
import {MesCol} from './MesColumns'
import {AnualMesCol} from './AnualMesColumns'
import {LiqtecmesCol} from './LiqtecmesColumns'
import {EjecucionCol} from './EjecucionColumns'
import {EjecucionCuentaCol} from './EjecucionCuentaColumns'
import {CuentaTipoCol} from './CuentaTipoColumns'
import {DocumentoCol} from './DocumentoColumns'
import {FileCol} from './FileColumns'
import {UploadCol} from './UploadColumns'
import {PermisoTransposedCol} from './PermisoTransposedColumns'
import {DispositivoCol} from './DispositivoColumns'
import {SagePedidoCol} from './SagePedidoColumns'
import {SageClienteCol} from './SageClienteColumns'
import {SageEmpresaCol} from './SageEmpresaColumns'
import {SageDomicilioCol} from './SageDomicilioColumns'
import {SageDescuentoCol} from './SageDescuentoColumns'

export function registerDatabase(){
    return [
        [ObraTable, ProvinciaTable, SolicitudTable, StatusTable, TareaTable, TipoTareaTable, TipologiaTable, UserTable, ProcedenciaTable, CuentaTable, DireccionTable, ContactoTable, NotaTable, RelacionadaTable, RepresentanteTable, UserRoleTable, PaisTable, SolicitudTareaTable, AlmacenTable, PermisoTable, ContadorTable, ConfiguracionTable, SistemaTable, TareaSistemaTable, TareaEstadoTable, LiquidacionTable, AvisoTable, AvisoTipoTable, AprobacionTable, AprobacionTipoTable, AproestaTable, AproposicionTable, AproasignarTable, AproliqmanTable, AproplanoTable, PlanoEstadoTable, MesTable, AnualMesTable, LiqtecmesTable, EjecucionTable, EjecucionCuentaTable, CuentaTipoTable, DocumentoTable, FileTable, UploadTable, PermisoTransposedTable, DispositivoTable, SagePedidoTable, SageClienteTable, SageEmpresaTable, SageDomicilioTable, SageDescuentoTable],
        [ObraEdt, ProvinciaEdt, SolicitudEdt, StatusEdt, TareaEdt, TipoTareaEdt, TipologiaEdt, UserEdt, ProcedenciaEdt, CuentaEdt, DireccionEdt, ContactoEdt, NotaEdt, RelacionadaEdt, RepresentanteEdt, UserRoleEdt, PaisEdt, SolicitudTareaEdt, AlmacenEdt, PermisoEdt, ContadorEdt, ConfiguracionEdt, SistemaEdt, TareaSistemaEdt, TareaEstadoEdt, LiquidacionEdt, AvisoEdt, AvisoTipoEdt, AprobacionEdt, AprobacionTipoEdt, AproestaEdt, AproposicionEdt, AproasignarEdt, AproliqmanEdt, AproplanoEdt, PlanoEstadoEdt, MesEdt, AnualMesEdt, LiqtecmesEdt, EjecucionEdt, EjecucionCuentaEdt, CuentaTipoEdt, DocumentoEdt, FileEdt, UploadEdt, PermisoTransposedEdt, DispositivoEdt, SagePedidoEdt, SageClienteEdt, SageEmpresaEdt, SageDomicilioEdt, SageDescuentoEdt],
        [ObraCol, ProvinciaCol, SolicitudCol, StatusCol, TareaCol, TipoTareaCol, TipologiaCol, UserCol, ProcedenciaCol, CuentaCol, DireccionCol, ContactoCol, NotaCol, RelacionadaCol, RepresentanteCol, UserRoleCol, PaisCol, SolicitudTareaCol, AlmacenCol, PermisoCol, ContadorCol, ConfiguracionCol, SistemaCol, TareaSistemaCol, TareaEstadoCol, LiquidacionCol, AvisoCol, AvisoTipoCol, AprobacionCol, AprobacionTipoCol, AproestaCol, AproposicionCol, AproasignarCol, AproliqmanCol, AproplanoCol, PlanoEstadoCol, MesCol, AnualMesCol, LiqtecmesCol, EjecucionCol, EjecucionCuentaCol, CuentaTipoCol, DocumentoCol, FileCol, UploadCol, PermisoTransposedCol, DispositivoCol, SagePedidoCol, SageClienteCol, SageEmpresaCol, SageDomicilioCol, SageDescuentoCol],
    ]    
}
