import React from 'react'
import {appVersion} from 'sop/sop'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    dashboardPage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    appName: {
        //fontSize: '200%',
        //color: theme.palette.primary,
    },
    version: {
        
    }
})) 


export function DashboardPage(){
    const cls = useStyles()
    
    return (
        <div className={cls.dashboardPage}>
            <img src="/logo190x58.png" alt="sop logo" />
            <Typography className={cls.version} color="secondary" variant="h6" >{appVersion}</Typography>
        </div>
    )
}