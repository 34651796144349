 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ConfiguracionIds} from './Configuracion'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbFloat, DbInt, DbIntId} from 'sopix/db/dbField'

export const ConfiguracionTable = new DbTable(
    Tables.Configuracion,
    'Configuraciones',
    graphqlUrl,
    'Configuracion',
    [ConfiguracionIds.idConfig],
    [ConfiguracionIds.idConfig, ConfiguracionIds.ratioRepeticion, ConfiguracionIds.ratioSimetria, ConfiguracionIds.asumirEstudioPropio],
    [],
    [],
    ['verConfiguracion'],
    ['modificarConfiguracion'],
    undefined,
    undefined,
    [],
    [],
)


export const ConfiguracionFld = Object.freeze({
    idConfig : new DbIntId(Tables.Configuracion, ConfiguracionIds.idConfig, 'Id config'),
    ratioRepeticion : new DbFloat(Tables.Configuracion, ConfiguracionIds.ratioRepeticion, 'Ratio repeticion'),
    ratioSimetria : new DbFloat(Tables.Configuracion, ConfiguracionIds.ratioSimetria, 'Ratio simetria'),
    asumirEstudioPropio : new DbInt(Tables.Configuracion, ConfiguracionIds.asumirEstudioPropio, 'Priorización pre-asignados'),
})

registerTableFields(Tables.Configuracion, ConfiguracionFld) 
