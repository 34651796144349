 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AproasignarIds = Object.freeze({
    aprobacion: Symbol('aprobacion'),
    idAproasignar: Symbol('idAproasignar'),
    idAprobacion: Symbol('idAprobacion'),
    excluidos: Symbol('excluidos'),
    valoracionesPorTecnico: Symbol('valoracionesPorTecnico'),
    sorteadas: Symbol('sorteadas'),
    justificacion: Symbol('justificacion'),
})

export const Aproasignar = Object.freeze({
    aprobacion: 'aprobacion',
    idAproasignar: 'idAproasignar',
    idAprobacion: 'idAprobacion',
    excluidos: 'excluidos',
    valoracionesPorTecnico: 'valoracionesPorTecnico',
    sorteadas: 'sorteadas',
    justificacion: 'justificacion',
})
