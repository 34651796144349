import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {SageCuentaListPiece, SageCuentaTab} from 'sop/componentes/cuenta-sage/SageCuentaTab'
import {SageCuentaForm, SageCuentaFormPiece} from 'sop/componentes/cuenta-sage/SageCuentaForm'
import {SageDescuentosPiece} from 'sop/componentes/cuenta-sage/SageDescuentos'
import {SageDireccionesPiece} from 'sop/componentes/cuenta-sage/SageDirecciones'
import {SagePedidosPiece} from 'sop/componentes/cuenta-sage/SagePedidos'
import {SageCliente} from 'sop/db/SageCliente'
import {action, observable} from 'mobx'
import {SageTabs} from 'sop/componentes/cuenta-sage/sage-cuenta-defs'
import {SageTab} from 'sop/componentes/cuenta-sage/SageTab'
import {boundMethod} from 'autobind-decorator'


export class SageCuentaInfoPiece extends Piece{

    @observable
    sageTab = SageTabs.DESCUENTOS


    constructor(world) {
        super(world)

        this.sageCuentas = new SageCuentaListPiece(this.world)
        this.sageForm = new SageCuentaFormPiece(this.world)
        this.sageDescuentos = new SageDescuentosPiece(this.world)
        this.sageDirecciones = new SageDireccionesPiece(this.world)
        this.sagePedidos = new SagePedidosPiece(this.world)

        this.load = this._state(Symbol('LOAD'), async cif => {
            await this.sageCuentas.enable()
            if (!cif) {
                await this.invalidate()
            }else{
                await this.sageCuentas.load(cif)
            }
        })
        
        this.sageCuentas.onChange.subscribe(async row => {
            if (!row) {
                await this.invalidate()
            } else {
                this.sageForm.setFields(row)

                const codigoEmpresa = row[SageCliente.codigoEmpresa]
                const codigoCliente = row[SageCliente.codigoCliente]

                await this.sageDescuentos.load(codigoEmpresa, codigoCliente)

                await this.sageDirecciones.load(codigoEmpresa, codigoCliente)

                await this.sagePedidos.load(codigoEmpresa, codigoCliente)

                await this.validate()
                await this.sageCuentas.validate()
            }
        })
        
    }

    async validate() {
        return await super.validate()
    }

    @boundMethod
    async _init() {
    }

    @action.bound
    setSageTab(sageTab) {
        if (this.sageTab !== sageTab){
            this.sageTab = sageTab
        }
    }
}


export const SageCuentaInfo = getPieceContainer('info', observer(
    /**
     * @param {SageCuentaInfoPiece} info
     */
    ({info}) => {

        return (
            <>
                <SageCuentaTab list={info.sageCuentas} />
                <SageCuentaForm form={info.sageForm} />
                <SageTab info={info} />
            </>
        )
}))
