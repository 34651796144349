import {sopIcons} from 'sop/sop-icons'
import {StateBasics} from 'sopix/react-state/stateBasics'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {TipoTareaTable} from 'sop/db/TipoTareaTable'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {TipoTarea} from 'sop/db/TipoTarea'
import {computed, observable, runInAction} from 'mobx'
import {boundMethod} from 'autobind-decorator'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {tableColumnsFromDb} from 'sopix/boundless-db/boundless-db-utils'
import {TipoTareaCol} from 'sop/db/TipoTareaColumns'

export class ConfigLiquidacionState extends StateBasics{
    
    order = []

    idField = TipoTarea.idTipo

    columns = tableColumnsFromDb([
        TipoTareaCol.tipo,
        TipoTareaCol.ratio,
        TipoTareaCol.m2,
        TipoTareaCol.detalle,
    ], (col) => col.align = 'left')
    
    @observable.shallow
    initialRows = []

    //ATENCIÓN: No puede ser shallow porque se vuelven los controles readonly
    @observable
    rows = []
    
    loadQuery = new TableGraphql(TipoTareaTable)
    saveMutation = new SaveMutation(TipoTareaTable, 'MultiSaveTipoTarea', 
        {listOfRows: true})
    
    constructor({errorManager}) {
        super({
            name: "Cálculo de m² liquidaciones",
            icon: sopIcons.Efectivos,
            errorManager: errorManager,
        });
    }

    async __init() {
        const {rows} = await this.loadQuery.query(
            TipoTareaTable.regularFields, 
            {sort: calcGraphqlOrder(TipoTarea.orden)}, {
                archivadoIsNull: true,
                claseIn: [1,2],
            })
        
        runInAction(() => {
            this.rows = rows
            this._undirty()
        })
    }
    
    _undirty(){
        this.initialRows = this.rows.map(row => ({...row}))
    }
    
    @computed
    get dirty(){
        return JSON.stringify(this.rows) !== JSON.stringify(this.initialRows)
    }
    
    @boundMethod
    async _save(){
        if (!this.dirty) return
        
        await this.saveMutation.query(this.rows, 
            {initialData: this.initialRows, idField: this.idField})
        
        runInAction(() => this._undirty())
    }
    
    @boundMethod
    _revert(){
        this.rows = this.initialRows
        this._undirty()
    }
}