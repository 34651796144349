 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproliqmanIds} from './Aproliqman'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const AproliqmanCol = Object.freeze({
    responsable : new LookupColumn(AproliqmanIds.responsable, Tables.User, 'nombre_completo'),
    idAproliqman : new SimpleColumn(AproliqmanIds.idAproliqman, 11, 11, Align.RIGHT),
    m2Efectivos : new SimpleColumn(AproliqmanIds.m2Efectivos, 11, 11, Align.RIGHT),
    justificacion : new SimpleColumn(AproliqmanIds.justificacion, 40, 16, Align.LEFT),
})

tableColumns[Tables.Aproliqman] = AproliqmanCol
