import {isArray, isFunction} from 'lodash-es'
import {FunctionComponent} from "react";

type strOrFunc = string | (() => string)

export class AppPageInfo {
    id: string | undefined
    paths
    icon: FunctionComponent
    permissions: string[]
    rootId: string
    getBadgeProps: (...args) => Array<any> | undefined

    private _title: strOrFunc
    
    constructor(id: string, paths, title: strOrFunc, icon: FunctionComponent,
                permissions?: string[] | undefined, {rootId = undefined, getBadgeProps = undefined} = {}) {
        this.id = id
        
        this.paths = isArray(paths) ? paths : [paths]
        
        this._title = title
        this.icon = icon
        this.permissions = permissions
        this.rootId = rootId ? rootId : id
        this.getBadgeProps = getBadgeProps
    }
    
    get title(){
        return typeof this._title === 'function' ? this._title() : this._title
    }

    get path(){
        return this.paths[0]
    }

}