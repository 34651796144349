import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {AprobacionFld, AprobacionTable} from 'sop/db/AprobacionTable'
import {boundMethod} from 'autobind-decorator'
import {observable, runInAction} from 'mobx'
import {getDefaultAprobacionFilter} from 'sop/componentes/aprobacion/aprobacion-consts'
import {aprobacionFilter} from 'sop/componentes/aprobacion/aprobacionFilter'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'

export class AprobacionCheck {
    _aprobacionesQL = new TableGraphql(AprobacionTable)

    @observable
    hayAprobaciones = 0

    @boundMethod
    async refresh(){
        if (!userAuth.isAllowed(Permiso.verAprobaciones)) {
            return
        }
        
        const defaultFilter = getDefaultAprobacionFilter()
        const graphqlFilter = aprobacionFilter(defaultFilter) 

        const result = await this._aprobacionesQL.query([AprobacionFld.idAprobacion], {first: 1},
            graphqlFilter)
        runInAction(()=>{
            this.hayAprobaciones = result.rows.length
        })
    }
    
    @boundMethod
    getBadgeProps(){
        return {
            badge: this.hayAprobaciones,
            badgeVariant: 'dot',
        }
    }
}

export const aprobacionCheck = new AprobacionCheck()


let refreshFunc

export function setRefreshAprobacionesPage(func){
    refreshFunc = func
}

export function refreshAprobacionesPage() {
    if (!refreshFunc) return
    refreshFunc()
}