import {DbBool, DbFloat, DbInt} from 'sopix/db/dbField'

export const DataType = Object.freeze({
    INT: 'INT',
    FLOAT: 'FLOAT',
    STRING: 'STRING',
    BOOL: 'BOOL',
})


export function castFromBool(bool, /* DataType */ dataType, nullValue = undefined){
    switch (dataType){
        case DataType.INT:
            return bool ? 1 : 0
        case DataType.FLOAT:
            return bool ? 1.0 : 0.0
        case DataType.STRING:
            return `${bool}`
        default:
            return bool
    }
}

export function castToBool(value, nullValue = undefined){
    if (value === nullValue) return false
    return !!value
}

export class BoolCast{
    constructor(/* DataType */ dataType, nullValue) {
        this.dataType = dataType
        this.nullValue = nullValue
    }

    fromBool(bool){
        return castFromBool(bool, this.dataType, this.nullValue)
    }

    toBool(value){
        return castToBool(value, this.nullValue)
    }
}



export function castFromString(text, /* DataType */ dataType, nullValue = undefined){
    if (text === '') return nullValue

    switch (dataType){
        case DataType.INT:
            const result = parseInt(text) 
            return isNaN(result) ? nullValue : result
        case DataType.FLOAT:
            return parseFloat(text)
        case DataType.BOOL:
            return text === 'true'
        default:
            return text
    }    
}

export function castToString(value, nullValue = undefined){
    if (value === nullValue) return ''
    return `${value}`
}

export class StringCast{
    constructor(/** DataType */ dataType, nullValue) {
        this.dataType = dataType ? dataType : DataType.STRING
        this.nullValue = nullValue
    }
    
    fromString(text){
        return castFromString(text, this.dataType, this.nullValue)
    }
    
    toString(value){
        return castToString(value, this.nullValue)
    }
}

export function fieldTypeToDataType(/** DbField */ field){
    if (field instanceof DbInt) {
        return DataType.INT
    } else if (field instanceof DbFloat) {
        return DataType.FLOAT
    } else if (field instanceof DbBool) {
        return DataType.INT
    } else {
        return DataType.STRING
    }
}