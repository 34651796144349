 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageEmpresaIds} from './SageEmpresa'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const SageEmpresaCol = Object.freeze({
    codigoEmpresa : new SimpleColumn(SageEmpresaIds.codigoEmpresa, 11, 11, Align.RIGHT),
    empresa : new SimpleColumn(SageEmpresaIds.empresa, 40, 16, Align.LEFT),
    anagrama : new SimpleColumn(SageEmpresaIds.anagrama, 40, 16, Align.LEFT),
})

tableColumns[Tables.SageEmpresa] = SageEmpresaCol
