import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {observer} from 'mobx-react'
import clsx from 'clsx'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {Push} from 'sopix/formComps/Push'

const useStyles = makeStyles(theme => ({
    errorDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '0 8px 4px',
        position: 'relative',
        minHeight: 50,
        maxHeight: 200,
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
    },

    error: {
        display: 'flex',
        background: theme.palette.secondary,
        color: theme.palette.grey['300'],
        margin: 8,
        padding: '5px 10px',
        alignItems: 'center',
    },

    errorIcon: {
        marginRight: 16,    
    },
    
    firstError: {
        color: 'inherit',
    },
    
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 5,
    },
    
    retry: {
        flex: 1,
        justifyContent: 'space-between',
    },
}))


function Error({classes, error, first}) {

    const cls = useStyles()

    return (
        <Typography className={clsx(
            cls.error,
            first && cls.firstError
        )} >
            {error}
        </Typography>
    )
}
    


export const ErrorList = observer(
/**
 * 
 * @param {ErrorStore} errorManager
 * @returns {JSX.Element|null}
 */
({errorManager}) => {
        
    const cls = useStyles()
    
    if (errorManager.errors.length === 0 && !errorManager.failedStates.size) {
        return null
    }
    
    const retry = !errorManager.hasFailedStates() ? null : (
        <Bar>
            <Title Icon={icons.Error} iconProps={{color: "secondary"}}>
               {errorManager.failedStates.size === 1
                   ? 'Errores en un componente:'
                   : `Errores en ${errorManager.failedStates.size} componentes:`
               } 
            </Title>
            <HintIconButton title="Reintentar" Icon={icons.Retry} onClick={errorManager.retry} />
        </Bar>
    )
    
    const clear = retry || !errorManager.errors.length ? null : (
        <Bar>
            <HintIconButton color="secondary" Icon={icons.Error} title="Limpiar errores"
                            onClick={errorManager.clear} />
            <Title>Errores:</Title>
            <Push />
            <HintIconButton className={cls.closeButton} title="Limpiar errores" Icon={icons.Clear} 
                            onClick={errorManager.clear} />
            
        </Bar>
    )
    
    const messages = errorManager.errors.map( (error, index) =>
        <Error key={index} error={error.message} first={index === 0}/>
    )
        
    return ( 
        <div className={cls.errorDiv} >
            {retry}
            {clear}
            {messages}
        </div>
    )
})