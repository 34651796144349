import {WorldObject} from 'sopix/piece/worldObject'
import {observable, runInAction} from 'mobx'
import {ObserverHub} from 'sopix/utils/observers'
import {boundMethod} from 'autobind-decorator'
import {Tarea} from 'sop/db/Tarea'

export class TrabajoSortMixer extends WorldObject{

    @observable.shallow
    /** @type {Map<Any, OrderEntry[]>} */
    orders

    onSort = new ObserverHub()

    constructor(world, defaultOrders) {
        super(world)
        runInAction(()=>{
            this.orders = new Map(defaultOrders)
        })
    }

    @observable
    _mode

    get mode(){
        return this._mode
    }

    setMode(mode){
        this._mode = mode
    }

    get currentOrder(){
        return this.orders.get(this._mode)
    }

    @boundMethod
    orderChanged(order){
        // Lista de solicitantes no se puede ordenar
        if (order.length && order[0].fieldId === Tarea.solicitudList) {
            return
        }
        
        this.orders.set(this._mode, order)
        this.onSort.notify(order)
    }
    __orderChanged = this._action(this.orderChanged)

}
