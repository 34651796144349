import {FieldsPiece} from 'sopix/pieces/fieldsPiece'
import {RowFetcher, RowNotFound} from 'sopix/db-access/rowFetcher'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {boundMethod} from 'autobind-decorator'
import {action, computed, observable, runInAction} from 'mobx'
import {ObserverHub} from 'sopix/utils/observers'
import {formLockedSnakbar} from 'sopix/piece-linkers/form-list'
import {DISCARD_STATE, FrameFlag} from 'sopix/piece/piece'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {ALERT_CANCEL, ALERT_DESCARTAR, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'
import {getChangedFields} from 'sopix/db/changed-state'

const STATE_CURRENT_ROW = Symbol('STATE_CURRENT_ROW')


const FormOperation = Object.freeze({
    CREATING: Symbol('CREATING'),
    LOADING: Symbol('LOADING'),
    APPLYING: Symbol('APPLYING'),
    SAVING: Symbol('SAVING'),
    DELETING: Symbol('DELETING'),
})

export class FormPiece extends FieldsPiece{

    id
    
    //Si se load o apply con un row dirty, se rechaza la operación y se almacena aquí lo deseado
    rejectedLoadId
    loadingId
    
    rejectedApplyData
    
    /** @type {RowFetcher} */
    _rowFetcher

    /** @type {SaveMutation} */
    _saveMutation
    
    /** @type {SimpleMutation} */
    _deleteMutation
    
    onDelete = new ObserverHub()
    onDeleteRows = new ObserverHub()
    onApply = new ObserverHub()
    onSave = new ObserverHub()
    onCreate = new ObserverHub()
    onCurrentRowChanging = new ObserverHub()
    onRowNotFound = new ObserverHub()
    onRefresh = new ObserverHub()
    
    newRowFields = {}
    
    @observable
    operationsInProgress = new Set()


    /**
     * @param {PieceWorld} world
     */
    constructor(world, fieldDescriptors, {table, idField, displayField, saveMutation, extraFields, 
        deleteMutation, ...fieldsPieceOptions}) {
        
        super(world, fieldsPieceOptions)

        this._rowFetcher = new RowFetcher(table, fieldDescriptors)
        this.idField = idField
        this.displayField = displayField ? displayField : idField
        this._deleteMutation = !deleteMutation || !table ? undefined :
            new SimpleMutation(table.graphqlUrl, deleteMutation, ['success'])
        this._saveMutation = !saveMutation || !table ? undefined :
            new SaveMutation(table, saveMutation, {
                fields: fieldDescriptors,
                extraFields: extraFields,
            })
        
        this.frameFlags = new Set([FrameFlag.SCROLL, FrameFlag.MARGIN2])
    }

    @boundMethod
    async _init() {
        // No llamar a super._init(): queremos el form inválido por defecto
    }
    
    get canDelete(){
        return !!this._deleteMutation
    }
    
    @action
    _startOperation(/** @enum {FormOperation} */ operation){
        this.operationsInProgress.add(operation)        
    }
    
    @action
    _stopOperation(/** @enum {FormOperation} */ operation){
        this.operationsInProgress.delete(operation)
    }

    @boundMethod
    _getTitle(empty) {
        if (empty) return `Crear ${this.name.toLowerCase()}`
        else return `${this.name} ${this.recordName}`
    }

    @computed
    get title(){
        const ops = this.operationsInProgress 
        if (!this.valid || ops.has(FormOperation.LOADING) || ops.has(FormOperation.APPLYING) 
            || ops.has(FormOperation.CREATING)
        ) {
            return this.name
        }
        
        return this._getTitle(this.id === undefined)
    }

    get recordName(){
        if (!this.valid || !this.enabled){
            return ''
        }
        const name = this.getField(this.displayField)
        return name ? name : `Crear ${this.name.toLowerCase()}`
    }
    
    get rowChangesLocked(){
        return this.dirty && (this.rejectedApplyData || (this.rejectedLoadId && this.rejectedLoadId !== this.id))
    }


    async _invalidateActions() {
        this.fieldManager.load({}, {render: false})
        this.id = undefined
    }

    async _applyRow(data){
        this.id = data[this.idField]
        this.fieldManager.load(data)
        this.fieldManager.setReadOnly(data['aclReadOnly'], 'formPiece')
    }
    
    @boundMethod
    async _apply(data){
        if (!data) {
            data = {}
        }
        
        if (this.dirty) {
            this.rejectedApplyData = data
            formLockedSnakbar()
            return
        }
        this.rejectedApplyData = undefined


        this._startOperation(FormOperation.APPLYING)
        try {
            await this._applyRow(data)
            await this.validate()
            this.onApply.notify(data)
            
        }finally{
            this._stopOperation(FormOperation.APPLYING)
        }
        
    }
    apply = this._state(STATE_CURRENT_ROW, this._apply)


    @boundMethod
    async _saveRow(fields){
        return await this._saveMutation.query(fields,
            {initialData: this.fieldManager.initialFields})
    }
    
    
    getChangedFields(){
        return getChangedFields(this._saveMutation.dbTable, this.fields, this.fieldManager.initialFields)
    }
    
    @boundMethod
    async _saveAndApply(){
        const result = await this._saveRow({...this.fieldManager.fields})
        if (result.success) {
            const data = result.row
            if (data) {
                await this._applyRow(data)
            }
        }
        return result
    }
    
    @boundMethod
    async save(){
        if (!this.dirty) return
        
        this._startOperation(FormOperation.SAVING)
        try {
            const result = await this._saveAndApply()
            if (result.success) {
                runInAction(() => {
                    this.fieldManager.undirty()
                })
                this.onSave.notify(this._fields)
                return true
            } else {
                runInAction(() => {
                    this.fieldManager.setErrors(result.errorList)
                })
                return false
            }
        }finally {
            this._stopOperation(FormOperation.SAVING)
        }
    }
    __save = this._asyncAction(this.save)

    deleteAlert(){
        return new AlertDefinition(
            `¿Eliminar ${this.recordName}?`,
            `Se eliminará ${this.recordName}.`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
            ],
        )
    }
    
    @boundMethod
    async refresh(){
        if (!this.id) return
        const changes = this.getChangedFields()
        //if (this.dirty) return
        const data = await this._loadRow(this.id)
        await this._applyRow(data)
        await this.setFields(changes, {notifications: false})
        this.onRefresh.notify(this.fields)
    }
    __refresh = this._asyncAction(this.refresh)
    
    revertAlert(){
        return new AlertDefinition(
            "¿Revertir cambios?",
            "Se descartarán los cambios.", [
                [ALERT_DESCARTAR, 'Revertir'],
                [ALERT_CANCEL, 'Cancelar'],
            ],
        )   
    }
    
    
    async _deleteRows(...ids){
        await this._deleteMutation.query({ids: ids})
        this.onDeleteRows.notify(...ids)
    }
    
    @boundMethod
    async delete(){
        if (this.id === undefined) {
            throw new Error('Nada que borrar')
        }
        const deletedId = this.id
        this._startOperation(FormOperation.DELETING)
        try {
            await this._deleteRows(this.id)
        }finally{
            this._stopOperation(FormOperation.DELETING)
        }
        await this.invalidate()
        this.onCurrentRowChanging.notify()
        this.onDelete.notify(deletedId)
    }
    __delete = this._asyncAction(this.delete)
    
    @boundMethod
    async _loadRow(id){
        return await this._rowFetcher.fetch({[this.idField]: id})
    }
    
    @boundMethod
    async _load(id){
        if (id && id === this.loadingId) {
            return
        }
        
        if (this.dirty) {
            this.rejectedLoadId = id
            return
        }
        this.rejectedLoadId = undefined
        
        this.onCurrentRowChanging.notify(id)

        this._startOperation(FormOperation.LOADING)
        try{
            this.loadingId = id
            const data = await this._loadRow(id)
            await this._apply(data)
        } catch (e) {
            if (e instanceof RowNotFound) {
                this.onRowNotFound.notify()
                const exc = new Error(`No existe el registro ${id}`)
                exc[DISCARD_STATE] = true
                throw exc
            }
            throw e
        }finally {
            this.loadingId = undefined
            this._stopOperation(FormOperation.LOADING)
        }
        
        //Cosas secundarias para cargar en un segundo paso
        await this._afterLoad().then()
    }
    load = this._state(STATE_CURRENT_ROW, this._load)


    @boundMethod
    async _afterLoad(){
    }
    
    @boundMethod
    async _createRow(){
        await this._applyRow(this.newRowFields)
    }
    
    @boundMethod
    async _create(){
        if (this.dirty) {
            return
        }

        this._startOperation(FormOperation.CREATING)
        try{
            await this._createRow()
            this.fieldManager.undirty()
            
            await runInAction(async ()=>{
                await this.validate()
            })
            
            this.onCurrentRowChanging.notify()
            this.onCreate.notify(this.fields)
        
        }finally {
            this._stopOperation(FormOperation.CREATING)
        }
    }
    create = this._state(STATE_CURRENT_ROW, this._create)
    
    
    @boundMethod
    rowSelectedForLoad({id}){
        if (id){
            this.load(id).then()
        } else if (this.id) {
            this.invalidate().then()
        }
    }
    
    @boundMethod
    rowSelectedForApply({row, id}){
        if (row){
            this.apply(row).then()
        } else if (id) {
            this.load(id).then()
        } else if (this.id) {
            this.invalidate().then()
        }
    }
    
    @boundMethod
    async urlChanged(id){
        if (id !== this.id) {
            await this.invalidate()
            const test = 0
        }
    }
}
