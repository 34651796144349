 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ContactoIds} from './Contacto'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const ContactoCol = Object.freeze({
    autor : new LookupColumn(ContactoIds.autor, Tables.User, 'nombre_completo'),
    modificacion : new LookupColumn(ContactoIds.modificacion, Tables.User, 'nombre_completo'),
    idContacto : new SimpleColumn(ContactoIds.idContacto, 11, 11, Align.RIGHT),
    idCuenta : new SimpleColumn(ContactoIds.idCuenta, 11, 11, Align.RIGHT),
    nombre : new SimpleColumn(ContactoIds.nombre, 40, 16, Align.LEFT),
    tel : new SimpleColumn(ContactoIds.tel, 40, 16, Align.LEFT),
    correo : new SimpleColumn(ContactoIds.correo, 40, 16, Align.LEFT),
    notas : new SimpleColumn(ContactoIds.notas, 40, 16, Align.LEFT),
    principal : new SimpleColumn(ContactoIds.principal, 11, 11, Align.RIGHT),
    fechaAlta : new DateTimeColumn(ContactoIds.fechaAlta, 16, 16, Align.LEFT),
    fechaAltaOnlyDate : new DateColumn(ContactoIds.fechaAlta, 11, 11, Align.LEFT),
    fechaModificacion : new DateTimeColumn(ContactoIds.fechaModificacion, 16, 16, Align.LEFT),
    fechaModificacionOnlyDate : new DateColumn(ContactoIds.fechaModificacion, 11, 11, Align.LEFT),
    odooCambiado : new SimpleColumn(ContactoIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Contacto] = ContactoCol
