import {WorldObject} from 'sopix/piece/worldObject'
import {ObserverHub} from 'sopix/utils/observers'
import {locationObservers, replaceUrl} from 'sopix/router/router'
import {getRouteParams, getUrlFromRoute, parseUrlParam} from 'sopix/router/routerUtils'
import {boundMethod} from 'autobind-decorator'
import {snackbarNotifications} from 'sopix/snackbar/snackbarNotifications'

export const UrlState = Object.freeze({
    VALID: Symbol('VALID'),
    INVALID: Symbol('INVALID'),
    EXTERNAL: Symbol('EXTERNAL'),
})

export class UrlManager extends WorldObject {

    valid = true
    id
    enabled = false
    
    _idField
    _rowIsDirty = false

    /** @type {AppPageInfo} */
    _pageInfo

    onIdChange = new ObserverHub()

    constructor(world, idField, listPath, formPath) {
        super(world)
        this._idField = idField
        this.listPath = listPath
        this.formPath = formPath
    }

    @boundMethod
    enable() {
        locationObservers.subscribe(this._objectId, this.urlChanged)
        this.enabled = true
    }

    @boundMethod
    disable() {
        locationObservers.unsubscribe(this._objectId)
        this.enabled = false
    }


    _setId(id) {
        if (id === this.id) return
        this.id = id
        this.onIdChange.notify(id)
    }

    _getUrl() {
        const params = getRouteParams([this.listPath, this.formPath])

        if (!params) {
            return {state: UrlState.EXTERNAL}
        }

        const id = parseUrlParam(params[this._idField])
        if (id === null) {
            return {state: UrlState.INVALID}
        }

        return {state: UrlState.VALID, id}
    }

    _setUrl(id) {
        if (!this.enabled) {
            return
        }
        
        const url = id === undefined
            ? this.listPath
            : getUrlFromRoute(this.formPath, {[this._idField]: parseInt(id)})

        replaceUrl(url, {render: false})
    }

    
    isActive(){
        const {state} = this._getUrl()
        return state !== UrlState.INVALID
    }
    
    
    
    @boundMethod
    _rowDirty(dirty) {
        this._rowIsDirty = dirty
    }
    rowDirty = this._action(this._rowDirty)


    @boundMethod
    _urlChanged() {
        const {id, state} = this._getUrl()
        if (state === UrlState.EXTERNAL) {
            return
        }

        if (state === UrlState.INVALID) {
            snackbarNotifications.notify('Url inválido')
        }

        if (this._rowIsDirty) {
            this._setUrl(this.id)
        } else {
            this._setId(id)
        }
    }
    urlChanged = this._action(this._urlChanged)

    
    @boundMethod
    rowApplied(data) {
        this.id = data
            ? data[this._idField]
            : undefined
        
        this._setUrl(this.id)
    }
    
    @boundMethod
    rowCreated(data){
        this.rowApplied(data)
    }

    @boundMethod
    rowNotFound(){
        this._setUrl()
        this._setId()
    }
    
    @boundMethod
    _pageMounted(){
        const {id, state} = this._getUrl()
        if (state === UrlState.VALID && !id) {
            this._setUrl(this.id)
        } else {
            this._urlChanged()
        }
    }
    pageMounted = this._action(this._pageMounted)
    
    @boundMethod    
    rowSelected({id}){
        if (!id){
            this._setId()
            if (this.isActive()) {
                this._setUrl()
            }
        }
    }
}