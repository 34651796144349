 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SistemaIds} from './Sistema'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const SistemaCol = Object.freeze({
    idSistema : new SimpleColumn(SistemaIds.idSistema, 11, 11, Align.RIGHT),
    sistema : new SimpleColumn(SistemaIds.sistema, 30, 12, Align.LEFT),
})

tableColumns[Tables.Sistema] = SistemaCol
