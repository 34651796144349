import React from 'react'
import {ModalContent} from 'sopix/modal/ModalContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {LoginScreen, PasswordFld} from 'sop/login/loginState'
import Typography from '@material-ui/core/Typography'
import {useKeyPressed} from 'sopix/hooks/use-key-pressed'

export const ResetPassForm = observer(
/**
 * 
 * @param {LoginState} login
 * @param children
 * @returns {JSX.Element}
 */
({login, children}) => {

    useKeyPressed(key => {
        if (key === 'Enter') {
            login.reset()
        } else if (key === 'Escape') {
            login.setLoginScreen()
        }
    })


    return (
        <>
            <ModalContent>
                <Typography>Pregunta por tu <i>nombre de usuario</i> y <i>clave de reinicio</i> a un administrador.</Typography>
                <Fields>
                    <Field manager={login.fieldManager} name={PasswordFld.USERNAME} label="Usuario"
                           autoFocus={login.userEmpty}
                    />
                    <Field manager={login.fieldManager} name={PasswordFld.RESET_KEY} label="Clave de reinicio"
                           autoFocus={!login.userEmpty} autoComplete="off"
                    />
                    <Field manager={login.fieldManager} name={PasswordFld.NEW_PASSWORD} label="Nueva contraseña" type="password" autoComplete="off" />
                    <Field manager={login.fieldManager} name={PasswordFld.NEW_PASSWORD2} label="Repetir contraseña" type="password" autoComplete="off" />
                </Fields>
            </ModalContent>
            <DialogActions>
                <Button
                    onClick={() => login.setLoginScreen()}
                >
                    Cancelar
                </Button>
                <Push/>
                <Button
                    disabled={!login.resetAllowed}
                    onClick={login.reset}
                    color="primary"
                    defaultChecked={true}
                >
                    Reiniciar
                </Button>
            </DialogActions>
        </>
    )
})
