import {StateBasics} from 'sopix/react-state/stateBasics'
import {FieldManager} from 'sopix/field-manager/fieldManager'
import {action, computed, observable} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {boundMethod} from 'autobind-decorator'

export class FieldsState extends StateBasics{
    fieldManager = new FieldManager({onChange: this._fieldChangeReaction, onDirtyChange: this._dirtyChangeReaction})

    @observable.shallow
    _fields = []

    @observable
    _dirty = false

    @action.bound
    _dirtyChangeReaction(dirty){
        this._dirty = dirty
    }

    @action.bound
    _fieldChangeReaction({fields}){
        this._fields = fields
    }

    @computed
    get dirty() {
        return this._dirty
    }

    @boundMethod
    getField(...fieldPath){
        const fld = getFieldExtractor(this._fields)
        return fld(...fieldPath)
    }

    @action
    setField(fieldName, value){
        this.fieldManager.setValue(fieldName, value)
    }

    @action
    setFields(fields){
        this.fieldManager.setValues(fields)
    }
    
}