import React from 'react'
import {observer} from 'mobx-react'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {getLoggers} from 'sopix/log'
import {TrabajoRow} from 'sop/componentes/trabajo/TrabajoRow'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {TareaTable} from 'sop/db/TareaTable'
import {Tarea} from 'sop/db/Tarea'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {columnsFromMode, TRABAJO_LIST_FIELDS, TrabajoListMode} from 'sop/componentes/trabajo/trabajo-defs'
import {TrabajoPainter} from 'sop/componentes/trabajo/trabajoPainter'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {TrabajoSearchPiece} from 'sop/componentes/trabajo/TrabajoSearch'
import {boundMethod} from 'autobind-decorator'
import {trabajoFilter, TrabajoFilter} from 'sop/componentes/trabajo/TrabajoFilter'
import {TrabajoSortMixer} from 'sop/componentes/trabajo/trabajoSortMixer'

const {debug} = getLoggers('TrabajoList')


export class TrabajoListPiece extends TablePiece {

    /** @enum {TrabajoListMode} */
    mode

    /**
     * @param {TrabajoListMode} listMode
     */
    constructor(world, listMode) {


        const TABLE = TareaTable
        const ID_FIELD = Tarea.idTarea
        const DEFAULT_FILTER = {
            //[TrabajoFilter.RESPONSABLE_TRABAJOS]: TrabajoFilter.YO,
            [TrabajoFilter.ESTADO_TRABAJOS]: TrabajoFilter.ESTADO_ACTIVOS,
            [TrabajoFilter.ESTADO_COTIZACIONES]: TrabajoFilter.ESTADO_PLANIFICADOR,
        }
        const DEFAULT_ORDER_TRABA = [new OrderEntry(Tarea.fechaCreacion, OrderDirection.DESC)]
        const DEFAULT_ORDER_ESTU = [new OrderEntry(Tarea.ordenEstudio, OrderDirection.ASC)]
        const DEFAULT_ORDER_VALO = [new OrderEntry(Tarea.ordenValoracion, OrderDirection.ASC)]

        const columns = columnsFromMode.get(listMode)
        const painter = new TrabajoPainter(world, columns)

        const filtrator = graphqlFiltratorBuilder(world, TABLE, TRABAJO_LIST_FIELDS, {
            graphqlFilterCalculator: trabajoFilter, empty: true,
        })

        const selection = new RowSelection(world, filtrator, ID_FIELD)

        super(world, ID_FIELD, filtrator, painter, selection)

        this.sortMixer = new TrabajoSortMixer(world, [
            [TrabajoListMode.TRABAJOS, DEFAULT_ORDER_TRABA],
            [TrabajoListMode.ESTUDIOS, DEFAULT_ORDER_ESTU],
            [TrabajoListMode.VALORACIONES, DEFAULT_ORDER_VALO],
        ])
        this.sortMixer.onSort.subscribe(filtrator.__sort)

        this.search = new TrabajoSearchPiece(this.world, TrabajoListMode.TRABAJOS,
            {defaultFields: DEFAULT_FILTER})
        this.search.onFieldsChange.subscribe(filtrator.__searchChanged)
    }

    @boundMethod
    setMode(mode) {
        if (mode === this.mode) return
        this.mode = mode
        this.search.setMode(mode)
        this.sortMixer.setMode(mode)
        const columns = columnsFromMode.get(mode)
        this.painter.setColumnDescriptors(columns)
        this.filtrator.clearData().then()
        this.filtrator.filterAndSort(this.search.fields, this.sortMixer.currentOrder).then()
    }
}

export const TrabajoList = observer(
    /**
     * @param {TrabajoListPiece} list
     */
    ({list, ...other}) => {
        return (
            <GraphqlTable
                verticalAlign="top"
                Row = {TrabajoRow}
                list={list}
                order={list.sortMixer.currentOrder}
                onOrderChange={list.sortMixer.__orderChanged}
                {...other} 
            />
        )
})
