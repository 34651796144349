import Moment from 'moment'
import {Obra} from 'sop/db/Obra'
import {getILikeFromKeyword} from 'sopix/db/graphql-utils'
import {userAuth} from 'sopix/session/userAuth'
import {UserRoles} from 'sop/db-config/row-consts'
import {Representante} from 'sop/db/Representante'

/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export function obraFilter(searchFields) {
    const result = {}
    for (let [key, value] of Object.entries(searchFields)) {
        if (value === null || value === '') {
            continue
        }
        switch (key) {
            case Obra.idObra:
                //result.idObraLt = parseInt(value) + 10
                result.idObraGt = parseInt(value) - 10
                break
            case 'buscar':
                const words = value.split(' ')
                const nombreEntries = words.map(word => ({nombreIlike: getILikeFromKeyword(word)}))
                result.or = [
                    {codIlike: getILikeFromKeyword(value)},
                    //Muy lento:
                    //{solicitanteIlike: getILikeFromKeyword(value)},
                    {and: nombreEntries},
                ]
                break
            case Obra.m2:
                const m2 = parseFloat(value)
                let m2Rango = parseFloat(searchFields.m2Rango)

                if (isNaN(m2)) {
                    break
                }

                if (isNaN(m2Rango)) {
                    m2Rango = 0
                }

                const delta = m2Rango * m2 / 100
                result.m2Gte = m2 - delta
                result.m2Lte = m2 + +delta
                break
            case Obra.cp:
                result.cpIlike = `%${value}%`
                break
            case 'cpProvincia':
                result.cpIlike = `${searchFields.cpProvincia}%`
                break
            case 'altaDesde':
                const altaDesde = Moment(value).format()
                result.fechaAltaGte = altaDesde
                break
            case 'altaHasta':
                const altaHasta = Moment(value).add(1, 'day').format()
                result.fechaAltaLt = altaHasta
                break
            case Obra.idTipologia:
                result.idTipologia = parseInt(value)
                break
            case Obra.idStatus:
                result.idStatus = parseInt(value)
                break
            case Obra.obraRepresentativa:
                if (value) {
                    result[Obra.obraRepresentativa] = 1
                }
                break
            case Obra.passiveHouse:
                if (value) {
                    result[Obra.passiveHouse] = 1
                }
                break
            default:
        }
    }
    
    if (userAuth.role === UserRoles.REPRESENTANTE) {
        result[Representante.idRepresentante] = userAuth.userId
    }
    
    return result
}