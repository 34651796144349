import React, {useState} from 'react'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {makeStyles} from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {icons} from 'sopix/icon/icons'
import {observer} from 'mobx-react'
import {getLog} from 'sopix/log'
import {ColumnsSummary} from 'sopix/db-components/ColumnsSummary'
import {Solicitud} from 'sop/db/Solicitud'
import {SolicitanteForm} from 'sop/componentes/obra-solicitante-S/SolicitanteForm'
import {ModalFormFrameOld} from 'sopix/modal-state/ModalFormFrameOld'
import {action} from 'mobx'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import List from '@material-ui/core/List'
import {Contacto} from 'sop/db/Contacto'
import {Cuenta} from 'sop/db/Cuenta'
import {Bar} from 'sopix/formComps/Bar'
import {Frame} from 'sopix/formComps/Frame'
import {ListItem} from '@material-ui/core'
import {sopColors} from 'sop/sop-colors'
import {ContactoCol} from 'sop/db/ContactoColumns'
import {DireccionCol} from 'sop/db/DireccionColumns'
import {CuentaCol} from 'sop/db/CuentaColumns'
import {SolicitudFld} from 'sop/db/SolicitudTable'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {CuentaTipo} from 'sop/db/CuentaTipo'
import {cimStyles} from 'sopix/theme/cimStyles'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {odooUrl} from "sop/db-config/odoo";

const log = getLog('SolicitanteList')


const CONTACTO_SUMMARY = [
    ContactoCol.tel,
    ContactoCol.correo,
]
const DIRECCION_SUMMARY = [
    DireccionCol.cp,
    DireccionCol.direccion,
    DireccionCol.localidad,
    DireccionCol.provincia,
]
const CUENTA_SUMMARY = [
    CuentaCol.telefono1,
    CuentaCol.cifdni,
]
export const SOLICITUD_SUMMARY = [
    [SolicitudFld.contacto, CONTACTO_SUMMARY],
    [SolicitudFld.direccion, DIRECCION_SUMMARY],
    [SolicitudFld.cuenta, CUENTA_SUMMARY],
]



const entryStyles = makeStyles(theme => ({
    listItemSecondaryAction: {
        visibility: "hidden",
    },
    
    listItemIcon: {
        visibility: "hidden",    
    },
    
    listItem: {
        "&:hover $listItemSecondaryAction": {
            visibility: "inherit",
        },
        "&:hover $listItemIcon": {
            visibility: "inherit",
        },
    },

    info: {
        backgroundColor: sopColors.infoAreaBackground,
    }
})) 


/**
 *
 * @param text
 * @param checked
 * @param selected
 * @param {SolicitanteListState} solicitudes
 * @param solicitud
 * @param selectSolicitud
 * @param listItemProps
 * @returns {JSX.Element}
 * @constructor
 */
const Entry = observer(
({text, selected, info, setInfo, solicitanteList, solicitud, onEdit, onDelete, ...listItemProps}) => {

    const idSolicitud = solicitud.idSolicitud
    const cls = entryStyles()
    
    function handleClick(){
        if (selected){
            if (info) {
                setInfo(undefined)
            } else {
                setInfo(idSolicitud)
            }
        } else {
            solicitanteList.select(idSolicitud)
            setInfo(undefined)
        }
    }
    
    const sol = getFieldExtractor(solicitud)

    const odooDireccionTecnicoId = solicitud.odooDireccionTecnicoId
    const odooCuentaCotizaciones = solicitud.odooCuentaCotizaciones
    const textoCotizaciones = `${odooCuentaCotizaciones} ${odooCuentaCotizaciones === 1 ? 'cotización': 'cotizaciones'}`

    return (
        <div>
            <ListItem
                classes={{container: cls.listItem}} dense button selected={selected}
                onClick={action(handleClick)} {...listItemProps}
            >
                <ListItemIcon className={cls.listItemIcon}>
                    {!userAuth.isAllowed(Permiso.modificarSolicitantes) || odooDireccionTecnicoId ? null :
                        <HintIconButton Icon={icons.Edit} title="editar" onClick={onEdit}/>
                    }
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    secondary={
                        <span>
                            <span>{sol(Solicitud.cuenta, Cuenta.tipo, CuentaTipo.tipo)}</span> - <span>{
                                !odooDireccionTecnicoId ?
                                    <span>{sol(Solicitud.contacto,Contacto.nombre)}</span> :
                                    <span style={{color: 'Black'}}>
                                        <strong>Proyecta</strong>:
                                        &nbsp;<a rel="noreferrer" href={`${odooUrl}/cotizacion/api/vista_cotizaciones_direccion?sop_id_solicitud=${idSolicitud}`} target="_blank" >{`${textoCotizaciones} Odoo`}</a>
                                    </span>
                            }</span>
                        </span>
                    }
                />
                <ListItemSecondaryAction className={cls.listItemSecondaryAction}>
                    {!userAuth.isAllowed(Permiso.quitarSolicitantes) ? null :
                        <HintIconButton Icon={icons.Delete} title="quitar solicitante" onClick={onDelete}/>
                    }
                </ListItemSecondaryAction>
            </ListItem>
            {!info ? null : <ColumnsSummary className={cls.info} data={solicitud} columns={[
                [SolicitudFld.contacto, [
                    ContactoCol.tel,
                    [ContactoCol.correo, {value: v => <a href={`mailto:${v}`}>{v}</a> }],
                ]],
                [SolicitudFld.direccion, DIRECCION_SUMMARY],
                [SolicitudFld.cuenta, CUENTA_SUMMARY],
            ]}/>}
        </div>
    )
})







const useStyles = makeStyles(() => ({
    list: {
        flex: 1,
        overflow: 'auto',
        width: '100%',
    },
    icon: {
        marginRight: 10,
    },
    invalid: {
        ...cimStyles.disabled,
        width: '100%',
    }
}))


export const SolicitanteList = observer(
/**
 * @param {SolicitanteListState} solicitanteList
 */
({solicitanteList}) => {
    const cls = useStyles()

    const [info, setInfo] = useState()
    
    const asa = solicitanteList._locked_catched_async
    
    const result =
        <>
            <Frame>
                <List className={cls.list} disablePadding={true}>
                    <>
                        <Bar absolute transparent Icon={sopIcons.Solicitante} iconProps={{
                            title: "Solicitantes",
                            badge: solicitanteList.badgeCount,
                            badgeColor: sopColors.badgeCount,
                            color: 'black',
                        }} />
                        <Bar direction="rowReverse" absolute transparent>
                            {!userAuth.isAllowed(Permiso.agregarSolicitantes) ? null :
                                <HintIconButton Icon={sopIcons.Solicitante} badge='+' badgeColor={sopColors.badgeAdd}
                                    title="Añadir solicitante" onClick={() => solicitanteList.createItem()}
                                />
                            }
                        </Bar>
                        <ListItem button selected={!solicitanteList.selected} onClick={() => solicitanteList.select()}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Todos los solicitantes" />
                        </ListItem>
                    </>
                    {solicitanteList.rows.map((solicitud, index) => {
                        const {idSolicitud, cuenta} = solicitud
                        return (
                            <Entry
                                key={idSolicitud}
                                text={cuenta.cuenta}
                                selected={solicitanteList.selected === idSolicitud}
                                info={info === idSolicitud}
                                setInfo={setInfo}
                                solicitanteList={solicitanteList}
                                solicitud={solicitud}
                                onEdit={() => solicitanteList.openItem(solicitud[Solicitud.idSolicitud])}
                                onDelete={asa(() => solicitanteList.delete(idSolicitud))}
                            />
                        )
                    })}
                </List>
            </Frame>
            <ModalFormFrameOld
                formState={solicitanteList.form}
                maxWidth="sm"
                onClose={solicitanteList.closeItem}
            >
                <SolicitanteForm solicitante={solicitanteList.form} />
            </ModalFormFrameOld>
        </>

    if (solicitanteList.valid) {
        return result
    } else {
        return <div className={cls.invalid} />
    }
    
})
