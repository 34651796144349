import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {UserEdt} from 'sop/db/UserEditors'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {usePiece} from 'sopix/piece/use-piece'


export class UsuarioSearchPiece extends SearchPiece{
    _listFetcher = new ListFetcher([
        UserEdt.roleUsuarioObj.getListQueryEntry(),
    ])

    async _init() {
        this.setLists(await this._listFetcher.fetch())
        await super._init()
    }
}


/**
 * 
 * @param {SearchPiece} search
 * @returns {JSX.Element}
 * @constructor
 */
export const UsuarioSearch = ({search}) => {

    usePiece(search)


    return (
        <PieceFrame piece={search} renderPiece={()=> {

            const fld = search.fieldHelper
            const man = search.fieldManager

            return (
                <>
                    <Field manager={man} name="buscar" label="buscar" autoFocus/>
                    <DropdownField {...fld.edt(UserEdt.roleUsuarioObj)} disabled={false} />
                </>
            )

        }} />
    )
}