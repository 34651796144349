import React from 'react'
import {FrameFlag, Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {Bar} from 'sopix/formComps/Bar'
import {SageClienteTable} from 'sop/db/SageClienteTable'
import {ListFetcher, ListQueryEntry} from 'sopix/db-access/listFetcher'
import {SageEmpresa} from 'sop/db/SageEmpresa'
import {SageEmpresaFld, SageEmpresaTable} from 'sop/db/SageEmpresaTable'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {SageCliente} from 'sop/db/SageCliente'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {DbSource} from 'sopix/data-source/dbSource'
import {TabBar} from 'sopix/formComps/TabFrame'
import {Tab} from '@material-ui/core'
import {find} from 'lodash-es'
import {action, observable} from 'mobx'
import {ObserverHub} from 'sopix/utils/observers'
import {boundMethod} from 'autobind-decorator'


export class SageCuentaListPiece extends Piece{
    
    onChange = new ObserverHub()
    
    @observable
    tabNo
    
    listFetcher = new ListFetcher([
        new ListQueryEntry({
            sort: calcGraphqlOrder(SageEmpresa.codigoEmpresa)
        }, [
            SageEmpresaFld.codigoEmpresa, SageEmpresaFld.anagrama
        ], {
            [`${SageEmpresa.codigoEmpresa}Lt`]: 10
        })
    ])
    
    constructor(world) {
        super(world)
        
        const FIELDS = [
            ...SageClienteTable.regularFields,
        ]
        
        this.dbSource = new DbSource(this.world, new TableGraphql(SageClienteTable), 
            FIELDS, {defaultGraphqlParams: {
                sort: calcGraphqlOrder(SageEmpresa.codigoEmpresa)
            }})

        this.load = this._state(Symbol('LOAD'), async cif => {
            if (!cif){
                await this.dbSource.clearData()
                this.setTabNo()
            } else {
                this.dbSource._setGraphqlFilter({
                    [`${SageCliente.cifDni}Ilike`]: cif
                })
                this.setTabNo()

                await this.dbSource.refresh()
                
                if (this.dbSource.data.length){
                    this.setTabNo(0)
                }
            }
        })
        
    }
    
    @action.bound
    setTabNo(tabNo){
        if (tabNo !== this.tabNo){
            this.tabNo = tabNo
            this.onChange.notify(tabNo === undefined ? undefined : this.dbSource.data[tabNo])
        }
    }

    @boundMethod
    async _init() {
        this.lists = await this.listFetcher.fetch()
    }
}


export const SageCuentaTab = getPieceContainer('list', observer(
    /**
     * @param {SageCuentaListPiece} list
     */
    ({list}) => {

        const sya = list._action

        const tabs = []
        for (let [idx, row] of Object.entries(list.dbSource.data)) {
            const codEmpresa = row[SageCliente.codigoEmpresa]
            const empresa = find(list.lists[SageEmpresaTable.graphqlId].rows, 
                {[SageEmpresa.codigoEmpresa]: codEmpresa})
            const anagrama = empresa[SageEmpresa.anagrama]
            tabs.push(<Tab key={idx} label={anagrama} />)
        }

        if (list.tabNo === undefined) return null
        
        return (
            <Bar>
                <TabBar tabs={tabs} value={list.tabNo} onChange={sya(value => list.setTabNo(value))} />
            </Bar>
        )
}), {[FrameFlag.SHRINK]: true})
