import {boundMethod} from 'autobind-decorator'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('boundless-classes')

export const DataSide = Object.freeze({
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    NEW: 'NEW',
    NONE: 'NONE',
})

export const BoundlessState = Object.freeze({
    STATIC: 'STATIC',
    DYNAMIC: 'DYNAMIC',
})


export class TableColumn{
    constructor(id, {title, width, minWidth, maxWidth, align, props, headerProps} = {}) {
        this.id = id
        this.title = title === undefined ? id : title
        this.width = width
        this.minWidth = minWidth
        this.maxWidth = maxWidth
        this.align = align
        this.props = props || {}
        this.headerProps = headerProps || {}
    }
}


export class BoundlessRow{
    height = undefined
    doRender
    visible = false
    dirty = true
}

function createArrayOfRows(count){
    return new Array(count).fill(undefined).map(() => new BoundlessRow())    
}

export class BoundlessRows{

    dirtyTop = 0
    dirtyBottom = 0
    topRequest = false
    bottomRequest = false

    constructor(index, count) {
        this.index = index
        this.rows = createArrayOfRows(count)
        this.dirtyBottom = count
    }
    
    get totalHeight(){
        let result = 0
        for (let {height} of this.rows) {
            result += height === undefined ? 0 : height
        }
        return result
    }
    
    get length(){
        return this.rows.length
    }
    
    get topAllowed(){
        return this.dirtyTop === 0 && !this.topRequest
    }
    
    get bottomAllowed(){
        return this.dirtyBottom === 0 && !this.bottomRequest
    }

    measureTopRows(count){
        if (count < 0) return 0
        let idx = 0
        let heightSum = 0
        for (let {height} of this.rows) {
            if (idx++ >= count) {
                break
            }
            if (height === undefined) return undefined
            heightSum += height
        }
        return heightSum
    }
    
    expandBottom(count) {
        if (count === 0) return
        if (this.dirtyBottom) throw new Error(`Cannot expand ${count} rows on a dirty bottom. ${this.asText()}`)
        const newRows = createArrayOfRows(count)
        this.rows = [...this.rows, ...newRows]
        this.dirtyBottom += count
    }

    expandTop(count) {
        if (count === 0) return
        if (this.dirtyTop) throw new Error('Cannot expand dirty top')
        this.index -= count
        const newRows = createArrayOfRows(count)
        this.rows = [...newRows, ...this.rows]
        this.dirtyTop += count
    }

    trimTop(trimCount) {
        if (trimCount === 0) return
        if (this.topRequest) throw new Error('Cannot trim top rows with a pending request')
        if (this.dirtyTop > 0) throw new Error('Cannot trim dirty top rows')
        this.index += trimCount
        this.rows = this.rows.slice(trimCount)
    }

    trimBottom(trimCount) {
        if (trimCount === 0) return
        if (this.bottomRequest) throw new Error('Cannot trim bottom rows with a pending request')
        if (this.dirtyBottom > 0) throw new Error('Cannot trim dirty bottom rows')
        this.rows = this.rows.slice(0, -trimCount)
    }

    countTopRows(cutHeight){
        if (this.dirtyTop > 0) throw new Error('Cannot count dirty top rows')
        
        const maxRows = this.rows.length - this.dirtyBottom
        let rowCount = 0
        let heightCount = 0
        for (let {height} of this.rows) {
            if (rowCount >= maxRows) break
            if (heightCount + height > cutHeight) break
            heightCount += height
            rowCount++
        }
        return [rowCount, heightCount]
    }
    
    countBottomRows(cutHeight){
        if (this.dirtyBottom > 0) throw new Error('Cannot count dirty bottom rows')
        
        const maxRows = this.rows.length - this.dirtyTop
        let rowCount = 0
        let heightCount = 0
        for (let {height} of [...this.rows].reverse()){
            if (rowCount >= maxRows) break
            if (heightCount + height > cutHeight) break
            heightCount += height
            rowCount++
        }
        return [rowCount, heightCount]        
    }
    
    @boundMethod
    updateRow(index, height){
        const localIndex = index - this.index

        if (index < this.index) {
            const debug = 1
            return
        }
        
        if (index >= this.index + this.rows.length) {
            const debug = 1
            return
        }

        const row = this.rows[localIndex]

        //Resized!
        if (row.height !== height) {
            debug(()=>`updateRow ${index} (#${localIndex}): ${height}px`)
            row.dirty = true
            row.height = height
        }
    }
    
    cleanUpTopRows(){
        //Check
        for (let i = 0; i < this.dirtyTop; i++){
            if (this.rows[i].height === undefined) return false
        }

        //Enable
        let heightSum = 0
        for (let i = 0; i < this.dirtyTop; i++){
            const row = this.rows[i] 
            row.visible = true
            heightSum += row.height
        }
        
        this.dirtyTop = 0
        return heightSum
    }

    cleanUpBottomRows(){
        const last = this.rows.length - 1
        
        //Check
        for (let i = 0; i < this.dirtyBottom; i++){
            if (this.rows[last - i].height === undefined) return false
        }

        //Enable
        let heightSum = 0
        for (let i = 0; i < this.dirtyBottom; i++){
            const row = this.rows[last - i]
            row.visible = true
            heightSum += row.height
        }
        
        this.dirtyBottom = 0
        return heightSum
    }

    getAverageHeight(){
        let sum = 0
        let count = 0
        for (let {height} of this.rows) {
            if (height !== undefined) {
                sum += height
                count ++
            }
        }
        return count === 0 ? undefined : sum / count
    }

    asText(){
        return `[?${this.index}:${this.index + this.rows.length}?]`
    }
    
}


export class BoundlessManager{
    
    /** @type {Boundless} */
    _boundless
    
    get scroll(){
        if (!this._boundless) return ()=>{}
        return this._boundless.scrollAction
    }
    
    get reset(){
        if (!this._boundless) return ()=>{}
        return this._boundless.resetAction
    }
    
    get invalidate(){
        if (!this._boundless) return ()=>{}
        return this._boundless.invalidateAction
    }
}


export class BoundlessStats{
    scrollTop
    windowHeight
    avgHeight
}