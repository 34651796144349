 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AnualMesIds = Object.freeze({
    liqtecmeses: Symbol('liqtecmeses'),
    mesNoObj: Symbol('mesNoObj'),
    idMes: Symbol('idMes'),
    anoNo: Symbol('anoNo'),
    mesNo: Symbol('mesNo'),
})

export const AnualMes = Object.freeze({
    liqtecmeses: 'liqtecmeses',
    mesNoObj: 'mesNoObj',
    idMes: 'idMes',
    anoNo: 'anoNo',
    mesNo: 'mesNo',
})
