import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {observer} from 'mobx-react'
import clsx from 'clsx'
import {DisplayStatus} from 'sopix/piece/piece-enums'
import {CircularProgress} from '@material-ui/core'
import {icons} from 'sopix/icon/icons'
import {cimStyles} from 'sopix/theme/cimStyles'
import {FrameFlag} from 'sopix/piece/piece'
import {usePiece} from 'sopix/piece/use-piece'
import {ErrorList} from 'sopix/errors/ErrorList'

const useStyles = makeStyles(theme => ({
    pieceContainer: {
        flex: 1,
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: 40,
        minWidth: 40,
    },
    locked: {
        pointerEvents: 'none',
    },
    shade: {
        position: 'absolute', 
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 99,
        opacity: 0.01,  //Esto cambia el stackin ctx
        background: 'black',
    },
    progress: {
        width: 36,
        height: 36,
    },
    centered: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 100,
    },
    fields: cimStyles.fields,
    margin: cimStyles.margin,
    margin2: cimStyles.margin2,
    shrink: cimStyles.shrink,
    row: cimStyles.row,
    scroll: cimStyles.scroll,
    block: cimStyles.block,
})) 

export const PieceFrame = observer(
    /**
     * 
     * @param {Piece} piece
     * @param children
     * @returns {JSX.Element}
     */
    ({className, piece, renderPiece, ...frameFlags}) => {
    
        const cls = useStyles()
        
        const locked = [DisplayStatus.PROGRESS_OPAQUE, DisplayStatus.PROGRESS_TRANSPARENT]
            .includes(piece.displayStatus) 
        const transparent = [DisplayStatus.VALID, DisplayStatus.PROGRESS_TRANSPARENT].includes(piece.displayStatus)
        const shade = [DisplayStatus.PROGRESS_TRANSPARENT, DisplayStatus.EMPTY, DisplayStatus.ERROR]
            .includes(piece.displayStatus)
        const error = [DisplayStatus.ERROR].includes(piece.displayStatus)
        
        const ProgressIcon = !locked ? null : CircularProgress
        
        function flag(id){
            const param = frameFlags[id]
            return param !== undefined ? param : piece.frameFlags.has(id)
        }
        
        return (
            <div className={clsx(
                cls.pieceContainer,
                locked && cls.locked,
                flag(FrameFlag.SHRINK) && cls.shrink,
                flag(FrameFlag.FIELDS) && cls.fields,
                flag(FrameFlag.MARGIN) && cls.margin,
                flag(FrameFlag.MARGIN2) && cls.margin2,
                flag(FrameFlag.ROW) && cls.row,
                flag(FrameFlag.SCROLL) && cls.scroll,
                flag(FrameFlag.BLOCK) && cls.block,
                shade && cls.withShade,
                className,
            )} >
                {!shade ? null : <div className={cls.shade} /> }
                {!transparent ? null : renderPiece()}
                {!ProgressIcon? null : <div className={cls.centered}><ProgressIcon className={cls.progress} /></div>}
                {!error? null : <icons.Error className={cls.centered} />}
            </div>
        )
})





export function getPieceContainer(propName, Comp, pieceProps={}){
    return observer(

        (props) => {

            const piece = props[propName]

            usePiece(piece)

            return (
                <PieceFrame {...pieceProps} piece={piece} renderPiece={()=> {
                    return (
                        <Comp {...props} />
                    )
                }} />
            )
        })
}


export function getPageContainer(comp, pieceProps={}){
    return observer(

        (props) => {

            const piece = props.page

            usePiece(piece)

            const Comp = observer(comp)
            
            return (
                <>
                    <ErrorList errorManager={piece.errorManager} />
                    <PieceFrame {...pieceProps} piece={piece} renderPiece={()=> {
                        return (
                            <Comp {...props} />
                        )
                    }} />
                </>
            )
        })
}
