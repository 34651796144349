 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SagePedidoIds = Object.freeze({
    codigoEmpresa: Symbol('codigoEmpresa'),
    ejercicioPedido: Symbol('ejercicioPedido'),
    seriePedido: Symbol('seriePedido'),
    numeroPedido: Symbol('numeroPedido'),
    codigoCliente: Symbol('codigoCliente'),
    suPedido: Symbol('suPedido'),
    razonSocial: Symbol('razonSocial'),
    fechaPedido: Symbol('fechaPedido'),
    referencia: Symbol('referencia'),
})

export const SagePedido = Object.freeze({
    codigoEmpresa: 'codigoEmpresa',
    ejercicioPedido: 'ejercicioPedido',
    seriePedido: 'seriePedido',
    numeroPedido: 'numeroPedido',
    codigoCliente: 'codigoCliente',
    suPedido: 'suPedido',
    razonSocial: 'razonSocial',
    fechaPedido: 'fechaPedido',
    referencia: 'referencia',
})
