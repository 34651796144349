export class FuncState {
    retryFunc
    stateId
    runNo
    obj
    func
    args
    options
    
    constructor(obj, retryFunc, stateId, func, args, options){
        this.obj = obj
        this.retryFunc = retryFunc
        this.stateId = stateId
        this.func = func
        this.args = args
        this.options = options
    }
}