 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ContadorIds} from './Contador'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbText} from 'sopix/db/dbField'

export const ContadorTable = new DbTable(
    Tables.Contador,
    'Contadores',
    graphqlUrl,
    'Contador',
    [ContadorIds.idContador],
    [ContadorIds.idContador, ContadorIds.contador, ContadorIds.letra, ContadorIds.numero],
    [],
    [],
    ['verListas'],
    ['modificarConfiguracion'],
    undefined,
    undefined,
    [],
    [],
)


export const ContadorFld = Object.freeze({
    idContador : new DbIntId(Tables.Contador, ContadorIds.idContador, 'Id contador'),
    contador : new DbText(Tables.Contador, ContadorIds.contador, 'Contador', 15),
    letra : new DbText(Tables.Contador, ContadorIds.letra, 'Letra', 2),
    numero : new DbInt(Tables.Contador, ContadorIds.numero, 'Numero'),
})

registerTableFields(Tables.Contador, ContadorFld) 
