 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AproestaIds = Object.freeze({
    tareas: Symbol('tareas'),
    aprobacion: Symbol('aprobacion'),
    trabajo: Symbol('trabajo'),
    estadoOriginal: Symbol('estadoOriginal'),
    estado: Symbol('estado'),
    idAproesta: Symbol('idAproesta'),
    idAprobacion: Symbol('idAprobacion'),
    idTrabajo: Symbol('idTrabajo'),
    idEstadoOriginal: Symbol('idEstadoOriginal'),
    idEstado: Symbol('idEstado'),
    justificacion: Symbol('justificacion'),
})

export const Aproesta = Object.freeze({
    tareas: 'tareas',
    aprobacion: 'aprobacion',
    trabajo: 'trabajo',
    estadoOriginal: 'estadoOriginal',
    estado: 'estado',
    idAproesta: 'idAproesta',
    idAprobacion: 'idAprobacion',
    idTrabajo: 'idTrabajo',
    idEstadoOriginal: 'idEstadoOriginal',
    idEstado: 'idEstado',
    justificacion: 'justificacion',
})
