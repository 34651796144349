 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ContadorIds} from './Contador'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const ContadorCol = Object.freeze({
    idContador : new SimpleColumn(ContadorIds.idContador, 11, 11, Align.RIGHT),
    contador : new SimpleColumn(ContadorIds.contador, 15, 10, Align.LEFT),
    letra : new SimpleColumn(ContadorIds.letra, 2, 2, Align.LEFT),
    numero : new SimpleColumn(ContadorIds.numero, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Contador] = ContadorCol
