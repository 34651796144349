 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const DocumentoIds = Object.freeze({
    aprobaciones: Symbol('aprobaciones'),
    aproplanos: Symbol('aproplanos'),
    files: Symbol('files'),
    autor: Symbol('autor'),
    obra: Symbol('obra'),
    solicitud: Symbol('solicitud'),
    cotizacion: Symbol('cotizacion'),
    planosEstado: Symbol('planosEstado'),
    usuarioPlanos: Symbol('usuarioPlanos'),
    aproplano: Symbol('aproplano'),
    idDocumento: Symbol('idDocumento'),
    idAutor: Symbol('idAutor'),
    idObra: Symbol('idObra'),
    idSolicitud: Symbol('idSolicitud'),
    idCotizacion: Symbol('idCotizacion'),
    documento: Symbol('documento'),
    fechaCreacion: Symbol('fechaCreacion'),
    borrado: Symbol('borrado'),
    fechaModificacion: Symbol('fechaModificacion'),
    idModificacion: Symbol('idModificacion'),
    idPlanosEstado: Symbol('idPlanosEstado'),
    idUsuarioPlanos: Symbol('idUsuarioPlanos'),
    planosFechaEnvio: Symbol('planosFechaEnvio'),
    idAproplano: Symbol('idAproplano'),
})

export const Documento = Object.freeze({
    aprobaciones: 'aprobaciones',
    aproplanos: 'aproplanos',
    files: 'files',
    autor: 'autor',
    obra: 'obra',
    solicitud: 'solicitud',
    cotizacion: 'cotizacion',
    planosEstado: 'planosEstado',
    usuarioPlanos: 'usuarioPlanos',
    aproplano: 'aproplano',
    idDocumento: 'idDocumento',
    idAutor: 'idAutor',
    idObra: 'idObra',
    idSolicitud: 'idSolicitud',
    idCotizacion: 'idCotizacion',
    documento: 'documento',
    fechaCreacion: 'fechaCreacion',
    borrado: 'borrado',
    fechaModificacion: 'fechaModificacion',
    idModificacion: 'idModificacion',
    idPlanosEstado: 'idPlanosEstado',
    idUsuarioPlanos: 'idUsuarioPlanos',
    planosFechaEnvio: 'planosFechaEnvio',
    idAproplano: 'idAproplano',
})
