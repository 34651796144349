import {iterateRowsAndColumns, orderData} from 'sopix/boundless-db/boundless-db-utils'
import {useMemo, useState} from 'react'
import {decodeColumnDescriptors} from 'sopix/db/db-column-utils'

export function useBoundlessTable(data, columnDescriptors, keyId, getFormatted, getSortable, initialOrder = []){
    
    const viewData = useMemo(() => {
        const dbColumns = decodeColumnDescriptors(columnDescriptors)
        return iterateRowsAndColumns(data, dbColumns, getFormatted)
    }, [columnDescriptors, data, getFormatted])
    
    const sortData = useMemo(() => {
        const dbColumns = decodeColumnDescriptors(columnDescriptors)
        return iterateRowsAndColumns(data, dbColumns, getSortable)
    }, [columnDescriptors, data, getSortable])

    const [order, setOrder] = useState(initialOrder)

    const orderedData = useMemo(() => {
        if (order.length === 0) {
            return viewData
        } else {
            return orderData(viewData, sortData, keyId, order)
        }
    }, [keyId, order, sortData, viewData])

    return [orderedData, order, setOrder]
}
