import {action, computed, observable, runInAction} from 'mobx'
import {InitState} from 'sopix/utils/InitState'
import {boundMethod} from 'autobind-decorator'
import {ErrorStore} from 'sopix/errors/ErrorStore'

export class StateBasics {

    @observable
    _initialized = InitState.NO
    
    @computed
    get initialized(){
        return this._initialized
    }
    @action
    setInitialized(value){
        this._initialized = value
    }

    
    @observable
    _locked

    _initializing = false

    @computed
    get locked(){
        return this._locked
    }
    
    @action
    setLocked(value){
        this._locked = value
    }

    /** @type {ErrorStore} **/
    errorManager

    @observable
    _icon

    @observable
    _name
    
    @computed
    get name(){
        return this._name
    }
    
    @computed
    get icon(){
        return this._icon
    }

    constructor({name, icon, errorManager} = {}){
        this.errorManager = errorManager !== undefined ? errorManager : new ErrorStore()
        runInAction(() => {
            this._name = name
            this._icon = icon
        })
    }
    

    @action.bound
    async initialize(){
        if (this.initialized === InitState.YES) return
        if (!this.errorManager) throw new Error('Missing errorManager')
        this.errorManager.clear()
        try {
            await this._init()
            this.errorManager.clear()
        } catch (error) {
            this.setInitialized(InitState.ERROR)
            this.errorManager.add(error)
        }
    }


    async __init() {
    }    
    

    async _init(){
        if (this.initialized === InitState.YES || this._initializing) return

        this._initializing = true
        try{
            this.setInitialized(InitState.NO)

            await this.__init()

            this.setInitialized(InitState.YES)
        }finally {
            this._initializing = false
        }
    }


    @boundMethod
    _locked_catched_async(fn){
        return action(async (...args) => {
            if (!this.errorManager) throw new Error('Missing errorManager')

            this.setLocked(true)
            try {
                return await fn(...args)
            }catch(error){
                this.errorManager.add(error)
            }finally {
                this.setLocked(false)
            }
        })
    }

    @boundMethod
    _catched_async(fn){
        return action(async (...args) => {
            if (!this.errorManager) throw new Error('Missing errorManager')

            try {
                return await fn(...args)
            }catch(error){
                this.errorManager.add(error)
            }
        })
    }
    
    @boundMethod
    _catched(fn){
        return action((...args) => {
            if (!this.errorManager) throw new Error('Missing errorManager')
            try {
                return fn(...args)
            }catch(error){
                this.errorManager.add(error)
            }
        })
    }

    @boundMethod
    mountReaction(){
        this.initialize().then()
    }

    @boundMethod
    unmountReaction(){
    }
}