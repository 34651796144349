 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {CuentaTipoIds} from './CuentaTipo'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const CuentaTipoTable = new DbTable(
    Tables.CuentaTipo,
    'CuentaTipos',
    graphqlUrl,
    'CuentaTipo',
    [CuentaTipoIds.idTipo],
    [CuentaTipoIds.idTipo, CuentaTipoIds.tipo],
    [],
    [CuentaTipoIds.cuentas],
    ['verCuentas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const CuentaTipoFld = Object.freeze({
    cuentas : new DbObjectList(Tables.CuentaTipo, CuentaTipoIds.cuentas, Tables.Cuenta),
    idTipo : new DbIntId(Tables.CuentaTipo, CuentaTipoIds.idTipo, 'Id tipo'),
    tipo : new DbText(Tables.CuentaTipo, CuentaTipoIds.tipo, 'Tipo', 20),
})

registerTableFields(Tables.CuentaTipo, CuentaTipoFld) 
