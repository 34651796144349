import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'


/**
 * 
 * @param {SearchPiece} search
 * @returns {JSX.Element}
 * @constructor
 */
export const LiquidacionSearch = ({search}) => {

    usePiece(search)


    return (
        <PieceFrame piece={search} renderPiece={()=> {

            const fld = search.fieldHelper
            const man = search.fieldManager

            const dateGroupOptions=[
                {
                    idPeriodo: 1,
                    periodo: 'Semana',
                },
                {
                    idPeriodo: 2,
                    periodo: 'Mes',
                },
                {
                    idPeriodo: 3,
                    periodo: 'Año',
                },
            ]
            
            return (
                <>
                    <Field manager={man} name="buscar" label="buscar" autoFocus/>
                    <Push />
                </>
            )

        }} />
    )
}