import React from 'react'
import {TransparentProgress} from 'sopix/progress/TransparentProgress'
import {WithInitProgress} from 'sopix/progress/WithInitProgress'

/**
 * @param {InitState} initialized
 * @param onReplay
 * @param locked
 * @param invisible
 * @param children
 * @param backDropProps
 * @returns {JSX.Element}
 * @constructor
 */
export const WithComplexProgress = ({initialized, onReplay, locked = false, children, ...backDropProps}) => {
    
    return (
        <WithInitProgress
            initialized={initialized}
            onReplay={onReplay}
        >
            {children}
            <TransparentProgress open={locked} {...backDropProps}/>
        </WithInitProgress>
    )
}