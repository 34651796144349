 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageDomicilioIds} from './SageDomicilio'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbText, DbTextId} from 'sopix/db/dbField'

export const SageDomicilioTable = new DbTable(
    Tables.SageDomicilio,
    'Domicilios',
    graphqlUrl,
    'SageDomicilio',
    [SageDomicilioIds.numeroDomicilio],
    [SageDomicilioIds.codigoEmpresa, SageDomicilioIds.codigoCliente, SageDomicilioIds.tipoDomicilio, SageDomicilioIds.numeroDomicilio, SageDomicilioIds.domicilio, SageDomicilioIds.municipio, SageDomicilioIds.codigoPostal, SageDomicilioIds.provincia, SageDomicilioIds.nacion],
    [],
    [],
    ['verCuentasSage'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SageDomicilioFld = Object.freeze({
    codigoEmpresa : new DbIntId(Tables.SageDomicilio, SageDomicilioIds.codigoEmpresa, 'Codigo empresa'),
    codigoCliente : new DbTextId(Tables.SageDomicilio, SageDomicilioIds.codigoCliente, 'Codigo cliente', 15),
    tipoDomicilio : new DbTextId(Tables.SageDomicilio, SageDomicilioIds.tipoDomicilio, 'Tipo domicilio', 1),
    numeroDomicilio : new DbIntId(Tables.SageDomicilio, SageDomicilioIds.numeroDomicilio, 'Numero domicilio'),
    domicilio : new DbText(Tables.SageDomicilio, SageDomicilioIds.domicilio, 'Domicilio', 40),
    municipio : new DbText(Tables.SageDomicilio, SageDomicilioIds.municipio, 'Municipio', 25),
    codigoPostal : new DbText(Tables.SageDomicilio, SageDomicilioIds.codigoPostal, 'Codigo postal', 8),
    provincia : new DbText(Tables.SageDomicilio, SageDomicilioIds.provincia, 'Provincia', 20),
    nacion : new DbText(Tables.SageDomicilio, SageDomicilioIds.nacion, 'Nacion', 25),
})

registerTableFields(Tables.SageDomicilio, SageDomicilioFld) 
