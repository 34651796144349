 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DireccionIds} from './Direccion'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {CuentaIds} from './Cuenta'
import {UserIds} from './User'

export const DireccionTable = new DbTable(
    Tables.Direccion,
    'Direcciones',
    graphqlUrl,
    'Direccion',
    [DireccionIds.idDireccion],
    [DireccionIds.idDireccion, DireccionIds.idCuenta, DireccionIds.cod, DireccionIds.idEmpresa, DireccionIds.direccion, DireccionIds.localidad, DireccionIds.provincia, DireccionIds.pais, DireccionIds.cp, DireccionIds.principal, DireccionIds.fechaAlta, DireccionIds.fechaModificacion, DireccionIds.idAutor, DireccionIds.idModificacion, DireccionIds.odooCambiado],
    [DireccionIds.cuenta, DireccionIds.autor, DireccionIds.modificacion],
    [DireccionIds.solicitudes, DireccionIds.contactos],
    ['verCuentas'],
    ['modificarCuentas'],
    undefined,
    undefined,
    [],
    [],
)


export const DireccionFld = Object.freeze({
    solicitudes : new DbObjectList(Tables.Direccion, DireccionIds.solicitudes, Tables.Solicitud),
    contactos : new DbObjectList(Tables.Direccion, DireccionIds.contactos, Tables.Contacto),
    cuenta : new DbObject(Tables.Direccion, DireccionIds.cuenta, DireccionIds.idCuenta, 'Cuenta'),
    autor : new DbObject(Tables.Direccion, DireccionIds.autor, DireccionIds.idAutor, 'Autor'),
    modificacion : new DbObject(Tables.Direccion, DireccionIds.modificacion, DireccionIds.idModificacion, 'Modificacion'),
    idDireccion : new DbIntId(Tables.Direccion, DireccionIds.idDireccion, 'Id direccion'),
    idCuenta : new DbObjectId(Tables.Direccion, DireccionIds.idCuenta, 'Id cuenta', {foreignTableId: Tables.Cuenta, foreignKeyFieldId: CuentaIds.idCuenta}),
    cod : new DbInt(Tables.Direccion, DireccionIds.cod, 'Cod'),
    idEmpresa : new DbInt(Tables.Direccion, DireccionIds.idEmpresa, 'Id empresa'),
    direccion : new DbText(Tables.Direccion, DireccionIds.direccion, 'Direccion', 100),
    localidad : new DbText(Tables.Direccion, DireccionIds.localidad, 'Localidad', 60),
    provincia : new DbText(Tables.Direccion, DireccionIds.provincia, 'Provincia', 50),
    pais : new DbText(Tables.Direccion, DireccionIds.pais, 'Pais', 50),
    cp : new DbText(Tables.Direccion, DireccionIds.cp, 'Cp', 10),
    principal : new DbInt(Tables.Direccion, DireccionIds.principal, 'Principal'),
    fechaAlta : new DbDateTime(Tables.Direccion, DireccionIds.fechaAlta, 'Fecha creación'),
    fechaModificacion : new DbDateTime(Tables.Direccion, DireccionIds.fechaModificacion, 'Fecha edición'),
    idAutor : new DbObjectId(Tables.Direccion, DireccionIds.idAutor, 'Creado', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idModificacion : new DbObjectId(Tables.Direccion, DireccionIds.idModificacion, 'Editado', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    odooCambiado : new DbInt(Tables.Direccion, DireccionIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.Direccion, DireccionFld) 
