import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {sopIcons} from 'sop/sop-icons'
import {ContactoFld, ContactoTable} from 'sop/db/ContactoTable'
import {Contacto} from 'sop/db/Contacto'
import {FormPiece} from 'sopix/pieces/formPiece'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {ContactoEdt} from 'sop/db/ContactoEditors'
import {DireccionFld} from 'sop/db/DireccionTable'
import {CuentaFld} from 'sop/db/CuentaTable'
import {DireccionEdt} from 'sop/db/DireccionEditors'
import {Direccion} from 'sop/db/Direccion'
import {CuentaEdt} from 'sop/db/CuentaEditors'


export class ContactoFormPiece extends FormPiece{
    constructor(world) {
        const FIELDS = [
            ...ContactoTable.regularFields,
            [ContactoFld.direccion, [
                DireccionFld.direccion,
                [DireccionFld.cuenta, [CuentaFld.cuenta]]
            ]]
        ]

        super(world,
            FIELDS,
            {
                name: 'Contacto',
                icon: sopIcons.Contacto,
                table: ContactoTable,
                idField: Contacto.idContacto,
                displayField: Contacto.nombre,
                saveMutation: 'SaveContacto',
                deleteMutation: 'DeleteContacto',
            },
        )
    }

}


export const ContactoForm = getPieceContainer('form', observer(
    /**
     * @param {ContactoFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField
        
        return (
            <Fields>
                {!fld(Contacto.direccion) ? null :
                    <>
                        <Field {...H.obj(Contacto.direccion, Direccion.cuenta, CuentaEdt.cuenta)} />
                        <Field {...H.obj(Contacto.direccion, DireccionEdt.direccion)} />
                    </>
                }
                <Field {...H.edt(ContactoEdt.nombre)} autoFocus />
                <Field {...H.edt(ContactoEdt.tel)} />
                <Field {...H.edt(ContactoEdt.correo)} />
                <Field {...H.edt(ContactoEdt.notas)} multiline rows={5} width={1000} />
            </Fields>
        )
}))
