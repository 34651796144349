import React from 'react'
import {ModalFrame} from 'sopix/modal/ModalFrame'
import {ModalContent} from 'sopix/modal/ModalContent'
import {observer} from 'mobx-react'
import {ErrorList} from 'sopix/errors/ErrorList'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {getLoggers} from 'sopix/log'

const {log} = getLoggers('PoperFrame')

export const PoperFrame = observer(
/**
 * @param {Poper} poper
 */
function({poper, children, extraButtons, ...modalFrameProps}) {

    const piece = poper.piece
    
    return (
        <ModalFrame
                title={poper.title}
                Icon={poper.icon}
                open={poper.isOpen}
                onClose={poper.__close}
                {...modalFrameProps}
        >
            <ErrorList errorManager={piece.errorManager} />
                <ModalContent>
                    {children}
                </ModalContent>
                {!poper.hasButtons ? null :
                    <DialogActions>
                        <Button
                            onClick={() => poper.__close({force: true})}
                            color="primary"
                            disabled={piece.inProgress}
                        >
                            Cancelar
                        </Button>
                        {!extraButtons ? null : extraButtons}
                        <Button
                            disabled={!poper.dirty || piece.inProgress}
                            onClick={poper.__acceptClicked}
                            color="primary"
                            defaultChecked={true}
                        >
                            {poper.acceptButton}
                        </Button>
                    </DialogActions>
                }
        </ModalFrame>
    )
})
