import React from 'react'
import Divider from '@material-ui/core/Divider'
import {DateTimeEditor, Editor, LookupEditor} from 'sopix/db/dbEditor'
import {fieldTypeToDataType} from 'sopix/form/form-types'
import {PickerType} from 'sopix/form/editors/DateField'
import {DbObject} from 'sopix/db/dbField'
import {boundMethod} from 'autobind-decorator'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {isString} from 'lodash-es'
import {getUrlFromRoute} from 'sopix/router/routerUtils'
import {canReadField, canWriteField, canWriteTable} from 'sopix/db-auth/db-auth-utils'


export class FormFieldHelper {

    autoNum = 0
    lowerCase
    dividerComp

    /**
     *
     * @param {FieldManager} fieldManager
     * @param {Object<ListData>} lists
     * @param {Object} options
     * @param options.lowerCase
     * @param options.dividerComp
     */
    constructor(fieldManager, lists = {}, {lowerCase =  false, dividerComp = Divider} = {}){
        this.fieldManager = fieldManager
        this.lists = lists
        this.lowerCase = lowerCase
        this.dividerComp = dividerComp
    }
    
    _getEditorLabel(editor){
        return this.lowerCase ? editor.title.toLowerCase() : editor.title
    }
    
    @boundMethod
    edt(/** Editor */ editor, {manager = true, listName, list, lookup=true
        , disableReadOnly=true} = {}){
        const field = editor.field
        const props = {
            name: field.fieldName,
            label: this._getEditorLabel(editor),
            width: editor.maxWidth,
            dataType: fieldTypeToDataType(field),
        }
        if (field instanceof DbObject){
            props.name = field.idField.fieldName
        }
        
        if (manager === true) {
            props.manager = this.fieldManager
        }
        
        if (editor instanceof LookupEditor && lookup) {
            props.idField = editor.foreignKeyField.fieldName
            props.displayField = editor.foreignEditor.field.fieldName
            
            const lstName = listName !== undefined ? listName : editor.foreignTable.graphqlId
            const lst = list !== undefined ? list : this.lists[lstName] 
            if (lst !== undefined) {
                props.options = lst.rows
                props.idField = lst.idField
                props.displayField = lst.displayField
            }
        }
        
        if (editor instanceof DateTimeEditor) {
            props.pickerType = PickerType.DATE_TIME
        }
        
        if (disableReadOnly && (!canWriteTable(field.table) || !canWriteField(field.physicalField))) {
            const canTable = canWriteTable
            const canField = canWriteField
            props['disabled'] = true
        }

        if (!canReadField(field.physicalField)) {
            props['nullify'] = true
        }
        
        return props
    }
    
    // Los primeros nombres, el último editor
    obj(...fieldNamesOrEditor){
        let firstEditor
        let lastEditor
        let dataPath = []
        for (let fld of fieldNamesOrEditor) {
            if (isString(fld)){
                dataPath.push(fld)
            } else if (fld instanceof Editor){
                dataPath.push(fld.field.fieldName)
                if (!firstEditor) {
                    firstEditor = fld
                }
                lastEditor = fld
            } else {
                throw new Error('FormFieldHelper requires field names or editors')
            }
        }

        const fld = getFieldExtractor(this.fieldManager.fields)
        
        const dbField = lastEditor.field

        const result = {
            value: fld(...dataPath) || '-',
            disabled: true,
            label: this._getEditorLabel(firstEditor),
            width: lastEditor.maxWidth,
            dataType: fieldTypeToDataType(dbField),
        }

        if (!canReadField(dbField.physicalField)) {
            result['nullify'] = true
        }

        return result
    }

    /**
     *
     * @param {LookupEditor} lookupEditor
     * @param url
     * @param icon
     * @returns {{startAdornment: JSX.Element}}
     */
    btn(lookupEditor, url, icon){
        if (! lookupEditor instanceof LookupEditor) {
            throw new Error(`No es lookupEditor: ${JSON.stringify(lookupEditor)}`)
        }
        
        const fld = getFieldExtractor(this.fieldManager.fields)
        
        const idField = lookupEditor.foreignKeyField
        
        const id = fld(lookupEditor.field.fieldName, idField.fieldName)
        if (!id) {
            throw new Error(`btn: ${idField.table.graphqlId}.${idField.fieldName} no definido`)
        }
        
        const display = fld(lookupEditor.foreignDisplayField.fieldName)
        
        const button = <HintIconButton Icon={icon} title={display} fit
                                       href={getUrlFromRoute(url, {[idField.fieldName]: id})} />
        
        return {
            startAdornment: button,
        }
    }
    
    
    @boundMethod
    list(listName){
        const lst = this.lists[listName]
        if (lst === undefined) {
            throw new Error('Unknown list')
        }
        return {
            options: lst.rows,
            idField: lst.idField,
            displayField: lst.displayField,
        }
    }
}