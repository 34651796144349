import {boundMethod} from 'autobind-decorator'
import {map} from 'lodash-es'
import {getLoggers} from 'sopix/log'
import {ObserverHub} from 'sopix/utils/observers'
import {observable, runInAction} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'

const {log, debug} = getLoggers('FieldManager')

export class FieldManager{
    
    @observable
    _readOnly = new Set()
    
    fields = {}
    
    _initialFields = {}
    
    errors = {}
    
    /** @type {Object<String, FieldControllerState>} */
    _fieldControllers = {}
    
    _changedBeforeBlur = false
    _loading = false

    onLoadObservers = new ObserverHub()
    onChangeObservers = new ObserverHub()
    onBlurObservers = new ObserverHub()
    onDirtyObservers = new ObserverHub()
    
    _dirty = false
    
    constructor({deleteUndefinedFields = false, defaultFields = {}, onChange, onDirtyChange} = {}){
        this.deleteUndefinedFields = deleteUndefinedFields
        
        if (onChange) this.onChangeObservers.subscribe(onChange)
        if (onDirtyChange) this.onDirtyObservers.subscribe(onDirtyChange)
        
        runInAction(() =>{
            this._initialFields = {...defaultFields} 
            this.fields = {...defaultFields}
        })
    }
    
    get readOnly(){
        return !!this._readOnly.size
    }
    
    setReadOnly(readOnly, optionOwner){
        if(readOnly){
            if (this._readOnly.has(optionOwner)) {
                return
            }
            runInAction(() => {
                this._readOnly.add(optionOwner)
            })
        } else {
            if (!this._readOnly.has(optionOwner)) {
                return
            }
            runInAction(() => {
                this._readOnly.delete(optionOwner)
            })
        }
    }
    
    get initialFields(){
        return this._initialFields
    }
    
    _onChange(fieldName, value){
        if (this._loading) return
        
        log(()=>`onChange: ${fieldName} ${value}`)
        this.onChangeObservers.notify({
            fieldName: fieldName,
            value: value,
            fields: this.fields,
        })
    }

    _onBlur(fieldName, changed){
        if (this._loading) return

        log(()=>`onBlur: ${fieldName}`)
        this.onBlurObservers.notify({
            fieldName: fieldName,
            value: this.fields[fieldName],
            fields: this.fields,
            changed: changed,
        })
    }
    
    __setField(fieldName, value){
        if (this.fields[fieldName] !== value) {
            if (value === undefined && this.deleteUndefinedFields) {
                delete this.fields[fieldName]
            } else {
                this.fields[fieldName] = value
            }
            if (this.errors[fieldName] !== undefined) {
                this.setError(fieldName, undefined)
            }
            return true
        }
        return false
    }
    
    _setField(fieldName, value){
        if (this.__setField(fieldName, value)) {
            this._changedBeforeBlur = true
            this._onChange(fieldName, value)
            this._refreshDirty()
        }
    }
    
    _onControlFieldChange(fieldName, value){
        this._setField(fieldName, value)
        this._renderField(fieldName)
    }
    
    _onControlFieldBlur(fieldName){
        this._onBlur(fieldName, this._changedBeforeBlur)
        this._changedBeforeBlur = false
    }
    
    _renderField(fieldName){
        const controller = this._fieldControllers[fieldName]
        controller && controller.doRender()
    }
    
    _registerControl(fieldName, controller){
        debug(()=>`register ${fieldName}`)
        this._fieldControllers[fieldName] = controller
    }
    
    _unregisterControl(fieldName, controller){
        if (this._fieldControllers[fieldName] !== controller) return
        debug(()=>`unregister ${fieldName}`)
        delete this._fieldControllers[fieldName]
    }

    @boundMethod
    setValue(fieldName, value){
        log(()=>`setValue: ${fieldName} ${value}`)
        delete this.errors[fieldName]
        this._setField(fieldName, value)
        this._renderField(fieldName)
    }
    
    @boundMethod
    setValues(fields){
        let changed = false
        this._loading = true
        try{
            for (let [fieldName, value] of Object.entries(fields)){
                if (this.__setField(fieldName, value)){
                    this._renderField(fieldName)
                    changed = true
                }
            }
        }finally {
            this._loading = false
        }
        
        if (changed) {
            this._onChange()
            this._refreshDirty()
        }
    }
    
    @boundMethod
    load(fields, {render = true} = {}) {
        this._loading = true
        try{
            const oldErrors = this.errors
            const oldFields = this.fields
            this.errors = []
            this._initialFields = {...fields}
            this.fields = {...fields}
            map(this._fieldControllers, (_, fieldName) => {
                if (oldFields[fieldName] !== fields[fieldName] || oldErrors[fieldName] !== undefined) {
                    if (render) {
                        this._renderField(fieldName)
                    }
                }
            })
        }finally {
            this._loading = false    
        }
        this._onChange()
        this._refreshDirty()
        this.onLoadObservers.notify(this.fields)
    }
    
    @boundMethod
    undirty(fields = undefined){
        const flds = !fields ? this.fields : fields
        this._initialFields = {...flds}
        this._refreshDirty()
    }
    
    @boundMethod
    setErrors(errors){
        const oldErrors = this.errors
        this.errors = errors
        map(this._fieldControllers, (_, fieldName) => {
            if (oldErrors[fieldName] !== this.errors[fieldName]) {
                this._renderField(fieldName)
            }
        })
    }
    
    @boundMethod
    setError(fieldName, error){
        const oldError = this.errors[fieldName]
        if (error === undefined) {
            delete this.errors[fieldName]
        } else {
            this.errors[fieldName] = error
        }
        if (oldError !== error) {
            this._renderField(fieldName)
        }
    }
    
    @boundMethod
    revert() {
        this.load(this.initialFields)
    }

    _refreshDirty() {
        const dirty = JSON.stringify(this.fields) !== JSON.stringify(this.initialFields)
        if (this._dirty !== dirty) {
            this._dirty = dirty
            this.onDirtyObservers.notify(dirty)
        }
    }
    
    @boundMethod
    getValue(...fieldPath){
        const fld = getFieldExtractor(this.fields)
        return fld(...fieldPath)
    }

}