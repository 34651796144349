 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageDomicilioIds} from './SageDomicilio'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const SageDomicilioCol = Object.freeze({
    codigoEmpresa : new SimpleColumn(SageDomicilioIds.codigoEmpresa, 11, 11, Align.RIGHT),
    codigoCliente : new SimpleColumn(SageDomicilioIds.codigoCliente, 15, 10, Align.LEFT),
    tipoDomicilio : new SimpleColumn(SageDomicilioIds.tipoDomicilio, 1, 1, Align.LEFT),
    numeroDomicilio : new SimpleColumn(SageDomicilioIds.numeroDomicilio, 11, 11, Align.RIGHT),
    domicilio : new SimpleColumn(SageDomicilioIds.domicilio, 40, 16, Align.LEFT),
    municipio : new SimpleColumn(SageDomicilioIds.municipio, 25, 10, Align.LEFT),
    codigoPostal : new SimpleColumn(SageDomicilioIds.codigoPostal, 8, 8, Align.LEFT),
    provincia : new SimpleColumn(SageDomicilioIds.provincia, 20, 10, Align.LEFT),
    nacion : new SimpleColumn(SageDomicilioIds.nacion, 25, 10, Align.LEFT),
})

tableColumns[Tables.SageDomicilio] = SageDomicilioCol
