 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ProvinciaIds} from './Provincia'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbText} from 'sopix/db/dbField'

export const ProvinciaTable = new DbTable(
    Tables.Provincia,
    'Provincias',
    graphqlUrl,
    'Provincia',
    [ProvinciaIds.idProvincia],
    [ProvinciaIds.idProvincia, ProvinciaIds.cp, ProvinciaIds.provincia],
    [],
    [],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const ProvinciaFld = Object.freeze({
    idProvincia : new DbIntId(Tables.Provincia, ProvinciaIds.idProvincia, 'Id provincia'),
    cp : new DbText(Tables.Provincia, ProvinciaIds.cp, 'Cp', 2),
    provincia : new DbText(Tables.Provincia, ProvinciaIds.provincia, 'Provincia', 30),
})

registerTableFields(Tables.Provincia, ProvinciaFld) 
