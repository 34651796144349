 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SolicitudTareaIds} from './SolicitudTarea'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const SolicitudTareaEdt = Object.freeze({
    idSolicitud : new SimpleEditor(SolicitudTareaIds.idSolicitud, 11, 11),
    idTarea : new SimpleEditor(SolicitudTareaIds.idTarea, 11, 11),
})

tableEditors[Tables.SolicitudTarea] = SolicitudTareaEdt
