 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const TipoTareaIds = Object.freeze({
    tareas: Symbol('tareas'),
    idTipo: Symbol('idTipo'),
    tipo: Symbol('tipo'),
    ratio: Symbol('ratio'),
    m2: Symbol('m2'),
    detalle: Symbol('detalle'),
    clase: Symbol('clase'),
    orden: Symbol('orden'),
    archivado: Symbol('archivado'),
    ratioOld: Symbol('ratioOld'),
    planificador: Symbol('planificador'),
})

export const TipoTarea = Object.freeze({
    tareas: 'tareas',
    idTipo: 'idTipo',
    tipo: 'tipo',
    ratio: 'ratio',
    m2: 'm2',
    detalle: 'detalle',
    clase: 'clase',
    orden: 'orden',
    archivado: 'archivado',
    ratioOld: 'ratioOld',
    planificador: 'planificador',
})
