import {PersistentSplitPane} from 'sopix/layout/SplitPanePersistent'
import React from 'react'

export function VerticalSplit({id, minSize, defaultSizes, children}) {
    return (
        <PersistentSplitPane id={id} split="vertical" minSize={minSize}  defaultSizes={defaultSizes}> 
            {children}
        </PersistentSplitPane>
    )
}
