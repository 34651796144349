import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReplayIcon from '@material-ui/icons/Replay'
import {IconButton} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import {InitState} from 'sopix/utils/InitState'

const useStyles = makeStyles(theme => ({
    opaqueProgress: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
    },
}))

/**
 * 
 * @param {InitState} initialized
 * @param onReplay
 * @returns {JSX.Element}
 * @constructor
 */
export const InitProgress = ({initialized, onReplay}) => {
    const classes = useStyles()
    
    let ProgressElement
    switch (initialized){
        case InitState.NO:
            ProgressElement = <CircularProgress />
            break
        case InitState.ERROR:
            if (onReplay) ProgressElement = <IconButton onClick={onReplay}><ReplayIcon/></IconButton>
            else ProgressElement = <ErrorIcon />
            break
        default:
            return null
    }

    return (
        <div className={classes.opaqueProgress}>
            {ProgressElement}
        </div>
    )
}