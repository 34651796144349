import {DbField, DbFloat, DbInt} from 'sopix/db/dbField'
import Moment from 'moment'
import {DateColumn, DateTimeColumn, DbColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'
import isPlainObject from 'lodash-es/isPlainObject'
import {isArray} from 'lodash-es'

/**
 * @param {DbColumn} column
 * @returns {function(value: Any): String}
 */
export function getColumnTextFormatter(column) {
    const mom = Moment

    if (column instanceof DateColumn) {
        return value => value ? mom(value).format('DD/MM/YYYY') : ''

    } else if (column instanceof DateTimeColumn) {
        return value => value ? Moment(value).format('DD/MM/YYYY HH:mm:ss') : ''

    } else if (column instanceof LookupColumn) {
        if (!column.foreignColumn) {
            throw new Error(`Missing foreign field in ${column.field.fieldName}`)
        }
        const fieldName = column.foreignColumn.field.fieldName
        return value => {
            return !isPlainObject(value) ? '' : value[fieldName]
        }

    } else if (column instanceof SimpleColumn && (column.field instanceof DbInt || column.field instanceof DbFloat)) {
        return value => value === undefined ? '' : value.toLocaleString('de-DE')

    } else {
        return value => value
    }
}

/**
 * @param {DbColumn} column
 * @returns {function(value: Any): String}
 */
export function getColumnSortFormatter(column) {
    if (column instanceof LookupColumn) {
        const fieldName = column.foreignColumn.field.fieldName
        return value => {
            return !isPlainObject(value) ? '' : value[fieldName]
        }

    } else if (column instanceof SimpleColumn && (column.field instanceof DbInt || column.field instanceof DbFloat)) {
        return value => value === undefined ? '' : value.toLocaleString('de-DE')

    } else {
        return value => value
    }
}


/**
 * @param {Map<String, DbColumn>} dbColumnMap
 * @returns {{}}
 */
export function getDbColFormatters(dbColumnMap){
    const formatters = {}
    for (let [key, column] of dbColumnMap){
        formatters[key] = getColumnTextFormatter(column)
    }
    return formatters
}

export function getColumnTextFormatterList(columnDescriptors) {
    const dbColumnMap = decodeColumnDescriptors(columnDescriptors)
    return getDbColFormatters(dbColumnMap)
}




/**
 * @returns {Map<String, DbColumn>}
 */
export function decodeColumnDescriptors(columnDescriptors) {
    const result = new Map()
    for (let col of columnDescriptors) {
        let left, id, dbColumn

        if (col instanceof DbColumn) {
            id = col.field.fieldName
            dbColumn = col

        } else {
            if (isArray(col)) {
                left = col[0]
                dbColumn = col[1]
            } else {
                left = col
                dbColumn = undefined
            }
            if (left instanceof DbField) {
                id = left.fieldName
            } else {
                id = left
            }
        }
        result.set(id, dbColumn)
    }
    return result
}
