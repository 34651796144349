import React, {useState} from 'react'
import {OkCancelAlert} from 'sopix/alert/OkCancelAlert'

/**
 * 
 * @returns {(JSX.Element|(function(*, *=, *=, *=, *=): undefined))[]}
 */
export function useAlert(){
    const [alertArgs, setAlertArgs] = useState({open: false})
    
    function withAlert(func, title, acceptLabel, text, showAlert = true){

        if (!showAlert) {
            func()
            return
        }
        
        function hide(){
            setAlertArgs({open: false})
        }

        function handleAccept(){
            func()
            hide()
        }
        
        setAlertArgs({open: true, title: title, text: text, acceptLabel: acceptLabel, onAccept: handleAccept, onCancel: hide})
    }
    
    const alert = <OkCancelAlert {...alertArgs} />
    
    return [alert, withAlert]
}