import React from 'react'
import {observer} from 'mobx-react'
import {Frame} from 'sopix/formComps/Frame'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {Push} from 'sopix/formComps/Push'
import {ErrorList} from 'sopix/errors/ErrorList'
import {UsuarioForm, UsuarioFormPiece} from 'sop/componentes/usuario/UsuarioForm'
import {FormControl} from 'sopix/piece-components/FormControl'
import {sopPages} from 'sop/sop-pages'
import {userAuth} from 'sopix/session/userAuth'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {getAuthSingleton} from 'sopix/session/auth-singletons'

export class YoPagePiece extends PagePiece{

    constructor() {
        super()
        this.form = new UsuarioFormPiece(this.world)
    }

    async _init() {
        await this.form.load(userAuth.userId)
        await this.validate()
    }

}


export const getYoPage = getAuthSingleton(sopPages.yo.id, () => {
    return new YoPagePiece(sopPages.usuarios)
})


export const YoPage = observer(
    /**
     * 
     * @param {YoPagePiece} page
     * @returns {JSX.Element}
     */
    ({page}) => {

        usePiece(page)
        
        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame piece={page} renderPiece={()=> {
                    return (
                        <VerticalSplit id="YoPage" defaultSizes={[30,70]}>
                            <Frame>
                                <Bar startGap>
                                    <Title>{page.form.recordName}</Title>
                                    <Push />
                                    <FormControl form={page.form} />
                                </Bar>
                                <UsuarioForm form={page.form} />
                            </Frame>
                            <Frame />
                        </VerticalSplit>
                    )
                }} />
            </>
        )
    })