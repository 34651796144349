import React, {forwardRef} from 'react'
import IconButton from '@material-ui/core/IconButton'

import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {Link} from 'sopix/router/link'

const useStyles = makeStyles(theme => ({
    withBadge: {
        marginTop: 2,
        marginRight: 2,
    },
})) 

export const ICON_BUTTON_SIZE_FIT = 42
export const ICON_BUTTON_SIZE = 60
export const ICON_SIZE = 42

export const HintIconButton = forwardRef(
    ({className, title, Icon, disabled, edge, onClick, badge, badgeColor, badgeVariant, fit, mini, large, color, 
         href, ...buttonProps}, ref) => {

    const cls = useStyles()
    
    function handleOnClick(e){
        e.stopPropagation()
        onClick && onClick(e)
    }
    
    return (
        <IconButton
            ref={ref}
            component={!href ? undefined : Link}
            {...(!href ? {} : {href: href})}
            className={clsx(
                    badge !== undefined && cls.withBadge,
                    className,
            )}
            size={fit ? 'small' : undefined} disabled={disabled} edge={edge} onClick={handleOnClick} 
            {...buttonProps}
        >
            <HintIcon Icon={Icon} badge={badge} badgeColor={badgeColor} badgeVariant={badgeVariant} color={color} 
                      mini={mini} large={large} title={title} />
        </IconButton>
    )
})
