 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {CuentaIds} from './Cuenta'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {CuentaTipoIds} from './CuentaTipo'
import {UserIds} from './User'
import {AlmacenIds} from './Almacen'

export const CuentaEdt = Object.freeze({
    tipo : new LookupEditor(CuentaIds.tipo, Tables.CuentaTipo, CuentaTipoIds.idTipo, 'tipo', {sort: 'ID_TIPO_ASC'}),
    autor : new LookupEditor(CuentaIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    modificacion : new LookupEditor(CuentaIds.modificacion, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    almacen : new LookupEditor(CuentaIds.almacen, Tables.Almacen, AlmacenIds.idAlmacen, 'almacen', {sort: 'ID_ALMACEN_ASC'}),
    comercial : new LookupEditor(CuentaIds.comercial, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idCuenta : new SimpleEditor(CuentaIds.idCuenta, 11, 11),
    cifdni : new SimpleEditor(CuentaIds.cifdni, 15, 10),
    cuenta : new SimpleEditor(CuentaIds.cuenta, 40, 15),
    telefono1 : new SimpleEditor(CuentaIds.telefono1, 40, 16),
    comentarios : new SimpleEditor(CuentaIds.comentarios, 40, 16),
    fechaAlta : new DateTimeEditor(CuentaIds.fechaAlta, 16, 16),
    fechaAltaOnlyDate : new DateEditor(CuentaIds.fechaAlta, 11, 11),
    fechaModificacion : new DateTimeEditor(CuentaIds.fechaModificacion, 16, 16),
    fechaModificacionOnlyDate : new DateEditor(CuentaIds.fechaModificacion, 11, 11),
    odooCambiado : new SimpleEditor(CuentaIds.odooCambiado, 11, 11),
})

tableEditors[Tables.Cuenta] = CuentaEdt
