 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {MesIds} from './Mes'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const MesTable = new DbTable(
    Tables.Mes,
    'Meses',
    graphqlUrl,
    'Mes',
    [MesIds.idMes],
    [MesIds.idMes, MesIds.mes, MesIds.mesCorto],
    [],
    [MesIds.anualMeses],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const MesFld = Object.freeze({
    anualMeses : new DbObjectList(Tables.Mes, MesIds.anualMeses, Tables.AnualMes),
    idMes : new DbIntId(Tables.Mes, MesIds.idMes, 'Id mes'),
    mes : new DbText(Tables.Mes, MesIds.mes, 'Mes', 10),
    mesCorto : new DbText(Tables.Mes, MesIds.mesCorto, 'Mes corto', 3),
})

registerTableFields(Tables.Mes, MesFld) 
