import {ListQueryEntry} from 'sopix/db-access/listFetcher'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {User} from 'sop/db/User'
import {OrderDirection} from 'sopix/data/orderEntry'
import {UserFld} from 'sop/db/UserTable'
import {TipoTarea} from 'sop/db/TipoTarea'
import {TipoTareaFld} from 'sop/db/TipoTareaTable'
import {TareaEstado} from 'sop/db/TareaEstado'
import {TareaEstadoFld} from 'sop/db/TareaEstadoTable'
import {isEmpty} from 'lodash-es'
import {Provincia} from 'sop/db/Provincia'
import {ProvinciaFld} from 'sop/db/ProvinciaTable'

/**
 * @param {RolesUsuario} roleUsuario
 * @param {boolean} includeInactivos
 */
export function getUsuariosListQueryEntry(roleUsuario,
                                          {includeInactivos, listKey} = {}){
    
    const filterRole = !roleUsuario ? {} : {
        [User.roleUsuario]: roleUsuario,
    }

    const filterInactivos = includeInactivos ? {} : {
        [User.inactivo]: 0,
    }
    
    const filter = {...filterRole, ...filterInactivos}

    return new ListQueryEntry(
        {sort: calcGraphqlOrder(User.nombreCompleto, OrderDirection.ASC)},
        [UserFld.id, UserFld.nombreCompleto, UserFld.username, UserFld.inactivo],
        isEmpty(filter) ? undefined : {filters: filter, listKey},
    )    
}

export const tareaTipoQuery = new ListQueryEntry(
    {sort: calcGraphqlOrder(TipoTarea.orden)},
    [TipoTareaFld.idTipo, TipoTareaFld.tipo, TipoTareaFld.clase],
    {filters: {archivadoIsNull: true}}
)

export const tareaEstadoQuery = new ListQueryEntry(
    {sort: calcGraphqlOrder(TareaEstado.idEstado)},
    [TareaEstadoFld.idEstado, TareaEstadoFld.estado, TareaEstadoFld.finalizado],
)

export const provinciaQuery = new ListQueryEntry(
    {sort: calcGraphqlOrder(Provincia.provincia)},
    [ProvinciaFld.cp, ProvinciaFld.provincia],
)
