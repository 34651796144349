import React from 'react'
import {observer} from 'mobx-react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {Bar} from 'sopix/formComps/Bar'
import {ErrorList} from 'sopix/errors/ErrorList'
import {once} from 'lodash-es'
import {sopPages} from 'sop/sop-pages'
import {Piece} from 'sopix/piece/piece'
import {PieceWorld} from 'sopix/piece/pieceWorld'
import {ErrorStore} from 'sopix/errors/ErrorStore'
import {observable} from 'mobx'
import {ChartTab} from 'sop/componentes/stats/ChartTab'
import {boundMethod} from 'autobind-decorator'
import {Separator} from 'sopix/formComps/Separator'
import {ChartIds, chartPieces} from 'sop/componentes/stats/chart-consts'
import {getAuthSingleton} from 'sopix/session/auth-singletons'

export class StatsPagePiece extends Piece{

    @observable
    id
    
    /** @type {ChartPiece} */
    chart
    
    constructor(pageInfo) {
        const world = new PieceWorld(new ErrorStore())
        super(world)
    }

    @boundMethod
    /**
     * @param {ChartIds} id
     */
    async setId(id){
        if (id === this.id) return
        
        const chartInfo = chartPieces.get(id)
        
        this.chart = !chartInfo ? undefined : 
            new chartInfo.chartClass(this.world)
        
        this.id = id
    }
    __setId = this._asyncAction(this.setId)
    
    async _init() {
        await this.setId(ChartIds.TECNICO_MES)
        await super._init()
    }
}


export const getStatsPage = getAuthSingleton(sopPages.stats.id, () => {
    return new StatsPagePiece(sopPages.stats)
})






export const StatsPage = observer(
    /**
     * @param {StatsPagePiece} page
     */
    ({page}) => {

        usePiece(page)

        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame piece={page} renderPiece={()=> {
                    return (
                        <>
                            <Bar>
                                <ChartTab page={page} />
                            </Bar>
                            <Bar>
                                {page.chart && page.chart.Search}
                            </Bar>
                            {page.chart && page.chart.Frame}
                        </>
                    )

                }} />
            </>
        )
})