 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SagePedidoIds} from './SagePedido'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbIntId, DbText, DbTextId} from 'sopix/db/dbField'

export const SagePedidoTable = new DbTable(
    Tables.SagePedido,
    'CabeceraPedidoCliente',
    graphqlUrl,
    'SagePedido',
    [SagePedidoIds.numeroPedido],
    [SagePedidoIds.codigoEmpresa, SagePedidoIds.ejercicioPedido, SagePedidoIds.seriePedido, SagePedidoIds.numeroPedido, SagePedidoIds.codigoCliente, SagePedidoIds.suPedido, SagePedidoIds.razonSocial, SagePedidoIds.fechaPedido, SagePedidoIds.referencia],
    [],
    [],
    ['verPedidosSage'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SagePedidoFld = Object.freeze({
    codigoEmpresa : new DbIntId(Tables.SagePedido, SagePedidoIds.codigoEmpresa, 'Codigo empresa'),
    ejercicioPedido : new DbIntId(Tables.SagePedido, SagePedidoIds.ejercicioPedido, 'Ejercicio pedido'),
    seriePedido : new DbTextId(Tables.SagePedido, SagePedidoIds.seriePedido, 'Serie pedido', 10),
    numeroPedido : new DbIntId(Tables.SagePedido, SagePedidoIds.numeroPedido, 'Nº pedido'),
    codigoCliente : new DbText(Tables.SagePedido, SagePedidoIds.codigoCliente, 'Codigo cliente', 15),
    suPedido : new DbText(Tables.SagePedido, SagePedidoIds.suPedido, 'Nº pedido cliente', 20),
    razonSocial : new DbText(Tables.SagePedido, SagePedidoIds.razonSocial, 'Cuenta', 40),
    fechaPedido : new DbDateTime(Tables.SagePedido, SagePedidoIds.fechaPedido, 'Fecha'),
    referencia : new DbText(Tables.SagePedido, SagePedidoIds.referencia, 'Referencia', 15),
})

registerTableFields(Tables.SagePedido, SagePedidoFld) 
