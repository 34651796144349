 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AproplanoIds = Object.freeze({
    documentos: Symbol('documentos'),
    aprobacion: Symbol('aprobacion'),
    documento: Symbol('documento'),
    idAproplano: Symbol('idAproplano'),
    idAprobacion: Symbol('idAprobacion'),
    justificacion: Symbol('justificacion'),
    idDocumento: Symbol('idDocumento'),
})

export const Aproplano = Object.freeze({
    documentos: 'documentos',
    aprobacion: 'aprobacion',
    documento: 'documento',
    idAproplano: 'idAproplano',
    idAprobacion: 'idAprobacion',
    justificacion: 'justificacion',
    idDocumento: 'idDocumento',
})
