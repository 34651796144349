import React from 'react'
import {observer} from 'mobx-react'
import {Frame} from 'sopix/formComps/Frame'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {ObraSearch} from 'sop/componentes/obra/ObraSearch'
import {ObraList} from 'sop/componentes/obra/ObraList'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {Push} from 'sopix/formComps/Push'
import {icons} from 'sopix/icon/icons'
import {ErrorList} from 'sopix/errors/ErrorList'
import {ObraForm} from 'sop/componentes/obra/ObraForm'
import {FormControl} from 'sopix/piece-components/FormControl'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {HorizontalSplit} from 'sopix/layout/HorizontalSplit'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {NotaList} from 'sop/componentes/obra-nota/NotaList'
import {SolicitanteList} from 'sop/componentes/obra-solicitante-S/SolicitanteList'
import {ObraTab} from 'sop/componentes/obra/ObraTab'
import {ObraPageMode} from 'sop/componentes/obra/obraPagePiece'
import {ObraTrabajoList} from 'sop/componentes/obra-trabajo/ObraTrabajoList'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'


const useStyles = makeStyles(() => ({
    ejecutadaBien: {
        background: sopColors.greenBackground,
    },
    ejecutadaMal: {
        background: sopColors.redBackground,
    }
}))


export const ObraPage = observer(
    /**
     * 
     * @param {ObraPagePiece} page
     * @returns {JSX.Element}
     */
    ({page}) => {

        usePiece(page)

        const cls = useStyles()

        const ejecutada = page.form.ejecutada
        
        const asa = page._asyncAction

        return (<>
            <ErrorList errorManager={page.errorManager} />
            <PieceFrame piece={page} renderPiece={()=> {
                return page.mode === ObraPageMode.LIST
                ? (
                    <>
                        <Bar alignEnd >
                            <ObraSearch search={page.search} extra={<>
                                {!userAuth.isAllowed(Permiso.crearObras) ? null :
                                    <HintIconButton Icon={sopIcons.Obra} badge='+' badgeColor={sopColors.badgeAdd}
                                                    large title="Crear nueva obra"
                                                    onClick={page.__createRow}
                                    />
                                }
                                <HintIconButton Icon={icons.Reload}
                                large title="Actualizar" onClick={page.__refresh}
                                />
                            </>} />
                        </Bar>
                        <ObraList list={page.list} />
                    </>
                )
                : (<>
                    <VerticalSplit id="ObraPage" defaultSizes={[30,55,15]}>
                        <HorizontalSplit id="ObraPageLeft" defaultSizes={[60,40]} >
                                <Frame>
                                    <Bar className={clsx(
                                        ejecutada === true && cls.ejecutadaBien,
                                        ejecutada === false && cls.ejecutadaMal,
                                    )} >
                                        <HintIconButton Icon={icons.Back} title="Volver a la lista"
                                                        onClick={()=>{page.__setMode(ObraPageMode.LIST)}}
                                                        disabled={page.form.dirty}
                                        />
                                        <ListNavigator list={page.list} /><Separator rightGap />
                                        <Title>{page.form.recordName}</Title>
                                        <Push />
                                        <FormControl form={page.form} onRefresh={asa(page.refreshButtonClicked)} />
                                    </Bar>
                                    <ObraForm form={page.form} />
                                </Frame>
                            <SolicitanteList solicitanteList={page.solicitanteList}/>
                        </HorizontalSplit>
                        <HorizontalSplit id="ObraPageCenter" defaultSizes={[40,60]} >
                            <ObraTab page={page} />
                            <ObraTrabajoList list={page.trabajos} />
                        </HorizontalSplit>
                        <NotaList list={page.notas} />
                    </VerticalSplit>
                </>)

            }} />
        </>)
    })