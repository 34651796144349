import {Piece} from 'sopix/piece/piece'
import {PieceWorld} from 'sopix/piece/pieceWorld'
import {ErrorStore} from 'sopix/errors/ErrorStore'
import {boundMethod} from 'autobind-decorator'


export class PagePiece extends Piece{

    /** @type {AppPageInfo} */
    pageInfo
    
    constructor(pageInfo) {
        const world = new PieceWorld(new ErrorStore())
        super(world)
        this.pageInfo = pageInfo
        
    }

    @boundMethod
    async refresh(){
        await Promise.all([
            this.list.filtrator.refresh(),
            this.form.refresh(),
        ])
    }
    __refresh = this._asyncAction(this.refresh)
    
}