 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ConfiguracionIds} from './Configuracion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const ConfiguracionEdt = Object.freeze({
    idConfig : new SimpleEditor(ConfiguracionIds.idConfig, 11, 11),
    ratioRepeticion : new SimpleEditor(ConfiguracionIds.ratioRepeticion, 11, 11),
    ratioSimetria : new SimpleEditor(ConfiguracionIds.ratioSimetria, 11, 11),
    asumirEstudioPropio : new SimpleEditor(ConfiguracionIds.asumirEstudioPropio, 11, 11),
})

tableEditors[Tables.Configuracion] = ConfiguracionEdt
