import {observer} from 'mobx-react'
import {TrabajoRow} from 'sop/componentes/trabajo/TrabajoRow'
import React from 'react'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {Tarea} from 'sop/db/Tarea'

export const RepartirList = observer(
    /**
     * @param {RepartirPartPiece} part
     */
    ({part}) => {
        
        const sya = part._action
        
        return (
            <BlTable
                verticalAlign="top"
                Row = {TrabajoRow}
                keyColumnId={Tarea.idTarea}
                columns={part.valPainter.boundlessColumns}
                renderCellContent={sya(part.valPainter.boundlessPainter)}
                data={part.dbVals.data}
            />
        )
    })
