 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PaisIds} from './Pais'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const PaisEdt = Object.freeze({
    idPais : new SimpleEditor(PaisIds.idPais, 11, 11),
    iso : new SimpleEditor(PaisIds.iso, 2, 2),
    nombre : new SimpleEditor(PaisIds.nombre, 40, 16),
})

tableEditors[Tables.Pais] = PaisEdt
