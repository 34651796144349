import {dropRight, isArray, isPlainObject, isString, map, mapValues} from 'lodash-es'
import {DbBool, DbField, DbIntId, DbObject, DbObjectList, fields} from 'sopix/db/dbField'
import {DbColumn, LookupColumn} from 'sopix/db/dbColumn'
import {snakeCaseFromCamelCase} from 'sopix/string/string-case'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {decodeFieldDescriptors} from 'sopix/db/db-utils'
import {graphqlCanIncludeField, graphqlCanIncludeTable} from 'sopix/db/graphqlMiddleware'

const DEFAULT_OPTIONS = {arrayBrackets: "[]", dictBrackets: "{}", stringify: true, dictPairSeparator: ':'}

export const arrayToGraphQLText = (data, options = {}) => {
    return map(data, value => `${toGrapQLText(value, options)}`).join(' ')
}

export const dictToGraphQLText = (data, options = {}) => {
    const opts = {...DEFAULT_OPTIONS, ...options}
    return map(data, (value, key) => `${key}${opts.dictPairSeparator}${toGrapQLText(value, options)}`).join(' ')
}

export const toGrapQLText = (data, options = {}) => {
    const opts = {...DEFAULT_OPTIONS, ...options}
    if (isArray(data)) {
        const items = arrayToGraphQLText(data, options)
        return `${opts.arrayBrackets.charAt(0)}${items}${opts.arrayBrackets.charAt(1)}`
    } else if (isPlainObject(data)) {
        const items = dictToGraphQLText(data, options)
        return `${opts.dictBrackets.charAt(0)}${items}${opts.dictBrackets.charAt(1)}`
    } else {
        if (opts.stringify) {
            return JSON.stringify(data)
        } else {
            return String(data)
        }
    }
}

export const getGraphqlError = (error, fullStack = false) => {
    if (error.response && error.response.status === 400) {
        return error.response.data.errors[0].message
    } else {
        return fullStack ? error.stack : error.message
    }
}



function getFieldEntryDetails(entry){
    let field
    let fieldName
    let fieldIdArray

    if (isArray(entry)) {
        const foi = entry[0]
        field = foi instanceof DbField ? foi : fields[foi]
        if (!field) {
            if (!foi) {
                throw new Error(`One fld is undefined`)
            }
            throw new Error(`Missing field ${foi.table.graphqlId}.${foi.fieldName}`)
        }
        fieldName = field.fieldName
        fieldIdArray = entry[1]
    } else {
        if (entry instanceof DbField){
            field = entry    
        }else{
            field = fields[entry]
        }
         
        if (!(field instanceof DbField)) {
            throw new Error(`"${JSON.stringify(entry)}" is not a DbField`)
        }
        fieldName = field.fieldName
        if (field instanceof DbObject) fieldIdArray = field.idField.foreignTable.regularFieldIds
        else if (field instanceof DbObjectList) fieldIdArray = field.foreignTable.regularFieldIds
    }
    return [field, fieldName, fieldIdArray]
}

/**
 * 
 * @param {DbField|} fieldEntries
 */
export function getGraphqlFields(fieldEntries){
    const result = []
    
    for (let entry of fieldEntries){

        const [field, fieldName, fieldIdArray] = getFieldEntryDetails(entry)
        
        if (field instanceof DbObject) {

            // En un responsibility chain undefined !== false
            if (false === graphqlCanIncludeField.get(field.physicalField)) {
                continue
            }

            // En un responsibility chain undefined !== false
            if (false === graphqlCanIncludeTable.get(field.idField.foreignTable)) {
                continue
            }
            
            result.push({[fieldName]: getGraphqlFields(fieldIdArray)})

        } else if (field instanceof DbObjectList) {

            // En un responsibility chain undefined !== false
            if (false === graphqlCanIncludeTable.get(field.foreignTable)) {
                continue
            }
            
            result.push({
                [fieldName]: [
                    {
                        edges: [
                            {node: getGraphqlFields(fieldIdArray)}
                        ]
                    }
                ]
            })

        } else {
            // En un responsibility chain undefined !== false
            if (false === graphqlCanIncludeField.get(field)) {
                continue
            }
            result.push(fieldName)
        }
    }
    
    return result
}



function processGraphqlValue(field, value){
    if (value === null) {
        return undefined
    }
    if (field instanceof DbIntId) {
        return parseInt(value)
    } else if (field instanceof DbBool && isString(value)) {
        return value === 'true' || value === '1'
    } else {
        return value
    }
}


function processGraphqlObject(fieldEntries, object){

    if (object === undefined || object === null) return undefined
    
    const result = {}

    for (let entry of fieldEntries){

        const [field, fieldName, fieldIdArray] = getFieldEntryDetails(entry)

        const rawValue = object[fieldName]
        let value
        
        if (field instanceof DbObject)
            value = processGraphqlObject(fieldIdArray, rawValue)

        else if (field instanceof DbObjectList) {
            value = !rawValue ? [] : processGraphqlResult(fieldIdArray, rawValue.edges)

        } else {
            value = processGraphqlValue(field, rawValue)
        }

        result[fieldName] = value
    }

    return result
}

export function processGraphqlResult(fieldEntries, data){
    if (isArray(data)) {
        const result = []
        for (let item of data){
            result.push(processGraphqlObject(fieldEntries, item.node))
        }
        return result
    } else return processGraphqlObject(fieldEntries, data)
}


export function simplifyRows(rows){
    return rows.map(row => simplifyRow(row))
}


export function simplifyRow(row){
    return mapValues(row, field => {
        if (isPlainObject(field) && field.edges !== undefined) {
            return field.edges.map(obj => {
                return simplifyRow(obj.node)    
            })
        } else
            return field
    })
}


export function getColumnPhysicalField(column){
    if (!column instanceof DbColumn) {
        return undefined
    }
    return column instanceof LookupColumn
        ? column.field.idField
        : column.field
}

export function getFieldsFromColumns(columnDescriptors, {onlyPhysical = false, excludeDescriptors = true } = {}){
    const result = []
    for(let descriptor of columnDescriptors){
        let col
        if (!isArray(descriptor)) {
            col = descriptor
        } else {
            if (excludeDescriptors) {
                continue
            }
            col = descriptor[1]
        }
        if (col instanceof LookupColumn && onlyPhysical) {
            continue
        }
        if (! col instanceof DbColumn) {
            throw new Error('No es una columna')
        }
        result.push(col.field)
    }
    return result
}


/**
 * 
 * @param {OrderEntry} order
 */
export function getGraphqlOrder(order){
    //No puedo snakeCase de lodash porque convierte m2Calc en m_2
    return `${snakeCaseFromCamelCase(order.fieldId).toUpperCase()}_${order.direction.toUpperCase()}`
}

/**
 * 
 * @param fieldName
 * @param direction
 * @returns {string}
 */
export function calcGraphqlOrder(fieldName, direction = OrderDirection.ASC){
    return getGraphqlOrder(new OrderEntry(fieldName, direction))
}


export function calcGraphqlOrderMulti(/* OrderEntry[] */ order){
    if (order.length === 0) {
        return {}
    } else {
        return order.map(order => getGraphqlOrder(order))
    }
}







/**
 * Ejemplo
 *      Entrada:
 *          clause = 'nombreIlike' , values = ['%Pablo%', '%López%']
 *      Salida:
 *          [{nombreIlike: '%Pablo%'}, {nombreIlike: '%López%'}]
 *
 *      Ejemplo de filtro: {and: salida}
 *
 * @param {string}  clause
 * @param {String[]} values
 * @returns {*}
 */
export function getGraphqlRepeatinnClause(clause, values) {
    return values.map(value => ({[clause]: value}))
}


export function getILikeFromKeyword(/** String */ word){
    let keyword = word
    let begin = '%'
    let end = '%'
    if (keyword.slice(-1) === '|') {
        end = ''
        keyword = keyword.slice(0, -1)
    }
    if (keyword.slice(0, 1) === '|') {
        begin = ''
        keyword = keyword.slice(1)
    }
    return `${begin}${keyword}${end}`
}

export function getILikeFromKeyphrase(phrase, graphqlFilterKey){
    const words = phrase.split(' ')

    const wordFilters = words.map(word => {
        
        const filterKeys = isArray(graphqlFilterKey) ? graphqlFilterKey : [graphqlFilterKey] 
        const or = []
        for (let filterKey of filterKeys) {
            or.push({[filterKey]: getILikeFromKeyword(word)})
        }
        
        return or.length === 1 ? or[0] : {or: or}
    })

    return wordFilters
}



export function getGraphqlOrderCalculator(fieldDescriptors) {
    const dbFieldMap = decodeFieldDescriptors(fieldDescriptors)

    function getOrderFieldName(colId) {
        let field = dbFieldMap.get(colId)
        return !field ? undefined : field.physicalField.fieldName
    }

    function calc(order) {
        const result = []
        for (let {fieldId, direction} of order) {
            const fieldName = getOrderFieldName(fieldId)
            if (fieldName) {
                result.push(calcGraphqlOrder(fieldName, direction))
            }
        }
        return result
    }

    return calc
}



export function excludeGraphqlErrors(result, excludedErrors){
    const {data, errors} = result
    if (!errors || !excludedErrors) {
        return result
    }
    
    const newErrors = []
    const excludedPaths = new Set()
    
    // Remove errors and prepare exclude path set
    for (let error of errors) {
        let match = false
        for (let [excludedError, parentLevel] of excludedErrors) {
            if (error.message.includes(excludedError)) {
                match = true
                const path = dropRight(error.path, parentLevel)
                excludedPaths.add(path.join('/'))
                break
            }
        }
        if (!match) {
            newErrors.push(error)
        }
    }

    if (!excludedPaths.size) {
        return result
    }
    
    function parseProp(prop, pathBase){
        if (isArray(prop)) {
            const result = []
            let idx = -1
            for (let entry of prop) {
                idx++
                const path = (!pathBase ? '' : pathBase + '/') + idx
                if (excludedPaths.has(path)) {
                    continue
                }
                result.push(parseProp(entry, path))
            }
            return result
            
        } else if (isPlainObject(prop)) {
            const result = {}
            for (let [key, entry] of Object.entries(prop)) {
                const path = (!pathBase ? '' : pathBase + '/') + key
                if (excludedPaths.has(path)) {
                    continue
                }
                result[key] = parseProp(entry, path)           
            }
            return result
            
        } else {
            return prop
        }
    }
    
    const newData = parseProp(data, '')
    
    return {data: newData, ...(!newErrors.length ? {} : {errors: newErrors})}
}