 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const TareaSistemaIds = Object.freeze({
    idTarea: Symbol('idTarea'),
    idSistema: Symbol('idSistema'),
})

export const TareaSistema = Object.freeze({
    idTarea: 'idTarea',
    idSistema: 'idSistema',
})
