import {boundMethod} from 'autobind-decorator'
import {action, observable} from 'mobx'

export class WorldObject{
    
    /** @type {PieceWorld} */
    world
    _objectId
    
    @observable
    _progressCount = 0


    /**
     * @param {PieceWorld} world
     */
    constructor(world) {
        this.world = world
        this._objectId = Symbol()
        this._afterConstructor()
    }
    
    @boundMethod
    _afterConstructor(){
    }

    /** @type {ErrorStore} */
    get errorManager(){
        return this.world.errorManager
    }
    
    
    @action.bound
    _startProgress(){
        this._progressCount++
    }
    startProgress = this._action(this._startProgress)

    
    @action.bound
    _stopProgress(){
        this._progressCount--
    }
    stopProgress = this._action(this._stopProgress)
    
    
    get inProgress(){
        return !!this._progressCount
    }
    
    // mobx action + catch errors
    // Cerca del evento. Viene de fuera de una piece, o de una función de piece pero sin action.
    @boundMethod
    _action(func){
        return action((...args) => {
            if (!this.errorManager) throw new Error('Missing errorManager')
            try {
                return func(...args)
            }catch(error){
                this.errorManager.add(error)
            }
        })
    }

    // mobx action + catch errors + async
    @boundMethod
    _asyncAction(fn, {withProgress=true} = {}){
        return action(async (...args) => {
            if (!this.errorManager) throw new Error('Missing errorManager')

            if (withProgress) {
                this._startProgress()
            }
            try {
                return await fn(...args)
            }catch(error){
                this.errorManager.add(error)
            }finally {
                if (withProgress) {
                    this._stopProgress()
                }
            }
        })
    }
}