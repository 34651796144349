 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DocumentoIds} from './Documento'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserIds} from './User'
import {SolicitudIds} from './Solicitud'
import {TareaIds} from './Tarea'
import {PlanoEstadoIds} from './PlanoEstado'

export const DocumentoEdt = Object.freeze({
    autor : new LookupEditor(DocumentoIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    solicitud : new LookupEditor(DocumentoIds.solicitud, Tables.Solicitud, SolicitudIds.idSolicitud, 'idSolicitud', {sort: 'ID_SOLICITUD_ASC'}),
    cotizacion : new LookupEditor(DocumentoIds.cotizacion, Tables.Tarea, TareaIds.idTarea, 'ref', {sort: 'ID_TAREA_ASC'}),
    planosEstado : new LookupEditor(DocumentoIds.planosEstado, Tables.PlanoEstado, PlanoEstadoIds.idEstado, 'estado', {sort: 'ID_ESTADO_ASC'}),
    usuarioPlanos : new LookupEditor(DocumentoIds.usuarioPlanos, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idDocumento : new SimpleEditor(DocumentoIds.idDocumento, 11, 11),
    documento : new SimpleEditor(DocumentoIds.documento, 40, 16),
    fechaCreacion : new DateTimeEditor(DocumentoIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(DocumentoIds.fechaCreacion, 11, 11),
    borrado : new SimpleEditor(DocumentoIds.borrado, 11, 11),
    fechaModificacion : new DateTimeEditor(DocumentoIds.fechaModificacion, 16, 16),
    fechaModificacionOnlyDate : new DateEditor(DocumentoIds.fechaModificacion, 11, 11),
    idModificacion : new SimpleEditor(DocumentoIds.idModificacion, 11, 11),
    planosFechaEnvio : new DateTimeEditor(DocumentoIds.planosFechaEnvio, 16, 16),
    planosFechaEnvioOnlyDate : new DateEditor(DocumentoIds.planosFechaEnvio, 11, 11),
})

tableEditors[Tables.Documento] = DocumentoEdt
