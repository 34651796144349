import {Piece} from 'sopix/piece/piece'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('virtualListPiece')

export class TablePiece extends Piece{

    /** @type {RowSelection} */
    selection

    /** @type {GraphqlFiltrator} */
    filtrator
    
    /** @type {TablePainter} */
    painter
    
    idField

    /**
     * @param {Filtrator} filtrator
     * @param {TablePainter} painter
     * @param {RowSelection} selection
     */
    constructor(world, idField, filtrator, painter, selection) {
        super(world)

        this.idField = idField
        this.filtrator = filtrator
        this.painter = painter
        this.selection = selection
    }
}
