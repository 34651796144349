import React from 'react'
import {observer} from 'mobx-react'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {Frame} from 'sopix/formComps/Frame'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {AvisoSearch} from 'sop/componentes/aviso/AvisoSearch'
import {AvisoListPiece} from 'sop/componentes/aviso/AvisoList'
import {AvisoForm, AvisoFormPiece} from 'sop/componentes/aviso/AvisoForm'
import {boundMethod} from 'autobind-decorator'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {Aviso} from 'sop/db/Aviso'
import {AvisoTipo} from 'sop/db/AvisoTipo'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {graphqlUrl} from 'sop/db-config/db'
import {runInAction} from 'mobx'
import {icons} from 'sopix/icon/icons'
import {listFormSplitDefault} from 'sop/sop-config'
import {ErrorList} from 'sopix/errors/ErrorList'
import {once} from 'lodash-es'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {UrlManager} from 'sopix/pieces/urlManager'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {avisosCheck} from 'sop/componentes/aviso/avisosCheck'
import {linkPageListSearchFormUrlWithApply} from 'sopix/piece-linkers/list-form-url'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {getAuthSingleton} from 'sopix/session/auth-singletons'

export class AvisoPagePiece extends PagePiece{

    _avisosVistosMutation = new SimpleMutation(graphqlUrl, 'AvisosVistos')
    
    constructor() {
        super()
        this.form = new AvisoFormPiece(this.world)
        this.urlManager = new UrlManager(this.world, this.form.idField, sopUrls.avisos, sopUrls.aviso)
        this.search = new SearchPiece(this.world)
        this.list = new AvisoListPiece(this.world)

        linkPageListSearchFormUrlWithApply(this, this.list, this.search, this.form, this.urlManager)
    }

    async _init() {
        await super._init()
        await avisosCheck.refresh()
    }

    @boundMethod
    _listRowClicked({data}){
        this.form.apply(data)
    }
    
    
    @boundMethod
    async _marcarVistos(){
        await this._avisosVistosMutation.query()
        runInAction(()=>{
            avisosCheck.hayAvisos = 0
            this.list.filtrator.refresh()
        })
    }
    marcarVistos = this._asyncAction(this._marcarVistos)

    
    @boundMethod
    async _recargarAvisos(){
        await Promise.all([
            this.refresh(),
            avisosCheck.refresh(),
        ])
    }
    recargarAvisos = this._asyncAction(this._recargarAvisos)
    
    
}


export const getAvisoPage = getAuthSingleton(sopPages.avisos.id, () => {
    return new AvisoPagePiece(sopPages.avisos)
})


export const AvisoPage = observer(
    /**
     * 
     * @param {AvisoPagePiece} page
     * @returns {JSX.Element}
     */
    ({page}) => {

        usePiece(page)
        
        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame piece={page} renderPiece={()=> {
                    return (
                        <VerticalSplit id="AvisoPage" defaultSizes={listFormSplitDefault}>
                            <Frame>
                                <Bar>
                                    <AvisoSearch search={page.search} />
                                    <HintIconButton Icon={icons.CheckAll} disabled={!avisosCheck.hayAvisos}
                                                    large title="Marcar todos como vistos" onClick={page.marcarVistos}
                                    />
                                    <HintIconButton Icon={icons.Reload}
                                                    large title="Actualizar" onClick={page.recargarAvisos}
                                    />
                                </Bar>
                                <GraphqlTable list={page.list} />
                            </Frame>
                            <Frame>
                                <Bar>
                                    <ListNavigator list={page.list} /><Separator rightGap />
                                    <Title>{page.form.getField(Aviso.tipo, AvisoTipo.tipo) || ''}</Title>
                                </Bar>
                                <AvisoForm form={page.form} />
                            </Frame>
                        </VerticalSplit>
                    )

                }} />
            </>
        )
})