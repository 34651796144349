import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {FormPiece} from 'sopix/pieces/formPiece'
import {sopIcons} from 'sop/sop-icons'
import {DocumentoFld, DocumentoTable} from 'sop/db/DocumentoTable'
import {Documento} from 'sop/db/Documento'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {DocumentoEdt} from 'sop/db/DocumentoEditors'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {Tarea} from 'sop/db/Tarea'
import {Solicitud} from 'sop/db/Solicitud'
import {CuentaFld} from 'sop/db/CuentaTable'
import {observable, runInAction} from 'mobx'
import {Cuenta} from 'sop/db/Cuenta'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {CuentaEdt} from 'sop/db/CuentaEditors'
import {DateField} from 'sopix/form/editors/DateField'
import {UserEdt} from 'sop/db/UserEditors'
import {UserFld} from 'sop/db/UserTable'
import {PlanoEstado} from 'sop/db/PlanoEstado'
import {TareaEdt} from 'sop/db/TareaEditors'
import {PlanoEstadoFld} from 'sop/db/PlanoEstadoTable'
import {TipoTarea} from 'sop/db/TipoTarea'
import {TrabajoClase} from 'sop/db-config/row-consts'
import {TipoTareaFld} from 'sop/db/TipoTareaTable'
import {SolicitudEdt} from 'sop/db/SolicitudEditors'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'


export class DocumentoFormPiece extends FormPiece{
    
    _trabajoQL = new TableGraphql(TareaTable)
    _solicitudQL = new TableGraphql(SolicitudTable)
    
    @observable
    trabajoList = []
    
    @observable
    solicitanteList = []

    constructor(world) {
        const TABLE = DocumentoTable
        
        const FIELDS = [
            ...DocumentoTable.regularFields,
            [DocumentoFld.cotizacion, [
                TareaFld.ref,
                [TareaFld.tipo, [TipoTareaFld.clase]],
            ]],
            [DocumentoFld.planosEstado, [PlanoEstadoFld.estado]],
            [DocumentoFld.autor, [UserFld.nombreCompleto]],
        ]
        
        super(world,
            FIELDS,
            {
                name: 'Documento',
                icon: sopIcons.Documento,
                table: TABLE,
                idField: Documento.idDocumento,
                displayField: Documento.documento,
                deleteMutation: 'DeleteDocumento',
                saveMutation: 'SaveDocumento',
            },
        )
    }

    async _applyRow(data) {
        
        const idObra = data[Documento.idObra]
        
        const trabajos = await this._trabajoQL.query([TareaFld.idTarea, TareaFld.ref], undefined, 
            {[Tarea.idObra]: idObra})

        const solicitudes = await this._solicitudQL.query([
            SolicitudFld.idSolicitud,
            [SolicitudFld.cuenta, [CuentaFld.cuenta]]
        ], undefined,
            {[Solicitud.idObra]: idObra})

        runInAction(()=>{
            this.trabajoList = trabajos.rows
            this.solicitanteList = solicitudes.rows.map(sol => ({
                [Solicitud.idSolicitud]: sol[Solicitud.idSolicitud],
                [Cuenta.cuenta]: sol[Solicitud.cuenta][Cuenta.cuenta],
            }))
        })
        
        await super._applyRow(data)
    }

}





export const DocumentoForm = getPieceContainer('form', observer(
    /**
     * @param {DocumentoFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField
        
        const isNew = !fld(Documento.idDocumento)

        const planos = fld(Documento.cotizacion, Tarea.tipo, TipoTarea.clase) === TrabajoClase.ESTUDIO
        
        const editable = isNew && userAuth.isAllowed(Permiso.modificarDocumentos)
        
        return (
            <Fields>
                <Field autoFocus {...H.edt(DocumentoEdt.documento)} />
                <DropdownField {...H.edt(SolicitudEdt.cuenta)} disabled={!editable} label="Solicitante" name={Solicitud.idSolicitud} idField={Solicitud.idSolicitud} 
                               options={form.solicitanteList} />
                <DropdownField {...H.edt(DocumentoEdt.cotizacion)} disabled={!editable} options={form.trabajoList} />
                {!fld(Documento.planosEstado) || !planos ? null :
                    <Field {...H.obj(DocumentoEdt.planosEstado, PlanoEstado.estado)} disabled />}

                {!fld(Documento.planosFechaEnvio) || !planos ? null :
                    <DateField {...H.edt(DocumentoEdt.planosFechaEnvio)} disabled />}
                {!fld(Tarea.usuarioPlanos) || !planos ? null :
                    <Field {...H.obj(TareaEdt.usuarioPlanos, UserEdt.nombreCompleto)} />}

                {isNew ? null : <>
                    <DateField disabled {...H.edt(DocumentoEdt.fechaCreacion)} />
                    <Field {...H.obj(DocumentoEdt.autor, UserEdt.nombreCompleto)} />
                </>}
            </Fields>
        )
}))
