import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {observer} from 'mobx-react'
import {FormPiece} from 'sopix/pieces/formPiece'
import {NotaEdt} from 'sop/db/NotaEditors'
import {Nota} from 'sop/db/Nota'
import {sopIcons} from 'sop/sop-icons'
import {NotaFld, NotaTable} from 'sop/db/NotaTable'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {CUENTA_FIELDS_MIN, NOTA_FIELDS} from 'sop/db-config/fieldSets'
import {Field} from 'sopix/form/editors/Field'
import {ObraEdt} from 'sop/db/ObraEditors'
import {Obra} from 'sop/db/Obra'
import {UserEdt} from 'sop/db/UserEditors'
import {User} from 'sop/db/User'
import {DateField} from 'sopix/form/editors/DateField'
import {Wrap} from 'sopix/formComps/Wrap'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {CuentaEdt} from 'sop/db/CuentaEditors'
import {BoolField} from 'sopix/form/editors/BoolField'
import {Solicitud} from 'sop/db/Solicitud'
import {Cuenta} from 'sop/db/Cuenta'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {boundMethod} from 'autobind-decorator'
import {Fields} from 'sopix/formComps/Fields'
import {DEFAULT_MAX_LIST_ROWS} from 'sopix/data-source/data-source-defs'
import {usePiece} from 'sopix/piece/use-piece'

export class NotaFormPiece extends FormPiece{

    _listFetcher = new ListFetcher([
    ])

    _solicitudesQL = new TableGraphql(SolicitudTable)

    /** @type {Object[]} */
    solicitudRows
    
    newRowFields = {
    }
    
    constructor(world) {
        super(world,
            NOTA_FIELDS,
            {
                name: 'Nota',
                icon: sopIcons.Nota,
                table: NotaTable,
                idField: Nota.idNota,
                displayField: Nota.idNota,
                saveMutation: 'SaveNota',
                deleteMutation: 'DeleteNota',
            },
        )
    }

    @boundMethod
    async _createRow(){
        await this._applyRow({
            ...this.newRowFields,
            [Nota.destacada]: 0,
            [Nota.privada]: 0,
        })
    }
    
    @boundMethod
    async _applyRow(data) {
        const query = await this._solicitudesQL.query([
                SolicitudFld.idSolicitud,
                [SolicitudFld.cuenta, CUENTA_FIELDS_MIN],
            ],
            {first: DEFAULT_MAX_LIST_ROWS},
            {[Nota.idObra]: data[Nota.idObra]}
        )
        this.solicitudRows = query.rows
        await super._applyRow(data)
    }

}


export const NotaForm = observer(
    /**
     * @param {NotaFormPiece} form
     * @returns {JSX.Element}
     */
    ({form}) => {

        usePiece(form)

        function getSolicitudLabel(solicitud){
            return getFieldExtractor(solicitud)(Solicitud.cuenta, Cuenta.cuenta)
        }

        return <PieceFrame piece={form} renderPiece={()=> {

            const H = form.fieldHelper
            const fld = form.getField
            const man = form.fieldManager

            return (
                <Fields >
                    {!fld(Nota.idNota) ? null : <>
                        <Field
                            {...H.edt(ObraEdt.cod, {manager: false})}
                            label={NotaFld.obra.title}
                            disabled={true}
                            value={fld(Nota.obra, Obra.cod)}
                        />
                        <Field
                            {...H.edt(UserEdt.username, {manager: false})}
                            label={NotaFld.autor.title}
                            disabled={true}
                            value={fld(Nota.autor,User.username)}
                        />
                        <DateField
                            {...H.edt(NotaEdt.fechaCreacion, {manager: false})}
                            disabled={true}
                            value={fld(Nota.fechaCreacion)}
                        />
                        <Wrap />
                    </>}
                    <DropdownField
                        manager={man}
                        name={Nota.idSolicitud}
                        idField={Nota.idSolicitud}
                        getRowLabel={getSolicitudLabel} nullValue={0}
                        options={form.solicitudRows}
                        width={CuentaEdt.cuenta.maxWidth}
                    />
                    <BoolField {...H.edt(NotaEdt.destacada)} />
                    <BoolField {...H.edt(NotaEdt.privada)} />
                    <Field {...H.edt(NotaEdt.nota)} multiline rows={10} autoFocus/>
                </Fields>
            )

        }} />
    })