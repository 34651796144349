import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {Solicitud} from 'sop/db/Solicitud'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {ObraFld} from 'sop/db/ObraTable'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {Obra} from 'sop/db/Obra'
import {ObraCol} from 'sop/db/ObraColumns'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {boundMethod} from 'autobind-decorator'
import {getUrlFromRoute} from 'sopix/router/routerUtils'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {Liquidacion} from 'sop/db/Liquidacion'
import {NEW_RECORD} from 'sopix/db/db-types'
import {pushUrl} from 'sopix/router/router'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'

export class CuentaObraPainter extends TablePainter{
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global}) {
        switch (id){
            case Obra.cod:
                return flds(Solicitud.obra, Obra.cod)
                
            case Obra.nombre:
                return flds(Solicitud.obra, Obra.nombre)

            default:
                return super._paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global})
        }
    }
}


export class CuentaObraListPiece extends Piece{
    
    idField = Solicitud.idSolicitud
    
    constructor(world) {
        super(world)
        
        const FIELDS = [
            SolicitudFld.idSolicitud,
            SolicitudFld.idObra,
            [SolicitudFld.obra, [ObraFld.idObra, ObraFld.cod, ObraFld.nombre]]
        ]
        
        const COLUMNS = [
            ObraCol.cod,
            ObraCol.nombre,
        ]
        
        this.painter = new CuentaObraPainter(world, COLUMNS)
        
        this.dbSource = new SubsetDbSource(world, Solicitud.idCuenta, new TableGraphql(SolicitudTable), 
            FIELDS, {
                defaultGraphqlParams: {
                    sort: calcGraphqlOrder(Solicitud.idSolicitud, OrderDirection.DESC),
                }
            })
        
        this.load = this._state(Symbol('LOAD'), async idCuenta => {
            await this.dbSource.loadSubset(idCuenta)
        })
    }        
    
    @boundMethod
    rowClicked({data}){
        const url = getUrlFromRoute(sopUrls.obra, {[Obra.idObra]: data[Solicitud.idObra]})
        pushUrl(url)
    }
}


export const CuentaObraList = getPieceContainer('list', observer(
    /**
     * @param {CuentaObraListPiece} list
     */
    ({list}) => {

        const sya = list._action

        return (
            <Frame>
                <BlTable fitContainer dense
                         global={list}
                         verticalAlign="center"
                         data={list.dbSource.data}
                         keyColumnId={list.idField}
                         columns={list.painter.boundlessColumns}
                         renderCellContent={sya(list.painter.boundlessPainter)}
                         renderHeaderContent={sya(list.painter.headerPainter)}
                         onClick={sya(list.rowClicked)}
                />
            </Frame>
        )
}))
