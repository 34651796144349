import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {SageDescuento} from 'sop/db/SageDescuento'
import {SageDescuentoFld, SageDescuentoTable} from 'sop/db/SageDescuentoTable'
import {SageDescuentoCol} from 'sop/db/SageDescuentoColumns'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {DbSource} from 'sopix/data-source/dbSource'
import {boundMethod} from 'autobind-decorator'
import {SageClienteFld, SageClienteTable} from 'sop/db/SageClienteTable'
import {SageCliente} from 'sop/db/SageCliente'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'

export class SageDescuentoPainter extends TablePainter{

    @boundMethod
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global}) {
        switch(id){
            case SageDescuento.pO_DtoR:
            case SageDescuento.pO_DtoB:
            case SageDescuento.pO_DtoA:
            case SageDescuento.descuento1:
                return value.toFixed(2)
            default: 
                return super._paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global})
        }
    }
    
    @boundMethod
    _paintHeader({id, content, global}) {
        return super._paintHeader({id, content, global})
    }

}

export class SageDescuentosPiece extends Piece{
    idField = SageDescuento.codigoFamilia

    constructor(world) {
        super(world)

        const FIELDS = [
            SageDescuentoFld.codigoEmpresa,
            SageDescuentoFld.codigoCliente,
            SageDescuentoFld.codigoFamilia,
            SageDescuentoFld.descripcionFamilia,
            SageDescuentoFld.pO_DtoA,
            SageDescuentoFld.pO_DtoB,
            SageDescuentoFld.pO_DtoR,
            SageDescuentoFld.descuento1,
            SageDescuentoFld.pO_Observaciones,
        ]

        const COLUMNS = [
            SageDescuentoCol.descripcionFamilia,
            SageDescuentoCol.pO_DtoA,
            SageDescuentoCol.pO_DtoB,
            SageDescuentoCol.pO_DtoR,
            SageDescuentoCol.descuento1,
            SageDescuentoCol.pO_Observaciones,
        ]

        this.painter = new SageDescuentoPainter(world, COLUMNS)

        this.dbSource = new DbSource(world,
            new TableGraphql(SageDescuentoTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(SageDescuento.codigoFamilia)},
                defaultGraphqlFilter: {},
            })

        this.dbClientes = new DbSource(world,
            new TableGraphql(SageClienteTable),
            [
                SageClienteFld.codigoEmpresa,
                SageClienteFld.codigoCliente,
                SageClienteFld.comentarios_,
            ], {
             defaultGraphqlFilter: {},
        }) 
        
        this.load = this._state(Symbol('LOAD'), async (codigoEmpresa, codigoCliente) => {
            
            this.dbSource._setGraphqlFilter({
                ...this.dbSource.graphqlFilter,
                [SageDescuento.codigoEmpresa]: codigoEmpresa,
                [SageDescuento.codigoCliente]: codigoCliente,
            })
            this.dbClientes._setGraphqlFilter({
                ...this.dbClientes.graphqlFilter,
                [SageCliente.codigoEmpresa]: codigoEmpresa,
                [SageCliente.codigoCliente]: codigoCliente,
            })
            this.dbClientes.refresh().then()
            this.dbSource.refresh().then()
        })
    }

    @boundMethod
    async _init() {
       await super._init()
    }

    @boundMethod
    async _enable() {
        return await super._enable()
    }

    @boundMethod
    async _mountActions() {
        await super._mountActions()
    }

}


export const SageDescuentos = getPieceContainer('list', observer(
    /**
     * @param {SageDescuentosPiece} list
     */
    ({list}) => {

        const sya = list._action

        const cliRows = list.dbClientes.data
        const cli = !cliRows.length ? undefined : cliRows[0]
        
        return (
            <>
                <Frame>
                    <BlTable fitContainer dense
                             global={list}
                             verticalAlign="center"
                             data={list.dbSource.data}
                             keyColumnId={list.idField}
                             columns={list.painter.boundlessColumns}
                             renderCellContent={sya(list.painter.boundlessPainter)}
                             renderHeaderContent={sya(list.painter.headerPainter)}
                    />
                </Frame>
                <Fields>
                    {!cli ? null :
                        <Field value={cli[SageCliente.comentarios_]} multiline rowsMax={10} />
                    }
                </Fields>
            </>
        )
}))
