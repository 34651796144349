 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {RelacionadaIds} from './Relacionada'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const RelacionadaEdt = Object.freeze({
    obra : new LookupEditor(RelacionadaIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    relacionada : new LookupEditor(RelacionadaIds.relacionada, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    autor : new LookupEditor(RelacionadaIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idRelacion : new SimpleEditor(RelacionadaIds.idRelacion, 11, 11),
    fechaCreacion : new DateTimeEditor(RelacionadaIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(RelacionadaIds.fechaCreacion, 11, 11),
})

tableEditors[Tables.Relacionada] = RelacionadaEdt
