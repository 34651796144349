 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UserIds} from './User'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbBinary, DbBool, DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {UserRoleIds} from './UserRole'

export const UserTable = new DbTable(
    Tables.User,
    'Users',
    graphqlUrl,
    'User',
    [UserIds.id],
    [UserIds.id, UserIds.email, UserIds.password, UserIds.username, UserIds.nombreCompleto, UserIds.roleUsuario, UserIds.idAutor, UserIds.fechaCreacion, UserIds.zona, UserIds.movil, UserIds.ausente, UserIds.mueveCotizaciones, UserIds.ausenteDesde, UserIds.ausenteHasta, UserIds.idModificacion, UserIds.fechaModificacion, UserIds.inactivo, UserIds.horas, UserIds.hashedPbkdf2, UserIds.resetKey, UserIds.apiKey, UserIds.odooCambiado, UserIds.totpSecret, UserIds.totpRequerido, UserIds.resetKeyTime, UserIds.loginTryTime, UserIds.loginTryCount],
    [UserIds.roleUsuarioObj],
    [UserIds.obrasAutor, UserIds.obrasModificacion, UserIds.solicitudes, UserIds.tareasAutor, UserIds.tareasResponsable, UserIds.cuentasAutor, UserIds.cuentasModificacion, UserIds.cuentasComercial, UserIds.direccionesAutor, UserIds.direccionesModificacion, UserIds.contactosAutor, UserIds.contactosModificacion, UserIds.notas, UserIds.relacionadas, UserIds.representantesRepresentante, UserIds.representantesAutor, UserIds.sesiones, UserIds.liquidacionesAutor, UserIds.liquidacionesResponsable, UserIds.avisos, UserIds.aprobaciones, UserIds.aproliqmans, UserIds.liqtecmeses, UserIds.ejecucionesCuentas, UserIds.documentosAutor, UserIds.documentosUsuarioPlanos, UserIds.permisos, UserIds.avisoSubscripciones, UserIds.avisosPendientes],
    ['verUsuariosMinimo'],
    [],
    ['crearUsuarios'],
    ['eliminarUsuarios'],
    [[0, [], []], [1, [], [UserIds.id, UserIds.username, UserIds.nombreCompleto, UserIds.roleUsuario, UserIds.roleUsuarioObj, UserIds.zona, UserIds.aclReadOnly, UserIds.inactivo, UserIds.email, UserIds.movil, UserIds.fechaCreacion, UserIds.idAutor, UserIds.horas, UserIds.cnt, UserIds.ausente, UserIds.ausenteDesde, UserIds.ausenteHasta, UserIds.permisos, UserIds.totpRequerido]], [1, ['verUsuariosAdmin'], [UserIds.id, UserIds.username, UserIds.nombreCompleto, UserIds.roleUsuario, UserIds.roleUsuarioObj, UserIds.zona, UserIds.aclReadOnly, UserIds.inactivo, UserIds.email, UserIds.movil, UserIds.fechaCreacion, UserIds.idAutor, UserIds.horas, UserIds.cnt, UserIds.ausente, UserIds.ausenteDesde, UserIds.ausenteHasta, UserIds.permisos, UserIds.totpRequerido, UserIds.resetKey, UserIds.apiKey]]],
    [[0, [], []], [1, [], [UserIds.username, UserIds.nombreCompleto, UserIds.email, UserIds.movil, UserIds.ausente, UserIds.ausenteDesde, UserIds.ausenteHasta]], [1, ['modificarUsuariosAdmin'], [UserIds.username, UserIds.nombreCompleto, UserIds.email, UserIds.movil, UserIds.ausente, UserIds.ausenteDesde, UserIds.ausenteHasta, UserIds.roleUsuario, UserIds.zona, UserIds.inactivo, UserIds.horas, UserIds.resetKey, UserIds.apiKey, UserIds.permisos, UserIds.totpRequerido]]],
)


export const UserFld = Object.freeze({
    obrasAutor : new DbObjectList(Tables.User, UserIds.obrasAutor, Tables.Obra),
    obrasModificacion : new DbObjectList(Tables.User, UserIds.obrasModificacion, Tables.Obra),
    solicitudes : new DbObjectList(Tables.User, UserIds.solicitudes, Tables.Solicitud),
    tareasAutor : new DbObjectList(Tables.User, UserIds.tareasAutor, Tables.Tarea),
    tareasResponsable : new DbObjectList(Tables.User, UserIds.tareasResponsable, Tables.Tarea),
    cuentasAutor : new DbObjectList(Tables.User, UserIds.cuentasAutor, Tables.Cuenta),
    cuentasModificacion : new DbObjectList(Tables.User, UserIds.cuentasModificacion, Tables.Cuenta),
    cuentasComercial : new DbObjectList(Tables.User, UserIds.cuentasComercial, Tables.Cuenta),
    direccionesAutor : new DbObjectList(Tables.User, UserIds.direccionesAutor, Tables.Direccion),
    direccionesModificacion : new DbObjectList(Tables.User, UserIds.direccionesModificacion, Tables.Direccion),
    contactosAutor : new DbObjectList(Tables.User, UserIds.contactosAutor, Tables.Contacto),
    contactosModificacion : new DbObjectList(Tables.User, UserIds.contactosModificacion, Tables.Contacto),
    notas : new DbObjectList(Tables.User, UserIds.notas, Tables.Nota),
    relacionadas : new DbObjectList(Tables.User, UserIds.relacionadas, Tables.Relacionada),
    representantesRepresentante : new DbObjectList(Tables.User, UserIds.representantesRepresentante, Tables.Representante),
    representantesAutor : new DbObjectList(Tables.User, UserIds.representantesAutor, Tables.Representante),
    sesiones : new DbObjectList(Tables.User, UserIds.sesiones, Tables.Sesion),
    liquidacionesAutor : new DbObjectList(Tables.User, UserIds.liquidacionesAutor, Tables.Liquidacion),
    liquidacionesResponsable : new DbObjectList(Tables.User, UserIds.liquidacionesResponsable, Tables.Liquidacion),
    avisos : new DbObjectList(Tables.User, UserIds.avisos, Tables.Aviso),
    aprobaciones : new DbObjectList(Tables.User, UserIds.aprobaciones, Tables.Aprobacion),
    aproliqmans : new DbObjectList(Tables.User, UserIds.aproliqmans, Tables.Aproliqman),
    liqtecmeses : new DbObjectList(Tables.User, UserIds.liqtecmeses, Tables.Liqtecmes),
    ejecucionesCuentas : new DbObjectList(Tables.User, UserIds.ejecucionesCuentas, Tables.EjecucionCuenta),
    documentosAutor : new DbObjectList(Tables.User, UserIds.documentosAutor, Tables.Documento),
    documentosUsuarioPlanos : new DbObjectList(Tables.User, UserIds.documentosUsuarioPlanos, Tables.Documento),
    permisos : new DbObjectList(Tables.User, UserIds.permisos, Tables.Permiso),
    avisoSubscripciones : new DbObjectList(Tables.User, UserIds.avisoSubscripciones, Tables.AvisoTipo),
    avisosPendientes : new DbObjectList(Tables.User, UserIds.avisosPendientes, Tables.Aviso),
    roleUsuarioObj : new DbObject(Tables.User, UserIds.roleUsuarioObj, UserIds.roleUsuario, 'Rol'),
    id : new DbIntId(Tables.User, UserIds.id, 'Id'),
    email : new DbText(Tables.User, UserIds.email, 'Email', 249),
    password : new DbText(Tables.User, UserIds.password, 'Password', 255),
    username : new DbText(Tables.User, UserIds.username, 'Usuario', 100),
    nombreCompleto : new DbText(Tables.User, UserIds.nombreCompleto, 'Nombre', 80),
    roleUsuario : new DbObjectId(Tables.User, UserIds.roleUsuario, 'Role usuario', {foreignTableId: Tables.UserRole, foreignKeyFieldId: UserRoleIds.idRole}),
    idAutor : new DbInt(Tables.User, UserIds.idAutor, 'Id autor'),
    fechaCreacion : new DbDateTime(Tables.User, UserIds.fechaCreacion, 'Fecha creacion'),
    zona : new DbText(Tables.User, UserIds.zona, 'Zona', 50),
    movil : new DbText(Tables.User, UserIds.movil, 'Movil', 20),
    ausente : new DbInt(Tables.User, UserIds.ausente, 'Ausente'),
    mueveCotizaciones : new DbInt(Tables.User, UserIds.mueveCotizaciones, 'Mueve cotizaciones'),
    ausenteDesde : new DbDateTime(Tables.User, UserIds.ausenteDesde, 'Ausente desde'),
    ausenteHasta : new DbDateTime(Tables.User, UserIds.ausenteHasta, 'Ausente hasta'),
    idModificacion : new DbInt(Tables.User, UserIds.idModificacion, 'Id modificacion'),
    fechaModificacion : new DbDateTime(Tables.User, UserIds.fechaModificacion, 'Fecha modificacion'),
    inactivo : new DbInt(Tables.User, UserIds.inactivo, 'Inactivo'),
    horas : new DbInt(Tables.User, UserIds.horas, 'Horas/jornada'),
    hashedPbkdf2 : new DbBinary(Tables.User, UserIds.hashedPbkdf2, 'Hashed pbkdf2'),
    resetKey : new DbText(Tables.User, UserIds.resetKey, 'Clave de reinicio', 32),
    apiKey : new DbText(Tables.User, UserIds.apiKey, 'Clave API', 40),
    odooCambiado : new DbInt(Tables.User, UserIds.odooCambiado, 'Odoo cambiado'),
    totpSecret : new DbText(Tables.User, UserIds.totpSecret, 'Totp secret', 40),
    totpRequerido : new DbInt(Tables.User, UserIds.totpRequerido, 'Verificación en 2 pasos'),
    resetKeyTime : new DbDateTime(Tables.User, UserIds.resetKeyTime, 'Reset key time'),
    loginTryTime : new DbDateTime(Tables.User, UserIds.loginTryTime, 'Login try time'),
    loginTryCount : new DbInt(Tables.User, UserIds.loginTryCount, 'Login try count'),
    aclReadOnly : new DbBool(Tables.User, UserIds.aclReadOnly, 'Acl read only'),
})

registerTableFields(Tables.User, UserFld) 
