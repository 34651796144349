import React, {useEffect, useRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {filePicker} from 'sopix/file-picker/filePickerStore'

const useStyles = makeStyles(() => ({
    filePicker: {
        display: 'none'
    },
})) 

export function FilePicker({children}) {
    
    const cls = useStyles()

    const inputRef = useRef()

    useEffect(() => {
        filePicker._input = inputRef.current
        
        return () => {
            filePicker._input = undefined
        }
    }, [])
    
    return (
        <div>
            <input ref={inputRef} className={cls.filePicker} type="file" multiple="multiple"
                   onChange={filePicker._fileSelected}
            />
        </div>
    )
}
