import React, {createRef} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Draggable from 'react-draggable'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'


function PaperComponent({handleId, ...props}) {
    const nodeRef = createRef()
    return (
        <Draggable nodeRef={nodeRef} handle={`#${handleId}`} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} style={{minHeight: 400}} {...props} />
        </Draggable>
    );
}


const useStyles = makeStyles(() => ({
    paperAlmostFullScreen: {
        maxWidth: 'none',
        height: 'calc(100% - 64px)',
        width: 'calc(100% - 64px)',
    },
})) 



export function DraggableDialog({open, onClose, draggableComponentId, children, almostFullScreen, ...otherProps}) {
    
    const cls = useStyles()
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            PaperProps={{handleId: draggableComponentId}}
            fullWidth={true}
            classes={!almostFullScreen ? {} : {paper: cls.paperAlmostFullScreen}}
            {...otherProps}
        >
            {children}
        </Dialog>
    )
}
