import React, {forwardRef} from 'react'
import Moment from 'moment'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {Tarea} from 'sop/db/Tarea'
import {TareaEstado} from 'sop/db/TareaEstado'
import {BlRow} from 'sopix/boundless-table/BlRow'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {cimStyles} from 'sopix/theme/cimStyles'
import {sopColors} from 'sop/sop-colors'

const useStyles = makeStyles(theme => ({
    trabajoCell: {},
    finalizado: cimStyles.dimmed,
    vencido: {
        background: sopColors.yellowBackground,
    },
    urgente: {
        background: sopColors.redBackground,
    },
    asumible: {
        background: sopColors.greenBackground,
    },
}))

export const TrabajoRow = forwardRef(
    ({data, selected, children, ...more}, ref) => {

        const mom = Moment
        const cls = useStyles()
        const fld = getFieldExtractor(data)
        const finalizado = fld(Tarea.estado, TareaEstado.finalizado)
        const asumible = fld(Tarea.asumible)
        const urgente = fld(Tarea.urgente)
        const vencido = fld(Tarea.fechaVencimiento) && mom(fld(Tarea.fechaVencimiento)) <= mom()

        return (
            <BlRow ref={ref} className={clsx(
                finalizado && cls.finalizado,
                !selected && !finalizado && vencido && cls.vencido,
                !selected && !finalizado && urgente && cls.urgente,
                !selected && asumible && cls.asumible,
                cls.trabajoCell,
            )} data={data} selected={selected} {...more} >
                {children}
            </BlRow>
        )
    })