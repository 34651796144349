import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import React, {useReducer, useState} from 'react'
import {getLoggers} from 'sopix/log'
import {CancelError} from 'sopix/db/apiEndPoint'
import {FieldController} from 'sopix/form/FieldController'
import {getInputLabelProps, getInputProps, useFieldStyle} from 'sopix/form/field-style'
import {boundMethod} from 'autobind-decorator'
import {doNothing} from 'sopix/utils/misc'
import {getAdornmentInputProps} from 'sopix/form/editor-utils'
import clsx from 'clsx'
import {Tooltip} from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
const {info, debug} = getLoggers('DropdownField')

const LOOKUP_EXTRA_SIZE = 20


class DropDownFieldState{

    loadingText
    _options
    _optionsProp

    optionMap = new Map()
    optionKeys = []
    currentSearch

    idField
    displayField
    optionsSource
    onChange
    customRender
    nullValue
    
    handleGetRowLabel
    customFilter
    
    filterOptions = createFilterOptions({
        stringify: this._filterStringifyHandler
    })
    
    constructor(doRender) {
        this.doRender = doRender
    }
    
    onRender({idField, displayField, options, optionsSource, onChange, customRender, nullValue, getRowLabel, 
                 customFilter, multiple}){
        this.nullValue = nullValue
        this.idField = idField
        this.displayField = displayField
        this.onChange = onChange
        this.customRender = customRender
        if (options !== this._optionsProp) {
            this._optionsProp = options
            this.options = options
        }
        this.optionsSource = optionsSource
        this.handleGetRowLabel = getRowLabel === undefined ? this._defaultGetRowLabelHandler : getRowLabel
        this.customFilter = customFilter
        this.multiple = multiple
    }
    
    set options(options) {
        if (options === this._options) return
        this._options = options
        
        if (options === undefined || (options.length === 1 && options[0] === undefined)) {
            this.optionMap = new Map()
            this.optionKeys = []
        } else {
            this.optionMap = new Map(options.map(entry => [entry[this.idField], entry]))
            this.optionKeys = [...this.optionMap.keys()]
        }
    }
    

    get loading(){
        return this.loadingText !== undefined
    }
    
    setLoading(loadingText){
        if (this.loading === loadingText) return false
        this.loadingText = loadingText
        return true
    }
    
    
    async updateOptions(search) {
        if (this.optionsSource === undefined) return

        if (search === this.currentSearch) return
        this.currentSearch = search
        
        debug(()=>`updateOptions: ${search}`)

        if (this.setLoading('Cargando...')) this.doRender() 
        try {
            const result = await this.optionsSource(search)
            this.options = result
            this.setLoading()
            this.doRender()
        } catch (e) {
            if (!(e instanceof CancelError)) {
                this.options = []
                this.setLoading(`Error: ${e.message}`)
                this.doRender()
            }
        }
    }

    @boundMethod
    handleInputChange(_, text) {
        this.updateOptions(text)
    }


    @boundMethod
    _defaultGetRowLabelHandler(row){
        return row[this.displayField]
    }
    
    @boundMethod
    handleGetOptionLabel(option){
        const row = this.optionMap.get(option)
        const result = row === undefined ? '' : this.handleGetRowLabel(row)
        debug(()=>`option Label ${this.idField}: ${option} => ${result}`)
        return result
    }

    @boundMethod
    handleGetOptionSelected(option, value){
        let result
        if (this.customSelect){
            const row = this.optionMap.get(option)
            result = this.customSelect(row, value)
        } else {
            result = option === value
        }
        debug(()=>`getOptionSelected: option=${option},  value=${value}, result=${result}`)
        return result
    }

    @boundMethod
    handleRenderOption(option){
        const row = this.optionMap.get(option)
        let result
        if (this.customRender){
            result = this.customRender(row)
        
        } else {
            result = this.handleGetRowLabel(row)
        }
        debug(()=>`render Option ${this.idField}: ${option} => ${result}`)
        return result
    }
    
    
    filterStringify(row){
        return this.handleGetRowLabel(row)
    }
    
    @boundMethod
    _filterStringifyHandler(option){
        const row = this.optionMap.get(option)
        return !row ? '' : (this.customFilter ? this.customFilter(row) : this.filterStringify(row))
    }
    

    @boundMethod
    handleOnChange(renderOnChange, e, newValue){
        const value = newValue === null ? this.nullValue : newValue
        if (this.onChange) {
            if (this.multiple) {
                this.onChange(value)
            } else {
                this.onChange(value === this.nullValue ? value : this.optionMap.get(value))
            }
        }
        renderOnChange && renderOnChange(newValue)
    }

    filterInvalidValues(value){
        if (!this.multiple) {
            if (!this.optionKeys.includes(value)) {
                return null
            } else {
                return value    
            }
        }
        const result = []
        for (let val of value){
            if (this.optionKeys.includes(value)) {
                result.push(val)
            }
        }
        return result
    }
    
}



export const DropdownField = ({ className,
    manager, name, label, idField, displayField, width: _width, options, optionsSource, value, dataType,
    onChange, autoFocus, customRender, nullValue, getRowLabel, small, disabled : disabledBase, customFilter, 
    multiple, startAdornment, endAdornment, nullify, tooltip, tooltipPlacement, ...otherProps}
) => {
    
    const disabled = disabledBase || (manager && manager.readOnly)

    const width = _width + LOOKUP_EXTRA_SIZE
    
    const fieldCls = useFieldStyle({width, small})

    const [, doRender] = useReducer(state => state + 1, 0, undefined)
    
    const stateArray = useState(() => new DropDownFieldState(doRender))
    /** @type {DropDownFieldState} */
    const state = stateArray[0]
    
    if (nullify) {
        return null
    }
    
    state.onRender({
        idField, displayField, options, optionsSource, onChange, customRender, nullValue, getRowLabel, customFilter, 
        multiple})

    debug(()=>`render: options len: ${options.length}, loading: ${stateArray.loading}`)


    function render({onChange, onBlur, value, error}){
        info(()=>`render: ${idField} = ${value}, options: ${state.optionMap.size}`)
        const name2 = name
        let autocompleteValue 
        const opts = state.optionKeys
        if (value === undefined  || value === nullValue) {
            autocompleteValue = null
        } else{
            autocompleteValue = value

            // En el caso de que se actualice el valor y no esté en options, se usa value null. Si no se hace, luego
            //  cuando se actualiza options, el cambio no se refleja ya que value se mantiene sin cambios.
            if (!multiple && !opts.includes(value)) {
                autocompleteValue = null
            }
        }
        return (
            <Autocomplete
                className={clsx(
                    fieldCls.field,
                    className,
                )}
                loading={state.loading}
                loadingText={state.loadingText}
                options={opts}
                autoHighlight autoSelect
                getOptionLabel={state.handleGetOptionLabel}
                getOptionSelected={state.handleGetOptionSelected}
                renderOption={state.handleRenderOption}
                filterOptions={state.filterOptions}
                renderInput={params => {

                    const textField = (
                        <TextField
                            {...params}
                            autoFocus={autoFocus}
                            label={label}
                            inputProps={{
                                ...params.inputProps,
                                onBlur: e => {
                                    //Llama a onBlur de Autocomplete + onBlur de FieldManager
                                    params.inputProps.onBlur(e)
                                    onBlur(e)
                                    //Disabled + isEmpty muestra guión??
                                },
                                ...(!disabled || value ? {} : {value: '-'}),
                            }}
                            InputLabelProps={getInputLabelProps(fieldCls, small)}
                            InputProps={{
                                ...params.InputProps,
                                ...getInputProps(fieldCls, small),
                                ...getAdornmentInputProps(startAdornment, endAdornment),
                            }}
                            error={error !== undefined}
                            helperText={error}
                        />
                    )
                    
                    return !tooltip ? textField : (
                        <Tooltip title={tooltip} placement={tooltipPlacement} >
                            {textField}
                        </Tooltip>
                    )
                }}
                value={autocompleteValue}
                onChange={(e, newValue) =>
                    state.handleOnChange(onChange, e, newValue)}
                onInputChange={state.handleInputChange}
                disabled={disabled}
                multiple={multiple}
                ChipProps={{size: "small", variant:"outlined"}}
                {...otherProps}
            />
        )
    }

    if (manager === undefined) {
        return render({value: value, onBlur: doNothing})
        
    } else {
        return (
            <FieldController
                fieldManager={manager}
                name={name}
                render={render}
            />
        )
    }
}
