import {InputAdornment} from '@material-ui/core'
import React from 'react'

export function getAdornmentInputProps(startAdornment, endAdornment){
    return {
        ...(!startAdornment ? {} : {
            startAdornment: (
                <InputAdornment position="start">
                    {startAdornment}
                </InputAdornment>
            )}),
        ...(!endAdornment ? {} : {
            endAdornment: (
                <InputAdornment position="end">
                    {endAdornment}
                </InputAdornment>
            )}),
        
    }
}