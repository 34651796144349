 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproplanoIds} from './Aproplano'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AproplanoEdt = Object.freeze({
    idAproplano : new SimpleEditor(AproplanoIds.idAproplano, 11, 11),
    justificacion : new SimpleEditor(AproplanoIds.justificacion, 40, 16),
})

tableEditors[Tables.Aproplano] = AproplanoEdt
