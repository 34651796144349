import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles'
import {esES as materialesEs} from '@material-ui/core/locale'

export const sopTheme = createMuiTheme({
    palette: {
        primary: { main: '#3E2BB1'},
    },
    shape: {
        borderRadius: 0,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                backgroundColor: 'white',
                color: 'black',
                fontSize: "1em",
                padding: '2px 6px',
                borderRadius: '2px',
                border: '1px dotted gray',
                maxWidth: 600,
            }
        }
    }
}, materialesEs)
