 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TipologiaIds} from './Tipologia'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const TipologiaTable = new DbTable(
    Tables.Tipologia,
    'Tipologias',
    graphqlUrl,
    'Tipologia',
    [TipologiaIds.idTipologia],
    [TipologiaIds.idTipologia, TipologiaIds.tipologia, TipologiaIds.orden, TipologiaIds.subtipologias],
    [],
    [TipologiaIds.obras],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const TipologiaFld = Object.freeze({
    obras : new DbObjectList(Tables.Tipologia, TipologiaIds.obras, Tables.Obra),
    idTipologia : new DbIntId(Tables.Tipologia, TipologiaIds.idTipologia, 'Id tipología'),
    tipologia : new DbText(Tables.Tipologia, TipologiaIds.tipologia, 'Tipologia', 35),
    orden : new DbInt(Tables.Tipologia, TipologiaIds.orden, 'Orden'),
    subtipologias : new DbText(Tables.Tipologia, TipologiaIds.subtipologias, 'Subtipologias', 1000),
})

registerTableFields(Tables.Tipologia, TipologiaFld) 
