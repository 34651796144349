import {ChartPiece} from 'sop/componentes/stats/chartPiece'
import Chart from 'chart.js'
import {boundMethod} from 'autobind-decorator'
import {Classic20} from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
import {ChartFrame} from 'sop/componentes/stats/ChartFrame'
import React from 'react'
import {TecnicoMesSearch, TecnicoMesSearchPiece} from 'sop/componentes/stats/tecnico-mes/TecnicoMesSearch'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'
import {LiqtecmesFld, LiqtecmesTable} from 'sop/db/LiqtecmesTable'
import {UserFld, UserTable} from 'sop/db/UserTable'
import {
    getDateFromIdMes,
    getIdMes,
    TECMES_MES_DESDE,
    TECMES_MES_HASTA,
    tecnicoMesFilter,
} from 'sop/componentes/stats/tecnico-mes/tecnicoMesFilter'
import {Liqtecmes} from 'sop/db/Liqtecmes'
import {User} from 'sop/db/User'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {find} from 'lodash-es'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {getAnualMesLabel} from 'sopix/date/date-utils'
import Moment from 'moment'
import {action} from 'mobx'
import {CHART_APILAR, CHART_BAR_WIDTH, CHART_HORIZONTAL_BAR} from 'sop/componentes/stats/chart-consts'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {RolesUsuario} from 'sop/db-config/row-consts'
import {getUsuariosListQueryEntry} from 'sop/db-config/sop-query-lists'


export class TecnicoMesChartPiece extends ChartPiece {

    _listFetcher = new ListFetcher([
        getUsuariosListQueryEntry(RolesUsuario.TECNICO),
    ])

    _searchNo = 0

    _serieProps = {
        borderWidth: 1,
        //maxBarThickness: 50,
        //barThickness: 20,
    }

    _liqtecmesQL = new TableGraphql(LiqtecmesTable)

    minMes
    maxMes
    
    /** @type {TecnicoMesSearchPiece} */
    search
    
    horizontal = false
    apilar = true
    
    
    constructor(world) {
        super(world)

        const FIELDS = [
            LiqtecmesFld.idMes,
            LiqtecmesFld.m2,
            LiqtecmesFld.idTecnico,
            [LiqtecmesFld.tecnico, [UserFld.nombreCompleto]],
        ]

        this.filtrator = graphqlFiltratorBuilder(world, LiqtecmesTable, FIELDS,
            {
                graphqlFilterCalculator: tecnicoMesFilter,
                defaultOrder: [new OrderEntry(Liqtecmes.idMes), new OrderEntry(Liqtecmes.idTecnico)],
            })

        this.Frame = <ChartFrame chart={this}/>
        this.search = new TecnicoMesSearchPiece(world, {
            defaultFields: {
                [Liqtecmes.idTecnico]: [],
            }
        })
        this.Search = <TecnicoMesSearch search={this.search}/>
        
        this.search.fieldManager.onChangeObservers.subscribe(this._asyncAction(async()=>{
            if (await this.updateFiltrator() && this.initialized) {
                await this.refresh()
            }
        }))
        
        this.search.optionsMan.onChangeObservers.subscribe(({fields}) => {
            this.setHorizontal(!!fields[CHART_HORIZONTAL_BAR])
            this.setApilar(!!fields[CHART_APILAR])
        })
    }


    async _init() {
        const getParams = direction => {
            return [
                [LiqtecmesFld.idMes],
                {first: 1, sort: [calcGraphqlOrder(Liqtecmes.idMes, direction)]},
                {[`${Liqtecmes.m2}Gt`]: 0}
            ]
        }
        
        const first = await this._liqtecmesQL.query(...getParams())
        const last = await this._liqtecmesQL.query(...getParams(OrderDirection.DESC)) 

        const lists = await this._listFetcher.fetch()
        const tecs = lists[UserTable.graphqlId].rows.map(tec => tec[User.id])
        this.search.setField(Liqtecmes.idTecnico, tecs)

        const now = Moment()
        this.minMes = !first.rows.length ? now.year() * 12 : first.rows[0][Liqtecmes.idMes]
        this.maxMes = !last.rows.length ? now.year() * 12 : last.rows[0][Liqtecmes.idMes]
        
        await this.validate()
        
        if (this.ctx) {
            await this.updateFiltrator()
            await this.refresh()
        }
    }


    @boundMethod
    async _afterRegisterCanvas() {
        if (this.valid) {
            await this.updateFiltrator()
            await this.refresh()
        }
    }


    _updateHeight(){
        if (this.horizontal) {
            if (!this.mesCount) {
                this.height = 50
            } else {
                if (this.apilar) {
                    this.height = CHART_BAR_WIDTH * this.mesCount
                }else{
                    const tecnicos = this.tecCount
                    this.height = this.mesCount * (tecnicos.length || 1)  * CHART_BAR_WIDTH
                }
            }
        } else {
            this.height = '100%'
        }
    }
    
    setHorizontal(horizontal){
        if (horizontal === this.horizontal) return
        this.horizontal = horizontal

        this._updateHeight()        

        this._resetChart({
            labels: this.chart.data.labels,
            datasets: this.chart.data.datasets
        })
    }
    
    setApilar(apilar){
        if (apilar === this.apilar) return
        this.apilar = apilar
        
        this.chart.options.scales.yAxes[0].stacked = this.apilar
        this.chart.options.scales.xAxes[0].stacked = this.apilar
        this._updateHeight()
        this.chart.update()
    }
    
    get mesDesde(){
        const desdeDate = this.search.fieldManager.getValue(TECMES_MES_DESDE)
        return !desdeDate ? this.minMes : getIdMes(desdeDate)
    }

    get mesHasta(){
        const hastaDate = this.search.fieldManager.getValue(TECMES_MES_HASTA)
        return !hastaDate ? this.maxMes : getIdMes(hastaDate)
    }
    
    
    get mesCount(){
        return this.mesHasta - this.mesDesde + 1
    }
    
    get tecCount(){
        return this.search.fieldManager.getValue(Liqtecmes.idTecnico)        
    }
    
    
    @action.bound
    async updateFiltrator(){
        const newFields = {...this.search.fields}
        
        if (!newFields[TECMES_MES_DESDE]) {
            newFields[TECMES_MES_DESDE] = getDateFromIdMes(this.minMes)
        }

        if (!newFields[TECMES_MES_HASTA]) {
            newFields[TECMES_MES_HASTA] = getDateFromIdMes(this.maxMes)
        }

        if (JSON.stringify(this.filtrator.currentFilter) === JSON.stringify(newFields)) return false

        this.filtrator.setCurrentFilter(newFields)
        
        return true
    }
   
   
    
    _getMesLabels(mesDesde, mesHasta){
        const labels=[]
        
        if (mesDesde && mesHasta){
            for (let idMes = mesDesde; idMes <= mesHasta ; idMes++){
                labels.push(getAnualMesLabel(idMes))
            }
        }
        
        return labels
    }
    
    
    @boundMethod
    async refresh() {
        if (!this.ctx) return

        await this._resetChart({
            labels: this._getMesLabels(this.mesDesde, this.mesHasta),
        })

        this._startProgress()
        try{
            const searchNo = ++this._searchNo

            //Si se inicia otra búsqueda ésta se cancela
            await this.filtrator.refresh()
            if (this._searchNo !== searchNo) return

            let index = 0
            while (this._searchNo === searchNo) {

                const block = await this.filtrator.source.getData(index)
                if (this._searchNo !== searchNo) break

                this._updateChart(block.data)
                this.chart.update()
                if (!block.hasNextData) break
                index = block.end
            }
        }finally {
            this._stopProgress()
        }
    }
    __refresh = this._asyncAction(this.refresh)


    @boundMethod
    async _resetChart({labels=[], datasets=[]} = {}) {
        if (!this.ctx) return

        if (this.chart) {
            this.chart.destroy()
        }

        this.chart = new Chart(this.ctx, {
            type: this.horizontal ? 'horizontalBar' : 'bar',
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    colorschemes: {
                        scheme: Classic20,
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: this.apilar,
                    }],
                    yAxes: [{
                        stacked: this.apilar,
                    }]
                }
            }
        })
    }


    @boundMethod
    _updateChart(rows) {
        if (!this.chart) return

        const data = this.chart.data

        for (let row of rows) {

            const mesLabel = getAnualMesLabel(row[Liqtecmes.idMes])

            const tecLabel = row[Liqtecmes.tecnico][User.nombreCompleto]

            if (data.labels.indexOf(mesLabel) < 0) {
                data.labels.push(mesLabel)
            }

            let tecDataset = find(data.datasets, {label: tecLabel})
            if (!tecDataset) {
                const len = data.datasets.push({
                    ...this._serieProps,
                    label: tecLabel,
                    data: []
                })
                tecDataset = data.datasets[len - 1]
            }

            tecDataset.data.push(row[Liqtecmes.m2])
        }

    }


}