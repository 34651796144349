import {Piece} from 'sopix/piece/piece'
import {boundMethod} from 'autobind-decorator'
import {observable} from 'mobx'

export class ChartPiece extends Piece {

    chart
    
    canvas
    ctx

    /** @type {SearchPiece} */
    search
    Search = null

    Frame = null
    
    datasets = []

    @observable
    height='100%'


    @boundMethod
    async _afterRegisterCanvas(){
        
    }
    
    @boundMethod
    async registerCanvas(canvas) {
        this.canvas = canvas
        this.ctx = this.canvas.getContext('2d')
        await this._afterRegisterCanvas()
    }
    __registerCanvas = this._asyncAction(this.registerCanvas)
}