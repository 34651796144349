import {action, computed, observable, runInAction} from 'mobx'
import {InitState} from 'sopix/utils/InitState'
import {boundMethod} from 'autobind-decorator'
import {FieldsState} from 'sopix/react-state/fieldsState'
import {ObserverHub} from 'sopix/utils/observers'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {RowFetcher} from 'sopix/db-access/rowFetcher'

export class FormState extends FieldsState{

    @observable
    id

    onApplyObservers = new ObserverHub()
    
    /** @type {RowFetcher} */
    _rowFetcher

    /** @type {SaveMutation} */
    _saveMutation
    
    constructor(id, fields, {name='', idField, displayField, icon, deleteMutation, table, saveMutation, errorManager} = {}){
        super({name, icon, errorManager})
        
        // id = undefined -> new
        // id = null -> none
        runInAction(()=>{
            this.id = id
        })
        this._rowFetcher = new RowFetcher(table, fields)
        this.idField = idField
        this.displayField = displayField ? displayField : idField
        this._deleteMutation = !deleteMutation || !table ? undefined :
            new SimpleMutation(table.graphqlUrl, deleteMutation, ['success'])
        this._saveMutation = !saveMutation || !table ? undefined :
            new SaveMutation(table, saveMutation, {fields:fields})
    }

    @boundMethod
    _getTitle(empty) {
        if (empty) return `Crear ${this.name.toLowerCase()}`
        else return `${this.recordName} - ${this.name}`    
    }
    
    @computed
    get title(){
        if (this.initialized !== InitState.YES) return `Cargando ${this.name.toLowerCase()} ...`
        else return this._getTitle(this.id === undefined)
    }
    
    get recordName(){
        if (this.id === null) {
            return ''
        }
        const name = this.getField(this.displayField)
        return name ? name : `Crear ${this.name.toLowerCase()}`
    }
    
    async __init(){
        await this._apply(...await this._loadData(this.id))
    }

    async __new(){
        return [
            {},
            undefined
        ]
    }
    
    async __nullLoad(){
        return [
            {},
            null,
        ]
    }

    async __loadData(id){
        return [
            await this._rowFetcher.fetch({[this.idField]: id}),
            id
        ]
    }

    
    async _loadData(id){
        if (id === undefined) {
            return await this.__new()
        } else if (id === null) {
            return await this.__nullLoad()
        }else {
            return await this.__loadData(id)
        }
    }
    
    async _load(){
        await this._apply(...await this._loadData(this.id))
    }
    load = this._locked_catched_async(this._load)


    async __save(){
        return await this._saveMutation.query(this.fieldManager.fields,
            {initialData: this.fieldManager.initialFields})
    }
    
    @boundMethod
    async _save(){
        const result = await this.__save()
        if (result.success) {
            if (result.row) {
                const row = result.row
                await this._apply(row, row[this.idField])
            } else {
                runInAction(() => this.fieldManager.undirty())
            }
        } else {
            runInAction(() => this.fieldManager.setErrors(result.errorList))
        }
        return result.success
    }
    save = this._locked_catched_async(this._save)

    @boundMethod
    _afterApply(){
    }
    
    
    @boundMethod
    async __delete(){
        if (this.id === undefined) {
            throw new Error('Nada que borrar')
        }
        await this._deleteMutation.query({ids: [this.id]})
    }
    
    @boundMethod
    async _delete(){
        await this.__delete()
        await this._loadData()
    }
    delete = this._locked_catched_async(this._delete)
    
    
    @boundMethod
    _applyFields(data) {
        this.fieldManager.load(data)
    }
    
    @action.bound
    async _apply(data, id){
        this.id = id
        
        // null = invalid url
        if (id === null) {
            return
        }

        this._applyFields(data)
        if (id === undefined) {
            //New
            this.fieldManager.undirty({})
        }

        this._afterApply()
        this.onApplyObservers.notify(data)
    }
    
    _revert = () => {
        this.fieldManager.revert()
    }
    revert = this._catched(this._revert)
}