import {tableColumns} from 'sopix/db/TableColumns'
import {fields} from 'sopix/db/dbField'
import {DbFieldView} from 'sopix/db/dbFieldView'

export const columns = {}

export class DbColumn extends DbFieldView{
}

export class SimpleColumn extends DbColumn{
}


export class DateTimeColumn extends SimpleColumn{
}


export class DateColumn extends SimpleColumn{
    
}


export class LookupColumn extends DbColumn{
    constructor(fieldId, foreignTableId, foreignColumnId) {
        super(fieldId)
        this.foreignTableId = foreignTableId
        this.foreignColumnId = foreignColumnId
    }

    /** @returns {DbObject} */
    get field(){
        return fields[this.fieldId]
    }

    /** @returns {DbColumn} */
    get foreignColumn(){
        const tabCols = tableColumns 
        const cols = tableColumns[this.foreignTableId] 
        return cols[this.foreignColumnId]
    }

    get maxWidth(){
        return this.foreignColumn.maxWidth
    }

    get minWidth(){
        return this.foreignColumn.minWidth
    }

    get align(){
        return this.foreignColumn.align
    }
}