import {TrabajoView, TrabajoClase} from 'sop/db-config/row-consts'
import {sopIcons} from 'sop/sop-icons'

export function getTrabajoViewFromClase(clase){
    switch (clase) {
        case TrabajoClase.ESTUDIO:
        case TrabajoClase.VALORACION:
            return TrabajoView.COTIZACION
        case TrabajoClase.TAREA:
            return TrabajoView.TAREA
        default:
            return TrabajoView.DESCONOCIDO
    }
}

export function getTrabajoViewGraphqlFilter(view){
    switch (view) {
        case TrabajoView.COTIZACION:
            return {claseIn: [TrabajoClase.VALORACION, TrabajoClase.ESTUDIO]}
        case TrabajoView.TAREA:
            return {clase: TrabajoClase.TAREA}
        default:
            return {claseIsNull: true}
    }
}

export function getTrabajoViewName(view) {
    switch (view) {
        case TrabajoView.COTIZACION:
            return 'Cotización'
        case TrabajoView.TAREA:
            return 'Tarea'
        default:
            return 'Trabajo'
    }
}


export function getTrabajoViewIcon(view) {
    switch (view) {
        case TrabajoView.COTIZACION:
            return sopIcons.Cotizacion
        case TrabajoView.TAREA:
            return sopIcons.Tarea
        default:
            return sopIcons.Trabajo
    }
}

export function getTrabajoClaseIcon(clase) {
    switch (clase) {
        case TrabajoClase.ESTUDIO:
            return sopIcons.Estudio
        case TrabajoClase.VALORACION:
            return sopIcons.Valoracion
        case TrabajoClase.TAREA:
            return sopIcons.Tarea
        default:
            return sopIcons.Trabajo
    }
}

export function getTrabajoClaseName(clase) {
    switch (clase) {
        case TrabajoClase.ESTUDIO:
            return 'Estudio'
        case TrabajoClase.VALORACION:
            return 'Valoración'
        case TrabajoClase.TAREA:
            return 'Tarea'
        default:
            return 'Trabajo'
    }
}