import {WorldObject} from 'sopix/piece/worldObject'
import {boundMethod} from 'autobind-decorator'
import {action, observable, runInAction} from 'mobx'
import {sleep} from 'sopix/time/time-utils'
import {ALERT_DESCARTAR, cambiosSinGuardar} from 'sopix/alert/alert-defs'
import {askAlert} from 'sopix/alert/asyncAlert'

export class Poper extends WorldObject{

    acceptButton = 'Aceptar'
    _title
    _icon
    _hasButtons
    
    _askDiscard = true
    
    @observable
    _dirty = false
    
    /** @type {Piece} */
    _piece    
    
    @observable    
    _isOpen = false
    
    _resolve
    
    /**
     * @param {PieceWorld} world
     * @param {Piece} piece
     */
    constructor(piece, {title = undefined, icon = undefined,  
        hasButtons = true, askDiscard = true} = {}) {
        super(piece.world)
        this._title = title
        this._icon = icon
        this._piece = piece
        this._hasButtons = hasButtons
        this._askDiscard = askDiscard
    }

    get isOpen(){
        return this._isOpen
    }

    get hasButtons(){
        return this._hasButtons
    }
    
    @action.bound
    async open(){
        this._isOpen = true
    }
    __open = this._asyncAction(this.open)
    

    async openAndWait(){
        const promise = new Promise(resolve => {
            this._resolve = resolve
        })
        await this.open()
        
        return await promise
    }
    
    /** @type {Piece} */
    get piece(){
        return this._piece
    }
    
    get dirty(){
        return this._dirty
    }
    
    @action.bound
    setDirty(dirty){
        this._dirty = dirty
    }
    
    get title(){
        return this._title
    }
    
    get icon(){
        return this._icon
    }
    
    @boundMethod
    async _doAccept(){
        return true
    }


    @action.bound
    async close({force, accept} = {}){
        if (this.dirty && !force && this._askDiscard) {
            if (await askAlert(cambiosSinGuardar) !== ALERT_DESCARTAR) {
                return
            }
        }

        this.startProgress()
        try {
            runInAction(()=>{
                this._isOpen = false
            })
            await sleep(200)
            await this._closeActions()
        }finally{
            this.stopProgress()
        }
        
        this.errorManager.clear()
        
        if (this._resolve) {
            this._resolve(accept)
            this._resolve = undefined
        }
    }
    __close = this._asyncAction(this.close)


    @boundMethod
    async acceptClicked(){
        if (await this._doAccept()) {
            await this.close({accept: true})
        }
    }
    __acceptClicked = this._asyncAction(this.acceptClicked)

    @boundMethod
    async _closeActions() {
    }
}