import React, {forwardRef, useReducer} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {BlRowCell} from 'sopix/boundless-table/BlRowCell'
import {Field} from 'sopix/form/editors/Field'
import Typography from '@material-ui/core/Typography'
import {NumberField} from 'sopix/form/editors/NumberField'
import {action} from 'mobx'
import {observer} from 'mobx-react'
import {TipoTarea} from 'sop/db/TipoTarea'
import {find} from 'lodash-es'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {sopColors} from 'sop/sop-colors'


const useStyles = makeStyles(theme => ({
    cellContainer: {
        borderBottom: 'none',
    },
    cell: {
        alignItems: 'stretch',    
    },
    clase1: {
        background: sopColors.yellowLightBackground,
    },
    clase2: {
        background: sopColors.blueLightBackground,
    },
}))

const Row = forwardRef(({index, data, ...props}, ref) => {
    const cls = useStyles()
    const claseCls = cls[`clase${data[TipoTarea.clase]}`]

    return <BlRow ref={ref} className={claseCls} index={index} data={data} {...props} />
})


function Cell({children, rowId, ...otherProps}) {

    const cls = useStyles()

    return (
        <BlRowCell className={cls.cell} classes={{container: cls.cellContainer}} rowId={rowId} {...otherProps}>
            {children}
        </BlRowCell>
    )
}



const Content = observer(
/**
 * 
 * @param {ConfigLiquidacionState} efectivos
 * @param content
 * @param index
 * @param id
 * @param rowId
 * @returns {JSX.Element}
 */
({efectivos, content, index, id, rowId}) => {

    if (id === 'tipo') {
        return <Typography variant="subtitle2">{content}</Typography>
    }

    const row = find(efectivos.rows, {[TipoTarea.idTipo]: rowId})
    
    function handleChange(value) {
        row[id] = value
    }

    const common = {
        value: row[id],
        onChange: action(handleChange),
        disabled: !userAuth.isAllowed(Permiso.modificarConfiguracion),
    }

    let field
    switch(id){
        case TipoTarea.m2:
            field =<NumberField {...common} suffix=" m²" />
            break
        case TipoTarea.ratio:
            field = <NumberField {...common} suffix=" %" />
            break
        default:
            field = <Field {...common} />
    }
    return field
})



export const ConfigLiquidacion = observer(
/**
 *
 * @param {ConfigLiquidacionState} efectivos
 * @param direction
 * @returns {JSX.Element}
 * @constructor
 */
({efectivos, direction="rowReverse"}) => {
    
    function renderCellContent(props){
        return <Content efectivos={efectivos} {...props} />
    }

    return (
        <>
            <BlTable
                renderCellContent={renderCellContent}
                Row={Row}
                RowCell={Cell}
                dense={true}
                columns={efectivos.columns}
                data={efectivos.rows}
                keyColumnId={TipoTarea.idTipo}
                order={efectivos.order}
            />
        </>
    )
})
