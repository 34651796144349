 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const UserIds = Object.freeze({
    obrasAutor: Symbol('obrasAutor'),
    obrasModificacion: Symbol('obrasModificacion'),
    solicitudes: Symbol('solicitudes'),
    tareasAutor: Symbol('tareasAutor'),
    tareasResponsable: Symbol('tareasResponsable'),
    cuentasAutor: Symbol('cuentasAutor'),
    cuentasModificacion: Symbol('cuentasModificacion'),
    cuentasComercial: Symbol('cuentasComercial'),
    direccionesAutor: Symbol('direccionesAutor'),
    direccionesModificacion: Symbol('direccionesModificacion'),
    contactosAutor: Symbol('contactosAutor'),
    contactosModificacion: Symbol('contactosModificacion'),
    notas: Symbol('notas'),
    relacionadas: Symbol('relacionadas'),
    representantesRepresentante: Symbol('representantesRepresentante'),
    representantesAutor: Symbol('representantesAutor'),
    sesiones: Symbol('sesiones'),
    liquidacionesAutor: Symbol('liquidacionesAutor'),
    liquidacionesResponsable: Symbol('liquidacionesResponsable'),
    avisos: Symbol('avisos'),
    aprobaciones: Symbol('aprobaciones'),
    aproliqmans: Symbol('aproliqmans'),
    liqtecmeses: Symbol('liqtecmeses'),
    ejecucionesCuentas: Symbol('ejecucionesCuentas'),
    documentosAutor: Symbol('documentosAutor'),
    documentosUsuarioPlanos: Symbol('documentosUsuarioPlanos'),
    permisos: Symbol('permisos'),
    avisoSubscripciones: Symbol('avisoSubscripciones'),
    avisosPendientes: Symbol('avisosPendientes'),
    roleUsuarioObj: Symbol('roleUsuarioObj'),
    id: Symbol('id'),
    email: Symbol('email'),
    password: Symbol('password'),
    username: Symbol('username'),
    nombreCompleto: Symbol('nombreCompleto'),
    roleUsuario: Symbol('roleUsuario'),
    idAutor: Symbol('idAutor'),
    fechaCreacion: Symbol('fechaCreacion'),
    zona: Symbol('zona'),
    movil: Symbol('movil'),
    ausente: Symbol('ausente'),
    mueveCotizaciones: Symbol('mueveCotizaciones'),
    ausenteDesde: Symbol('ausenteDesde'),
    ausenteHasta: Symbol('ausenteHasta'),
    idModificacion: Symbol('idModificacion'),
    fechaModificacion: Symbol('fechaModificacion'),
    inactivo: Symbol('inactivo'),
    horas: Symbol('horas'),
    hashedPbkdf2: Symbol('hashedPbkdf2'),
    resetKey: Symbol('resetKey'),
    apiKey: Symbol('apiKey'),
    odooCambiado: Symbol('odooCambiado'),
    totpSecret: Symbol('totpSecret'),
    totpRequerido: Symbol('totpRequerido'),
    resetKeyTime: Symbol('resetKeyTime'),
    loginTryTime: Symbol('loginTryTime'),
    loginTryCount: Symbol('loginTryCount'),
    cnt: Symbol('cnt'),
    aclReadOnly: Symbol('aclReadOnly'),
})

export const User = Object.freeze({
    obrasAutor: 'obrasAutor',
    obrasModificacion: 'obrasModificacion',
    solicitudes: 'solicitudes',
    tareasAutor: 'tareasAutor',
    tareasResponsable: 'tareasResponsable',
    cuentasAutor: 'cuentasAutor',
    cuentasModificacion: 'cuentasModificacion',
    cuentasComercial: 'cuentasComercial',
    direccionesAutor: 'direccionesAutor',
    direccionesModificacion: 'direccionesModificacion',
    contactosAutor: 'contactosAutor',
    contactosModificacion: 'contactosModificacion',
    notas: 'notas',
    relacionadas: 'relacionadas',
    representantesRepresentante: 'representantesRepresentante',
    representantesAutor: 'representantesAutor',
    sesiones: 'sesiones',
    liquidacionesAutor: 'liquidacionesAutor',
    liquidacionesResponsable: 'liquidacionesResponsable',
    avisos: 'avisos',
    aprobaciones: 'aprobaciones',
    aproliqmans: 'aproliqmans',
    liqtecmeses: 'liqtecmeses',
    ejecucionesCuentas: 'ejecucionesCuentas',
    documentosAutor: 'documentosAutor',
    documentosUsuarioPlanos: 'documentosUsuarioPlanos',
    permisos: 'permisos',
    avisoSubscripciones: 'avisoSubscripciones',
    avisosPendientes: 'avisosPendientes',
    roleUsuarioObj: 'roleUsuarioObj',
    id: 'id',
    email: 'email',
    password: 'password',
    username: 'username',
    nombreCompleto: 'nombreCompleto',
    roleUsuario: 'roleUsuario',
    idAutor: 'idAutor',
    fechaCreacion: 'fechaCreacion',
    zona: 'zona',
    movil: 'movil',
    ausente: 'ausente',
    mueveCotizaciones: 'mueveCotizaciones',
    ausenteDesde: 'ausenteDesde',
    ausenteHasta: 'ausenteHasta',
    idModificacion: 'idModificacion',
    fechaModificacion: 'fechaModificacion',
    inactivo: 'inactivo',
    horas: 'horas',
    hashedPbkdf2: 'hashedPbkdf2',
    resetKey: 'resetKey',
    apiKey: 'apiKey',
    odooCambiado: 'odooCambiado',
    totpSecret: 'totpSecret',
    totpRequerido: 'totpRequerido',
    resetKeyTime: 'resetKeyTime',
    loginTryTime: 'loginTryTime',
    loginTryCount: 'loginTryCount',
    cnt: 'cnt',
    aclReadOnly: 'aclReadOnly',
})
