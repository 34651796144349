import React, {useState} from 'react'
import {useDidMount} from 'sopix/hooks/use-did-mount'
import {SesionPageState} from 'sop/componentes/sesion-S/sesionPageState'
import {ModelessFrame} from 'sopix/modal/ModelessFrame'
import {ErrorList} from 'sopix/errors/ErrorList'
import {WithComplexProgress} from 'sopix/progress/WithComplexProgress'
import {SesionForm} from 'sop/componentes/sesion-S/SesionForm'
import {observer} from 'mobx-react'
import {sopIcons} from 'sop/sop-icons'

export const SesionPage = observer(({...props}) => {
    /** @type {SesionPageState} */
    const [sesionPage] = useState(() => new SesionPageState())
    
    useDidMount(() => {
        sesionPage.initialize()
    })
    
    return (
        <>
            <ModelessFrame title="Sesión actual" Icon={sopIcons.Sesion} maxWidth={200}>
                <ErrorList errorManager={sesionPage.errorManager} />
                <WithComplexProgress
                    initialized={sesionPage.initialized}
                    locked={sesionPage.locked}
                    onReplay={() => sesionPage.initialize()}
                >
                    <SesionForm sesionPage={sesionPage} />
                </WithComplexProgress>
            </ModelessFrame>
        </>
    )
})