 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UserRoleIds} from './UserRole'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const UserRoleCol = Object.freeze({
    idRole : new SimpleColumn(UserRoleIds.idRole, 11, 11, Align.RIGHT),
    role : new SimpleColumn(UserRoleIds.role, 12, 10, Align.LEFT),
})

tableColumns[Tables.UserRole] = UserRoleCol
