import {StaticFiltrator} from 'sopix/filtrator/staticFiltrator'
import {orderData, tableColumnMapFromDb} from 'sopix/boundless-db/boundless-db-utils'
import {boundMethod} from 'autobind-decorator'
import {decodeColumnDescriptors, getColumnSortFormatter} from 'sopix/db/db-column-utils'
import {runInAction} from 'mobx'

export class TableFiltrator extends StaticFiltrator{

    /**
     * @param {DataSource} dataSource
     */
    constructor(world, dataSource, idField, columnDescriptors, {defaultFilter, defaultOrder} = {}) {

        super(world, dataSource, {defaultFilter, defaultOrder})
        this.idField = idField
        
        runInAction(()=>{
            this.setColumnDescriptors(columnDescriptors)
        })
    }

    setColumnDescriptors(columnDescriptors){
        this.dbColumns = decodeColumnDescriptors(columnDescriptors)
        this.sortifiers = {}
        this.formatters = {}
        for (let [id, dbCol] of this.dbColumns) {
            const defaulFormatter = getColumnSortFormatter(dbCol)
            this.formatters[id] = defaulFormatter
            this.sortifiers[id] = this._pickSortifier(id, dbCol, defaulFormatter)
        }

        this.columns = tableColumnMapFromDb(columnDescriptors, this._fixColumn)
    }
    
    
    @boundMethod
    _sortData(data, order) {
        if (order.length === 0) {
            return data
        } else {
            const dataForSort = this._runOverRows(data, this.dbColumns, this.sortifiers)
            return orderData(data, dataForSort, this.idField, order)
        }
    }

    /**
     * Un sortifier convirte el valor original a una versión ordenable
     * 
     * @param {DbColumn} dbCol
     */
    @boundMethod
    _sortify({id, value, row, rowId, index, dbCol, formatter}) {
        return formatter(value)
    }

    @boundMethod
    _getSortifier(id, dbCol, defaultFormatter){
        //se puede hacer override de esta y devolver undefined o calculator en vez de hacerlo celda a celda
        //return undefined
        return this._sortify
    }
    
    @boundMethod
    _pickSortifier(id, dbCol, defaultSortFormatter){
        const defaulSortifier = ({content, index, id, rowId, data}) => {
            return defaultSortFormatter(content)
        }
        const customSortifier = this._getSortifier(id, dbCol, defaultSortFormatter)
        return customSortifier || defaulSortifier
    }

    /**
     * @param {Map<String, DbColumn>} cols
     */
    _runOverRows(rows, dbCols, funcs){
        const result = []
        let index = 0
        for (let row of rows) {
            const outRow = {...row}
            for (let [id, dbCol] of dbCols.entries()){
                outRow[id] = funcs[id]({
                    id,
                    value: row[id],
                    row,
                    rowId: row[this.idField],
                    index: index++,
                    dbCol,
                    formatter: this.formatters[id],
                })
            }
            result.push(outRow)
        }
        return result
    }
    
}