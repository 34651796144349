import React from 'react'
import {BoundlessTable} from 'sopix/boundless/BoundlessTable'
import {BlTableContainer} from 'sopix/boundless-table/BlTableContainer'
import {defaultTo} from 'lodash-es'
import {BlTableBody} from 'sopix/boundless-table/BlTableBody'
import {BlHeader} from 'sopix/boundless-table/BlHeader'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {BlHeaderCell} from 'sopix/boundless-table/BlHeaderCell'
import {BlRowCell} from 'sopix/boundless-table/BlRowCell'

export function BlTable(props) {
    const {
        className, columns, data, keyColumnId, order, dense, hover, boundlessManager,
        onOrderChange, onColumnsChanged, onClick, selected, verticalAlign, global, fitContainer,
        TableContainer, Header, HeaderCell, renderHeaderContent, TableBody, Row, RowCell, renderCellContent
    } = props

    return <BoundlessTable
        TableContainer={defaultTo(TableContainer, BlTableContainer)}
        Header={defaultTo(Header, BlHeader)}
        HeaderCell={defaultTo(HeaderCell, BlHeaderCell)}
        renderHeaderContent={renderHeaderContent}
        TableBody={defaultTo(TableBody, BlTableBody)}
        Row={defaultTo(Row, BlRow)}
        RowCell={defaultTo(RowCell, BlRowCell)}
        renderCellContent={renderCellContent}
        {...props}
    />
}
