import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import React, {createRef, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import {Tooltip} from '@material-ui/core'

let dialogId = 0


function PaperComponent({id, ...props}) {
    const nodeRef = createRef()
    return (
        <Draggable nodeRef={nodeRef} handle={`#${id}-title`} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}


/**
 * @param {AlertDefinition} alert
 */
export const CustomAlert = ({alert, onClose}) => {

    const [id] = useState(() => `ok-cancel-alert-${++dialogId}`)

    return (
        <Dialog
            open={!!alert}
            onClose={() => onClose(alert.cancelAction)}
            PaperComponent={PaperComponent}
            PaperProps={{id: id}}
            aria-labelledby={`${id}-title`}
            aria-describedby={`${id}-description`}
        >
            <DialogTitle id={`${id}-title`}>{alert.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id={`${id}-description`}>
                    {alert.body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {(()=>{
                    const result = []
                    let i = 0
                    for (let [id, action] of [...alert.actions].reverse()){
                        const button =                             
                            <Button
                                key={++i}
                                onClick={() => onClose(id)}
                                color="primary"
                                autoFocus={id === alert.defaultAction}
                                defaultChecked={id === alert.defaultAction}
                            >
                                {action.caption}
                            </Button>

                        result.push(action.hint
                            ?   <Tooltip title={action.hint} >
                                    {button}
                                </Tooltip>
                            
                            :   button
                        )
                    }
                    return result
                })()}
            </DialogActions>
        </Dialog>
    )
}