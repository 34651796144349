import {keyBy} from 'lodash-es'


export function extractChangedFromRow(row, initialRow, idFields) {
    if (initialRow === undefined) {
        return row
    }
    
    if (idFields.length !== 1) {
        throw new Error('1 single key expected')    
    }
    const idField = idFields[0]
    
    //New record save all
    if (row[idField] === undefined) {
        return row
    }
    
    const result = {}
    for (let [key, value] of Object.entries(row)){
        const jsonValue = JSON.stringify(value)
        const jsonInitial = JSON.stringify(initialRow[key])
        
        if (jsonValue !== jsonInitial || key === idField) {
            result[key] = value
        }
    }
    return result
}

export function extractChangedFromRows(rows, initialRows, idFields){
    if (idFields.length !== 1) {
        throw new Error('1 single key expected')
    }
    const idField = idFields[0]
    
    //Changed
    const initial = keyBy(initialRows, idField)
    const changed = []
    for (let row of rows){
        const initialRow = initial[row[idField]]
        
        if (JSON.stringify(row) !== JSON.stringify(initialRow)){
            changed.push(extractChangedFromRow(row, initialRow, idFields))
        }
    }
    
    const rowMapping = keyBy(rows, idField)
    const deleted = []
    for (let initialRow of initialRows) {
        const id = initialRow[idField] 
        const row = rowMapping[id]
        
        //deleted
        if (row === undefined) {
            deleted.push(id)
        }
    }

    return [changed, deleted]
}

export function getChangedFields(table, row, initialRow){
    const keyFieldNames = table.keyFields.map(field => field.fieldName)
    return extractChangedFromRow(row, initialRow, keyFieldNames)
}


export function getChangedFieldsForRows(table, rows, initialRows){
    const keyFieldNames = table.keyFields.map(field => field.fieldName)
    return extractChangedFromRows(rows, initialRows, keyFieldNames)
}