 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaSistemaIds} from './TareaSistema'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const TareaSistemaEdt = Object.freeze({
    idTarea : new SimpleEditor(TareaSistemaIds.idTarea, 11, 11),
    idSistema : new SimpleEditor(TareaSistemaIds.idSistema, 11, 11),
})

tableEditors[Tables.TareaSistema] = TareaSistemaEdt
