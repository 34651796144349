import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {Field} from 'sopix/form/editors/Field'
import {NumberField} from 'sopix/form/editors/NumberField'
import {ObraEdt} from 'sop/db/ObraEditors'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {ProvinciaEdt} from 'sop/db/ProvinciaEditors'
import {Provincia} from 'sop/db/Provincia'
import {DateField} from 'sopix/form/editors/DateField'
import {Separator} from 'sopix/formComps/Separator'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {ProvinciaFld, ProvinciaTable} from 'sop/db/ProvinciaTable'
import {runInAction} from 'mobx'
import {Push} from 'sopix/formComps/Push'
import {BoolField} from 'sopix/form/editors/BoolField'
import {usePiece} from 'sopix/piece/use-piece'


export class ObraSearchPiece extends SearchPiece{
    _listFetcher = new ListFetcher([
        ObraEdt.tipologia.getListQueryEntry(),
        ObraEdt.status.getListQueryEntry(),
    ])

    _provinciasQL = new TableGraphql(ProvinciaTable, {cached: true})

    provincias

    async _init() {
        const [lists, provincias] = await Promise.all([
            this._listFetcher.fetch(),
            this._provinciasQL.query([
                ProvinciaFld.cp,
                ProvinciaFld.provincia,
            ]),
        ])
        runInAction(async () => {
            this.lists = lists
            this.provincias = provincias.rows
            await this.validate()
        })
    }
}


/**
 * 
 * @param {ObraSearchPiece} search
 * @returns {JSX.Element}
 * @constructor
 */
export const ObraSearch = ({search, extra}) => {

    usePiece(search)


    return (
        <PieceFrame piece={search} renderPiece={()=> {

            const fld = search.fieldHelper
            const man = search.fieldManager
            const _____ = <Separator wide />

            return (
                <>
                    <Field manager={man} name="buscar" label="buscar" autoFocus/>
                    {_____}

                    <NumberField {...fld.edt(ObraEdt.m2)} suffix=" m²" disabled={false}/>
                    <NumberField manager={man} name="m2Rango" label="margen" width={40} prefix="±" suffix="%" />
                    {_____}

                    <Field {...fld.edt(ObraEdt.cp)}  disabled={false}/>
                    <DropdownField
                        {...fld.edt(ProvinciaEdt.provincia)}
                        idField={Provincia.cp}
                        displayField={Provincia.provincia}
                        options={search.provincias}
                        name="cpProvincia"
                        disabled={false}
                    />
                    {_____}

                    <DateField {...fld.edt(ObraEdt.fechaAltaOnlyDate)} name="altaDesde" label="alta: desde" 
                               disabled={false}/>
                    <DateField {...fld.edt(ObraEdt.fechaAltaOnlyDate)} name="altaHasta" label="hasta"
                               disabled={false}/>
                    {_____}

                    <DropdownField {...fld.edt(ObraEdt.tipologia)}  disabled={false}/>
                    <DropdownField {...fld.edt(ObraEdt.status)}  disabled={false}/>
                    <BoolField {...fld.edt(ObraEdt.obraRepresentativa)}  disabled={false}/>
                    <BoolField {...fld.edt(ObraEdt.passiveHouse)}  disabled={false}/>
                    <Push/>
                    {extra || null}
                </>
            )

        }} />
    )
}