 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproasignarIds} from './Aproasignar'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AproasignarEdt = Object.freeze({
    idAproasignar : new SimpleEditor(AproasignarIds.idAproasignar, 11, 11),
    excluidos : new SimpleEditor(AproasignarIds.excluidos, 40, 16),
    valoracionesPorTecnico : new SimpleEditor(AproasignarIds.valoracionesPorTecnico, 11, 11),
    sorteadas : new SimpleEditor(AproasignarIds.sorteadas, 11, 11),
    justificacion : new SimpleEditor(AproasignarIds.justificacion, 40, 16),
})

tableEditors[Tables.Aproasignar] = AproasignarEdt
