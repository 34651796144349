import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {AvisoFld, AvisoTable} from 'sop/db/AvisoTable'
import {boundMethod} from 'autobind-decorator'
import {Aviso} from 'sop/db/Aviso'
import {observable, runInAction} from 'mobx'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'

export class AvisosCheck{

    _avisosQL = new TableGraphql(AvisoTable)

    @observable
    hayAvisos = 0

    @boundMethod
    async refresh(){
        if (!userAuth.isAllowed(Permiso.verAvisos)) {
            return
        }
        
        const result = await this._avisosQL.query([AvisoFld.idAviso], {first: 1},
            {[Aviso.pendiente]: true})
        runInAction(()=>{
            this.hayAvisos = result.rows.length
        })
    }
    
    @boundMethod
    getBadgeProps(){
        return {
            badge: this.hayAvisos,
            badgeVariant: 'dot',
        }
    }
}

export const avisosCheck = new AvisosCheck()
