 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const LiqtecmesIds = Object.freeze({
    mes: Symbol('mes'),
    tecnico: Symbol('tecnico'),
    idLiqtecmes: Symbol('idLiqtecmes'),
    idMes: Symbol('idMes'),
    idTecnico: Symbol('idTecnico'),
    m2: Symbol('m2'),
})

export const Liqtecmes = Object.freeze({
    mes: 'mes',
    tecnico: 'tecnico',
    idLiqtecmes: 'idLiqtecmes',
    idMes: 'idMes',
    idTecnico: 'idTecnico',
    m2: 'm2',
})
