 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SolicitudIds} from './Solicitud'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {CuentaIds} from './Cuenta'
import {DireccionIds} from './Direccion'
import {ContactoIds} from './Contacto'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const SolicitudTable = new DbTable(
    Tables.Solicitud,
    'Solicitudes',
    graphqlUrl,
    'Solicitud',
    [SolicitudIds.idSolicitud],
    [SolicitudIds.idSolicitud, SolicitudIds.idCuenta, SolicitudIds.idDireccion, SolicitudIds.idContacto, SolicitudIds.idObra, SolicitudIds.fechaCreacion, SolicitudIds.idAutor, SolicitudIds.tipoCuenta, SolicitudIds.idTipo, SolicitudIds.odooCambiado, SolicitudIds.odooDireccionTecnicoId, SolicitudIds.odooCuentaCotizaciones],
    [SolicitudIds.cuenta, SolicitudIds.direccion, SolicitudIds.contacto, SolicitudIds.obra, SolicitudIds.autor],
    [SolicitudIds.notas, SolicitudIds.documentos, SolicitudIds.tareaList],
    ['verSolicitantes'],
    ['modificarSolicitantes'],
    undefined,
    undefined,
    [],
    [],
)


export const SolicitudFld = Object.freeze({
    notas : new DbObjectList(Tables.Solicitud, SolicitudIds.notas, Tables.Nota),
    documentos : new DbObjectList(Tables.Solicitud, SolicitudIds.documentos, Tables.Documento),
    tareaList : new DbObjectList(Tables.Solicitud, SolicitudIds.tareaList, Tables.Tarea),
    cuenta : new DbObject(Tables.Solicitud, SolicitudIds.cuenta, SolicitudIds.idCuenta, 'Cuenta'),
    direccion : new DbObject(Tables.Solicitud, SolicitudIds.direccion, SolicitudIds.idDireccion, 'Direccion'),
    contacto : new DbObject(Tables.Solicitud, SolicitudIds.contacto, SolicitudIds.idContacto, 'Contacto'),
    obra : new DbObject(Tables.Solicitud, SolicitudIds.obra, SolicitudIds.idObra, 'Obra'),
    autor : new DbObject(Tables.Solicitud, SolicitudIds.autor, SolicitudIds.idAutor, 'Autor'),
    idSolicitud : new DbIntId(Tables.Solicitud, SolicitudIds.idSolicitud, 'Id solicitud'),
    idCuenta : new DbObjectId(Tables.Solicitud, SolicitudIds.idCuenta, 'Id cuenta', {foreignTableId: Tables.Cuenta, foreignKeyFieldId: CuentaIds.idCuenta}),
    idDireccion : new DbObjectId(Tables.Solicitud, SolicitudIds.idDireccion, 'Id direccion', {foreignTableId: Tables.Direccion, foreignKeyFieldId: DireccionIds.idDireccion}),
    idContacto : new DbObjectId(Tables.Solicitud, SolicitudIds.idContacto, 'Id contacto', {foreignTableId: Tables.Contacto, foreignKeyFieldId: ContactoIds.idContacto}),
    idObra : new DbObjectId(Tables.Solicitud, SolicitudIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    fechaCreacion : new DbDateTime(Tables.Solicitud, SolicitudIds.fechaCreacion, 'Fecha creacion'),
    idAutor : new DbObjectId(Tables.Solicitud, SolicitudIds.idAutor, 'Id autor', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    tipoCuenta : new DbText(Tables.Solicitud, SolicitudIds.tipoCuenta, 'Tipo cuenta', 30),
    idTipo : new DbInt(Tables.Solicitud, SolicitudIds.idTipo, 'Id tipo'),
    odooCambiado : new DbInt(Tables.Solicitud, SolicitudIds.odooCambiado, 'Odoo cambiado'),
    odooDireccionTecnicoId : new DbInt(Tables.Solicitud, SolicitudIds.odooDireccionTecnicoId, 'Odoo direccion tecnico id'),
    odooCuentaCotizaciones : new DbInt(Tables.Solicitud, SolicitudIds.odooCuentaCotizaciones, 'Odoo cuenta cotizaciones'),
})

registerTableFields(Tables.Solicitud, SolicitudFld) 
