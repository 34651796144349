import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'

const useStyles = makeStyles(() => ({
    modalContent: {
        display: 'flex',
        flexDirection: 'column',

        padding: '0px 24px 24px',
    },
})) 

export function ModalContent({children}) {
    
    const cls = useStyles()
    
    return (
        <DialogContent className={cls.modalContent}>
            {children}                
        </DialogContent>
    )
}
