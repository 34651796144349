import {useEffect, useReducer} from 'react'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('BoundlessBody')

export function Body({bodyRef, render, register, unregister, afterRender, onWidthChange}) {

    const [, doRender] = useReducer(state => state + 1, 0, undefined)

    useEffect(() => {
        register(doRender)

        return () => unregister()
    }, [register, unregister])

    useEffect(afterRender)

    useEffect(() => {

        const domBody = bodyRef.current

        let lastSize

        function sizeChanged() {
            if (lastSize === domBody.clientWidth) return
            lastSize = domBody.clientWidth
            onWidthChange(lastSize)
            debug(() => `on body height change: ${lastSize}px`)
        }

        let sizeObserver
        try {
            sizeObserver = new ResizeObserver(sizeChanged)
            sizeObserver.observe(domBody)
        }catch(e){
            window.log(e)
        }

        return () => {
            sizeObserver.unobserve(domBody)
        }

    }, [bodyRef, onWidthChange])

    return render(doRender)
}