import {intersection, isArray} from 'lodash-es'
import {action, observable} from 'mobx'
import {boundMethod} from 'autobind-decorator'
import {authSingletons} from 'sopix/session/auth-singletons'

export const ANONYMOUS_USER = 'Anónimo' 
export const UNKNOWN_USER = 'Desconocido'

export const AuthUser = Object.freeze({
    UNKNOWN: Symbol('unknown'),
    ANONYMOUS: Symbol('anonymous'),
}) 

export class UserAuth {
    
    // undefined = sin actualizar. null = anonymous
    @observable
    userId = AuthUser.UNKNOWN
    
    @observable
    username = UNKNOWN_USER
    
    @observable
    permissions = {}
    
    @observable
    access
    
    @observable
    accessId
    
    @observable
    permissionGroups
    
    @observable
    expiry
    
    @observable
    role

    @observable
    knownDevice

    @action
    logout(){
        this.userId = AuthUser.ANONYMOUS
        this.username = ANONYMOUS_USER
        this.permissions = {}
        this.permissionGroups = undefined
        this.expiry = undefined
        this.role = undefined
        authSingletons.clear()
    }

    get pemissionGroupsAsText(){
        if (!this.permissionGroups){
            return ''
        } else {
            return this.permissionGroups.join(' | ')
        }
    }
    
    get permissionList(){
        const result = []
        for (let [key, value] of Object.entries(this.permissions)){
            if (value === 1) {
                result.push(key)
            }
        }
        return result
    }
    
    @boundMethod
    isAllowed(requiredPermissions){
        if (this.userId === AuthUser.UNKNOWN) return false
        const requiredPermissionList = isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions] 
        return requiredPermissionList
            && intersection(requiredPermissionList, this.permissionList).length === requiredPermissionList.length
    }
    
    @action
    update(userId, username, permissions, permissionGroups, expiry, role, knownDevice){
        this.userId = userId === null ? AuthUser.ANONYMOUS : userId
        this.username = username === null ? ANONYMOUS_USER : username
        this.permissions = permissions
        this.permissionGroups = permissionGroups
        this.expiry = expiry
        this.role = role
        this.knownDevice = knownDevice

        return this
    }
    
}

export const userAuth = new UserAuth()
