 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DispositivoIds} from './Dispositivo'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbBinary, DbDateTime, DbIntId} from 'sopix/db/dbField'

export const DispositivoTable = new DbTable(
    Tables.Dispositivo,
    'Dispositivos',
    graphqlUrl,
    'Dispositivo',
    [DispositivoIds.idDispositivo],
    [DispositivoIds.idDispositivo, DispositivoIds.token, DispositivoIds.fechaCreacion, DispositivoIds.fechaFin],
    [],
    [],
    undefined,
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const DispositivoFld = Object.freeze({
    idDispositivo : new DbIntId(Tables.Dispositivo, DispositivoIds.idDispositivo, 'Id dispositivo'),
    token : new DbBinary(Tables.Dispositivo, DispositivoIds.token, 'Token'),
    fechaCreacion : new DbDateTime(Tables.Dispositivo, DispositivoIds.fechaCreacion, 'Fecha creacion'),
    fechaFin : new DbDateTime(Tables.Dispositivo, DispositivoIds.fechaFin, 'Fecha fin'),
})

registerTableFields(Tables.Dispositivo, DispositivoFld) 
