import {Typography} from '@material-ui/core'
import React from 'react'


export function getDropdownBoldLineRender(idField, displayField) {
    return (row) => {
        const id = row[idField]
        const display = row[displayField]
        if (id < 0) {
            return <Typography variant="h6">{display}</Typography>
        } else {
            return display
        }
    }
}
