import {WorldObject} from 'sopix/piece/worldObject'
import {ObserverHub} from 'sopix/utils/observers'
import {action, observable} from 'mobx'
import {boundMethod} from 'autobind-decorator'

export class DataSource extends WorldObject {
    
    @observable.ref
    _data = []

    onSetData = new ObserverHub()
    onClearData = new ObserverHub()
    onRefresh = new ObserverHub()
    
    get data(){
        return this._data
    }

    @action
    _setData(data){
        this._data = data
        this.onSetData.notify(data)    
    }
    
    @boundMethod
    async _doRefresh(){}
    
    @boundMethod
    async refresh() {
        await this._doRefresh()
        this.onRefresh.notify()
    }
    __refresh = this._asyncAction(this.refresh)

    
    @boundMethod
    async _doClearData(){
        this._setData([])
    }
    
    @boundMethod
    async clearData() {
        if (!this.data.length) return
        await this._doClearData()
        this.onClearData.notify(this)
    }
    
}

