 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AnualMesIds} from './AnualMes'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AnualMesEdt = Object.freeze({
    idMes : new SimpleEditor(AnualMesIds.idMes, 11, 11),
    anoNo : new SimpleEditor(AnualMesIds.anoNo, 11, 11),
})

tableEditors[Tables.AnualMes] = AnualMesEdt
