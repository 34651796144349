 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageEmpresaIds} from './SageEmpresa'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const SageEmpresaEdt = Object.freeze({
    codigoEmpresa : new SimpleEditor(SageEmpresaIds.codigoEmpresa, 11, 11),
    empresa : new SimpleEditor(SageEmpresaIds.empresa, 40, 16),
    anagrama : new SimpleEditor(SageEmpresaIds.anagrama, 40, 16),
})

tableEditors[Tables.SageEmpresa] = SageEmpresaEdt
