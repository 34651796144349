import {sopAppStage} from 'sop/sop'
import {AppStage} from 'sopix/app/app-utils'

function getUploadUrl(){
    switch (sopAppStage) {
        case AppStage.STAGING:
            return 'https://devsop.polytherm.es/upload'
        case AppStage.PRODUCTION:
            return 'https://sop.polytherm.es/upload'
        default:
            return 'https://devsop.asix.cima/upload'
    }
}

export const uploadUrl = getUploadUrl()


export const DocumentoAction = Object.freeze({
    ENVIAR_PLANOS: 'enviarPlanos',
    ACTIVAR_SEGUIMIENTO: 'activarSeguimiento',
    DESACTIVAR_SEGUIMIENTO: 'desactivarSeguimiento',
})
