 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaSistemaIds} from './TareaSistema'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const TareaSistemaCol = Object.freeze({
    idTarea : new SimpleColumn(TareaSistemaIds.idTarea, 11, 11, Align.RIGHT),
    idSistema : new SimpleColumn(TareaSistemaIds.idSistema, 11, 11, Align.RIGHT),
})

tableColumns[Tables.TareaSistema] = TareaSistemaCol
