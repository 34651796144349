 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const UploadIds = Object.freeze({
    idUpload: Symbol('idUpload'),
    path: Symbol('path'),
})

export const Upload = Object.freeze({
    idUpload: 'idUpload',
    path: 'path',
})
