 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaEstadoIds} from './TareaEstado'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const TareaEstadoEdt = Object.freeze({
    idEstado : new SimpleEditor(TareaEstadoIds.idEstado, 11, 11),
    estado : new SimpleEditor(TareaEstadoIds.estado, 13, 10),
    finalizado : new SimpleEditor(TareaEstadoIds.finalizado, 11, 11),
    verbo : new SimpleEditor(TareaEstadoIds.verbo, 30, 12),
})

tableEditors[Tables.TareaEstado] = TareaEstadoEdt
