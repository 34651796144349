import {sopIcons} from 'sop/sop-icons'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {AproliqmanFld, AproliqmanTable} from 'sop/db/AproliqmanTable'
import {Aproliqman} from 'sop/db/Aproliqman'
import {observer} from 'mobx-react'
import {Fields} from 'sopix/formComps/Fields'
import {NumberField} from 'sopix/form/editors/NumberField'
import {Field} from 'sopix/form/editors/Field'
import React, {useEffect} from 'react'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {AproliqmanEdt} from 'sop/db/AproliqmanEditors'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {boundMethod} from 'autobind-decorator'
import {ListFetcher, ListQueryEntry} from 'sopix/db-access/listFetcher'
import {AproFormPiece} from 'sop/componentes/aprobacion/aproFormPiece'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {User} from 'sop/db/User'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {UserFld} from 'sop/db/UserTable'
import {RolesUsuario} from 'sop/db-config/row-consts'
import {Permiso} from 'sop/db/Permiso'
import {userAuth} from 'sopix/session/userAuth'
import {usePiece} from 'sopix/piece/use-piece'

export class AproliqmanFormPiece extends AproFormPiece{

    permisoAdmin = Permiso.aprobarLiquidacionManual
    sePuedeCorregir = true

    _listFetcher = new ListFetcher([
    new ListQueryEntry(
        {sort: calcGraphqlOrder(User.nombreCompleto, OrderDirection.ASC)},
[UserFld.id, UserFld.nombreCompleto, UserFld.username], 
        {filters: {
            [User.inactivo]: 0,
            [User.roleUsuario]: RolesUsuario.TECNICO,
        }}
    ),
])
    
    constructor(world) {

        const FIELDS = [
            ...AproliqmanTable.regularFields,
            [AproliqmanFld.aprobacion, [AprobacionFld.estado]],
        ]
        
        const TABLE = AproliqmanTable
        super(world,
            FIELDS,
            {
                name: 'Solicitar liquidación manual',
                icon: sopIcons.Liquidacion,
                table: TABLE,
                idField: Aproliqman.idAproliqman,
                saveMutation: 'SaveAproliqman',
            },
        )
        
        this._actionMutation = new SimpleMutation(graphqlUrl, 'AproliqmanAction', ['success'])
        
        if (userAuth.role === RolesUsuario.TECNICO) {
            this.newRowFields[Aproliqman.idResponsable] = userAuth.userId
        }
    }

    @boundMethod
    async _init() {
        this.setLists(await this._listFetcher.fetch())
    }

    @boundMethod
    _getTitle(empty) {
        return this.name
    }
}




export const AproliqmanForm = observer(
    /**
     * @param {AproliqmanFormPiece} form
     */
    ({form, editable}) => {

        usePiece(form)

        return (
            <PieceFrame piece={form} renderPiece={()=> {

                const H = form.fieldHelper
                const fld = form.getField

                if (editable !== undefined){
                    form.fieldManager.setReadOnly(!editable)
                }
                
                return(
                    <>
                        <Fields>
                            <DropdownField {...H.edt(AproliqmanEdt.responsable)} 
                                           disabled={!userAuth.isAllowed(Permiso.aprobarLiquidacionManual)} />
                            <NumberField {...H.edt(AproliqmanEdt.m2Efectivos)} suffix=" m²" />
                            <Field {...H.edt(AproliqmanEdt.justificacion)} multiline rows={10} width={1000} />
                        </Fields>
                    </>
                )
            }} />
        )
})
