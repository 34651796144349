 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ObraIds} from './Obra'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const ObraCol = Object.freeze({
    tipologia : new LookupColumn(ObraIds.tipologia, Tables.Tipologia, 'tipologia'),
    status : new LookupColumn(ObraIds.status, Tables.Status, 'status'),
    autor : new LookupColumn(ObraIds.autor, Tables.User, 'nombre_completo'),
    modificacion : new LookupColumn(ObraIds.modificacion, Tables.User, 'nombre_completo'),
    idObra : new SimpleColumn(ObraIds.idObra, 11, 11, Align.RIGHT),
    cod : new SimpleColumn(ObraIds.cod, 10, 10, Align.LEFT),
    nombre : new SimpleColumn(ObraIds.nombre, 60, 24, Align.LEFT),
    m2 : new SimpleColumn(ObraIds.m2, 11, 11, Align.RIGHT),
    direccion : new SimpleColumn(ObraIds.direccion, 40, 16, Align.LEFT),
    cp : new SimpleColumn(ObraIds.cp, 7, 7, Align.LEFT),
    localidad : new SimpleColumn(ObraIds.localidad, 40, 16, Align.LEFT),
    provincia : new SimpleColumn(ObraIds.provincia, 40, 16, Align.LEFT),
    pais : new SimpleColumn(ObraIds.pais, 7, 7, Align.LEFT),
    fechaAlta : new DateTimeColumn(ObraIds.fechaAlta, 16, 16, Align.LEFT),
    fechaAltaOnlyDate : new DateColumn(ObraIds.fechaAlta, 11, 11, Align.LEFT),
    fechaModificacion : new DateTimeColumn(ObraIds.fechaModificacion, 16, 16, Align.LEFT),
    fechaModificacionOnlyDate : new DateColumn(ObraIds.fechaModificacion, 11, 11, Align.LEFT),
    lat : new SimpleColumn(ObraIds.lat, 20, 10, Align.LEFT),
    lng : new SimpleColumn(ObraIds.lng, 20, 10, Align.LEFT),
    altitud : new SimpleColumn(ObraIds.altitud, 11, 11, Align.RIGHT),
    locked : new SimpleColumn(ObraIds.locked, 16, 16, Align.LEFT),
    contadorEstudios : new SimpleColumn(ObraIds.contadorEstudios, 11, 11, Align.RIGHT),
    contadorTareas : new SimpleColumn(ObraIds.contadorTareas, 11, 11, Align.RIGHT),
    contadorValoraciones : new SimpleColumn(ObraIds.contadorValoraciones, 11, 11, Align.RIGHT),
    contadorCambioOferta : new SimpleColumn(ObraIds.contadorCambioOferta, 11, 11, Align.RIGHT),
    fechaEjecucion : new DateTimeColumn(ObraIds.fechaEjecucion, 16, 16, Align.LEFT),
    fechaEjecucionOnlyDate : new DateColumn(ObraIds.fechaEjecucion, 11, 11, Align.LEFT),
    passiveHouse : new SimpleColumn(ObraIds.passiveHouse, 11, 11, Align.RIGHT),
    obraRepresentativa : new SimpleColumn(ObraIds.obraRepresentativa, 11, 11, Align.RIGHT),
    odooCambiado : new SimpleColumn(ObraIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Obra] = ObraCol
