import React, {forwardRef} from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {Bar} from 'sopix/formComps/Bar'
import {Direction} from 'sopix/formComps/comp-classes'
import {Push} from 'sopix/formComps/Push'
import {Frame} from 'sopix/formComps/Frame'
import {CuentaFld} from 'sop/db/CuentaTable'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {DocumentoFld, DocumentoTable} from 'sop/db/DocumentoTable'
import {DocumentoCol} from 'sop/db/DocumentoColumns'
import {UserCol} from 'sop/db/UserColumns'
import {Cuenta} from 'sop/db/Cuenta'
import {Documento} from 'sop/db/Documento'
import {SolicitudFld} from 'sop/db/SolicitudTable'
import {TareaFld} from 'sop/db/TareaTable'
import {TareaCol} from 'sop/db/TareaColumns'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {Solicitud} from 'sop/db/Solicitud'
import {Tarea} from 'sop/db/Tarea'
import {UserFld} from 'sop/db/UserTable'
import {CuentaCol} from 'sop/db/CuentaColumns'
import {boundMethod} from 'autobind-decorator'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {sopColors} from 'sop/sop-colors'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {icons} from 'sopix/icon/icons'
import {linkSubsetDbFiltratorForm} from 'sopix/piece-linkers/subsetdb-filtrator-form'
import {DocumentoForm, DocumentoFormPiece} from 'sop/componentes/documento/DocumentoForm'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {Tag} from 'sopix/formComps/Tag'
import {Separator} from 'sopix/formComps/Separator'
import {action, observable} from 'mobx'
import {FileList, FileListPiece} from 'sop/componentes/documento/FileList'
import {DocumentoPainter, LEFT_BUTTONS, RIGHT_BUTTONS} from 'sop/componentes/documento/documentoPainter'
import {DocumentoFiltrator, FILTER_ENABLED} from 'sop/componentes/documento/documentoFiltrator'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {Permiso} from 'sop/db/Permiso'
import {userAuth} from 'sopix/session/userAuth'
import {PlanoEstadoFld} from 'sop/db/PlanoEstadoTable'
import {AproObjectInfo} from 'sop/componentes/aprobacion/aproObjectInfo'
import {AproplanoFld} from 'sop/db/AproplanoTable'
import {AproPoper} from 'sop/componentes/aprobacion/aproPoper'
import {AproplanoForm, AproplanoFormPiece} from 'sop/componentes/documento/AproplanoForm'
import {AproPoperFrame} from 'sop/componentes/aprobacion/AproPoperFrame'
import {DocumentoAction} from 'sop/componentes/documento/documento-consts'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {TipoTarea} from 'sop/db/TipoTarea'
import {TipoTareaFld} from 'sop/db/TipoTareaTable'
import {ALERT_CANCEL, ALERT_ELIMINAR, ALERT_OK} from 'sopix/alert/alert-defs'


export class DocumentoListPiece extends Piece{

    idField = Documento.idDocumento

    @observable
    /** @type {FileListPiece} */
    _fileList
    
    
    /** @type {DocumentoFiltrator} */
    filtrator

    /** @type {AproObjectInfo} */
    selectedInfo


    _documentoAction = new SimpleMutation(graphqlUrl, 'DocumentoAction', ['success'])


    /**
     * @param {PieceWorld} world
     */
    constructor(world) {
        super(world)
        
        const FIELDS = [
            ...DocumentoTable.regularFields,
            [DocumentoFld.cotizacion, [
                TareaFld.ref,
                [TareaFld.tipo, [TipoTareaFld.clase]],
            ]],
            [DocumentoFld.solicitud, [
                [SolicitudFld.cuenta, [CuentaFld.cuenta]],
            ]],
            [DocumentoFld.autor, [UserFld.nombreCompleto]],
            [DocumentoFld.planosEstado, [PlanoEstadoFld.estado]],
            [DocumentoFld.aproplano, [AproplanoFld.idAproplano, AproplanoFld.aprobacion]],
        ]

        const COLUMNS = [
            LEFT_BUTTONS,
            [DocumentoFld.autor, UserCol.nombreCompleto],
            [DocumentoFld.cotizacion, TareaCol.ref],
            DocumentoCol.documento,
            [DocumentoFld.solicitud, CuentaCol.cuenta],
            DocumentoCol.fechaCreacion,
            DocumentoCol.planosEstado,
            RIGHT_BUTTONS,
        ]

        this.dbSource = new SubsetDbSource(world,
            Documento.idObra,
            new TableGraphql(DocumentoTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(Documento.fechaModificacion, OrderDirection.DESC)},
            })

        this.painter = new DocumentoPainter(world, COLUMNS)
        
        this.selection = new RowSelection(world, this.dbSource, this.idField, {deselectOnClick: true})
        
        this.load = this._state(Symbol('LOAD'), async id => {
            await this.dbSource.loadSubset(id)
        })
        
        this.selectedInfo = new AproObjectInfo()

        this.selection.onRowSelected.subscribe(async ({id}) => {
            if (!id) {
                this.selectedInfo._id = undefined
                this.selectedInfo._fields = undefined
            } else {
                this.selectedInfo._id = this.selection.id
                this.selectedInfo._fields = this.selection.row
            }
            this.setFileListId(id)
        })
        
        this.form = new DocumentoFormPiece(world.cloneWithOwnErrors())

        this.poper = new FormPoper(this.form)
        
        this.filtrator = new DocumentoFiltrator(world, this.dbSource, this.idField, COLUMNS, {
            defaultOrder: [new OrderEntry(Tarea.fechaCreacion, OrderDirection.DESC)],
            defaultFilter: {[FILTER_ENABLED]: true},
        })

        linkSubsetDbFiltratorForm(this.dbSource, this.filtrator, this.form)
        
        this.aproplanoForm = new AproplanoFormPiece(world.cloneWithOwnErrors())
        this.aproplanoPoper = new AproPoper(this.aproplanoForm, Documento.idDocumento, DocumentoFld.aproplano, 
            AproplanoFld.documento, undefined, {
                textoCrear: "Solicitar envío de planos",
                textoEditar: "Modificar solicitud de envío de planos",
        })
        this.aproplanoForm.onSave.subscribe(async ()=>{
            await this.dbSource.refresh()
        })
        this.aproplanoForm.onAction.subscribe(async() => {
            await this.dbSource.refresh()
        })

    }

    getFileList(idDoc){
        if (!this._fileList || this._fileList.idDocumento !== idDoc) {
            return undefined
        }
        return this._fileList
    }
    
    @action.bound
    setFileListId(idDoc){
        if (this._fileList && this._fileList.idDocumento === idDoc) {
            return
        }
        
        this._fileList = new FileListPiece(this.world, idDoc)
    }
    
    
    @boundMethod
    async rowClicked({data}){
    }
    __rowClicked = this._asyncAction(this.rowClicked)


    @boundMethod
    getFileAddClicked(idDoc){
        return () => {
            if (this._fileList && this._fileList.idDocumento === idDoc) {
                this._fileList.pickFiles()
            }
        }
    }
    
    @boundMethod
    async deleteDocumento(id){
        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            `¿Eliminar documento?`,
            `Se eliminarán el documento y sus archivos.`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
        ],))) {
            await this.form._deleteRows(id)
        }            
    }
    __deleteDocumento = this._asyncAction(this.deleteDocumento)


    @boundMethod
    async enviarPlanosConAlerta(documento){
        const fld = getFieldExtractor(documento)
        const ref = fld(Documento.cotizacion, Tarea.ref)
        const doc = fld(Documento.documento)
        if (ALERT_OK === await askAlert(new AlertDefinition(`Enviar planos`,
                `${ref} - ${doc}`,[
                    [ALERT_OK, 'Enviar planos'],
                    [ALERT_CANCEL, 'Cancelar'],
                ])))
        {
            await this.planosAction(DocumentoAction.ENVIAR_PLANOS, documento)
        }
    }

    @boundMethod
    async planosAction(action, documento){
        await this._documentoAction.query({id: documento[Documento.idDocumento], 
            action: action})
        await this.dbSource.refresh()
    }


}



const Row = observer(forwardRef(
    /**
     * @param {DocumentoListPiece} list
     */
    ({global: list, data, ...props}, ref) => {
        
        const row = <BlRow ref={ref} data={data} {...props} />
        
        const idDocumento = data[Documento.idDocumento]
        const fileList = list.getFileList(idDocumento)
        
        if (!fileList || !userAuth.isAllowed('verArchivosDocumento')){
            return row
        } else {
            return <>
                {row}
                <FileList list={fileList} />
            </>
        }
}))


export const DocumentoList = getPieceContainer('list', observer(
    /**
     * @param {DocumentoListPiece} list
     */
    ({list}) => {

        const filtrator = list.filtrator
        
        const filterSolicitud = filtrator.filterSolicitud
        const cuenta = getFieldExtractor(filterSolicitud)(Solicitud.cuenta, Cuenta.cuenta)
        
        const filterCotiz = filtrator.filterCotizacion
        const cotizRef = getFieldExtractor(filterCotiz)(Tarea.ref)
        
        const newFields = {
            [Documento.idObra]: list.dbSource.parentId,
        }
        
        if (list.filtrator.filterSolicitud && list.filtrator.enabled) {
            newFields[Documento.idSolicitud] = list.filtrator.filterSolicitud[Solicitud.idSolicitud]
        }
        
        if (list.filtrator.filterCotizacion && list.filtrator.enabled) {
            newFields[Documento.idCotizacion] = list.filtrator.filterCotizacion[Tarea.idTarea]
        }
        
        const asa = list._asyncAction
        


        return (
            <>
                <Bar direction={Direction.COLUMN} >
                    <Push />
                </Bar>
                <Frame>
                    <Bar direction={Direction.ROW_REVERSE}>
                        {!userAuth.isAllowed(Permiso.modificarDocumentos) ? null :
                            <>
                                <HintIconButton Icon={sopIcons.Documento} badge="+" badgeColor={sopColors.badgeAdd}
                                                title="Crear documento" onClick={() => list.poper.__createWith(newFields)}/>
                                <Separator />
                            </>
                        }
                        <HintIconButton
                            Icon={icons.Filter}
                            title={`Filtro por solicitante y cotización: ${filtrator.enabled ? "Activado" : "Desactivado"}`}
                            color={filtrator.enabled ? sopColors.enabled : sopColors.disabled}
                            onClick={() => filtrator.__setEnabled(!filtrator.enabled)}
                        />
                        {!filterCotiz || !filtrator.enabled ? null :
                            <Tag
                                variant="outlined"
                                orientation="horizontal"
                                label={cotizRef}
                                tooltip={`filtro: "${cotizRef}"`}
                            />
                        }
                        <Separator transparent />
                        {!filterSolicitud || !filtrator.enabled ? null :
                            <Tag
                                variant="outlined"
                                orientation="horizontal"
                                label={cuenta}
                                tooltip={`filtro: "${cuenta}"`}
                            />
                        }
                        
                    </Bar>
                    <Frame>
                        <BlTable fitContainer dense
                             Row={Row}
                             global={list}
                             verticalAlign="center"
                             data={list.filtrator.data}
                             keyColumnId={list.idField}
                             columns={list.painter.boundlessColumns}
                             renderCellContent={list.painter.__boundlessPainter}
                             onClick={list.selection.__boundlessClicked}
                             selected={list.selection.id}
                             order={list.filtrator.currentOrder}
                             onOrderChange={list.filtrator.__orderChanged}
                        />
                    </Frame>
                </Frame>

                <PoperFrame poper={list.poper}>
                    <DocumentoForm form={list.form} />
                </PoperFrame>

                <AproPoperFrame
                    poper={list.aproplanoPoper}
                    maxWidth="sm"
                >
                    <AproplanoForm form={list.aproplanoForm}/>
                </AproPoperFrame>

            </>
        )
}), {row: true})
