import {StyleRules, Theme} from "@material-ui/core/styles";

export const cimStyles: StyleRules = {
    fields: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: '8px !important', //Issue: React CSS diferente en producción
        }
    },
    grow: {
        flex: 1,
    },
    shrink: {
        flex: '0 1 auto',
    },
    margin: {
        padding: 8,
    },
    margin2: {
        padding: 16,
    },
    column: {
        flexDirection: 'column',
    },
    row: {
        flexDirection: 'row',
    },
    scroll: {
        overflow: 'auto',
    },
    block: {
        display: 'block',
    },
    noWrap: {
        flexWrap: 'nowrap',
    },
    itemsBottom: {
        alignItems: 'flex-end',
    },
    itemsCenter: {
        alignItems: 'center',
    },
    itemsTop: {
        alignItems: 'flex-start',
    },
    singleLine: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dimmed: {
        opacity: 0.5,
    },
    disabled: {
        opacity: 0.01,
        background: 'black',
    },
    invisible: {
        visibility: 'hidden',
    },
    verticalText: {
        writingMode: 'vertical-lr',
        transform: 'rotate(180deg)',
        height: 'inherit',
    },
}


export function getLinkStyle(theme: Theme) {
    return {
        color: theme.palette.info.main,

        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.info.dark,
        }
    }
}

export function getEllipsisStyle(maxWidth) {
    return {
        textOverflow: 'ellipsis',
        maxWidth: maxWidth,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
}
