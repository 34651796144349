 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DireccionIds} from './Direccion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {CuentaIds} from './Cuenta'
import {UserIds} from './User'

export const DireccionEdt = Object.freeze({
    cuenta : new LookupEditor(DireccionIds.cuenta, Tables.Cuenta, CuentaIds.idCuenta, 'cuenta', {sort: 'ID_CUENTA_ASC'}),
    autor : new LookupEditor(DireccionIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    modificacion : new LookupEditor(DireccionIds.modificacion, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idDireccion : new SimpleEditor(DireccionIds.idDireccion, 11, 11),
    cod : new SimpleEditor(DireccionIds.cod, 11, 11),
    idEmpresa : new SimpleEditor(DireccionIds.idEmpresa, 11, 11),
    direccion : new SimpleEditor(DireccionIds.direccion, 40, 16),
    localidad : new SimpleEditor(DireccionIds.localidad, 40, 16),
    provincia : new SimpleEditor(DireccionIds.provincia, 40, 16),
    pais : new SimpleEditor(DireccionIds.pais, 40, 16),
    cp : new SimpleEditor(DireccionIds.cp, 10, 10),
    principal : new SimpleEditor(DireccionIds.principal, 11, 11),
    fechaAlta : new DateTimeEditor(DireccionIds.fechaAlta, 16, 16),
    fechaAltaOnlyDate : new DateEditor(DireccionIds.fechaAlta, 11, 11),
    fechaModificacion : new DateTimeEditor(DireccionIds.fechaModificacion, 16, 16),
    fechaModificacionOnlyDate : new DateEditor(DireccionIds.fechaModificacion, 11, 11),
    odooCambiado : new SimpleEditor(DireccionIds.odooCambiado, 11, 11),
})

tableEditors[Tables.Direccion] = DireccionEdt
