 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TipoTareaIds} from './TipoTarea'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbFloat, DbInt, DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const TipoTareaTable = new DbTable(
    Tables.TipoTarea,
    'TipoTarea',
    graphqlUrl,
    'TipoTarea',
    [TipoTareaIds.idTipo],
    [TipoTareaIds.idTipo, TipoTareaIds.tipo, TipoTareaIds.ratio, TipoTareaIds.m2, TipoTareaIds.detalle, TipoTareaIds.clase, TipoTareaIds.orden, TipoTareaIds.archivado, TipoTareaIds.ratioOld, TipoTareaIds.planificador],
    [],
    [TipoTareaIds.tareas],
    ['verListas'],
    ['modificarConfiguracion'],
    undefined,
    undefined,
    [],
    [],
)


export const TipoTareaFld = Object.freeze({
    tareas : new DbObjectList(Tables.TipoTarea, TipoTareaIds.tareas, Tables.Tarea),
    idTipo : new DbIntId(Tables.TipoTarea, TipoTareaIds.idTipo, 'Id tipo'),
    tipo : new DbText(Tables.TipoTarea, TipoTareaIds.tipo, 'Tipo', 30),
    ratio : new DbFloat(Tables.TipoTarea, TipoTareaIds.ratio, 'Ratio'),
    m2 : new DbFloat(Tables.TipoTarea, TipoTareaIds.m2, 'M² fijos'),
    detalle : new DbText(Tables.TipoTarea, TipoTareaIds.detalle, 'Detalle', 50),
    clase : new DbInt(Tables.TipoTarea, TipoTareaIds.clase, 'Clase'),
    orden : new DbInt(Tables.TipoTarea, TipoTareaIds.orden, 'Orden'),
    archivado : new DbInt(Tables.TipoTarea, TipoTareaIds.archivado, 'Archivado'),
    ratioOld : new DbFloat(Tables.TipoTarea, TipoTareaIds.ratioOld, 'Ratio old'),
    planificador : new DbInt(Tables.TipoTarea, TipoTareaIds.planificador, 'Planificador'),
})

registerTableFields(Tables.TipoTarea, TipoTareaFld) 
