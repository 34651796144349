import {reduce} from 'lodash-es'
import {getILikeFromKeyword} from 'sopix/db/graphql-utils'
import {User} from 'sop/db/User'

/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export const usuarioFilter = (searchFields) =>
    reduce(searchFields, (result, value, key) => {
        if (value === null || value === '') {
            return result
        }
        switch (key) {
            case User.id:
                //result.idObraLt = parseInt(value) + 10
                result[`${User.id}Gt`] = parseInt(value) - 10
                break
            case 'buscar':
                const words = value.split(' ')
                const nombreEntries = words.map(word => ({[`${User.nombreCompleto}Ilike`]: getILikeFromKeyword(word)}))
                result.or = [
                    {[`${User.username}Ilike`]: getILikeFromKeyword(value)},
                    {and: nombreEntries},
                ]
                break
            case User.roleUsuario:
                result.roleUsuario = parseInt(value)
                break
            default:
        }
        return result
    }, {})