 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ObraIds} from './Obra'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {BoolEditor, DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {TipologiaIds} from './Tipologia'
import {StatusIds} from './Status'
import {UserIds} from './User'

export const ObraEdt = Object.freeze({
    tipologia : new LookupEditor(ObraIds.tipologia, Tables.Tipologia, TipologiaIds.idTipologia, 'tipologia', {sort: 'ID_TIPOLOGIA_ASC'}),
    status : new LookupEditor(ObraIds.status, Tables.Status, StatusIds.idStatus, 'status', {sort: 'ID_STATUS_ASC'}),
    autor : new LookupEditor(ObraIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    modificacion : new LookupEditor(ObraIds.modificacion, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idObra : new SimpleEditor(ObraIds.idObra, 11, 11),
    cod : new SimpleEditor(ObraIds.cod, 10, 10),
    nombre : new SimpleEditor(ObraIds.nombre, 60, 24),
    m2 : new SimpleEditor(ObraIds.m2, 11, 11),
    direccion : new SimpleEditor(ObraIds.direccion, 40, 16),
    cp : new SimpleEditor(ObraIds.cp, 7, 7),
    localidad : new SimpleEditor(ObraIds.localidad, 40, 16),
    provincia : new SimpleEditor(ObraIds.provincia, 40, 16),
    pais : new SimpleEditor(ObraIds.pais, 7, 7),
    fechaAlta : new DateTimeEditor(ObraIds.fechaAlta, 16, 16),
    fechaAltaOnlyDate : new DateEditor(ObraIds.fechaAlta, 11, 11),
    fechaModificacion : new DateTimeEditor(ObraIds.fechaModificacion, 16, 16),
    fechaModificacionOnlyDate : new DateEditor(ObraIds.fechaModificacion, 11, 11),
    lat : new SimpleEditor(ObraIds.lat, 20, 10),
    lng : new SimpleEditor(ObraIds.lng, 20, 10),
    altitud : new SimpleEditor(ObraIds.altitud, 11, 11),
    locked : new BoolEditor(ObraIds.locked, 16, 16),
    contadorEstudios : new SimpleEditor(ObraIds.contadorEstudios, 11, 11),
    contadorTareas : new SimpleEditor(ObraIds.contadorTareas, 11, 11),
    contadorValoraciones : new SimpleEditor(ObraIds.contadorValoraciones, 11, 11),
    contadorCambioOferta : new SimpleEditor(ObraIds.contadorCambioOferta, 11, 11),
    fechaEjecucion : new DateTimeEditor(ObraIds.fechaEjecucion, 16, 16),
    fechaEjecucionOnlyDate : new DateEditor(ObraIds.fechaEjecucion, 11, 11),
    passiveHouse : new SimpleEditor(ObraIds.passiveHouse, 11, 11),
    obraRepresentativa : new SimpleEditor(ObraIds.obraRepresentativa, 11, 11),
    odooCambiado : new SimpleEditor(ObraIds.odooCambiado, 11, 11),
})

tableEditors[Tables.Obra] = ObraEdt
