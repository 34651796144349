import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Field} from 'sopix/form/editors/Field'
import {observer} from 'mobx-react'
import {DateField} from 'sopix/form/editors/DateField'
import {LiquidacionEdt} from 'sop/db/LiquidacionEditors'
import {Liquidacion} from 'sop/db/Liquidacion'
import {UserEdt} from 'sop/db/UserEditors'
import {sopUrls} from 'sop/sop-pages'
import {TareaEdt} from 'sop/db/TareaEditors'
import {FormPiece} from 'sopix/pieces/formPiece'
import {sopIcons} from 'sop/sop-icons'
import {LiquidacionFld, LiquidacionTable} from 'sop/db/LiquidacionTable'
import {TareaFld} from 'sop/db/TareaTable'
import {UserFld} from 'sop/db/UserTable'
import {Fields} from 'sopix/formComps/Fields'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {User} from 'sop/db/User'
import {NumberField} from 'sopix/form/editors/NumberField'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {getUsuariosListQueryEntry} from 'sop/db-config/sop-query-lists'
import {usePiece} from 'sopix/piece/use-piece'

export class LiquidacionFormPiece extends FormPiece{

    _listFetcher = new ListFetcher([
        getUsuariosListQueryEntry(undefined, {includeInactivos: true}),
    ])
    
    constructor(world) {
        const FIELDS = [
            ...LiquidacionTable.regularFields,
            [LiquidacionFld.responsable, [UserFld.id, UserFld.username, UserFld.nombreCompleto]],
            [LiquidacionFld.tarea, [TareaFld.idTarea, TareaFld.ref, TareaFld.descripcion]],
            [LiquidacionFld.autor, [UserFld.username, UserFld.nombreCompleto]],
        ]

        super(world,
            FIELDS,
            {
                name: 'Liquidacion',
                icon: sopIcons.Liquidacion,
                table: LiquidacionTable,
                idField: 'idLiquidacion',
                displayField: 'idLiquidacion',
                saveMutation: 'SaveLiquidacion',
                deleteMutation: 'DeleteLiquidacion',
            },
        )
    }

    async _init() {
        this.setLists(await this._listFetcher.fetch())
    }
}


export const LiquidacionForm = observer(
    /**
     * @param {FormPiece} form
     * @returns {JSX.Element}
     */
    ({form}) => {

    usePiece(form)

    return (
        <PieceFrame piece={form} renderPiece={()=> {

            const H = form.fieldHelper
            const fld = form.getField

            return (
                <Fields>
                    <DropdownField {...H.edt(LiquidacionEdt.responsable)} displayField={User.nombreCompleto} autoFocus />
                    <NumberField {...H.edt(LiquidacionEdt.m2Efectivos)} suffix=" m²" />
                    <DateField {...H.edt(LiquidacionEdt.fechaOnlyDate)} />
                    {!fld(Liquidacion.tarea) ? null :
                        <Field {...H.obj(LiquidacionEdt.tarea, TareaEdt.ref)}
                               {...H.btn(LiquidacionEdt.tarea, sopUrls.trabajo, sopIcons.Trabajo)} />
                    }

                    <Field {...H.edt(LiquidacionEdt.descripcion)} multiline rows={10} width={1000} />

                    {!fld(Liquidacion.idLiquidacion) ? null : <>
                        <DateField {...H.edt(LiquidacionEdt.fechaCreacion)} disabled />
                        <Field {...H.obj(LiquidacionEdt.autor, UserEdt.nombreCompleto)} />
                    </>}
                </Fields>
            )

        }} />
    )
})