 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {StatusIds} from './Status'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const StatusTable = new DbTable(
    Tables.Status,
    'Status',
    graphqlUrl,
    'Status',
    [StatusIds.idStatus],
    [StatusIds.idStatus, StatusIds.status],
    [],
    [StatusIds.obras],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const StatusFld = Object.freeze({
    obras : new DbObjectList(Tables.Status, StatusIds.obras, Tables.Obra),
    idStatus : new DbIntId(Tables.Status, StatusIds.idStatus, 'Id status'),
    status : new DbText(Tables.Status, StatusIds.status, 'Status', 20),
})

registerTableFields(Tables.Status, StatusFld) 
