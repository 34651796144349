import {isArray} from 'lodash-es'

export class AlertAction {
    caption
    hint

    constructor(caption, hint) {
        this.caption = caption
        this.hint = hint
    }
}

export class AlertDefinition {

    title
    body

    /** @type {[String,AlertAction][]} */
    actions = []
    defaultAction
    cancelAction

    /**
     * @param {Object<Array>} actions
     */
    constructor(title, body, actions, {defaultAction, cancelAction} = {}) {
        this.title = title
        this.body = body
        this.defaultAction = defaultAction || actions[0][0]
        this.cancelAction = cancelAction || actions[actions.length-1][0]
        for (let [key, action] of actions) {
            const args = isArray(action) ? action : [action]
            this.actions.push([key, new AlertAction(...args)])
        }
    }
}
