import {TecnicoMesChartPiece} from 'sop/componentes/stats/tecnico-mes/TecnicoMesChart'

export const CHART_APILAR = 'APILAR'
export const CHART_HORIZONTAL_BAR = 'HORIZ_BAR'
export const CHART_BAR_WIDTH = 50
/**
 * @enum
 */
export const ChartIds = Object.freeze({
    TECNICO_MES: 0,
})

export class ChartInfo {
    title
    chartClass

    constructor(title, chartClass) {
        this.title = title
        this.chartClass = chartClass
    }
}

/** @type {Map<int, ChartInfo>} */
export const chartPieces = new Map([

    [ChartIds.TECNICO_MES, new ChartInfo(
        'Técnico / mes',
        TecnicoMesChartPiece,
    )],
])