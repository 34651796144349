import AddBoxIcon from '@material-ui/icons/AddBox'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import EditIcon from '@material-ui/icons/Edit'
import FilterListIcon from '@material-ui/icons/FilterList'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SearchIcon from '@material-ui/icons/Search'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import RemoveIcon from '@material-ui/icons/Remove'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreIcon from '@material-ui/icons/Restore'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import InfoIcon from '@material-ui/icons/Info'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AddIcon from '@material-ui/icons/Add'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import WarningIcon from '@material-ui/icons/Warning'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MenuIcon from '@material-ui/icons/Menu'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import PauseIcon from '@material-ui/icons/Pause'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import HomeIcon from '@material-ui/icons/Home'
import ReplayIcon from '@material-ui/icons/Replay'
import ErrorIcon from '@material-ui/icons/Error'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import BlockIcon from '@material-ui/icons/Block'
import DoneAllIcon from '@material-ui/icons/DoneAll'

export const icons = {
    Home: HomeIcon,
    Menu: MenuIcon,    
    Add: AddBoxIcon,
    AddSimple: AddIcon,
    AddCircle: AddCircleIcon,
    Remove: RemoveCircleIcon,
    Check: CheckIcon,
    CheckAll: DoneAllIcon,
    Clear: ClearIcon,
    Delete: DeleteIcon,
    Edit: EditIcon,
    Filter: FilterListIcon,
    First: FirstPageIcon,
    Last: LastPageIcon,
    Next: ChevronRightIcon,
    Previous: ChevronLeftIcon,
    Search: SearchIcon,
    Left: ArrowLeftIcon,
    Right: ArrowRightIcon,
    Down: ArrowDropDownIcon,
    Up: ArrowDropUpIcon,
    Back: ArrowBackIosIcon,
    Save: SaveIcon,
    Accept: CheckIcon,
    Cancel: CancelIcon,
    Deny: BlockIcon,
    Revert: RestoreIcon,
    Info: InfoIcon,
    InfoDeselected: InfoOutlinedIcon,
    Star: StarOutlinedIcon,
    StarDisabled: StarBorderIcon,
    Show: VisibilityIcon,
    Hide: VisibilityOffIcon,
    Warning: WarningIcon,
    Error: ErrorIcon,
    Lock: LockIcon,
    LockOpen: LockOpenIcon,
    Drag: DragIndicatorIcon,
    Pause: PauseIcon,
    Sync: SyncAltIcon,
    SyncDisabled: SyncDisabledIcon,
    Disabled: ClearIcon,
    Retry: ReplayIcon,
    Reload: ReplayIcon,
    Waiting: HourglassEmptyIcon,
}


const materialTableIconSelection = {
    DetailPanel: ChevronRightIcon,
    Export: SaveAltIcon,
    Filter: FilterListIcon,
    FirstPage: FirstPageIcon,
    LastPage: LastPageIcon,
    NextPage: ChevronRightIcon,
    PreviousPage: ChevronLeftIcon,
    ResetSearch: ClearIcon,
    SortArrow: ArrowDownwardIcon,
    ThirdStateCheck: RemoveIcon,
    ViewColumn: ViewColumnIcon
}