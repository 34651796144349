import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('BaseTable')

export const tableStyles = {
    table: {
        position: 'absolute',
        height: '100%',
    },
}

export const useTableStyles = makeStyles(() => tableStyles) 


/**
 * @param {TablePiece} list
 */
export function getTableProps(list){
    const filtrator = list.filtrator
    const painter = list.painter
    return {
        keyColumnId: list.idField,
        columns: painter.boundlessColumns,
        renderCellContent: painter.__boundlessPainter,

        selected: list.selection.id,
        onClick: list.selection.__boundlessClicked,
        order: filtrator.currentOrder,
        onOrderChange: filtrator.__orderChanged,
    }
}