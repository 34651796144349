 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ProcedenciaIds} from './Procedencia'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const ProcedenciaTable = new DbTable(
    Tables.Procedencia,
    'Procedencias',
    graphqlUrl,
    'Procedencia',
    [ProcedenciaIds.idProcedencia],
    [ProcedenciaIds.idProcedencia, ProcedenciaIds.procedencia],
    [],
    [ProcedenciaIds.tareas, ProcedenciaIds.ejecuciones],
    ['verListas'],
    ['modificarConfiguracion'],
    undefined,
    undefined,
    [],
    [],
)


export const ProcedenciaFld = Object.freeze({
    tareas : new DbObjectList(Tables.Procedencia, ProcedenciaIds.tareas, Tables.Tarea),
    ejecuciones : new DbObjectList(Tables.Procedencia, ProcedenciaIds.ejecuciones, Tables.Ejecucion),
    idProcedencia : new DbIntId(Tables.Procedencia, ProcedenciaIds.idProcedencia, 'Id procedencia'),
    procedencia : new DbText(Tables.Procedencia, ProcedenciaIds.procedencia, 'Procedencia', 40),
})

registerTableFields(Tables.Procedencia, ProcedenciaFld) 
