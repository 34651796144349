 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AprobacionIds} from './Aprobacion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const AprobacionCol = Object.freeze({
    tipo : new LookupColumn(AprobacionIds.tipo, Tables.AprobacionTipo, 'tipo'),
    solicitante : new LookupColumn(AprobacionIds.solicitante, Tables.User, 'nombre_completo'),
    obra : new LookupColumn(AprobacionIds.obra, Tables.Obra, 'cod'),
    tarea : new LookupColumn(AprobacionIds.tarea, Tables.Tarea, 'ref'),
    idAprobacion : new SimpleColumn(AprobacionIds.idAprobacion, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(AprobacionIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(AprobacionIds.fechaCreacion, 11, 11, Align.LEFT),
    solicitud : new SimpleColumn(AprobacionIds.solicitud, 40, 16, Align.LEFT),
    estado : new SimpleColumn(AprobacionIds.estado, 11, 11, Align.RIGHT),
    fechaResolucion : new DateTimeColumn(AprobacionIds.fechaResolucion, 16, 16, Align.LEFT),
    fechaResolucionOnlyDate : new DateColumn(AprobacionIds.fechaResolucion, 11, 11, Align.LEFT),
    razonRechazo : new SimpleColumn(AprobacionIds.razonRechazo, 40, 16, Align.LEFT),
})

tableColumns[Tables.Aprobacion] = AprobacionCol
