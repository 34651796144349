 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TipologiaIds} from './Tipologia'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const TipologiaEdt = Object.freeze({
    idTipologia : new SimpleEditor(TipologiaIds.idTipologia, 11, 11),
    tipologia : new SimpleEditor(TipologiaIds.tipologia, 16, 10),
    orden : new SimpleEditor(TipologiaIds.orden, 11, 11),
    subtipologias : new SimpleEditor(TipologiaIds.subtipologias, 40, 16),
})

tableEditors[Tables.Tipologia] = TipologiaEdt
