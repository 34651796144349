import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {cimStyles} from 'sopix/theme/cimStyles'

const useStyles = makeStyles(theme => ({
    frame: {
        alignContent: 'start',
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        position: 'relative',
    },
    paper: {
        background: theme.palette.background.paper,
    },
    shrink: {
        flex: 0,    
    },
    row: {
        flexDirection: 'row',
        width: 'auto',
        height: '100%',
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    columnReverse: {
        flexDirection: 'column-reverse',
    },
    margin: cimStyles.margin,
    margin2: cimStyles.margin2,
    grow: cimStyles.grow,
    center: cimStyles.itemsCenter,
})) 

export function Frame({className, direction, paper, row, Icon, title, shrink, margin, margin2, center, children}) {
    
    const cls = useStyles()
    
    const bar = !Icon && !title ? null : <Bar><Title Icon={Icon}>{title}></Title></Bar>
    
    return (
        <div className={clsx(
            cls.frame,
            shrink && cls.shrink,
            margin && cls.margin,
            margin2 && cls.margin2,
            paper && cls.paper,
            cls[direction],
            row && cls.row,
            center && cls.center,
            className,
        )}>
            {bar}
            {children}
        </div>
    )
}
