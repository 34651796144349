 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageDescuentoIds} from './SageDescuento'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const SageDescuentoCol = Object.freeze({
    codigoEmpresa : new SimpleColumn(SageDescuentoIds.codigoEmpresa, 11, 11, Align.RIGHT),
    codigoCliente : new SimpleColumn(SageDescuentoIds.codigoCliente, 15, 10, Align.LEFT),
    codigoFamilia : new SimpleColumn(SageDescuentoIds.codigoFamilia, 10, 10, Align.LEFT),
    descripcionFamilia : new SimpleColumn(SageDescuentoIds.descripcionFamilia, 40, 16, Align.LEFT),
    pO_DtoA : new SimpleColumn(SageDescuentoIds.pO_DtoA, 11, 11, Align.RIGHT),
    pO_DtoB : new SimpleColumn(SageDescuentoIds.pO_DtoB, 11, 11, Align.RIGHT),
    pO_DtoR : new SimpleColumn(SageDescuentoIds.pO_DtoR, 11, 11, Align.RIGHT),
    descuento1 : new SimpleColumn(SageDescuentoIds.descuento1, 11, 11, Align.RIGHT),
    pO_Observaciones : new SimpleColumn(SageDescuentoIds.pO_Observaciones, 40, 16, Align.LEFT),
})

tableColumns[Tables.SageDescuento] = SageDescuentoCol
