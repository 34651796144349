import React from 'react'
import {NotFoundPage} from 'sopix/app-frame/notFoundPage'
import {useRouter} from 'sopix/router/useRouter'
import {getLoggers} from 'sopix/log'
import {pageIndex, sopPages} from 'sop/sop-pages'
import {Layout} from 'sopix/app-frame/Layout'
import {layoutData} from 'sopix/app-frame/layoutData'
import {Login} from 'sop/login/Login'
import {appName, appVersion, shortAppName, sopAppStage} from 'sop/sop'
import {SesionPage} from 'sop/componentes/sesion-S/SesionPage'
import {ConfigPage} from 'sop/componentes/config-S/ConfigPage'
import {AvisoPage, getAvisoPage} from 'sop/componentes/aviso/AvisoPage'
import {getUsuarioPage, UsuarioPage} from 'sop/componentes/usuario/UsuarioPage'
import {getTrabajoPage, TrabajoPage} from 'sop/componentes/trabajo/TrabajoPage'
import {ObraPage} from 'sop/componentes/obra/ObraPage'
import {getObraPage} from 'sop/componentes/obra/obraPagePiece'
import {AprobacionPage, getAprobacionPage} from 'sop/componentes/aprobacion/aprobacionPage'
import {getLiquidacionPage, LiquidacionPage} from 'sop/componentes/liquidacion/LiquidacionPage'
import {getStatsPage, StatsPage} from 'sop/componentes/stats/StatsPage'
import {CuentaPage, getCuentaPage} from 'sop/componentes/cuenta/CuentaPage'
import {getYoPage, YoPage} from 'sop/componentes/usuario/YoPage'
import {DashboardPage} from 'sop/componentes/dashboard/DashboardPage'
import {route} from 'sopix/router/routerUtils'

const {debug} = getLoggers('SopRouter')

layoutData.appName = appName
layoutData.shortAppName = shortAppName
layoutData.appVersion = appVersion
layoutData.stage = sopAppStage

const withLayout = (pageInfo, renderPage) => {
    
    return (
        <Layout page={pageInfo} index={pageIndex} loginComponent={
            <Login />
        } renderPage={renderPage} appStage={sopAppStage} />
    )
}

const routes = new Map([
    [sopPages.dashboard.paths, () => withLayout(sopPages.dashboard, <DashboardPage /> )],
        
    [sopPages.trabajos.paths, () => withLayout(sopPages.trabajos,
        () => <TrabajoPage page={getTrabajoPage()} /> )],

    [sopPages.avisos.paths, () => withLayout(sopPages.avisos,
        () => <AvisoPage page={getAvisoPage()} />)],

    [sopPages.aprobaciones.paths, () => withLayout(sopPages.aprobaciones, 
        () => <AprobacionPage page={getAprobacionPage()} />)],

    [sopPages.obras.paths, () => withLayout(sopPages.obras,
        () => <ObraPage page={getObraPage()} />)],

    [sopPages.liquidaciones.paths, () => withLayout(sopPages.liquidaciones,
        () => <LiquidacionPage page={getLiquidacionPage()} />)],

    [sopPages.cuentas.paths, () => withLayout(sopPages.cuentas,
        () => <CuentaPage page={getCuentaPage()} />)],
    
    [sopPages.usuarios.paths, () => withLayout(sopPages.usuarios,
        () => <UsuarioPage page={getUsuarioPage()} />)],

    [sopPages.yo.paths, () => withLayout(sopPages.yo,
        () => <YoPage page={getYoPage()} />)],
    
    [sopPages.stats.paths, () => withLayout(sopPages.stats,
        () => <StatsPage page={getStatsPage()} />)],

    [sopPages.configuracion.paths, () => withLayout(sopPages.configuracion,
        () => <ConfigPage />)],

    [sopPages.sesion.paths, () => withLayout(sopPages.sesion,
        () => <SesionPage/>)],
])


export function SopRouter(){

    useRouter()

    const page = route(routes)

    debug(()=>`SopRouter: ${window.location.pathname}`)
    
    return page || <NotFoundPage />
}