 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const StatusIds = Object.freeze({
    obras: Symbol('obras'),
    idStatus: Symbol('idStatus'),
    status: Symbol('status'),
})

export const Status = Object.freeze({
    obras: 'obras',
    idStatus: 'idStatus',
    status: 'status',
})
