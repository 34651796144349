 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {NotaIds} from './Nota'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserIds} from './User'
import {ObraIds} from './Obra'

export const NotaEdt = Object.freeze({
    autor : new LookupEditor(NotaIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    obra : new LookupEditor(NotaIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    idNota : new SimpleEditor(NotaIds.idNota, 11, 11),
    nota : new SimpleEditor(NotaIds.nota, 40, 16),
    contexto : new SimpleEditor(NotaIds.contexto, 40, 16),
    fechaCreacion : new DateTimeEditor(NotaIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(NotaIds.fechaCreacion, 11, 11),
    destacada : new SimpleEditor(NotaIds.destacada, 11, 11),
    privada : new SimpleEditor(NotaIds.privada, 11, 11),
})

tableEditors[Tables.Nota] = NotaEdt
