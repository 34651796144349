import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    barPush: {
        flex: 1,
    },
})) 

export function Push({children}) {
    
    const cls = useStyles()
    
    return (
        <div className={cls.barPush} />
    )
}
