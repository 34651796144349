import {boundMethod} from 'autobind-decorator'
import {sleep} from 'sopix/time/time-utils'
import {Poper} from 'sopix/piece-objects/Poper'

export class FormBasePoper extends Poper{

    /** @type {FormPiece} */
    form

    acceptButton = 'Guardar'

    /** @param {FormPiece} form */
    constructor(form) {
        super(form, {hasButtons:true})
        this.form = form
    }

    get piece(){
        return this.form
    }

    get title(){
        return this.form.title
    }

    get icon(){
        return this.form.icon
    }

    get dirty(){
        return this.form.dirty
    }
    
    @boundMethod
    async create(){
        await this.form.enable()
        await this.open()
        await this.form.create()
    }
    __create = this._asyncAction(this.create)


    @boundMethod
    async createWith(newRowFields = {}){
        this.form.newRowFields = {...this.form.newRowFields, ...newRowFields}
        await this.create()
    }
    __createWith = this._asyncAction(this.createWith)


    async _delayedOpen(){
        await sleep(50)
        await this.open()
    }

    @boundMethod
    async delete(id){
        await this.form._deleteRows(id)
    }
    __delete = this._asyncAction(this.delete)

    async _closeActions() {
        await this.form.invalidate()
        await this.form.disable()
    }
}