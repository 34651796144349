import {getFieldExtractor} from 'sopix/data/data-utils'
import {Txt, TxtList} from 'sopix/formComps/TxtList'
import {Solicitud} from 'sop/db/Solicitud'
import {Cuenta} from 'sop/db/Cuenta'
import React from 'react'
import {Tarea} from 'sop/db/Tarea'
import {Configuracion} from 'sop/db/Configuracion'

export function getSolicitanteListColumn(value, {textOnly=false} = {}){
    if (!value) return ''
    let results = []
    let idx = 0
    for (let sol of value){
        const fld = getFieldExtractor(sol)
        const cuenta = fld(Solicitud.cuenta, Cuenta.cuenta)
        if (textOnly) {
            results.push(cuenta)
        } else {
            results.push(<Txt key={idx++} label={cuenta} maxWidth={240} />)
        }
    }
    
    return textOnly ? results.join(',') : <TxtList>{results}</TxtList>
}


export function calcM2Calculados(cotiz, config){
    const fld = getFieldExtractor(cotiz)
    return (fld(Tarea.m2Simetricos) || 0) * (config[Configuracion.ratioSimetria] || 0) / 100 +
        (fld(Tarea.m2Repetidos) || 0) * (config[Configuracion.ratioRepeticion] || 0) / 100 +
        (fld(Tarea.m2Completos) || 0)
}