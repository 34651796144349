import {action, observable} from 'mobx'
import {delay} from 'lodash-es'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('SnackbarNotifications')

export class SnackbarEntry{
    @observable
    level

    @observable
    text
    
    constructor(text, level) {
        this.level = level
        this.text = text
    }
}


export class SnackbarMessage{
    
    @observable
    height
    
    @observable
    open = true

    @observable
    _deleting = false
    
    @observable
    autoclose = true
    
    @observable.shallow
    content = []
    
    constructor(parent, id, timestamp) {
        this.parent = parent
        this.id = id
        this.timestamp = timestamp
    }

    push(text, level) {
        this.content.push(new SnackbarEntry(text, level))        
    }
    
    get mustPaint(){
        return this.open || !this._deleting
    }
    
    @action.bound
    toggleAutoclose(){
        this.autoclose = !this.autoclose
    }

    @action.bound
    closeReaction(){
        this.open = false

        delay(action(() => {
            this._deleting = true

            delay(action(() => {
                this.parent.messages.delete(this.id)
            }), 100)
        }), 200)

    }

    @action.bound
    replace(text, level){
        this.content = []
        this.push(text, level)
    }

    @action.bound
    append(text, level){
        this.push(text, level)
        debug(()=>`Append -> ${this.content.length}`)
    }
}

let idCounter = 0

export class SnackbarNotifications{
    
    @observable
    /** @type {Map<int, SnackbarMessage>} */
    messages = new Map()

    /**
     * @param text
     * @param level
     * @returns {SnackbarMessage}
     */
    @action.bound
    notify(text, level = 'info'){
        const id = ++idCounter
        const message = new SnackbarMessage(this, id, new Date().getTime())
        message.push(text, level)
        this.messages.set(id, message)
        return message
    }
}

export const snackbarNotifications = new SnackbarNotifications()
