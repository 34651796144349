import {defaultTo, isArray} from 'lodash-es'
import {compile, match} from 'path-to-regexp'
import {FunctionComponent, ReactNode} from "react";

type strOrArray = string | string[]

export function route(routes: Map<strOrArray, (params) => ReactNode>, location?){
    const loc = defaultTo(location, window.location)
    const locationPath = loc.pathname

    for (let [paths, get] of routes.entries()){
        const pathList = isArray(paths) ? paths : [paths]
        for (let path of pathList){
            const matches = match(path, {
                decode: decodeURIComponent
            })
            const info = matches(locationPath)
            if (info) {
                return get(info.params)
            }
        }
    }
    return false
}


export function getRouteParams(routes){
    const routeList = isArray(routes) ? routes : [routes]
    for (let route of routeList){
        const info = match(route)(window.location.pathname)
        if (info) {
            return info.params
        }        
    }
    return undefined
}

export function parseUrlParam(urlId){
    const intId = urlId === undefined ? undefined : parseInt(urlId)
    return Number.isNaN(intId) ? null : intId
}

export function getUrlFromRoute(route, params){
    const makeUrl = compile(route,{ encode: encodeURIComponent }) 
    return makeUrl(params)  
}
