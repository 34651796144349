import React from 'react'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {TipoTareaFld, TipoTareaTable} from 'sop/db/TipoTareaTable'
import {USER_FIELDS_MIN} from 'sop/db-config/fieldSets'
import {SolicitudFld} from 'sop/db/SolicitudTable'
import {SistemaFld} from 'sop/db/SistemaTable'
import {UserFld} from 'sop/db/UserTable'
import {TareaEstadoFld} from 'sop/db/TareaEstadoTable'
import {Tarea} from 'sop/db/Tarea'
import {ObraFld, ObraTable} from 'sop/db/ObraTable'
import {Solicitud} from 'sop/db/Solicitud'
import {Cuenta} from 'sop/db/Cuenta'
import {FormPiece} from 'sopix/pieces/formPiece'
import {ListFetcher, ListQueryEntry} from 'sopix/db-access/listFetcher'
import {TareaEdt} from 'sop/db/TareaEditors'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {TipoTarea} from 'sop/db/TipoTarea'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {sopIcons} from 'sop/sop-icons'
import {Sistema} from 'sop/db/Sistema'
import {
    getTrabajoClaseIcon,
    getTrabajoClaseName,
    getTrabajoViewFromClase,
    getTrabajoViewIcon,
    getTrabajoViewName,
} from 'sop/db-config/sop-db-utils'
import {Obra} from 'sop/db/Obra'
import {TrabajoEstadoValue, TrabajoTipoTarea, TrabajoView} from 'sop/db-config/row-consts'
import {action, computed, observable, runInAction} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {boundMethod} from 'autobind-decorator'
import {CuentaFld} from 'sop/db/CuentaTable'
import {Title} from 'sopix/formComps/Title'
import {CalculadoraFormPiece} from 'sop/componentes/trabajo/CalculadoraForm'
import {AproestaFld} from 'sop/db/AproestaTable'
import {AproestaFormPiece} from 'sop/componentes/trabajo/AproestaForm'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {AproposicionFld} from 'sop/db/AproposicionTable'
import {AproposicionFormPiece} from 'sop/componentes/trabajo/AproposicionForm'
import {ObserverHub} from 'sopix/utils/observers'
import {FormApplyPoper} from 'sopix/piece-objects/formApplyPoper'
import {userAuth} from 'sopix/session/userAuth'
import {AproPoper} from 'sop/componentes/aprobacion/aproPoper'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {getUsuariosListQueryEntry} from 'sop/db-config/sop-query-lists'


export const TRABAJO_VIEW = 'VIEW'

export const SOLICITUD_IDS = Object.freeze({
    name: 'solicitudIds',
    label: 'Solicitantes',
    idField: Solicitud.idSolicitud,
    displayField: Cuenta.cuenta,
})
export const SISTEMA_IDS = Object.freeze({
    name: 'sistemaIds',
    label: 'Sistemas',
    idField: Sistema.idSistema,
    displayField: Sistema.sistema,
})
export const PADRE_IDS = Object.freeze({
    name: Tarea.idPadre,
    label: 'Padre',
    idField: Tarea.idTarea,
    displayField: Tarea.ref,
    width: 100,
})


/**
 * @enum
 */
export const CotizacionView = Object.freeze({
    FORM: Symbol('FORM'),
    CALCULADORA: Symbol('CALCULADORA'),
})


export const TrabajoAction = Object.freeze({
    REALIZAR: 'realizar',
    ASIGNAR: 'asignar',
})




const CUENTA_FIELDS = [
    CuentaFld.cuenta,
]
const OBRA_SOLICITUDES_FIELDS = [
    SolicitudFld.idSolicitud,
    [SolicitudFld.cuenta, CUENTA_FIELDS],
]
const OBRA_TRABAJOS_FIELDS = [
    TareaFld.idTarea,
    TareaFld.ref,
    TareaFld.idPadre,
    [TareaFld.tipo, [TipoTareaFld.tipo, TipoTareaFld.clase]],
]
const OBRA_FIELDS = [
    ObraFld.idObra,
    ObraFld.cod,
    ObraFld.nombre,
    [ObraFld.tareas, OBRA_TRABAJOS_FIELDS],
    [ObraFld.solicitudes, OBRA_SOLICITUDES_FIELDS],
]
const TAREA_FIELDS = [
    ...TareaTable.regularFields,
    TareaFld.aclReadOnly,
    [TareaFld.tipo, TipoTareaTable.regularFields],
    [TareaFld.obra, OBRA_FIELDS],
    [TareaFld.responsable, USER_FIELDS_MIN],
    [TareaFld.solicitudList, [
        SolicitudFld.idSolicitud]],
    [TareaFld.sistemaList, [
        SistemaFld.idSistema]],
    [TareaFld.autor, [
        UserFld.username, UserFld.nombreCompleto]],
    [TareaFld.estado, [
        TareaEstadoFld.estado, TareaEstadoFld.finalizado]],
    [TareaFld.aproesta, [
        AproestaFld.idAproesta, 
        [AproestaFld.aprobacion, [
            AprobacionFld.estado,
        ]]]],
    [TareaFld.aproposicion, [
        AproposicionFld.idAproposicion,
        [AproposicionFld.aprobacion, [
            AprobacionFld.estado,
        ]]]],
]



export class TrabajoFormPiece extends FormPiece {

    _listFetcher = new ListFetcher([
        TareaEdt.estado.getListQueryEntry(),
        TareaEdt.procedencia.getListQueryEntry(),
        //TareaEdt.autor.getListQueryEntry(),
        getUsuariosListQueryEntry(undefined, {includeInactivos: true}),
        new ListQueryEntry(
            undefined,
            [SistemaFld.idSistema, SistemaFld.sistema],
        ),
        new ListQueryEntry(
            {sort: calcGraphqlOrder(TipoTarea.orden)},
            [TipoTareaFld.idTipo, TipoTareaFld.tipo, TipoTareaFld.clase],
            {filters: {archivadoIsNull: true}}),
    ])

    _obraQL = new TableGraphql(ObraTable)
    
    @observable.shallow
    solicitantesObra = []

    @observable
    trabajosObraMap = new Map()

    @observable
    view

    @observable
    /** @type {CotizacionView} */
    _cotizacionView = CotizacionView.FORM

    _trabajoAction = new SimpleMutation(graphqlUrl, 'TrabajoAction', ['success'])
    
    onRealizar = new ObserverHub()
    
    /** @type {AproestaFormPiece} */
    aproestaForm
    
    /** @type {AproPoper} */
    aproestaPoper

    /** @type {AproposicionFormPiece} */
    aproposicionForm

    /** @type {AproPoper} */
    aproposicionPoper

    constructor(world) {
        const TABLE = TareaTable
        super(world,
            TAREA_FIELDS,
            {
                name: 'Trabajo',
                icon: sopIcons.Trabajo,
                table: TABLE,
                idField: Tarea.idTarea,
                displayField: 'ref',
                deleteMutation: 'DeleteTrabajo',
                saveMutation: 'SaveTrabajo',
                extraFields: [SOLICITUD_IDS.name, SISTEMA_IDS.name],
            },
        )

        this.calcForm = new CalculadoraFormPiece(world.cloneWithOwnErrors())
        this.calcPoper = new FormApplyPoper(this.calcForm)
        this.calcPoper.onAccept.subscribe(() => {
            this.setFields(this.calcForm.getChangedFields())
            this.setField(Tarea.m2Efectivos, this.calcForm.calcM2Efectivos())
        })
        
        
        this.aproestaForm = new AproestaFormPiece(world.cloneWithOwnErrors())
        this.aproestaPoper = new AproPoper(this.aproestaForm, Tarea.idTarea, TareaFld.aproesta, 
            AproestaFld.trabajo, () => this.fields, {
                extraWidth: 20,
                textoCrear: 'Solicitar cambio de estado',
                textoEditar: 'Editar solicitud de cambio de estado',
        })
        this.aproestaForm.onAction.subscribe(async() => {
            await this.refresh()
        })
 
        
        this.aproposicionForm = new AproposicionFormPiece(world.cloneWithOwnErrors())
        this.aproposicionPoper = new AproPoper(this.aproposicionForm, Tarea.idTarea, TareaFld.aproposicion, 
            AproposicionFld.trabajo, () => this.fields, {
                extraWidth: 40,
                textoCrear: 'Solicitar cambio de posición',
                textoEditar: 'Editar solicitud de cambio de posición',
        })
        this.aproposicionForm.onAction.subscribe(async() => {
            await this.refresh()
        })
    }

    get estaFinalizado(){
        const estado = this.getField(Tarea.estado)
        return estado === TrabajoEstadoValue.REALIZADO || estado === TrabajoEstadoValue.CANCELADO
    }
    
    get titleWithIcon(){
        const name = super.recordName

        const clase = this.getField(Tarea.tipo, TipoTarea.clase)
        const Icon = getTrabajoClaseIcon(clase)
        return <Title Icon={Icon} >{name}</Title>
    }
    
    @computed
    get trabajosObra() {
        return [...this.trabajosObraMap.values()]
    }

    @computed
    get padreList() {
        const thisId = this.getField(Tarea.idTarea)
        const result = new Map()
        for (let [id, trabajo] of this.trabajosObraMap.entries()) {
            if (!this._isParent(thisId, id)) {
                result.set(id, trabajo)
            }
        }
        return [...result.values()]
    }
    
    get cotizacionView(){
        return this._cotizacionView
    }
    
    @action.bound
    setCotizacionView(view){
        this._cotizacionView = view
    }
    
    
    async _init() {
        //Quitamos el init para que no valide hasta que se cargue algo
        //await super._init()
        const lists = await this._listFetcher.fetch()
        runInAction(()=>{
            this.lists = lists
        })
    }


    @action
    async _applyRow(data) {
        const fld = getFieldExtractor(data)
        
        const solicitudList = data[Tarea.solicitudList]
        data[SOLICITUD_IDS.name] = solicitudList.map(solicitud => solicitud[[Solicitud.idSolicitud]])

        const sistemaList = data[Tarea.sistemaList]
        data[SISTEMA_IDS.name] = sistemaList.map(sistema => sistema[Sistema.idSistema])
        
        if (!fld(Tarea.idTarea)) {
            this.view = fld(TRABAJO_VIEW)
        } else {
            const clase = fld(Tarea.tipo, TipoTarea.clase)
            if (clase) {
                this.view = getTrabajoViewFromClase(clase)
            }
        }
        
        this.solicitantesObra = this._populateSolicitantes(fld(Tarea.obra, Obra.solicitudes))
        this.trabajosObraMap = this._populateTrabajosMap(fld(Tarea.obra, Obra.tareas))

        await super._applyRow(data)
    }


    async _createRow() {
        
        const trabajo = {
            ...this.newRowFields,
            [Tarea.sistemaList]: [],
        }
        
        if (!trabajo[Tarea.solicitudList]) {
            trabajo[Tarea.solicitudList] = []
        }
        
        const idObra = trabajo[Tarea.idObra] 
        if (!idObra) {
            throw new Error('Falta el idObra')
        }
        
        const obra = (await this._obraQL.query(OBRA_FIELDS, undefined,
            {[Obra.idObra]: idObra})).rows[0]

        if (trabajo[TRABAJO_VIEW] === TrabajoView.TAREA) {
            trabajo[Tarea.idTipo] = TrabajoTipoTarea
        }
        
        const idPadre = trabajo[Tarea.idPadre] 
        if (idPadre) {
            const padre = await this._rowFetcher.fetch({[Tarea.idTarea]: idPadre})

            function copia(field){
                trabajo[field] = padre[field]
            }
            
            //Copiar comunes
            copia(Tarea.responsable)
            //copia(Tarea.solicitudList)
            
            if (padre[Tarea.tipo] !== TrabajoTipoTarea && trabajo[Tarea.tipo] !== TrabajoTipoTarea) {
                copia(Tarea.m2Totales)
                copia(Tarea.ratio)
                copia(Tarea.sistemaList)
                copia(Tarea.refCliente)
                copia(Tarea.idProcedencia)
            }
        }
        
        await this._applyRow({
            ...trabajo,
            [Tarea.obra]: obra,
            ...(this.view !== TrabajoView.TAREA ? {} : {[Tarea.idTipo]: 1}),
        })
    }

    @computed
    get name() {
        const clase = this.getField(Tarea.tipo, TipoTarea.clase)
        return clase ? getTrabajoClaseName(clase) : getTrabajoViewName(this.view)
    }

    @computed
    get icon() {
        const clase = this.getField(Tarea.tipo, TipoTarea.clase)
        return clase ? getTrabajoClaseIcon(clase) : getTrabajoViewIcon(this.view)
    }


    @computed
    get tareaTipoOtions() {
        const result = []
        for (let tipo of this.lists[TipoTareaTable.graphqlId].rows) {
            const formClass = getTrabajoViewFromClase(tipo[TipoTarea.clase])
            if (formClass === this.view) {
                result.push(tipo)
            }
        }
        return result
    }


    _isParent(idParent, idChild) {
        if (!idParent) return false
        const trabajos = this.trabajosObraMap
        let id = idChild
        while (id) {
            const trabajo = trabajos.get(id)
            if (!trabajo) {
                throw new Error(`Trabajo no existe: ${id}`)
            }
            if (id === idParent) return true

            id = trabajo[Tarea.idPadre]
        }
        return false
    }

    _populateTrabajosMap(trabajos) {
        const result = new Map()
        for (let trabajo of trabajos) {
            const fld = getFieldExtractor(trabajo)
            const id = fld(Tarea.idTarea)
            result.set(id, {
                [Tarea.idTarea]: id,
                [Tarea.idPadre]: fld(Tarea.idPadre),
                [Tarea.ref]: fld(Tarea.ref),
                [TipoTarea.tipo]: fld(Tarea.tipo, TipoTarea.tipo),
            })
        }
        return result
    }


    @boundMethod
    mapMarkerReaction(fields) {
        this.setFields(fields)
    }

    _populateSolicitantes(solicitudes) {
        const solicitantes = []
        if (!solicitudes) return solicitantes

        for (let solicitud of solicitudes) {
            const cuenta = solicitud[Solicitud.cuenta]
            solicitantes.push({
                [SOLICITUD_IDS.idField]: solicitud[Solicitud.idSolicitud],
                [SOLICITUD_IDS.displayField]: cuenta[Cuenta.cuenta],
            })
        }
        return solicitantes
    }


    @boundMethod
    async realizar(){
        if (this.view === TrabajoView.TAREA) {
            this.setField(Tarea.idEstado, TrabajoEstadoValue.REALIZADO)
            await this.save()
            await this.refresh()
        } else {
            await this.save()
            await this._trabajoAction.query({id: this.id, action: TrabajoAction.REALIZAR})
            this.onRealizar.notify()
        }
    }


    get sePuedeRealizar(){
        if (this.getField(Tarea.idResponsable) !== userAuth.userId) {
            return false
        }
        if (this.view === TrabajoView.TAREA){
            return this.getField(Tarea.idEstado) !== TrabajoEstadoValue.REALIZADO
        } else {
            return this.getField(Tarea.idEstado) === TrabajoEstadoValue.ASIGNADO
        }
    }
    
}