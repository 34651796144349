import React, {useState} from 'react'
import {LoginScreen, LoginState} from 'sop/login/loginState'
import {LoginForm} from 'sop/login/LoginForm'
import {observer} from 'mobx-react'
import {ErrorList} from 'sopix/errors/ErrorList'
import {WithComplexProgress} from 'sopix/progress/WithComplexProgress'
import {ModelessFrame} from 'sopix/modal/ModelessFrame'
import {ResetPassForm} from 'sop/login/ResetPassForm'
import {AccessRestrictedForm} from 'sop/login/AccessRestrictedForm'
import {ResetSuccessForm} from 'sop/login/ResetSuccessForm'
import {useDidMount} from 'sopix/hooks/use-did-mount'
import {LoginOtpForm} from "sop/login/LoginOtpForm";
import {AskOtpSecretForm} from "sop/login/AskOtpSecretForm";
import {AskOtpSuccessForm} from "sop/login/AskOtpSuccessForm";

export const Login = observer(({...props}) => {
    
    /** @type {LoginState} */
    const [login] = useState(() => new LoginState())

    useDidMount(() => {
        login.initialize()
    })

    
    return (
        <>
            <ModelessFrame title={login.title} Icon={login.icon} maxWidth={200}>
                <ErrorList errorManager={login.errorManager} />
                <WithComplexProgress
                    initialized={login.initialized}
                    locked={login.locked}
                    onReplay={() => login.initialize()}
                >
                    {(() => {
                        switch(login.screen){
                            case LoginScreen.ASK_OTP_SECRET:
                                return <AskOtpSecretForm login={login} />
                            case LoginScreen.ASK_OTP_SECRET_SUCCESS:
                                return <AskOtpSuccessForm login={login} />
                            case LoginScreen.RESET:
                                return <ResetPassForm login={login} />
                            case LoginScreen.RESET_DONE:
                                return <ResetSuccessForm login={login} />
                            case LoginScreen.ACCESS_RESTRICTED:
                                return <AccessRestrictedForm login={login} />
                            case LoginScreen.LOGIN_TOTP:
                                return <LoginOtpForm login={login} />
                            default:
                                return <LoginForm login={login} />
                        }
                    })()}
                </WithComplexProgress>
            </ModelessFrame>
        </>
    )    
})
