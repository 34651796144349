import React, {forwardRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    tableBody: {
        //overflow: 'hidden',
    },
})) 

export const BlTableBody = forwardRef(({style, children}, ref) => {
    
    const cls = useStyles()
    
    return (
        <div ref={ref} className={cls.tableBody} style={style} >
            {children}
        </div>
    )
})
