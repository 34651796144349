 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TipoTareaIds} from './TipoTarea'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const TipoTareaEdt = Object.freeze({
    idTipo : new SimpleEditor(TipoTareaIds.idTipo, 11, 11),
    tipo : new SimpleEditor(TipoTareaIds.tipo, 20, 10),
    ratio : new SimpleEditor(TipoTareaIds.ratio, 11, 11),
    m2 : new SimpleEditor(TipoTareaIds.m2, 11, 11),
    detalle : new SimpleEditor(TipoTareaIds.detalle, 20, 10),
    clase : new SimpleEditor(TipoTareaIds.clase, 11, 11),
    orden : new SimpleEditor(TipoTareaIds.orden, 11, 11),
    archivado : new SimpleEditor(TipoTareaIds.archivado, 11, 11),
    ratioOld : new SimpleEditor(TipoTareaIds.ratioOld, 11, 11),
    planificador : new SimpleEditor(TipoTareaIds.planificador, 11, 11),
})

tableEditors[Tables.TipoTarea] = TipoTareaEdt
