 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ProvinciaIds} from './Provincia'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const ProvinciaEdt = Object.freeze({
    idProvincia : new SimpleEditor(ProvinciaIds.idProvincia, 11, 11),
    cp : new SimpleEditor(ProvinciaIds.cp, 2, 2),
    provincia : new SimpleEditor(ProvinciaIds.provincia, 22, 10),
})

tableEditors[Tables.Provincia] = ProvinciaEdt
