import React from 'react'
import {TabBar} from 'sopix/formComps/TabFrame'
import {sopIcons} from 'sop/sop-icons'
import {TrabajosMode} from 'sop/componentes/trabajo/TrabajoPage'
import {observer} from 'mobx-react'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {IconTab} from 'sopix/formComps/IconTab'

export const TrabajoTab = observer(
    /**
     * @param {TrabajoPagePiece} page
     */
    ({page}) => {

        const tabs = new Map([
            [TrabajosMode.TRABAJOS,
                <IconTab large key={0} icon={sopIcons.Trabajo} toolTip="Trabajos" />],
            [TrabajosMode.ESTUDIOS,
                <IconTab large key={1} icon={sopIcons.Estudio} toolTip="Estudios" />],
            [TrabajosMode.VALORACIONES,
                <IconTab large key="valoraciones" icon={sopIcons.Valoracion} toolTip="Valoraciones" />],
        ])
        
        if (userAuth.isAllowed(Permiso.repartirValoraciones)) {
            tabs.set(TrabajosMode.REPARTIR_ESTUDIOS,
                <IconTab large key="asignarVal" icon={sopIcons.ValoracionAsignar} toolTip="Repartir valoraciones" />    
            )
        }
    
        return (
            <TabBar
                value={[...tabs.keys()].indexOf(page.mode)}
                onChange={index => page.__setMode([...tabs.keys()][index])}
                absolute={false}
                direction="row"
                edge="start"
                tabs={tabs}
                expand={false}
            />
        )
})
