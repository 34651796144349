 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaIds} from './Tarea'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {BoolEditor, DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {TareaEstadoIds} from './TareaEstado'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {ProcedenciaIds} from './Procedencia'
import {TipoTareaIds} from './TipoTarea'

export const TareaEdt = Object.freeze({
    estado : new LookupEditor(TareaIds.estado, Tables.TareaEstado, TareaEstadoIds.idEstado, 'estado', {sort: 'ID_ESTADO_ASC'}),
    autor : new LookupEditor(TareaIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    responsable : new LookupEditor(TareaIds.responsable, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    obra : new LookupEditor(TareaIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    procedencia : new LookupEditor(TareaIds.procedencia, Tables.Procedencia, ProcedenciaIds.idProcedencia, 'procedencia', {sort: 'ID_PROCEDENCIA_ASC'}),
    tipo : new LookupEditor(TareaIds.tipo, Tables.TipoTarea, TipoTareaIds.idTipo, 'tipo', {sort: 'ID_TIPO_ASC'}),
    idTarea : new SimpleEditor(TareaIds.idTarea, 11, 11),
    ordenEstudio : new SimpleEditor(TareaIds.ordenEstudio, 4, 4),
    ordenValoracion : new SimpleEditor(TareaIds.ordenValoracion, 4, 4),
    descripcion : new SimpleEditor(TareaIds.descripcion, 100, 30),
    fechaVencimiento : new DateEditor(TareaIds.fechaVencimiento, 11, 11),
    fechaCreacion : new DateTimeEditor(TareaIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(TareaIds.fechaCreacion, 11, 11),
    ref : new SimpleEditor(TareaIds.ref, 12, 9),
    refCliente : new SimpleEditor(TareaIds.refCliente, 20, 10),
    m2Totales : new SimpleEditor(TareaIds.m2Totales, 8, 8),
    urgente : new SimpleEditor(TareaIds.urgente, 11, 11),
    ratio : new SimpleEditor(TareaIds.ratio, 11, 11),
    fechaFinalizada : new DateTimeEditor(TareaIds.fechaFinalizada, 16, 16),
    fechaFinalizadaOnlyDate : new DateEditor(TareaIds.fechaFinalizada, 11, 11),
    desplazada : new SimpleEditor(TareaIds.desplazada, 11, 11),
    m2Completos : new SimpleEditor(TareaIds.m2Completos, 11, 11),
    m2Repetidos : new SimpleEditor(TareaIds.m2Repetidos, 11, 11),
    m2Simetricos : new SimpleEditor(TareaIds.m2Simetricos, 11, 11),
    m2Fijos : new SimpleEditor(TareaIds.m2Fijos, 11, 11),
    detalle : new SimpleEditor(TareaIds.detalle, 40, 16),
    justificacion : new SimpleEditor(TareaIds.justificacion, 40, 16),
    m2Efectivos : new SimpleEditor(TareaIds.m2Efectivos, 8, 8),
    precioSinRegulacion : new SimpleEditor(TareaIds.precioSinRegulacion, 11, 11),
    refE : new SimpleEditor(TareaIds.refE, 40, 16),
    refC : new SimpleEditor(TareaIds.refC, 40, 16),
    refV : new SimpleEditor(TareaIds.refV, 40, 16),
    refT : new SimpleEditor(TareaIds.refT, 40, 16),
    odooCambiado : new SimpleEditor(TareaIds.odooCambiado, 11, 11),
    asumible : new BoolEditor(TareaIds.asumible, 8, 8),
    aclReadOnly : new BoolEditor(TareaIds.aclReadOnly, 13, 13),
})

tableEditors[Tables.Tarea] = TareaEdt
