import {boundMethod} from 'autobind-decorator'
import {FormBasePoper} from 'sopix/piece-objects/formBasePoper'
import {ObserverHub} from 'sopix/utils/observers'

export class FormApplyPoper extends FormBasePoper{

    acceptButton = 'Aceptar'

    onAccept = new ObserverHub()
    
    async _doAccept() {
        this.onAccept.notify()
        this.form.fieldManager.undirty()
        return true
    }

    @boundMethod
    async apply(fields){
        await this.form.enable()

        await Promise.all([
            this.form.apply(fields),
            this._delayedOpen(),
        ])
    }
    __apply = this._asyncAction(this.apply)
}