 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PermisoTransposedIds} from './PermisoTransposed'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const PermisoTransposedCol = Object.freeze({
    idPermiso : new SimpleColumn(PermisoTransposedIds.idPermiso, 11, 11, Align.RIGHT),
    permiso : new SimpleColumn(PermisoTransposedIds.permiso, 26, 10, Align.LEFT),
})

tableColumns[Tables.PermisoTransposed] = PermisoTransposedCol
