 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AvisoTipoIds} from './AvisoTipo'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const AvisoTipoTable = new DbTable(
    Tables.AvisoTipo,
    'AvisoTipos',
    graphqlUrl,
    'AvisoTipo',
    [AvisoTipoIds.idTipo],
    [AvisoTipoIds.idTipo, AvisoTipoIds.tipo],
    [],
    [AvisoTipoIds.avisos, AvisoTipoIds.usuariosSubscritos],
    ['verAvisos'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const AvisoTipoFld = Object.freeze({
    avisos : new DbObjectList(Tables.AvisoTipo, AvisoTipoIds.avisos, Tables.Aviso),
    usuariosSubscritos : new DbObjectList(Tables.AvisoTipo, AvisoTipoIds.usuariosSubscritos, Tables.User),
    idTipo : new DbIntId(Tables.AvisoTipo, AvisoTipoIds.idTipo, 'Id tipo'),
    tipo : new DbText(Tables.AvisoTipo, AvisoTipoIds.tipo, 'Tipo', 20),
})

registerTableFields(Tables.AvisoTipo, AvisoTipoFld) 
