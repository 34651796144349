 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproplanoIds} from './Aproplano'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {AprobacionIds} from './Aprobacion'
import {DocumentoIds} from './Documento'

export const AproplanoTable = new DbTable(
    Tables.Aproplano,
    'Aproplanos',
    graphqlUrl,
    'Aproplano',
    [AproplanoIds.idAproplano],
    [AproplanoIds.idAproplano, AproplanoIds.idAprobacion, AproplanoIds.justificacion, AproplanoIds.idDocumento],
    [AproplanoIds.aprobacion, AproplanoIds.documento],
    [AproplanoIds.documentos],
    ['verAprobaciones'],
    ['solicitarEnvioPlanos'],
    undefined,
    undefined,
    [],
    [],
)


export const AproplanoFld = Object.freeze({
    documentos : new DbObjectList(Tables.Aproplano, AproplanoIds.documentos, Tables.Documento),
    aprobacion : new DbObject(Tables.Aproplano, AproplanoIds.aprobacion, AproplanoIds.idAprobacion, 'Aprobacion'),
    documento : new DbObject(Tables.Aproplano, AproplanoIds.documento, AproplanoIds.idDocumento, 'Documento'),
    idAproplano : new DbIntId(Tables.Aproplano, AproplanoIds.idAproplano, 'Id aproplano'),
    idAprobacion : new DbObjectId(Tables.Aproplano, AproplanoIds.idAprobacion, 'Id aprobacion', {foreignTableId: Tables.Aprobacion, foreignKeyFieldId: AprobacionIds.idAprobacion}),
    justificacion : new DbText(Tables.Aproplano, AproplanoIds.justificacion, 'Justificacion', 1000),
    idDocumento : new DbObjectId(Tables.Aproplano, AproplanoIds.idDocumento, 'Id documento', {foreignTableId: Tables.Documento, foreignKeyFieldId: DocumentoIds.idDocumento}),
})

registerTableFields(Tables.Aproplano, AproplanoFld) 
