import React from 'react'
import {Badge} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    icon: {
    },
    hintIconBlack: {
        color: theme.palette.common.black,
    },
    badge: {
        height: 12,
        minWidth: 12,
        padding: 0,
    },
    badgeMini: {
        height: 10,
        minWidth: 10,
        //padding: '0 3px',
    },
    badgeColorPrimary: {
        background: theme.palette.background.paper,
    },
    anchorOriginTopRightRectangle: {
        transform: 'scale(1) translate(60%, -25%)',    
    },
})) 

export function HintIcon({className, styles, title, Icon, badge, badgeColor='secondary', badgeVariant, mini, 
                             large, color, ...iconProps}) {

    const cls = useStyles()

    const icon = <Icon
        className={clsx(
            cls.icon,
            color === 'black' && cls.hintIconBlack, 
            className
        )}
        color={color === 'black' ? undefined : color} 
        fontSize={mini ? 'small' : (large ? 'large' : undefined)} 
        {...iconProps}
    />
    
    const iconWithBadge = badge === undefined
        ?   icon
        :   <Badge badgeContent={badge} color={badgeColor} variant={badgeVariant} classes={{
                    'badge': clsx(
                        badgeVariant !== 'dot' && cls.badge,
                        mini && cls.badgeMini,
                        (!badgeColor || badgeColor === 'default') && cls.badgeColorPrimary,
                        styles && styles.badge,
                    ),
                    'anchorOriginTopRightRectangle': cls.anchorOriginTopRightRectangle,
                }}
            >
            {icon}
            </Badge>

    return title === undefined ? iconWithBadge : (
        <Tooltip title={title}>
            {iconWithBadge}
        </Tooltip>
    )
}
