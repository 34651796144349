import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {boundMethod} from 'autobind-decorator'
import {Tarea} from 'sop/db/Tarea'
import {getSolicitanteListColumn} from 'sop/componentes/trabajo/trabajo-utils'
import {TipoTarea} from 'sop/db/TipoTarea'
import {Obra} from 'sop/db/Obra'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {TareaEstado} from 'sop/db/TareaEstado'
import {TrabajoEstadoValue} from 'sop/db-config/row-consts'
import {sopIcons} from 'sop/sop-icons'
import {User} from 'sop/db/User'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {TareaEstadoFld} from 'sop/db/TareaEstadoTable'
import {ICON_BUTTON_SIZE} from 'sopix/formComps/HintIconButton'
import {
    BUTTONS_TAREA_LIST_LEFT,
    BUTTONS_TAREA_LIST_RIGHT,
    TAREA_LIST_DRAG,
} from 'sop/componentes/obra-trabajo/obraTrabajoListPiece'
import {getTrabajoClaseIcon, getTrabajoClaseName} from 'sop/db-config/sop-db-utils'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {getEllipsisStyle, getLinkStyle} from 'sopix/theme/cimStyles'
import {getLoggers} from 'sopix/log'
import {Align} from 'sopix/db/db-types'
import {truncate} from 'lodash-es'
import {icons} from 'sopix/icon/icons'
import {Aproposicion} from 'sop/db/Aproposicion'
import {observer} from 'mobx-react'
import {Tooltip} from '@material-ui/core'
import Moment from 'moment'
import {Aprobacion} from 'sop/db/Aprobacion'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import {AprobacionEstado} from 'sop/componentes/aprobacion/aprobacion-consts'

const {debug} = getLoggers('TrabajoPainter')


const useOrderStyles = makeStyles(theme => ({
    orderChanged: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: '0 5px',
    },
    entry: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        marginRight: 10,
    },
})) 


const OrdenNumber = observer(
    
    ({value, flds}) => {

        const cls = useOrderStyles()
        
        const cambiosRaw = flds(Tarea.aproposiciones)
        const cambios = []
        
        if (cambiosRaw && cambiosRaw.length) {
            for (let cambio of cambiosRaw) {
                const aprobacion = cambio[Aproposicion.aprobacion] 
                if (!aprobacion) {
                    continue
                }

                if (aprobacion[Aprobacion.estado] !== AprobacionEstado.APROBADA) {
                    continue
                }
                
                const fecha = Moment(aprobacion[Aprobacion.fechaResolucion]).format('DD-MMM')

                cambios.push(
                    <div key={cambio[Aproposicion.idAproposicion]} className={cls.entry} >
                        <Typography component="span" color="secondary">{cambio[Aproposicion.posicionOriginal]}</Typography>
                        <ArrowRightIcon fontSize="small" />
                        <Typography className={cls.margin} component="span" color="secondary">
                            {cambio[Aproposicion.nuevaPosicion]}
                        </Typography>
                        <Typography className={cls.margin} component="span" color="primary" variant="caption"> 
                            {fecha}
                        </Typography>
                        <Typography component="span" variant="caption"> {cambio[Aproposicion.justificacion]}</Typography>
                    </div>
                )
            }
        }

        const number =  <Typography className={!cambios.length ? undefined : cls.orderChanged} color="secondary" 
                           variant="h6" >{value}</Typography>
        
        if (!cambios.length) {
            return number
        } else {
            return <Tooltip title={cambios}>{number}</Tooltip>
        }
})



export class TrabajoPainter extends TablePainter {

    @boundMethod
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds}) {
        
        switch (id) {
            case Tarea.asumible:
                return !value ? null : <icons.Check />
            case Tarea.ordenValoracion:
            case Tarea.ordenEstudio:
                return <OrdenNumber value={value} flds={flds} />            
            case Tarea.ref:
                const clase = flds(Tarea.tipo, TipoTarea.clase)
                const tipo = flds(Tarea.tipo, TipoTarea.tipo)
                return (
                    <TrabajoRef rowId={rowId} clase={clase} tipo={tipo} >{value}</TrabajoRef>    
                )
            case Tarea.solicitudList:
                return getSolicitanteListColumn(value)
            case Tarea.tipo:
                return value[TipoTarea.tipo]
            case Tarea.obra:
                return value[Obra.nombre]

            case Tarea.descripcion:
                return truncate(value, {length: 200})
            
            case Tarea.estado:
                const ico = icon => <HintIcon mini Icon={icon} title={fld(TareaEstado.estado)} />
                switch (fld(TareaEstado.idEstado)){
                    case TrabajoEstadoValue.PLANIFICADOR:
                        return ico(sopIcons.Planificador)
                    case TrabajoEstadoValue.ASIGNADO:
                        return ico(sopIcons.TrabajoEnProgreso)
                    case TrabajoEstadoValue.BLOQUEADO:
                        return ico(sopIcons.TrabajoBloqueado)
                    case TrabajoEstadoValue.CANCELADO:
                        return ico(sopIcons.TrabajoCancelado)
                    case TrabajoEstadoValue.REALIZADO:
                        return ico(sopIcons.TrabajoRealizado)
                    default:
                        return null
                }

            case Tarea.responsable:
                return value ? value[User.nombreCompleto] : ''

            case Tarea.padre:
                return fld(Tarea.ref)

            case Tarea.fechaCreacion:
                const fechaVencimiento = formatter(flds(Tarea.fechaVencimiento))
                return [
                    <span key="creacion">{formatter(value)}</span>,
                    ...(!fechaVencimiento ? [] : [
                        <Typography key="vencimiento" color="secondary" variant="caption">{fechaVencimiento}</Typography>
                    ])
                ]

            default:
                return formatter(value)
        }
    }

    _fixColumn(col, id, dbCol) {
        switch (id) {
            case Tarea.responsable:
                col.title = 'Resp.'
                break
            case Tarea.ordenValoracion:
            case Tarea.ordenEstudio:
                col.minWidth = col.width = 50
                col.title = '#'
                col.align = Align.LEFT
                break
            case Tarea.estado:
                col.title = TareaEstadoFld.estado.title
                col.minWidth = col.width = 40
                break
            case Tarea.solicitudList:
                col.title = 'Solicitantes'
                break
            case Tarea.padre:
                col.title = 'Padre'
                break
            case BUTTONS_TAREA_LIST_LEFT:
                col.minWidth = col.maxWidth = ICON_BUTTON_SIZE
                break
            case BUTTONS_TAREA_LIST_RIGHT:
                col.minWidth = col.maxWidth = ICON_BUTTON_SIZE
                break
            case TAREA_LIST_DRAG:
                col.minWidth = col.width = 30
                break
            case Tarea.ref:
                col.width = col.minWidth += 35
                break
            case Tarea.fechaCreacion:
                col.title = 'Fecha'
                break
            default:
        }
    }
}


const useStyles = makeStyles(theme => ({
    ref: {
        display: 'flex',
        alignItems: 'center',
    },
    refIcon: {
        marginRight: 5,
    },
    tareaTipo: {
        ...theme.typography.caption,
        ...getEllipsisStyle(114),
        alignSelf: 'center',
    },
    badge: {
        right: 10,
    },
    badgeAncestor: {
        background: theme.palette.secondary.light,
        color: 'white',
    },
    badgeDescendant: {
        background: theme.palette.secondary.light,
    },
    link: getLinkStyle(theme),
})) 



export function TrabajoRef({rowId, clase, tipo, descendantsToSelected, badgeNumber, onClick, children}) {

    debug(()=>`REF ${rowId}`)
    
    const cls = useStyles()
    
    return (
        <div>
            <div className={cls.ref} >
                <HintIcon
                    className={cls.refIcon}
                    Icon={getTrabajoClaseIcon(clase)}
                    title={getTrabajoClaseName(clase)}
                    badge={descendantsToSelected > 0 ? 'x' : badgeNumber}
                    badgeVariant={descendantsToSelected > 0 ? 'dot' : undefined}
                    styles={{badge: clsx([
                            cls.badge,
                            badgeNumber && cls.badgeAncestor,
                            descendantsToSelected > 0 && cls.badgeDescendant,
                        ])}}
                />
                <span className={
                    onClick && cls.link
                } onClick={e => {
                    e.stopPropagation()
                    onClick && onClick()
                }}>
                    {children}
                </span>
            </div>
            <div className={cls.tareaTipo}>{tipo}</div>
        </div>
    )
}
