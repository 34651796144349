import {WorldObject} from 'sopix/piece/worldObject'
import {decodeColumnDescriptors, getColumnTextFormatter} from 'sopix/db/db-column-utils'
import {tableColumnMapFromDb} from 'sopix/boundless-db/boundless-db-utils'
import {boundMethod} from 'autobind-decorator'
import {observable, runInAction} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'

export class TablePainter extends WorldObject{

    /** @type {Map<String, TableColumn>} */
    @observable.ref
    boundlessColumns

    constructor(world, columnDescriptors) {
        super(world)

        this.setColumnDescriptors(columnDescriptors)
    }

    setColumnDescriptors(columnDescriptors){
        this.dbColumns = decodeColumnDescriptors(columnDescriptors)
        this.painters = {}
        this.formatters = {}
        for (let [id, dbCol] of this.dbColumns) {
            const defaulFormatter = getColumnTextFormatter(dbCol)
            this.formatters[id] = defaulFormatter 
            this.painters[id] = this._pickPainter(id, dbCol, defaulFormatter)
        }

        runInAction(()=>{
            this.boundlessColumns = tableColumnMapFromDb(columnDescriptors, this._fixColumn)
        })
    }

    @boundMethod
    _fixColumn(/* TableColumn */ col, id, /* DbColumn */ dbCol) {
    }

    /**
     * @param {DbColumn} dbCol
     */
    @boundMethod
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global}){
        return formatter(value)
    }
    
    @boundMethod
    _paintHeader({id, content, global}){
        return content
    }
    
    @boundMethod
    _getPainter(id, dbCol, defaultFormatter){
        //se puede hacer override de esta y devolver undefined o painter en vez de calcular celda a celda
        //return undefined
        return this._paint
    }
    
    @boundMethod
    _pickPainter(id, dbCol, defaultFormatter){
        const defaulPainter = ({value}) => {
            return defaultFormatter(value)
        }
        const customPainter = this._getPainter(id, dbCol, defaultFormatter)
        return customPainter || defaulPainter
    }

    @boundMethod
    boundlessPainter({content, index, id, rowId, data, global}){
        return this.painters[id]({
            id: id,
            value: content,
            rowId,
            row: data,
            index: index,
            dbCol: this.dbColumns[id],
            formatter: this.formatters[id],
            fld: getFieldExtractor(content),
            flds: getFieldExtractor(data),
            global: global,
        })
    }
    __boundlessPainter = this._action(this.boundlessPainter)

    @boundMethod
    headerPainter({content, id, global}){
        return this._paintHeader({id, content, global})
    }

}