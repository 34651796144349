 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {MesIds} from './Mes'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const MesEdt = Object.freeze({
    idMes : new SimpleEditor(MesIds.idMes, 11, 11),
    mes : new SimpleEditor(MesIds.mes, 10, 10),
    mesCorto : new SimpleEditor(MesIds.mesCorto, 3, 3),
})

tableEditors[Tables.Mes] = MesEdt
