import {DataSide} from 'sopix/boundless/boundless-classes'

export class DataBlock {

    /** @type {[]} */
    data
    
    constructor(index, data, hasPreviousData, hasNextData) {
        this.index = index
        this.data = data
        this.hasPreviousData = hasPreviousData
        this.hasNextData = hasNextData
    }

    get length() {
        return this.data.length
    }
    
    get end(){
        return this.index + this.data.length
    }

    trimTop(trimCount) {
        if (trimCount === 0) return
        this.index += trimCount
        this.data = this.data.slice(trimCount)
        this.hasPreviousData = true
    }

    trimBottom(trimCount) {
        if (trimCount === 0) return
        this.data = this.data.slice(0, -trimCount)
        this.hasNextData = true
    }

    extendBottom(/* DataBlock */ extension) {
        this.data = [...this.data, ...extension.data]
        this.hasNextData = extension.hasNextData
    }

    extendTop(extension) {
        this.index -= extension.data.length
        this.data = [...extension.data, ...this.data]
        this.hasPreviousData = extension.hasPreviousData
    }

    getSubBlock(index, len, side = DataSide.BOTTOM){
        const absIndex = side === DataSide.BOTTOM ? index : index - len
        
        const end = absIndex + len
        
        if (absIndex > this.end || end <= this.index) return undefined

        const subIndex = absIndex < this.index ? this.index : absIndex
        const subEnd = end >= this.end ? this.end : end
        const hasPreviousData = subIndex > this.index || this.hasPreviousData
        const hasNextData = subEnd < this.end || this.hasNextData
        return new DataBlock(
            subIndex,
            this.data.slice(subIndex - this.index, subEnd - this.index),
            hasPreviousData,
            hasNextData
        )
    }

    get(index){
        if (index >= this.end || index < this.index) return undefined
        return this.data[index - this.index]
    }
    
    clone(){
        return new DataBlock(this.index, [...this.data], this.hasPreviousData, this.hasNextData)
    }
    
    asText() {
        const before = this.hasPreviousData ? '..' : ''
        const after = this.hasNextData ? '..' : ''
        return `[${before}${this.index}:${this.index + this.data.length}${after}]`
    }
}