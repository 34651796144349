import {AproplanoFld, AproplanoTable} from 'sop/db/AproplanoTable'
import {Aproplano} from 'sop/db/Aproplano'
import {observer} from 'mobx-react'
import {Fields} from 'sopix/formComps/Fields'
import React, {useEffect} from 'react'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Field} from 'sopix/form/editors/Field'
import {AproplanoEdt} from 'sop/db/AproplanoEditors'
import {sopIcons} from 'sop/sop-icons'
import {boundMethod} from 'autobind-decorator'
import {AproFormPiece} from 'sop/componentes/aprobacion/aproFormPiece'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {Permiso} from 'sop/db/Permiso'
import {DocumentoTable} from 'sop/db/DocumentoTable'
import {usePiece} from 'sopix/piece/use-piece'

export class AproplanoFormPiece extends AproFormPiece{

    permisoAdmin = Permiso.aprobarEnvioPlanos
    
    constructor(world) {

        const FIELDS = [
            ...AproplanoTable.regularFields,
            [AproplanoFld.aprobacion, [AprobacionFld.estado]],
            [AproplanoFld.documento,[
                ...DocumentoTable.regularFields,
            ]],
        ]

        const TABLE = AproplanoTable
        super(world,
            FIELDS,
            {
                name: 'Solicitud de envío de planos',
                icon: sopIcons.Planos,
                table: TABLE,
                idField: Aproplano.idAproplano,
                saveMutation: 'SaveAproplano',
            },
        )
        this._actionMutation = new SimpleMutation(graphqlUrl, 'AproplanoAction', ['success'])
    }

    @boundMethod
    _getTitle(empty) {
        return this.name
    }
}




export const AproplanoForm = observer(
    /**
     * @param {AproplanoFormPiece} form
     */
    ({form}) => {

        usePiece(form)

        return (
            <PieceFrame piece={form} renderPiece={()=> {

                const H = form.fieldHelper
                const fld = form.getField

                return(
                    <Fields margin>
                        <Field autoFocus {...H.edt(AproplanoEdt.justificacion)} multiline rows={15} width={1000} />
                    </Fields>
                )
            }} />
        )
    })
