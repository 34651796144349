 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SageDescuentoIds = Object.freeze({
    codigoEmpresa: Symbol('codigoEmpresa'),
    codigoCliente: Symbol('codigoCliente'),
    codigoFamilia: Symbol('codigoFamilia'),
    descripcionFamilia: Symbol('descripcionFamilia'),
    pO_DtoA: Symbol('pO_DtoA'),
    pO_DtoB: Symbol('pO_DtoB'),
    pO_DtoR: Symbol('pO_DtoR'),
    descuento1: Symbol('descuento1'),
    pO_Observaciones: Symbol('pO_Observaciones'),
})

export const SageDescuento = Object.freeze({
    codigoEmpresa: 'codigoEmpresa',
    codigoCliente: 'codigoCliente',
    codigoFamilia: 'codigoFamilia',
    descripcionFamilia: 'descripcionFamilia',
    pO_DtoA: 'pO_DtoA',
    pO_DtoB: 'pO_DtoB',
    pO_DtoR: 'pO_DtoR',
    descuento1: 'descuento1',
    pO_Observaciones: 'pO_Observaciones',
})
