import {TareaCol} from 'sop/db/TareaColumns'
import {TareaFld} from 'sop/db/TareaTable'
import {UserCol} from 'sop/db/UserColumns'
import {CuentaCol} from 'sop/db/CuentaColumns'
import {ObraCol} from 'sop/db/ObraColumns'
import {TipoTareaFld, TipoTareaTable} from 'sop/db/TipoTareaTable'
import {UserFld} from 'sop/db/UserTable'
import {ObraFld} from 'sop/db/ObraTable'
import {SolicitudFld} from 'sop/db/SolicitudTable'
import {CuentaFld} from 'sop/db/CuentaTable'
import {TAREA_ESTADO_FIELDS} from 'sop/db-config/fieldSets'
import {AproposicionFld} from 'sop/db/AproposicionTable'
import {AprobacionFld} from 'sop/db/AprobacionTable'

const COLUMNS_COMUN = [
    TareaCol.ref,
    TareaCol.estado,
    [TareaFld.responsable, UserCol.nombreCompleto],
    TareaCol.descripcion,
    TareaCol.fechaCreacionOnlyDate,
    [TareaFld.solicitudList, CuentaCol.cuenta],
    [TareaFld.obra, ObraCol.nombre],
]
const COLUMNS_TRABAJOS = [
    TareaCol.ordenEstudio,
    TareaCol.ordenValoracion,
    ...COLUMNS_COMUN,
]
const COLUMNS_ESTUDIOS = [
    TareaCol.ordenEstudio,
    ...COLUMNS_COMUN,
]
export const COLUMNS_VALORACIONES = [
    TareaCol.ordenValoracion,
    ...COLUMNS_COMUN,
]
/**
 * @enum
 */
export const TrabajoListMode = {
    ESTUDIOS: 'ESTU',
    VALORACIONES: 'VALO',
    TRABAJOS: 'TRABA',
}


export const columnsFromMode = new Map([
    [TrabajoListMode.TRABAJOS, COLUMNS_TRABAJOS],
    [TrabajoListMode.ESTUDIOS, COLUMNS_ESTUDIOS],
    [TrabajoListMode.VALORACIONES, COLUMNS_VALORACIONES],
])



export const TRABAJO_LIST_FIELDS = [
    TareaFld.asumible,
    TareaFld.urgente,
    TareaFld.idTarea,
    TareaFld.fechaCreacion,
    TareaFld.fechaVencimiento,
    TareaFld.ref,
    TareaFld.descripcion,
    TareaFld.ordenEstudio,
    TareaFld.ordenValoracion,
    TareaFld.idResponsable,
    [TareaFld.tipo, [TipoTareaFld.tipo]],
    [TareaFld.responsable, [UserFld.id, UserFld.username, UserFld.nombreCompleto]],
    [TareaFld.obra, [ObraFld.cod, ObraFld.nombre]],
    [TareaFld.solicitudList, [[SolicitudFld.cuenta, [CuentaFld.cuenta]]]],
    [TareaFld.responsable, [UserFld.username, UserFld.nombreCompleto]],
    [TareaFld.tipo, TipoTareaTable.regularFields],
    [TareaFld.estado, TAREA_ESTADO_FIELDS],
    [TareaFld.aproposiciones, [
        AproposicionFld.idAproposicion,
        [AproposicionFld.aprobacion, [AprobacionFld.estado, AprobacionFld.fechaResolucion]],
        AproposicionFld.posicionOriginal,
        AproposicionFld.nuevaPosicion,
        AproposicionFld.justificacion,
    ]]
]
