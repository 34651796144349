 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AvisoIds} from './Aviso'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const AvisoCol = Object.freeze({
    tipo : new LookupColumn(AvisoIds.tipo, Tables.AvisoTipo, 'tipo'),
    usuario : new LookupColumn(AvisoIds.usuario, Tables.User, 'nombre_completo'),
    obra : new LookupColumn(AvisoIds.obra, Tables.Obra, 'cod'),
    tarea : new LookupColumn(AvisoIds.tarea, Tables.Tarea, 'ref'),
    idAviso : new SimpleColumn(AvisoIds.idAviso, 11, 11, Align.RIGHT),
    fecha : new DateTimeColumn(AvisoIds.fecha, 16, 16, Align.LEFT),
    fechaOnlyDate : new DateColumn(AvisoIds.fecha, 11, 11, Align.LEFT),
    aviso : new SimpleColumn(AvisoIds.aviso, 40, 16, Align.LEFT),
    idPedido : new SimpleColumn(AvisoIds.idPedido, 11, 11, Align.RIGHT),
    pendiente : new SimpleColumn(AvisoIds.pendiente, 9, 9, Align.LEFT),
})

tableColumns[Tables.Aviso] = AvisoCol
