import React, {useReducer, useState} from 'react'
import {useWillUnmount} from 'sopix/hooks/use-will-unmount'
import {action} from 'mobx'
import {boundMethod} from 'autobind-decorator'


export class FieldControllerState{
    
    get value(){
        return this.fieldManager.fields[this.name]
    }

    get error(){
        return this.fieldManager.errors[this.name]
    }
    
    constructor(fieldManager, name, doRender) {
        this.fieldManager = fieldManager
        this.name = name
        this.doRender = doRender
        fieldManager._registerControl(name, this)
    }

    @boundMethod
    changeReaction(newValue) {
        this.fieldManager._onControlFieldChange(this.name, newValue)
    }

    @boundMethod
    blurReaction() {
        this.fieldManager._onControlFieldBlur(this.name)
    }
}


/**
 * 
 * @param {FieldManager} fieldManager
 * @param name
 * @param render
 * @returns {*}
 * @constructor
 */
export function FieldController({fieldManager, name, render}) {

    if (fieldManager === undefined) throw new Error(`fieldManager not defined in ${name}`)

    const [, doRender] = useReducer(state => state + 1, 0, undefined)

    const [state] = useState(() => new FieldControllerState(fieldManager, name, doRender))

    useWillUnmount(() => {
        fieldManager._unregisterControl(name, state)
    })
    
    return render({
        onChange: action(state.changeReaction),
        onBlur: action(state.blurReaction),
        value: state.value,
        error: state.error,
    })
}
