 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const TipologiaIds = Object.freeze({
    obras: Symbol('obras'),
    idTipologia: Symbol('idTipologia'),
    tipologia: Symbol('tipologia'),
    orden: Symbol('orden'),
    subtipologias: Symbol('subtipologias'),
})

export const Tipologia = Object.freeze({
    obras: 'obras',
    idTipologia: 'idTipologia',
    tipologia: 'tipologia',
    orden: 'orden',
    subtipologias: 'subtipologias',
})
