 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {MesIds} from './Mes'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const MesCol = Object.freeze({
    idMes : new SimpleColumn(MesIds.idMes, 11, 11, Align.RIGHT),
    mes : new SimpleColumn(MesIds.mes, 10, 10, Align.LEFT),
    mesCorto : new SimpleColumn(MesIds.mesCorto, 3, 3, Align.LEFT),
})

tableColumns[Tables.Mes] = MesCol
