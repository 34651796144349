 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const ProcedenciaIds = Object.freeze({
    tareas: Symbol('tareas'),
    ejecuciones: Symbol('ejecuciones'),
    idProcedencia: Symbol('idProcedencia'),
    procedencia: Symbol('procedencia'),
})

export const Procedencia = Object.freeze({
    tareas: 'tareas',
    ejecuciones: 'ejecuciones',
    idProcedencia: 'idProcedencia',
    procedencia: 'procedencia',
})
