import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import React, {createRef, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import {defaultTo} from 'lodash-es'

let dialogId = 0


function PaperComponent({id, ...props}) {
    const nodeRef = createRef()
    return (
        <Draggable nodeRef={nodeRef} handle={`#${id}-title`} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

export const OkCancelAlert = ({open, onAccept, onCancel, title, text, acceptLabel, cancelLabel, cancelByDefault = true}) => {

    const [id] = useState(() => `ok-cancel-alert-${++dialogId}`)

    const handleAccept = (...args) => {
        onAccept && onAccept(...args)
    }

    const handleCancel = (...args) => {
        onCancel && onCancel(...args)
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperComponent={PaperComponent}
            PaperProps={{id: id}}
            aria-labelledby={`${id}-title`}
            aria-describedby={`${id}-description`}
        >
            <DialogTitle id={`${id}-title`}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id={`${id}-description`}>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleCancel}
                    color="primary"
                    autoFocus={cancelByDefault ? true : undefined}
                    defaultChecked={cancelByDefault ? true : undefined}
                >
                    {defaultTo(cancelLabel, "Cancelar")}
                </Button>
                <Button
                    onClick={handleAccept}
                    color="primary"
                    autoFocus={cancelByDefault ? undefined: true}
                    defaultChecked={cancelByDefault ? undefined: true}
                >
                    {defaultTo(acceptLabel, "Aceptar")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}