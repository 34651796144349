import {action, computed, observable, runInAction} from 'mobx'
import {StateBasics} from 'sopix/react-state/stateBasics'
import {boundMethod} from 'autobind-decorator'
import {ObserverHub} from 'sopix/utils/observers'
import {find} from 'lodash-es'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'

export class ListState extends StateBasics{

    @observable
    id

    @observable.shallow
    rows = []
    
    @observable
    dirty = false

    @observable
    _selected

    @observable
    /** @type {typeof FormState} */
    form
    
    onSelectObservers = new ObserverHub()
    onLoadObservers = new ObserverHub()

    @computed
    get selected(){
        return this._selected
    }
    
    @computed
    get badgeCount(){
        return this.rows.length === 0 ? undefined : this.rows.length 
    }

    constructor(id, {name='', idField, itemIdField, formClass, table, deleteMutation, icon, 
        errorManager} = {}
    ){
        super({name, icon, errorManager})
        this.id = id
        this.idField = idField
        this.itemIdField = itemIdField
        this.formClass = formClass
        this._deleteMutation = !deleteMutation || !table ? undefined :
            new SimpleMutation(table.graphqlUrl, deleteMutation, ['success'])
    }

    @action.bound
    select(id){
        if (id === this._selected) return

        this._selected = id === undefined ? undefined : parseInt(id)
        const row = id === undefined ? undefined
            : find(this.rows, {[this.itemIdField]: id})
        this.onSelectObservers.notify(row)
    }

    selectOrToggle(id){
        if (this.selected !== id) {
            this.select(id)
        } else {
            this.select(undefined)
        }
    }
    
    
    @computed
    get title(){
        if (this.id === undefined) return `Cargando ${this.name.toLowerCase()} ...`
        else return `${this.name} ${this.id}`
    }

    @computed
    get filteredRows(){
        return this._filterRows()
    }

    async __init(){
        this._apply(...await this._loadData(this.id))        
    }

    @boundMethod
    async _reload(){
        await this._load(this.id)
    }
    reload = this._locked_catched_async(this._reload)


    async __loadData(id){
        return []
    }

    async _loadData(id){
        if (!id) {
            return [[], undefined]
        }
        const rows = await this.__loadData(id) 
        return [rows, id]
    }

    
    __apply(data){
        this.rows = data
    }
    
    @boundMethod
    async _loadAndApply(id){
        if (!id) {
            this.rows = []
            return
        }
        const [data] = await this._loadData(id)
        this._apply(data, id)
    }
    loadAndApply = this._locked_catched_async(this._loadAndApply)
    
    
    @action
    _apply = (data, id) => {
        this.id = id
        this.__apply(data)
        this._afterApply()
    }
    
    @boundMethod
    _afterApply(){
    }
    
    @boundMethod
    async _load(){
        this._apply(...await this._loadData(this.id))
        this.onLoadObservers.notify()
    }
    load = this._locked_catched_async(this._load)


    @boundMethod
    _filterRows() {
        return this.rows
    }

    @boundMethod
    _openItem(itemId){
        this.form = new this.formClass(itemId)
    }
    openItem = this._catched(this._openItem)

    @boundMethod
    _createItem(options){
        this.form = new this.formClass(undefined, {idList: this.id, ...options})
    }
    createItem = this._catched(this._createItem)
    
    @boundMethod
    _closeItem(refresh){
        this.form = undefined
        if (refresh) {
            this.reload()
        }
    }
    closeItem = this._catched(this._closeItem)
    
    @action.bound
    async _deleteItem(itemId){
        await this._deleteMutation.query({ids: [itemId]})
        await this._reload()
        runInAction(() => {
            if (this._selected === undefined) return
            if (find(this.rows, {[this.itemIdField]: this._selected}) === undefined) {
                this.select()
            }
        })
    }
    deleteItem = this._locked_catched_async(this._deleteItem)
}