 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {EjecucionCuentaIds} from './EjecucionCuenta'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {CuentaIds} from './Cuenta'
import {UserIds} from './User'

export const EjecucionCuentaEdt = Object.freeze({
    cuenta : new LookupEditor(EjecucionCuentaIds.cuenta, Tables.Cuenta, CuentaIds.idCuenta, 'cuenta', {sort: 'ID_CUENTA_ASC'}),
    autor : new LookupEditor(EjecucionCuentaIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idEjecucionCuenta : new SimpleEditor(EjecucionCuentaIds.idEjecucionCuenta, 11, 11),
    idRol : new SimpleEditor(EjecucionCuentaIds.idRol, 11, 11),
    comentarios : new SimpleEditor(EjecucionCuentaIds.comentarios, 40, 16),
    fechaCreacion : new DateTimeEditor(EjecucionCuentaIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(EjecucionCuentaIds.fechaCreacion, 11, 11),
    odooCambiado : new SimpleEditor(EjecucionCuentaIds.odooCambiado, 11, 11),
})

tableEditors[Tables.EjecucionCuenta] = EjecucionCuentaEdt
