 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DispositivoIds} from './Dispositivo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, SimpleEditor} from 'sopix/db/dbEditor'

export const DispositivoEdt = Object.freeze({
    idDispositivo : new SimpleEditor(DispositivoIds.idDispositivo, 11, 11),
    fechaCreacion : new DateTimeEditor(DispositivoIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(DispositivoIds.fechaCreacion, 11, 11),
    fechaFin : new DateTimeEditor(DispositivoIds.fechaFin, 16, 16),
    fechaFinOnlyDate : new DateEditor(DispositivoIds.fechaFin, 11, 11),
})

tableEditors[Tables.Dispositivo] = DispositivoEdt
