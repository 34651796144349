 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {LiquidacionIds} from './Liquidacion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserIds} from './User'
import {TareaIds} from './Tarea'

export const LiquidacionEdt = Object.freeze({
    autor : new LookupEditor(LiquidacionIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    responsable : new LookupEditor(LiquidacionIds.responsable, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    tarea : new LookupEditor(LiquidacionIds.tarea, Tables.Tarea, TareaIds.idTarea, 'ref', {sort: 'ID_TAREA_ASC'}),
    idLiquidacion : new SimpleEditor(LiquidacionIds.idLiquidacion, 11, 11),
    fechaCreacion : new DateTimeEditor(LiquidacionIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(LiquidacionIds.fechaCreacion, 11, 11),
    fecha : new DateTimeEditor(LiquidacionIds.fecha, 16, 16),
    fechaOnlyDate : new DateEditor(LiquidacionIds.fecha, 11, 11),
    m2Efectivos : new SimpleEditor(LiquidacionIds.m2Efectivos, 11, 11),
    descripcion : new SimpleEditor(LiquidacionIds.descripcion, 40, 16),
})

tableEditors[Tables.Liquidacion] = LiquidacionEdt
