import {BoundlessRows, DataSide} from 'sopix/boundless/boundless-classes'
import {defaultTo, isFunction} from 'lodash-es'
import {getLoggers, LogLevel} from 'sopix/log'
import {boundMethod} from 'autobind-decorator'
import {DataBlock} from 'sopix/data/dataBlock'

const {debug, info, logEnabled} = getLoggers('Boundless')

// Extra añadido a la ventana de scroll por arriba o por abajo cuando se piden datos.
// Unidad: altura de la ventana de scroll.
export const DEFAULT_PRECACHED_SIZE = 0.2

// Cuanto debe sobresalir una row de la ventana de scroll + precached para ser purgada. Número de alturas de la ventana.
// Unidad: altura de la ventana de scroll.
export const DEFAULT_DISPOSE_SIZE = 0

// Cuando se desplaza muy rápido el scroll y el espacio a rellenar es gigante, máximo tamaño por petición
// Unidad: altura de la ventana de scroll.
export const DEFAULT_MAX_REQUEST_SIZE = 5

// Ampliación del scroll por arriba o abajo para hacer hueco a nuevos datos.
// Unidad: altura de la ventana de scroll.
export const DEFAULT_SCROLL_EXTEND_SIZE = 1


const MAX_SCROLL_HEIGHT = 2000


function scrollTxt(scroll, virtual = false){
    return `{${scroll[0]}:${scroll[0] + scroll[1]}px}`    
}

function scrollDeltaTxt(from, to){
    if (from[0] === to[0] && from[1] === to[1]) return scrollTxt(from)
    else return `${scrollTxt(from)} --(${to[0]-from[0]})--> ${scrollTxt(to)}`    
}

function dataTxt(top, bottom){
    return `<${top}:${bottom}px>`    
}

function dataDeltaTxt(fromTop, fromBottom, toTop, toBottom){
    if (fromTop === toTop && fromBottom === toBottom) return dataTxt(fromTop, fromBottom)
    else return `${dataTxt(fromTop, fromBottom)} --${toTop - fromTop}--> ${dataTxt(toTop, toBottom)}`
}

function rowCountTxt(count){
    return `${count < 0 ? '' : '+'}${count} rows`
}

function createDataBlock(){
    return new DataBlock(0, [], false, true)    
}


export class Boundless {
    precachedSize = DEFAULT_PRECACHED_SIZE
    disposeSize = DEFAULT_DISPOSE_SIZE
    maxRequestSize = DEFAULT_MAX_REQUEST_SIZE
    scrollExtendSize = DEFAULT_SCROLL_EXTEND_SIZE
    defaultRowHeight
    
    containerRef
    bodyRef

    _data
    /** @type {DataBlock} */
    dataBlock
    renderedDataHeight
    renderedDataWidth
    
    /** @type {BoundlessRows} */
    rows
    
    height
    
    rowAvgHeight
    
    isStatic    
    
    rendering = false
    renderRowsNeeded = false

    /** @type {BoundlessManager} */
    boundlessManager

    _actions = []
    
    doRenderBody = () => {}
    doRenderRows = () => {}
    
    
    get precachedHeight(){
        return this.scrollHeight === undefined ? undefined : this.precachedSize * this.scrollHeight
    }
    
    get disposeHeight(){
        return this.scrollHeight === undefined ? undefined : this.disposeSize * this.scrollHeight
    }
    
    get scrollExtendHeight(){
        return this.scrollHeight === undefined ? undefined : this.scrollExtendSize * this.scrollHeight
    }
    
    get maxRequestHeight(){
        return this.scrollHeight === undefined ? undefined : this.maxRequestSize * this.scrollHeight
    }
    
    
    _dataTop
    get dataTop(){
        return this._dataTop === undefined ? this.base : this._dataTop 
    }
    
    set dataTop(value){
        this._dataTop = value
    }
    
    get dataBottom(){
        const totalHeight = this.rows.totalHeight
        return totalHeight === undefined ? undefined : this.dataTop + totalHeight
    }
    
    get data() {
        return this.dataBlock.data
    }
    
    get index() {
        return this.dataBlock.index
    }

    get base(){
        return this.bodyRef && this.bodyRef.current && this.bodyRef.current.offsetTop 
    }
    
    @boundMethod
    registerBody(doRenderBody){
        this.doRenderBody = doRenderBody
    }
    
    @boundMethod
    unregisterBody(){
        this.doRenderBody = () => {}
    }

    @boundMethod
    registerRows(doRenderRows){
        this.doRenderRows = doRenderRows
    }

    @boundMethod
    unregisterRows(){
        this.doRenderRows = () => {}
    }


    get scrollTop(){
        return this.containerRef && this.containerRef.current && this.containerRef.current.scrollTop
    }

    set scrollTop(value){
        if (this.containerRef.current.scrollTop !== value) {
            debug(() => `set scrollTop: ${value}`)
            this.containerRef.current.scrollTop = value
            debug(() => `new scrollTop: ${value}`)
        }
    }
    
    get scrollHeight(){
        const result = this.containerRef && this.containerRef.current && this.containerRef.current.clientHeight
        
        //Cuando el container no tiene límites, scrollHeight crece sin fin en un bucle infinito
        return result > MAX_SCROLL_HEIGHT ? MAX_SCROLL_HEIGHT : result
    }
    
    get scrollBottom(){
        return this.scrollTop + this.scrollHeight
    }
    
    get scrollPair(){
        return [this.scrollTop, this.scrollHeight]
    }
    
    _setData(dataBlock) {
        debug(()=>`setData`)
        this.dataBlock = dataBlock
        this.rows = new BoundlessRows(dataBlock.index, dataBlock.length)
    }
    
    _updateOptions(options) {

        let changed = false

        const update = opt => {
            if (this[opt] !== options[opt]) {
                this[opt] = options[opt]
                changed = true
            }
        }

        update('precachedSize')
        update('disposeSize')
        update('maxRequestSize')
        update('scrollExtendSize')
        update('defaultRowHeight')
        
        return changed
    }

    _resetData(newData){
        this._setData(newData)
        this.dataTop = this.base
    }

    reset(/* DataBlock */ dataBlock= undefined){
        debug(()=>`reset`)

        this._resetData(dataBlock !== undefined ? dataBlock.clone() : createDataBlock())
        this.renderRowsNeeded = true
        
        this.height = 0
        this.renderBodyNeeded = true

        this.scrollTop = 0
    }

    _runActions(){
        let action
        // eslint-disable-next-line no-cond-assign
        while(action = this._actions.pop()){
            action()
        }
    }


    @boundMethod
    resetAction(/* DataBlock */ dataBlock = undefined){
        this._actions.push(() => {
            this.reset(dataBlock)
        })
        this._cycle()
    }
    
    @boundMethod
    invalidateAction(){
        this._actions.push(() => {
                this.reset(new DataBlock(
                    this.dataBlock.index,
                    [],
                    this.dataBlock.hasPreviousData,
                    this.dataBlock.length > 0 || this.dataBlock.hasNextData
                ))
            }
        )
        this._cycle()
    }
    
    @boundMethod
    scrollAction({row, offset}){
        this._actions.push(() => {
                let top = 0
                if (row !== undefined) {
                    top = this.rows.measureTopRows(row - this.dataBlock.index)
                }
                this.scrollTop = top + offset
            }
        )
        this._cycle()
    }
    
    _registerBoundlessManager(/** BoundlessManager */ boundlessManager){
        if (boundlessManager !== this.boundlessManager) {
            this.boundlessManager = boundlessManager
            if (boundlessManager !== undefined){
                boundlessManager._boundless = this
            }
        }        
    }

    _unregisterBoundlessManager(){
        if (this.boundlessManager !== undefined){
            delete this.boundlessManager._boundless
        }
    }

    @boundMethod
    didMount(){
        this.afterRowsRendered()
    }
    
    @boundMethod
    willUnmount(){
        delete this.containerRef
        delete this.bodyRef
        this._unregisterBoundlessManager()
        this.unregisterBody()
    }

    onRender(data, boundlessManager, containerRef, bodyRef, options) {

        debug(()=>`render: ${String(~~(Date.now()/100)/10).substr(-5)}s`)

        this.renderRowsNeeded = false
        this.rendering = true

        this.containerRef = containerRef
        this.bodyRef = bodyRef
        this._registerBoundlessManager(boundlessManager)
        
        if (this._updateOptions(options)) {}

        if (data !== this._data) {
            this._data = data

            if (isFunction(data)) {
                this.isStatic = false
                this.height = 0
                this._setData(createDataBlock())
                
            } else {
                this.isStatic = true
                this.height = undefined
                this._setData(new DataBlock(0, data, false, false))
            }
        }
    }
    
    _get_log_state(){
        if (!logEnabled(LogLevel.DEBUG)) return {}
        return ({
            dataTop: [this.dataTop, this.dataTop + this.rows.totalHeight],
            data: this.dataBlock.asText(),
            rows: this.rows.asText(),
            scroll: this.scrollPair,
        })
    }

    _log_state_delta(before, after = undefined){
        if (!logEnabled(LogLevel.DEBUG)) return
        const after_ = after !== undefined ? after : this._get_log_state()
        
        const dataLog = before.data === after_.data
            ? before.data
            : `${before.data} -> ${after_.data}`

        debug(()=>`   ${dataLog}`)
        debug(()=>`   ${dataLog}`)
        debug(()=>`   ${dataDeltaTxt(before.dataTop[0], before.dataTop[1], after_.dataTop[0], after_.dataTop[1])}`)
        debug(()=>`   ${scrollDeltaTxt(before.scroll, after_.scroll)}`)
    }
    
    _withLog(fn){
        if (!logEnabled(LogLevel.DEBUG)) {
            fn && fn()
            return
        }
        
        const before = this._get_log_state()
        
        if (fn && fn() === false)
            return
        
        this._log_state_delta(before, this._get_log_state())
    }
    
    trimTop(){
        if (this.dataBlock.length === 0) return

        const cutLine = this.scrollTop - this.precachedHeight - this.disposeHeight 
        
        const [trimCount, heightCount] = this.rows.countTopRows(cutLine - this.dataTop)
        
        if (trimCount > 0){
            const before = this.dataBlock.asText() 
            
            this._withLog(() => {
                this.rows.trimTop(trimCount)
                this.dataBlock.trimTop(trimCount)
                this.dataTop = this.dataTop + heightCount
            })

            info(()=>`## trim ${DataSide.TOP} ${heightCount}px, ${rowCountTxt(-trimCount)}, ${before} -> ${this.dataBlock.asText()}`)
            
            this.renderRowsNeeded = true
        }
    }

    trimBottom(){
        if (this.dataBlock.length === 0) return
        
        const cutLine = this.scrollBottom + this.precachedHeight + this.disposeHeight
        
        //Trim bottom
        const [trimCount, heightCount] = this.rows.countBottomRows(this.dataBottom - cutLine)

        if (trimCount > 0){
            info(()=>`## trim ${DataSide.BOTTOM} ${heightCount}px ${rowCountTxt(-trimCount)}`)
            this._withLog(() => {
                this.rows.trimBottom(trimCount)
                this.dataBlock.trimBottom(trimCount)
            })

            this.renderRowsNeeded = true
        }
    }

    get bottomEmptySpace(){
        if (this.rows.dirtyBottom > 0) throw new Error('Cannot measure renderRowsNeeded bottom')
        const bottom = this.dataBottom
        const bottomLimit = this.scrollBottom + this.precachedHeight
        return bottomLimit <= bottom  ? 0 : bottomLimit - bottom
    }

    get topEmptySpace(){
        if (this.rows.dirtyTop > 0) throw new Error('Cannot measure renderRowsNeeded top')
        const top = this.dataTop
        const bottomLimit_ = this.scrollTop - this.precachedHeight
        const bottomLimit = bottomLimit_ < 0 ? 0 : bottomLimit_
        return top < bottomLimit ? 0 : top - bottomLimit 
    }

    _expandTop(expansion){
        if (expansion.length === 0) return false
        
        this._withLog(() => {
            this.rows.expandTop(expansion.length)
            this.dataBlock.extendTop(expansion)
            debug(()=>`expandedTopRows: ${expansion.length} rows`)
            this.renderRowsNeeded = true
        })
        
        return true
    }

    _expandBottom(expansion){
        if (expansion.length === 0) return false
        this.rows.expandBottom(expansion.length)
        this.dataBlock.extendBottom(expansion)
        this.renderRowsNeeded = true
        return true
    }
    
    _getRequestParams(side, emptySpace){
        const index = side === DataSide.TOP ? this.index : this.index + this.dataBlock.length
        
        const avgHeight = this.rows.getAverageHeight()
        
        if (avgHeight !== undefined) {
            this.rowAvgHeight = avgHeight
        }
        
        const stats = {
            scrollTop: this.scrollTop,
            windowHeight: this.scrollHeight + this.precachedHeight,
            avgHeight: defaultTo(this.rowAvgHeight, this.defaultRowHeight) 
        }
        return [side, index, emptySpace, stats]
    }
    
    _requestNo = 0
    async _requestData(/** DataSide */ side, emptySpace){
        
        if (side === DataSide.BOTTOM) this.rows.bottomRequest = true
        else this.rows.topRequest = true

        const no = ++this._requestNo
        let newData
        try {
            const log_time_1 = Date.now()
            if (side === DataSide.BOTTOM) {
                debug(()=>`request #${no} ${side}: ${dataTxt(this.dataTop, this.dataBottom)} +${emptySpace}px`)
            } else {
                debug(()=>`request #${no} ${side}: +${emptySpace}px ${dataTxt(this.dataTop, this.dataBottom)}`)
            }
            /** @type {DataBlock} */
            newData = await this._data(...this._getRequestParams(side, emptySpace))
            const log_time_2 = Date.now() - log_time_1
            debug(()=>`response #${no} ${side}: ${~~(log_time_2 / 100) / 10}s`)
            this._saveNewData(newData, no)

        } finally {
            if (side === DataSide.BOTTOM) this.rows.bottomRequest = false
            else this.rows.topRequest = false
        }

        if (newData !== undefined && newData.length > 0) this._cycle()
    }

    lookupTop() {
        if (this.rows.topRequest) return false
        if (this.rows.dirtyTop > 0) return false
        
        let empty = this.topEmptySpace
        if (empty >= this.maxRequestHeight) {
            empty = this.maxRequestHeight
        }
        if (this.dataBlock.hasPreviousData && empty > 0) {
            this._requestData(DataSide.TOP, empty).then(() => {})
            return true
        }
        
        return false
    }

    lookupBottom() {
        if (this.rows.bottomRequest) return false
        if (this.rows.dirtyBottom > 0) return false

        let empty = this.bottomEmptySpace
        if (empty >= this.maxRequestHeight) {
            empty = this.maxRequestHeight
        }
        if (this.dataBlock.hasNextData && empty > 0) {
            this._requestData(DataSide.BOTTOM, empty).then(() => {})
            return true
        }
        
        return false
    }

    _saveNewData(/* DataBlock */ newData, requestNo){

        if (newData === undefined) {
            debug(()=>`NO extension`)
            return
        } 
 
        const log_before = this.dataBlock.asText()

        const log_extension = extension => {
            info(()=>`## expand ${extension} #${requestNo} ${rowCountTxt(newData.length)}: ${log_before} --> ${this.dataBlock.asText()}`)
            debug(()=>`   ${scrollTxt(this.scrollPair, true)}`)
            debug(()=>`   ${dataTxt(this.dataTop, this.dataBottom)}`)
        }
        
        if (newData.index === this.dataBlock.index + this.dataBlock.length) {
            if (this._expandBottom(newData)) log_extension(DataSide.BOTTOM)
            
        } else if (newData.index + newData.length === this.dataBlock.index) {
            if (this._expandTop(newData)) log_extension(DataSide.TOP)
            
        } else {
            this.reset(newData)
        }
    }
    

    _completeExpansion(){
        if (this.rows.dirtyTop > 0) {
            const dirtyTop = this.rows.dirtyTop
            const heightSum = this.rows.cleanUpTopRows()
            if (heightSum !== false) {
                debug(() => `complete top expansion: ${heightSum}px, +${dirtyTop} rows`)
                this._withLog(() => {
                    if (this.dataBlock.hasPreviousData){
                        this.dataTop -= heightSum
                        if (this.dataTop <= this.base) {
                            this.dataTop += this.scrollExtendHeight
                            this.scrollTop += this.scrollExtendHeight
                        }
                    } else {
                        this.dataTop = this.base
                        this.scrollTop = 0
                    }
                })
                this.renderRowsNeeded = true
            }
        }

        if (this.rows.dirtyBottom > 0) {
            const dirtyBottom = this.rows.dirtyBottom
            const heightSum = this.rows.cleanUpBottomRows()
            if (heightSum !== false) {
                debug(() => `complete top expansion: ${heightSum}px, ${dirtyBottom} rows`)
                this.renderRowsNeeded = true
            }
        }
    }
    
    
    _getExpandHeightParams(height){
        if (height === 0) {
            return [0, 2 * this.scrollExtendHeight]
        }
        
        const expandLine = height - this.scrollExtendHeight
        let newHeight = height + this.scrollExtendHeight
        
        return [expandLine, newHeight]        
    }
    
    _expandHeight(){
        if (!this.dataBlock.hasNextData) {
            if (this.height !== this.dataBottom) {
                this._withLog(() => {
                    debug(() => `Fit height to dataBottom: ${this.dataBottom}px`)
                    this.height = this.dataBottom
                    this.renderBodyNeeded = true
                })
            }
        } else {
            if (this.dataBlock.index === 0 && this.data.length === 0 && this.dataTop < this.scrollHeight) return
            
            if (this.dataBottom < this.scrollTop) return
            
            const [expandLine, newHeight] = this._getExpandHeightParams(this.height)
            
            if (this.scrollBottom > expandLine) {
                this._withLog(() => {
                    debug(() => `Expand height: dataBottom: ${this.scrollBottom}px, expand line: ${expandLine},  ${this.height}px -> ${newHeight}px`)
                    this.height = newHeight
                    this.renderBodyNeeded = true
                })
            }
        }
    }
    
    _cycle(){
        if (this.rendering) return false
        
        this._runActions()
        
        if (!this.renderRowsNeeded) {
            this._expandHeight()
        }
        
        if (this.rows.bottomAllowed) {
            this.trimBottom()
            this.lookupBottom()
        }
        
        if (this.rows.topAllowed) {
            this.trimTop()
            this.lookupTop()
        }
        
        if (this.renderRowsNeeded) {
            debug(()=>`Scroll before render: ${scrollTxt(this.scrollPair)}`)
            this.doRenderRows()
        }

        if (this.renderBodyNeeded) {
            this.renderBodyNeeded = false
            this.doRenderBody()
        }
        
        return true
    }

    @boundMethod
    bodyWidthObserver(width){
        if (this.isStatic) return
        
        if (this.renderedDataHeight !== this.rows.totalHeight 
            || this.renderedDataWidth !== width) {
            this.renderedDataWidth = width
            this.renderRowsNeeded = true
            const cycle = this._cycle()
            info(() => `bodyWidthObserver: width: ${this.bodyRef.current.clientWidth}px cycle: ${cycle}`)
        }
    }


    @boundMethod
    afterRowsRendered(){
        this.rendering = false
        if (this.isStatic) return
        
        //No container ref the first time Rows effect is triggered 
        if (!this.containerRef || !this.containerRef.current) return
        
        this.renderRowsNeeded = false
        this.renderedDataHeight = this.rows.totalHeight
        this._completeExpansion()
        this._cycle()
    }
    
    @boundMethod
    afterBodyRendered(){
        if (this.isStatic) return

        debug(()=>`After body rendered`)
    }
    
    @boundMethod
    scrollObserver(){
        if (this.isStatic) return
        
        const cycle = this._cycle()
        
        info(()=>`scrollObserver: ${this.scrollTop}px cycle: ${cycle}`)
    }
    
}