 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PermisoIds} from './Permiso'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const PermisoCol = Object.freeze({
    idPermiso : new SimpleColumn(PermisoIds.idPermiso, 11, 11, Align.RIGHT),
    permiso : new SimpleColumn(PermisoIds.permiso, 40, 16, Align.LEFT),
    principal : new SimpleColumn(PermisoIds.principal, 11, 11, Align.RIGHT),
    verObras : new SimpleColumn(PermisoIds.verObras, 11, 11, Align.RIGHT),
    modificarObras : new SimpleColumn(PermisoIds.modificarObras, 11, 11, Align.RIGHT),
    crearObras : new SimpleColumn(PermisoIds.crearObras, 11, 11, Align.RIGHT),
    eliminarObras : new SimpleColumn(PermisoIds.eliminarObras, 11, 11, Align.RIGHT),
    verSolicitantes : new SimpleColumn(PermisoIds.verSolicitantes, 11, 11, Align.RIGHT),
    modificarSolicitantes : new SimpleColumn(PermisoIds.modificarSolicitantes, 11, 11, Align.RIGHT),
    agregarSolicitantes : new SimpleColumn(PermisoIds.agregarSolicitantes, 11, 11, Align.RIGHT),
    quitarSolicitantes : new SimpleColumn(PermisoIds.quitarSolicitantes, 11, 11, Align.RIGHT),
    verNotas : new SimpleColumn(PermisoIds.verNotas, 11, 11, Align.RIGHT),
    modificarNotas : new SimpleColumn(PermisoIds.modificarNotas, 11, 11, Align.RIGHT),
    accederNotasPrivadas : new SimpleColumn(PermisoIds.accederNotasPrivadas, 11, 11, Align.RIGHT),
    modificarNotasDeOtros : new SimpleColumn(PermisoIds.modificarNotasDeOtros, 11, 11, Align.RIGHT),
    eliminarNotasDeOtros : new SimpleColumn(PermisoIds.eliminarNotasDeOtros, 11, 11, Align.RIGHT),
    verObrasRelacionadas : new SimpleColumn(PermisoIds.verObrasRelacionadas, 11, 11, Align.RIGHT),
    modificarObrasRelacionadas : new SimpleColumn(PermisoIds.modificarObrasRelacionadas, 11, 11, Align.RIGHT),
    verRepresentantesObra : new SimpleColumn(PermisoIds.verRepresentantesObra, 11, 11, Align.RIGHT),
    modificarRepresentantesObra : new SimpleColumn(PermisoIds.modificarRepresentantesObra, 11, 11, Align.RIGHT),
    verUsuariosMinimo : new SimpleColumn(PermisoIds.verUsuariosMinimo, 11, 11, Align.RIGHT),
    verUsuarios : new SimpleColumn(PermisoIds.verUsuarios, 11, 11, Align.RIGHT),
    verUsuariosAdmin : new SimpleColumn(PermisoIds.verUsuariosAdmin, 11, 11, Align.RIGHT),
    modificarUsuarios : new SimpleColumn(PermisoIds.modificarUsuarios, 11, 11, Align.RIGHT),
    modificarUsuariosAdmin : new SimpleColumn(PermisoIds.modificarUsuariosAdmin, 11, 11, Align.RIGHT),
    permisosRepresentantes : new SimpleColumn(PermisoIds.permisosRepresentantes, 11, 11, Align.RIGHT),
    crearUsuarios : new SimpleColumn(PermisoIds.crearUsuarios, 11, 11, Align.RIGHT),
    paginaUsuarios : new SimpleColumn(PermisoIds.paginaUsuarios, 11, 11, Align.RIGHT),
    verListas : new SimpleColumn(PermisoIds.verListas, 11, 11, Align.RIGHT),
    verCotizaciones : new SimpleColumn(PermisoIds.verCotizaciones, 11, 11, Align.RIGHT),
    modificarCotizaciones : new SimpleColumn(PermisoIds.modificarCotizaciones, 11, 11, Align.RIGHT),
    modificarCotizacionesTecnico : new SimpleColumn(PermisoIds.modificarCotizacionesTecnico, 11, 11, Align.RIGHT),
    crearCotizaciones : new SimpleColumn(PermisoIds.crearCotizaciones, 11, 11, Align.RIGHT),
    eliminarCotizaciones : new SimpleColumn(PermisoIds.eliminarCotizaciones, 11, 11, Align.RIGHT),
    verTareas : new SimpleColumn(PermisoIds.verTareas, 11, 11, Align.RIGHT),
    modificarTareas : new SimpleColumn(PermisoIds.modificarTareas, 11, 11, Align.RIGHT),
    crearTareas : new SimpleColumn(PermisoIds.crearTareas, 11, 11, Align.RIGHT),
    eliminarTareas : new SimpleColumn(PermisoIds.eliminarTareas, 11, 11, Align.RIGHT),
    paginaTrabajos : new SimpleColumn(PermisoIds.paginaTrabajos, 11, 11, Align.RIGHT),
    verCuentas : new SimpleColumn(PermisoIds.verCuentas, 11, 11, Align.RIGHT),
    modificarCuentas : new SimpleColumn(PermisoIds.modificarCuentas, 11, 11, Align.RIGHT),
    crearCuentas : new SimpleColumn(PermisoIds.crearCuentas, 11, 11, Align.RIGHT),
    eliminarCuentas : new SimpleColumn(PermisoIds.eliminarCuentas, 11, 11, Align.RIGHT),
    paginaCuentas : new SimpleColumn(PermisoIds.paginaCuentas, 11, 11, Align.RIGHT),
    verPedidosSage : new SimpleColumn(PermisoIds.verPedidosSage, 11, 11, Align.RIGHT),
    verCuentasSage : new SimpleColumn(PermisoIds.verCuentasSage, 11, 11, Align.RIGHT),
    verPermisos : new SimpleColumn(PermisoIds.verPermisos, 11, 11, Align.RIGHT),
    modificarPermisos : new SimpleColumn(PermisoIds.modificarPermisos, 11, 11, Align.RIGHT),
    verConfiguracion : new SimpleColumn(PermisoIds.verConfiguracion, 11, 11, Align.RIGHT),
    modificarConfiguracion : new SimpleColumn(PermisoIds.modificarConfiguracion, 11, 11, Align.RIGHT),
    paginaConfiguracion : new SimpleColumn(PermisoIds.paginaConfiguracion, 11, 11, Align.RIGHT),
    verLiquidaciones : new SimpleColumn(PermisoIds.verLiquidaciones, 11, 11, Align.RIGHT),
    modificarLiquidaciones : new SimpleColumn(PermisoIds.modificarLiquidaciones, 11, 11, Align.RIGHT),
    verAvisos : new SimpleColumn(PermisoIds.verAvisos, 11, 11, Align.RIGHT),
    verAprobaciones : new SimpleColumn(PermisoIds.verAprobaciones, 11, 11, Align.RIGHT),
    verEjecuciones : new SimpleColumn(PermisoIds.verEjecuciones, 11, 11, Align.RIGHT),
    modificarEjecuciones : new SimpleColumn(PermisoIds.modificarEjecuciones, 11, 11, Align.RIGHT),
    verDocumentos : new SimpleColumn(PermisoIds.verDocumentos, 11, 11, Align.RIGHT),
    verArchivosDocumento : new SimpleColumn(PermisoIds.verArchivosDocumento, 11, 11, Align.RIGHT),
    modificarDocumentos : new SimpleColumn(PermisoIds.modificarDocumentos, 11, 11, Align.RIGHT),
    eliminarUsuarios : new SimpleColumn(PermisoIds.eliminarUsuarios, 11, 11, Align.RIGHT),
    repartirValoraciones : new SimpleColumn(PermisoIds.repartirValoraciones, 11, 11, Align.RIGHT),
    solicitarCambioEstado : new SimpleColumn(PermisoIds.solicitarCambioEstado, 11, 11, Align.RIGHT),
    solicitarCambioPosicion : new SimpleColumn(PermisoIds.solicitarCambioPosicion, 11, 11, Align.RIGHT),
    solicitarEnvioPlanos : new SimpleColumn(PermisoIds.solicitarEnvioPlanos, 11, 11, Align.RIGHT),
    solicitarLiquidacion : new SimpleColumn(PermisoIds.solicitarLiquidacion, 11, 11, Align.RIGHT),
    verM2Efectivos : new SimpleColumn(PermisoIds.verM2Efectivos, 11, 11, Align.RIGHT),
    modificarM2Efectivos : new SimpleColumn(PermisoIds.modificarM2Efectivos, 11, 11, Align.RIGHT),
    aprobarCambioEstado : new SimpleColumn(PermisoIds.aprobarCambioEstado, 11, 11, Align.RIGHT),
    aprobarCambioPosicion : new SimpleColumn(PermisoIds.aprobarCambioPosicion, 11, 11, Align.RIGHT),
    aprobarLiquidacion : new SimpleColumn(PermisoIds.aprobarLiquidacion, 11, 11, Align.RIGHT),
    aprobarLiquidacionManual : new SimpleColumn(PermisoIds.aprobarLiquidacionManual, 11, 11, Align.RIGHT),
    aprobarEnvioPlanos : new SimpleColumn(PermisoIds.aprobarEnvioPlanos, 11, 11, Align.RIGHT),
    cambiarResponsableCotizacion : new SimpleColumn(PermisoIds.cambiarResponsableCotizacion, 11, 11, Align.RIGHT),
    enviarPlanos : new SimpleColumn(PermisoIds.enviarPlanos, 11, 11, Align.RIGHT),
    cambiarSeguimientoPlanos : new SimpleColumn(PermisoIds.cambiarSeguimientoPlanos, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Permiso] = PermisoCol
