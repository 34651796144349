import React from 'react'
import {observer} from 'mobx-react'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {Frame} from 'sopix/formComps/Frame'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {UsuarioSearch, UsuarioSearchPiece} from 'sop/componentes/usuario/UsuarioSearch'
import {UsuarioListPiece, UsuarioRow} from 'sop/componentes/usuario/UsuarioList'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {Push} from 'sopix/formComps/Push'
import {icons} from 'sopix/icon/icons'
import {listFormSplitDefault} from 'sop/sop-config'
import {ErrorList} from 'sopix/errors/ErrorList'
import {UsuarioForm, UsuarioFormPiece} from 'sop/componentes/usuario/UsuarioForm'
import {FormControl} from 'sopix/piece-components/FormControl'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {UrlManager} from 'sopix/pieces/urlManager'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {linkPageListSearchFormUrlWithApply} from 'sopix/piece-linkers/list-form-url'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {getAuthSingleton} from 'sopix/session/auth-singletons'
import {User} from 'sop/db/User'

export class UsuarioPagePiece extends PagePiece{

    constructor() {
        super()
        this.form = new UsuarioFormPiece(this.world)
        this.urlManager = new UrlManager(this.world, this.form.idField, sopUrls.usuarios, sopUrls.usuario)
        this.search = new UsuarioSearchPiece(this.world)
        this.list = new UsuarioListPiece(this.world)

        linkPageListSearchFormUrlWithApply(this, this.list, this.search, this.form, this.urlManager)        
    }

}


export const getUsuarioPage = getAuthSingleton(sopPages.usuarios.id, () => {
    return new UsuarioPagePiece(sopPages.usuarios)
})


export const UsuarioPage = observer(
    /**
     * 
     * @param {UsuarioPagePiece} page
     * @returns {JSX.Element}
     */
    ({page}) => {

        usePiece(page)
        
        const id = page.form.getField(User.id)
        
        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame piece={page} renderPiece={()=> {
                    return (
                        <VerticalSplit id="UsuarioPage" defaultSizes={listFormSplitDefault}>
                            <Frame>
                                <Bar>
                                    <UsuarioSearch search={page.search} />
                                    {!userAuth.isAllowed(Permiso.crearUsuarios) ? null :
                                        <HintIconButton Icon={sopIcons.Usuario} badge='+'
                                                        badgeColor={sopColors.badgeAdd}
                                                        disabled={page.form.valid && !id}
                                                        large title="Crear nuevo usuario"
                                                        onClick={async () => {
                                                            await page.form.create()
                                                            page.form.focus && page.form.focus()
                                                        }}
                                        />
                                    }
                                    <HintIconButton Icon={icons.Reload}
                                                    large title="Actualizar" onClick={page.__refresh}
                                    />
                                </Bar>
                                <GraphqlTable list={page.list} Row={UsuarioRow} />
                            </Frame>
                            <Frame>
                                <Bar>
                                    <ListNavigator list={page.list} /><Separator rightGap />
                                    <Title>{page.form.recordName}</Title>
                                    <Push />
                                    <FormControl form={page.form}/>
                                </Bar>
                                <UsuarioForm form={page.form} />
                            </Frame>
                        </VerticalSplit>
                    )

                }} />
            </>
        )
    })