import {sopIcons} from 'sop/sop-icons'
import {StateBasics} from 'sopix/react-state/stateBasics'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {getGraphqlOrder, calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {computed, observable, runInAction} from 'mobx'
import {boundMethod} from 'autobind-decorator'
import {ProcedenciaTable} from 'sop/db/ProcedenciaTable'
import {Procedencia} from 'sop/db/Procedencia'
import {SaveMutation} from 'sopix/db-access/saveMutation'
import {tableColumnsFromDb} from 'sopix/boundless-db/boundless-db-utils'
import {ProcedenciaCol} from 'sop/db/ProcedenciaColumns'
import {remove} from 'lodash-es'
import {ICON_BUTTON_SIZE_FIT} from 'sopix/formComps/HintIconButton'

export class ConfigProcedenciasState extends StateBasics{
    
    order = []

    idField = Procedencia.idProcedencia

    columns = [
        ...tableColumnsFromDb([ProcedenciaCol.procedencia]),
        {
            id: 'actions',
            title: '',
            align: 'right',
            minWidth: ICON_BUTTON_SIZE_FIT,
            maxWidth: ICON_BUTTON_SIZE_FIT,
        },
    ]

    @observable.shallow
    initialRows = []

    //ATENCIÓN: No puede ser shallow porque se vuelven los controles readonly
    @observable
    rows = []
    
    deleted = []

    _ratios
    
    keyCount = -1

    loadQuery = new TableGraphql(ProcedenciaTable)
    saveMutation = new SaveMutation(ProcedenciaTable, 'MultiSaveProcedencia',
        {listOfRows: true})

    constructor({errorManager}) {
        super({
            name: "Procedencias",
            icon: sopIcons.Procedencia,
            errorManager: errorManager,
        });
    }

    async _load(){
        const {rows} = await this.loadQuery.query(
            ProcedenciaTable.regularFields, {
                sort: calcGraphqlOrder(Procedencia.idProcedencia)})
        
        for(let row of rows){
            row.key = row[this.idField]
        }

        runInAction(() => {
            this.rows = rows
            this._undirty()
        })
    }
    
    async __init() {
        await this._load()
    }

    _undirty(){
        this.initialRows = this.rows.map(row => ({...row}))
    }

    @computed
    get dirty(){
        return JSON.stringify(this.rows) !== JSON.stringify(this.initialRows)
    }

    @boundMethod
    async _save(){
        if (!this.dirty) return
        
        await this.saveMutation.query(this.rows,
            {initialData: this.initialRows, idField: this.idField})

        runInAction(() => this._undirty())
    }

    @boundMethod
    _revert(){
        this.rows = this.initialRows
        this._undirty()
    }
    
    @boundMethod
    _addNew(){
        this.rows=[...this.rows, {key: this.keyCount--}]
    }
    addNew = this._catched(this._addNew)
    
    @boundMethod
    _delete(key){
        if (remove(this.rows, row => row.key === key).length > 0) {
            this.rows = [...this.rows]
        }
    }
    delete = this._catched(this._delete)
    
    
}