import {makeStyles} from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, {forwardRef} from 'react'
import {Paper} from '@material-ui/core'

const useStyles = makeStyles(() => ({
    fakeModal: {
        minWidth: 400,
        minHeight: 200,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        position: 'relative',
        top: 5,
        left: -5,
        marginRight: 8, 
    },
    title: {
        cursor: 'default',
    }
}))

export const ModelessFrame = forwardRef(({title, Icon, onClose, maxWidth, children, ...otherDialogProps}, ref) => {

    const cls = useStyles()
    
    const icon = Icon === undefined ? '' : <Icon className={cls.icon}/>
    
    return (
        <Paper ref={ref} className={cls.fakeModal} elevation={5} style={!maxWidth ? {} : {maxWidth: maxWidth}}>
            <DialogTitle className={cls.title}>{icon}{title}</DialogTitle>
            {children}
        </Paper>
    )
})