 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PlanoEstadoIds} from './PlanoEstado'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const PlanoEstadoCol = Object.freeze({
    idEstado : new SimpleColumn(PlanoEstadoIds.idEstado, 11, 11, Align.RIGHT),
    estado : new SimpleColumn(PlanoEstadoIds.estado, 14, 10, Align.LEFT),
})

tableColumns[Tables.PlanoEstado] = PlanoEstadoCol
