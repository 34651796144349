import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Bar} from 'sopix/formComps/Bar'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {sopColors} from 'sop/sop-colors'
import {observer} from 'mobx-react'
import {Permiso} from 'sop/db/Permiso'
import {userAuth} from 'sopix/session/userAuth'

const useStyles = makeStyles(() => ({
    obraMap: {
        flexGrow: 1,
    },
}))

/**
 * 
 * @param {ObraMapState} obraMap
 * @returns {JSX.Element}
 * @constructor
 */
export const ObraMap = observer(
({obraMap, direction}) => {
    
    const cls = useStyles()
    
    useEffect(() => {
        obraMap.mountReaction()
        
        return obraMap.unmountReaction
        
    }, [obraMap])
    
    return (
        <>
            <Bar direction={direction}>
                {!userAuth.isAllowed(Permiso.modificarObras) ? null : 
                    <HintIconButton
                        Icon={icons.Sync}
                        title={`Sincronización: ${obraMap.sync ? "Activada" : "Desactivada"}`}
                        color={obraMap.sync ? sopColors.enabled : sopColors.disabled}
                        onClick={obraMap.toggleSync}
                    />
                }
            </Bar>
            <div id="obra-map" className={cls.obraMap}>
            </div>
        </>
    )
})
