import {getGraphqlOrderCalculator} from 'sopix/db/graphql-utils'
import {boundMethod} from 'autobind-decorator'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {GraphqlDataSource} from 'sopix/boundless-db/graphqlDataSource'
import {getLoggers} from 'sopix/log'
import {Filtrator} from 'sopix/filtrator/filtrator'

const {debug} = getLoggers('filtrator')

export class GraphqlFiltrator extends Filtrator{

    /** @type {GraphqlDataSource} */
    source
    

    /**
     *
     * @param {PieceWorld} world
     * @param {GraphqlDataSource} graphqlDataSource
     * @param fieldDescriptors
     * @param graphqlOrderCalculator
     * @param graphqlFilterCalculator
     * @param defaultFilter
     * @param defaultOrder
     */
    constructor(world, graphqlDataSource, fieldDescriptors,
                {graphqlFilterCalculator, defaultFilter = {}, defaultOrder = []} = {}) {
        super(world, {defaultFilter, defaultOrder})

        this._graphqlFilterCalc = !graphqlFilterCalculator ? () => ({}) : graphqlFilterCalculator
        this._graphqlOrderCalc = getGraphqlOrderCalculator(fieldDescriptors)

        this.source = graphqlDataSource

        this.source.onSetData.subscribe(this._action(data => {
            this._setData(data)
        }))
    }

    
    @boundMethod
    async _doRefresh() {
        //await this.source.refresh()
        await this._doFilterAndSort(this.currentFilter, this.currentOrder)
    }


    @boundMethod
    async _doFilter(filter){
        await this.source.fetchAndReset(this._graphqlFilterCalc(filter), undefined)
    }
    

    @boundMethod
    async _doSort(order) {
        await this.source.fetchAndReset(undefined, this._graphqlOrderCalc(order))
    }
    

    @boundMethod
    async _doFilterAndSort(filter, order){
        await this.source.fetchAndReset(this._graphqlFilterCalc(filter), this._graphqlOrderCalc(order))
    }


    @boundMethod
    async _doClearData() {
        await this.source.clearData()
    }

}



export function buildVirtualFiltrator(world, table, fieldDescriptors, defaultFilter = {}, defaultOrder = [],
                                      {
                                    graphqlId, graphqlFilterCalculator = () => {
                                },
                                } = {}) {
    const graphqlEndPoint = new TableGraphql(table,
        {graphqlId: graphqlId || table.graphqlId})

    const graphqlOrderCalculator = getGraphqlOrderCalculator(fieldDescriptors)

    const graphqlProvider = new GraphqlDataSource(world, graphqlEndPoint, fieldDescriptors,
        graphqlFilterCalculator(defaultFilter), graphqlOrderCalculator(defaultOrder),
    )

    return new GraphqlFiltrator(world, graphqlProvider, fieldDescriptors, {
        graphqlFilterCalculator, defaultFilter, defaultOrder,
    })
}