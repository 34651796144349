import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {NotaTable} from 'sop/db/NotaTable'
import {Nota} from 'sop/db/Nota'
import {NOTA_FIELDS} from 'sop/db-config/fieldSets'
import {StaticFiltrator} from 'sopix/filtrator/staticFiltrator'
import {boundMethod} from 'autobind-decorator'
import {NotaFormPiece} from 'sop/componentes/obra-nota/NotaForm'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {Piece} from 'sopix/piece/piece'
import {linkSubsetDbFiltratorForm} from 'sopix/piece-linkers/subsetdb-filtrator-form'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {action} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {isArray} from 'lodash-es'
import {linkPieceDataSource} from 'sopix/piece-linkers/piece-data-source'


const FILTER_ENABLED = 'ENABLED'
const FILTER_SOLICITUD = 'SOLICITUD'

const SORT_OBRA_FIRST = Symbol('SORT_OBRA_FIRST')
const SORT_SOLICITANTES_FIRST = Symbol('SORT_SOLICITANTES_FIRST')

function getOrderFor(enabled, solicitud){
    return enabled && solicitud ? [SORT_SOLICITANTES_FIRST] : [SORT_OBRA_FIRST] 
}


class NotaFiltrator extends StaticFiltrator{

    @boundMethod
    async _doFilterAndSort(filter, order){
        await super._doFilterAndSort(filter, order)
    }
    
    
    _filterData(data, filter) {
        let rows = []

        const flt = getFieldExtractor(filter)
        
        const enabled = flt(FILTER_ENABLED)
        const filterIdSolicitud = flt(FILTER_SOLICITUD, Nota.idSolicitud)

        //Filter
        if (!enabled || !filterIdSolicitud) {
            return data
        } else {
            for (let nota of data) {
                const idSolicitud = nota[Nota.idSolicitud]
                if (!filterIdSolicitud || !idSolicitud || idSolicitud === filterIdSolicitud) {
                    rows.push(nota)
                }
            }
        }

        return rows
    }

    _sortData(data, order) {
        const sort = (a,b) => {
            if (!a[Nota.destacada] || !b[Nota.destacada]) {
                return !a[Nota.destacada] ? 1 : -1
            } else {
                return a[Nota.fechaCreacion] < b[Nota.fechaCreacion] ? 1 : -1
            }
        }

        const sortSolicitantesFirst = (a,b) => {
            if (!a[Nota.idSolicitud] || !b[Nota.idSolicitud]) {
                return !a[Nota.idSolicitud] ? 1 : -1
            } else {
                return sort(a,b)
            }
        }

        const sortObraFirst = (a,b) => {
            if (!a[Nota.idSolicitud] !== !b[Nota.idSolicitud]) {
                return !a[Nota.idSolicitud] ? -1 : 1
            } else {
                return sort(a,b)
            }
        }

        const solicitantesFirst = isArray(order) && order.length && order[0] === SORT_SOLICITANTES_FIRST
        
        return data.sort(solicitantesFirst ? sortSolicitantesFirst : sortObraFirst)
    }
    
    get solicitud(){
        return this.getFilter(FILTER_SOLICITUD)
    }

    get enabled(){
        return this.getFilter(FILTER_ENABLED)
    }
    
    get isFiltered(){
        return this.enabled && !!this.solicitud
    }

    @action
    async setFilterSolicitud(solicitud){
        this.modifyCurrentFilter({[FILTER_SOLICITUD]: solicitud})
        const order = getOrderFor(this.enabled, solicitud)
        this.setCurrentOrder(order)
        await this.refresh()
    }
    
    async setEnabled(enabled){
        this.modifyCurrentFilter({[FILTER_ENABLED]: enabled})
        await this.refresh()
    }
    
    @boundMethod
    async toggleFilter(){
        await this.setEnabled(!this.enabled)
    }
    __toggleFilter = this._asyncAction(this.toggleFilter)

    @boundMethod
    async clearSolicitud() {
        if (!this.solicitud) return
        const filter = this.currentFilter
        delete filter[FILTER_SOLICITUD]
        await this.filter(filter)
    }
    
}


export class NotaListPiece extends Piece{

    /** @type {SubsetDbSource} */
    dbSource

    /** @type {NotaFiltrator} */
    filtrator
    
    /** @param {PieceWorld} world */
    constructor(world) {
        super(world)
        
        this.dbSource = new SubsetDbSource(world,
            Nota.idObra,
            new TableGraphql(NotaTable),
            NOTA_FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(Nota.fechaCreacion, OrderDirection.DESC)},
        })
        
        this.filtrator = new NotaFiltrator(world, this.dbSource, {
            defaultFilter: {[FILTER_ENABLED]: true},
            defaultOrder: getOrderFor(true, undefined),
        }) 
        
        this.form = new NotaFormPiece(world.cloneWithOwnErrors())
        this.poper = new FormPoper(this.form)
        
        this.load = this._state(Symbol('LOAD'), this.dbSource.loadSubset)
        
        
        linkPieceDataSource(this, this.filtrator)
        linkSubsetDbFiltratorForm(this.dbSource, this.filtrator, this.form)
        
        this.filtrator.onFilter.subscribe(filter => {
            this.form.newRowFields[Nota.idSolicitud] = this.filtrator.getFilter(FILTER_SOLICITUD, Nota.idSolicitud)
        })
        
        this.dbSource.onSetData.subscribe(id => {
            this.filtrator.clearSolicitud().then()
        })
        
    }

}