import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {usePiece} from 'sopix/piece/use-piece'


export class CuentaSearchPiece extends SearchPiece{
    
}


/**
 * 
 * @param {SearchPiece} search
 * @returns {JSX.Element}
 * @constructor
 */
export const CuentaSearch = ({search, extra}) => {

    usePiece(search)

    return (
        <PieceFrame piece={search} renderPiece={()=> {

            const fld = search.fieldHelper
            const man = search.fieldManager

            return (
                <>
                    <Field manager={man} name="buscar" label="buscar" autoFocus/>
                    <Push />
                    {!extra ? null: extra}
                </>
            )

        }} />
    )
}