import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {OBRA_FIELDS_MIN, SOLICITUD_FIELDS_MIN, TAREA_ESTADO_FIELDS, USER_FIELDS_MIN} from 'sop/db-config/fieldSets'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {linkSubsetDbFiltratorForm} from 'sopix/piece-linkers/subsetdb-filtrator-form'
import {Tarea} from 'sop/db/Tarea'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {TareaCol} from 'sop/db/TareaColumns'
import {UserCol} from 'sop/db/UserColumns'
import {SolicitudCol} from 'sop/db/SolicitudColumns'
import {TipoTareaTable} from 'sop/db/TipoTareaTable'
import {TrabajoFormPiece} from 'sop/componentes/trabajo/TrabajoFormPiece'
import {TableFiltrator} from 'sopix/filtrator/tableFiltrator'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {action, computed} from 'mobx'
import {getSolicitanteListColumn} from 'sop/componentes/trabajo/trabajo-utils'
import {getColumnTextFormatter} from 'sopix/db/db-column-utils'
import React from 'react'
import {TrabajoPainter} from 'sop/componentes/trabajo/trabajoPainter'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {Tree} from 'sopix/tree-data/tree-classes'
import {boundMethod} from 'autobind-decorator'
import {find} from 'lodash-es'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {Solicitud} from 'sop/db/Solicitud'
import {RowDeleter} from 'sopix/db-access/rowDeleter'

export const BUTTONS_TAREA_LIST_LEFT = 'buttons-left'
export const BUTTONS_TAREA_LIST_RIGHT = 'buttons-right'
export const TAREA_LIST_DRAG = 'drag'

const FILTER_ENABLED = 'ENABLED'
const FILTER_SOLICITUD = 'SOLICITUD'

//Undefined: desactivado
//Lista vacía: activado sin selección
//Objeto Trabajo: activado con selección
const FILTER_FAMILIA = 'FAMILIA'

class TrabajoFiltrator extends TableFiltrator{

    @computed
    get isFiltered(){
        return this.enabled && !!this.filterSolicitud
    }

    get enabled(){
        return this.currentFilter[FILTER_ENABLED]
    }
    
    @boundMethod
    async setEnabled(enabled){
        await this.filter({[FILTER_ENABLED]: enabled}, {mix: true})
    }
    __setEnabled = this._asyncAction(this.setEnabled)
    
    @computed
    get filterSolicitud(){
        return this.currentFilter[FILTER_SOLICITUD]
    }

    @computed
    get filterFamilia(){
        return this.currentFilter[FILTER_FAMILIA]
    }
    
    @action
    async setFilterSolicitud(solicitud){
        await this.filter({[FILTER_SOLICITUD]: solicitud}, {mix: true})
    }
    
    @action
    async setFilterFamilia(trabajos){
        await this.modifyCurrentFilter({[FILTER_FAMILIA]: trabajos})
        await this.refresh()
    }
    
    @boundMethod
    _filterData(data, filter) {

        const flt = getFieldExtractor(filter)
        const enabled = flt(FILTER_ENABLED)
        const idSol = flt(FILTER_SOLICITUD, Solicitud.idSolicitud)
        let familia = filter[FILTER_FAMILIA]
        if (familia && !familia.length){
            familia = undefined
        }
        
        if ((!enabled || !idSol) && !familia) {
            return data
        }

        const result = []
        for(let row of data){
            if (familia && familia.indexOf(row[Tarea.idTarea]) < 0) {
                continue
            }
            
            if (!enabled || !idSol){
                result.push(row)
                continue
            }
            
            const sol = row[Tarea.solicitudList] || []
            if (find(sol, {[Solicitud.idSolicitud]: idSol})) {
                result.push(row)
            }
        }
        return result
    }
    
    _sortFormat(id, value, dbCol, row) {
        switch (id) {
            case Tarea.solicitudList:
                return getSolicitanteListColumn(value, {textOnly: true})
            case Tarea.fechaVencimiento:
            case Tarea.fechaCreacion:
                return value
            case Tarea.estado:
                return row[Tarea.idEstado]
            case Tarea.ref:
                return getColumnTextFormatter(dbCol)(value)
            default:
                return super._sortFormat(id, value, dbCol, row)
        }
    }
}




export class ObraTrabajoListPiece extends TablePiece{

    /** @type {SubsetDbSource} */
    dbSource

    /** @type {Tree} */
    trabajoTree

    /** @type {TrabajoFiltrator} */
    filtrator
    
    /** @param {PieceWorld} world */
    constructor(world) {

        const COLUMNS = [
            BUTTONS_TAREA_LIST_LEFT,
            TareaCol.ref,
            Tarea.estado,
            [Tarea.responsable, UserCol.username],
            TareaCol.descripcion,
            [Tarea.solicitudList, SolicitudCol.cuenta],
            TareaCol.fechaCreacionOnlyDate,
            BUTTONS_TAREA_LIST_RIGHT,
        ]

        const TAREA_FIELDS = [
            ...TareaTable.regularFields,
            [TareaFld.padre, [TareaFld.idTarea, TareaFld.ref]],
            [TareaFld.tipo, TipoTareaTable.regularFields],
            [TareaFld.obra, OBRA_FIELDS_MIN],
            [TareaFld.responsable, USER_FIELDS_MIN],
            [TareaFld.solicitudList, SOLICITUD_FIELDS_MIN],
            [TareaFld.estado, TAREA_ESTADO_FIELDS],
        ]
        
        const ID_FIELD = Tarea.idTarea

        const dbSource = new SubsetDbSource(world,
            Tarea.idObra,
            new TableGraphql(TareaTable),
            TAREA_FIELDS)

        const filtrator = new TrabajoFiltrator(world, dbSource, ID_FIELD, COLUMNS, {
            defaultOrder: [new OrderEntry(Tarea.fechaCreacion, OrderDirection.DESC)],
            defaultFilter: {[FILTER_ENABLED]: true},
        })

        const painter = new TrabajoPainter(world, COLUMNS)

        const selection = new RowSelection(world, filtrator, ID_FIELD, {deselectOnClick:true})

        super(world, ID_FIELD, filtrator, painter, selection)
        this.dbSource = dbSource

        this.rowDeleter = new RowDeleter(world, TareaTable, 'DeleteTrabajo')
        this.rowDeleter.onDelete.subscribe(this.dbSource.refresh)

        this.form = new TrabajoFormPiece(world.cloneWithOwnErrors())
        this.poper = new FormPoper(this.form)
        this.form.aproposicionForm.onSave.subscribe(() => {
            Promise.all([
                this.dbSource.refresh(),
                this.form.refresh(),
            ])
        })
        this.form.aproestaForm.onSave.subscribe(() => {
            Promise.all([
                this.dbSource.refresh(),
                this.form.refresh(),
            ])
        })


        this.load = this._state(Symbol('LOAD'), dbSource.loadSubset)

        linkSubsetDbFiltratorForm(dbSource, filtrator, this.form)

        filtrator.onRefresh.subscribe(this.selection.listRefreshed)
        
        filtrator.onFilter.subscribe(() => {
            const solicitud = filtrator.filterSolicitud
            if (solicitud) {
                this.form.newRowFields[Tarea.solicitudList] = [solicitud]
            } else {
                this.form.newRowFields[Tarea.solicitudList] = []
            }
        })
        
        dbSource.onSetData.subscribe(data => {
            this.trabajoTree = new Tree(data, {idKey: Tarea.idTarea, parentIdKey: Tarea.idPadre})
        })
        
        this.selection.onRowSelected.subscribe(async () => {
            if (this.filtrator.filterFamilia) {
                await this.updateFilterFamilia()
            }
        })
    }


    _getFamiliaIds(){
        const selected = this.selection.row 
        if (!selected) {
            return []
        }
        
        const trabajos = []
        const selNode = this.trabajoTree.nodes.get(this.selection.id)
        for(let [id, node] of this.trabajoTree.nodes.entries()){
            const level = selNode.getDescendantLevel(node)
            if (level !== undefined) {
                trabajos.push(id)
            }
        }
        return trabajos
    }
    
    @boundMethod
    async updateFilterFamilia(){
        const ids = this._getFamiliaIds()
        await this.filtrator.setFilterFamilia(ids)
    }
    
    @boundMethod
    async toggleFilterFamilia(){
        if (this.filtrator.filterFamilia) {
            await this.filtrator.setFilterFamilia()
        } else {
            await this.updateFilterFamilia()
        }
    }
}