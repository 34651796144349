 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ContactoIds} from './Contacto'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserIds} from './User'

export const ContactoEdt = Object.freeze({
    autor : new LookupEditor(ContactoIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    modificacion : new LookupEditor(ContactoIds.modificacion, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idContacto : new SimpleEditor(ContactoIds.idContacto, 11, 11),
    idCuenta : new SimpleEditor(ContactoIds.idCuenta, 11, 11),
    nombre : new SimpleEditor(ContactoIds.nombre, 40, 16),
    tel : new SimpleEditor(ContactoIds.tel, 40, 16),
    correo : new SimpleEditor(ContactoIds.correo, 40, 16),
    notas : new SimpleEditor(ContactoIds.notas, 40, 16),
    principal : new SimpleEditor(ContactoIds.principal, 11, 11),
    fechaAlta : new DateTimeEditor(ContactoIds.fechaAlta, 16, 16),
    fechaAltaOnlyDate : new DateEditor(ContactoIds.fechaAlta, 11, 11),
    fechaModificacion : new DateTimeEditor(ContactoIds.fechaModificacion, 16, 16),
    fechaModificacionOnlyDate : new DateEditor(ContactoIds.fechaModificacion, 11, 11),
    odooCambiado : new SimpleEditor(ContactoIds.odooCambiado, 11, 11),
})

tableEditors[Tables.Contacto] = ContactoEdt
