import React from 'react'
import {SplitPane} from 'react-multi-split-pane'
import './react-multi-split-pane.css'

export function PersistentSplitPane({id, split, minSize = 50, defaultSizes, children}) {

    const storageId = `SplitPane_${id}`

    const storedSizes = JSON.parse(localStorage.getItem(storageId))
    
    return (
        <SplitPane
            split={split}
            misSize={minSize}
            defaultSizes={storedSizes || defaultSizes}
            onDragFinished={size => localStorage.setItem(storageId, JSON.stringify(size))}
        >
            {children}
        </SplitPane>
    )
}
