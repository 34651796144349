import React from 'react'
import {ModalContent} from 'sopix/modal/ModalContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'
import {LoginScreen} from 'sop/login/loginState'
import Typography from '@material-ui/core/Typography'
import {useKeyPressed} from 'sopix/hooks/use-key-pressed'

export const ResetSuccessForm = observer(
/**
 * 
 * @param {LoginState} login
 * @param children
 * @returns {JSX.Element}
 */
({login, children}) => {
        
    useKeyPressed(key => {
        if (key === 'Enter' || key === 'Escape'){
            login.setScreen(LoginScreen.LOGIN)
        }
    })
    
    return (
        <>
            <ModalContent>
                <Typography>
                    Para continuar, inicia sesión con tu nueva contraseña.
                </Typography>

            </ModalContent>
            <DialogActions>
                <Button
                    onClick={() => login.setScreen(LoginScreen.LOGIN)}
                    color="primary"
                    defaultChecked={true}
                >
                    Iniciar sesión
                </Button>
            </DialogActions>
        </>
    )
})
