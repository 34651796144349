 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {FileIds} from './File'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, SimpleEditor} from 'sopix/db/dbEditor'

export const FileEdt = Object.freeze({
    idFile : new SimpleEditor(FileIds.idFile, 11, 11),
    idAutor : new SimpleEditor(FileIds.idAutor, 11, 11),
    fileSize : new SimpleEditor(FileIds.fileSize, 40, 16),
    filePath : new SimpleEditor(FileIds.filePath, 40, 16),
    fechaCreacion : new DateTimeEditor(FileIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(FileIds.fechaCreacion, 11, 11),
    borrado : new SimpleEditor(FileIds.borrado, 11, 11),
    size : new SimpleEditor(FileIds.size, 11, 11),
})

tableEditors[Tables.File] = FileEdt
