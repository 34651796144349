 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const CuentaIds = Object.freeze({
    solicitudes: Symbol('solicitudes'),
    direcciones: Symbol('direcciones'),
    avisos: Symbol('avisos'),
    ejecucionesCuentas: Symbol('ejecucionesCuentas'),
    tipo: Symbol('tipo'),
    autor: Symbol('autor'),
    modificacion: Symbol('modificacion'),
    almacen: Symbol('almacen'),
    comercial: Symbol('comercial'),
    idCuenta: Symbol('idCuenta'),
    cifdni: Symbol('cifdni'),
    cuenta: Symbol('cuenta'),
    telefono1: Symbol('telefono1'),
    idTipo: Symbol('idTipo'),
    comentarios: Symbol('comentarios'),
    fechaAlta: Symbol('fechaAlta'),
    fechaModificacion: Symbol('fechaModificacion'),
    idAutor: Symbol('idAutor'),
    idModificacion: Symbol('idModificacion'),
    idAlmacen: Symbol('idAlmacen'),
    idComercial: Symbol('idComercial'),
    odooCambiado: Symbol('odooCambiado'),
})

export const Cuenta = Object.freeze({
    solicitudes: 'solicitudes',
    direcciones: 'direcciones',
    avisos: 'avisos',
    ejecucionesCuentas: 'ejecucionesCuentas',
    tipo: 'tipo',
    autor: 'autor',
    modificacion: 'modificacion',
    almacen: 'almacen',
    comercial: 'comercial',
    idCuenta: 'idCuenta',
    cifdni: 'cifdni',
    cuenta: 'cuenta',
    telefono1: 'telefono1',
    idTipo: 'idTipo',
    comentarios: 'comentarios',
    fechaAlta: 'fechaAlta',
    fechaModificacion: 'fechaModificacion',
    idAutor: 'idAutor',
    idModificacion: 'idModificacion',
    idAlmacen: 'idAlmacen',
    idComercial: 'idComercial',
    odooCambiado: 'odooCambiado',
})
