 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ConfiguracionIds} from './Configuracion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const ConfiguracionCol = Object.freeze({
    idConfig : new SimpleColumn(ConfiguracionIds.idConfig, 11, 11, Align.RIGHT),
    ratioRepeticion : new SimpleColumn(ConfiguracionIds.ratioRepeticion, 11, 11, Align.RIGHT),
    ratioSimetria : new SimpleColumn(ConfiguracionIds.ratioSimetria, 11, 11, Align.RIGHT),
    asumirEstudioPropio : new SimpleColumn(ConfiguracionIds.asumirEstudioPropio, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Configuracion] = ConfiguracionCol
