 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const RelacionadaIds = Object.freeze({
    obra: Symbol('obra'),
    relacionada: Symbol('relacionada'),
    autor: Symbol('autor'),
    idRelacion: Symbol('idRelacion'),
    idObra: Symbol('idObra'),
    idRelacionada: Symbol('idRelacionada'),
    fechaCreacion: Symbol('fechaCreacion'),
    idAutor: Symbol('idAutor'),
})

export const Relacionada = Object.freeze({
    obra: 'obra',
    relacionada: 'relacionada',
    autor: 'autor',
    idRelacion: 'idRelacion',
    idObra: 'idObra',
    idRelacionada: 'idRelacionada',
    fechaCreacion: 'fechaCreacion',
    idAutor: 'idAutor',
})
