import React from 'react'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {Aviso} from 'sop/db/Aviso'
import {AvisoCol} from 'sop/db/AvisoColumns'
import {AvisoFld, AvisoTable} from 'sop/db/AvisoTable'
import {AvisoTipoCol} from 'sop/db/AvisoTipoColumns'
import {UserCol} from 'sop/db/UserColumns'
import {TareaCol} from 'sop/db/TareaColumns'
import {ObraCol} from 'sop/db/ObraColumns'
import {UserFld} from 'sop/db/UserTable'
import {TareaFld} from 'sop/db/TareaTable'
import {ObraFld} from 'sop/db/ObraTable'
import {AvisoTipoFld} from 'sop/db/AvisoTipoTable'
import {avisoFilter} from 'sop/componentes/aviso/avisoFilter'
import {User} from 'sop/db/User'
import {Tarea} from 'sop/db/Tarea'
import {Obra} from 'sop/db/Obra'
import {stripHtmlTags} from 'sopix/string/html-utils'
import {AvisoTipo} from 'sop/db/AvisoTipo'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'


class AvisoPainter extends TablePainter{
    
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds}) {
        switch(id){
            case Aviso.usuario:
                return fld(User.nombreCompleto)
            case Aviso.tarea:
                return fld(Tarea.ref)
            case Aviso.obra:
                return fld(Obra.cod)
            case Aviso.aviso:
                return stripHtmlTags(value)
            case Aviso.tipo:
                return fld(AvisoTipo.tipo)
            default:
                return formatter(value)
        }
    }
}

export class AvisoListPiece extends TablePiece{

    constructor(world) {

        const TABLE = AvisoTable
        const ID_FIELD = Aviso.idAviso
        const DEFAULT_FILTER = {pendientes: true}
        const DEFAULT_ORDER = [new OrderEntry(Aviso.fecha, OrderDirection.DESC)]

        const COLUMNS = [
            AvisoCol.fecha,
            AvisoCol.aviso,
            [AvisoFld.tipo, AvisoTipoCol.tipo],
            [AvisoFld.usuario, UserCol.nombreCompleto],
            [AvisoFld.tarea, TareaCol.ref],
            [AvisoFld.obra, ObraCol.cod],
            AvisoCol.idPedido,
        ]
        
        const FIELDS = [
            AvisoFld.idAviso,
            AvisoFld.pendiente,
            AvisoFld.fecha,
            AvisoFld.aviso,
            AvisoFld.idPedido,
            [AvisoFld.usuario, [UserFld.id, UserFld.nombreCompleto]],
            [AvisoFld.tarea, [TareaFld.idTarea, TareaFld.ref]],
            [AvisoFld.obra, [ObraFld.idObra, ObraFld.cod]],
            [AvisoFld.tipo, [AvisoTipoFld.tipo]],
        ]
        
        const painter = new AvisoPainter(world, COLUMNS)
        
        const filtrator = graphqlFiltratorBuilder(world, TABLE, FIELDS, {
            graphqlFilterCalculator: avisoFilter, defaultFilter: DEFAULT_FILTER, defaultOrder: DEFAULT_ORDER})
        
        const selection = new RowSelection(world, filtrator, ID_FIELD) 
        
        super(world, ID_FIELD, filtrator, painter, selection)
    }

}

