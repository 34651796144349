export const authSingletons = new Map()

export function getAuthSingleton(id, createFunc){
    return () => {
        let singleton = authSingletons.get(id)
        if (!singleton) {
            singleton = createFunc()
            authSingletons.set(id, singleton)
        }
        return singleton
    }
}
