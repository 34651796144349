 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproliqmanIds} from './Aproliqman'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserIds} from './User'

export const AproliqmanEdt = Object.freeze({
    responsable : new LookupEditor(AproliqmanIds.responsable, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idAproliqman : new SimpleEditor(AproliqmanIds.idAproliqman, 11, 11),
    m2Efectivos : new SimpleEditor(AproliqmanIds.m2Efectivos, 11, 11),
    justificacion : new SimpleEditor(AproliqmanIds.justificacion, 40, 16),
})

tableEditors[Tables.Aproliqman] = AproliqmanEdt
