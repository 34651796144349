import {getILikeFromKeyword} from 'sopix/db/graphql-utils'
import {Aviso} from 'sop/db/Aviso'


// MISTERIO: Si llamo al método xxxListFilter el breakpoint no se detiene!!!
/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export function avisoFilter(searchFields) {
    if (!searchFields) return {}
    const result = {}
    for (let [key, value] of Object.entries(searchFields)) {
        if (!value) {
            continue
        }

        const filters = {
            [Aviso.idAviso]: () => {
                result[`${Aviso.idAviso}Gt`] = parseInt(value) - 10
            },

            'buscar': () => {
                const words = value.split(' ')
                const textoEntries = words.map(word => ({[`${Aviso.aviso}Ilike`]: getILikeFromKeyword(word)}))
                result.or = [
                    {and: textoEntries},
                ]
            },

            'pendientes': () => {
                if (value) {
                    result['pendiente'] = true
                }
            },
        }

        const filter = filters[key]
        filter && filter()
    }
    return result
}