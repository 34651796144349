import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {observer} from 'mobx-react'
import {Cuenta} from 'sop/db/Cuenta'
import {FormPiece} from 'sopix/pieces/formPiece'
import {sopIcons} from 'sop/sop-icons'
import {CuentaFld, CuentaTable} from 'sop/db/CuentaTable'
import {Fields} from 'sopix/formComps/Fields'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {Field} from 'sopix/form/editors/Field'
import {CuentaEdt} from 'sop/db/CuentaEditors'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {UserFld} from 'sop/db/UserTable'
import {UserEdt} from 'sop/db/UserEditors'
import {DateField} from 'sopix/form/editors/DateField'
import {RolesUsuario} from 'sop/db-config/row-consts'
import {getUsuariosListQueryEntry} from 'sop/db-config/sop-query-lists'
import {usePiece} from 'sopix/piece/use-piece'

export class CuentaFormPiece extends FormPiece{

    _listFetcher = new ListFetcher([
        CuentaEdt.tipo.getListQueryEntry(),
        CuentaEdt.almacen.getListQueryEntry(),
        getUsuariosListQueryEntry(RolesUsuario.COMERCIAL),
    ])
    
    constructor(world) {
        const FIELDS = [
            ...CuentaTable.regularFields,
            [CuentaFld.autor, [UserFld.nombreCompleto]],
            [CuentaFld.modificacion, [UserFld.nombreCompleto]],
        ]

        super(world,
            FIELDS,
            {
                name: 'Cuenta',
                icon: sopIcons.Cuenta,
                table: CuentaTable,
                idField: Cuenta.idCuenta,
                displayField: Cuenta.cuenta,
                saveMutation: 'SaveCuenta',
                deleteMutation: 'DeleteCuenta',
            },
        )
    }

    async _init() {
        this.setLists(await this._listFetcher.fetch())
    }
}


export const CuentaForm = observer(
    /**
     * @param {FormPiece} form
     * @returns {JSX.Element}
     */
    ({form}) => {

    usePiece(form)

    return (
        <PieceFrame piece={form} renderPiece={()=> {

            const H = form.fieldHelper
            const fld = form.getField

            return (
                <Fields>
                    <Field autoFocus {...H.edt(CuentaEdt.cuenta)} />
                    <Field {...H.edt(CuentaEdt.cifdni)} />
                    <Field {...H.edt(CuentaEdt.telefono1)} />
                    <DropdownField {...H.edt(CuentaEdt.tipo)} />
                    <DropdownField {...H.edt(CuentaEdt.almacen)} />
                    <DropdownField {...H.edt(CuentaEdt.comercial)} />
                    
                    <Field {...H.edt(CuentaEdt.comentarios)} multiline rows={5} width={1000} />
                    
                    <Field {...H.obj(CuentaEdt.autor, UserEdt.nombreCompleto)} />
                    <DateField disabled {...H.edt(CuentaEdt.fechaAltaOnlyDate)} />
                    <Field {...H.obj(CuentaEdt.modificacion, UserEdt.nombreCompleto)} />
                    <DateField disabled {...H.edt(CuentaEdt.fechaModificacionOnlyDate)} />
                </Fields>
            )

        }} />
    )
})