import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {observer} from 'mobx-react'
import {FormPiece} from 'sopix/pieces/formPiece'
import {Obra} from 'sop/db/Obra'
import {sopIcons} from 'sop/sop-icons'
import {ObraTable} from 'sop/db/ObraTable'
import {ListFetcher, ListQueryEntry} from 'sopix/db-access/listFetcher'
import {OBRA_FIELDS} from 'sop/db-config/fieldSets'
import {runInAction} from 'mobx'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {Tipologia} from 'sop/db/Tipologia'
import {TipologiaFld} from 'sop/db/TipologiaTable'
import {ObraEdt} from 'sop/db/ObraEditors'
import {boundMethod} from 'autobind-decorator'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import {Field} from 'sopix/form/editors/Field'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {NumberField} from 'sopix/form/editors/NumberField'
import {BoolField} from 'sopix/form/editors/BoolField'
import {User} from 'sop/db/User'
import {DateField} from 'sopix/form/editors/DateField'
import {Fields} from 'sopix/formComps/Fields'
import {usePiece} from 'sopix/piece/use-piece'
import {Ejecucion} from 'sop/db/Ejecucion'

export class ObraFormPiece extends FormPiece{

    _listFetcher = new ListFetcher([
        new ListQueryEntry(
            {sort: calcGraphqlOrder(Tipologia.orden)},
            [TipologiaFld.idTipologia, TipologiaFld.tipologia, TipologiaFld.subtipologias],
        ),
        ObraEdt.status.getListQueryEntry(),
    ])

    
    constructor(world) {
        super(world,
            OBRA_FIELDS,
            {
                name: 'Obra',
                icon: sopIcons.Obra,
                table: ObraTable,
                idField: Obra.idObra,
                displayField: Obra.cod,
                saveMutation: 'SaveObra',
                deleteMutation: 'DeleteObra',
            },
        )
        
        this.newRowFields={
            [Obra.locked]: 1,
        }
    }

    //True: Polytherm
    //False: competencia
    //undefined: nadie
    get ejecutada(){
        const ejecuciones = this.fields[Obra.ejecuciones]
        if (!ejecuciones || !ejecuciones.length) {
            return undefined
        }

        return !ejecuciones[0][Ejecucion.competencia]
    }


    @boundMethod
    async invalidate() {
        const test = 0
        return await super.invalidate()
    }

    @boundMethod
    async _load(id) {
        return await super._load(id)
    }

    @boundMethod
    async validate() {
        return await super.validate()
    }

    async _init() {
        //Quitamos el init para que no valide hasta que se cargue algo
        //await super._init()
        const lists = await this._listFetcher.fetch()
        runInAction(()=>{
            this.lists = lists
        })
    }

    @boundMethod
    mapMarkerReaction(fields){
        this.setFields(fields)
    }

    @boundMethod
    async _mountActions() {
        await super._mountActions()
    }

    @boundMethod
    async _enableActions() {
        await super._enableActions()
        if (this.id){
            await this.validate()
        }
    }

}



const useStyles = makeStyles(theme => ({
    tipologiaEntry: {
    },
    subtipologia: {
        marginTop: 8,
        color: theme.palette.grey['500'],
    }
}))



function TipologiaEntry({tipologia, subtipologias}){
    const cls = useStyles()

    const subtipos = !subtipologias ? [] : subtipologias.split('\r\n')

    return (
        <div className={cls.tipologiaEntry} >
            <Typography display="block" className={cls.tipologia}>{tipologia}</Typography>
            {subtipos.map((subtipo, index) =>
                <Typography key={index} display="block" className={cls.subtipologia} variant="caption">{subtipo}</Typography>
            )}
        </div>
    )
}


export const ObraForm = observer(
    /**
     * @param {ObraFormPiece} form
     */
    ({form}) => {

        usePiece(form)
    
        const autor = form.getField(Obra.autor)
        const modificacion = form.getField(Obra.modificacion)
    
        function renderTipologia(row){
            return <TipologiaEntry tipologia={row[Tipologia.tipologia]} subtipologias={row[Tipologia.subtipologias]} />
        }
    
        function getTipologiaFilter(row){
            const subtipologiasString = row[Tipologia.subtipologias]
            if (!subtipologiasString) return row[Tipologia.tipologia]
            const subtipologias = subtipologiasString.split('\r\n')
            return `${row[Tipologia.tipologia]}|${subtipologias.join('|')}`
        }
    
    
        return (
            <PieceFrame piece={form} renderPiece={()=> {
    
                const H = form.fieldHelper
                const fld = form.getField
    
                return (
                    <Fields>
                        <Field {...H.edt(ObraEdt.nombre)} autoFocus />

                        <DropdownField
                            {...H.edt(ObraEdt.tipologia)}
                            nullValue={0}
                            customRender={renderTipologia}
                            customFilter={getTipologiaFilter}
                        />

                        <NumberField {...H.edt(ObraEdt.m2)} suffix=" m²" />
                        <BoolField {...H.edt(ObraEdt.passiveHouse)} />
                        <BoolField {...H.edt(ObraEdt.obraRepresentativa)} />
                        <Field {...H.edt(ObraEdt.direccion)} />
                        <Field {...H.edt(ObraEdt.cp)} />
                        <Field {...H.edt(ObraEdt.localidad)} />
                        <Field {...H.edt(ObraEdt.provincia)} />
                        <Field {...H.edt(ObraEdt.pais)} />
                        <NumberField {...H.edt(ObraEdt.altitud)} suffix=" m" />
                        <Field {...H.edt(ObraEdt.autor, {manager: false, lookup: false})}
                               value={autor && autor[User.nombreCompleto]} disabled={true}/>
                        <DateField {...H.edt(ObraEdt.fechaAlta)} disabled={true}/>
                        <Field {...H.edt(ObraEdt.modificacion, {manager: false, lookup: false})}
                               value={modificacion && modificacion[User.nombreCompleto]} disabled={true}/>
                        <DateField {...H.edt(ObraEdt.fechaModificacion)} disabled={true}/>
                    </Fields>
                )
    
            }} />
    )
})