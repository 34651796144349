 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {RepresentanteIds} from './Representante'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId} from 'sopix/db/dbField'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const RepresentanteTable = new DbTable(
    Tables.Representante,
    'Representantes',
    graphqlUrl,
    'Representante',
    [RepresentanteIds.idOr],
    [RepresentanteIds.idOr, RepresentanteIds.idObra, RepresentanteIds.idRepresentante, RepresentanteIds.idAutor, RepresentanteIds.fechaCreacion, RepresentanteIds.odooCambiado],
    [RepresentanteIds.obra, RepresentanteIds.representante, RepresentanteIds.autor],
    [],
    ['verRepresentantesObra'],
    ['modificarRepresentantesObra'],
    undefined,
    undefined,
    [],
    [],
)


export const RepresentanteFld = Object.freeze({
    obra : new DbObject(Tables.Representante, RepresentanteIds.obra, RepresentanteIds.idObra, 'Obra'),
    representante : new DbObject(Tables.Representante, RepresentanteIds.representante, RepresentanteIds.idRepresentante, 'Representante'),
    autor : new DbObject(Tables.Representante, RepresentanteIds.autor, RepresentanteIds.idAutor, 'Autor'),
    idOr : new DbIntId(Tables.Representante, RepresentanteIds.idOr, 'Id or'),
    idObra : new DbObjectId(Tables.Representante, RepresentanteIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idRepresentante : new DbObjectId(Tables.Representante, RepresentanteIds.idRepresentante, 'Id representante', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idAutor : new DbObjectId(Tables.Representante, RepresentanteIds.idAutor, 'Id autor', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    fechaCreacion : new DbDateTime(Tables.Representante, RepresentanteIds.fechaCreacion, 'Fecha creacion'),
    odooCambiado : new DbInt(Tables.Representante, RepresentanteIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.Representante, RepresentanteFld) 
