import {icons} from 'sopix/icon/icons'
import {userAuth} from 'sopix/session/userAuth'
import {UserRoles} from 'sop/db-config/row-consts'

export const APRO_ACTION = 'action'

export const AproAction = Object.freeze({
    ANULAR: 'anular',
    APROBAR: 'aprobar',
    DENEGAR: 'denegar',
    CORREGIR: 'corregir',
})


export const AprobacionEstado = Object.freeze({
    PENDIENTE: undefined,
    DENEGADA: 0,
    APROBADA: 1,
    NECESITA_CORRECCION: 2,
})


export const AprobacionIcon = Object.freeze({
    [AprobacionEstado.PENDIENTE]: icons.Waiting,
    [AprobacionEstado.DENEGADA]: icons.Cancel,
    [AprobacionEstado.APROBADA]: icons.Check,
    [AprobacionEstado.NECESITA_CORRECCION]: icons.Warning,
})

export const AprobacionDesc = Object.freeze({
    [AprobacionEstado.PENDIENTE]: 'Pendiente de aprobar',
    [AprobacionEstado.DENEGADA]: 'Denegada',
    [AprobacionEstado.APROBADA]: 'Aprobada',
    [AprobacionEstado.NECESITA_CORRECCION]: 'Pendiente de corregir',
})


export const AprobacionFilter = Object.freeze({
    BUSCAR: 'buscar',
    
    ESTADO: 'ESTADO',
    ESTADO_PENDIENTES: -1,
    ESTADO_FINALIZADOS: -2,
    
    SOLICITANTE: 'SOLICITANTE',
    
    APROBADOR: 'aprobarIdUser',
})


export function getDefaultAprobacionFilter(){
    return {
        [AprobacionFilter.ESTADO]: AprobacionFilter.ESTADO_PENDIENTES,
        [AprobacionFilter.APROBADOR]: true,
        [AprobacionFilter.SOLICITANTE]: true,
    }
}

