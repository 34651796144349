 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AprobacionTipoIds} from './AprobacionTipo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const AprobacionTipoCol = Object.freeze({
    idTipo : new SimpleColumn(AprobacionTipoIds.idTipo, 11, 11, Align.RIGHT),
    tipo : new SimpleColumn(AprobacionTipoIds.tipo, 30, 12, Align.LEFT),
    permisoAprobar : new SimpleColumn(AprobacionTipoIds.permisoAprobar, 40, 16, Align.LEFT),
})

tableColumns[Tables.AprobacionTipo] = AprobacionTipoCol
