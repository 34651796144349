import {getLog} from 'sopix/log'
import {ListState} from 'sopix/react-state/listState'
import {sopIcons} from 'sop/sop-icons'
import {SolicitudFld, SolicitudTable} from 'sop/db/SolicitudTable'
import {Solicitud} from 'sop/db/Solicitud'
import {ContactoTable} from 'sop/db/ContactoTable'
import {DireccionTable} from 'sop/db/DireccionTable'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {SolicitanteFormState} from 'sop/componentes/obra-solicitante-S/solicitanteFormState'
import {CUENTA_FIELDS} from 'sop/db-config/fieldSets'
import {TareaFld} from 'sop/db/TareaTable'
import React from 'react'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {find} from 'lodash-es'
import {Tarea} from 'sop/db/Tarea'
import {boundMethod} from 'autobind-decorator'
import {ObraFld} from 'sop/db/ObraTable'
import {NotaFld} from 'sop/db/NotaTable'
import {Obra} from 'sop/db/Obra'
import {action, observable} from 'mobx'
import {FORBIDDEN_ROW_CONFIG} from 'sopix/db/graphql-defs'
import {ALERT_CANCEL, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'

const log = getLog('SolicitanteListState')

const MAX_RECORDS = 500


const SOLICITUD_FIELDS = [
    ...SolicitudTable.regularFields,
    [SolicitudFld.contacto, ContactoTable.regularFields],
    [SolicitudFld.direccion, DireccionTable.regularFields],
    [SolicitudFld.cuenta, CUENTA_FIELDS],
    [SolicitudFld.tareaList, [TareaFld.ref]],
    [SolicitudFld.obra, [
        [ObraFld.notas, [NotaFld.idNota]],
    ]]
]


export class SolicitanteListState extends ListState{
    
    @observable
    valid = true
    
    _solicitudQL = new TableGraphql(SolicitudTable)
    
    constructor(idObra, options) {
        super(idObra, {
            name: 'SolicitudIds de obra',
            formClass: SolicitanteFormState,
            idField: Solicitud.idObra,
            itemIdField: Solicitud.idSolicitud,
            icon: sopIcons.Solicitante,
            table: SolicitudTable,
            deleteMutation: 'DeleteSolicitud',
            ...options,
        })
    }

    async __loadData(id) {
        const result = await this._solicitudQL.query(
            SOLICITUD_FIELDS, 
            {first: MAX_RECORDS, sort: calcGraphqlOrder(Solicitud.idSolicitud, OrderDirection.DESC)}, 
            {[Solicitud.idObra]: parseInt(id)}
        )
        return result.rows
    }

    @boundMethod
    async delete(id){
        const row = find(this.rows, {[Solicitud.idSolicitud]: id})
        const trabajos = row[Solicitud.tareaList].map(trabajo => 
            trabajo[Tarea.ref])
        const notas = row[Solicitud.obra][Obra.notas]
        
        const trabajosText = !trabajos.length ? '' : `Se eliminarán los trabajos ${trabajos.join(', ')}.`
        const notasText = !notas.length ? '' : `Se eliminarán ${notas.length} notas.`
        
        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            `¿Quitar solicitante?`,
            `${trabajosText} ${notasText}`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
        ],))) {
            await super._deleteItem(id)
        }
    }
    
    @action
    invalidate(){
        this.valid = false    
    }
    
    @action
    validate(){
        this.valid = true
    }
}

