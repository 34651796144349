/**
 * @param {SubsetDbSource} subsetDb
 * @param {Filtrator} filtrator
 * @param {FormPiece} form
 */
export function linkSubsetDbFiltratorForm(subsetDb, filtrator, form){
    
    subsetDb.onSetData.subscribe(filtrator.refresh)

    subsetDb.onParentIdChange.subscribe(async id => {
        if (!id){
            await form.invalidate()
        }
        form.newRowFields[subsetDb.parentIdField] = id
        filtrator.clearFilters()
    })

    form.onSave.subscribe(subsetDb.refresh)

    form.onDeleteRows.subscribe(subsetDb.refresh)
}


