import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Bar} from 'sopix/formComps/Bar'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {Frame} from 'sopix/formComps/Frame'
import {observer} from 'mobx-react'
import {Nota} from 'sop/db/Nota'
import {User} from 'sop/db/User'
import {getColumnTextFormatter} from 'sopix/db/db-column-utils'
import {NotaCol} from 'sop/db/NotaColumns'
import {icons} from 'sopix/icon/icons'
import {Solicitud} from 'sop/db/Solicitud'
import {BodyText, MicroText} from 'sopix/textComps/textComps'
import {useAlert} from 'sopix/alert/use-alert'
import {Push} from 'sopix/formComps/Push'
import {Cuenta} from 'sop/db/Cuenta'
import {Tag} from 'sopix/formComps/Tag'
import clsx from 'clsx'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {Separator} from 'sopix/formComps/Separator'
import {sopColors} from 'sop/sop-colors'
import {usePiece} from 'sopix/piece/use-piece'
import {NotaForm} from 'sop/componentes/obra-nota/NotaForm'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'

const fechaFormatter = getColumnTextFormatter(NotaCol.fechaCreacion)



const entryStyles = makeStyles(theme => ({
    nota: {
        position: 'relative',
        //borderBottom: `1px solid ${theme.palette.grey[300]}`,
        
        '&:first-child': {
            //borderTop: `1px solid ${theme.palette.grey[300]}`,
        },
        
        '&:hover': {
            background: theme.palette.action.hover
        },
        '&:hover $button': {
            visibility: "inherit",
        },
        padding: '4px 0',
    },
    field: {
        cursor: 'pointer',
        pointerEvents: 'none',
    },

    solicitante: {
        margin: '8px 4px 0 4px',
        maxWidth: '95%',
        overflow: 'hidden',
        pointerEvents: 'none',
    },

    body: {
        margin: 4,
        cursor: 'pointer',
        marginBottom: -5,
        pointerEvents: 'none',
    },
    star: {
        position: 'relative',
        top: 4,
        left: -1,
        color: theme.palette.secondary.main,
        pointerEvents: 'none',
    },
    starDisabled: {
        color: sopColors.yellow,
        top: 7,
        left: 1,
    },
    
    privada: {
        fontWeight: 'bold',   
        marginRight: 6,
    },
    
    button: {
        visibility: 'hidden',
    },
    
    separator: {
        borderTop: `1px solid ${theme.palette.grey['200']}`,
        paddingTop: 16,
    },
    
    firma: {
        marginLeft: 8,
    }
})) 



function Entry({nota, separator, onEdit, onDelete, filtered}){
    const cls = entryStyles()

    const solicitud = nota[Nota.solicitud]
    const cuenta = solicitud ? solicitud[Solicitud.cuenta][Cuenta.cuenta] : ''

    const star = nota[Nota.destacada]
        ? <HintIcon mini Icon={icons.Star} className={cls.star}/>
        : <HintIcon mini Icon={sopIcons.Nota} className={clsx(cls.star, cls.starDisabled)} />
    
    const auth = userAuth
    const canDelete = auth.isAllowed(Permiso.modificarNotas) && (
        auth.isAllowed(Permiso.eliminarNotasDeOtros) || auth.userId === nota[Nota.idAutor]
    )
    
    const canEdit = auth.isAllowed(Permiso.modificarNotas) && (
        auth.isAllowed(Permiso.modificarNotasDeOtros) || auth.userId === nota[Nota.idAutor]
    )
    
    const clickHandler = canEdit ? onEdit : undefined
    
    return (
        <div className={clsx(cls.nota, separator && cls.separator)} onClick={clickHandler}>
            {!solicitud ? null :
                <Tag className={cls.solicitante}
                    label={cuenta} size="small" variant="outlined"
                />
            }
            <BodyText className={cls.body} onClick={clickHandler}>
                {star} {nota[Nota.privada] ? <span className={cls.privada}>privada </span> : ''}
                {nota[Nota.nota]}
            </BodyText>
            <Bar className={cls.firma} transparent={true} direction="row" style={{pointerEvents: 'none'}}>
                <MicroText className={cls.field}>
                    {nota[Nota.autor][User.nombreCompleto]}
                </MicroText>
                <MicroText className={cls.field}>
                    {fechaFormatter(nota[Nota.fechaCreacion])}
                </MicroText>
                <Push />
                {!canDelete ? null :
                    <HintIconButton
                        className={cls.button}
                        Icon={icons.Delete}
                        title="Eliminar nota"
                        onClick={onDelete}
                    />
                }
            </Bar>
        </div>
    )    
}


const useStyles = makeStyles(() => ({
    notaList: {
        display: 'flex',
    },
    list: {
        flex: 1,
        overflow: 'auto',
    },
    chip: {
        marginLeft: 10,
        //overflow: 'hidden',
    }
}))

export const NotaList = observer(
    /**
     * 
     * @param {NotaListPiece} list
     * @returns {JSX.Element}
     */
    ({list}) => {
    
    const cls = useStyles()
        
    usePiece(list)
        
    const [alert, withAlert] = useAlert()

    return <PieceFrame piece={list} renderPiece={()=> {

        const form = list.form
        const filtrator = list.filtrator
        const poper = list.poper
        const cuenta = getFieldExtractor(filtrator.solicitud)(Solicitud.cuenta, Cuenta.cuenta)
        let notaDeObraEnLaAnterior
        
        const asa = list._asyncAction

        const modificarNotas = userAuth.isAllowed(Permiso.modificarNotas)

        return (
            <>
                    <Bar Icon={sopIcons.Nota} iconProps={{
                        badge: list.dbSource.data.length || '',
                        badgeColor: sopColors.badgeCount,
                        title: "Notas",
                        color: sopColors.mainIcon,
                    }} >
                        <Push />
                        {!list.filtrator.isFiltered ? null :
                            <Tag
                                variant="outlined"
                                className={cls.chip}
                                label={cuenta}
                                tooltip={`filtro: "${cuenta}"`}
                            />}
                        <HintIconButton
                            Icon={icons.Filter}
                            title={`Filtro por solicitante: ${filtrator.enabled ? "Activado" : "Desactivado"}`}
                            color={filtrator.enabled ? sopColors.enabled : sopColors.disabled}
                            onClick={filtrator.__toggleFilter}
                        />
                        <Separator />
                        {!modificarNotas ? null :
                            <HintIconButton
                                Icon={sopIcons.Nota}
                                badge='+'
                                badgeColor={sopColors.badgeAdd}
                                title="Añadir nota"
                                onClick={poper.__create}
                            />
                        }
                    </Bar>
                    <div className={cls.list}>
                        {list.filtrator.data.map((nota, index) => {

                            const notaDeObra = !nota[Nota.idSolicitud]

                            const result = <Entry
                                key={nota[Nota.idNota]}
                                filtered={filtrator.isFiltered}
                                nota={nota}
                                separator={notaDeObraEnLaAnterior !== undefined && notaDeObra !== notaDeObraEnLaAnterior}
                                onEdit={() => poper.__edit(nota[Nota.idNota])}
                                onDelete={
                                    () => withAlert(
                                        () => asa(form._deleteRows(nota[Nota.idNota])),
                                        'Eliminar nota?',
                                        'Eliminar',
                                        nota[Nota.nota],
                                    )
                                }
                            />
                            notaDeObraEnLaAnterior = notaDeObra
                            return result
                        })}
                    </div>
                <PoperFrame
                    poper={poper}
                    maxWidth="sm"
                >
                    <NotaForm form={list.form}/>
                </PoperFrame>
                {alert}
            </>
        )
    }} />
})
