import {pbkdf2} from 'pbkdf2'

/**
 * @param password
 * @param salt
 * @param iterations
 * @param keylen
 * @param digest
 * @returns {Promise<Uint8Array>}
 */
export async function pbkdf2Async(password, salt, iterations, keylen, digest){
    return new Promise((resolve, reject) => {
        pbkdf2(password, salt, iterations, keylen, digest, (err, derivedKey) => {
            if (err) {
                reject(err)
            } else {
                resolve(derivedKey)
            }
        })
    })
}