 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {FileIds} from './File'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const FileCol = Object.freeze({
    idFile : new SimpleColumn(FileIds.idFile, 11, 11, Align.RIGHT),
    idAutor : new SimpleColumn(FileIds.idAutor, 11, 11, Align.RIGHT),
    fileSize : new SimpleColumn(FileIds.fileSize, 40, 16, Align.LEFT),
    filePath : new SimpleColumn(FileIds.filePath, 40, 16, Align.LEFT),
    fechaCreacion : new DateTimeColumn(FileIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(FileIds.fechaCreacion, 11, 11, Align.LEFT),
    borrado : new SimpleColumn(FileIds.borrado, 11, 11, Align.RIGHT),
    size : new SimpleColumn(FileIds.size, 11, 11, Align.RIGHT),
})

tableColumns[Tables.File] = FileCol
