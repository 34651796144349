import React, {forwardRef} from 'react'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {ObraFld, ObraTable} from 'sop/db/ObraTable'
import {obraFilter} from 'sop/componentes/obra/obraFilter'
import {Obra} from 'sop/db/Obra'
import {ObraCol} from 'sop/db/ObraColumns'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {graphqlFiltratorBuilder2} from 'sopix/piece-helpers/graphql-filtrator-builder'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {makeStyles} from '@material-ui/core/styles'
import {Ejecucion} from 'sop/db/Ejecucion'
import clsx from 'clsx'
import {sopColors} from 'sop/sop-colors'
import {EjecucionFld} from 'sop/db/EjecucionTable'
import {TableGraphql} from 'sopix/db-access/tableGraphql'


class ObraPainter extends TablePainter{
    _fixColumn(col, id, dbCol) {
        switch(id){
            case Obra.fechaAlta:
                col.title = 'Fecha'
                break
            default:
        }
    }
}

export class ObraListPiece extends TablePiece{

    constructor(world) {

        const TABLE = ObraTable
        const ID_FIELD = Obra.idObra
        const DEFAULT_FILTER = {m2Rango: 5}
        const DEFAULT_ORDER = [new OrderEntry(Obra.fechaAlta, OrderDirection.DESC)]

        const COLUMNS = [
            ObraCol.cod,
            ObraCol.fechaAltaOnlyDate,
            ObraCol.fechaEjecucionOnlyDate,
            ObraCol.tipologia,
            ObraCol.nombre,
            ObraCol.direccion,
            ObraCol.m2
        ]

        const FIELDS = [
            ObraFld.idObra,
            [ObraFld.ejecuciones, [EjecucionFld.competencia]],
            ...COLUMNS.map(col => col.field)
        ]

        const painter = new ObraPainter(world, COLUMNS)

        const filtrator = graphqlFiltratorBuilder2(world,
            new TableGraphql(TABLE), 
            FIELDS, {
            graphqlFilterCalculator: obraFilter, defaultFilter: DEFAULT_FILTER, defaultOrder: DEFAULT_ORDER})

        const selection = new RowSelection(world, filtrator, ID_FIELD, {deselectOnClick: false})

        super(world, ID_FIELD, filtrator, painter, selection)
    }
}


const useStyles = makeStyles(() => ({
    ejecutadaBien: {
        background: sopColors.greenBackground,
    },
    ejecutadaMal: {
        background: sopColors.redBackground,
    }
})) 


const Row = forwardRef(({data, ...props}, ref) => {
    const cls = useStyles()

    const ejecuciones = data[Obra.ejecuciones]
    let ejecutada
    if (!ejecuciones || !ejecuciones.length) {
        ejecutada = undefined
    } else {
        ejecutada = !ejecuciones[0][Ejecucion.competencia] 
    }


    return <BlRow ref={ref} className={clsx(
        ejecutada === true && cls.ejecutadaBien,
        ejecutada === false && cls.ejecutadaMal,
    )} data={data} {...props} />
})



export function ObraList({list}) {
    
    return (
        <GraphqlTable list={list} Row={Row} />
    )
}
