import {PagePiece} from 'sopix/pieces/pagePiece'
import {action, observable, runInAction} from 'mobx'
import {ObraFormPiece} from 'sop/componentes/obra/ObraForm'
import {UrlManager} from 'sopix/pieces/urlManager'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {ObraSearchPiece} from 'sop/componentes/obra/ObraSearch'
import {ObraListPiece} from 'sop/componentes/obra/ObraList'
import {NotaListPiece} from 'sop/componentes/obra-nota/notaListPiece'
import {ObraTrabajoListPiece} from 'sop/componentes/obra-trabajo/obraTrabajoListPiece'
import {SolicitanteListState} from 'sop/componentes/obra-solicitante-S/solicitanteListState'
import {RepresentanteListState} from 'sop/componentes/obra-representante-S/representanteListState'
import {PedidoListState} from 'sop/componentes/obra-pedido-S/pedidoListState'
import {ObraMapState} from 'sop/componentes/obra-map-S/obraMapState'
import {Obra} from 'sop/db/Obra'
import {boundMethod} from 'autobind-decorator'
import {RelacionadaListPiece} from 'sop/componentes/obra-relacionada/relacionadaListPiece'
import {linkPageListSearchFormUrlWithLoad} from 'sopix/piece-linkers/list-form-url'
import {EjecucionListPiece} from 'sop/componentes/ejecucion/EjecucionList'
import {DocumentoListPiece} from 'sop/componentes/documento/DocumentoList'
import {linkFormListSeparated} from 'sopix/piece-linkers/form-list'
import {getAuthSingleton} from 'sopix/session/auth-singletons'

export const ObraPageMode = Object.freeze({
    LIST: Symbol('LIST'),
    FORM: Symbol('FORM'),
})
export const ObraTrabajoTabView = Object.freeze({
    TRABAJOS: Symbol('TRABAJOS'),
    DOCUMENTOS: Symbol('DOCUMENTOS'),
})

export class ObraPagePiece extends PagePiece {

    /** @enum {ObraPageMode} */
    @observable
    _mode = ObraPageMode.LIST

    @observable
    _trabajoTabView = ObraTrabajoTabView.TRABAJOS

    /** @type {ObraListPiece} */
    list
    
    @observable
    creating = false
    
    constructor(pageInfo) {
        super(pageInfo)
        const world = this.world
        this.form = new ObraFormPiece(world)
        this.urlManager = new UrlManager(world, this.form.idField, sopUrls.obras, sopUrls.obra)
        this.search = new ObraSearchPiece(world)
        this.list = new ObraListPiece(world)
        this.notas = new NotaListPiece(world)
        this.trabajos = new ObraTrabajoListPiece(world)
        this.relacionadas = new RelacionadaListPiece(world)
        this.ejecuciones = new EjecucionListPiece(world)
        this.documentos = new DocumentoListPiece(world)
        
        runInAction(() => {
            const opts = {errorManager: this.world.errorManager}

            this.solicitanteList = new SolicitanteListState(undefined, opts)
            this.representanteList = new RepresentanteListState(undefined, opts)

            this.pedidoList = new PedidoListState(undefined, opts)

            this.obraMap = new ObraMapState(opts)
        })

        linkPageListSearchFormUrlWithLoad(this, this.list, this.search, this.form, this.urlManager)
        linkFormListSeparated(this.form, this.list, this.setMode, ObraPageMode.FORM, ObraPageMode.LIST)

        this.obraMap.onMarkerSyncObservers.subscribe(this.form.mapMarkerReaction)
        this.form.fieldManager.onLoadObservers.subscribe(this.obraMap.obraLoadReaction)
        this.form.fieldManager.onBlurObservers.subscribe(this.obraMap.obraBlurReaction)
        
        this.form.onCurrentRowChanging.subscribe(id => {
            this.setCreating(false)
            this.trabajos.validate()
            this.notas.validate()
            this.solicitanteList.validate()

            this.notas.load(id).then()
            this.trabajos.load(id).then()
            this.relacionadas.load(id).then()
            
            this.ejecuciones.load(id).then()
            this.documentos.load(id).then()

            this.solicitanteList.loadAndApply(id).then()
            this.representanteList.loadAndApply(id).then()
        })

        this.form.onApply.subscribe(data => {
            this.pedidoList.loadAndApply(data[Obra.cod]).then()
        })
        
        this.form.onSave.subscribe(() => {
            if (this.representanteList.obra && this.representanteList.obra[Obra.cp] !== this.form.getField(Obra.cp)) {
                this.representanteList.loadAndApply(this.form.id).then()
            }
        })
        
        this.form.onCreate.subscribe(() => {
            this.trabajos.invalidate()
            this.notas.invalidate()
            this.solicitanteList.invalidate()
            this.setCreating(true)
        })

        this.solicitanteList.onSelectObservers.subscribe(solicitudRow => {
            this.trabajos.filtrator.setFilterSolicitud(solicitudRow).then()
            this.notas.filtrator.setFilterSolicitud(solicitudRow).then()
            this.documentos.filtrator.setFilterSolicitud(solicitudRow).then()
        })

        this.trabajos.selection.onRowSelected.subscribe(async ({row}) => {
            this.documentos.filtrator.setFilterCotizacion(row).then()
        })
        
        const ejecucionesChanged = async () => {
            await this.refresh()
        }
        this.ejecuciones.ejecucionForm.onSave.subscribe(ejecucionesChanged)
        this.ejecuciones.deleter.onDelete.subscribe(ejecucionesChanged)
        
        this.trabajos.form.onSave.subscribe(this.form.refresh)
    }

    @boundMethod
    async refreshButtonClicked(){
        await Promise.all([
            this.form.refresh(),
            this.refreshSubPieces(),
        ])
    }
    
    @boundMethod
    async refreshSubPieces() {
        if (!this.form.id) {
            return
        }
        await Promise.all([
            this.trabajos.dbSource.refresh(),
            this.notas.dbSource.refresh(),
            this.relacionadas.dbSource.refresh(),
            this.documentos.dbSource.refresh(),
            
            this.ejecuciones.dbSource.refresh(),
            
            this.solicitanteList._reload(),
            this.representanteList._reload(),
        ])
    }

    @action
    setCreating(creating){
        if (creating !== this.creating){
            this.creating = creating
        }
    }
    
    async _init() {
        await this.solicitanteList._init()
        await this.representanteList._init()
        await super._init()
    }

    get mode() {
        return this._mode
    }

    get trabajoTabView() {
        return this._trabajoTabView
    }

    @boundMethod
    setTrabajoTabView(view) {
        this._trabajoTabView = view
    }

    __setTrabajoTabView = this._action(this.setTrabajoTabView)


    @action.bound
    setMode(mode) {
        if (this._mode !== mode) {
            this._mode = mode
        }
    }
    __setMode = this._action(this.setMode)
    
    @boundMethod
    async createRow(){
        this.setMode(ObraPageMode.FORM)
        await this.form.create()
    }
    __createRow = this._asyncAction(this.createRow)
}

export const getObraPage = getAuthSingleton(sopPages.obras.id, () => {
    return new ObraPagePiece(sopPages.obras)
})