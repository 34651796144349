import {observable} from 'mobx'

export class LayoutData{
    
    @observable
    shortAppName
    
    @observable
    appName
    
    @observable
    appVersion
    
    @observable
    title
    
    @observable
    stage
}

export const layoutData = new LayoutData()
