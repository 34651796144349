 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaIds} from './Tarea'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const TareaCol = Object.freeze({
    estado : new LookupColumn(TareaIds.estado, Tables.TareaEstado, 'estado'),
    autor : new LookupColumn(TareaIds.autor, Tables.User, 'nombre_completo'),
    responsable : new LookupColumn(TareaIds.responsable, Tables.User, 'nombre_completo'),
    obra : new LookupColumn(TareaIds.obra, Tables.Obra, 'cod'),
    procedencia : new LookupColumn(TareaIds.procedencia, Tables.Procedencia, 'procedencia'),
    tipo : new LookupColumn(TareaIds.tipo, Tables.TipoTarea, 'tipo'),
    idTarea : new SimpleColumn(TareaIds.idTarea, 11, 11, Align.RIGHT),
    ordenEstudio : new SimpleColumn(TareaIds.ordenEstudio, 4, 4, Align.RIGHT),
    ordenValoracion : new SimpleColumn(TareaIds.ordenValoracion, 4, 4, Align.RIGHT),
    descripcion : new SimpleColumn(TareaIds.descripcion, 100, 30, Align.LEFT),
    fechaVencimiento : new DateColumn(TareaIds.fechaVencimiento, 11, 11, Align.LEFT),
    fechaCreacion : new DateTimeColumn(TareaIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(TareaIds.fechaCreacion, 11, 11, Align.LEFT),
    ref : new SimpleColumn(TareaIds.ref, 12, 9, Align.LEFT),
    refCliente : new SimpleColumn(TareaIds.refCliente, 20, 10, Align.LEFT),
    m2Totales : new SimpleColumn(TareaIds.m2Totales, 8, 8, Align.RIGHT),
    urgente : new SimpleColumn(TareaIds.urgente, 11, 11, Align.RIGHT),
    ratio : new SimpleColumn(TareaIds.ratio, 11, 11, Align.RIGHT),
    fechaFinalizada : new DateTimeColumn(TareaIds.fechaFinalizada, 16, 16, Align.LEFT),
    fechaFinalizadaOnlyDate : new DateColumn(TareaIds.fechaFinalizada, 11, 11, Align.LEFT),
    desplazada : new SimpleColumn(TareaIds.desplazada, 11, 11, Align.RIGHT),
    m2Completos : new SimpleColumn(TareaIds.m2Completos, 11, 11, Align.RIGHT),
    m2Repetidos : new SimpleColumn(TareaIds.m2Repetidos, 11, 11, Align.RIGHT),
    m2Simetricos : new SimpleColumn(TareaIds.m2Simetricos, 11, 11, Align.RIGHT),
    m2Fijos : new SimpleColumn(TareaIds.m2Fijos, 11, 11, Align.RIGHT),
    detalle : new SimpleColumn(TareaIds.detalle, 40, 16, Align.LEFT),
    justificacion : new SimpleColumn(TareaIds.justificacion, 40, 16, Align.LEFT),
    m2Efectivos : new SimpleColumn(TareaIds.m2Efectivos, 8, 8, Align.RIGHT),
    precioSinRegulacion : new SimpleColumn(TareaIds.precioSinRegulacion, 11, 11, Align.RIGHT),
    refE : new SimpleColumn(TareaIds.refE, 40, 16, Align.LEFT),
    refC : new SimpleColumn(TareaIds.refC, 40, 16, Align.LEFT),
    refV : new SimpleColumn(TareaIds.refV, 40, 16, Align.LEFT),
    refT : new SimpleColumn(TareaIds.refT, 40, 16, Align.LEFT),
    odooCambiado : new SimpleColumn(TareaIds.odooCambiado, 11, 11, Align.RIGHT),
    asumible : new SimpleColumn(TareaIds.asumible, 8, 8, Align.LEFT),
    aclReadOnly : new SimpleColumn(TareaIds.aclReadOnly, 13, 13, Align.LEFT),
})

tableColumns[Tables.Tarea] = TareaCol
