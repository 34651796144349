 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UserIds} from './User'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const UserCol = Object.freeze({
    roleUsuarioObj : new LookupColumn(UserIds.roleUsuarioObj, Tables.UserRole, 'role'),
    id : new SimpleColumn(UserIds.id, 11, 11, Align.RIGHT),
    email : new SimpleColumn(UserIds.email, 40, 16, Align.LEFT),
    password : new SimpleColumn(UserIds.password, 40, 16, Align.LEFT),
    username : new SimpleColumn(UserIds.username, 15, 5, Align.LEFT),
    nombreCompleto : new SimpleColumn(UserIds.nombreCompleto, 15, 10, Align.LEFT),
    idAutor : new SimpleColumn(UserIds.idAutor, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(UserIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(UserIds.fechaCreacion, 11, 11, Align.LEFT),
    zona : new SimpleColumn(UserIds.zona, 40, 16, Align.LEFT),
    movil : new SimpleColumn(UserIds.movil, 20, 10, Align.LEFT),
    ausente : new SimpleColumn(UserIds.ausente, 11, 11, Align.RIGHT),
    mueveCotizaciones : new SimpleColumn(UserIds.mueveCotizaciones, 11, 11, Align.RIGHT),
    ausenteDesde : new DateColumn(UserIds.ausenteDesde, 11, 11, Align.LEFT),
    ausenteHasta : new DateColumn(UserIds.ausenteHasta, 11, 11, Align.LEFT),
    idModificacion : new SimpleColumn(UserIds.idModificacion, 11, 11, Align.RIGHT),
    fechaModificacion : new DateTimeColumn(UserIds.fechaModificacion, 16, 16, Align.LEFT),
    fechaModificacionOnlyDate : new DateColumn(UserIds.fechaModificacion, 11, 11, Align.LEFT),
    inactivo : new SimpleColumn(UserIds.inactivo, 11, 11, Align.RIGHT),
    horas : new SimpleColumn(UserIds.horas, 11, 11, Align.RIGHT),
    resetKey : new SimpleColumn(UserIds.resetKey, 32, 13, Align.LEFT),
    apiKey : new SimpleColumn(UserIds.apiKey, 40, 15, Align.LEFT),
    odooCambiado : new SimpleColumn(UserIds.odooCambiado, 11, 11, Align.RIGHT),
    totpSecret : new SimpleColumn(UserIds.totpSecret, 40, 16, Align.LEFT),
    totpRequerido : new SimpleColumn(UserIds.totpRequerido, 11, 11, Align.RIGHT),
    resetKeyTime : new DateTimeColumn(UserIds.resetKeyTime, 16, 16, Align.LEFT),
    resetKeyTimeOnlyDate : new DateColumn(UserIds.resetKeyTime, 11, 11, Align.LEFT),
    loginTryTime : new DateTimeColumn(UserIds.loginTryTime, 16, 16, Align.LEFT),
    loginTryTimeOnlyDate : new DateColumn(UserIds.loginTryTime, 11, 11, Align.LEFT),
    loginTryCount : new SimpleColumn(UserIds.loginTryCount, 11, 11, Align.RIGHT),
    aclReadOnly : new SimpleColumn(UserIds.aclReadOnly, 13, 13, Align.LEFT),
})

tableColumns[Tables.User] = UserCol
