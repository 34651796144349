import {TableFiltrator} from 'sopix/filtrator/tableFiltrator'
import {boundMethod} from 'autobind-decorator'
import {computed} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {Solicitud} from 'sop/db/Solicitud'
import {Tarea} from 'sop/db/Tarea'
import {Documento} from 'sop/db/Documento'

export const FILTER_ENABLED = 'ENABLED'
const FILTER_SOLICITUD = 'SOLICITUD'
const FILTER_COTIZACION = 'COTIZACION'

export class DocumentoFiltrator extends TableFiltrator {

    get enabled() {
        return this.currentFilter[FILTER_ENABLED]
    }

    @boundMethod
    async setEnabled(enabled) {
        await this.modifyCurrentFilter({[FILTER_ENABLED]: enabled})
        await this.refresh()
    }

    __setEnabled = this._asyncAction(this.setEnabled)

    @computed
    get filterSolicitud() {
        return this.currentFilter[FILTER_SOLICITUD]
    }

    @computed
    get filterCotizacion() {
        return this.currentFilter[FILTER_COTIZACION]
    }

    @boundMethod
    async setFilterCotizacion(cotizacion) {
        await this.modifyCurrentFilter({[FILTER_COTIZACION]: cotizacion})
        await this.refresh()
    }

    @boundMethod
    async setFilterSolicitud(solicitud) {
        await this.modifyCurrentFilter({[FILTER_SOLICITUD]: solicitud})
        await this.refresh()
    }

    @boundMethod
    _filterData(data, filter) {
        const flt = getFieldExtractor(filter)
        const enabled = flt(FILTER_ENABLED)
        const idSol = flt(FILTER_SOLICITUD, Solicitud.idSolicitud)
        const idCotiz = flt(FILTER_COTIZACION, Tarea.idTarea)

        if (!enabled || (!idSol && !idCotiz)) {
            return data
        }

        const result = []
        for (let row of data) {
            if (idSol && row[Documento.idSolicitud] !== idSol) {
                continue
            }
            if (idCotiz && row[Documento.idCotizacion] !== idCotiz) {
                continue
            }
            result.push(row)
        }
        return result
    }

}