import React from 'react'
import {observer} from 'mobx-react'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {Frame} from 'sopix/formComps/Frame'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {Bar} from 'sopix/formComps/Bar'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {Push} from 'sopix/formComps/Push'
import {graphqlUrl} from 'sop/db-config/db'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {icons} from 'sopix/icon/icons'
import {TareaTable} from 'sop/db/TareaTable'
import {listFormSplitDefault} from 'sop/sop-config'
import {ErrorList} from 'sopix/errors/ErrorList'
import {FormControl} from 'sopix/piece-components/FormControl'
import {linkFormListWithLoad} from 'sopix/piece-linkers/form-list'
import {TrabajoSearch, TrabajoSearchCommon} from 'sop/componentes/trabajo/TrabajoSearch'
import {TrabajoForm} from 'sop/componentes/trabajo/TrabajoForm'
import {TrabajoFormPiece} from 'sop/componentes/trabajo/TrabajoFormPiece'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {UrlManager} from 'sopix/pieces/urlManager'
import {linkUrlForm} from 'sopix/piece-linkers/url-form'
import {action, observable, runInAction} from 'mobx'
import {TrabajoTab} from 'sop/componentes/trabajo/TrabajoTab'
import {linkUrlSelection} from 'sopix/piece-linkers/url-selection'
import {boundMethod} from 'autobind-decorator'
import {avisosCheck} from 'sop/componentes/aviso/avisosCheck'
import {aprobacionCheck, refreshAprobacionesPage} from 'sop/componentes/aprobacion/aprobacionCheck'
import {setRefreshTrabajosPage} from 'sop/componentes/trabajo/trabajoCheck'
import {linkFiltratorSelection} from 'sopix/piece-linkers/filtrator-selection'
import {sopIcons} from 'sop/sop-icons'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {Tarea} from 'sop/db/Tarea'
import {RolesUsuario, TrabajoClase, UserRoles} from 'sop/db-config/row-consts'
import {userAuth} from 'sopix/session/userAuth'
import {TipoTarea} from 'sop/db/TipoTarea'
import {RepartirPart, RepartirPartPiece} from 'sop/componentes/trabajo/RepartirPart'
import {TrabajoListMode} from 'sop/componentes/trabajo/trabajo-defs'
import {TrabajoList, TrabajoListPiece} from 'sop/componentes/trabajo/TrabajoList'
import {getAuthSingleton} from 'sopix/session/auth-singletons'
import {ALERT_CANCEL, ALERT_OK} from 'sopix/alert/alert-defs'

/**
 * @enum
 */
export const TrabajosMode = Object.freeze({
    TRABAJOS: Symbol('TRABAJOS'),
    VALORACIONES: Symbol('VALORACIONES'),
    ESTUDIOS: Symbol('ESTUDIOS'),
    REPARTIR_ESTUDIOS: Symbol('REPARTIR_ESTUDIOS')
})

export const trabajoModeToList = new Map([
    [TrabajosMode.TRABAJOS, TrabajoListMode.TRABAJOS],
    [TrabajosMode.VALORACIONES, TrabajoListMode.VALORACIONES],
    [TrabajosMode.ESTUDIOS, TrabajoListMode.ESTUDIOS],
    [TrabajosMode.REPARTIR_ESTUDIOS, TrabajoListMode.VALORACIONES],
])



export class TrabajoPagePiece extends PagePiece{

    _asumirMutation = new SimpleMutation(graphqlUrl, 'AsumirEstudio', 
        [{cotizacionAsumible: [
            Tarea.ref,
        ]}])

    _trabajosQL = new TableGraphql(TareaTable)

    @observable
    /** @type {TrabajosMode} */
    mode
    
    @observable
    cotizacionAsumible
    
    constructor() {
        super(sopPages.trabajos)
        
        this.form = new TrabajoFormPiece(this.world)
        this.urlManager = new UrlManager(this.world, this.form.idField, sopUrls.trabajos, sopUrls.trabajo)
        
        this.list = new TrabajoListPiece(this.world, TrabajoListMode.TRABAJOS)
        
        this.repartir = new RepartirPartPiece(this.world)

        linkFormListWithLoad(this.list, this.form)
        linkUrlForm(this, this.urlManager, this.form)
        linkUrlSelection(this.urlManager, this.list.selection)
        linkFiltratorSelection(this.list.filtrator, this.list.selection)

        setRefreshTrabajosPage(this.externalRefresh)

        this.form.aproposicionForm.onSave.subscribe(this.refresh)
        this.form.aproestaForm.onSave.subscribe(this.refresh)
    }

    async _init() {
        this.setMode(TrabajosMode.TRABAJOS)
        await Promise.all([
            this.refreshAvisos(),
            this.refreshSePuedeAsumir(),
        ])
        await super._init()
    }

    @action.bound
    setMode(mode){
        this.list.setMode(trabajoModeToList.get(mode))
        this.mode = mode
    }
    __setMode = this._action(this.setMode)
    
    
    @boundMethod
    async refreshAvisos(){
        await Promise.all([
            avisosCheck.refresh(),
            aprobacionCheck.refresh(),
        ])
    }
    
    @boundMethod
    async externalRefresh(){
        await Promise.all([
            this.list.filtrator.refresh(),
            this.list.selection.set()
        ])
    }
    
    @boundMethod
    async refreshExtra(){
        await Promise.all([
            this.refreshAvisos(),
            this.refresh(),
        ])
    }
    __refreshExtra = this._asyncAction(this.refreshExtra)


    @boundMethod
    async refresh() {
        await Promise.all([
            super.refresh(),
            this.refreshSePuedeAsumir(),
            this.repartir.refresh(),
        ])
    }

    async refreshSePuedeAsumir(){
        if (userAuth.role !== RolesUsuario.TECNICO) {
            return
        }
        const {cotizacionAsumible} = await this._asumirMutation.query({onlyCheck: true})
        runInAction(() => {
            this.cotizacionAsumible = cotizacionAsumible
        })
    }

    @boundMethod
    async asumirEstudio(){
        const ref = this.cotizacionAsumible[Tarea.ref]
        if (ALERT_OK === await askAlert(new AlertDefinition(`Estudio ${ref}`, 
            `Se asumirá el estudio ${ref}.`,[
                [ALERT_OK, 'Asumir'],
                [ALERT_CANCEL, 'Cancelar'],
            ]))) 
        {
            await this._asumirMutation.query()
            await this.refresh()
        }
    }
    __asumirEstudio = this._asyncAction(this.asumirEstudio)


    @boundMethod
    async realizar(){
        const ref = this.form.getField(Tarea.ref)
        const clase = this.form.getField(Tarea.tipo, TipoTarea.clase) 
        if (clase === TrabajoClase.TAREA 
            || ALERT_OK === await askAlert(new AlertDefinition(`Cotización ${ref}`,
            `Se realizará la cotización ${ref}.`,[
                [ALERT_OK, 'Realizar'],
                [ALERT_CANCEL, 'Cancelar'],
            ])))
        {
            await this.form.realizar()
            await Promise.all([
                this.refresh(),
                aprobacionCheck.refresh(),
            ])
            refreshAprobacionesPage()
        }
    }

}


export const getTrabajoPage = getAuthSingleton(sopPages.trabajos.id, () => {
    return new TrabajoPagePiece(sopPages.trabajos)
})






export const TrabajoPageRight = observer(
    /**
     * @param {TrabajoPagePiece} page
     */
    ({page}) => {

        const buttons = []
        
        const asa = page._asyncAction
        
        if (page.form.sePuedeRealizar){
            buttons.push(
                <HintIconButton Icon={icons.Check}
                                title="Realizar" onClick={asa(page.realizar)}
                />
            )
        }
        
        return ( 
            <Frame>
                {!page.form.valid ? null :
                    <Bar>
                        <ListNavigator list={page.list}/><Separator rightGap/>
                        {page.form.titleWithIcon}
                        <Push/>
                        {!buttons.length ? null :
                            <>
                                <Separator/>
                                {buttons}
                            </>
                        }
                        <Separator/>
                        <FormControl form={page.form} />
                    </Bar>
                }
                <TrabajoForm form={page.form} />
            </Frame>
        )
})    






export const TrabajoPage = observer(
    /**
     * @param {TrabajoPagePiece} page
     */
    ({page}) => {
        usePiece(page)
        
        const repartir = page.mode === TrabajosMode.REPARTIR_ESTUDIOS
        
        const asa = page._asyncAction
        
        const topButtons = 
            <HintIconButton Icon={icons.Reload} large title="Actualizar"
                onClick={repartir
                    ? asa(page.repartir.refresh)
                    : asa(page.refreshExtra)
                }
            />
            
        const asumirVisible = userAuth.role === UserRoles.TECNICO 
            && [TrabajosMode.TRABAJOS, TrabajosMode.ESTUDIOS].indexOf(page.mode) >= 0
        
        // ATENCIÓN: El VerticalSplit tiene que ser hijo de Frame porque aplicara el 100% del padre
        // y como en este caso la barra de estado ocupa espacio, parte quedaría oculta por abajo sin el Frame.
        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame column piece={page} renderPiece={()=> {
                    return (
                        <>
                            <Frame>
                                <Bar alignEnd >
                                    <TrabajoTab page={page} />
                                    <Separator />
                                    {page.mode === TrabajosMode.REPARTIR_ESTUDIOS ? null :
                                        <TrabajoSearchCommon search={page.list.search} extra={topButtons}/>
                                    }
                                    {page.mode !== TrabajosMode.REPARTIR_ESTUDIOS ? null : 
                                        <>  
                                            <Push />
                                            {topButtons}
                                        </>
                                    }
                                </Bar>
                                <Frame>
                                    {repartir
                                        ?   <RepartirPart part={page.repartir} />
                                        :   <VerticalSplit id="TrabajoPage" defaultSizes={listFormSplitDefault}>
                                                <Frame>
                                                    <Bar>
                                                        <TrabajoSearch search={page.list.search} extra={
                                                            !asumirVisible ? undefined :
                                                                <HintIconButton large
                                                                                Icon={sopIcons.AsumirEstudio}
                                                                                disabled={!page.cotizacionAsumible}
                                                                                title={!page.cotizacionAsumible ? undefined :
                                                                                    `Asumir ${page.cotizacionAsumible[Tarea.ref]}`}
                                                                                onClick={page.__asumirEstudio}
                                                                />
                                                        }/>
                                                    </Bar>
                                                    <TrabajoList list={page.list}/>
                                                </Frame>
                                                <TrabajoPageRight page={page}/>
                                            </VerticalSplit>
                                    }
                                </Frame>
                            </Frame>
                        </>
                    )

                }} />
            </>
        )
    }
)
