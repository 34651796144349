 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DispositivoIds} from './Dispositivo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const DispositivoCol = Object.freeze({
    idDispositivo : new SimpleColumn(DispositivoIds.idDispositivo, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(DispositivoIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(DispositivoIds.fechaCreacion, 11, 11, Align.LEFT),
    fechaFin : new DateTimeColumn(DispositivoIds.fechaFin, 16, 16, Align.LEFT),
    fechaFinOnlyDate : new DateColumn(DispositivoIds.fechaFin, 11, 11, Align.LEFT),
})

tableColumns[Tables.Dispositivo] = DispositivoCol
