import {AlertDefinition} from 'sopix/alert/alertDefinition'

export const ALERT_OK = Symbol('ALERT_OK')
export const ALERT_ELIMINAR = Symbol('ALERT_ELIMINAR')
export const ALERT_CANCEL = Symbol('ALERT_CANCEL')
export const ALERT_DESCARTAR = Symbol('ALERT_DESCARTAR')

export const cambiosSinGuardar = new AlertDefinition(
    '¿Descartar cambios?',
    'Hay cambios sin guardar. Si continua se perderán.',
    [
        [ALERT_DESCARTAR, 'Descartar'],
        [ALERT_CANCEL, 'Cancelar'],
    ],
)