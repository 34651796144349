import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {SagePedido} from 'sop/db/SagePedido'
import {SagePedidoFld, SagePedidoTable} from 'sop/db/SagePedidoTable'
import {SagePedidoCol} from 'sop/db/SagePedidoColumns'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {OrderDirection} from 'sopix/data/orderEntry'
import {SageDomicilio} from 'sop/db/SageDomicilio'
import {DbSource} from 'sopix/data-source/dbSource'


export class SagePedidoPainter extends TablePainter{
    _fixColumn(col, id, dbCol) {
        
        switch (id){
            case SagePedido.referencia:
                col.title = 'Obra'
                break
            default:
        }
    }
}


export class SagePedidosPiece extends Piece{
    idField = SagePedido.numeroPedido

    constructor(world) {
        super(world)

        const FIELDS = [
            SagePedidoFld.codigoEmpresa,
            SagePedidoFld.codigoCliente,
            SagePedidoFld.referencia,
            SagePedidoFld.numeroPedido,
            SagePedidoFld.suPedido,
            SagePedidoFld.fechaPedido,
            SagePedidoFld.razonSocial,
        ]

        const COLUMNS = [
            SagePedidoCol.referencia,
            SagePedidoCol.numeroPedido,
            SagePedidoCol.suPedido,
            SagePedidoCol.fechaPedidoOnlyDate,
            SagePedidoCol.razonSocial,
        ]

        this.painter = new SagePedidoPainter(world, COLUMNS)

        this.dbSource = new DbSource(world,
            new TableGraphql(SagePedidoTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(SagePedido.fechaPedido, OrderDirection.DESC)},
            })

        this.load = this._state(Symbol('LOAD'), async (codigoEmpresa, codigoCliente) => {
            this.dbSource._setGraphqlFilter({
                ...this.dbSource.graphqlFilter,
                [SagePedido.codigoEmpresa]: codigoEmpresa,
                [SagePedido.codigoCliente]: codigoCliente,
            })
            await this.dbSource.refresh()
        })
    }

}


export const SagePedidos = getPieceContainer('list', observer(
    /**
     * @param {SagePedidosPiece} list
     */
    ({list}) => {

        const sya = list._action

        return (
            <Frame>
                <BlTable fitContainer dense
                     global={list}
                     verticalAlign="center"
                     data={list.dbSource.data}
                     keyColumnId={list.idField}
                     columns={list.painter.boundlessColumns}
                     renderCellContent={sya(list.painter.boundlessPainter)}
                     renderHeaderContent={sya(list.painter.headerPainter)}
                />
            </Frame>
        )
}))
