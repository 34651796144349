import {AproestaFld, AproestaTable} from 'sop/db/AproestaTable'
import {Aproesta} from 'sop/db/Aproesta'
import {observer} from 'mobx-react'
import {Fields} from 'sopix/formComps/Fields'
import React from 'react'
import {TareaFld, TareaTable} from 'sop/db/TareaTable'
import {TipoTareaTable} from 'sop/db/TipoTareaTable'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Field} from 'sopix/form/editors/Field'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {TareaEdt} from 'sop/db/TareaEditors'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {runInAction} from 'mobx'
import {AproestaEdt} from 'sop/db/AproestaEditors'
import {sopIcons} from 'sop/sop-icons'
import {boundMethod} from 'autobind-decorator'
import {Tarea} from 'sop/db/Tarea'
import {TareaEstadoFld, TareaEstadoTable} from 'sop/db/TareaEstadoTable'
import {filter} from 'lodash-es'
import {TareaEstado} from 'sop/db/TareaEstado'
import {TrabajoEstadoValue} from 'sop/db-config/row-consts'
import {TareaEstadoEdt} from 'sop/db/TareaEstadoEditors'
import {AproFormPiece} from 'sop/componentes/aprobacion/aproFormPiece'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {Permiso} from 'sop/db/Permiso'
import {usePiece} from 'sopix/piece/use-piece'

export class AproestaFormPiece extends AproFormPiece{

    permisoAdmin = Permiso.aprobarCambioEstado
    permisoSolicitar = Permiso.solicitarCambioEstado
    
    _listFetcher = new ListFetcher([
        TareaEdt.estado.getListQueryEntry(),
    ])

    constructor(world) {

        const FIELDS = [
            ...AproestaTable.regularFields,
            [AproestaFld.aprobacion, [AprobacionFld.estado]],
            [AproestaFld.estadoOriginal, [TareaEstadoFld.estado]],
            [AproestaFld.trabajo,[
                ...TareaTable.regularFields,
                [TareaFld.estado, [TareaEstadoFld.estado]],
                [TareaFld.tipo, TipoTareaTable.regularFields],
            ]],
        ]

        const TABLE = AproestaTable
        super(world,
            FIELDS,
            {
                name: 'Solicitud de cambio de estado',
                icon: sopIcons.TrabajoEstado,
                table: TABLE,
                idField: Aproesta.idAproesta,
                saveMutation: 'SaveAproesta',
            },
        )
        this._actionMutation = new SimpleMutation(graphqlUrl, 'AproestaAction', ['success'])
    }

    @boundMethod
    async _createRow() {
        this.newRowFields[Aproesta.idEstadoOriginal]
            = getFieldExtractor(this.newRowFields)(Aproesta.trabajo, Tarea.idEstado)
        this.newRowFields[Aproesta.estadoOriginal]
            = getFieldExtractor(this.newRowFields)(Aproesta.trabajo, Tarea.estado)
        return await super._createRow()
    }

    async _init() {
        const lists = await this._listFetcher.fetch()
        runInAction(()=>{
            this.lists = lists
        })
    }

    @boundMethod
    async _applyRow(data) {
        await super._applyRow(data)
    }

    @boundMethod
    _getTitle(empty) {
        return this.name
    }
}




export const AproestaForm = observer(
    /**
     * @param {AproestaFormPiece} form
     */
    ({form}) => {

        usePiece(form)

        return (
            <PieceFrame piece={form} renderPiece={()=> {

                const H = form.fieldHelper
                const fld = form.getField

                const prohibidos = [
                    fld(Aproesta.trabajo, Tarea.idEstado),
                    TrabajoEstadoValue.REALIZADO,
                ]
                
                if (!fld(Aproesta.trabajo, Tarea.idResponsable)) {
                    prohibidos.push(TrabajoEstadoValue.ASIGNADO)
                }
                    
                const options = filter(form.lists[TareaEstadoTable.graphqlId].rows, row => 
                    -1 === prohibidos.indexOf(row[TareaEstado.idEstado]) )
                
                return(
                    <Fields margin>
                        <Field {...H.obj(AproestaEdt.estadoOriginal, TareaEstadoEdt.estado)} />
                        <DropdownField {...H.edt(AproestaEdt.estado)} options={options} />
                        <Field {...H.edt(AproestaEdt.justificacion)} multiline rows={10} width={1000} />
                    </Fields>
                )
            }} />
        )
    })
