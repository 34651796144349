import {FormPiece} from 'sopix/pieces/formPiece'
import {ObserverHub} from 'sopix/utils/observers'
import {APRO_ACTION, AproAction} from 'sop/componentes/aprobacion/aprobacion-consts'
import {boundMethod} from 'autobind-decorator'

export class AproFormPiece extends FormPiece{

    /** @type {SimpleMutation} */
    _actionMutation
    
    onAction = new ObserverHub()
    
    sePuedeDenegar = true
    sePuedeCorregir = false
    
    permisoAdmin
    permisoSolicitar
    
    anulable
    
    async action(action, params = {}, {save=false} = {}){
        if (save) {
            await this.save()
        }
        await this._actionMutation.query({
            [APRO_ACTION]: action,
            id: this.id,
            ...params,
        })
        this.onAction.notify(this.id)
    }
    
    @boundMethod
    async anular(options){
        await this.action(AproAction.ANULAR, {}, options)
    }
    __anular = this._asyncAction(this.anular)
    
}