import {snackbarNotifications} from 'sopix/snackbar/snackbarNotifications'

export function formLockedSnakbar() {
    const msg = snackbarNotifications.notify('Ficha bloqueada', 'error')
    msg.append('Guarda los cambios o reviértelos para poder cambiar de registro.')
}

/**
 *
 * @param {TablePiece} list
 * @param {FormPiece} form
 */
function linkFormList(list, form) {
    list.selection.onLockedRejection.subscribe(formLockedSnakbar)
    form.onCreate.subscribe(list.selection.rowCreated)
    form.onDirty.subscribe(list.selection.lock)
    form.onSave.subscribe(async fields => {
        list.selection.rowSaved(fields)
        await list.filtrator.refresh()
    })
    form.onDelete.subscribe(list.selection.rowDeleted)
}

/**
 * 
 * @param {TablePiece} list
 * @param {FormPiece} form
 */
export function linkFormListWithApply(list, form){
    list.selection.onRowSelected.subscribe(form.rowSelectedForApply)
    linkFormList(list, form)
}

/**
 *
 * @param {TablePiece} list
 * @param {FormPiece} form
 */
export function linkFormListWithLoad(list, form){
    list.selection.onRowSelected.subscribe(form.rowSelectedForLoad)
    linkFormList(list, form)
}




export function linkFormListSeparated(form, list, setMode, formMode, listMode){
    form.onDelete.subscribe(async () => {
        await list.filtrator.refresh()
    })
    form.onRowNotFound.subscribe(() => {
        setMode(listMode)
    })

    list.selection.onRowSelected.subscribe(({id}) => {
        if (id){
            setMode(formMode)
        }
    })
    list.selection.onRowClicked.subscribe(async ({id}) => {
        if (id !== form.id) {
            await form.invalidate()
        }
        setMode(formMode)
    })
}