 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {EjecucionCuentaIds} from './EjecucionCuenta'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const EjecucionCuentaCol = Object.freeze({
    cuenta : new LookupColumn(EjecucionCuentaIds.cuenta, Tables.Cuenta, 'cuenta'),
    autor : new LookupColumn(EjecucionCuentaIds.autor, Tables.User, 'nombre_completo'),
    idEjecucionCuenta : new SimpleColumn(EjecucionCuentaIds.idEjecucionCuenta, 11, 11, Align.RIGHT),
    idRol : new SimpleColumn(EjecucionCuentaIds.idRol, 11, 11, Align.RIGHT),
    comentarios : new SimpleColumn(EjecucionCuentaIds.comentarios, 40, 16, Align.LEFT),
    fechaCreacion : new DateTimeColumn(EjecucionCuentaIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(EjecucionCuentaIds.fechaCreacion, 11, 11, Align.LEFT),
    odooCambiado : new SimpleColumn(EjecucionCuentaIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.EjecucionCuenta] = EjecucionCuentaCol
