import React from 'react'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'

export const AproPoperFrame = observer(
    /**
     * @param {AproPoper} poper
     * @param anulable
     */

    ({poper, children, ...props}) => {
    const asa = poper.form._asyncAction
    
    const isActive = poper.getEditDetails(poper.editingObj).isActive
    return (
        <PoperFrame
            {...props}
            poper={poper}
            extraButtons={!isActive ? null :
                <Button
                    onClick={asa(poper.anular)}
                    color="primary"
                >
                    Anular solicitud
                </Button>
            }
        >
            {children}
        </PoperFrame>
    )
})
