 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AprobacionTipoIds} from './AprobacionTipo'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const AprobacionTipoEdt = Object.freeze({
    idTipo : new SimpleEditor(AprobacionTipoIds.idTipo, 11, 11),
    tipo : new SimpleEditor(AprobacionTipoIds.tipo, 30, 12),
    permisoAprobar : new SimpleEditor(AprobacionTipoIds.permisoAprobar, 40, 16),
})

tableEditors[Tables.AprobacionTipo] = AprobacionTipoEdt
