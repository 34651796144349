import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import capitalize from '@material-ui/core/utils/capitalize'
import {defaultTo} from 'lodash-es'

const useStyles = makeStyles(theme => ({
    cellContainer: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        '&:first-child': {
            paddingLeft: 12,
        },
        '&:last-child': {
            paddingRight: 12,
        },
    },
    cell: {
        height: '100%',
        width: '100%',
        flex: 1,
        padding: '16px 6px 16px 6px',
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    
    verticalTop: {
        justifyContent: 'flex-start',
    },

    verticalBottom: {
        justifyContent: 'flex-end',
    },

    verticalCenter: {
        justifyContent: 'center',
    },
    
    stickyHeader: {
        position: 'sticky',
        top: 0,
        background: theme.palette.background.default,
        zIndex: 2,
    },
    head: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        lineHeight: '1.5rem',
    },
    sizeSmall: {
        padding: '6px 6px 6px 6px',
    },
    cellContainerSmall: {
        '&:first-child': {
            paddingLeft: 6,
        },
        '&:last-child': {
            paddingRight: 6,
        },
    },
    alignLeft: {
        alignItems: 'flex-start',
    },
    /* Styles applied to the nodes element if `align="center"`. */
    alignCenter: {
        alignItems: 'center',
    },
    /* Styles applied to the nodes element if `align="right"`. */
    alignRight: {
        alignItems: 'flex-end',
    },
    /* Styles applied to the nodes element if `align="justify"`. */
    alignJustify: {
        textAlign: 'justify',
    },
    invisible: {
        visibility: 'hidden',
    },
    noMargin: {
        padding: 0,
    }
})) 

export function BlCell({className, classes, style, align: alignProp, head=false, size: sizeProp,
                           verticalAlign = 'center', noMargin, onlyHover, children}) {
    
    const cls = useStyles()
    
    const size = defaultTo(sizeProp, 'medium') 
    const align = defaultTo(alignProp, 'left')
    
    return (
        <div className={clsx(
                cls.cellContainer,
                cls[`cellContainer${capitalize(size)}`],
                classes && classes.container,
                head && cls.stickyHeader,
                head && cls.head,
            )} style={style} 
        >
            <div className={clsx(
                    cls.cell,
                    cls[`vertical${capitalize(verticalAlign)}`],
                    {
                        [cls[`size${capitalize(size)}`]]: size !== 'medium',
                    },
                    cls[`align${capitalize(align)}`],
                    noMargin && cls.noMargin,
                    onlyHover && 'visible-on-hover',
                    onlyHover && cls.invisible,
                    className,
                )}
            >
                {children}
            </div>
        </div>
    )
}
