 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {RepresentanteIds} from './Representante'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const RepresentanteCol = Object.freeze({
    obra : new LookupColumn(RepresentanteIds.obra, Tables.Obra, 'cod'),
    representante : new LookupColumn(RepresentanteIds.representante, Tables.User, 'nombre_completo'),
    autor : new LookupColumn(RepresentanteIds.autor, Tables.User, 'nombre_completo'),
    idOr : new SimpleColumn(RepresentanteIds.idOr, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(RepresentanteIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(RepresentanteIds.fechaCreacion, 11, 11, Align.LEFT),
    odooCambiado : new SimpleColumn(RepresentanteIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Representante] = RepresentanteCol
