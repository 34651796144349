import {useEffect, useState} from 'react'
import {isFunction} from 'lodash-es'

export function useReactState(stateOrFunc){
    const [reactState] = useState(isFunction(stateOrFunc)
        ? stateOrFunc
        : stateOrFunc
    )
    
    useEffect(() => {
        reactState.mountReaction()
        return reactState.unmountReaction
    }, [reactState])

    return reactState
}