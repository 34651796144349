 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AprobacionTipoIds = Object.freeze({
    aprobaciones: Symbol('aprobaciones'),
    idTipo: Symbol('idTipo'),
    tipo: Symbol('tipo'),
    permisoAprobar: Symbol('permisoAprobar'),
})

export const AprobacionTipo = Object.freeze({
    aprobaciones: 'aprobaciones',
    idTipo: 'idTipo',
    tipo: 'tipo',
    permisoAprobar: 'permisoAprobar',
})
