import {Piece} from 'sopix/piece/piece'
import {FormFieldHelper} from 'sopix/form/formFieldHelper'
import {action, computed, observable, runInAction} from 'mobx'
import {boundMethod} from 'autobind-decorator'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {FieldManager} from 'sopix/field-manager/fieldManager'
import {ObserverHub} from 'sopix/utils/observers'

export class FieldsPiece extends Piece{

    @observable.shallow
    _fields = []

    @observable
    _dirty = false

    @observable
    _name = 'Cargando...'
    
    @observable
    _icon
    
    @observable.shallow
    lists = {}

    onDirty = new ObserverHub()
    
    /** @type {FieldManager} */
    fieldManager
    
    constructor(world, {name, icon, deleteUndefinedFields = false, defaultFields = {}} = {}) {
        super(world)

        runInAction(() => {
            this._name = name
            this._icon = icon
        })
        this.fieldManager = new FieldManager(
            {deleteUndefinedFields, defaultFields})
        
        this.fieldManager.onChangeObservers.subscribe(this._action(({fields})=>{
            this._fields = fields
        }))
        
        this.fieldManager.onDirtyObservers.subscribe(this._action(dirty => {
            this._dirty = dirty
            this.onDirty.notify(dirty)
        }))
    }
    
    get fields(){
        return this._fields
    }
    
    get name(){
        return this._name
    }
    
    get icon(){
        return this._icon
    }
    
    get onFieldsChange(){
        return this.fieldManager.onChangeObservers
    }
    
    get fieldHelper(){
        return new FormFieldHelper(this.fieldManager, this.lists)
    }
    
    @computed
    get dirty() {
        return this._dirty
    }
    
    @action
    setLists(lists){
        this.lists = lists
    }
    
    @boundMethod
    getField(...fieldPath){
        const fld = getFieldExtractor(this._fields)
        return fld(...fieldPath)
    }

    @boundMethod
    revert(){
        this.fieldManager.revert()
    }
    __revert = this._action(this.revert)
    
    @action
    setField(fieldName, value, {notifications=true} = {}){
        
        if (notifications) {
            this.fieldManager.setValue(fieldName, value)
            
        } else {
            this.fieldManager.onChangeObservers.disable()
            try {
                this.fieldManager.setValue(fieldName, value)
                this._fields = this.fieldManager.fields
            }finally{
                this.fieldManager.onChangeObservers.enable()
            }
        }
    }

    @action
    setFields(fields, {notifications=true} = {}){
        if (notifications) {
            this.fieldManager.setValues(fields)

        } else {
            this.fieldManager.onChangeObservers.disable()
            try {
                this.fieldManager.setValues(fields)
                this._fields = this.fieldManager.fields
            }finally{
                this.fieldManager.onChangeObservers.enable()
            }
        }
    }

}