 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const EjecucionCuentaIds = Object.freeze({
    ejecucion: Symbol('ejecucion'),
    cuenta: Symbol('cuenta'),
    obra: Symbol('obra'),
    autor: Symbol('autor'),
    idEjecucionCuenta: Symbol('idEjecucionCuenta'),
    idEjecucion: Symbol('idEjecucion'),
    idCuenta: Symbol('idCuenta'),
    idRol: Symbol('idRol'),
    idObra: Symbol('idObra'),
    comentarios: Symbol('comentarios'),
    fechaCreacion: Symbol('fechaCreacion'),
    idAutor: Symbol('idAutor'),
    odooCambiado: Symbol('odooCambiado'),
})

export const EjecucionCuenta = Object.freeze({
    ejecucion: 'ejecucion',
    cuenta: 'cuenta',
    obra: 'obra',
    autor: 'autor',
    idEjecucionCuenta: 'idEjecucionCuenta',
    idEjecucion: 'idEjecucion',
    idCuenta: 'idCuenta',
    idRol: 'idRol',
    idObra: 'idObra',
    comentarios: 'comentarios',
    fechaCreacion: 'fechaCreacion',
    idAutor: 'idAutor',
    odooCambiado: 'odooCambiado',
})
