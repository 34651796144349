 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {DocumentoIds} from './Documento'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const DocumentoCol = Object.freeze({
    autor : new LookupColumn(DocumentoIds.autor, Tables.User, 'nombre_completo'),
    solicitud : new LookupColumn(DocumentoIds.solicitud, Tables.Solicitud, 'id_solicitud'),
    cotizacion : new LookupColumn(DocumentoIds.cotizacion, Tables.Tarea, 'ref'),
    planosEstado : new LookupColumn(DocumentoIds.planosEstado, Tables.PlanoEstado, 'estado'),
    usuarioPlanos : new LookupColumn(DocumentoIds.usuarioPlanos, Tables.User, 'nombre_completo'),
    idDocumento : new SimpleColumn(DocumentoIds.idDocumento, 11, 11, Align.RIGHT),
    documento : new SimpleColumn(DocumentoIds.documento, 40, 16, Align.LEFT),
    fechaCreacion : new DateTimeColumn(DocumentoIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(DocumentoIds.fechaCreacion, 11, 11, Align.LEFT),
    borrado : new SimpleColumn(DocumentoIds.borrado, 11, 11, Align.RIGHT),
    fechaModificacion : new DateTimeColumn(DocumentoIds.fechaModificacion, 16, 16, Align.LEFT),
    fechaModificacionOnlyDate : new DateColumn(DocumentoIds.fechaModificacion, 11, 11, Align.LEFT),
    idModificacion : new SimpleColumn(DocumentoIds.idModificacion, 11, 11, Align.RIGHT),
    planosFechaEnvio : new DateTimeColumn(DocumentoIds.planosFechaEnvio, 16, 16, Align.LEFT),
    planosFechaEnvioOnlyDate : new DateColumn(DocumentoIds.planosFechaEnvio, 11, 11, Align.LEFT),
})

tableColumns[Tables.Documento] = DocumentoCol
