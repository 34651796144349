import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {cimStyles} from 'sopix/theme/cimStyles'

const useStyles = makeStyles(() => ({
    fields: {
        width: '100%',
        position: 'relative',
        
        ...cimStyles.fields,
    },
    
    noWrap: cimStyles.noWrap,
    
    margin: cimStyles.margin,
})) 

export function Fields({className, noWrap, margin, children}) {
    
    const cls = useStyles()
    
    return (
        <div className={clsx(
            cls.fields,
            margin && cls.margin,
            noWrap && cls.noWrap,
            className,
        )}>
            {children}
        </div>
    )
}
