 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproposicionIds} from './Aproposicion'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {AprobacionIds} from './Aprobacion'
import {TareaIds} from './Tarea'

export const AproposicionTable = new DbTable(
    Tables.Aproposicion,
    'Aproposiciones',
    graphqlUrl,
    'Aproposicion',
    [AproposicionIds.idAproposicion],
    [AproposicionIds.idAproposicion, AproposicionIds.idAprobacion, AproposicionIds.idTrabajo, AproposicionIds.posicionOriginal, AproposicionIds.nuevaPosicion, AproposicionIds.justificacion],
    [AproposicionIds.aprobacion, AproposicionIds.trabajo],
    [AproposicionIds.tareas],
    ['verAprobaciones'],
    ['solicitarCambioPosicion'],
    undefined,
    undefined,
    [],
    [],
)


export const AproposicionFld = Object.freeze({
    tareas : new DbObjectList(Tables.Aproposicion, AproposicionIds.tareas, Tables.Tarea),
    aprobacion : new DbObject(Tables.Aproposicion, AproposicionIds.aprobacion, AproposicionIds.idAprobacion, 'Aprobacion'),
    trabajo : new DbObject(Tables.Aproposicion, AproposicionIds.trabajo, AproposicionIds.idTrabajo, 'Trabajo'),
    idAproposicion : new DbIntId(Tables.Aproposicion, AproposicionIds.idAproposicion, 'Id aproposicion'),
    idAprobacion : new DbObjectId(Tables.Aproposicion, AproposicionIds.idAprobacion, 'Id aprobacion', {foreignTableId: Tables.Aprobacion, foreignKeyFieldId: AprobacionIds.idAprobacion}),
    idTrabajo : new DbObjectId(Tables.Aproposicion, AproposicionIds.idTrabajo, 'Id trabajo', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    posicionOriginal : new DbInt(Tables.Aproposicion, AproposicionIds.posicionOriginal, 'Posicion original'),
    nuevaPosicion : new DbInt(Tables.Aproposicion, AproposicionIds.nuevaPosicion, 'Nueva posicion'),
    justificacion : new DbText(Tables.Aproposicion, AproposicionIds.justificacion, 'Justificacion', 1000),
})

registerTableFields(Tables.Aproposicion, AproposicionFld) 
