export class Node{
    id
    parentId
    
    data
    parent
    children = new Map()
    
    constructor(id, parentId, data) {
        this.id = id
        this.parentId = parentId
        this.data = data
    }

    /**
     * Devuelve la relación de parentesco con el propio node
     * 
     * @param node
     * @returns {undefined|number} 0 = mismo, >0 descendiente, 1 hijo, <0 descendiente, -1 padre
     * 
     */
    getDescendantLevel(/** {Node} */ node){
        
        //Soy yo!
        if (node.id === this.id) {
            return 0
        }
        
        //Mira si es ancestor
        let current = this
        let level = 0
        while(true){
            current = current.parent
            if (!current) {
                break
            }
            level--
            if (current.id === node.id) {
                return level
            }
        }
        
        //Mira si es descendant (y yo su ancestor)
        current = node
        level = 0
        while(true){
            current = current.parent
            if (!current) {
                break
            }
            level++
            if (current.id === this.id) {
                return level
            }           
        }
        
        //No somos parientes
        return undefined
    }
    
    getAncestorCount(){
        let depth = 0
        let current = this
        while(current.parent){
            depth++
            current = current.parent
        }
        return depth
    }
}


export class Tree{
    
    /** @type Map<Any, Node> */
    nodes = new Map()
    roots = new Map()
    wrong = new Map()
    
    constructor(array, {idKey='id', parentIdKey = 'parentId'} = {}) {
        this.idKey = idKey
        this.parentIdKey = parentIdKey
   
        this._populateNodes(array)
    }


    _populateNodes(array) {
        const nodes = this.nodes

        //Add all
        nodes.clear()
        for (let entry of array){
            const id = entry[this.idKey]
            nodes.set(id, new Node(id, entry[this.parentIdKey], entry))
        }

        for (let [id, node] of nodes.entries()){
            if (!node.parentId) {
                this.roots.set(id, node)
                continue
            }
            
            const parentNode = nodes.get(node.parentId)
            
            node.parent = parentNode || null
            if (parentNode === null) {
                this.wrong.set(id, node)
                continue
            }
            
            parentNode.children.set(id, node)
        }
    }
}