import {find, isArray, orderBy} from 'lodash-es'
import {TableColumn} from 'sopix/boundless/boundless-classes'
import {DbColumn} from 'sopix/db/dbColumn'
import {Align} from 'sopix/db/db-types'
import {DbField} from 'sopix/db/dbField'

const DEFAULT_WIDTH = 100
const DEFAULT_MIN_WIDTH = 100

export function tableColumnMapFromDb(dbColumnDescriptors, fixTableColumn) {
    return new Map(tableColumnsFromDb(dbColumnDescriptors, fixTableColumn).map(col => [col.id, col]))
}

export function tableColumnsFromDb(dbColumnDescriptors, fixTableColumn){

    const result = []

    /**
     * @param {DbColumn} dbCol
     * @param id
     * @param {DbField} dbField
     */
    function addEntry(id, dbCol, dbField){
        const tableCol =
            dbCol
                ? new TableColumn(id, {
                    title: dbField ? dbField.title : dbCol.field.title,
                    width: dbCol.maxWidth,
                    minWidth: dbCol.minWidth,
                    //maxWidth: dbCol.maxWidth,
                    align: dbCol.align,
                })
                : new TableColumn(id, {
                    title: '',
                    width: DEFAULT_WIDTH,
                    minWidth: DEFAULT_MIN_WIDTH,
                    align: Align.LEFT,
                })
        fixTableColumn && fixTableColumn(tableCol, id, dbCol)
        result.push(tableCol)
    }
    
    for (let col of dbColumnDescriptors) {
        let left, id, dbColumn, dbField

        if (col instanceof DbColumn) {
            id = col.field.fieldName
            dbColumn = col

        } else {
            if (isArray(col)) {
                left = col[0]
                dbColumn = col[1]
                if (!(dbColumn instanceof DbColumn)) {
                    throw new Error(`Not a DbColumn: ${JSON.stringify(dbColumn)}`)
                }
            } else {
                left = col
                dbColumn = undefined
            }
            if (left instanceof DbField) {
                id = left.fieldName
                dbField = left
            } else {
                id = left
            }
        }
        addEntry(id, dbColumn, dbField)
    }

    return result
}


export function iterateRowsAndColumns(data, cols, func){
    const result = []
    
    for (let row of data) {
        const outRow = {...row}
        for (let [id, column] of cols.entries()){
            outRow[id] = func(id, row[id], column, row)
        }
        result.push(outRow)
    }
    
    return result
}



export function orderData(data, sortData, keyId, order){
    const ordered = orderBy(sortData, order.map(entry => entry.fieldId), order.map(entry => entry.direction))
    const result = []
    for (let sortRow of ordered){
        result.push(find(data, row => row[keyId] === sortRow[keyId]))
    }
    return result
}