 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const FileIds = Object.freeze({
    documento: Symbol('documento'),
    idFile: Symbol('idFile'),
    idDocumento: Symbol('idDocumento'),
    idAutor: Symbol('idAutor'),
    fileSize: Symbol('fileSize'),
    filePath: Symbol('filePath'),
    fechaCreacion: Symbol('fechaCreacion'),
    borrado: Symbol('borrado'),
    size: Symbol('size'),
})

export const File = Object.freeze({
    documento: 'documento',
    idFile: 'idFile',
    idDocumento: 'idDocumento',
    idAutor: 'idAutor',
    fileSize: 'fileSize',
    filePath: 'filePath',
    fechaCreacion: 'fechaCreacion',
    borrado: 'borrado',
    size: 'size',
})
