import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {FormPiece} from 'sopix/pieces/formPiece'
import {sopIcons} from 'sop/sop-icons'
import {EjecucionCuentaFld, EjecucionCuentaTable} from 'sop/db/EjecucionCuentaTable'
import {EjecucionCuenta} from 'sop/db/EjecucionCuenta'
import {Fields} from 'sopix/formComps/Fields'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {Cuenta} from 'sop/db/Cuenta'
import {Field} from 'sopix/form/editors/Field'
import {EjecucionCuentaEdt} from 'sop/db/EjecucionCuentaEditors'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {CuentaFld, CuentaTable} from 'sop/db/CuentaTable'
import {boundMethod} from 'autobind-decorator'


export class EjecucionCuentaFormPiece extends FormPiece{

    _cuentaQL = new TableGraphql(CuentaTable)

    constructor(world) {
        const FIELDS = [
            ...EjecucionCuentaTable.regularFields,
            [EjecucionCuentaFld.cuenta, [CuentaFld.idCuenta, CuentaFld.cuenta]]
        ]

        super(world,
            FIELDS,
            {
                name: 'Cuenta de ejecución',
                icon: sopIcons.Cuenta,
                table: EjecucionCuentaTable,
                idField: EjecucionCuenta.idEjecucionCuenta,
                saveMutation: 'SaveEjecucionCuenta',
                deleteMutation: 'DeleteEjecucionCuenta',
            },
        )
    }

    @boundMethod
    async cuentaOptionsSource(search){
        const list = await this._cuentaQL.query(
            [CuentaFld.idCuenta, CuentaFld.cuenta],
            {first: 50},
            {[`${Cuenta.cuenta}Ilike`]:  `%${search}%`}
        )
        return list.rows
    }
    __cuentaOptionsSource = this._asyncAction(this.cuentaOptionsSource)


    @boundMethod
    async cuentaChanged(cuenta){
        this.setField(EjecucionCuenta.cuenta, cuenta)
    }
    __cuentaChanged = this._asyncAction(this.cuentaChanged)
    
}


export const EjecucionCuentaForm = getPieceContainer('form', observer(
    /**
     * @param {EjecucionCuentaFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField
        const man = form.fieldManager

        const nuevo = !fld(EjecucionCuenta.idEjecucionCuenta)
        
        return (
            <Fields>
                <DropdownField disabled={!nuevo} {...H.edt(EjecucionCuentaEdt.cuenta)} optionsSource={form.__cuentaOptionsSource} 
                               options={[fld(EjecucionCuenta.cuenta)]} onChange={form.__cuentaChanged} />
                <Field {...H.edt(EjecucionCuentaEdt.comentarios)} multiline rows={10} width={1000} />
            </Fields>
        )
}))
