 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const RepresentanteIds = Object.freeze({
    obra: Symbol('obra'),
    representante: Symbol('representante'),
    autor: Symbol('autor'),
    idOr: Symbol('idOr'),
    idObra: Symbol('idObra'),
    idRepresentante: Symbol('idRepresentante'),
    idAutor: Symbol('idAutor'),
    fechaCreacion: Symbol('fechaCreacion'),
    odooCambiado: Symbol('odooCambiado'),
})

export const Representante = Object.freeze({
    obra: 'obra',
    representante: 'representante',
    autor: 'autor',
    idOr: 'idOr',
    idObra: 'idObra',
    idRepresentante: 'idRepresentante',
    idAutor: 'idAutor',
    fechaCreacion: 'fechaCreacion',
    odooCambiado: 'odooCambiado',
})
