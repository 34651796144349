import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {BlRow} from 'sopix/boundless-table/BlRow'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    tableHeader: {
        width: '100%',
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
    }
})) 

export function BlHeader({children}) {
    
    const cls = useStyles()
    
    return (
        <div className={clsx(cls.tableHeader, cls.stickyHeader)}>
            <BlRow>
                {children}
            </BlRow>
        </div>
    )
}
