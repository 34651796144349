 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {LiquidacionIds} from './Liquidacion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const LiquidacionCol = Object.freeze({
    autor : new LookupColumn(LiquidacionIds.autor, Tables.User, 'nombre_completo'),
    responsable : new LookupColumn(LiquidacionIds.responsable, Tables.User, 'nombre_completo'),
    tarea : new LookupColumn(LiquidacionIds.tarea, Tables.Tarea, 'ref'),
    idLiquidacion : new SimpleColumn(LiquidacionIds.idLiquidacion, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(LiquidacionIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(LiquidacionIds.fechaCreacion, 11, 11, Align.LEFT),
    fecha : new DateTimeColumn(LiquidacionIds.fecha, 16, 16, Align.LEFT),
    fechaOnlyDate : new DateColumn(LiquidacionIds.fecha, 11, 11, Align.LEFT),
    m2Efectivos : new SimpleColumn(LiquidacionIds.m2Efectivos, 11, 11, Align.RIGHT),
    descripcion : new SimpleColumn(LiquidacionIds.descripcion, 40, 16, Align.LEFT),
})

tableColumns[Tables.Liquidacion] = LiquidacionCol
