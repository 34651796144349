import {userAuth} from 'sopix/session/userAuth'

const DENY = 0
const ALLOW = 1
const DENY_IF_NOT = 2
const ALLOW_IF_NOT = 3


/**
 * @param {DbTable} table
 * @returns {boolean|*}
 */
export function canReadTable(table){
    if (!table.readPermissions) {
        return false
    }
    return userAuth.isAllowed(table.readPermissions)
}


/**
 * @param {DbTable} table
 * @returns {boolean|*}
 */
export function canWriteTable(table){
    if (!table.writePermissions) {
        return false
    }
    return userAuth.isAllowed(table.writePermissions)
}


/**
 * @param {DbTable} table
 * @returns {boolean|*}
 */
export function canCreateTable(table){
    if (!table.createPermissions) {
        return false
    }
    return userAuth.isAllowed(table.createPermissions)
}


/**
 * @param {DbTable} table
 * @returns {boolean|*}
 */
export function canDeleteTable(table){
    if (!table.deletePermissions) {
        return false
    }
    return userAuth.isAllowed(table.deletePermissions)
}



/**
 * @param {DbField} field
 * @returns {boolean|*}
 */
function canAccessField(fieldPermissions, field){
    let result = true
    for (let config of fieldPermissions) {
        const allowOrDeny = config[0]
        const permisos = config[1]
        const fieldIds = config[2]

        const fieldIncluded = fieldIds.indexOf(field.fieldId) >= 0

        if (!fieldIds.length || fieldIncluded) {
            const match = userAuth.isAllowed(permisos)
            if (match && (allowOrDeny === ALLOW || allowOrDeny === DENY)) {
                result = allowOrDeny === ALLOW
            } else if (!match && (allowOrDeny === ALLOW_IF_NOT || allowOrDeny === DENY_IF_NOT)) {
                result = allowOrDeny === ALLOW_IF_NOT
            }
        }
    }
    return result
}


/**
 * @param {DbField} field
 * @returns {boolean|*}
 */
export function canReadField(field){
    const table = field.table
    const result = canAccessField(table.readFieldPermissions, field)
    if (!result) {
        const test = 0
    }
    return result
}


/**
 * @param {DbField} field
 * @returns {boolean|*}
 */
export function canWriteField(field){
    const table = field.table
    return canAccessField(table.writeFieldPermissions, field)
}
