 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const PermisoTransposedIds = Object.freeze({
    idPermiso: Symbol('idPermiso'),
    permiso: Symbol('permiso'),
})

export const PermisoTransposed = Object.freeze({
    idPermiso: 'idPermiso',
    permiso: 'permiso',
})
