 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AvisoIds} from './Aviso'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {BoolEditor, DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {AvisoTipoIds} from './AvisoTipo'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {TareaIds} from './Tarea'

export const AvisoEdt = Object.freeze({
    tipo : new LookupEditor(AvisoIds.tipo, Tables.AvisoTipo, AvisoTipoIds.idTipo, 'tipo', {sort: 'ID_TIPO_ASC'}),
    usuario : new LookupEditor(AvisoIds.usuario, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    obra : new LookupEditor(AvisoIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    tarea : new LookupEditor(AvisoIds.tarea, Tables.Tarea, TareaIds.idTarea, 'ref', {sort: 'ID_TAREA_ASC'}),
    idAviso : new SimpleEditor(AvisoIds.idAviso, 11, 11),
    fecha : new DateTimeEditor(AvisoIds.fecha, 16, 16),
    fechaOnlyDate : new DateEditor(AvisoIds.fecha, 11, 11),
    aviso : new SimpleEditor(AvisoIds.aviso, 40, 16),
    idPedido : new SimpleEditor(AvisoIds.idPedido, 11, 11),
    pendiente : new BoolEditor(AvisoIds.pendiente, 9, 9),
})

tableEditors[Tables.Aviso] = AvisoEdt
