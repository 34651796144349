 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const EjecucionIds = Object.freeze({
    ejecucionesCuentas: Symbol('ejecucionesCuentas'),
    obra: Symbol('obra'),
    tarea: Symbol('tarea'),
    sistema: Symbol('sistema'),
    procedencia: Symbol('procedencia'),
    idEjecucion: Symbol('idEjecucion'),
    idObra: Symbol('idObra'),
    idTarea: Symbol('idTarea'),
    idSistema: Symbol('idSistema'),
    idProcedencia: Symbol('idProcedencia'),
    m2Calculados: Symbol('m2Calculados'),
    m2Efectivos: Symbol('m2Efectivos'),
    m2Totales: Symbol('m2Totales'),
    competencia: Symbol('competencia'),
    idAutor: Symbol('idAutor'),
    fechaCreacion: Symbol('fechaCreacion'),
    odooCambiado: Symbol('odooCambiado'),
})

export const Ejecucion = Object.freeze({
    ejecucionesCuentas: 'ejecucionesCuentas',
    obra: 'obra',
    tarea: 'tarea',
    sistema: 'sistema',
    procedencia: 'procedencia',
    idEjecucion: 'idEjecucion',
    idObra: 'idObra',
    idTarea: 'idTarea',
    idSistema: 'idSistema',
    idProcedencia: 'idProcedencia',
    m2Calculados: 'm2Calculados',
    m2Efectivos: 'm2Efectivos',
    m2Totales: 'm2Totales',
    competencia: 'competencia',
    idAutor: 'idAutor',
    fechaCreacion: 'fechaCreacion',
    odooCambiado: 'odooCambiado',
})
