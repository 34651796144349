import {fields} from 'sopix/db/dbField'

export const PIXELS_PER_CHAR = 10
const UNDEFINED_CHARS = 2


export class DbFieldView{
    constructor(fieldId, maxChars = undefined, minChars = undefined, 
                align = undefined) {
        this.fieldId = fieldId
        this._minChars = minChars
        this._maxChars = maxChars
        this._align = align
    }

    get maxWidth(){
        let max_chars = this._maxChars
        if (!max_chars) max_chars = UNDEFINED_CHARS
        return max_chars * PIXELS_PER_CHAR
    }

    get minWidth(){
        let min_chars = this._minChars
        if (!min_chars) min_chars = UNDEFINED_CHARS
        return min_chars * (min_chars < 15 ? PIXELS_PER_CHAR : PIXELS_PER_CHAR / 2)
    }

    get align(){
        return this._align
    }

    get field(){
        return fields[this.fieldId]
    }

    get title(){
        return this.field.title
    }
}
