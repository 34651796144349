 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AvisoTipoIds = Object.freeze({
    avisos: Symbol('avisos'),
    usuariosSubscritos: Symbol('usuariosSubscritos'),
    idTipo: Symbol('idTipo'),
    tipo: Symbol('tipo'),
})

export const AvisoTipo = Object.freeze({
    avisos: 'avisos',
    usuariosSubscritos: 'usuariosSubscritos',
    idTipo: 'idTipo',
    tipo: 'tipo',
})
