import React from 'react'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {Cuenta} from 'sop/db/Cuenta'
import {CuentaCol} from 'sop/db/CuentaColumns'
import {CuentaFld, CuentaTable} from 'sop/db/CuentaTable'
import {cuentaFilter} from 'sop/componentes/cuenta/cuentaFilter'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'


class CuentaPainter extends TablePainter{
    
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds}) {
        switch(id){
            default:
                return formatter(value)
        }
    }
    
    _fixColumn(col, id, dbCol) {
        switch(id){
            default:
                super._fixColumn(col, id, dbCol)
        }
    }
}

export class CuentaListPiece extends TablePiece{

    constructor(world) {

        const TABLE = CuentaTable
        const ID_FIELD = Cuenta.idCuenta
        const DEFAULT_FILTER = {}
        const DEFAULT_ORDER = [new OrderEntry(Cuenta.fechaAlta, OrderDirection.DESC)]

        const COLUMNS = [
            CuentaCol.fechaAltaOnlyDate,
            CuentaCol.cuenta,
        ]
        
        const FIELDS = [
            CuentaFld.idCuenta,
            CuentaFld.fechaAlta,
            CuentaFld.cuenta,
        ]
        
        const painter = new CuentaPainter(world, COLUMNS)
        
        const filtrator = graphqlFiltratorBuilder(world, TABLE, FIELDS, {
            graphqlFilterCalculator: cuentaFilter, defaultFilter: DEFAULT_FILTER, defaultOrder: DEFAULT_ORDER})
        
        const selection = new RowSelection(world, filtrator, ID_FIELD) 
        
        super(world, ID_FIELD, filtrator, painter, selection)
    }

}

