import Moment from 'moment'
import {Liqtecmes} from 'sop/db/Liqtecmes'


export const TECMES_MES_DESDE = 'MES_DESDE'
export const TECMES_MES_HASTA = 'MES_HASTA'

export function getIdMes(date){
    const moment = Moment(date)
    const year = moment.year()
    const month = moment.month()
    return year * 12 + month
}

export function getDateFromIdMes(idMes){
    return Moment(Date.UTC(Math.floor(idMes/12), idMes % 12)).format()
}


// MISTERIO: Si llamo al método xxxListFilter el breakpoint no se detiene!!!
/**
 *
 * @param searchFields
 * @returns {Object<String>}
 */
export function tecnicoMesFilter(searchFields) {
    if (!searchFields) return {}


    const result = {}
    for (let [key, value] of Object.entries(searchFields)) {
        if (!value) {
            continue
        }

        const filters = {
            [TECMES_MES_DESDE]: () => {
                result[`${Liqtecmes.idMes}Gte`] = getIdMes(value)
            },
            [TECMES_MES_HASTA]: () => {
                result[`${Liqtecmes.idMes}Lte`] = getIdMes(value)
            },
            [Liqtecmes.idTecnico]: () => {
                result[`${Liqtecmes.idTecnico}In`] = value 
            }
        }

        const filter = filters[key]
        filter && filter()
    }
    return result
}

