import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import {debounce} from 'lodash-es'
import {getLoggers} from 'sopix/log'
import {boundMethod} from 'autobind-decorator'

const {debug} = getLoggers('TransparentProgress')

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}))


class TransparentProgressState{

    setVisible
    open
    visible
    
    render(open, visible, setVisible){
        this.open = open
        this.visible = visible
        this.setVisible = setVisible
    }
    
    makeVisible = debounce(() => {
        if (this.open) {
            this.setVisible(true)
            debug(()=>`visible`)
        }
    }, 500, {})

    @boundMethod
    openEffect(){
        if (this.open) {
            this.makeVisible()
        } else {
            this.makeVisible.cancel()
            this.setVisible(false)
            debug(()=>`invisible`)
        }
    }
}

export function TransparentProgress({open, ...backDropProps}) {
    const cls = useStyles()

    const [visible, setVisible] = useState(false)

    const [state] = useState(() => new TransparentProgressState())

    state.render(open, visible, setVisible)
    useEffect(() => {
        state.openEffect()
    }, [open, state])
    
    //Si se quita esto, la CPU se dispara porque se sigue pintando el CircularProgress en background
    if (!open) return null

    return (
        <Backdrop className={cls.backdrop} open={open} invisible={!visible} {...backDropProps}>
            {!visible ? null : <CircularProgress color={visible ? "inherit" : undefined} />}
        </Backdrop>
    )
}