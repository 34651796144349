 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SistemaIds} from './Sistema'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const SistemaTable = new DbTable(
    Tables.Sistema,
    'Sistemas',
    graphqlUrl,
    'Sistema',
    [SistemaIds.idSistema],
    [SistemaIds.idSistema, SistemaIds.sistema],
    [],
    [SistemaIds.ejecuciones, SistemaIds.tareaList],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SistemaFld = Object.freeze({
    ejecuciones : new DbObjectList(Tables.Sistema, SistemaIds.ejecuciones, Tables.Ejecucion),
    tareaList : new DbObjectList(Tables.Sistema, SistemaIds.tareaList, Tables.Tarea),
    idSistema : new DbIntId(Tables.Sistema, SistemaIds.idSistema, 'Id sistema'),
    sistema : new DbText(Tables.Sistema, SistemaIds.sistema, 'Sistema', 30),
})

registerTableFields(Tables.Sistema, SistemaFld) 
