import React from 'react'
import {Frame} from 'sopix/formComps/Frame'
import {observer} from 'mobx-react'
import {Bar} from 'sopix/formComps/Bar'
import {NumberField} from 'sopix/form/editors/NumberField'
import {Push} from 'sopix/formComps/Push'
import {HintIconButton, ICON_BUTTON_SIZE} from 'sopix/formComps/HintIconButton'
import {BlRowCell} from 'sopix/boundless-table/BlRowCell'
import {BlHeaderCell} from 'sopix/boundless-table/BlHeaderCell'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {sopIcons} from 'sop/sop-icons'
import {UserCol} from 'sop/db/UserColumns'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {User} from 'sop/db/User'
import {DbSource} from 'sopix/data-source/dbSource'
import {UserFld, UserTable} from 'sop/db/UserTable'
import {TrabajoEstadoValue, UserRoles} from 'sop/db-config/row-consts'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {Piece} from 'sopix/piece/piece'
import {Fields} from 'sopix/formComps/Fields'
import {action, observable, runInAction} from 'mobx'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {Checkbox, Slider, Tooltip, Typography} from '@material-ui/core'
import {boundMethod} from 'autobind-decorator'
import {AproasignarForm, AproasignarFormPiece} from 'sop/componentes/trabajo/AproasignarForm'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {Aproasignar} from 'sop/db/Aproasignar'
import {makeStyles} from '@material-ui/core/styles'
import {TareaTable} from 'sop/db/TareaTable'
import {Tarea} from 'sop/db/Tarea'
import {BoolField} from 'sopix/form/editors/BoolField'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {COLUMNS_VALORACIONES, TRABAJO_LIST_FIELDS} from 'sop/componentes/trabajo/trabajo-defs'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {listFormSplitDefault} from 'sop/sop-config'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {RepartirList} from 'sop/componentes/trabajo/RepartirList'
import {TrabajoPainter} from 'sop/componentes/trabajo/trabajoPainter'
import {random, take} from 'lodash-es'
import {ALERT_CANCEL, ALERT_OK} from 'sopix/alert/alert-defs'

const BUTTONS = 'buttons'

class RepartirTecPainter extends TablePainter{
    _fixColumn(col, id, dbCol) {
        switch (id){
            case BUTTONS:
                col.maxWidth = col.minWidth = ICON_BUTTON_SIZE
                break
            case User.nombreCompleto:
                col.title = <Typography variant="subtitle2" component="span">Todos</Typography>
                break
            default:
                return super._fixColumn(col, id, dbCol) 
        }
    }
    
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds}) {
        return super._paint({id, value, row, rowId, index, dbCol, formatter, fld, flds})
    }
}


export class RepartirPartPiece extends Piece {
    
    /** @type {DbSource} */
    dbTecs

    /** @type {TablePainter} */
    tecPainter

    @observable
    count = 1

    @observable
    selected = new Set()

    @observable
    sortearResto = false

    @observable
    valsPorTec = 0
    
    @observable
    resto = 0
    
    @observable
    excluidas = 0
    
    reparto
    
    _repartirMutation = new SimpleMutation(graphqlUrl, 'RepartirValoraciones')
    
    _valoracionesQL = new TableGraphql(TareaTable)
    

    /** @param {PieceWorld} world */
    constructor(world) {
        super(world)

        const TEC_COLUMNS = [
            BUTTONS,
            UserCol.nombreCompleto
        ]

        const TEC_FIELDS = [
            UserFld.id,
            UserFld.nombreCompleto,
        ]
        
        this.dbTecs = new DbSource(world,
            new TableGraphql(UserTable),
            TEC_FIELDS, {
                defaultGraphqlFilter: {[User.roleUsuario]: UserRoles.TECNICO, [User.inactivo]: 0}
            }
        )
        
        this.dbVals = new DbSource(world,
            new TableGraphql(TareaTable),
            TRABAJO_LIST_FIELDS, {
                defaultGraphqlFilter: {
                    [Tarea.idEstado]: TrabajoEstadoValue.PLANIFICADOR,
                    [`${Tarea.ordenValoracion}Gt`]: 0,
                },
                defaultGraphqlParams: {sort: calcGraphqlOrder(Tarea.ordenValoracion)},
            }
        )
        
        this.tecPainter = new RepartirTecPainter(world, TEC_COLUMNS)
        
        this.valPainter = new TrabajoPainter(this.world, COLUMNS_VALORACIONES)

        this.aproasignarForm = new AproasignarFormPiece(world.cloneWithOwnErrors())

        this.aproasignarForm.onSave.subscribe(this._repartir)

        this.poper = new FormPoper(this.aproasignarForm)
        this.poper.acceptButton = 'Repartir'
    }

    get valCount(){
        return this.dbVals.data.length
    }
    
    get tecCount(){
        return this.selected.size
    }
    
    get repartidas(){
        return this.count * this.tecCount + this.restoRepartido 
    }
    
    get restoRepartido(){
        return this.sortearResto && this.withResto ?  this.resto : 0
    }
    
    get sobran(){
        return this.valCount - this.repartidas
    }
    
    get allSelected() {
        if (this.selected.size === this.dbTecs.data.length) {
            return true
        } else if (this.selected.size === 0) {
            return false
        } else {
            return undefined
        }
    }
    
    @action.bound
    setSortearResto(value){
        this.sortearResto = value
    }
    
    @action.bound
    selectAll() {
        for (let row of this.dbTecs.data) {
            this.selected.add(row[User.id])
        }
    }

    @action.bound
    clearSelection() {
        this.selected.clear()
    }

    @boundMethod
    async _init() {
        await this.refresh()
        this.selectAll()
        this._updateReparto()
        await super._init()
    }
    
    _updateTotals(){
        this._updateReparto()
        this.setCount(this.sliderMax)
    }
    
    _calcReparto(){
        
        if (!this.selected.size || !this.dbVals.data.length) {
            return {
                valsPorTec: 0, 
                resto: 0, 
                excluidas: 0, 
                reparto: new Map()
            }
        }
        
        const reparto = new Map()
        for (let idTec of this.selected) {
            reparto.set(idTec, [])
        }

        // Añade las asignadas
        for (let val of this.dbVals.data) {

            const idTec = val[Tarea.idResponsable]
            if (!idTec) {
                continue
            }

            const vals = reparto.get(idTec)
            if (!vals) {
                continue
            }

            vals.push(val)
        }

        // Incluye en el sorteo los que menos tienen
        const getTecs = maxVals => {
            const tecs = new Set()
            for (let [idTec, tecVals] of reparto) {
                if (tecVals.length <= maxVals) {
                    tecs.add(idTec)
                }
            }
            return tecs
        }

        let batchTecs = new Set()
        let valsPorTec = -1
        for (let val of this.dbVals.data) {

            if (val[Tarea.idResponsable]) {
                continue
            }

            while (!batchTecs.size) {
                valsPorTec++
                batchTecs = getTecs(valsPorTec)
            }

            const rnd = random(0, batchTecs.size - 1)
            const idTec = [...batchTecs.keys()][rnd]
            const vals = reparto.get(idTec)

            batchTecs.delete(idTec)
            vals.push(val)
        }

        let resto = 0
        const exact = !batchTecs.size
        if (exact) {
            valsPorTec++
            resto = 0
        } else {
            for (let vals of reparto.values()){
                if (vals.length > valsPorTec) {
                    resto++
                }
            }
        }

        const excluidas = this.dbVals.data.length - valsPorTec * reparto.size - resto
        
        return {valsPorTec, resto, excluidas, reparto}
    }
    
    @action
    _updateReparto(){
        const {valsPorTec, resto, excluidas, reparto} = this._calcReparto()
        this.valsPorTec = valsPorTec
        this.resto = resto
        this.excluidas = excluidas
        this.reparto = reparto
    }

    /**
     * @param {Map} reparto
     */
    _trimReparto(reparto, max){
        const result = new Map()
        for (let [idTec, vals] of reparto.entries()) {
            const firstVals = take(vals, max)
            if (firstVals.length) {
                result.set(idTec, firstVals)
            }
        }
        return result
    }
    
    @action.bound
    async refresh(){
        
        await Promise.all([
            this.dbVals.refresh(),
            this.dbTecs.refresh(),
        ])
        
        runInAction(()=>{
            this._updateTotals()
        })
    }

    @action.bound
    setCount(count) {
        if (count * this.tecCount > this.valCount) return
        this.count = count
    }

    @action.bound
    selectChanged(id, checked) {
        if (checked) {
            this.selected.add(id)
        } else {
            this.selected.delete(id)
        }
        this._updateTotals()
    }

    @action.bound
    selectAllChanged(checked) {
        if (checked) {
            this.selectAll()
        } else {
            this.clearSelection()
        }
        this._updateTotals()
    }
    
    get includeResto(){
        return this.withResto && this.sortearResto && this.resto > 0 
    }
    
    @boundMethod
    async repartirClicked() {
        let body = ''
        
        if (this.count) {
            body = `Se repartirán ${this.count * this.tecCount} valoraciones entre ${this.selected.size} técnicos ` +  
                `a ${this.count} val./técnico.`
            if (this.restoRepartido){
                if (this.sobran) {
                    body += (this.restoRepartido === 1 
                            ? ` Otra, se sorteará` 
                            : ` Otras ${this.restoRepartido}, se sortearán`) +
                        ` entre los ${this.selected.size} técnicos.`
                } else {
                    body += (this.restoRepartido === 1
                        ? ` La otra, se sorteará`
                        : ` Las otras ${this.restoRepartido}, se sortearán`) +
                        ` entre los ${this.selected.size} ténicos.`
                }
            }
        } else {
            body = `Se sortearán ${this.restoRepartido} valoraciones entre ${this.selected.size} técnicos.`
        }

        if (this.sobran) {
            body += this.sobran === 1 
                ? ` La última, se quedará sin repartir.`
                : ` Las ${this.sobran} restantes, se quedarán sin repartir.`
        }

        if( ALERT_OK !== await askAlert(new AlertDefinition('¿Asignar valoraciones?', body,
            [
                [ALERT_OK, 'Repartir'],
                [ALERT_CANCEL, 'Cancelar'],
            ]
        ))){
            return
        }
        
        if (this.allSelected) {
            await this._repartir()
            
        } else {
            const excluidos = []
            for (let tec of this.dbTecs.data) {
                if (!this.selected.has(tec[User.id])) {
                    excluidos.push(tec[User.nombreCompleto])
                }
            }
            this.aproasignarForm.newRowFields={
                [Aproasignar.valoracionesPorTecnico]: this.count,
                [Aproasignar.sorteadas]: this.restoRepartido,
                [Aproasignar.excluidos]: excluidos.join(', '),
            } 
            await this.poper.create()
        }
    }
    
    @boundMethod
    async _repartir(){
        
        const reparto = this._trimReparto(this.reparto, this.restoRepartido ? this.count + 1 : this.count)
        
        const tecIds = []
        const valIds = []
    
        for(let [idTec, vals] of reparto) {
            tecIds.push(idTec)
            valIds.push(vals.map(v => v[Tarea.idTarea]))
        }
        
        await this._repartirMutation.query({tecIds, valIds})
        await this.refresh()
    }
    
    
    get sliderMin(){
        return 0
    }
    
    get sliderMax(){
        return this.valsPorTec
    }
    
    get sliderMarks(){
        const marks = []
        const inc = 1 + Math.floor(this.sliderMax / 10)
        for (let i = 0; i <= this.sliderMax; i+=inc)
            marks.push({value: i, label: i})
        return marks
    }
    
    get withResto(){
        return this.resto > 0 && this.sliderMax === this.count
    }
}









export const HeaderCell = observer(
    /**
     * @param {RepartirPartPiece} form
     */
    ({global: form, column, children, ...props}) => {
        return (
            <BlHeaderCell column={column} {...props} >
                {(()=>{
                    const allSelected = form.allSelected
                    switch(column.id){
                        case BUTTONS: 
                            return <Checkbox checked={!!allSelected} indeterminate={allSelected === undefined}
                                             onChange={event => form.selectAllChanged(event.target.checked)} />
                        default:
                            return children
                    }
                })()}
            </BlHeaderCell>
        )
    })



export const Cell = observer(

    /**
     * @param {RepartirPartPiece} form
     */
    ({global: form, column, data, children, ...props}) => {
        const id = data[User.id]
        return (
            <BlRowCell column={column} {...props} >
                {(()=>{
                    switch(column.id){
                        case BUTTONS:
                            return <Checkbox checked={!!form.selected.has(id)} 
                                             onChange={event => form.selectChanged(id, event.target.checked)} />
                        default:
                            return children
                    }
                })()}
            </BlRowCell>
        )
})


const useStyles = makeStyles(() => ({
    asignarForm: {
    },
    slider: {
        flex: 1,
        marginRight: '30px !important',
    },
    restoInvisible: {
        visibility: 'hidden',
    }
})) 



export const RepartirPart = observer(
    /**
     * @param {RepartirPartPiece} part
     */
    ({part}) => {
        
        const cls = useStyles()
        usePiece(part)

        return <PieceFrame piece={part} renderPiece={()=> {
            
            const withResto = part.withResto
            
            const asa = part._asyncAction
            
            return (
                <VerticalSplit id="RepartirVal" defaultSizes={listFormSplitDefault}>
                    <RepartirList part={part} />
                    <Frame>
                        <Bar startGap>
                            <Fields noWrap >
                                <NumberField disabled width={15} value={part.valCount} label="Vals." />
                                <NumberField disabled width={15} value={part.tecCount} label="Técs." />
                                <Tooltip title="Valoraciones / técnico">
                                    <Slider className={cls.slider} min={part.sliderMin} max={part.sliderMax || 1} 
                                            value={part.count} marks={part.sliderMarks}
                                            onChange={(_,value) => part.setCount(value)}
                                            disabled={!part.sliderMax}
                                    />
                                </Tooltip>
                                <BoolField className={!withResto && cls.restoInvisible} value={part.sortearResto}
                                           label={`Sortear resto (${part.resto})`} onChange={part.setSortearResto}/>
                                <NumberField disabled label="Repart." width={15} value={part.repartidas} />
                                <NumberField disabled label="Sobran" width={15} value={part.sobran}/>
                            </Fields>
                            <Push/>
                            <HintIconButton
                                Icon={sopIcons.Valoracion}
                                title="Repartir valoraciones"
                                onClick={asa(part.repartirClicked)}
                                disabled={!part.count && !part.includeResto}
                            />
                        </Bar>
                        <Frame>
                            <BlTable fitContainer dense
                                     global={part}
                                     RowCell={Cell}
                                     HeaderCell={HeaderCell}
                                     verticalAlign="center"
                                     keyColumnId={User.id}
                                     columns={part.tecPainter.boundlessColumns}
                                     renderCellContent={part.tecPainter.__boundlessPainter}
                                     data={part.dbTecs.data}
                            />
                        </Frame>
                        <PoperFrame poper={part.poper} >
                            <AproasignarForm form={part.aproasignarForm} />
                        </PoperFrame>
                    </Frame>
                </VerticalSplit>
            )
        }} />
})
