import {AppPageInfo} from 'sopix/app-frame/appPageInfo'
import SettingsIcon from '@material-ui/icons/Settings'
import {sopIcons} from 'sop/sop-icons'
import {Permiso} from 'sop/db/Permiso'
import {aprobacionCheck} from 'sop/componentes/aprobacion/aprobacionCheck'
import {avisosCheck} from 'sop/componentes/aviso/avisosCheck'
import {userAuth} from 'sopix/session/userAuth'
import {icons} from 'sopix/icon/icons'

export let pageIndex

export const sopUrls = Object.freeze({
    dashboard: '/',
    trabajos: '/trabajos',
    trabajo: '/trabajos/{:idTarea}',
    obras: '/obras',
    obra: '/obras/:idObra',
    liquidaciones: '/liquidaciones',
    liquidacion: '/liquidaciones/:idLiquidacion',
    avisos: '/avisos',
    aviso: '/avisos/:idAviso',
    aprobaciones: '/aprobaciones',
    aprobacion: '/aprobaciones/:idAprobacion',
    cuentas: '/cuentas',
    cuenta: '/cuentas/:idCuenta',
    stats: '/estadisticas',
    usuarios: '/usuarios',
    usuario: '/usuarios/:id',
    configuracion: '/config',
    yo: '/yo',
    sesion: '/sesion',
})

export const sopPages = Object.freeze({
    dashboard: new AppPageInfo('dashboard', sopUrls.dashboard, 'Home', icons.Home, [Permiso.verListas]),
    
    trabajos: new AppPageInfo('trabajos', [sopUrls.trabajos, sopUrls.trabajo], 'Trabajos', 
        sopIcons.Trabajo, [Permiso.paginaTrabajos]),
    
    obras: new AppPageInfo('obras', [sopUrls.obras, sopUrls.obra], 'Obras', 
        sopIcons.Obra, [Permiso.verObras]),
    
    liquidaciones: new AppPageInfo('liquidaciones', [sopUrls.liquidaciones, sopUrls.liquidacion], 'Liquidaciones',
        sopIcons.Liquidacion, [Permiso.verLiquidaciones]),
    
    avisos: new AppPageInfo('avisos', [sopUrls.avisos, sopUrls.aviso], 'Avisos',
        sopIcons.Aviso, [Permiso.verAvisos],
        {getBadgeProps: avisosCheck.getBadgeProps}),

    aprobaciones: new AppPageInfo('aprobaciones', [sopUrls.aprobaciones, sopUrls.aprobacion], 'Aprobaciones',
        sopIcons.Aprobacion, [Permiso.verAprobaciones], 
        {getBadgeProps: aprobacionCheck.getBadgeProps}),

    cuentas: new AppPageInfo('cuentas', [sopUrls.cuentas, sopUrls.cuenta], 'Cuentas', 
        sopIcons.Cuenta,[Permiso.paginaCuentas]),

    stats: new AppPageInfo('stats', [sopUrls.stats], 'Estadísticas',
        sopIcons.Estadisticas, [Permiso.verLiquidaciones]),
    
    usuarios: new AppPageInfo('usuarios', [sopUrls.usuarios, sopUrls.usuario], 'Usuarios', 
        sopIcons.Usuario, [Permiso.paginaUsuarios]),
    
    configuracion: new AppPageInfo('configuracion', sopUrls.configuracion, 'Configuración', 
        SettingsIcon, [Permiso.paginaConfiguracion]),
    
    yo: new AppPageInfo('yo', [sopUrls.yo], () => userAuth.username,
        sopIcons.Yo, [Permiso.verUsuariosMinimo]),

    sesion: new AppPageInfo('sesion', sopUrls.sesion, 'Sesión', 
        sopIcons.Sesion, [Permiso.verListas]),
})


pageIndex = [
    sopPages.trabajos,
    sopPages.avisos,
    sopPages.aprobaciones,
    sopPages.obras,
    sopPages.liquidaciones,
    sopPages.stats,
    sopPages.cuentas,
    sopPages.usuarios,
    sopPages.configuracion,
    null,
    sopPages.yo,
    sopPages.sesion,
]
