import {observer} from 'mobx-react'
import {useReactState} from 'sopix/react-state/use-react-state'
import {ConfigPageState} from 'sop/componentes/config-S/configPageState'
import {errorStore} from 'sopix/errors/ErrorStore'
import React, {useState} from 'react'
import {LocationLocker} from 'sopix/app-frame/LockedLocation'
import {Frame} from 'sopix/formComps/Frame'
import {FormToolbar} from 'sopix/formComps/FormToolbar'
import {ConfigLiquidacion} from 'sop/componentes/config-S/ConfigLiquidacion'
import {WithComplexProgress} from 'sopix/progress/WithComplexProgress'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {FormFieldHelper} from 'sopix/form/formFieldHelper'
import {Field} from 'sopix/form/editors/Field'
import {ContadorEdt} from 'sop/db/ContadorEditors'
import {NumberField} from 'sopix/form/editors/NumberField'
import {ConfiguracionEdt} from 'sop/db/ConfiguracionEditors'
import {ConfigProcedencias} from 'sop/componentes/config-S/ConfigProcedencias'
import {Fields} from 'sopix/formComps/Fields'
import {Title} from 'sopix/formComps/Title'
import {Section} from 'sopix/formComps/Section'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopColors} from 'sop/sop-colors'
import {Push} from 'sopix/formComps/Push'

export const ConfigPage = observer
(() => {
    
    /** @type {ConfigPageState} */
    const configPage = useReactState(() => new ConfigPageState({errorManager: errorStore}))

    const [fld] = useState(() => new FormFieldHelper(configPage.fieldManager))
    
    return (
        <>
            <LocationLocker isDirty={configPage.dirty}>
                <WithComplexProgress
                    initialized={configPage.initialized}
                    locked={configPage.locked}
                    onReplay={() => configPage.initialize()}
                >
                    <VerticalSplit id="Config" defaultSizes={[40,20,20]}>
                        <Frame paper margin2>
                            <Title margin Icon={configPage.liquidaciones.icon}>{configPage.liquidaciones.name}</Title>
                            <Fields>
                                <NumberField {...fld.edt(ConfiguracionEdt.ratioRepeticion)} suffix=" %" />
                                <NumberField {...fld.edt(ConfiguracionEdt.ratioSimetria)} suffix=" %" />
                            </Fields>
                            <Section margin>
                                <ConfigLiquidacion efectivos={configPage.liquidaciones}/>
                            </Section>
                            
                            <FormToolbar formPage={configPage} absolute/>
                        </Frame>
                        <Frame paper margin2>
                            <Section row>
                                <Title Icon={configPage.procedencias.icon}>{configPage.procedencias.name}</Title>
                                <Push />
                                <HintIconButton
                                    Icon={configPage.procedencias.icon}
                                    badge='+'
                                    badgeColor={sopColors.badgeAdd}
                                    title={'Añadir procedencia'}
                                    onClick={configPage.procedencias.addNew}
                                />
                            </Section>
                            <Section margin>
                                <ConfigProcedencias procedencias={configPage.procedencias}/>
                            </Section>
                        </Frame>
                        <Frame paper margin2>
                            <Title margin >Contador</Title>
                            <Fields>
                                <Field {...fld.edt(ContadorEdt.letra)} />
                                <NumberField {...fld.edt(ContadorEdt.numero)} />
                            </Fields>
                            <Title margin >Planificador</Title>
                            <Fields>
                                <NumberField {...fld.edt(ConfiguracionEdt.asumirEstudioPropio)} suffix="%" />
                            </Fields>
                        </Frame>
                    </VerticalSplit>
                </WithComplexProgress>
            </LocationLocker>
        </>
    )
})