export function extractFileName(path){
    const parts = path.split('/')
    return !parts.length ? undefined : parts[parts.length - 1]
}


export function extractFileExt(path){
    const name = extractFileName(path)
    const parts = name.split('.')
    if (parts.length >= 2) {
        return parts[parts.length - 1]
    } else {
        return ''
    }
}

export function extractFileBase(path){
    const ext = extractFileExt(path)
    if (!ext.length) return path
    
    return path.substr(0, path.length - ext.length - 1)
}

export function getUniqueFileName(name, list){
    
    if (list.indexOf(name) < 0) {
        return name
    }
 
    const base = extractFileBase(name)
    const ext = extractFileExt(name)
    let index = 2
    while(true){
        const testName = `${base} (${index}).${ext}`
        if (list.indexOf(testName) < 0) {
            return testName
        }
        index ++
    }
    
}