import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {cimStyles} from 'sopix/theme/cimStyles'

const useStyles = makeStyles(theme => ({
    frame: {
        alignContent: 'start',
        alignItems: 'start',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',

        '&:first-child': {
            paddingTop: 0,
        }
    },
    margin: {
        padding: '24px 0 16px 0',
    },
    row: cimStyles.row,
    noWrap: cimStyles.noWrap,
    bottom: cimStyles.itemsBottom,
    center: cimStyles.itemsCenter,
})) 

export function Section({row, noWrap, bottom, center, margin, children}) {
    
    const cls = useStyles()
    
    return (
        <div className={clsx(
            cls.frame,
            margin && cls.margin,
            noWrap && cls.noWrap,
            row && cls.row,
            bottom && cls.bottom,
            center && cls.center,
        )}>
            {children}            
        </div>
    )
}
