import React from 'react'
import {ModalContent} from 'sopix/modal/ModalContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'
import {LoginScreen} from 'sop/login/loginState'
import Typography from '@material-ui/core/Typography'

export const AccessRestrictedForm = observer(
/**
 * 
 * @param {LoginState} login
 * @param children
 * @returns {JSX.Element}
 */
({login, children}) => {
        
    return (
        <>
            <ModalContent><Typography>
                No estás autorizado a acceder a esta página. Inicia sesión como otro usuario o cambia de página.
            </Typography></ModalContent>
            <DialogActions>
                <Button
                    onClick={() => login.setScreen(LoginScreen.LOGIN)}
                    color="primary"
                    defaultChecked={true}
                >
                    Iniciar sesión
                </Button>
            </DialogActions>
        </>
    )
})
