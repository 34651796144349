 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {CuentaIds} from './Cuenta'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const CuentaCol = Object.freeze({
    tipo : new LookupColumn(CuentaIds.tipo, Tables.CuentaTipo, 'tipo'),
    autor : new LookupColumn(CuentaIds.autor, Tables.User, 'nombre_completo'),
    modificacion : new LookupColumn(CuentaIds.modificacion, Tables.User, 'nombre_completo'),
    almacen : new LookupColumn(CuentaIds.almacen, Tables.Almacen, 'almacen'),
    comercial : new LookupColumn(CuentaIds.comercial, Tables.User, 'nombre_completo'),
    idCuenta : new SimpleColumn(CuentaIds.idCuenta, 11, 11, Align.RIGHT),
    cifdni : new SimpleColumn(CuentaIds.cifdni, 15, 10, Align.LEFT),
    cuenta : new SimpleColumn(CuentaIds.cuenta, 40, 15, Align.LEFT),
    telefono1 : new SimpleColumn(CuentaIds.telefono1, 40, 16, Align.LEFT),
    comentarios : new SimpleColumn(CuentaIds.comentarios, 40, 16, Align.LEFT),
    fechaAlta : new DateTimeColumn(CuentaIds.fechaAlta, 16, 16, Align.LEFT),
    fechaAltaOnlyDate : new DateColumn(CuentaIds.fechaAlta, 11, 11, Align.LEFT),
    fechaModificacion : new DateTimeColumn(CuentaIds.fechaModificacion, 16, 16, Align.LEFT),
    fechaModificacionOnlyDate : new DateColumn(CuentaIds.fechaModificacion, 11, 11, Align.LEFT),
    odooCambiado : new SimpleColumn(CuentaIds.odooCambiado, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Cuenta] = CuentaCol
