import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {TareaEdt} from 'sop/db/TareaEditors'
import {getPieceContainer, PieceFrame} from 'sopix/piece/PieceFrame'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {usePiece} from 'sopix/piece/use-piece'
import {boundMethod} from 'autobind-decorator'
import {observable, runInAction} from 'mobx'
import {
    getUsuariosListQueryEntry,
    provinciaQuery,
    tareaEstadoQuery,
    tareaTipoQuery,
} from 'sop/db-config/sop-query-lists'
import {observer} from 'mobx-react'
import {TipoTareaTable} from 'sop/db/TipoTareaTable'
import {filter} from 'lodash-es'
import {TipoTarea} from 'sop/db/TipoTarea'
import {RolesUsuario, TrabajoClase} from 'sop/db-config/row-consts'
import {UserTable} from 'sop/db/UserTable'
import {User} from 'sop/db/User'
import {Typography} from '@material-ui/core'
import {TareaEstado} from 'sop/db/TareaEstado'
import {TareaEstadoTable} from 'sop/db/TareaEstadoTable'
import {TrabajoListMode} from 'sop/componentes/trabajo/trabajo-defs'
import {makeStyles} from '@material-ui/core/styles'
import {sopColors} from 'sop/sop-colors'
import clsx from 'clsx'
import {Fields} from 'sopix/formComps/Fields'
import {FrameFlag} from 'sopix/piece/piece'
import {BoolField} from 'sopix/form/editors/BoolField'
import {Push} from 'sopix/formComps/Push'
import {TrabajoFilter} from 'sop/componentes/trabajo/TrabajoFilter'
import {CuentaEdt} from 'sop/db/CuentaEditors'
import {userAuth} from 'sopix/session/userAuth'
import {cimStyles} from 'sopix/theme/cimStyles'
import {getDropdownBoldLineRender} from 'sop/utils/edit-field-utils'
import {Permiso} from 'sop/db/Permiso'
import {Provincia} from 'sop/db/Provincia'
import {ProvinciaTable} from 'sop/db/ProvinciaTable'

const COMERCIAL_LIST = 'comercial'

export class TrabajoSearchPiece extends SearchPiece{
    _listFetcher = new ListFetcher([
        TareaEdt.responsable.getListQueryEntry(),
        getUsuariosListQueryEntry(RolesUsuario.COMERCIAL, {listKey: COMERCIAL_LIST}),
        CuentaEdt.tipo.getListQueryEntry(),
        tareaEstadoQuery,
        tareaTipoQuery,
        provinciaQuery,
    ])

    /** @type {Object<ListData>} */
    lists
    
    @observable
    _mode

    constructor(world, mode, {defaultFields} = {}) {
        super(world, {defaultFields})
        
        runInAction(()=>{
            this.setMode(mode)
        })
    }

    _afterConstructor() {
        this.frameFlags = new Set([FrameFlag.ROW])
    }

    get mode(){
        return this._mode
    }

    setMode(mode){
        this._mode = mode
        this.setField(TrabajoFilter.MODE, this._mode, {notifications: false})
    }
    
    @boundMethod
    async _init() {
        const lists = await this._listFetcher.fetch()
        runInAction(()=>{
            this.lists = lists
        })
        await super._init()
    }
}


function renderTipoLine(row){
    const id = row[TipoTarea.idTipo]
    const tipo = row[TipoTarea.tipo]
    if (id < 0) {
        return <Typography variant="h6">{tipo}</Typography>
    } else {
        return tipo
    }
}

function renderTipo(search, name){
    const fld = search.fieldHelper

    const tipos = search.lists[TipoTareaTable.graphqlId].rows

    const tipoList = [
        {
            [TipoTarea.idTipo]: TrabajoFilter.TIPO_TAREAS,
            [TipoTarea.tipo]: 'Tareas',
        },{
            [TipoTarea.idTipo]: TrabajoFilter.TIPO_COTIZACIONES,
            [TipoTarea.tipo]: 'Cotizaciones',
        },{
            [TipoTarea.idTipo]: TrabajoFilter.TIPO_ESTUDIOS,
            [TipoTarea.tipo]: 'Estudios',
        }, ...filter(tipos, tipo => tipo[TipoTarea.clase] === TrabajoClase.ESTUDIO), {
            [TipoTarea.idTipo]: TrabajoFilter.TIPO_VALORACIONES,
            [TipoTarea.tipo]: 'Valoraciones',
        }, ...filter(tipos, tipo => tipo[TipoTarea.clase] === TrabajoClase.VALORACION)
    ]

    return <DropdownField {...fld.edt(TareaEdt.tipo)} options={tipoList}
                          customRender={renderTipoLine} name={name} />
}



/**
 * @param {TrabajoSearchPiece} search
 */
const TrabaSearch = ({search}) => {
    const fld = search.fieldHelper
    
    return (
        <>
            {renderResponsable(search, TrabajoFilter.RESPONSABLE_TRABAJOS)}
            {renderEstado(search, TrabajoFilter.ESTADO_TRABAJOS)}
            {renderTipo(search, TrabajoFilter.TIPO_TRABA)}
        </>
    )
}

/**
 * @param {TrabajoSearchPiece} search
 */
function ValoSearch({search}){
    const fld = search.fieldHelper

    const tipoList = search.lists[TipoTareaTable.graphqlId]
    
    const valoList = filter(tipoList.rows, tipo => tipo[TipoTarea.clase] === TrabajoClase.VALORACION)

    return (
        <>
            {renderResponsable(search, TrabajoFilter.RESPONSABLE_COTIZACIONES)}
            {renderEstado(search, TrabajoFilter.ESTADO_COTIZACIONES)}
            <DropdownField {...fld.edt(TareaEdt.tipo)} label="Tipo valoración" name={TrabajoFilter.TIPO_VALO}
                           options={valoList} />
       </>
    )
}

/**
 * @param {TrabajoSearchPiece} search
 */
function EstuSearch({search}){
    const fld = search.fieldHelper

    const tipoList = search.lists[TipoTareaTable.graphqlId]

    const valoList = filter(tipoList.rows, tipo => tipo[TipoTarea.clase] === TrabajoClase.ESTUDIO)

    return (
        <>
            {renderResponsable(search, TrabajoFilter.RESPONSABLE_COTIZACIONES)}
            {renderEstado(search, TrabajoFilter.ESTADO_COTIZACIONES)}
            <DropdownField {...fld.edt(TareaEdt.tipo)} label="Tipo estudio" name={TrabajoFilter.TIPO_ESTU}
                           options={valoList} />
       </>
    )
}



const comps = new Map([
    [TrabajoListMode.VALORACIONES, ValoSearch],
    [TrabajoListMode.ESTUDIOS, EstuSearch],
    [TrabajoListMode.TRABAJOS, TrabaSearch],
])


function renderResponsableLine(row){
    const id = row[User.id]
    const nombreCompleto = row[User.nombreCompleto]
    if (id < 0) {
        return <Typography variant="h6">{nombreCompleto}</Typography>
    } else {
        return nombreCompleto
    }
}

function renderEstadoLine(row){
    const id = row[TareaEstado.idEstado]
    const estado = row[TareaEstado.estado]
    if (id < 0) {
        return <Typography variant="h6">{estado}</Typography>
    } else {
        return estado
    }
}


function renderEstado(search, name){
    const fld = search.fieldHelper

    const estados = search.lists[TareaEstadoTable.graphqlId].rows
    
    const estadoList = [
        {
            [TareaEstado.idEstado]: TrabajoFilter.ESTADO_PLANIFICADOR,
            [TareaEstado.estado]: 'Planificador',
        },{
            [TareaEstado.idEstado]: TrabajoFilter.ESTADO_ACTIVOS,
            [TareaEstado.estado]: 'Activos',
        }, ...filter(estados, estado => !estado[TareaEstado.finalizado]), {
            [TareaEstado.idEstado]: TrabajoFilter.ESTADO_FINALIZADOS,
            [TareaEstado.estado]: 'Finalizados',
        }, ...filter(estados, estado => estado[TareaEstado.finalizado])
    ]
        
    return (
        <DropdownField {...fld.edt(TareaEdt.estado)} options={estadoList} disabled={false}
               customRender={renderEstadoLine} name={name} />
    )
}

function renderResponsable(search, name){
    const fld = search.fieldHelper
    
    const responsableList = [{
        [User.id]: TrabajoFilter.YO,
        [User.nombreCompleto]: 'Yo',
    }, {
        [User.id]: TrabajoFilter.ASIGNADO,
        [User.nombreCompleto]: 'Asignado',
    }, {
        [User.id]: TrabajoFilter.SIN_ASIGNAR,
        [User.nombreCompleto]: 'Sin asignar',
    }, ...search.lists[UserTable.graphqlId].rows]

    return <DropdownField {...fld.edt(TareaEdt.responsable)} options={responsableList}
                      customRender={renderResponsableLine} name={name} />
}




const useStyles = makeStyles(() => ({
    trabajos: {
        flexShrink: 0.3,
        //background: sopColors.greenBackLightground,
    },
    estudios: {
        flexShrink: 0.3,
        background: sopColors.blueLightBackground,
    },
    valoraciones: {
        flexShrink: 0.3,
        background: sopColors.blueLightBackground,
    },
}))





export const TrabajoSearch = observer(
    /**
     * 
     * @param {TrabajoSearchPiece} search
     */
    ({search, extra}) => {
    
        usePiece(search)
        const cls = useStyles()

        return (
            <PieceFrame piece={search} renderPiece={()=> {

                const fld = search.fieldHelper
                const Search = comps.get(search.mode)

                return (
                    <Fields className={clsx(
                        search.mode === TrabajoListMode.VALORACIONES && cls.valoraciones,
                        search.mode === TrabajoListMode.ESTUDIOS && cls.estudios,
                        search.mode === TrabajoListMode.TRABAJOS && cls.trabajos,
                    )}>
                        <Search search={search} />
                        <Push />
                        {!extra ? null : extra}
                    </Fields>
                )
    
            }} />
        )
})


const planosComercialRender = getDropdownBoldLineRender(User.id, User.nombreCompleto)

export const TrabajoSearchCommon = getPieceContainer('search', observer(
    /**
     * @param {TrabajoSearchPiece} search
     */
    ({search, extra}) => {
        const H = search.fieldHelper
        const fld = search.getField
        const man = search.fieldManager
        
        const comercialOptions = [
            ...(userAuth.role !== RolesUsuario.COMERCIAL ? []:
                [{[User.id]: TrabajoFilter.PLANOS_COMERCIAL_YO, [User.nombreCompleto]: 'Yo',}]),
            ...search.lists[COMERCIAL_LIST].rows,
        ]
        
        const provinciaOptions = search.lists[ProvinciaTable.graphqlId].rows
        
        const planosStyle = fld(TrabajoFilter.PLANOS) ? undefined : cimStyles.invisible 
        
        return (
            <Fields>
                <Field manager={search.fieldManager} name={TrabajoFilter.BUSCAR} label="buscar"/>
                {!userAuth.isAllowed(Permiso.aprobarEnvioPlanos) ? null : (
                    <>
                        <BoolField manager={search.fieldManager} name={TrabajoFilter.PLANOS}
                                   label="Seguimiento de planos"/>
                        <DropdownField {...H.edt(CuentaEdt.tipo)} name={TrabajoFilter.PLANOS_TIPO_CUENTA} disabled={false}
                                       style={planosStyle} label="Tipo de cuenta" />
                        <DropdownField {...H.edt(CuentaEdt.comercial)} name={TrabajoFilter.PLANOS_COMERCIAL} disabled={false}
                                       options={comercialOptions} style={planosStyle} label="Comercial de la cuenta"
                                       customRender={planosComercialRender}
                        />
                        <DropdownField manager={man} name={TrabajoFilter.PLANOS_PROVINCIA} options={provinciaOptions}
                                       idField={Provincia.cp} displayField={Provincia.provincia}
                                       style={planosStyle} label="Provincia de la cuenta" />
                    </>    
                )} 
               <Push/>
                {!extra ? null : extra}
            </Fields>
        )
}))
