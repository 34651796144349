import React from 'react'
import {doNothing} from 'sopix/utils/misc'
import {FieldController} from 'sopix/form/FieldController'
import {FormControlLabel, FormHelperText, Switch} from '@material-ui/core'
import {BoolCast} from 'sopix/form/form-types'
import {useFieldStyle} from 'sopix/form/field-style'
import clsx from 'clsx'

export function BoolField({className, manager, name, label, value, dataType, width, nullValue, onChange, 
          disabled: disabledBase, nullify, ...otherProps}) {
    const disabled = disabledBase || (manager && manager.readOnly)

    const fieldCls = useFieldStyle()
    
    if (nullify) {
        return null
    }
    
    const cast = new BoolCast(dataType, nullValue)

    function getOnChange(controllerOnChange){
        return function(e) {
            const value = cast.fromBool(e.target.checked)
            controllerOnChange && controllerOnChange(value)
        }
    }
    
    function render({onChange: controllerOnChange, onBlur, value, error}) {
        return (
            <div className={clsx(
                fieldCls.switchContainer,
                className,
            )}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={cast.toBool(value)}
                            onChange={getOnChange(controllerOnChange)}
                            name={name}
                            inputProps={{onBlur: onBlur}}
                            disabled={disabled}
                            {...otherProps}
                        />
                    }
                    label={label}
                />
                <FormHelperText error={error !== undefined}>{error}</FormHelperText>
            </div>
        )
    }
    
    if (manager === undefined) {
        return render({value: value, onBlur: doNothing, onChange: onChange})

    } else {
        return (
            <FieldController
                fieldManager={manager}
                name={name}
                render={render}
            />
        )
    }
}
