import React, {forwardRef} from 'react'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {UserTable} from 'sop/db/UserTable'
import {usuarioFilter} from 'sop/componentes/usuario/usuarioFilter'
import {UserCol} from 'sop/db/UserColumns'
import {User} from 'sop/db/User'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {icons} from 'sopix/icon/icons'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {TablePiece} from 'sopix/pieces/tablePiece'
import {graphqlFiltratorBuilder} from 'sopix/piece-helpers/graphql-filtrator-builder'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {makeStyles} from '@material-ui/core/styles'
import {cimStyles} from 'sopix/theme/cimStyles'
import clsx from 'clsx'
import {sopColors} from 'sop/sop-colors'
import {USER_FIELDS} from 'sop/db-config/fieldSets'


class UsuarioPainter extends TablePainter{
    _paint({id, value, row, rowId, index, dbCol, formatter, fld}) {
        switch(id){
            case User.resetKey:
                return !value ? '' : <HintIcon Icon={icons.Check} />
            default:
                return formatter(value)
        }
    }
    
    _fixColumn(col, id, dbCol) {
        switch(id){
            case User.fechaCreacion:
                col.title = 'Fecha'
                break
            case User.resetKey:
                col.title = 'Reinicio'
                col.width = col.minWidth = 50
                break
            default:
        }
    }
}


export class UsuarioListPiece extends TablePiece{

    constructor(world) {

        const TABLE = UserTable
        const ID_FIELD = User.id
        const DEFAULT_FILTER = {}
        const DEFAULT_ORDER = [new OrderEntry(User.username, OrderDirection.DESC)]

        const COLUMNS = [
            UserCol.username,
            UserCol.nombreCompleto,
            UserCol.roleUsuarioObj,
            UserCol.movil,
            UserCol.resetKey,
        ]
        
        const painter = new UsuarioPainter(world, COLUMNS)

        const filtrator = graphqlFiltratorBuilder(world, TABLE, USER_FIELDS, {
            graphqlFilterCalculator: usuarioFilter, defaultFilter: DEFAULT_FILTER, defaultOrder: DEFAULT_ORDER})

        const selection = new RowSelection(world, filtrator, ID_FIELD)

        super(world, ID_FIELD, filtrator, painter, selection)
    }

}



const useStyles = makeStyles(() => ({
    inactivo: cimStyles.dimmed,
    ausente: {
        background: sopColors.redBackground,    
    },
}))



export const UsuarioRow = forwardRef(({rowId, data, selected, ...props}, ref) => {
    const cls = useStyles()

    return <BlRow ref={ref} className={clsx(
        !selected && data[User.inactivo] && cls.inactivo,
        !selected && data[User.ausente] && cls.ausente,
    )} rowId={rowId} data={data} selected={selected} {...props} />
})
