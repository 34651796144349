 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {LiquidacionIds} from './Liquidacion'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbFloat, DbIntId, DbObject, DbObjectId, DbText} from 'sopix/db/dbField'
import {UserIds} from './User'
import {TareaIds} from './Tarea'

export const LiquidacionTable = new DbTable(
    Tables.Liquidacion,
    'Liquidaciones',
    graphqlUrl,
    'Liquidacion',
    [LiquidacionIds.idLiquidacion],
    [LiquidacionIds.idLiquidacion, LiquidacionIds.fechaCreacion, LiquidacionIds.idAutor, LiquidacionIds.fecha, LiquidacionIds.m2Efectivos, LiquidacionIds.idResponsable, LiquidacionIds.idTarea, LiquidacionIds.descripcion],
    [LiquidacionIds.autor, LiquidacionIds.responsable, LiquidacionIds.tarea],
    [],
    ['verLiquidaciones'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const LiquidacionFld = Object.freeze({
    autor : new DbObject(Tables.Liquidacion, LiquidacionIds.autor, LiquidacionIds.idAutor, 'Autor'),
    responsable : new DbObject(Tables.Liquidacion, LiquidacionIds.responsable, LiquidacionIds.idResponsable, 'Responsable'),
    tarea : new DbObject(Tables.Liquidacion, LiquidacionIds.tarea, LiquidacionIds.idTarea, 'Tarea'),
    idLiquidacion : new DbIntId(Tables.Liquidacion, LiquidacionIds.idLiquidacion, 'Id liquidacion'),
    fechaCreacion : new DbDateTime(Tables.Liquidacion, LiquidacionIds.fechaCreacion, 'Fecha creacion'),
    idAutor : new DbObjectId(Tables.Liquidacion, LiquidacionIds.idAutor, 'Id autor', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    fecha : new DbDateTime(Tables.Liquidacion, LiquidacionIds.fecha, 'Fecha'),
    m2Efectivos : new DbFloat(Tables.Liquidacion, LiquidacionIds.m2Efectivos, 'M² efectivos'),
    idResponsable : new DbObjectId(Tables.Liquidacion, LiquidacionIds.idResponsable, 'Id responsable', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idTarea : new DbObjectId(Tables.Liquidacion, LiquidacionIds.idTarea, 'Id tarea', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    descripcion : new DbText(Tables.Liquidacion, LiquidacionIds.descripcion, 'Descripcion', 1000),
})

registerTableFields(Tables.Liquidacion, LiquidacionFld) 
