import React from 'react'

// Esta tiene que ir antes para estar disponible

import CssBaseline from '@material-ui/core/CssBaseline'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import fnsEs from 'date-fns/locale/es'
import {configure as mobxConfigure} from 'mobx'

// unstable_createMuiStrictModeTheme es una pre-release de material-ui v5 que sale en 2020 3T
// y que soluciona warnings "findDOMNode is deprecated in StrictMode."
import {makeStyles, ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles'
import {SopRouter} from 'sop/SopRouter'
import {apiEndPointOnFinalize, apiEndPointOnStart} from 'sopix/db/endPointMiddleware'
import {taskStore} from 'sopix/tasks/taskStore'
import {
    graphqlCanIncludeField,
    graphqlCanIncludeTable,
    graphqlConstructorOptions,
    graphqlOnError,
} from 'sopix/db/graphqlMiddleware'
import {canReadField, canReadTable} from 'sopix/db-auth/db-auth-utils'
import {FORBIDDEN_ROW_CONFIG, LOGGED_OUT} from 'sopix/db/graphql-defs'
import {userAuth} from 'sopix/session/userAuth'
import {errorStore} from 'sopix/errors/ErrorStore'
import {setObserverHubErrorMiddleware} from 'sopix/utils/observers'
import {registerDatabase} from "sop/db/Database"
import {sopTheme} from "sop/sop-theme"

mobxConfigure({
    enforceActions: 'always',
})

registerDatabase()


//Middlewares

apiEndPointOnStart.subscribe(id => {
    taskStore.taskStarted(['ApiEndPoint'], {id: id})
})

apiEndPointOnFinalize.subscribe(taskStore.taskStopped)

graphqlCanIncludeField.subscribe('userAuth', field => {
    const can = canReadField(field)
    if (can === false) {
        return false
    }
})

graphqlCanIncludeTable.subscribe('userAuth', table => {
    const can = canReadTable(table)
    if (can === false) {
        return false
    }
})

graphqlConstructorOptions.subscribe('userAuth', graphqlEndPoint => {
    return {excludedErrors: [FORBIDDEN_ROW_CONFIG]}
})


graphqlOnError.subscribe(message => {
    if (message.includes(LOGGED_OUT)) {
        userAuth.logout()
    }
})

setObserverHubErrorMiddleware(error => {
    errorStore.add(error)
})


const useStyles = makeStyles(() => ({
    app: {
        minWidth: 1200,
        minHeight: 700,
        overflow: 'hidden',
        position: 'absolute',
        width: '100%',
        height: '100%',
    }
}))

function App() {
    const cls = useStyles()
    return (
        <>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fnsEs}>
                <ThemeProvider theme={sopTheme}>
                    <div className={cls.app}>
                        <SopRouter />
                    </div>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </>
    )
}

export default App
