let refreshFunc

export function setRefreshTrabajosPage(func){
   refreshFunc = func 
}


export function refreshTrabajosPage(){
    if (!refreshFunc) return
    refreshFunc()
}