import React from 'react'
import {observer} from 'mobx-react'
import {action, observable} from 'mobx'
import {CustomAlert} from 'sopix/alert/CustomAlert'


export class AlertStore{
    
    @observable.shallow
    /** @type {[AlertDefinition, function][]} */
    alerts = []
    
    add(alert, onComplete){
        return new Promise(action((resolve, reject) => {
            this.alerts.push([alert, action(result => {
                this.alerts.shift()
                resolve(result)
            })])
        }))
    }
    
}

export const alertStore = new AlertStore()

/**
 * @param {AlertDefinition} alert
 */
export async function askAlert(alert){
    return alertStore.add(alert)
}

export const AlertPlaceholder = observer(
    /**
     * @param {AlertStore} alerts
     */
    ({alerts}) => {
        
        if (!alerts.alerts.length) {
            return null
        }
        
        const [alert, resolve] = alerts.alerts[0]
        
        return <CustomAlert alert={alert} onClose={resolve} />
})
