import {useEffect} from 'react'

export const useKeyPressed = fn => {

    useEffect(() => {

        const keyPressReaction = (event) => {
            fn(event.key)
        }

        document.addEventListener('keydown', keyPressReaction)

        return (() => {
            document.removeEventListener('keydown', keyPressReaction)
        })
    })
    
}