/**
 * 
 * @param {Piece} piece
 * @param {DataSource} source
 */
export function linkPieceDataSource(piece, source){
    source.onSetData.subscribe(async () =>{
        await piece.validate()
    })

    source.onClearData.subscribe(async () => {
        await piece.invalidate()
    })

    piece.onStateRejected.subscribe(async () => {
        await source.clearData()
    })
}