 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ContadorIds} from './Contador'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const ContadorEdt = Object.freeze({
    idContador : new SimpleEditor(ContadorIds.idContador, 11, 11),
    contador : new SimpleEditor(ContadorIds.contador, 15, 10),
    letra : new SimpleEditor(ContadorIds.letra, 2, 2),
    numero : new SimpleEditor(ContadorIds.numero, 11, 11),
})

tableEditors[Tables.Contador] = ContadorEdt
