import {ApiEndPoint} from 'sopix/db/apiEndPoint'
import {GraphqlMutation} from 'sopix/db/graphqlMutation'
import {boundMethod} from 'autobind-decorator'
import {getGraphqlFields, processGraphqlResult} from 'sopix/db/graphql-utils'
import {getChangedFields, getChangedFieldsForRows} from 'sopix/db/changed-state'

export class SaveMutation extends GraphqlMutation{

    constructor(/** DbTable */ dbTable, mutationName,
                {fields = undefined, response = [], listOfRows = false, extraFields = []} = {}) {
        const apiEndPoint = new ApiEndPoint(dbTable.graphqlUrl)

        let graphqlFields
        if (fields) {
            graphqlFields = getGraphqlFields(fields)
        }

        const responseFields = graphqlFields ? [{row: graphqlFields}] : []
        
        const responseSingle = [
            {errors: ['field', 'error']},
            ...responseFields,
        ] 
        
        const responseMany = [
            {rows: responseSingle}
        ]
        
        super(apiEndPoint, mutationName, [
            ...response,
            ...(listOfRows ? responseMany : responseSingle),
            'success',
        ])
        
        this.dbTable = dbTable
        this.fields = fields
        this.listOfRows = listOfRows
        this.extraFields = extraFields
    }
    
    @boundMethod
    _calcRowQuery(tableFields, rowParams){
        const regularParams = {}
        const nullParams = []
        for (let [name, value] of Object.entries(rowParams)) {
            if (tableFields.indexOf(name) >= 0 || this.extraFields.indexOf(name) >= 0) {
                if (value === null || value === undefined) {
                    nullParams.push(name)
                } else {
                    regularParams[name] = value
                }
            }
        }
        return {...regularParams, nullParams: nullParams}
    }

    @boundMethod
    _processResult(row, errors){
        let processedRow
        if (this.fields) {
            processedRow = processGraphqlResult(this.fields, row)
        }

        const errorList = {}
        for (let {field, error} of errors){
            errorList[field] = error
        }

        return [processedRow, errorList]
    }


    _calcTableFields(){
        return this.dbTable.regularFields.map(field => field.fieldName)
    }
    
    @boundMethod
    async query(data, {initialData} = {}){
        const tableFields = this._calcTableFields()
        
        if (this.listOfRows) {
            const [rawData, deleted] = !initialData ? [data, []]
                : getChangedFieldsForRows(this.dbTable, data, initialData)

            const rowRequests = [] 
            for (let row of rawData) {
                rowRequests.push(this._calcRowQuery(tableFields, row))
            }

            const {success, rows: rowResults, ...others} = await super.query({rows: rowRequests, delete: deleted})
            
            const rows = []
            for (let {errors, row} of rowResults){
                const [processedRow, errorList] = this._processResult(row, errors)
                rows.push({errorList, row: processedRow})
            }
            
            return {...others, success, rows}
            
        } else {
            const rawData = !initialData ? data : getChangedFields(this.dbTable, data, initialData)

            const request = this._calcRowQuery(tableFields, rawData)

            const {success, errors, row, ...others} = await super.query(request)

            const [processedRow, errorList] = this._processResult(row, errors)

            return {...others, success, errorList, row: processedRow}
        }
    }
    
}