import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {boundMethod} from 'autobind-decorator'
import {Documento} from 'sop/db/Documento'
import {Solicitud} from 'sop/db/Solicitud'
import {Cuenta} from 'sop/db/Cuenta'
import {Tarea} from 'sop/db/Tarea'
import {User} from 'sop/db/User'
import {HintIconButton, ICON_BUTTON_SIZE} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {icons} from 'sopix/icon/icons'
import React from 'react'
import {getLinkStyle} from 'sopix/theme/cimStyles'
import {makeStyles} from '@material-ui/core/styles'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {PlanosEstados, TrabajoClase} from 'sop/db-config/row-consts'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {Tooltip} from '@material-ui/core'
import {DocumentoAction} from 'sop/componentes/documento/documento-consts'
import {TipoTarea} from 'sop/db/TipoTarea'

export const LEFT_BUTTONS = 'LEFT_BUTTONS'
export const RIGHT_BUTTONS = 'RIGHT_BUTTONS'


const useStyles = makeStyles(theme => ({
    documentoLink: getLinkStyle(theme),
})) 


/**
 * @param {DocumentoListPiece} list
 */
export function DocumentoLink({list, flds}){
    const cls = useStyles()
    return (
        <span className={cls.documentoLink} onClick={e => {
            list.poper.__edit(flds(Documento.idDocumento))
            e.preventDefault()
            e.stopPropagation()
        }} >
            {flds(Documento.documento)}
        </span>
    )
}

/**
 * @param {DocumentoListPiece} list
 */
export function PlanosLink({list, documento}){
    const cls = useStyles()
    
    const flds = getFieldExtractor(documento)
    const estado = flds(Documento.idPlanosEstado)
    const poper = list.aproplanoPoper
    
    let title
    let link
    let tooltip
    
    const asa = list._asyncAction
    
    const clase = flds(Documento.cotizacion, Tarea.tipo, TipoTarea.clase)
    
    if (clase !== TrabajoClase.ESTUDIO) {
        return null
    }
    
    switch(estado){
        case PlanosEstados.NO_AUTORIZADO:
            const {isActive, isEditable, isVisible, edit, create} = poper.getEditDetails(documento)
            if (!isActive) {
                title = "No autorizado"
                if (isVisible) {
                    tooltip = poper.textoCrear
                    link = create
                }
            } else {
                title = "Autorización pendiente"
                if (isEditable) {
                    tooltip = poper.textoEditar
                    link = edit
                }
            }
            break
        case PlanosEstados.AUTORIZADO:
            title = "Enviar planos"
            link = () => list.enviarPlanosConAlerta(documento)
            break
        case PlanosEstados.ENVIADOS:
            title = "Seguimiento desactivado"
            if (userAuth.isAllowed(Permiso.cambiarSeguimientoPlanos)) {
                tooltip = "Activar seguimiento"
                link = () => list.planosAction(DocumentoAction.ACTIVAR_SEGUIMIENTO, documento)
            }
            break
        case PlanosEstados.SEGUIMIENTO:
            title = "Seguimiento activado"
            if (userAuth.isAllowed(Permiso.cambiarSeguimientoPlanos)) {
                tooltip = "Desactivar seguimiento"
                link = () => list.planosAction(DocumentoAction.DESACTIVAR_SEGUIMIENTO, documento)
            }
            break
        default:
            throw Error('Opción de planos inexistente')
    }
    
    const linkClicked = e => {
        e.preventDefault()
        e.stopPropagation()
        asa(link, {withProgress: false})()
    }
    
    const span = (
        <span className={!link ? undefined : cls.documentoLink} onClick={!link ? undefined : linkClicked} >
            {title}
        </span>
    )
    
    return !tooltip ? span : (
        <Tooltip title={tooltip}>
            {span}
        </Tooltip>
    )
}


export class DocumentoPainter extends TablePainter {

    @boundMethod
    /**
     * @param {DocumentoListPiece} list
     */
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global: list}) {
        switch (id) {
            case Documento.documento:
                return <DocumentoLink list={list} flds={flds} />
            case Documento.solicitud:
                return fld(Solicitud.cuenta, Cuenta.cuenta)
            case Documento.cotizacion:
                return fld(Tarea.ref)
            case Documento.autor:
                return fld(User.nombreCompleto)
            case LEFT_BUTTONS:
                const idDocumento = list._fileList && list._fileList.idDocumento 
                return idDocumento !== rowId ? null :  (!userAuth.isAllowed(Permiso.modificarDocumentos) ? null :
                    <HintIconButton list={list} Icon={sopIcons.File} title="Añadir archivo"
                                       badge="+" badgeColor={sopColors.badgeAdd}
                                       onClick={list.getFileAddClicked(rowId)}
                    />
                )
            case RIGHT_BUTTONS:
                return !userAuth.isAllowed(Permiso.modificarDocumentos) ? null :
                    <HintIconButton Icon={icons.Delete} title="Eliminar documento" 
                                       onClick={() => list.__deleteDocumento(rowId)} />

            case Documento.planosEstado:
                return <PlanosLink list={list} documento={row} />
                                       
            default:
                return super._paint(
                    {id, value, row, rowId, index, dbCol, formatter, fld, flds, global})
        }
    }

    @boundMethod
    _fixColumn(col, id, dbCol) {

        const fixer = {
            [Documento.solicitud]: () => {
                col.title = 'Solicitante'
            },
            [LEFT_BUTTONS]: () => {
                col.minWidth = col.maxWidth = ICON_BUTTON_SIZE
                col.props['noMargin'] = true
                col.props['onlyHover'] = true
            },
            [RIGHT_BUTTONS]: () => {
                col.minWidth = col.maxWidth = ICON_BUTTON_SIZE
                col.props['noMargin'] = true
                col.props['onlyHover'] = true
            },
        }[id]

        fixer && fixer()
    }
}