import React from 'react'
import {observer} from 'mobx-react'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {Frame} from 'sopix/formComps/Frame'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {LiquidacionSearch} from 'sop/componentes/liquidacion/LiquidacionSearch'
import {LiquidacionListPiece} from 'sop/componentes/liquidacion/LiquidacionList'
import {LiquidacionForm, LiquidacionFormPiece} from 'sop/componentes/liquidacion/LiquidacionForm'
import {boundMethod} from 'autobind-decorator'
import {Bar} from 'sopix/formComps/Bar'
import {Title} from 'sopix/formComps/Title'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {Liquidacion} from 'sop/db/Liquidacion'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {listFormSplitDefault} from 'sop/sop-config'
import {ErrorList} from 'sopix/errors/ErrorList'
import {once} from 'lodash-es'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {UrlManager} from 'sopix/pieces/urlManager'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {linkPageListSearchFormUrlWithApply} from 'sopix/piece-linkers/list-form-url'
import {Push} from 'sopix/formComps/Push'
import {FormControl} from 'sopix/piece-components/FormControl'
import {AproliqmanForm, AproliqmanFormPiece} from 'sop/componentes/liquidacion/AproliqmanForm'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {aprobacionCheck, refreshAprobacionesPage} from 'sop/componentes/aprobacion/aprobacionCheck'
import {User} from 'sop/db/User'
import {getAuthSingleton} from 'sopix/session/auth-singletons'

export class LiquidacionPagePiece extends PagePiece{

    constructor() {
        super()
        this.form = new LiquidacionFormPiece(this.world)
        this.urlManager = new UrlManager(this.world, this.form.idField, sopUrls.liquidaciones, sopUrls.liquidacion)
        this.search = new SearchPiece(this.world)
        this.list = new LiquidacionListPiece(this.world)

        this.aproliqmanForm = new AproliqmanFormPiece(this.world.cloneWithOwnErrors())
        this.poper = new FormPoper(this.aproliqmanForm)
        this.aproliqmanForm.onSave.subscribe(async () => {
            aprobacionCheck.refresh()
            refreshAprobacionesPage()
        })
        
        linkPageListSearchFormUrlWithApply(this, this.list, this.search, this.form, this.urlManager)
    }

    @boundMethod
    _listRowClicked({data}){
        this.form.apply(data)
    }
    
}


export const getLiquidacionPage = getAuthSingleton(sopPages.liquidaciones.id, () => {
    return new LiquidacionPagePiece(sopPages.liquidaciones)
})


export const LiquidacionPage = observer(
    /**
     * 
     * @param {LiquidacionPagePiece} page
     * @returns {JSX.Element}
     */
    ({page}) => {

        usePiece(page)


        
        function getTitle(){
            const userName = page.form.getField(Liquidacion.responsable, User.nombreCompleto) || null

            if (!page || !page.form.id) return null
            const m2Raw = page.form.getField(Liquidacion.m2Efectivos)
            const m2 = m2Raw.toLocaleString('de-DE')
            return `${m2} m² ${userName}`
        }
        
        const title = getTitle()
        
        
        return (
            <>
                <ErrorList errorManager={page.errorManager} />
                <PieceFrame piece={page} renderPiece={()=> {
                    return (
                        <VerticalSplit id="LiquidacionPage" defaultSizes={listFormSplitDefault}>
                            <Frame>
                                <Bar>
                                    <LiquidacionSearch search={page.search} />
                                    <HintIconButton large Icon={icons.Reload}
                                                    title="Actualizar" onClick={page.__refresh}
                                    />
                                </Bar>
                                <GraphqlTable list={page.list} />
                            </Frame>
                            <Frame>
                                <Bar>
                                    <ListNavigator list={page.list} /><Separator rightGap />
                                    <Title>{
                                        title
                                    }</Title>
                                    <Push />
                                    <FormControl form={page.form} />
                                    <HintIconButton Icon={sopIcons.Liquidacion} badge="+" 
                                                    badgeColor={sopColors.badgeAdd}
                                                    title="Solicitar liquidación manual" onClick={page.poper.__create}
                                    />
                                </Bar>
                                <LiquidacionForm form={page.form} />
                                <PoperFrame poper={page.poper} >
                                    <AproliqmanForm form={page.aproliqmanForm} />
                                </PoperFrame>
                            </Frame>
                        </VerticalSplit>
                    )

                }} />
            </>
        )
})