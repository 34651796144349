 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SolicitudIds} from './Solicitud'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {DateColumn, DateTimeColumn, LookupColumn, SimpleColumn} from 'sopix/db/dbColumn'

export const SolicitudCol = Object.freeze({
    cuenta : new LookupColumn(SolicitudIds.cuenta, Tables.Cuenta, 'cuenta'),
    direccion : new LookupColumn(SolicitudIds.direccion, Tables.Direccion, 'direccion'),
    contacto : new LookupColumn(SolicitudIds.contacto, Tables.Contacto, 'nombre'),
    obra : new LookupColumn(SolicitudIds.obra, Tables.Obra, 'cod'),
    autor : new LookupColumn(SolicitudIds.autor, Tables.User, 'nombre_completo'),
    idSolicitud : new SimpleColumn(SolicitudIds.idSolicitud, 11, 11, Align.RIGHT),
    fechaCreacion : new DateTimeColumn(SolicitudIds.fechaCreacion, 16, 16, Align.LEFT),
    fechaCreacionOnlyDate : new DateColumn(SolicitudIds.fechaCreacion, 11, 11, Align.LEFT),
    tipoCuenta : new SimpleColumn(SolicitudIds.tipoCuenta, 30, 12, Align.LEFT),
    idTipo : new SimpleColumn(SolicitudIds.idTipo, 11, 11, Align.RIGHT),
    odooCambiado : new SimpleColumn(SolicitudIds.odooCambiado, 11, 11, Align.RIGHT),
    odooDireccionTecnicoId : new SimpleColumn(SolicitudIds.odooDireccionTecnicoId, 11, 11, Align.RIGHT),
    odooCuentaCotizaciones : new SimpleColumn(SolicitudIds.odooCuentaCotizaciones, 11, 11, Align.RIGHT),
})

tableColumns[Tables.Solicitud] = SolicitudCol
