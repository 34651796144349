import {fields} from 'sopix/db/dbField'

/** @type {Object<DbTable>} */
export const tables = {}

function registerTable(tableId, table){
    tables[tableId] = table
}

export class DbTable {

    id
    title
    graphqlUrl
    graphqlId
    keyFieldId

    regularFieldIds
    objectFieldIds
    objectListFieldIds
    
    /** @type {Object<DbField>} */
    fields

    constructor(id, title, graphqlUrl, graphqlId, keyFieldId, regularFieldIds, objectFieldIds, objectListFieldIds, 
            readPermissions, writePermissions, createPermissions, deletePermissions, 
            readFieldPermissions, writeFieldPermissions, {displayFieldId} = {}) {
        this.title = title
        this.graphqlUrl = graphqlUrl
        this.graphqlId = graphqlId
        this.keyFieldId = keyFieldId
        this.displayFieldId = displayFieldId || keyFieldId[0]
        this.regularFieldIds = regularFieldIds
        this.objectFieldIds = objectFieldIds
        this.objectListFieldIds = objectListFieldIds
        
        this.readPermissions = readPermissions
        this.writePermissions = writePermissions
        this.createPermissions = createPermissions || writePermissions
        this.deletePermissions = deletePermissions || writePermissions
        
        this.readFieldPermissions = readFieldPermissions
        this.writeFieldPermissions = writeFieldPermissions
        
        registerTable(id, this)
    }

    
    /** @type {DbField[]} */
    get keyFields(){
        return this.keyFieldId.map(id => fields[id])
    }
    
    get displayField(){
        return fields[this.displayFieldId]
    }
    
    /** @type {DbField[]} */
    get regularFields(){
        return this.regularFieldIds.map(id => fields[id])
    }

    /** @member {String[]} */
    get regularFieldNames(){
        return this.regularFieldIds.map(id => fields[id].fieldName)
    }

    /** @member {DbObject[]} */
    get objectFields(){
        return this.objectFieldIds.map(id => fields[id])
    }

    /** @member {String[]} */
    get objectFieldNames(){
        return this.objectFieldIds.map(id => fields[id].fieldName)
    }

    /** @member {DbObjectList[]} */
    get objectListFields(){
        return this.objectListFieldIds.map(id => fields[id])
    }

    /** @member {String[]} */
    get objectListFieldNames(){
        return this.objectListFieldIds.map(id => fields[id].fieldName)
    }
}
