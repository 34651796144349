import React, {forwardRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    tableContainer: {
        //Necesario para que aparezca el scroll vertical
        overflowX: 'auto',
        position: 'relative',
        minHeight: 100,
        width: '100%',
    },
    fitContainer: {
        position: 'absolute',
        height: '100%',
    }
}))

export const BlTableContainer = forwardRef(({className, fitContainer, onScroll, children}, ref) => {
    const cls = useStyles()
    return (
        <div ref={ref} className={clsx(
            cls.tableContainer,
            fitContainer && cls.fitContainer,
            className,
        )} onScroll={onScroll}>
            {children}
        </div>
    )
})
