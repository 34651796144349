 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageDescuentoIds} from './SageDescuento'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const SageDescuentoEdt = Object.freeze({
    codigoEmpresa : new SimpleEditor(SageDescuentoIds.codigoEmpresa, 11, 11),
    codigoCliente : new SimpleEditor(SageDescuentoIds.codigoCliente, 15, 10),
    codigoFamilia : new SimpleEditor(SageDescuentoIds.codigoFamilia, 10, 10),
    descripcionFamilia : new SimpleEditor(SageDescuentoIds.descripcionFamilia, 40, 16),
    pO_DtoA : new SimpleEditor(SageDescuentoIds.pO_DtoA, 11, 11),
    pO_DtoB : new SimpleEditor(SageDescuentoIds.pO_DtoB, 11, 11),
    pO_DtoR : new SimpleEditor(SageDescuentoIds.pO_DtoR, 11, 11),
    descuento1 : new SimpleEditor(SageDescuentoIds.descuento1, 11, 11),
    pO_Observaciones : new SimpleEditor(SageDescuentoIds.pO_Observaciones, 40, 16),
})

tableEditors[Tables.SageDescuento] = SageDescuentoEdt
