 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AprobacionIds} from './Aprobacion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {AprobacionTipoIds} from './AprobacionTipo'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {TareaIds} from './Tarea'

export const AprobacionEdt = Object.freeze({
    tipo : new LookupEditor(AprobacionIds.tipo, Tables.AprobacionTipo, AprobacionTipoIds.idTipo, 'tipo', {sort: 'ID_TIPO_ASC'}),
    solicitante : new LookupEditor(AprobacionIds.solicitante, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    obra : new LookupEditor(AprobacionIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    tarea : new LookupEditor(AprobacionIds.tarea, Tables.Tarea, TareaIds.idTarea, 'ref', {sort: 'ID_TAREA_ASC'}),
    idAprobacion : new SimpleEditor(AprobacionIds.idAprobacion, 11, 11),
    fechaCreacion : new DateTimeEditor(AprobacionIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(AprobacionIds.fechaCreacion, 11, 11),
    solicitud : new SimpleEditor(AprobacionIds.solicitud, 40, 16),
    estado : new SimpleEditor(AprobacionIds.estado, 11, 11),
    fechaResolucion : new DateTimeEditor(AprobacionIds.fechaResolucion, 16, 16),
    fechaResolucionOnlyDate : new DateEditor(AprobacionIds.fechaResolucion, 11, 11),
    razonRechazo : new SimpleEditor(AprobacionIds.razonRechazo, 40, 16),
})

tableEditors[Tables.Aprobacion] = AprobacionEdt
