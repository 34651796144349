import {getLog} from 'sopix/log'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {DbField, fields} from 'sopix/db/dbField'

const log = getLog('ListFetcher')


export class ListData {
    constructor(fields, rows) {
        this.fields = fields
        this.idField = fields[0]
        this.displayField = fields.length > 1 ? fields[1] : fields[0]
        this.rows = rows
    }
    
}

export class ListFetcherError extends Error{
}


export class ListQueryEntry{
    constructor(queryParams, fieldList, {filters, listKey} = {}) {
        this.params = queryParams
        this.fields = fieldList.map(foi => foi instanceof DbField ? foi : fields[foi])
        this._listKey = listKey
        this.filters = filters
    }
    
    get firstField(){
        return this.fields[0]
    }
    
    get table(){
        return this.firstField.table
    }
    
    get listKey(){
        return this._listKey ? this._listKey : this.table.graphqlId
    }
    
    get keyField(){
        return this.table.keyFields[0]
    }
    
    get fieldsIncludingKey(){
        return this.fields.includes(this.keyField) ? this.fields : [this.keyField, ...this.fields]
    }
    
    get fieldNamesIncludingKey(){
        return this.fieldsIncludingKey.map(field => field.fieldName)        
    }
}

export class ListFetcher {

    /**
     * @param {ListQueryEntry[]} queryEntries
     */
    constructor(queryEntries) {
        this.queryEntries = queryEntries
    }
    
    fetch = async () => {

        const promises = []
        for(let queryEntry of this.queryEntries){
            const graphql = new TableGraphql(queryEntry.table, {cached: true})
            promises.push(graphql.query(queryEntry.fieldsIncludingKey, queryEntry.params, queryEntry.filters))
        }
        
        const results = await Promise.all(promises)

        let idx = 0
        const lists = {}
        for(let result of results){
            const queryEntry = this.queryEntries[idx++]
            lists[queryEntry.listKey] = new ListData(
                queryEntry.fieldNamesIncludingKey,
                result.rows,
            )
        }
        return lists
    }
}
