import React from 'react'
import {ErrorList} from 'sopix/errors/ErrorList'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Frame} from 'sopix/formComps/Frame'
import {Bar} from 'sopix/formComps/Bar'
import {ObraSearch, ObraSearchPiece} from 'sop/componentes/obra/ObraSearch'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {ObraList, ObraListPiece} from 'sop/componentes/obra/ObraList'
import {Piece} from 'sopix/piece/piece'
import {linkSearchFiltrator} from 'sopix/piece-linkers/search-list'
import {observer} from 'mobx-react'
import {usePiece} from 'sopix/piece/use-piece'

export class ObraPickerPiece extends Piece{
    constructor(world) {
        super(world)

        this.search = new ObraSearchPiece(world)
        this.list = new ObraListPiece(world)
        linkSearchFiltrator(this.search, this.list.filtrator)
        
        this.filtrator = this.list.filtrator
    }
}


export const ObraPicker = observer(({picker}) => {
    
    usePiece(picker)
    
    return (<>
        <PieceFrame piece={picker} renderPiece={()=> {
            return (
                <Frame >
                    <Bar alignEnd >
                        <ObraSearch search={picker.search} />
                        <HintIconButton Icon={icons.Reload}
                                        large title="Actualizar" onClick={picker.filtrator.__refresh}
                        />
                    </Bar>
                    <ObraList list={picker.list} />
                </Frame>
            )
        }}/>
    </>)
            
})