import React, {useState} from 'react'
import {TabFrame} from 'sopix/formComps/TabFrame'
import {IconTab} from 'sopix/formComps/IconTab'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {RepresentanteList} from 'sop/componentes/obra-representante-S/RepresentanteList'
import {observer} from 'mobx-react'
import {PedidoList} from 'sop/componentes/obra-pedido-S/pedidoList'
import {ObraMap} from 'sop/componentes/obra-map-S/ObraMap'
import {RelacionadaList} from 'sop/componentes/obra-relacionada/RelacionadaList'
import {EjecucionList} from 'sop/componentes/ejecucion/EjecucionList'
import {DocumentoList} from 'sop/componentes/documento/DocumentoList'

const DIRECTION = 'column'

export const ObraTab = observer(
/**
 *
 * @param {ObraPagePiece} page
 * @returns {JSX.Element}
 * @constructor
 */
({page}) => {
    const [tab, setTab] = useState(0)

    const hasRepresentantes = page.representanteList.rows.length > 0
    
    const tabDirection = `${DIRECTION}Reverse`
    
    let tabs = [
        <IconTab key="mapa" icon={sopIcons.Mapa} toolTip="Mapa" />,
        <IconTab
            key="representantes"
            icon={sopIcons.Representante}
            toolTip={`Representates${!page.representanteList.hasNoZoneRepresentantes ? '' : ': algunos están fuera de zona' }`}
            color={page.representanteList.hasNoZoneRepresentantes ? 'error' : undefined}
            badgeColor={hasRepresentantes ? sopColors.badgeCount : 'error'}
            badge={page.representanteList.badgeCount || ' '}
            badgeVariant={hasRepresentantes ? undefined : "dot"}
        />,
        <IconTab 
            key="obrasRelacionadas" icon={sopIcons.Obra} toolTip="Obras relacionadas"
            badgeColor={sopColors.badgeCount} badge={page.relacionadas.dbSource.data.length || undefined}
        />,
        <IconTab
            key="pedidos" icon={sopIcons.Pedido} toolTip="Pedidos"
            badgeColor={sopColors.badgeCount} badge={page.pedidoList.badgeCount}
        />,
        <IconTab key="ejecuciones" icon={sopIcons.Ejecucion} toolTip="Ejecuciones" />,
        <IconTab key="documentos" icon={sopIcons.Documento} toolTip="Documentos" />,
    ]
    
    if (page.creating) {
        tabs=[tabs[0]]
    }
    
    return (
        <TabFrame
            value={tab}
            direction={DIRECTION}
            onChange={setTab}
            absolute={true}
            edge="start"
            tabs={tabs}
        >
            {[
                <ObraMap obraMap={page.obraMap} direction={tabDirection}/>,
                <RepresentanteList representanteList={page.representanteList} direction={tabDirection}/>,
                <RelacionadaList list={page.relacionadas} direction={tabDirection}/>,
                <PedidoList pedidoList={page.pedidoList} direction={tabDirection}/>,
                <EjecucionList list={page.ejecuciones} />,
                <DocumentoList list={page.documentos} />,
            ][tab]}
        </TabFrame>
    )
})
