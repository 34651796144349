 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AproposicionIds = Object.freeze({
    tareas: Symbol('tareas'),
    aprobacion: Symbol('aprobacion'),
    trabajo: Symbol('trabajo'),
    idAproposicion: Symbol('idAproposicion'),
    idAprobacion: Symbol('idAprobacion'),
    idTrabajo: Symbol('idTrabajo'),
    posicionOriginal: Symbol('posicionOriginal'),
    nuevaPosicion: Symbol('nuevaPosicion'),
    justificacion: Symbol('justificacion'),
})

export const Aproposicion = Object.freeze({
    tareas: 'tareas',
    aprobacion: 'aprobacion',
    trabajo: 'trabajo',
    idAproposicion: 'idAproposicion',
    idAprobacion: 'idAprobacion',
    idTrabajo: 'idTrabajo',
    posicionOriginal: 'posicionOriginal',
    nuevaPosicion: 'nuevaPosicion',
    justificacion: 'justificacion',
})
