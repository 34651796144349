 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TipoTareaIds} from './TipoTarea'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const TipoTareaCol = Object.freeze({
    idTipo : new SimpleColumn(TipoTareaIds.idTipo, 11, 11, Align.RIGHT),
    tipo : new SimpleColumn(TipoTareaIds.tipo, 20, 10, Align.LEFT),
    ratio : new SimpleColumn(TipoTareaIds.ratio, 11, 11, Align.RIGHT),
    m2 : new SimpleColumn(TipoTareaIds.m2, 11, 11, Align.RIGHT),
    detalle : new SimpleColumn(TipoTareaIds.detalle, 20, 10, Align.LEFT),
    clase : new SimpleColumn(TipoTareaIds.clase, 11, 11, Align.RIGHT),
    orden : new SimpleColumn(TipoTareaIds.orden, 11, 11, Align.RIGHT),
    archivado : new SimpleColumn(TipoTareaIds.archivado, 11, 11, Align.RIGHT),
    ratioOld : new SimpleColumn(TipoTareaIds.ratioOld, 11, 11, Align.RIGHT),
    planificador : new SimpleColumn(TipoTareaIds.planificador, 11, 11, Align.RIGHT),
})

tableColumns[Tables.TipoTarea] = TipoTareaCol
