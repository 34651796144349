import {findIndex, isArray, reduce} from 'lodash-es'
import {getLog} from 'sopix/log'
import {GraphqlEndPoint} from 'sopix/db/graphqlEndPoint'
import {ApiEndPoint} from 'sopix/db/apiEndPoint'
import {DbField, DbObject, DbObjectList, fields} from 'sopix/db/dbField'

const log = getLog('db-utils')


/**
 * 
 * @param {DbField[]} fieldArray
 * @param {String[]} fieldIds
 * @returns {*}
 */
export const takeFields = (fieldArray, fieldIds) => {
    const taken = reduce(fieldArray, 
        /** @param {DbField} field */
        (result, field, index) => {
            if (fieldIds.indexOf(field.id) >= 0) {
                result.push(field)
            }
            return result
        }, [])
    return taken
}


/**
 * Devuelve un objeto de una lista qué contenga un key y value con opción de obtener uno relativo al match
 * @param {Object[]} rows
 * @param fieldKey
 * @param fieldValue
 * @param offset
 */
export function pickRow(rows, fieldKey, fieldValue, offset = 0) {
    const index = findIndex(rows, [fieldKey, fieldValue])
    
    //Si el actual no está en la lista no se puede 
    if (index < 0) return undefined
    
    const targetIndex = index >= 0 ? index + offset : (offset < 0 ? rows.length + offset : offset)
    return targetIndex >= 0 && targetIndex < rows.length ? rows[targetIndex] : undefined
}


export function createTableGraphql(/* DbTable */ table){
    return new GraphqlEndPoint(table.graphqlId, new ApiEndPoint(table.graphqlUrl))
}


export function getFieldFromName(/* DbTable */ table, fieldName){
    for (let field of table.regularFields){
        if (field.fieldName === fieldName) {
            return field
        }
    }
}


export function decodeFieldDescriptors(fieldDescriptors, {onlyPhysical = false} = {}){
    const result = new Map()
    for(let descriptor of fieldDescriptors){
        const foi = isArray(descriptor) ? descriptor[0] : descriptor
        const field = foi instanceof DbField ? foi : fields[foi]
            
        if (onlyPhysical && (field instanceof DbObject || field instanceof DbObjectList)){
            continue
        }
        
        result.set(field.fieldName, field)
    }
    return result
}