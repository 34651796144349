 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {EjecucionIds} from './Ejecucion'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {TareaIds} from './Tarea'
import {SistemaIds} from './Sistema'
import {ProcedenciaIds} from './Procedencia'

export const EjecucionEdt = Object.freeze({
    tarea : new LookupEditor(EjecucionIds.tarea, Tables.Tarea, TareaIds.idTarea, 'ref', {sort: 'ID_TAREA_ASC'}),
    sistema : new LookupEditor(EjecucionIds.sistema, Tables.Sistema, SistemaIds.idSistema, 'sistema', {sort: 'ID_SISTEMA_ASC'}),
    procedencia : new LookupEditor(EjecucionIds.procedencia, Tables.Procedencia, ProcedenciaIds.idProcedencia, 'procedencia', {sort: 'ID_PROCEDENCIA_ASC'}),
    idEjecucion : new SimpleEditor(EjecucionIds.idEjecucion, 11, 11),
    m2Calculados : new SimpleEditor(EjecucionIds.m2Calculados, 11, 11),
    m2Efectivos : new SimpleEditor(EjecucionIds.m2Efectivos, 11, 11),
    m2Totales : new SimpleEditor(EjecucionIds.m2Totales, 11, 11),
    competencia : new SimpleEditor(EjecucionIds.competencia, 11, 11),
    idAutor : new SimpleEditor(EjecucionIds.idAutor, 11, 11),
    fechaCreacion : new DateTimeEditor(EjecucionIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(EjecucionIds.fechaCreacion, 11, 11),
    odooCambiado : new SimpleEditor(EjecucionIds.odooCambiado, 11, 11),
})

tableEditors[Tables.Ejecucion] = EjecucionEdt
