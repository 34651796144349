 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UserRoleIds} from './UserRole'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObjectList, DbText} from 'sopix/db/dbField'

export const UserRoleTable = new DbTable(
    Tables.UserRole,
    'UsersRoles',
    graphqlUrl,
    'UserRole',
    [UserRoleIds.idRole],
    [UserRoleIds.idRole, UserRoleIds.role],
    [],
    [UserRoleIds.users],
    ['verUsuariosMinimo'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const UserRoleFld = Object.freeze({
    users : new DbObjectList(Tables.UserRole, UserRoleIds.users, Tables.User),
    idRole : new DbIntId(Tables.UserRole, UserRoleIds.idRole, 'Id role'),
    role : new DbText(Tables.UserRole, UserRoleIds.role, 'Role', 20),
})

registerTableFields(Tables.UserRole, UserRoleFld) 
