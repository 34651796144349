 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {CuentaIds} from './Cuenta'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {CuentaTipoIds} from './CuentaTipo'
import {UserIds} from './User'
import {AlmacenIds} from './Almacen'

export const CuentaTable = new DbTable(
    Tables.Cuenta,
    'Cuentas',
    graphqlUrl,
    'Cuenta',
    [CuentaIds.idCuenta],
    [CuentaIds.idCuenta, CuentaIds.cifdni, CuentaIds.cuenta, CuentaIds.telefono1, CuentaIds.idTipo, CuentaIds.comentarios, CuentaIds.fechaAlta, CuentaIds.fechaModificacion, CuentaIds.idAutor, CuentaIds.idModificacion, CuentaIds.idAlmacen, CuentaIds.idComercial, CuentaIds.odooCambiado],
    [CuentaIds.tipo, CuentaIds.autor, CuentaIds.modificacion, CuentaIds.almacen, CuentaIds.comercial],
    [CuentaIds.solicitudes, CuentaIds.direcciones, CuentaIds.avisos, CuentaIds.ejecucionesCuentas],
    ['verCuentas'],
    ['modificarCuentas'],
    ['crearCuentas'],
    ['eliminarCuentas'],
    [],
    [],
)


export const CuentaFld = Object.freeze({
    solicitudes : new DbObjectList(Tables.Cuenta, CuentaIds.solicitudes, Tables.Solicitud),
    direcciones : new DbObjectList(Tables.Cuenta, CuentaIds.direcciones, Tables.Direccion),
    avisos : new DbObjectList(Tables.Cuenta, CuentaIds.avisos, Tables.Aviso),
    ejecucionesCuentas : new DbObjectList(Tables.Cuenta, CuentaIds.ejecucionesCuentas, Tables.EjecucionCuenta),
    tipo : new DbObject(Tables.Cuenta, CuentaIds.tipo, CuentaIds.idTipo, 'Tipo'),
    autor : new DbObject(Tables.Cuenta, CuentaIds.autor, CuentaIds.idAutor, 'Autor'),
    modificacion : new DbObject(Tables.Cuenta, CuentaIds.modificacion, CuentaIds.idModificacion, 'Modificacion'),
    almacen : new DbObject(Tables.Cuenta, CuentaIds.almacen, CuentaIds.idAlmacen, 'Almacen'),
    comercial : new DbObject(Tables.Cuenta, CuentaIds.comercial, CuentaIds.idComercial, 'Comercial'),
    idCuenta : new DbIntId(Tables.Cuenta, CuentaIds.idCuenta, 'Id cuenta'),
    cifdni : new DbText(Tables.Cuenta, CuentaIds.cifdni, 'CIF/DNI', 15),
    cuenta : new DbText(Tables.Cuenta, CuentaIds.cuenta, 'Cuenta', 80),
    telefono1 : new DbText(Tables.Cuenta, CuentaIds.telefono1, 'Teléfono', 40),
    idTipo : new DbObjectId(Tables.Cuenta, CuentaIds.idTipo, 'Id tipo', {foreignTableId: Tables.CuentaTipo, foreignKeyFieldId: CuentaTipoIds.idTipo}),
    comentarios : new DbText(Tables.Cuenta, CuentaIds.comentarios, 'Comentarios', 1000),
    fechaAlta : new DbDateTime(Tables.Cuenta, CuentaIds.fechaAlta, 'Fecha alta'),
    fechaModificacion : new DbDateTime(Tables.Cuenta, CuentaIds.fechaModificacion, 'Fecha modificacion'),
    idAutor : new DbObjectId(Tables.Cuenta, CuentaIds.idAutor, 'Creado por', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idModificacion : new DbObjectId(Tables.Cuenta, CuentaIds.idModificacion, 'Modificado por', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idAlmacen : new DbObjectId(Tables.Cuenta, CuentaIds.idAlmacen, 'Id almacen', {foreignTableId: Tables.Almacen, foreignKeyFieldId: AlmacenIds.idAlmacen}),
    idComercial : new DbObjectId(Tables.Cuenta, CuentaIds.idComercial, 'Id comercial', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    odooCambiado : new DbInt(Tables.Cuenta, CuentaIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.Cuenta, CuentaFld) 
