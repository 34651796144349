 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const PaisIds = Object.freeze({
    idPais: Symbol('idPais'),
    iso: Symbol('iso'),
    nombre: Symbol('nombre'),
})

export const Pais = Object.freeze({
    idPais: 'idPais',
    iso: 'iso',
    nombre: 'nombre',
})
