 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageClienteIds} from './SageCliente'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbIntId, DbText, DbTextId} from 'sopix/db/dbField'

export const SageClienteTable = new DbTable(
    Tables.SageCliente,
    'Clientes',
    graphqlUrl,
    'SageCliente',
    [SageClienteIds.codigoEmpresa],
    [SageClienteIds.codigoEmpresa, SageClienteIds.codigoCliente, SageClienteIds.cifDni, SageClienteIds.fechaAlta, SageClienteIds.razonSocial, SageClienteIds.nombre, SageClienteIds.domicilio, SageClienteIds.municipio, SageClienteIds.provincia, SageClienteIds.actividad, SageClienteIds.tipoCliente, SageClienteIds.codigoPostal, SageClienteIds.nacion, SageClienteIds.telefono, SageClienteIds.comentarios_],
    [],
    [],
    ['verCuentasSage'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SageClienteFld = Object.freeze({
    codigoEmpresa : new DbIntId(Tables.SageCliente, SageClienteIds.codigoEmpresa, 'Codigo empresa'),
    codigoCliente : new DbTextId(Tables.SageCliente, SageClienteIds.codigoCliente, 'Codigo cliente', 15),
    cifDni : new DbText(Tables.SageCliente, SageClienteIds.cifDni, 'CIF/DNI', 13),
    fechaAlta : new DbDateTime(Tables.SageCliente, SageClienteIds.fechaAlta, 'Fecha alta'),
    razonSocial : new DbText(Tables.SageCliente, SageClienteIds.razonSocial, 'Razon social', 40),
    nombre : new DbText(Tables.SageCliente, SageClienteIds.nombre, 'Nombre', 35),
    domicilio : new DbText(Tables.SageCliente, SageClienteIds.domicilio, 'Domicilio', 40),
    municipio : new DbText(Tables.SageCliente, SageClienteIds.municipio, 'Municipio', 25),
    provincia : new DbText(Tables.SageCliente, SageClienteIds.provincia, 'Provincia', 20),
    actividad : new DbText(Tables.SageCliente, SageClienteIds.actividad, 'Actividad', 50),
    tipoCliente : new DbText(Tables.SageCliente, SageClienteIds.tipoCliente, 'Tipo cliente', 10),
    codigoPostal : new DbText(Tables.SageCliente, SageClienteIds.codigoPostal, 'Codigo postal', 8),
    nacion : new DbText(Tables.SageCliente, SageClienteIds.nacion, 'Nacion', 25),
    telefono : new DbText(Tables.SageCliente, SageClienteIds.telefono, 'Telefono', 15),
    comentarios_ : new DbText(Tables.SageCliente, SageClienteIds.comentarios_, 'Comentarios', 1000),
})

registerTableFields(Tables.SageCliente, SageClienteFld) 
