import React, {useEffect} from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Field} from 'sopix/form/editors/Field'
import {observer} from 'mobx-react'
import {DateField} from 'sopix/form/editors/DateField'
import {AvisoEdt} from 'sop/db/AvisoEditors'
import {Aviso} from 'sop/db/Aviso'
import {UserEdt} from 'sop/db/UserEditors'
import {sopUrls} from 'sop/sop-pages'
import {TareaEdt} from 'sop/db/TareaEditors'
import {ObraEdt} from 'sop/db/ObraEditors'
import {AvisoTipoEdt} from 'sop/db/AvisoTipoEditors'
import {stripHtmlTags} from 'sopix/string/html-utils'
import {FormPiece} from 'sopix/pieces/formPiece'
import {sopIcons} from 'sop/sop-icons'
import {AvisoFld, AvisoTable} from 'sop/db/AvisoTable'
import {ObraFld} from 'sop/db/ObraTable'
import {TareaFld} from 'sop/db/TareaTable'
import {UserFld} from 'sop/db/UserTable'
import {AvisoTipoFld} from 'sop/db/AvisoTipoTable'
import {Fields} from 'sopix/formComps/Fields'
import {usePiece} from 'sopix/piece/use-piece'

export class AvisoFormPiece extends FormPiece{
    constructor(world) {
        const AVISO_FIELDS = [
            ...AvisoTable.regularFields,
            [AvisoFld.obra, [ObraFld.idObra, ObraFld.cod]],
            [AvisoFld.tarea, [TareaFld.idTarea, TareaFld.ref]],
            [AvisoFld.usuario, [UserFld.id, UserFld.nombreCompleto, UserFld.username]],
            [AvisoFld.tipo, [AvisoTipoFld.idTipo, AvisoTipoFld.tipo]],
        ]

        super(world,
            AVISO_FIELDS,
            {
                name: 'Aviso',
                icon: sopIcons.Aviso,
                table: AvisoTable,
                idField: 'idAviso',
                displayField: 'idAviso',
                saveMutation: 'SaveAviso',
                deleteMutation: 'DeleteAviso',
            },
        )
    }    
}


export const AvisoForm = observer(
    /**
     * @param {FormPiece} form
     * @returns {JSX.Element}
     */
    ({form}) => {

    usePiece(form)

    return (
        <PieceFrame piece={form} renderPiece={()=> {

            const H = form.fieldHelper
            const fld = form.getField

            return (
                <Fields>
                    <DateField disabled {...H.edt(AvisoEdt.fecha)} />
                    {!fld(Aviso.usuario) ? null :
                        <Field {...H.obj(AvisoEdt.usuario, UserEdt.nombreCompleto)}
                               {...H.btn(AvisoEdt.usuario, sopUrls.usuario, sopIcons.Usuario)} />
                    }
                    {!fld(Aviso.tarea) ? null :
                        <Field {...H.obj(AvisoEdt.tarea, TareaEdt.ref)} 
                               {...H.btn(AvisoEdt.tarea, sopUrls.trabajo, sopIcons.Trabajo)} />
                    }
                    {!fld(Aviso.obra) ? null :
                        <Field {...H.obj(AvisoEdt.obra, ObraEdt.cod)}
                               {...H.btn(AvisoEdt.obra, sopUrls.obra, sopIcons.Obra)} />
                    }

                    {!fld(Aviso.tipo) ? null :
                        <Field {...H.obj(AvisoEdt.tipo, AvisoTipoEdt.tipo)} />
                    }
                    
                    {!fld(Aviso.aviso) ? null : 
                        <Field disabled {...H.edt(AvisoEdt.aviso, {manager: false})} multiline 
                               width={1000}
                               value={stripHtmlTags(fld(Aviso.aviso))} />
                    }

                </Fields>
            )

        }} />
    )
})