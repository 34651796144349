import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {Direction} from 'sopix/formComps/comp-classes'
import {HintIcon} from 'sopix/formComps/HintIcon'

const useStyles = makeStyles(theme => ({
    bar: {
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        //padding: '0 10px',
        minHeight: 50,
        minWidth: 50,
        width: ({expand}) => expand ? '100%' : 'auto',
        pointerEvents: 'none',
        '& > *': {
            pointerEvents: 'auto !important', //Issue: React CSS diferente en producción
        },
    },
    
    alignStart: {
        alignItems: 'flex-start',   
    },

    alignEnd: {
        alignItems: 'flex-end',
    },

    barVertical: {
        //padding: '10px 0',
        height: ({expand}) => expand ? '100%' : 'auto',
        width: 'auto',
    },
    
    barAbsolute: {
        position: 'absolute',
        zIndex: 1,
    },

    barAbsoluteBottom: {
        bottom: 0,
    },

    barAbsoluteRight: {
        right: 0,
    },

    iconRow: {
        marginLeft: 10,
        marginRight: 10,
    },
    
    iconColumn: {
        marginTop: 10,
        marginBottom: 10,
    },
    
    column: {
        flexDirection: 'column',
    },

    columnReverse: {
        flexDirection: 'column-reverse',
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    
    transparent: {
        background: 'none',
    },

    verticalStartGap: {
        marginTop: 10,    
    },

    horizStartGap: {
        marginLeft: 10,
    },
}))

export function Bar({className, direction, absolute, absoluteRight, expand=false, transparent, alignStart, 
                        alignEnd, Icon, iconProps, startGap, children}) {

    const cls = useStyles({expand: expand})

    const vertical = direction === Direction.COLUMN || direction === Direction.COLUMN_REVERSE 
    
    const icon = !Icon ? null : <HintIcon Icon={Icon} className={clsx(
        !vertical && cls.iconRow,
        vertical && cls.iconColumn,
    )} {...iconProps} />
    
    return (
        <div className={clsx(
            cls.bar,
            transparent && cls.transparent,
            vertical && cls.barVertical,
            alignStart && cls.alignStart,
            alignEnd && cls.alignEnd,
            startGap && vertical && cls.verticalStartGap,
            startGap && !vertical && cls.horizStartGap,
            cls[direction],
            !absolute ? undefined : [
                cls.barAbsolute,
                (direction === Direction.COLUMN_REVERSE) && cls.barAbsoluteBottom,
                (direction === Direction.ROW_REVERSE || absoluteRight) && cls.barAbsoluteRight,
            ],
            className,
        )}>
            {icon}
            {children}
        </div>
    )
}
