 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PaisIds} from './Pais'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbText} from 'sopix/db/dbField'

export const PaisTable = new DbTable(
    Tables.Pais,
    'Paises',
    graphqlUrl,
    'Pais',
    [PaisIds.idPais],
    [PaisIds.idPais, PaisIds.iso, PaisIds.nombre],
    [],
    [],
    ['verListas'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const PaisFld = Object.freeze({
    idPais : new DbIntId(Tables.Pais, PaisIds.idPais, 'Id pais'),
    iso : new DbText(Tables.Pais, PaisIds.iso, 'Iso', 2),
    nombre : new DbText(Tables.Pais, PaisIds.nombre, 'Nombre', 80),
})

registerTableFields(Tables.Pais, PaisFld) 
