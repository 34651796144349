 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AprobacionIds} from './Aprobacion'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {AprobacionTipoIds} from './AprobacionTipo'
import {UserIds} from './User'
import {ObraIds} from './Obra'
import {TareaIds} from './Tarea'
import {DocumentoIds} from './Documento'

export const AprobacionTable = new DbTable(
    Tables.Aprobacion,
    'Aprobaciones',
    graphqlUrl,
    'Aprobacion',
    [AprobacionIds.idAprobacion],
    [AprobacionIds.idAprobacion, AprobacionIds.fechaCreacion, AprobacionIds.idTipo, AprobacionIds.solicitud, AprobacionIds.idSolicitante, AprobacionIds.idObra, AprobacionIds.idTarea, AprobacionIds.idDocumento, AprobacionIds.estado, AprobacionIds.fechaResolucion, AprobacionIds.razonRechazo],
    [AprobacionIds.tipo, AprobacionIds.solicitante, AprobacionIds.obra, AprobacionIds.tarea, AprobacionIds.documento],
    [AprobacionIds.tareas, AprobacionIds.aproestas, AprobacionIds.aproposiciones, AprobacionIds.aproasignar, AprobacionIds.aproliqmans, AprobacionIds.aproplanos],
    ['verAprobaciones'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const AprobacionFld = Object.freeze({
    tareas : new DbObjectList(Tables.Aprobacion, AprobacionIds.tareas, Tables.Tarea),
    aproestas : new DbObjectList(Tables.Aprobacion, AprobacionIds.aproestas, Tables.Aproesta),
    aproposiciones : new DbObjectList(Tables.Aprobacion, AprobacionIds.aproposiciones, Tables.Aproposicion),
    aproasignar : new DbObjectList(Tables.Aprobacion, AprobacionIds.aproasignar, Tables.Aproasignar),
    aproliqmans : new DbObjectList(Tables.Aprobacion, AprobacionIds.aproliqmans, Tables.Aproliqman),
    aproplanos : new DbObjectList(Tables.Aprobacion, AprobacionIds.aproplanos, Tables.Aproplano),
    tipo : new DbObject(Tables.Aprobacion, AprobacionIds.tipo, AprobacionIds.idTipo, 'Tipo'),
    solicitante : new DbObject(Tables.Aprobacion, AprobacionIds.solicitante, AprobacionIds.idSolicitante, 'Solicitante'),
    obra : new DbObject(Tables.Aprobacion, AprobacionIds.obra, AprobacionIds.idObra, 'Obra'),
    tarea : new DbObject(Tables.Aprobacion, AprobacionIds.tarea, AprobacionIds.idTarea, 'Trabajo'),
    documento : new DbObject(Tables.Aprobacion, AprobacionIds.documento, AprobacionIds.idDocumento, 'Documento'),
    idAprobacion : new DbIntId(Tables.Aprobacion, AprobacionIds.idAprobacion, 'Id aprobacion'),
    fechaCreacion : new DbDateTime(Tables.Aprobacion, AprobacionIds.fechaCreacion, 'Fecha creacion'),
    idTipo : new DbObjectId(Tables.Aprobacion, AprobacionIds.idTipo, 'Id tipo', {foreignTableId: Tables.AprobacionTipo, foreignKeyFieldId: AprobacionTipoIds.idTipo}),
    solicitud : new DbText(Tables.Aprobacion, AprobacionIds.solicitud, 'Solicitud', 1000),
    idSolicitante : new DbObjectId(Tables.Aprobacion, AprobacionIds.idSolicitante, 'Id solicitante', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    idObra : new DbObjectId(Tables.Aprobacion, AprobacionIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idTarea : new DbObjectId(Tables.Aprobacion, AprobacionIds.idTarea, 'Id tarea', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    idDocumento : new DbObjectId(Tables.Aprobacion, AprobacionIds.idDocumento, 'Id documento', {foreignTableId: Tables.Documento, foreignKeyFieldId: DocumentoIds.idDocumento}),
    estado : new DbInt(Tables.Aprobacion, AprobacionIds.estado, 'Aprobación'),
    fechaResolucion : new DbDateTime(Tables.Aprobacion, AprobacionIds.fechaResolucion, 'Fecha resolucion'),
    razonRechazo : new DbText(Tables.Aprobacion, AprobacionIds.razonRechazo, 'Razon rechazo', 1000),
})

registerTableFields(Tables.Aprobacion, AprobacionFld) 
