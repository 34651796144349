import TextField from '@material-ui/core/TextField'
import React, {useMemo} from 'react'
import {FieldController} from 'sopix/form/FieldController'
import {getInputLabelProps, getInputProps, useFieldStyle} from 'sopix/form/field-style'
import {doNothing} from 'sopix/utils/misc'
import {DataType, StringCast} from 'sopix/form/form-types'
import NumberFormat from 'react-number-format'
import clsx from 'clsx'
import {getAdornmentInputProps} from 'sopix/form/editor-utils'


export const NumberField = ({manager, className, name, value, dataType=DataType.FLOAT, width: baseWidth, 
                                extraWidth = 0, nullValue=undefined, prefix, suffix, small, 
                                onChange, startAdornment, endAdornment, disabled: disabledBase, 
                                nullify, ...otherProps}) => {
    const disabled = disabledBase || (manager && manager.readOnly)
    const width = baseWidth + extraWidth
    const fieldCls = useFieldStyle({width, small})

    const cast = useMemo(() => new StringCast(dataType, nullValue), [dataType, nullValue])

    const NumberFormatCustom = useMemo(() => {
        return ({name, inputRef, onChange, ...other}) => {

            return (
                <NumberFormat
                    {...other}
                    getInputRef={inputRef}
                    onValueChange={(values) => {
                        onChange({
                            target: {
                                name: name,
                                value: values.value,
                            },
                        })
                    }}
                    thousandSeparator="."
                    isNumericString
                    prefix={prefix}
                    suffix={suffix}
                    decimalSeparator=","
                    decimalScale={dataType === DataType.INT ? 0 : undefined}
                />
            )
        }
    }, [dataType, prefix, suffix])


    if (nullify) {
        return null
    }



    function getHandleChange(controllerOnChange){
        return function(e) {
            const value = e.target.value
            controllerOnChange && controllerOnChange(cast.fromString(value))
        }
    }

    function render({onChange: controllerOnChange, onBlur, value: controllerValue, error}) {
        return (
            <TextField
                {...otherProps}
                className={clsx(fieldCls.field, className)}
                inputProps={{
                    onBlur: onBlur,
                }}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    ...getInputProps(fieldCls, small),
                    ...getAdornmentInputProps(startAdornment, endAdornment),
                }}
                InputLabelProps={getInputLabelProps(fieldCls, small)}
                
                error={error !== undefined}
                helperText={error}

                type="tel"
                value={cast.toString(controllerValue)}
                onChange={getHandleChange(controllerOnChange)}
                disabled={disabled}
            />
        )
    }

    if (manager === undefined) {
        return render(
            {value: value, onBlur: doNothing, onChange: onChange})

    } else {
        return (
            <FieldController
                fieldManager={manager}
                name={name}
                render={render}
            />
        )
    }
}
