 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {StatusIds} from './Status'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const StatusEdt = Object.freeze({
    idStatus : new SimpleEditor(StatusIds.idStatus, 11, 11),
    status : new SimpleEditor(StatusIds.status, 20, 10),
})

tableEditors[Tables.Status] = StatusEdt
