import React from 'react'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {BoolField} from 'sopix/form/editors/BoolField'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {AprobacionFilter} from 'sop/componentes/aprobacion/aprobacion-consts'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {getDropdownBoldLineRender} from 'sop/utils/edit-field-utils'
import {usePiece} from 'sopix/piece/use-piece'


export class AprobacionSearchPiece extends SearchPiece{
}


const idValueDropdownRender =getDropdownBoldLineRender('id', 'value') 

/**
 * 
 * @param {AprobacionSearchPiece} search
 * @returns {JSX.Element}
 * @constructor
 */
export const AprobacionSearch = ({search}) => {

    usePiece(search)


    return (
        <PieceFrame piece={search} renderPiece={()=> {

            const H = search.fieldHelper
            const man = search.fieldManager

            const estadoOptions = [
                {id: AprobacionFilter.ESTADO_PENDIENTES, value: 'Pendientes'},
                {id: AprobacionFilter.ESTADO_FINALIZADOS, value: 'Finalizados'},
            ]

            return (
                <>
                    <Field manager={man} name={AprobacionFilter.BUSCAR} label="buscar" autoFocus/>
                    <DropdownField manager={man} disabled={false} label="Estado"
                        idField="id" displayField="value" options={estadoOptions} name={AprobacionFilter.ESTADO} />
                    <BoolField manager={man} name={AprobacionFilter.SOLICITANTE} label="Solicitadas por mí" />
                    <BoolField manager={man} name={AprobacionFilter.APROBADOR} label="Aprobables por mí" />
                    <Push />
                </>
            )

        }} />
    )
}