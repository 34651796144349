import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {BlRowCell} from 'sopix/boundless-table/BlRowCell'

const useStyles = makeStyles(() => ({
    blRowCell71: {
        maxHeight: 71,
    },
})) 

export function BlRowCell71({children, ...otherProps}) {
    
    const cls = useStyles()
    
    return (
        <BlRowCell className={cls.blRowCell71} {...otherProps}>
            {children}
        </BlRowCell>
    )
}
