 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const ContactoIds = Object.freeze({
    solicitudes: Symbol('solicitudes'),
    direccion: Symbol('direccion'),
    autor: Symbol('autor'),
    modificacion: Symbol('modificacion'),
    idContacto: Symbol('idContacto'),
    idCuenta: Symbol('idCuenta'),
    idDireccion: Symbol('idDireccion'),
    nombre: Symbol('nombre'),
    tel: Symbol('tel'),
    correo: Symbol('correo'),
    notas: Symbol('notas'),
    principal: Symbol('principal'),
    fechaAlta: Symbol('fechaAlta'),
    fechaModificacion: Symbol('fechaModificacion'),
    idAutor: Symbol('idAutor'),
    idModificacion: Symbol('idModificacion'),
    odooCambiado: Symbol('odooCambiado'),
})

export const Contacto = Object.freeze({
    solicitudes: 'solicitudes',
    direccion: 'direccion',
    autor: 'autor',
    modificacion: 'modificacion',
    idContacto: 'idContacto',
    idCuenta: 'idCuenta',
    idDireccion: 'idDireccion',
    nombre: 'nombre',
    tel: 'tel',
    correo: 'correo',
    notas: 'notas',
    principal: 'principal',
    fechaAlta: 'fechaAlta',
    fechaModificacion: 'fechaModificacion',
    idAutor: 'idAutor',
    idModificacion: 'idModificacion',
    odooCambiado: 'odooCambiado',
})
