 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {LiqtecmesIds} from './Liqtecmes'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {UserIds} from './User'

export const LiqtecmesEdt = Object.freeze({
    tecnico : new LookupEditor(LiqtecmesIds.tecnico, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idLiqtecmes : new SimpleEditor(LiqtecmesIds.idLiqtecmes, 11, 11),
    m2 : new SimpleEditor(LiqtecmesIds.m2, 11, 11),
})

tableEditors[Tables.Liqtecmes] = LiqtecmesEdt
