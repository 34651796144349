import {observer} from 'mobx-react'
import {IconButton, Snackbar} from '@material-ui/core'
import React, {useEffect, useRef} from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import {delay} from 'lodash-es'
import {action} from 'mobx'
import PauseIcon from '@material-ui/icons/Pause'
import {Alert} from '@material-ui/lab'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('SnackbarDisplay')

const useStyles = makeStyles(theme => ({
    snackbarMessage: {
        width: 500,
        transition: theme.transitions.create('bottom', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    alertContent: {
        width: '100%',
    },
    alertRoot: {
        position: 'relative',
        width: '100%',
    },
    alertMessage: {
        paddingRight: 50,    
    },
    action: {
        position: 'absolute',
        top: 4,
        right: 4,
    },
    
})) 


const Message = observer(
/**
 *
 * @param {SnackbarNotifications} notifications
 * @param {SnackbarMessage} message
 * @param bottom
 * @param autocloseDelay
 * @returns {JSX.Element}
 */
({notifications, message, bottom, autoCloseDelay}) => {
    const cls = useStyles()
    
    const ref = useRef()


    useEffect(() => {
        action(() => {
            message.height = ref.current.clientHeight
        })()
    })
    
    useEffect(() => {
        action(() => {
            delay(action(() => {
                if (message.autoclose) {
                    message.closeReaction()
                }
            }), autoCloseDelay)

        })()
    }, [autoCloseDelay, message])
    
    
    return (
        <Snackbar
            ref={ref}
            className={cls.snackbarMessage}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            style={{
                bottom: bottom,
            }}
            open={message.open}
            onClose={(event, reason) => {
                if (reason !== 'clickaway') message.closeReaction() 
            }}
        >{
            (() => {
                debug(() => `Entries: ${message.content.length}`)
                const alerts = []
                let i = 0
                for (let entry of message.content) {
                    alerts.push(
                        <Alert
                            key={++i}
                            classes={{message: cls.alertMessage, root: cls.alertRoot}} 
                            severity={entry.level}
                        >
                            {entry.text}
                        </Alert>
                    )
                }
                return <div className={cls.alertContent}>
                    {alerts}
                    <div className={cls.action}>
                        <IconButton size="small" aria-label="mantener abierto" color="inherit"
                                    onClick={message.toggleAutoclose}
                        >
                            <PauseIcon color={message.autoclose ? undefined : 'secondary'} fontSize="small" />
                        </IconButton>
                        <IconButton size="small" aria-label="cerrar" color="inherit" onClick={message.closeReaction}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            })()
        }
        </Snackbar>
    )
})


export const SnackbarDisplay = observer(
/**
 *
 * @param {SnackbarNotifications} notifications
 * @param delay
 */
({notifications, delay=5000}) => {

    let bottom = 24
    const snackbars = []
    for(let message of [...notifications.messages.values()].reverse()){
        if (!message.mustPaint) continue
        
        snackbars.push(
            <Message key={message.id} notifications={notifications} message={message} bottom={bottom} 
                     autoCloseDelay={delay}/>
        )
        bottom += 16 + (message.height ? message.height : 48) 
    }
    
    return <>{snackbars}</>
})