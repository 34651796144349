import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {Contacto} from 'sop/db/Contacto'
import {ContactoFld, ContactoTable} from 'sop/db/ContactoTable'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {ContactoCol} from 'sop/db/ContactoColumns'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {boundMethod} from 'autobind-decorator'
import {HintIconButton, ICON_BUTTON_SIZE, ICON_SIZE} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {ContactoForm, ContactoFormPiece} from 'sop/componentes/cuenta/ContactoForm'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {RowDeleter} from 'sopix/db-access/rowDeleter'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {DireccionFld} from 'sop/db/DireccionTable'
import {DireccionCol} from 'sop/db/DireccionColumns'
import {Direccion} from 'sop/db/Direccion'
import {Bar} from 'sopix/formComps/Bar'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {Push} from 'sopix/formComps/Push'
import {TableFiltrator} from 'sopix/filtrator/tableFiltrator'
import {computed} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {linkSubsetDbFiltratorForm} from 'sopix/piece-linkers/subsetdb-filtrator-form'
import {Tag} from 'sopix/formComps/Tag'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {ALERT_CANCEL, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'


const LEFT_BUTTONS = 'LEFT_BUTTONS'
const RIGHT_BUTTONS = 'RIGHT_BUTTONS'
const FILTER_DIRECCION = 'DIRECCION'

export class ContactoPainter extends TablePainter{

    /**
     * @param {ContactoListPiece} list
     */
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global: list}) {
        const asa = list._asyncAction
        switch (id){
            case LEFT_BUTTONS:
                return !userAuth.isAllowed(Permiso.modificarCuentas) ? null :
                    <HintIconButton Icon={icons.Edit} title="Editar" onClick={() => asa(list.poper.edit)(rowId)} />
            case RIGHT_BUTTONS:
                return !userAuth.isAllowed(Permiso.modificarCuentas) ? null :
                    <HintIconButton Icon={icons.Delete} title="Eliminar" onClick={() => asa(list.deleteClicked)(rowId)} />
            case Contacto.principal:
                return !value
                    ? <HintIcon color="primary" Icon={icons.StarDisabled} onClick={
                        !userAuth.isAllowed(Permiso.modificarCuentas) ? undefined :
                            () => asa(list.hacerPrincipal)(rowId)
                    } />
                    : <HintIcon color="primary" Icon={icons.Star} />

            case Contacto.direccion:
                return fld(Direccion.direccion)
            default:    
                return super._paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global})
        }
    }

    _fixColumn(col, id, dbCol) {
        switch(id){
            case LEFT_BUTTONS:
            case RIGHT_BUTTONS:
                col.minWidth = col.maxWidth = ICON_BUTTON_SIZE
                col.props['noMargin'] = true
                col.props['onlyHover'] = true
                col.headerProps['noMargin'] = true
                break
            case Direccion.principal:
                col.minWidth = col.maxWidth = ICON_SIZE
                break
            case Contacto.nombre:
                col.title = 'Contacto'
                break
            default:
                super._fixColumn(col, id, dbCol)
        }
    }
}


export class ContactoFiltrator extends TableFiltrator{

    @computed
    get filterDireccion() {
        return this.currentFilter[FILTER_DIRECCION]
    }

    @boundMethod
    async setFilterDireccion(direccion) {
        await this.modifyCurrentFilter({[FILTER_DIRECCION]: direccion})
        await this.refresh()
    }

    @boundMethod
    _filterData(data, filter) {
        const flt = getFieldExtractor(filter)
        
        const dirFilter = flt(FILTER_DIRECCION)
        if (!dirFilter) {
            return data
        }
        
        const idDireccion = dirFilter[Direccion.idDireccion]
        const result = []
        for (let contacto of data){
            if (contacto[Contacto.idDireccion] === idDireccion){
                result.push(contacto)
            }
        }
        
        return result
    }
}


export class ContactoListPiece extends Piece{
    
    idField = Contacto.idContacto

    setContactoPrincipal = new SimpleMutation(graphqlUrl, 'SetContactoPrincipal')


    constructor(world) {
        super(world)
        
        const COLUMNS = [
            LEFT_BUTTONS,
            ContactoCol.principal,
            ContactoCol.nombre,
            ContactoCol.tel,
            ContactoCol.correo,
            [ContactoFld.direccion, DireccionCol.direccion],
            RIGHT_BUTTONS,
        ]
        
        const FIELDS = [
            ContactoFld.principal,
            ContactoFld.idContacto,
            ContactoFld.nombre,
            ContactoFld.tel,
            ContactoFld.correo,
            ContactoFld.idDireccion,
            [ContactoFld.direccion, [DireccionFld.direccion]]
        ]

        this.painter = new ContactoPainter(world, COLUMNS)
        
        this.dbSource = new SubsetDbSource(world,
            Contacto.idCuenta,
            new TableGraphql(ContactoTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(Contacto.idContacto, OrderDirection.DESC)},
            })
        
        this.load = this._state(Symbol('LOAD'), this.dbSource.loadSubset)
        
        this.form = new ContactoFormPiece(this.world.cloneWithOwnErrors())
        this.poper = new FormPoper(this.form)
        
        this.deleter = new RowDeleter(this.world, ContactoTable, 'DeleteContacto')
        
        this.filtrator = new ContactoFiltrator(this.world, this.dbSource, this.idField, COLUMNS, {
            defaultOrder: [new OrderEntry(Contacto.idContacto, OrderDirection.DESC)],
        })

        this.selection = new RowSelection(world, this.filtrator, Contacto.idContacto)

        linkSubsetDbFiltratorForm(this.dbSource, this.filtrator, this.form)
        
        this.deleter.onDelete.subscribe(async id => {
            await this.dbSource.refresh()
            this.selection.rowDeleted(id)
        })
        
        this.filtrator.onSetData.subscribe(this.selection.listRefreshed)
    }

    @boundMethod
    async hacerPrincipal(id){
        await this.setContactoPrincipal.query({id: id})
        await this.dbSource.refresh()
    }

    @boundMethod
    contactoClicked({data}){
        const id = data[Contacto.idContacto]
        this.selection.boundlessClicked({data})
    }
    
    @boundMethod
    async deleteClicked(id){
        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            `¿Eliminar dirección?`,
            `Se eliminará la dirección.`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
        ],))) {
            await this.deleter.delete(id)
        }
    }
    
    @boundMethod
    async newClicked(){
        await this.poper.createWith({
            [Contacto.idCuenta]: this.dbSource.parentId,
            [Contacto.idDireccion]: this.filtrator.filterDireccion[Direccion.idDireccion],
        })
    }
}


export const ContactoList = getPieceContainer('list', observer(
    /**
     * @param {ContactoListPiece} list
     */
    ({list}) => {

        const asa = list._asyncAction 
        const sya = list._action
        
        const dirFilter = list.filtrator.filterDireccion
        const direccion = getFieldExtractor(dirFilter)(Direccion.direccion)
        
        return (
            <>
                <Bar startGap>
                    <HintIcon Icon={sopIcons.Contacto} title="Contactos" />
                    <Push/>
                    {!direccion ? null :
                        <Tag label={direccion} variant="outlined" />
                    }
                    {!userAuth.isAllowed(Permiso.modificarCuentas) ? null :
                        <HintIconButton disabled={!direccion} Icon={sopIcons.Contacto} badge="+" badgeColor={sopColors.badgeAdd}
                                        onClick={asa(list.newClicked)}
                                        title="Añadir nuevo contacto"
                        />
                    }
                </Bar>
                <Frame>
                    <BlTable fitContainer dense
                             global={list}
                             verticalAlign="center"
                             data={list.filtrator.data}
                             keyColumnId={list.idField}
                             columns={list.painter.boundlessColumns}
                             renderCellContent={sya(list.painter.boundlessPainter)}
                             renderHeaderContent={sya(list.painter.headerPainter)}
                             onClick={sya(list.contactoClicked)}
                             selected={list.selection.id}
                    />
                    <PoperFrame poper={list.poper} >
                        <ContactoForm form={list.form} />
                    </PoperFrame>
                </Frame>
            </>
        )
}))
