export const Direction = Object.freeze({
    ROW: 'row',
    ROW_REVERSE: 'rowReverse',
    COLUMN: 'column',
    COLUMN_REVERSE: 'columnReverse',
})
export const Edge = Object.freeze({
    START: 'start',
    END: 'end',
})

export function getTransposedDirection(/* Direction */ direction, /* Edge */ edge){
    const vertical = direction === Direction.COLUMN || direction === Direction.COLUMN_REVERSE
    
    return vertical
        ? (edge === Edge.END ? 'rowReverse' : 'row')
        : (edge === Edge.END ? 'columnReverse' : 'column')
}
