import {FormPoper} from 'sopix/piece-objects/formPoper'
import {boundMethod} from 'autobind-decorator'
import {userAuth} from 'sopix/session/userAuth'
import {AprobacionEstado} from 'sop/componentes/aprobacion/aprobacion-consts'
import {Aprobacion} from 'sop/db/Aprobacion'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import React from 'react'
import {action, observable} from 'mobx'

const APRO_APROBACION_FIELD = 'aprobacion'


export class AproPoper extends FormPoper{

    /** @type {AproFormPiece} */
    form

    objectFieldName

    /** @type {DbObject} */    
    aproField
    
    /** @type {DbObject} */
    aproBackField
    
    /** @type {function} */
    getObject
    
    @observable
    editingObj
    
    /**
     * @param {AproPoper} poper
     * @param {DbObject} aproField
     * @param {DbObject} aproBackField
     * @param {AproFormPiece} form
     */
    constructor(form, objectFieldName, aproField, aproBackField, getObjectFunc, {
        extraWidth=40, textoCrear = "Solicitar cambio", 
        textoEditar = "Editar solicitud de cambio", textoSolicitado = "Cambio solicitado",
    } = {}) {
        super(form)
        this.objectFieldName = objectFieldName
        this.aproField = aproField
        this.aproBackField = aproBackField
        this.getObject = getObjectFunc
        
        this.extraWidth = extraWidth
        this.textoCrear = textoCrear
        this.textoEditar = textoEditar
        this.textoSolicitado = textoSolicitado
    }

    @action.bound
    _setEditingObj(obj){
        this.editingObj = obj
    }
    
    getEditDetails(obj) {
        const fld = getFieldExtractor(obj)

        const id = fld(this.objectFieldName)
        const idApro = fld(this.aproField.idField.fieldName)
        const aprobacion = fld(this.aproField.fieldName, APRO_APROBACION_FIELD)
        const estado = fld(this.aproField.fieldName, APRO_APROBACION_FIELD, Aprobacion.estado)

        const form = this.form

        const sePuedeSolicitar = !form.permisoSolicitar || userAuth.isAllowed(form.permisoSolicitar)
        const isActive = !! (aprobacion && (estado === AprobacionEstado.PENDIENTE || estado === AprobacionEstado.NECESITA_CORRECCION))
        const isEditable = !! (aprobacion && (estado === AprobacionEstado.PENDIENTE) && sePuedeSolicitar)
        const isVisible = !!id

        const editClicked = () => {
            this._setEditingObj(obj)
            this.__edit(idApro)
        }

        const createClicked = () => {
            this._setEditingObj(obj)
            this.__createWith({
                [this.aproBackField.idField.fieldName]: id,
                [this.aproBackField.fieldName]: obj,
            })
        }

        return {isActive, isEditable, isVisible, edit: editClicked, create: createClicked}
    }

    get editDetails(){
        const obj = this.getObject()
        return this.getEditDetails(obj)
    }

    get buttonProps(){
        const obj = this.getObject()
        return this.getButtonProps(obj)
    }

    getButtonProps(obj, {disabled} = {}){
        const {isActive, isEditable, isVisible, edit, create} = this.getEditDetails(obj)
        const form = this.form

        let aproButtons
        let aproestaText
        if (!isActive) {
            aproButtons = <HintIconButton Icon={form.icon} disabled={disabled} title={this.textoCrear} 
                                          onClick={create} />
        } else {
            aproestaText = this.textoSolicitado
            aproButtons =<HintIconButton Icon={form.icon} disabled={!isEditable || disabled}
                                             title={this.textoEditar} color="secondary" onClick={edit} />
        }

        return {
            endAdornment: !isVisible ? undefined : aproButtons,
            extraWidth: !isVisible ? undefined : this.extraWidth,
            text: aproestaText,
        }
    }
    
    @boundMethod
    async anular(){
        await this.form.anular({save: false})
        await this.close({force: true})
    }
    
}