import {action, computed, observable} from 'mobx'
import {sopIcons} from 'sop/sop-icons'
import {FieldsState} from 'sopix/react-state/fieldsState'
import {AuthUser, userAuth} from 'sopix/session/userAuth'
import {boundMethod} from 'autobind-decorator'
import {graphqlUrl} from 'sop/db-config/db'
import {pbkdf2Async} from 'sopix/hash/pbkdf2'
import {fromByteArray} from 'base64-js'
import {FieldsMutation} from 'sopix/db-access/fieldsMutation'
import {PermisoTable} from 'sop/db/PermisoTable'
import {isEmpty} from 'lodash-es'

export const MIN_USER_LEN = 3
export const MIN_PASSWORD_LEN = 6
export const MIN_RESET_TOKEN_LEN = 6

export const OTP_LEN = 6

const HASH_SALT = 'polythermsop2salt'
const HASH_ITER_COUNT = 100000
const HASH_KEY_LEN = 32
const HASH_ALGORITHM = 'sha512'

export const LoginScreen = Object.freeze({
    LOGIN: Symbol('login'),
    LOGIN_TOTP: Symbol('login totp'),
    RESET: Symbol('reset'),
    ASK_OTP_SECRET: Symbol('ask otp'),
    ASK_OTP_SECRET_SUCCESS: Symbol('ask otp success'),
    RESET_DONE: Symbol('reset done'),
    ACCESS_RESTRICTED: Symbol('access restricted'),
})

export const PasswordFld = Object.freeze({
    USERNAME: 'username',
    PASSWORD: 'password',
    OTP: 'otp',
    RESET_KEY: 'resetKey',
    NEW_PASSWORD: 'newPassword',
    NEW_PASSWORD2: 'newPassword2',
    PBKDF2: 'pbkdf2',
})

const LOGIN_RESULT = ['userId', 'username', {'permisos': PermisoTable.regularFieldNames}, 'permisoList', 
    'expira', 'role', 'knownDevice']

export class LoginState extends FieldsState{
    
    _resetPasswordMutation = new FieldsMutation(graphqlUrl, 'ResetPassword', ['totpSecret'])
    _loginMutation = new FieldsMutation(graphqlUrl, 'Login', LOGIN_RESULT)
    _loginStatusMutation = new FieldsMutation(graphqlUrl, 'LoginStatus'
        , LOGIN_RESULT)
    
    constructor() {
        super('Login', sopIcons.Usuario);
    }

    async __init() {
        if (userAuth.userId === AuthUser.UNKNOWN) {
            const {success, userId, username, permisos, permisoList, expira, role, knownDevice} =
                await this._loginStatusMutation.query()

            if (success) {
                const auth = userAuth.update(userId, username, permisos, permisoList, expira, role, knownDevice)
            }
        }

        this.setLoginScreen()
    }

    @observable
    screen = LoginScreen.LOGIN

    totpSecret = null
    
    @computed
    get title(){
        switch(this.screen){
            case LoginScreen.RESET:
                return 'Reiniciar contraseña'
            case LoginScreen.ASK_OTP_SECRET:
                return 'Configurar Google Authenticator'
            case LoginScreen.ASK_OTP_SECRET_SUCCESS:
                return 'Instrucciones Authenticator'
            case LoginScreen.LOGIN_TOTP:
                return 'Validar dispositivo'
            case LoginScreen.ACCESS_RESTRICTED:
                return 'Acceso no permitido'
            case LoginScreen.RESET_DONE:
                return 'Contraseña cambiada'
            default:
                return 'Iniciar sesión'
        }
    }
    




    @computed
    get userEmpty(){
        const user = this.getField(PasswordFld.USERNAME)
        return !(user && user !== '')
    }
    
    @action
    setScreen(screen){
        this.screen = screen 
    }

    @action
    setLoginScreen(){
        const userId = userAuth.userId
        if (userId !== AuthUser.ANONYMOUS) {
            this.setScreen(!userAuth.knownDevice ? LoginScreen.LOGIN_TOTP : LoginScreen.ACCESS_RESTRICTED)
        } else {
            this.setScreen(LoginScreen.LOGIN)
        }
    }

    async _calcPbkdf2Mime64(pass){
        const pbkdf2 = await pbkdf2Async(pass, HASH_SALT, HASH_ITER_COUNT, HASH_KEY_LEN, HASH_ALGORITHM)
        return fromByteArray(pbkdf2)
    }




    _checkLogin(){
        const pass = this.getField(PasswordFld.PASSWORD)

        // Chequear
        const errors = {}
        if (!this.getField(PasswordFld.USERNAME)) {
            errors[PasswordFld.USERNAME] = 'Completa este campo'
        }
        if (!pass) {
            errors[PasswordFld.PASSWORD] = 'Completa este campo'
        }

        if (!isEmpty(errors)) {
            this.fieldManager.setErrors(errors)
            return
        }

        return true
    }
    @computed
    get loginAllowed(){
        return true
    }
    @boundMethod
    async _login(){
        if (!this._checkLogin()) return

        const pass = this.getField(PasswordFld.PASSWORD)
        const pbkdf2Mime64 = await this._calcPbkdf2Mime64(pass)

        const {success, errorList, userId, username, permisos, permisoList, expira, role, knownDevice}
            =  await this._loginMutation.query({
                [PasswordFld.USERNAME]: this.getField(PasswordFld.USERNAME),
                [PasswordFld.PBKDF2]: pbkdf2Mime64,
            })

        if (success){
            userAuth.update(userId, username, permisos, permisoList, expira, role, knownDevice)
            this.setScreen(!userAuth.knownDevice ? LoginScreen.LOGIN_TOTP : LoginScreen.ACCESS_RESTRICTED)
        } else {
            this.fieldManager.setValue(PasswordFld.PASSWORD, undefined)
            this.fieldManager.setErrors(errorList)
        }
    }
    login = this._locked_catched_async(this._login)





    @computed
    get otpLoginAllowed(){
        const otp = this.getField(PasswordFld.OTP)
        return otp && otp.length >= OTP_LEN
    }
    @boundMethod
    async _loginOtp(){
        if (!this.otpLoginAllowed) return

        const pass = this.getField(PasswordFld.PASSWORD)
        let pbkdf2Mime64 = null
        if (pass) {
            pbkdf2Mime64 = await this._calcPbkdf2Mime64(pass)
        }

        const {success, errorList, userId, username, permisos, permisoList, expira, role, knownDevice}
            =  await this._loginMutation.query({
            [PasswordFld.USERNAME]: this.getField(PasswordFld.USERNAME) || userAuth.username,
            [PasswordFld.PBKDF2]: pbkdf2Mime64,
            [PasswordFld.OTP]: this.getField(PasswordFld.OTP),
        })

        if (success){
            userAuth.update(userId, username, permisos, permisoList, expira, role, knownDevice)
            this.setScreen(!userAuth.knownDevice ? LoginScreen.LOGIN_TOTP : LoginScreen.ACCESS_RESTRICTED)
        } else {
            this.fieldManager.setErrors(errorList)
        }
    }
    loginOtp = this._locked_catched_async(this._loginOtp)




    _checkReset(){
        const user = this.getField(PasswordFld.USERNAME)
        const resetKey = this.getField(PasswordFld.RESET_KEY)
        const newPassword = this.getField(PasswordFld.NEW_PASSWORD)
        const newPassword2 = this.getField(PasswordFld.NEW_PASSWORD2)

        const errors = {}

        if (!user){
            errors[PasswordFld.USERNAME] = 'Completa este campo'
        }

        if (!newPassword){
            errors[PasswordFld.NEW_PASSWORD] = 'Completa este campo'
        } else if (newPassword.length < MIN_PASSWORD_LEN){
            errors[PasswordFld.NEW_PASSWORD] = 'La contraseña debe tener al menos 8 caracteres'
        }

        if (!newPassword2){
            errors[PasswordFld.NEW_PASSWORD2] = 'Completa este campo'

        } else if (newPassword !== newPassword2){
            errors[PasswordFld.NEW_PASSWORD2] = 'Las contraseñas no coinciden'
        }

        if (!resetKey) {
            errors[PasswordFld.RESET_KEY] = 'Completa este campo'
        }

        if (!isEmpty(errors)) {
            this.fieldManager.setErrors(errors)
            return false
        }

        return true
    }

    @computed
    get resetAllowed(){
        return true
    }

    @boundMethod
    async _reset(){
        if (!this.resetAllowed) return
        if (!this._checkReset()) return

        const newPass = this.getField(PasswordFld.NEW_PASSWORD)
        
        const pbkdf2Mime64 = await this._calcPbkdf2Mime64(newPass)
        
        const {success, errorList, totpSecret} = await this._resetPasswordMutation.query({
            [PasswordFld.USERNAME]: this.getField(PasswordFld.USERNAME),
            [PasswordFld.RESET_KEY]: this.getField(PasswordFld.RESET_KEY),
            [PasswordFld.PBKDF2]: pbkdf2Mime64,
        })
        this.totpSecret = totpSecret
        
        if (success){
            this.setScreen(LoginScreen.RESET_DONE)
        } else {
            this.fieldManager.setErrors(errorList)
        }
    }
    reset = this._locked_catched_async(this._reset)





    _checkAskOtp(){
        const user = this.getField(PasswordFld.USERNAME)
        const resetKey = this.getField(PasswordFld.RESET_KEY)

        const errors = {}

        if (!user){
            errors[PasswordFld.USERNAME] = 'Completa este campo'
        }

        if (!resetKey) {
            errors[PasswordFld.RESET_KEY] = 'Completa este campo'
        }

        if (!isEmpty(errors)) {
            this.fieldManager.setErrors(errors)
            return false
        }

        return true
    }
    @computed
    get askOtpAllowed(){
        return true
    }
    @boundMethod
    async _askOtpSecret(){
        if (!this.askOtpAllowed) return
        if (!this._checkAskOtp()) return

        const {success, errorList, totpSecret} = await this._resetPasswordMutation.query({
            [PasswordFld.USERNAME]: this.getField(PasswordFld.USERNAME),
            [PasswordFld.RESET_KEY]: this.getField(PasswordFld.RESET_KEY),
        })
        this.totpSecret = totpSecret

        if (success){
            this.setScreen(LoginScreen.ASK_OTP_SECRET_SUCCESS)
        } else {
            this.fieldManager.setErrors(errorList)
        }
    }
    askOtpSecret = this._locked_catched_async(this._askOtpSecret)

}