 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const LiquidacionIds = Object.freeze({
    autor: Symbol('autor'),
    responsable: Symbol('responsable'),
    tarea: Symbol('tarea'),
    idLiquidacion: Symbol('idLiquidacion'),
    fechaCreacion: Symbol('fechaCreacion'),
    idAutor: Symbol('idAutor'),
    fecha: Symbol('fecha'),
    m2Efectivos: Symbol('m2Efectivos'),
    idResponsable: Symbol('idResponsable'),
    idTarea: Symbol('idTarea'),
    descripcion: Symbol('descripcion'),
})

export const Liquidacion = Object.freeze({
    autor: 'autor',
    responsable: 'responsable',
    tarea: 'tarea',
    idLiquidacion: 'idLiquidacion',
    fechaCreacion: 'fechaCreacion',
    idAutor: 'idAutor',
    fecha: 'fecha',
    m2Efectivos: 'm2Efectivos',
    idResponsable: 'idResponsable',
    idTarea: 'idTarea',
    descripcion: 'descripcion',
})
