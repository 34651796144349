import {DbSource} from 'sopix/data-source/dbSource'
import {ObserverHub} from 'sopix/utils/observers'
import {action, observable} from 'mobx'
import {DEFAULT_MAX_LIST_ROWS} from 'sopix/data-source/data-source-defs'

export class SubsetDbSource extends DbSource{

    @observable
    parentId
    parentIdField

    onParentIdChange = new ObserverHub()
    
    constructor(world, parentIdField, graphqlEndPoint, fieldDescriptors, 
                {defaultGraphqlFilter, defaultGraphqlParams} = {}) {
        super(world, graphqlEndPoint, fieldDescriptors, {
            defaultGraphqlFilter: defaultGraphqlFilter,
            defaultGraphqlParams: {first: DEFAULT_MAX_LIST_ROWS, ...defaultGraphqlParams},
        })
        this.parentIdField = parentIdField
    }

    @action.bound
    async clearData() {
        this.parentId = undefined
        await super.clearData()
    }

    @action.bound
    async loadSubset(parentId){
        if (this.parentId === parentId) return
        
        this.parentId = parentId
        this.onParentIdChange.notify(parentId)
        
        if (!parentId) {
            await this.clearData()
        } else {
            this._setGraphqlFilter({...this.graphqlFilter, [this.parentIdField]: parentId})
            await this.refresh()
        }
    }
}
