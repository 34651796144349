import {makeStyles} from '@material-ui/core/styles'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {observer} from 'mobx-react'
import React, {useEffect, useRef} from 'react'


const useStyles = makeStyles(() => ({
    chartFrame: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        padding: 25,
    },

    chartContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    }
}))

export const ChartFrame = getPieceContainer('chart', observer(
    /**
     * @param {ChartPiece} chart
     */
    ({chart}) => {

        const cls = useStyles()

        const ref = useRef()

        useEffect(() => {
            if (!ref.current) return

            chart.__registerCanvas(ref.current)

        }, [chart])

        return (
            <div className={cls.chartFrame}>
                <div className={cls.chartContainer} style={{height: chart.height}} >
                    <canvas ref={ref} />
                </div>
            </div>
        )
    }))
