import React, {useRef} from 'react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {observer} from 'mobx-react'
import {FormPiece} from 'sopix/pieces/formPiece'
import {Field} from 'sopix/form/editors/Field'
import {UserEdt} from 'sop/db/UserEditors'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {RolesUsuario, UserRoles} from 'sop/db-config/row-consts'
import {User} from 'sop/db/User'
import {BoolField} from 'sopix/form/editors/BoolField'
import {sopIcons} from 'sop/sop-icons'
import {UserFld, UserTable} from 'sop/db/UserTable'
import {ListFetcher, ListQueryEntry} from 'sopix/db-access/listFetcher'
import {USER_FIELDS} from 'sop/db-config/fieldSets'
import {action, observable, runInAction} from 'mobx'
import {Fields} from 'sopix/formComps/Fields'
import {Wrap} from 'sopix/formComps/Wrap'
import {fromByteArray} from 'base64-js'
import {DateField} from 'sopix/form/editors/DateField'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {PermisoFld, PermisoTable} from 'sop/db/PermisoTable'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {Typography} from "@material-ui/core";


export const PERMISO_IDS = Object.freeze({
    name: 'permisoIds',
    label: 'Permisos',
    idField: Permiso.idPermiso,
    displayField: Permiso.permiso,
})



export class UsuarioFormPiece extends FormPiece{

    _listFetcher = new ListFetcher([
        UserEdt.roleUsuarioObj.getListQueryEntry(),
        new ListQueryEntry(
            {sort: calcGraphqlOrder(Permiso.idPermiso)},
            [PermisoFld.idPermiso, PermisoFld.permiso]
        ),
    ])

    
    constructor(world) {
        
        const TABLE = UserTable
        
        const FIELDS = [...USER_FIELDS, UserFld.resetKey, UserFld.apiKey, UserFld.totpRequerido]
        
        super(world,
            FIELDS,
            {
                name: 'Usuario',
                icon: sopIcons.User,
                table: TABLE,
                idField: 'id',
                displayField: User.nombreCompleto,
                deleteMutation: 'DeleteUser',
                saveMutation: 'SaveUser',
                extraFields: [PERMISO_IDS.name],
            },
        )
    }

    async _init() {
        //Quitamos el init para que no valide hasta que se cargue algo
        //await super._init()
        const lists = await this._listFetcher.fetch()
        runInAction(()=>{
            this.lists = lists
        })
    }

    async _applyRow(data) {
        
        const permisos = data[User.permisos]
        
        data[PERMISO_IDS.name] = !permisos ? [] : permisos.map(per => per.idPermiso)
        
        await super._applyRow(data)
    }

    get resetPass(){
        return this._resetPass
    }
    
    @action.bound
    async _setResetPass(enabled){

        if (!enabled) {
            this.setField(User.resetKey, undefined)
        
        } else {
            const array = new Uint8Array(16)
            crypto.getRandomValues(array)

            this.setField(User.resetKey, fromByteArray(array))
        }
        
        await this.save()
    }
    setResetPass = this._asyncAction(this._setResetPass)


    @action.bound
    async _setApiKey(enabled){

        if (!enabled) {
            this.setField(User.apiKey, undefined)

        } else {
            const array = new Uint8Array(16)
            crypto.getRandomValues(array)

            this.setField(User.apiKey, fromByteArray(array))
        }
    }
    setApiKey = this._asyncAction(this._setApiKey)



    @action.bound
    async _setOptRequerido(enabled){
        this.setField(User.totpRequerido, enabled ? 1 : undefined)

        if (enabled) {
            await this._setResetPass(true)
        }
    }
    setOptRequerido = this._asyncAction(this._setOptRequerido)


}



export const UsuarioForm = getPieceContainer('form', observer(
    /**
     * @param {UsuarioFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        const fld = form.getField

        const userNameRef = useRef()

        form.focus = () => {
            if (userNameRef.current) {
                userNameRef.current.focus()
            }
        }


        const permisoList = form.lists[PermisoTable.graphqlId]

        const puedeModificar = userAuth.isAllowed(Permiso.modificarUsuarios) || userAuth.userId === fld(User.id)
        const role = fld(User.roleUsuario)

        const adminPermisos = userAuth.isAllowed(Permiso.modificarUsuariosAdmin) || 
            (userAuth.isAllowed(Permiso.permisosRepresentantes) && role === RolesUsuario.REPRESENTANTE)
        
        const props = puedeModificar ? {} : {disabled: true} 
        
        return (
            <Fields>
                <Field inputRef={userNameRef} {...{...props, ...H.edt(UserEdt.username)}} />
                <DropdownField {...H.edt(UserEdt.roleUsuarioObj)} />
                <DropdownField multiple disabled={!adminPermisos}
                               manager={form.fieldManager}
                               {...PERMISO_IDS}
                               options={!permisoList ? [] : permisoList.rows}
                />
                <Field {...{...props, ...H.edt(UserEdt.nombreCompleto)}} />
                <Field {...{...props, ...H.edt(UserEdt.email)}} />
                <Field {...{...props, ...H.edt(UserEdt.movil)}} />
                {![UserRoles.REPRESENTANTE, UserRoles.COMERCIAL].includes(fld(User.roleUsuario)) ? null :
                    <Field {...H.edt(UserEdt.zona)} />}

                <Wrap />
                <BoolField {...{...props, ...H.edt(UserEdt.ausente)}} />
                {!fld(User.ausente) ? null :
                    <>
                        <DateField {...{...props, ...H.edt(UserEdt.ausenteDesde)}} />
                        <DateField {...{...props, ...H.edt(UserEdt.ausenteHasta)}} />
                    </>
                }

                <separator />
                <Wrap />
                <BoolField {...H.edt(UserEdt.inactivo)} />
                <Wrap />
                <Typography color="textSecondary">
                    Las cuentas inactivas no pueden iniciar sesión ni operar con SOP. La desactivación es inmediata
                    aunque tenga sesión iniciada.
                </Typography>


                {!userAuth.isAllowed(Permiso.verUsuariosAdmin) ? null :
                    <>
                        <separator />
                        <Wrap />
                        <BoolField label="Activar clave de reinicio" value={!!fld(User.resetKey)}
                                   onChange={form.setResetPass}/>
                        {!fld(User.resetKey) ? null :
                            <>
                                <Wrap />
                                <Field disabled {...H.edt(UserEdt.resetKey)} />
                            </>
                        }
                        <Wrap />
                        <Typography color="textSecondary">
                            La clave de reinicio permite al usuario:
                            <ol>
                                <li>Cambiar su contraseña.</li>
                                <li>Configurar Google Authenticator.</li>
                            </ol>
                        </Typography>
                    </>
                }

                {!userAuth.isAllowed(Permiso.verUsuariosAdmin) ? null :
                    <>
                        <separator />
                        <Wrap />
                        <BoolField label="Clave API" value={!!fld(User.apiKey)}
                                   onChange={form.setApiKey}/>
                        {!fld(User.apiKey) ? null :
                            <>
                                <Wrap />
                                <Field {...H.edt(UserEdt.apiKey)} />
                            </>
                        }
                        <Wrap />
                        <Typography color="textSecondary">
                            La clave API permite actualizar en SOP los contactos modificados en Odoo. Cada vez que la
                            actives se generará una nueva clave que tendrás que copiar en la configuración del usuario
                            en Odoo.
                        </Typography>
                    </>
                }

                <separator />
                <Wrap />
                <BoolField label="Verificación en 2 pasos" value={!!fld(User.totpRequerido)} onChange={form.setOptRequerido} />
                <Wrap />
                <Typography color="textSecondary">
                    La verificación en 2 pasos obliga al usuario a validar cada nuevo dispositivo con una clave
                    generada en el móvil por la app Google Authenticator.
                </Typography>
                <Typography color="textSecondary">
                    <strong>Atención</strong>: si activas esto en tu usuario perderás inmediatamente el acceso a SOP.
                    Asegúrate de copiar la clave de reinicio antes de hacerlo.
                </Typography>
            </Fields>
        )
}))
