import React from 'react'
import {InitProgress} from 'sopix/progress/InitProgress'
import {InitState} from 'sopix/utils/InitState'

/**
 * 
 * @param {InitState} initialized
 * @param onReplay
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const WithInitProgress = ({initialized, onReplay, children}) => {
    if (initialized !== InitState.YES) {
        return <InitProgress initialized={initialized} onReplay={onReplay} />
    } else {
        return <>{children}</>
    }
}
