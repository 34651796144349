import {FieldsState} from 'sopix/react-state/fieldsState'
import {sopIcons} from 'sop/sop-icons'
import {boundMethod} from 'autobind-decorator'
import {FieldsMutation} from 'sopix/db-access/fieldsMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {userAuth} from 'sopix/session/userAuth'

export class SesionPageState extends FieldsState{
    constructor() {
        super('Sesión', sopIcons.Sesion);
    }

    _logoutMutation = new FieldsMutation(graphqlUrl, 'Logout')
    
    
    @boundMethod
    async _logout(){
        const {success} = await this._logoutMutation.query()
        if (success) {
            userAuth.logout()
        }
    }
    logout = this._locked_catched_async(this._logout)
}