 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {PaisIds} from './Pais'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const PaisCol = Object.freeze({
    idPais : new SimpleColumn(PaisIds.idPais, 11, 11, Align.RIGHT),
    iso : new SimpleColumn(PaisIds.iso, 2, 2, Align.LEFT),
    nombre : new SimpleColumn(PaisIds.nombre, 40, 16, Align.LEFT),
})

tableColumns[Tables.Pais] = PaisCol
