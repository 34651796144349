import React, {useEffect} from 'react'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {makeStyles} from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {icons} from 'sopix/icon/icons'
import {observer} from 'mobx-react'
import {getLog} from 'sopix/log'
import {Representante} from 'sop/db/Representante'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import List from '@material-ui/core/List'
import {useAlert} from 'sopix/alert/use-alert'
import {Bar} from 'sopix/formComps/Bar'
import {Frame} from 'sopix/formComps/Frame'
import {ListItem} from '@material-ui/core'
import {getColumnTextFormatter} from 'sopix/db/db-column-utils'
import {RepresentanteCol} from 'sop/db/RepresentanteColumns'
import {User} from 'sop/db/User'
import {MicroText, MicroTextLine, Text} from 'sopix/textComps/textComps'
import {sopColors} from 'sop/sop-colors'
import {action} from 'mobx'
import {Cuenta} from 'sop/db/Cuenta'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {getTransposedDirection} from 'sopix/formComps/comp-classes'
import clsx from 'clsx'
import {sopIcons} from 'sop/sop-icons'
import {getUrlFromRoute} from 'sopix/router/routerUtils'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {pushUrl} from 'sopix/router/router'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'

const log = getLog('RepresentanteList')

const fechaFormatter = getColumnTextFormatter(RepresentanteCol.fechaCreacion)



const entryStyles = makeStyles(theme => ({
    listItemSecondaryAction: {
        visibility: "hidden",
    },

    listItemIcon: {
        visibility: "hidden",
    },

    listItem: {
        "&:hover $listItemSecondaryAction": {
            visibility: "inherit",
        },
        "&:hover $listItemIcon": {
            visibility: "inherit",
        },
    },
    representanteText: {
    },
    candidatoText: {
        color: theme.palette.action.disabled,    
    },
    
    zonaRepresentada: {
        fontWeight: 'bold',
    },
    zonaObra: {
        color: theme.palette.secondary.main,
    },
}))


const Entry = observer(
    /**
     * @param selected
     * @param {RepresentanteListState} representanteList
     * @param {Candidato} candidatoProp
     * @param onDelete
     * @param onAdd
     * @param listItemProps
     * @returns {JSX.Element}
     */
    ({selected, representanteList, candidato: candidatoProp, onDelete, onAdd, ...listItemProps}) => {

        const cls = entryStyles()
        
        
        const {candidato, representante, cuentas, obra} = candidatoProp
        
        const id = candidato ? candidato[User.id] : representante[Representante.idRepresentante]

        const fechaCreacion = representante && fechaFormatter(representante[Representante.fechaCreacion])
        const autor = representante && representante[Representante.autor]
        const autorUsername = autor && autor[User.nombreCompleto]

        const candidatoUserName = candidato ? candidato[User.nombreCompleto] : representante[Representante.representante][User.nombreCompleto]
        //const nombre = candidato[User.nombreCompleto]

        const zona = candidato ? candidato[User.zona] : representante[Representante.representante][User.zona]
        
        function goToRepresentante(){
            const url = getUrlFromRoute(sopUrls.usuario, {[User.id]: id})
            pushUrl(url)
        }
        
        function handleClick(){
            if (representanteList.selected === id) {
                representanteList.select()
            } else {
                representanteList.select(id)
            }
        }

        function getZonasRepresentante(zonas){
            const result = []
            for (let zona of zonas.split(',')){
                if (zona === '') continue
                result.push(
                    <MicroText key={zona} className={clsx(
                        candidatoProp.hasZona(zona)  && cls.zonaRepresentada,
                        zona === representanteList.zonaObra && cls.zonaObra
                    )}>
                        {zona}
                    </MicroText>
                )
            }
            return result
        }

        function getExtraInfo(){
            if (!selected) {
                return getZonasRepresentante(zona)
                
            } else {
                if (cuentas === undefined) {
                    return <MicroTextLine singleLine>Ningún solicitante en zona</MicroTextLine>
                } else {
                    
                    const result = []
                    if (obra) {
                        result.push(
                            <MicroTextLine key="obra" singleLine>
                                <span className={clsx(
                                        cls.zonaObra,
                                        candidatoProp.hasZona(zona)  && cls.zonaRepresentada,
                                    )}
                                >{representanteList.zonaObra}</span> : Obra
                            </MicroTextLine>
                        )
                    }
                    
                    for (let [idCuenta, cuenta] of cuentas) {
                        const cuentaNombre = cuenta.cuenta[Cuenta.cuenta]
                        result.push(
                            <MicroTextLine key={cuenta.cuenta[Cuenta.idCuenta]} singleLine>
                                {getZonasCuenta(cuenta)}: {cuentaNombre}
                            </MicroTextLine>
                        )
                    }
                    return result
                }
            }
        }
        
        const extraInfo = getExtraInfo()

        
        function getZonasCuenta(/* CandidatoCuenta */ cuenta){
            const added = new Set()
            let zonas = []
            for (let zona of cuenta.solicitudesZonas.values()) {
                if (added.has(zona)) continue
                zonas.push(
                    <span className={clsx(
                        candidatoProp.hasZona(zona) && cls.zonaRepresentada
                    )}>
                        {zona}
                    </span>)
                added.add(zona)
            }
            return zonas
        }
        
        return (
            <ListItem classes={{container: cls.listItem}}
                      dense button selected={selected} onClick={handleClick}
                {...listItemProps}
            >
                <ListItemIcon className={cls.listItemIcon}>
                    <HintIconButton Icon={sopIcons.Representante}
                                    title="Abrir usuario" onClick={goToRepresentante} />
                </ListItemIcon>
                <ListItemText
                    primary={<>
                        <Text>{candidatoUserName}</Text>
                        {!autorUsername ? null : <MicroText>{autorUsername}</MicroText>}
                        {!fechaCreacion ? null : <MicroText>{fechaCreacion}</MicroText>}
                    </>}
                    primaryTypographyProps={{
                        className: representante ? cls.representanteText : cls.candidatoText,
                    }}
                    secondary={extraInfo}
                />
                <ListItemSecondaryAction className={selected ? undefined : cls.listItemSecondaryAction }>
                    {candidato ? null : <HintIcon Icon={icons.Warning} 
                                                  title="Representante fuera de zona" color="error"/>}
                    {!userAuth.isAllowed(Permiso.modificarRepresentantesObra) ? null :
                        (representante
                            ? <HintIconButton title="Quitar representante" Icon={icons.Delete} onClick={onDelete} />
                            : <HintIconButton title="Añadir representante" Icon={icons.Add} onClick={onAdd}/>
                        )
                    }
                </ListItemSecondaryAction>
            </ListItem
                >
        )
    })







const useStyles = makeStyles(() => ({
    list: {
        flex: 1,
        overflow: 'auto',
        width: '100%',
        marginTop: 16,
    },
    icon: {
        marginRight: 10,
    },
}))


export const RepresentanteList = observer(
/**
 * @param {RepresentanteListState} representanteList
 * @returns {JSX.Element}
 * @constructor
 */
({representanteList, direction}) => {
    const cls = useStyles()

    const [alert, withAlert] = useAlert()
    
    useEffect(() => {
        representanteList.onMount()
    }, [representanteList])
    
    const candidatos = representanteList.candidatosVisibles
    
    const searchMode = representanteList.searchMode
    
    return (
        <>
            <Frame direction={getTransposedDirection(direction)}>
                <Bar direction={direction}>
                    {!userAuth.isAllowed(Permiso.modificarRepresentantesObra) ? null :
                        <>
                            <HintIconButton
                                Icon={searchMode ? icons.Show : icons.Hide}
                                title={`Representantes cercanos: ${searchMode? "visibles" : "ocultos"}`}
                                color={searchMode ? sopColors.enabled : sopColors.disabled}
                                onClick={action(representanteList.toggleSearchMode)}
                            />
                            <HintIconButton
                                Icon={icons.Delete}
                                disabled={!representanteList.hasNoZoneRepresentantes}
                                title="Quitar representantes fuera de zona"
                                onClick={() => withAlert(
                                    () => representanteList.deleteNoZoneRepresentantes(),
                                    `Fuera de zona`,
                                    'Quitar',
                                    `¿Quitar ${representanteList.noZoneRepresentantesCount} representante(s) fuera de zona?`,
                                )}
                            />
                        </>
                    }
                </Bar>
                <List className={cls.list} disablePadding={true}>
                    {candidatos.length === 0
                        ? <ListItem><ListItemText>{searchMode
                            ? "No se han encontrado representantes en zona"
                            : "Ningún representante asignado"
                        }</ListItemText></ListItem>
                        
                        : candidatos.map((/* Candidato */ candidato, index) => {
                            const idOr = candidato.representante && candidato.representante[Representante.idOr]
                            const idRepresentante = candidato.candidato
                                ? candidato.candidato[User.id] : candidato.representante[Representante.idRepresentante]
                            return (
                                <Entry
                                    key={idRepresentante}
                                    selected={representanteList.selected === idRepresentante}
                                    representanteList={representanteList}
                                    candidato={candidato}
                                    onDelete={
                                        () => withAlert(
                                            () => representanteList.delete(idOr),
                                            `Representante ${candidato.representante[Representante.representante][User.nombreCompleto]}`,
                                            'Quitar',
                                            '¿Quitar representante?',
                                        )
                                    }
                                    onAdd={
                                        () => withAlert(
                                            () => representanteList.add(idRepresentante),
                                            `Representante ${candidato.candidato[User.nombreCompleto]}`,
                                            'Añadir',
                                            '¿Añadir representante?',
                                        )
                                    }
                                />
                            )
                    })}
                </List>
            </Frame>
            {alert}
        </>
    )
})
