import {Loader} from 'google-maps'

export function getGeocode(maps){

    const geocoder = new maps.Geocoder()

    return function(request){
        return new Promise((resolve, reject) => {
            geocoder.geocode(request, (results, status) => {
                if (status !== 'OK') {
                    reject(`Maps geocoder error: ${status}`)
                } else {
                    resolve(results)
                }
            })
        })
    }
}


export function getElevationForLocations(maps){
    const elevationService = new maps.ElevationService()
    
    return function(request){
        return new Promise((resolve, reject) => {
            elevationService.getElevationForLocations(request, (results, status) => {
                if (status !== 'OK') {
                    reject(`Maps elevation service error: ${status}`)
                } else {
                    resolve(results)
                }
            })
        })
    }
}


let _loader

export function getMapsLoader(...options){
    if (!_loader){
        _loader = new Loader(...options)        
    }
    return _loader
}

