import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {getGraphqlOrderCalculator} from 'sopix/db/graphql-utils'
import {GraphqlDataSource} from 'sopix/boundless-db/graphqlDataSource'
import {GraphqlFiltrator} from 'sopix/filtrator/graphqlFiltrator'

/**
 * @param {DbTable} table
 * @param graphqlFilterCalculator
 */
export function graphqlFiltratorBuilder(world, table, fieldDescriptors, 
    {graphqlFilterCalculator, defaultFilter={}, defaultOrder=[], graphqlId, empty, prefetchSize,
    disposeSize} = {}) {

    const graphqlEndPoint = new TableGraphql(table,
        {graphqlId: graphqlId || table.graphqlId})

    const graphqlOrderCalculator = getGraphqlOrderCalculator(fieldDescriptors)

    const graphqlSource = new GraphqlDataSource(world, graphqlEndPoint, fieldDescriptors,
        graphqlFilterCalculator(defaultFilter), graphqlOrderCalculator(defaultOrder), 
        {empty, prefetchSize, disposeSize}
    )

    return new GraphqlFiltrator(world, graphqlSource, fieldDescriptors, {
        graphqlFilterCalculator, defaultFilter, defaultOrder,
    })
}



/**
 * @param {GraphqlEndPoint} graphqlEndPoint
 * @param graphqlFilterCalculator
 */
export function graphqlFiltratorBuilder2(world, graphqlEndPoint, fieldDescriptors,
                                        {graphqlFilterCalculator, defaultFilter={}, defaultOrder=[], graphqlId, empty, prefetchSize,
                                            disposeSize} = {}) {

    const graphqlOrderCalculator = getGraphqlOrderCalculator(fieldDescriptors)

    const graphqlSource = new GraphqlDataSource(world, graphqlEndPoint, fieldDescriptors,
        graphqlFilterCalculator(defaultFilter), graphqlOrderCalculator(defaultOrder),
        {empty, prefetchSize, disposeSize}
    )

    return new GraphqlFiltrator(world, graphqlSource, fieldDescriptors, {
        graphqlFilterCalculator, defaultFilter, defaultOrder,
    })
}
