 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UploadIds} from './Upload'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbText} from 'sopix/db/dbField'

export const UploadTable = new DbTable(
    Tables.Upload,
    'Uploads',
    graphqlUrl,
    'Upload',
    [UploadIds.idUpload],
    [UploadIds.idUpload, UploadIds.path],
    [],
    [],
    ['verDocumentos'],
    ['modificarDocumentos'],
    undefined,
    undefined,
    [],
    [],
)


export const UploadFld = Object.freeze({
    idUpload : new DbIntId(Tables.Upload, UploadIds.idUpload, 'Id upload'),
    path : new DbText(Tables.Upload, UploadIds.path, 'Path', 255),
})

registerTableFields(Tables.Upload, UploadFld) 
