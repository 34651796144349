 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const MesIds = Object.freeze({
    anualMeses: Symbol('anualMeses'),
    idMes: Symbol('idMes'),
    mes: Symbol('mes'),
    mesCorto: Symbol('mesCorto'),
})

export const Mes = Object.freeze({
    anualMeses: 'anualMeses',
    idMes: 'idMes',
    mes: 'mes',
    mesCorto: 'mesCorto',
})
