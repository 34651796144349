import React from 'react'
import {getPageContainer} from 'sopix/piece/PieceFrame'
import {sopPages, sopUrls} from 'sop/sop-pages'
import {PagePiece} from 'sopix/pieces/pagePiece'
import {Bar} from 'sopix/formComps/Bar'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {icons} from 'sopix/icon/icons'
import {VerticalSplit} from 'sopix/layout/VerticalSplit'
import {Frame} from 'sopix/formComps/Frame'
import {CuentaSearch, CuentaSearchPiece} from 'sop/componentes/cuenta/CuentaSearch'
import {GraphqlTable} from 'sopix/piece-components/GraphqlTable'
import {CuentaListPiece} from 'sop/componentes/cuenta/CuentaList'
import {linkPageListSearchFormUrlWithLoad} from 'sopix/piece-linkers/list-form-url'
import {UrlManager} from 'sopix/pieces/urlManager'
import {CuentaForm, CuentaFormPiece} from 'sop/componentes/cuenta/CuentaForm'
import {HorizontalSplit} from 'sopix/layout/HorizontalSplit'
import {ListNavigator} from 'sopix/piece-components/ListNavigator'
import {Separator} from 'sopix/formComps/Separator'
import {Title} from 'sopix/formComps/Title'
import {Push} from 'sopix/formComps/Push'
import {FormControl} from 'sopix/piece-components/FormControl'
import {action, observable} from 'mobx'
import {linkFormListSeparated} from 'sopix/piece-linkers/form-list'
import {DireccionList, DireccionListPiece} from 'sop/componentes/cuenta/DireccionList'
import {ContactoList, ContactoListPiece} from 'sop/componentes/cuenta/ContactoList'
import {boundMethod} from 'autobind-decorator'
import {Cuenta} from 'sop/db/Cuenta'
import {SageCuentaInfo, SageCuentaInfoPiece} from 'sop/componentes/cuenta-sage/SageCuentaInfo'
import {TabBar} from 'sopix/formComps/TabFrame'
import {IconTab} from 'sopix/formComps/IconTab'
import {CuentaObraList, CuentaObraListPiece} from 'sop/componentes/cuenta/CuentaObraList'
import {getAuthSingleton} from 'sopix/session/auth-singletons'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'


/**
 * @enum
 */
export const CuentaPageMode = Object.freeze({
    LIST: Symbol('LIST'),
    FORM: Symbol('FORM'),
})

/**
 * @enum
 */
export const CuentaRightMode = Object.freeze({
    SAGE: 0,
    OBRA: 1,
})


export class CuentaPagePiece extends PagePiece{
    
    @observable
    mode = CuentaPageMode.LIST
    
    @observable
    rightMode = CuentaRightMode.SAGE
    
    constructor(pageInfo) {
        super(pageInfo)
        
        this.search = new CuentaSearchPiece(this.world)
        this.list = new CuentaListPiece(this.world)
        this.list.selection._deselectOnClick = false
        this.form = new CuentaFormPiece(this.world)
        this.urlManager = new UrlManager(this.world, this.form.idField, sopUrls.cuentas, sopUrls.cuenta)

        this.direcciones = new DireccionListPiece(this.world)
        this.contactos = new ContactoListPiece(this.world)
        
        this.obras = new CuentaObraListPiece(this.world)
        
        this.sage = new SageCuentaInfoPiece(this.world)
        
        this.form.onCurrentRowChanging.subscribe(id => {
            this.direcciones.load(id).then()
            this.contactos.load(id).then()
            this.obras.load(id).then()
        })
        
        const syncSage = async data => {
            await this.sage.load(!data ? undefined : data[Cuenta.cifdni])
        }
        this.form.onApply.subscribe(syncSage)
        this.form.onSave.subscribe(syncSage)
        
        this.direcciones.selection.onRowSelected.subscribe(async ({row}) => {
            await this.contactos.filtrator.setFilterDireccion(row)
        })

        linkPageListSearchFormUrlWithLoad(this, this.list, this.search, this.form, this.urlManager)

        linkFormListSeparated(this.form, this.list, this.setMode, CuentaPageMode.FORM, CuentaPageMode.LIST)
        
        this.direcciones.deleter.onDelete.subscribe(this.contactos.dbSource.refresh)
    }

    @action.bound
    setRightMode(rightMode){
        if (this.rightMode !== rightMode) {
            this.rightMode = rightMode
        }
    }
    
    @action.bound
    setMode(mode) {
        if (this.mode !== mode) {
            this.mode = mode
        }
    }
    __setMode = this._action(this.setMode)

    
    @boundMethod
    async createRow(){
        this.setMode(CuentaPageMode.FORM)
        await this.form.create()
    }

    async _enableActions() {
        await super._enableActions()
        await this.form.validate()
    }

}



export const getCuentaPage = getAuthSingleton(sopPages.cuentas.id, () => {
    return new CuentaPagePiece(sopPages.cuentas)
})


export const CuentaPage = getPageContainer(
    /**
     * @param {CuentaPagePiece} page
     */
    ({page}) => {
        
        const asa = page._asyncAction
        const sya = page._action

        return page.mode === CuentaPageMode.LIST
            ? (
                <>
                    <Bar alignEnd >
                        <CuentaSearch search={page.search} extra={<>
                            {!userAuth.isAllowed(Permiso.crearCuentas) ? null :
                                <HintIconButton Icon={sopIcons.Cuenta} badge='+' badgeColor={sopColors.badgeAdd}
                                                large title="Crear nueva cuenta"
                                                onClick={asa(page.createRow)}
                                />
                            }
                            <HintIconButton Icon={icons.Reload}
                                            large title="Actualizar" onClick={asa(page.refresh)}
                            />
                        </>} />
                    </Bar>
                    <GraphqlTable list={page.list} />
                </>
            )
            : (
                <>
                    <VerticalSplit id="CuentaPage" defaultSizes={[50,50]}>
                        <HorizontalSplit id="CuentaLeft" defaultSizes={[40,30,30]} >
                            <Frame>
                                <Bar>
                                    <HintIconButton Icon={icons.Back} title="Volver a la lista"
                                                    onClick={()=>{page.__setMode(CuentaPageMode.LIST)}}
                                                    disabled={page.form.dirty}
                                    />
                                    <ListNavigator list={page.list} /><Separator rightGap />
                                    <Title>{page.form.recordName}</Title>
                                    <Push />
                                    {!userAuth.isAllowed(Permiso.modificarCuentas) ? null :
                                        <>
                                            <Separator/>
                                            <FormControl form={page.form} />
                                        </>
                                    }
                                    <Separator />
                                    <Bar>
                                        <TabBar value={page.rightMode} tabs={[
                                            <IconTab key={CuentaRightMode.SAGE} icon={sopIcons.Cuenta} title="Murano" />,
                                            <IconTab key={CuentaRightMode.OBRA} icon={sopIcons.Obra} title="Obras" />,
                                        ]} onChange={sya(value => page.setRightMode(value))} />
                                    </Bar>
                                </Bar>
                                <CuentaForm form={page.form} />
                            </Frame>
                            <DireccionList list={page.direcciones} />
                            <ContactoList list={page.contactos} />
                        </HorizontalSplit>
                        <Frame>
                            {page.rightMode === CuentaRightMode.SAGE 
                                ? <SageCuentaInfo info={page.sage} />
                                : <CuentaObraList list={page.obras} />
                            }
                        </Frame>
                    </VerticalSplit>
                </>
            )
})
