export const sopColors = {
    badgeAdd: 'secondary',
    badgeCount: 'default',
    enabled: 'primary',
    disabled: 'disabled',
    mainIcon: 'black',
    
    blueLightBackground: '#f9fcfd', 
    
    green: '#73d242',
    greenBackground: '#f0ffe6',
    greenBackLightground: '#fcfff4',
    greenDarkBackground: '#e1eed7',

    redLightBackground: '#fff9f9',
    redBackground: '#ffd6d6',
    redDarkBackground: '#f1ebeb',
    
    yellow: '#F5F400',
    yellowBackground: '#fdfdcd',
    yellowLightBackground: '#ffffe6',
    
    dimmedBackground: 'rgba(255,255,255,.5)',
    dimmedBackgroundStrong: 'rgba(255,255,255,.8)',
    dimmedBackgroundSoft: 'rgba(255,255,255,.2)',
    
    infoAreaBackground: undefined,
}
sopColors.infoAreaBackground = sopColors.greenBackground
