/**
 * @readonly
 * @enum {string}
 */
export const OrderDirection = Object.freeze({
    ASC: 'asc',
    DESC: 'desc',
})

export const OrderCycle = new Map([
    [OrderDirection.ASC, OrderDirection.DESC],
    [OrderDirection.DESC, undefined],
    [undefined, OrderDirection.ASC],
])

export class OrderEntry {

    /**
     * @param {string} fieldName
     * @param {OrderDirection} direction
     */
    constructor(fieldName, direction) {
        this.fieldId = fieldName
        this.direction = direction
    }
}