 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {FileIds} from './File'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbText} from 'sopix/db/dbField'
import {DocumentoIds} from './Documento'

export const FileTable = new DbTable(
    Tables.File,
    'Files',
    graphqlUrl,
    'File',
    [FileIds.idFile],
    [FileIds.idFile, FileIds.idDocumento, FileIds.idAutor, FileIds.fileSize, FileIds.filePath, FileIds.fechaCreacion, FileIds.borrado, FileIds.size],
    [FileIds.documento],
    [],
    ['verDocumentos'],
    ['modificarDocumentos'],
    undefined,
    undefined,
    [],
    [],
)


export const FileFld = Object.freeze({
    documento : new DbObject(Tables.File, FileIds.documento, FileIds.idDocumento, 'Documento'),
    idFile : new DbIntId(Tables.File, FileIds.idFile, 'Id file'),
    idDocumento : new DbObjectId(Tables.File, FileIds.idDocumento, 'Id documento', {foreignTableId: Tables.Documento, foreignKeyFieldId: DocumentoIds.idDocumento}),
    idAutor : new DbInt(Tables.File, FileIds.idAutor, 'Id autor'),
    fileSize : new DbText(Tables.File, FileIds.fileSize, 'File size', 255),
    filePath : new DbText(Tables.File, FileIds.filePath, 'File path', 255),
    fechaCreacion : new DbDateTime(Tables.File, FileIds.fechaCreacion, 'Fecha creacion'),
    borrado : new DbInt(Tables.File, FileIds.borrado, 'Borrado'),
    size : new DbInt(Tables.File, FileIds.size, 'Size'),
})

registerTableFields(Tables.File, FileFld) 
