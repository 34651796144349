 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {TareaEstadoIds} from './TareaEstado'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const TareaEstadoCol = Object.freeze({
    idEstado : new SimpleColumn(TareaEstadoIds.idEstado, 11, 11, Align.RIGHT),
    estado : new SimpleColumn(TareaEstadoIds.estado, 13, 10, Align.LEFT),
    finalizado : new SimpleColumn(TareaEstadoIds.finalizado, 11, 11, Align.RIGHT),
    verbo : new SimpleColumn(TareaEstadoIds.verbo, 30, 12, Align.LEFT),
})

tableColumns[Tables.TareaEstado] = TareaEstadoCol
