import React from 'react'
import {ModalContent} from 'sopix/modal/ModalContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {observer} from 'mobx-react'
import {Link, Typography} from '@material-ui/core'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {Push} from 'sopix/formComps/Push'
import {LoginScreen, PasswordFld} from 'sop/login/loginState'
import {useKeyPressed} from 'sopix/hooks/use-key-pressed'
import {makeStyles} from '@material-ui/core/styles'
import {Separator} from "sopix/formComps/Separator";


export const useFormStyles = makeStyles(theme => ({
    buttonLink: {
        padding: '6px 8px',
    },
}))



export const LoginForm = observer(
/**
 * 
 * @param {LoginState} login
 * @param children
 * @returns {JSX.Element}
 */
({login, children}) => {
    
    const cls = useFormStyles()

    useKeyPressed(key => {
        if (key === 'Enter') {
            login.login()
        }
    })    
    
    return (
        <>
            <ModalContent>
                <Fields>
                    <Field manager={login.fieldManager} name={PasswordFld.USERNAME} label="Usuario"
                           autoFocus={login.userEmpty}
                    />
                    <Field manager={login.fieldManager} name={PasswordFld.PASSWORD} label="Contraseña"
                           type="password" autoFocus={!login.userEmpty}
                    />
                </Fields>
                <br />
            </ModalContent>
            <DialogActions>
                <Link
                    className={cls.buttonLink}
                    component="button"
                    onClick={() => login.setScreen(LoginScreen.RESET)}
                >
                    No sé mi contraseña
                </Link>
                <Push/>
                <Button
                    disabled={!login.loginAllowed}
                    onClick={login.login}
                    color="primary"
                    defaultChecked={true}
                >
                    Iniciar
                </Button>
            </DialogActions>
        </>
    )
})
