 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const ObraIds = Object.freeze({
    solicitudes: Symbol('solicitudes'),
    tareas: Symbol('tareas'),
    notas: Symbol('notas'),
    relacionadasObra: Symbol('relacionadasObra'),
    relacionadasRelacionada: Symbol('relacionadasRelacionada'),
    representantes: Symbol('representantes'),
    avisos: Symbol('avisos'),
    aprobaciones: Symbol('aprobaciones'),
    ejecuciones: Symbol('ejecuciones'),
    ejecucionesCuentas: Symbol('ejecucionesCuentas'),
    documentos: Symbol('documentos'),
    tipologia: Symbol('tipologia'),
    status: Symbol('status'),
    autor: Symbol('autor'),
    modificacion: Symbol('modificacion'),
    idObra: Symbol('idObra'),
    cod: Symbol('cod'),
    nombre: Symbol('nombre'),
    idTipologia: Symbol('idTipologia'),
    m2: Symbol('m2'),
    direccion: Symbol('direccion'),
    cp: Symbol('cp'),
    localidad: Symbol('localidad'),
    provincia: Symbol('provincia'),
    pais: Symbol('pais'),
    idStatus: Symbol('idStatus'),
    fechaAlta: Symbol('fechaAlta'),
    fechaModificacion: Symbol('fechaModificacion'),
    idAutor: Symbol('idAutor'),
    idModificacion: Symbol('idModificacion'),
    lat: Symbol('lat'),
    lng: Symbol('lng'),
    altitud: Symbol('altitud'),
    locked: Symbol('locked'),
    contadorEstudios: Symbol('contadorEstudios'),
    contadorTareas: Symbol('contadorTareas'),
    contadorValoraciones: Symbol('contadorValoraciones'),
    contadorCambioOferta: Symbol('contadorCambioOferta'),
    fechaEjecucion: Symbol('fechaEjecucion'),
    passiveHouse: Symbol('passiveHouse'),
    obraRepresentativa: Symbol('obraRepresentativa'),
    odooCambiado: Symbol('odooCambiado'),
})

export const Obra = Object.freeze({
    solicitudes: 'solicitudes',
    tareas: 'tareas',
    notas: 'notas',
    relacionadasObra: 'relacionadasObra',
    relacionadasRelacionada: 'relacionadasRelacionada',
    representantes: 'representantes',
    avisos: 'avisos',
    aprobaciones: 'aprobaciones',
    ejecuciones: 'ejecuciones',
    ejecucionesCuentas: 'ejecucionesCuentas',
    documentos: 'documentos',
    tipologia: 'tipologia',
    status: 'status',
    autor: 'autor',
    modificacion: 'modificacion',
    idObra: 'idObra',
    cod: 'cod',
    nombre: 'nombre',
    idTipologia: 'idTipologia',
    m2: 'm2',
    direccion: 'direccion',
    cp: 'cp',
    localidad: 'localidad',
    provincia: 'provincia',
    pais: 'pais',
    idStatus: 'idStatus',
    fechaAlta: 'fechaAlta',
    fechaModificacion: 'fechaModificacion',
    idAutor: 'idAutor',
    idModificacion: 'idModificacion',
    lat: 'lat',
    lng: 'lng',
    altitud: 'altitud',
    locked: 'locked',
    contadorEstudios: 'contadorEstudios',
    contadorTareas: 'contadorTareas',
    contadorValoraciones: 'contadorValoraciones',
    contadorCambioOferta: 'contadorCambioOferta',
    fechaEjecucion: 'fechaEjecucion',
    passiveHouse: 'passiveHouse',
    obraRepresentativa: 'obraRepresentativa',
    odooCambiado: 'odooCambiado',
})
