import React from 'react'
import {PersistentSplitPane} from 'sopix/layout/SplitPanePersistent'

export function HorizontalSplit({id, minSize, defaultSizes, children}) {
    return (
        <PersistentSplitPane id={id} split="horizontal" minSize={minSize} defaultSizes={defaultSizes} >
            {children}
        </PersistentSplitPane>
    )
}
