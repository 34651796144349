import React, {forwardRef} from 'react'
import {pushUrl, replaceUrl} from 'sopix/router/router'

export const Link = forwardRef((
    {href, replace=false, onClick, children, ...others},
    ref) => {
    
    const handleClick = (e, ...more) => {
        if (replace) {
            replaceUrl(href)
        } else {
            pushUrl(href)
        }
        e.preventDefault()
        onClick && onClick(e, ...more)
    }
    
    // onClick debe ir después de others
    return <a
        ref={ref}
        href={href}    
        {...others}
        onClick={handleClick}
    >{children}</a>
})