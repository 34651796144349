 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SistemaIds = Object.freeze({
    ejecuciones: Symbol('ejecuciones'),
    tareaList: Symbol('tareaList'),
    idSistema: Symbol('idSistema'),
    sistema: Symbol('sistema'),
})

export const Sistema = Object.freeze({
    ejecuciones: 'ejecuciones',
    tareaList: 'tareaList',
    idSistema: 'idSistema',
    sistema: 'sistema',
})
