import React, {useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import {debounce} from 'lodash-es'
import ContactlessIcon from '@material-ui/icons/Contactless'
import {taskStore} from 'sopix/tasks/taskStore'
import {getLog} from 'sopix/log'

const log = getLog('TaskIndicator')

export const TasksIndicator = observer(({className, tags, timeout=0}) => {
    
    const [indicator, setIndicator] = useState(false)

    const isRunning = tags === undefined ? taskStore.isRunning : taskStore.getFilteredIsRunning(tags)

    //Crear el debouncer sólo una vez
    const [setIndicatorDebounced] = useState(() => {
        log(()=>`Debounce setup`)
        return debounce((value) => {
            setIndicator(value)
            log(()=>`setIndicator(${value})`)
            
        }, timeout)
    })

    if (isRunning) {
        if (!indicator) {
            log(()=>`setIndicator(true)`)
            setIndicator(true)
        }
        
    } else {
        setIndicatorDebounced(false)
    }

    return useMemo(() => {
        log(()=>`RENDER (indicator: ${indicator})`)
        return indicator ? <ContactlessIcon className={className} /> : null
    }, [indicator, className])
})