 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {RepresentanteIds} from './Representante'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, LookupEditor, SimpleEditor} from 'sopix/db/dbEditor'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const RepresentanteEdt = Object.freeze({
    obra : new LookupEditor(RepresentanteIds.obra, Tables.Obra, ObraIds.idObra, 'cod', {sort: 'ID_OBRA_ASC'}),
    representante : new LookupEditor(RepresentanteIds.representante, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    autor : new LookupEditor(RepresentanteIds.autor, Tables.User, UserIds.id, 'nombreCompleto', {sort: 'ID_ASC'}),
    idOr : new SimpleEditor(RepresentanteIds.idOr, 11, 11),
    fechaCreacion : new DateTimeEditor(RepresentanteIds.fechaCreacion, 16, 16),
    fechaCreacionOnlyDate : new DateEditor(RepresentanteIds.fechaCreacion, 11, 11),
    odooCambiado : new SimpleEditor(RepresentanteIds.odooCambiado, 11, 11),
})

tableEditors[Tables.Representante] = RepresentanteEdt
