 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {EjecucionIds} from './Ejecucion'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbFloat, DbInt, DbIntId, DbObject, DbObjectId, DbObjectList} from 'sopix/db/dbField'
import {ObraIds} from './Obra'
import {TareaIds} from './Tarea'
import {SistemaIds} from './Sistema'
import {ProcedenciaIds} from './Procedencia'

export const EjecucionTable = new DbTable(
    Tables.Ejecucion,
    'Ejecuciones',
    graphqlUrl,
    'Ejecucion',
    [EjecucionIds.idEjecucion],
    [EjecucionIds.idEjecucion, EjecucionIds.idObra, EjecucionIds.idTarea, EjecucionIds.idSistema, EjecucionIds.idProcedencia, EjecucionIds.m2Calculados, EjecucionIds.m2Efectivos, EjecucionIds.m2Totales, EjecucionIds.competencia, EjecucionIds.idAutor, EjecucionIds.fechaCreacion, EjecucionIds.odooCambiado],
    [EjecucionIds.obra, EjecucionIds.tarea, EjecucionIds.sistema, EjecucionIds.procedencia],
    [EjecucionIds.ejecucionesCuentas],
    ['verEjecuciones'],
    ['modificarEjecuciones'],
    undefined,
    undefined,
    [],
    [],
)


export const EjecucionFld = Object.freeze({
    ejecucionesCuentas : new DbObjectList(Tables.Ejecucion, EjecucionIds.ejecucionesCuentas, Tables.EjecucionCuenta),
    obra : new DbObject(Tables.Ejecucion, EjecucionIds.obra, EjecucionIds.idObra, 'Obra'),
    tarea : new DbObject(Tables.Ejecucion, EjecucionIds.tarea, EjecucionIds.idTarea, 'Tarea'),
    sistema : new DbObject(Tables.Ejecucion, EjecucionIds.sistema, EjecucionIds.idSistema, 'Sistema'),
    procedencia : new DbObject(Tables.Ejecucion, EjecucionIds.procedencia, EjecucionIds.idProcedencia, 'Procedencia'),
    idEjecucion : new DbIntId(Tables.Ejecucion, EjecucionIds.idEjecucion, 'Id ejecucion'),
    idObra : new DbObjectId(Tables.Ejecucion, EjecucionIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    idTarea : new DbObjectId(Tables.Ejecucion, EjecucionIds.idTarea, 'Id tarea', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    idSistema : new DbObjectId(Tables.Ejecucion, EjecucionIds.idSistema, 'Id sistema', {foreignTableId: Tables.Sistema, foreignKeyFieldId: SistemaIds.idSistema}),
    idProcedencia : new DbObjectId(Tables.Ejecucion, EjecucionIds.idProcedencia, 'Id procedencia', {foreignTableId: Tables.Procedencia, foreignKeyFieldId: ProcedenciaIds.idProcedencia}),
    m2Calculados : new DbInt(Tables.Ejecucion, EjecucionIds.m2Calculados, 'M2 calculados'),
    m2Efectivos : new DbFloat(Tables.Ejecucion, EjecucionIds.m2Efectivos, 'M2 efectivos'),
    m2Totales : new DbInt(Tables.Ejecucion, EjecucionIds.m2Totales, 'M2 totales'),
    competencia : new DbInt(Tables.Ejecucion, EjecucionIds.competencia, 'Competencia'),
    idAutor : new DbInt(Tables.Ejecucion, EjecucionIds.idAutor, 'Id autor'),
    fechaCreacion : new DbDateTime(Tables.Ejecucion, EjecucionIds.fechaCreacion, 'Fecha creacion'),
    odooCambiado : new DbInt(Tables.Ejecucion, EjecucionIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.Ejecucion, EjecucionFld) 
