import React from 'react'
import {Push} from 'sopix/formComps/Push'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {DateField} from 'sopix/form/editors/DateField'
import {LiquidacionEdt} from 'sop/db/LiquidacionEditors'
import {BoolField} from 'sopix/form/editors/BoolField'
import {observer} from 'mobx-react'
import {SearchPiece} from 'sopix/pieces/searchPiece'
import {ListFetcher} from 'sopix/db-access/listFetcher'
import {User} from 'sop/db/User'
import {UserTable} from 'sop/db/UserTable'
import {RolesUsuario} from 'sop/db-config/row-consts'
import {DropdownField} from 'sopix/form/editors/DropdownField'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {boundMethod} from 'autobind-decorator'
import {Fields} from 'sopix/formComps/Fields'
import {Section} from 'sopix/formComps/Section'
import {TECMES_MES_DESDE, TECMES_MES_HASTA} from 'sop/componentes/stats/tecnico-mes/tecnicoMesFilter'
import {Liqtecmes} from 'sop/db/Liqtecmes'
import {LiqtecmesEdt} from 'sop/db/LiqtecmesEditors'
import {FrameFlag} from 'sopix/piece/piece'
import {FieldManager} from 'sopix/field-manager/fieldManager'
import {CHART_APILAR} from 'sop/componentes/stats/chart-consts'
import {getUsuariosListQueryEntry} from 'sop/db-config/sop-query-lists'


export class TecnicoMesSearchPiece extends SearchPiece{

    _listFetcher = new ListFetcher([
        getUsuariosListQueryEntry(RolesUsuario.TECNICO),
    ])
    
    @boundMethod
    _afterConstructor() {
        super._afterConstructor()
        this.frameFlags = new Set([FrameFlag.MARGIN])
        this.optionsMan = new FieldManager({
            deleteUndefinedFields: true,
            defaultFields: {
                [CHART_APILAR]: true,
            },
        })
        
    }

    async _init() {
        await super._init()
        this.setLists(await this._listFetcher.fetch())
    }

}


export const TecnicoMesSearch = getPieceContainer('search', observer(
    /**
     * @param {TecnicoMesSearchPiece} search
     */
    ({search}) => {

        const H = search.fieldHelper
        const man = search.fieldManager

        function clearResponsablesClicked(){
            man.setValue(Liqtecmes.idTecnico, [])
        }

        function todosLosResponsablesClicked(){
            man.setValue(Liqtecmes.idTecnico, 
                [...search.lists[UserTable.graphqlId].rows.map(tec => tec[User.id])])
        }

        return (
            <>
                <Fields >
                    <DateField {...H.edt(LiquidacionEdt.fechaOnlyDate)} name={TECMES_MES_DESDE} label="desde"
                               disabled={false} format="MM yyyy" views={['year', 'month']}
                    />
                    <DateField {...H.edt(LiquidacionEdt.fechaOnlyDate)} name={TECMES_MES_HASTA} label="hasta"
                               disabled={false} format="MM yyyy" views={['year', 'month']}
                    />
                    <BoolField manager={search.optionsMan} name={CHART_APILAR} label="Apilar" />
    
                    <Push />
                </Fields>
                <Section row noWrap center >
                    <Fields>
                        <DropdownField multiple {...H.edt(LiqtecmesEdt.tecnico)}
                                       disabled={false} width={2000} label="Técnicos"/>
                    </Fields>
                    <HintIconButton Icon={icons.Clear} title="Limpiar campo técnicos"
                                    onClick={clearResponsablesClicked}
                    />
                    <HintIconButton Icon={icons.CheckAll} title="Seleccionar todos los técnicos"
                                    onClick={todosLosResponsablesClicked}
                    />
                </Section>
            </>
        )
}))


/*
    <BoolField manager={search.optionsMan} name={CHART_HORIZONTAL_BAR} label="Horizontal" />
 */