import {ApiEndPoint} from 'sopix/db/apiEndPoint'

const cache = new Map()

export class CachedApiEndPoint extends ApiEndPoint{
    
    async query(query) {
        let result = cache.get(query)
        if (result !== undefined) return result
        result = super.query(query)
        cache.set(query, result)
        return result
    }
}