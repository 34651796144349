 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AproliqmanIds = Object.freeze({
    aprobacion: Symbol('aprobacion'),
    responsable: Symbol('responsable'),
    idAproliqman: Symbol('idAproliqman'),
    idAprobacion: Symbol('idAprobacion'),
    idResponsable: Symbol('idResponsable'),
    m2Efectivos: Symbol('m2Efectivos'),
    justificacion: Symbol('justificacion'),
})

export const Aproliqman = Object.freeze({
    aprobacion: 'aprobacion',
    responsable: 'responsable',
    idAproliqman: 'idAproliqman',
    idAprobacion: 'idAprobacion',
    idResponsable: 'idResponsable',
    m2Efectivos: 'm2Efectivos',
    justificacion: 'justificacion',
})
