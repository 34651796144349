 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const AlmacenIds = Object.freeze({
    cuentas: Symbol('cuentas'),
    idAlmacen: Symbol('idAlmacen'),
    almacen: Symbol('almacen'),
})

export const Almacen = Object.freeze({
    cuentas: 'cuentas',
    idAlmacen: 'idAlmacen',
    almacen: 'almacen',
})
