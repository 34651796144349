 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {AproestaIds} from './Aproesta'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbIntId, DbObject, DbObjectId, DbObjectList, DbText} from 'sopix/db/dbField'
import {AprobacionIds} from './Aprobacion'
import {TareaIds} from './Tarea'
import {TareaEstadoIds} from './TareaEstado'

export const AproestaTable = new DbTable(
    Tables.Aproesta,
    'Aproestas',
    graphqlUrl,
    'Aproesta',
    [AproestaIds.idAproesta],
    [AproestaIds.idAproesta, AproestaIds.idAprobacion, AproestaIds.idTrabajo, AproestaIds.idEstadoOriginal, AproestaIds.idEstado, AproestaIds.justificacion],
    [AproestaIds.aprobacion, AproestaIds.trabajo, AproestaIds.estadoOriginal, AproestaIds.estado],
    [AproestaIds.tareas],
    ['verAprobaciones'],
    ['solicitarCambioEstado'],
    undefined,
    undefined,
    [],
    [],
)


export const AproestaFld = Object.freeze({
    tareas : new DbObjectList(Tables.Aproesta, AproestaIds.tareas, Tables.Tarea),
    aprobacion : new DbObject(Tables.Aproesta, AproestaIds.aprobacion, AproestaIds.idAprobacion, 'Aprobacion'),
    trabajo : new DbObject(Tables.Aproesta, AproestaIds.trabajo, AproestaIds.idTrabajo, 'Trabajo'),
    estadoOriginal : new DbObject(Tables.Aproesta, AproestaIds.estadoOriginal, AproestaIds.idEstadoOriginal, 'Estado original'),
    estado : new DbObject(Tables.Aproesta, AproestaIds.estado, AproestaIds.idEstado, 'Nuevo estado'),
    idAproesta : new DbIntId(Tables.Aproesta, AproestaIds.idAproesta, 'Id aproesta'),
    idAprobacion : new DbObjectId(Tables.Aproesta, AproestaIds.idAprobacion, 'Id aprobacion', {foreignTableId: Tables.Aprobacion, foreignKeyFieldId: AprobacionIds.idAprobacion}),
    idTrabajo : new DbObjectId(Tables.Aproesta, AproestaIds.idTrabajo, 'Id trabajo', {foreignTableId: Tables.Tarea, foreignKeyFieldId: TareaIds.idTarea}),
    idEstadoOriginal : new DbObjectId(Tables.Aproesta, AproestaIds.idEstadoOriginal, 'Id estado original', {foreignTableId: Tables.TareaEstado, foreignKeyFieldId: TareaEstadoIds.idEstado}),
    idEstado : new DbObjectId(Tables.Aproesta, AproestaIds.idEstado, 'Id estado', {foreignTableId: Tables.TareaEstado, foreignKeyFieldId: TareaEstadoIds.idEstado}),
    justificacion : new DbText(Tables.Aproesta, AproestaIds.justificacion, 'Justificacion', 1000),
})

registerTableFields(Tables.Aproesta, AproestaFld) 
