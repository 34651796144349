// ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const Tables = Object.freeze({
    Obra: Symbol('Obra'),
    Provincia: Symbol('Provincia'),
    Solicitud: Symbol('Solicitud'),
    Status: Symbol('Status'),
    Tarea: Symbol('Tarea'),
    TipoTarea: Symbol('TipoTarea'),
    Tipologia: Symbol('Tipologia'),
    User: Symbol('User'),
    Procedencia: Symbol('Procedencia'),
    Cuenta: Symbol('Cuenta'),
    Direccion: Symbol('Direccion'),
    Contacto: Symbol('Contacto'),
    Nota: Symbol('Nota'),
    Relacionada: Symbol('Relacionada'),
    Representante: Symbol('Representante'),
    UserRole: Symbol('UserRole'),
    Pais: Symbol('Pais'),
    SolicitudTarea: Symbol('SolicitudTarea'),
    Almacen: Symbol('Almacen'),
    Permiso: Symbol('Permiso'),
    Contador: Symbol('Contador'),
    Configuracion: Symbol('Configuracion'),
    Sistema: Symbol('Sistema'),
    TareaSistema: Symbol('TareaSistema'),
    TareaEstado: Symbol('TareaEstado'),
    Liquidacion: Symbol('Liquidacion'),
    Aviso: Symbol('Aviso'),
    AvisoTipo: Symbol('AvisoTipo'),
    Aprobacion: Symbol('Aprobacion'),
    AprobacionTipo: Symbol('AprobacionTipo'),
    Aproesta: Symbol('Aproesta'),
    Aproposicion: Symbol('Aproposicion'),
    Aproasignar: Symbol('Aproasignar'),
    Aproliqman: Symbol('Aproliqman'),
    Aproplano: Symbol('Aproplano'),
    PlanoEstado: Symbol('PlanoEstado'),
    Mes: Symbol('Mes'),
    AnualMes: Symbol('AnualMes'),
    Liqtecmes: Symbol('Liqtecmes'),
    Ejecucion: Symbol('Ejecucion'),
    EjecucionCuenta: Symbol('EjecucionCuenta'),
    CuentaTipo: Symbol('CuentaTipo'),
    Documento: Symbol('Documento'),
    File: Symbol('File'),
    Upload: Symbol('Upload'),
    PermisoTransposed: Symbol('PermisoTransposed'),
    Dispositivo: Symbol('Dispositivo'),
    SagePedido: Symbol('SagePedido'),
    SageCliente: Symbol('SageCliente'),
    SageEmpresa: Symbol('SageEmpresa'),
    SageDomicilio: Symbol('SageDomicilio'),
    SageDescuento: Symbol('SageDescuento'),
})
