 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const DireccionIds = Object.freeze({
    solicitudes: Symbol('solicitudes'),
    contactos: Symbol('contactos'),
    cuenta: Symbol('cuenta'),
    autor: Symbol('autor'),
    modificacion: Symbol('modificacion'),
    idDireccion: Symbol('idDireccion'),
    idCuenta: Symbol('idCuenta'),
    cod: Symbol('cod'),
    idEmpresa: Symbol('idEmpresa'),
    direccion: Symbol('direccion'),
    localidad: Symbol('localidad'),
    provincia: Symbol('provincia'),
    pais: Symbol('pais'),
    cp: Symbol('cp'),
    principal: Symbol('principal'),
    fechaAlta: Symbol('fechaAlta'),
    fechaModificacion: Symbol('fechaModificacion'),
    idAutor: Symbol('idAutor'),
    idModificacion: Symbol('idModificacion'),
    odooCambiado: Symbol('odooCambiado'),
})

export const Direccion = Object.freeze({
    solicitudes: 'solicitudes',
    contactos: 'contactos',
    cuenta: 'cuenta',
    autor: 'autor',
    modificacion: 'modificacion',
    idDireccion: 'idDireccion',
    idCuenta: 'idCuenta',
    cod: 'cod',
    idEmpresa: 'idEmpresa',
    direccion: 'direccion',
    localidad: 'localidad',
    provincia: 'provincia',
    pais: 'pais',
    cp: 'cp',
    principal: 'principal',
    fechaAlta: 'fechaAlta',
    fechaModificacion: 'fechaModificacion',
    idAutor: 'idAutor',
    idModificacion: 'idModificacion',
    odooCambiado: 'odooCambiado',
})
