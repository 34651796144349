import {Divider} from '@material-ui/core'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    vertical: {
        alignSelf: 'stretch',
        height: 'auto',
        margin: '0 4px',
    },
    
    horizontal: {
        width: '100%',
    },
    
    transparent: {
        backgroundColor: 'transparent',    
    },
    
    leftGap: {
        marginLeft: 20,    
    },

    rightGap: {
        marginRight: 20,
    },
}))

export const Separator = ({orientation, horizontal, transparent, wide, leftGap, rightGap, ...other}) => {
    const cls = useStyles()
    const vert = orientation !== 'horizontal' && !horizontal
    return (
        <Divider
            {...other}
            className={clsx(
                vert && cls.vertical,
                !vert && cls.horizontal,
                transparent && cls.transparent,
                leftGap && cls.leftGap,
                rightGap && cls.rightGap,
            )}
            orientation={vert ? 'vertical' : orientation}
        />
    )
}