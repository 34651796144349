import React from 'react'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {sopIcons} from 'sop/sop-icons'
import {makeStyles} from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {icons} from 'sopix/icon/icons'
import {observer} from 'mobx-react'
import {getLog} from 'sopix/log'
import {Relacionada} from 'sop/db/Relacionada'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import List from '@material-ui/core/List'
import {Bar} from 'sopix/formComps/Bar'
import {Frame} from 'sopix/formComps/Frame'
import {ListItem} from '@material-ui/core'
import {Obra} from 'sop/db/Obra'
import {getColumnTextFormatter} from 'sopix/db/db-column-utils'
import {RelacionadaCol} from 'sop/db/RelacionadaColumns'
import {User} from 'sop/db/User'
import {MicroText, Text} from 'sopix/textComps/textComps'
import {getUrlFromRoute} from 'sopix/router/routerUtils'
import {sopUrls} from 'sop/sop-pages'
import {sopColors} from 'sop/sop-colors'
import {getTransposedDirection} from 'sopix/formComps/comp-classes'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {ObraPicker} from 'sop/componentes/obra/ObraPicker'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {usePiece} from 'sopix/piece/use-piece'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {Permiso} from 'sop/db/Permiso'
import {userAuth} from 'sopix/session/userAuth'
import {ALERT_CANCEL, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'

const log = getLog('RelacionadaList')


const fechaFormatter = getColumnTextFormatter(RelacionadaCol.fechaCreacion)


const entryStyles = makeStyles(theme => ({
    listItemSecondaryAction: {
        visibility: "hidden",
    },

    listItemIcon: {
        visibility: "hidden",
    },

    listItem: {
        "&:hover $listItemSecondaryAction": {
            visibility: "inherit",
        },
        "&:hover $listItemIcon": {
            visibility: "inherit",
        },
    },
}))


const Entry = observer(
    /**
     * @param {RelacionadaListPiece} list
     */
({selected, list, relacionada, ...listItemProps}) => {

    const cls = entryStyles()

    const fecha = fechaFormatter(relacionada[Relacionada.fechaCreacion])

    const cod = relacionada[Relacionada.relacionada][Obra.cod]
    const descripcion = relacionada[Relacionada.relacionada][Obra.nombre]

    const autor = relacionada[Relacionada.autor]
    const username = autor ? autor[User.username] : ''
    
    async function relacionadaDeleted(){
        const cod = getFieldExtractor(relacionada)(Relacionada.relacionada, Obra.cod)
        const idRelacion = relacionada[Relacionada.idRelacion]

        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            '¿Quitar obra relacionada?',
            `Obra relacionada ${cod}`,
            [
                [ALERT_ELIMINAR, 'Quitar'],
                [ALERT_CANCEL, 'Cancelar'],
            ]
        ))) {
            await list.deleter.__delete(idRelacion)
        }
        
    }

    const relUrl = getUrlFromRoute(sopUrls.obra, {idObra: relacionada[Relacionada.idRelacionada]})
    
    return (
        <ListItem
            classes={{container: cls.listItem}} dense button selected={selected}
            {...listItemProps}
        >
            <ListItemIcon className={cls.listItemIcon}>
                <HintIconButton Icon={sopIcons.Obra} title="Abrir obra" href={relUrl}/>
            </ListItemIcon>
            <ListItemText
                primary={<>
                    <Text>{cod}</Text>
                    <MicroText>{username}</MicroText>
                    <MicroText>{fecha}</MicroText>
                </>}
                secondary={descripcion}
            />
            <ListItemSecondaryAction className={cls.listItemSecondaryAction}>
                <HintIconButton Icon={icons.Delete} title="quitar obra relacionada" 
                                onClick={relacionadaDeleted} />
            </ListItemSecondaryAction>
        </ListItem>
    )
})







const useStyles = makeStyles(() => ({
    list: {
        flex: 1,
        overflow: 'auto',
        width: '100%',
        marginTop: 16,
    },
    icon: {
        marginRight: 10,
    },
}))


export const RelacionadaList = observer(
/**
 * @param {RelacionadaListPiece} list
 */
({list, direction}) => {
    const cls = useStyles()

    usePiece(list)

    return <PieceFrame grow piece={list} renderPiece={()=> {

        const dbSource = list.dbSource
        const selection = list.selection
        const poper = list.poper

        return (
            <>
                <Frame direction={getTransposedDirection(direction)}>
                    <Bar direction={direction}>
                        {!userAuth.isAllowed(Permiso.modificarObrasRelacionadas) ? null :
                            <HintIconButton Icon={sopIcons.Obra} badge='+' badgeColor={sopColors.badgeAdd}
                                            title="Añadir obra relacionada" onClick={poper.__open}
                            />
                        }
                    </Bar>
                    <List className={cls.list} disablePadding={true}>
                        {dbSource.data.length === 0
                            ? <ListItem><ListItemText>Ninguna obra relacionada</ListItemText></ListItem>
                            : dbSource.data.map((relacionada, index) => {
                                const idRelacion = relacionada[Relacionada.idRelacion]
                                return (
                                    <Entry
                                        key={idRelacion}
                                        selected={selection.id === idRelacion}
                                        list={list}
                                        relacionada={relacionada}
                                    />
                                )
                            })}
                    </List>
                </Frame>
                <PoperFrame
                    poper={poper}
                    almostFullScreen
                >
                    <ObraPicker picker={list.picker}/>
                </PoperFrame>
            </>
        )
    }} />
})
