import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {FieldsPiece} from 'sopix/pieces/fieldsPiece'
import {Fields} from 'sopix/formComps/Fields'
import {Aprobacion} from 'sop/db/Aprobacion'
import {Field} from 'sopix/form/editors/Field'
import {AprobacionFld} from 'sop/db/AprobacionTable'


export class RechazoFormPiece extends FieldsPiece{
}


export const RechazoForm = getPieceContainer('form', observer(
    /**
     * @param {RechazoFormPiece} form
     */
    ({form}) => {

        return (
            <Fields>
                <Field autoFocus manager={form.fieldManager} name={Aprobacion.razonRechazo} 
                       label={AprobacionFld.razonRechazo.title} multiline rows={10} />
            </Fields>
        )
}))
