 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SageDescuentoIds} from './SageDescuento'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbFloat, DbIntId, DbText, DbTextId} from 'sopix/db/dbField'

export const SageDescuentoTable = new DbTable(
    Tables.SageDescuento,
    'PO_DtosClientesFamilias',
    graphqlUrl,
    'SageDescuento',
    [SageDescuentoIds.codigoEmpresa],
    [SageDescuentoIds.codigoEmpresa, SageDescuentoIds.codigoCliente, SageDescuentoIds.codigoFamilia, SageDescuentoIds.descripcionFamilia, SageDescuentoIds.pO_DtoA, SageDescuentoIds.pO_DtoB, SageDescuentoIds.pO_DtoR, SageDescuentoIds.descuento1, SageDescuentoIds.pO_Observaciones],
    [],
    [],
    ['verCuentasSage'],
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SageDescuentoFld = Object.freeze({
    codigoEmpresa : new DbIntId(Tables.SageDescuento, SageDescuentoIds.codigoEmpresa, 'Codigo empresa'),
    codigoCliente : new DbTextId(Tables.SageDescuento, SageDescuentoIds.codigoCliente, 'Codigo cliente', 15),
    codigoFamilia : new DbTextId(Tables.SageDescuento, SageDescuentoIds.codigoFamilia, 'Codigo familia', 10),
    descripcionFamilia : new DbText(Tables.SageDescuento, SageDescuentoIds.descripcionFamilia, 'Descripcion familia', 50),
    pO_DtoA : new DbFloat(Tables.SageDescuento, SageDescuentoIds.pO_DtoA, 'Dto A'),
    pO_DtoB : new DbFloat(Tables.SageDescuento, SageDescuentoIds.pO_DtoB, 'Dto B'),
    pO_DtoR : new DbFloat(Tables.SageDescuento, SageDescuentoIds.pO_DtoR, 'Dto R'),
    descuento1 : new DbFloat(Tables.SageDescuento, SageDescuentoIds.descuento1, 'Descuento'),
    pO_Observaciones : new DbText(Tables.SageDescuento, SageDescuentoIds.pO_Observaciones, 'Observaciones', 50),
})

registerTableFields(Tables.SageDescuento, SageDescuentoFld) 
