 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {ProcedenciaIds} from './Procedencia'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableColumns} from 'sopix/db/TableColumns'
import {SimpleColumn} from 'sopix/db/dbColumn'

export const ProcedenciaCol = Object.freeze({
    idProcedencia : new SimpleColumn(ProcedenciaIds.idProcedencia, 11, 11, Align.RIGHT),
    procedencia : new SimpleColumn(ProcedenciaIds.procedencia, 15, 10, Align.LEFT),
})

tableColumns[Tables.Procedencia] = ProcedenciaCol
