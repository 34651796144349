import {boundMethod} from 'autobind-decorator'
import {FormBasePoper} from 'sopix/piece-objects/formBasePoper'

export class FormPoper extends FormBasePoper{

    @boundMethod
    async _doAccept() {
        return await this.form.__save()
    }

    @boundMethod
    async edit(id){
        await this.form.enable()

        await Promise.all([
            this.form.load(id),
            this._delayedOpen(),
        ])
    }
    __edit = this._asyncAction(this.edit)
}