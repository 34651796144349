import {ListState} from 'sopix/react-state/listState'
import {SagePedidoTable} from 'sop/db/SagePedidoTable'
import {SagePedido} from 'sop/db/SagePedido'
import {sopIcons} from 'sop/sop-icons'
import {calcGraphqlOrderMulti} from 'sopix/db/graphql-utils'
import {OrderDirection, OrderEntry} from 'sopix/data/orderEntry'
import {TableGraphql} from 'sopix/db-access/tableGraphql'

const MAX_RECORDS = 500

export class PedidoListState extends ListState{

    _pedidoQL = new TableGraphql(SagePedidoTable)

    constructor(cod, options) {
        super(cod, {
            name: 'Obras pedidos',
            idField: SagePedido.referencia,
            itemIdField: SagePedido.numeroPedido,
            icon: sopIcons.Pedido,
            ...options,
        })
    }

    async __loadData(id) {
        const result = await this._pedidoQL.query(
            [
                ...SagePedidoTable.regularFields,
            ],
            {first: MAX_RECORDS, sort: calcGraphqlOrderMulti([
                new OrderEntry(SagePedido.fechaPedido, OrderDirection.DESC),
            ])},
            {[SagePedido.referencia]: id}
        )
        return result.rows
    }

}