import {DataBlock} from 'sopix/data/dataBlock'
import {getLoggers} from 'sopix/log'

const {debug} = getLoggers('GraphqlCache')

export class GraphqlCache{

    /** @type {GraphqlEndPoint} */
    graphql
    
    /** @type {DataBlock} */
    data
    cursors = []
    
    constructor(
        /** GraphqlEndPoint */ graphql,
        /** DbField[] */ fields,
        graphqlFilter,
        graphqlOrder,
        {maxSize=100, minRequest=10, empty} = {}
    ) {
        this.graphql = graphql
        this.fields = fields
        this.filter = graphqlFilter
        this.order = graphqlOrder
        this.maxSize = maxSize
        this.minRequest = minRequest

        this.data = new DataBlock(0, [], false, !empty)
    }
    
    get index(){
        return this.data.index
    }
    
    async extendBottom(index, len){
        if (index !== this.index + this.data.length) {
            //Sync error puede deberse a un maxSize demasiado pequeño.
            //Incrementar maxSize o corregir esta función para que acepte index desplazados
            throw new Error('GraphqlCache sync error')
        }

        const params = {first: len}
        if (this.data.length > 0) params.after = `"${this.cursors.slice(-1)[0]}"`
        if (this.order.length > 0) params.sort = this.order
        
        const result = await this.graphql.query(this.fields, params, this.filter)
        const extension = new DataBlock(index, result.rows, result.page.hasPreviousPage, result.page.hasNextPage)
        
        this.data.extendBottom(extension)
        this.cursors = [...this.cursors, ...result.cursors]

        const dispose = this.data.length - this.maxSize
        if (dispose > 0) {
            debug(() => `TRIM TOP`)
            this.data.trimTop(dispose)
            this.cursors = this.cursors.slice(dispose)
        }
        
        return extension
    }

    async extendTop(index, len){
        if (index !== this.index) {
            //Sync error puede deberse a un maxSize demasiado pequeño.
            //Incrementar maxSize o corregir esta función para que acepte index desplazados
            throw new Error('GraphqlCache sync error')
        }

        const params = {last: len}
        if (this.data.length > 0) params.before = `"${this.cursors.slice(0, 1)[0]}"`
        if (this.order.length > 0) params.sort = this.order

        const result = await this.graphql.query(this.fields, params, this.filter)
        const extension = new DataBlock(index - result.rows.length, result.rows, result.page.hasPreviousPage, result.page.hasNextPage)

        this.data.extendTop(extension)
        this.cursors = [...result.cursors, ...this.cursors]

        const dispose = this.data.length - this.maxSize
        if (dispose > 0) {
            debug(() => `TRIM BOTTOM`)
            this.data.trimBottom(dispose)
            this.cursors = this.cursors.slice(0, -dispose)
        }

        return extension
    }
    
}