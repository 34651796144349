import React, {Fragment, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {TasksIndicator} from 'sopix/tasks/TasksIndicator'
import {layoutData} from 'sopix/app-frame/layoutData'
import {setLayoutTitle} from 'sopix/app-frame/layout-utils'
import {ErrorList} from 'sopix/errors/ErrorList'
import clsx from 'clsx'
import {Push} from 'sopix/formComps/Push'
import {AccessControl} from 'sopix/session/AccessControl'
import {userAuth} from 'sopix/session/userAuth'
import {observer} from 'mobx-react'
import {AppStage} from 'sopix/app/app-utils'
import {SnackbarDisplay} from 'sopix/snackbar/SnackbarDisplay'
import {snackbarNotifications} from 'sopix/snackbar/snackbarNotifications'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {errorStore} from 'sopix/errors/ErrorStore'
import {AlertPlaceholder, alertStore} from 'sopix/alert/asyncAlert'
import {FilePicker} from 'sopix/file-picker/FilePicker'
import {isFunction} from 'lodash-es'
import {Link} from 'sopix/router/link'
import {cimStyles} from 'sopix/theme/cimStyles'

const drawerWidth = 55


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    drawer: {
        width: drawerWidth,
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'column',
        alignItems: 'center',
        background: ({appStage}) => appStage === AppStage.PRODUCTION 
            ? theme.palette.primary.main 
            : theme.palette.grey['600'],
        color: theme.palette.primary.contrastText,
    },
    icon: {
        color: theme.palette.primary.contrastText,
        
        '& .MuiSvgIcon-root': {
            opacity: theme.palette.action.disabledOpacity,
        },
        '&:hover .MuiSvgIcon-root': {
            opacity: 0.9,
        }
    },
    iconSelected: {
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
        '&:hover .MuiSvgIcon-root': {
            opacity: 'inherit',
        }
    },
    selectedText: {
        ...cimStyles.verticalText,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 14,
        cursor: 'default',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        
        flex: 1,
        
        //Necesario para que no crezca en Y para adaptarse al hijo
        overflowY: 'hidden',
    },

    appNameLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    appName: {
        opacity: 0.8,    
    },

    appVersion: {
        opacity: 0.4,  
        marginTop: -4,
        marginBottom: 4,
        decoration: 'none',
    },
    
    staging: {
        backgroundColor: 'red',
        color: 'white',
        width: '100%',
        textAlign: 'center',
        padding: '4px 0',
    },
    
    devel: {
        backgroundColor: 'yellow',
        color: 'gray',
        width: '100%',
        textAlign: 'center',
        padding: '4px 0',
    }
}))


function splitIndex(index){
    const topIndex = [], bottomIndex = []
    let top = true
    
    for (let entry of index) {
        if (!entry) {
            top = false
            continue
        }
        if (top){
            topIndex.push(entry)
        } else {
            bottomIndex.push(entry)
        }
    }
    return [topIndex, bottomIndex]
}

/**
 *
 * @param {PageInfo} page
 * @param {PageInfo[]} index
 * @param loginComponent
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const Layout = observer(({page, index, loginComponent, renderPage, appStage}) => {
    const cls = useStyles({appStage: appStage})

    useEffect(() => {
        page && !page.path.includes(':') && setLayoutTitle(page.title)
    })

    // Los errores no capturados llegan aquí y se añaden al error store
    useEffect(() => {

        const errorHandler = e => {
            // ResizeObserver loop limit exceeded
            // ResizeObserver loop completed with undelivered notifications.
            if (e.message.startsWith('ResizeObserver loop')){
                return
            }
            errorStore.add(e)
        }
        
        const unhandledRejectionHandler = event => {
            errorStore.add(event.reason)
        }
        
        //Errores sync
        window.addEventListener('error', errorHandler)
        
        //Errores promises
        window.addEventListener('unhandledrejection', unhandledRejectionHandler)
            
        return () => {
            window.removeEventListener('error', errorHandler)
            window.removeEventListener('unhandledrejection', unhandledRejectionHandler)
        }
    }, [])


    const [topIndex, bottomIndex] = splitIndex(index)
    
    function renderEntry({id, path, title, icon, permissions, getBadgeProps}){
        const selected = id === page.rootId
        if (!userAuth.isAllowed(permissions)) return null
        const badgeProps = !getBadgeProps ? {} : getBadgeProps()
        return (
            <Fragment key={id}>
                <HintIconButton
                    className={clsx(
                        cls.icon,
                        {[cls.iconSelected]: selected},
                    )}
                    title={selected ? undefined : title} Icon={icon} href={path}
                    {...badgeProps}
                />
                {!selected ? null : <Typography variant="h6" className={cls.selectedText}>{title}</Typography>}
            </Fragment>
        )
    }
    
    return (
        <div className={cls.root}>
            <div
                className={cls.drawer}
            >
                {topIndex.map(entry => renderEntry(entry))}
                <Push />
                <TasksIndicator />
                {bottomIndex.map(entry => renderEntry(entry))}
                <Link className={cls.appNameLink} href="/">
                    <Typography className={cls.appName} component="h2" variant="h6">{layoutData.shortAppName}</Typography>
                </Link>
                <Typography className={cls.appVersion} variant="caption">{layoutData.appVersion}</Typography>
                {layoutData.stage === AppStage.PRODUCTION ? null : (layoutData.stage === AppStage.STAGING
                        ? <Typography className={cls.staging} variant="caption">staging</Typography>
                        : <Typography className={cls.devel} variant="caption">devel</Typography>
                )}
            </div>
            <main className={cls.content}>
                <ErrorList errorManager={errorStore} />
                <AccessControl permissions={page.permissions} loginComponent={loginComponent}
                    render={() => (
                        <div className={cls.page}>
                            {!isFunction(renderPage) ? renderPage : renderPage()}
                        </div>                     
                )} />
                <SnackbarDisplay notifications={snackbarNotifications} />
            </main>
            <AlertPlaceholder alerts={alertStore} />
            <FilePicker />
        </div>
    )
})
