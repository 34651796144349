import React, {useEffect, useRef} from 'react'
import {Piece} from 'sopix/piece/piece'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {File as DocFile} from 'sop/db/File'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {FileFld, FileTable} from 'sop/db/FileTable'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {Fields} from 'sopix/formComps/Fields'
import {LinearProgress, Typography} from '@material-ui/core'
import {sopColors} from 'sop/sop-colors'
import {makeStyles} from '@material-ui/core/styles'
import {extractFileName, getUniqueFileName} from 'sopix/filename/filename-utils'
import {boundMethod} from 'autobind-decorator'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {fromByteArray} from 'base64-js'
import {action, observable, runInAction} from 'mobx'
import {observer} from 'mobx-react'
import {Upload} from 'sop/db/Upload'
import {Documento} from 'sop/db/Documento'
import {Section} from 'sopix/formComps/Section'
import {HintIconButton} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {RowDeleter} from 'sopix/db-access/rowDeleter'
import {uploadUrl} from 'sop/componentes/documento/documento-consts'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'

const CHUNK_SIZE = 1024 * 64

export class CurrentUpload{
    
    idDocumento
    
    name
    
    /** @type {File} */
    file
    
    @observable
    completedBytes = 0
    
    _cancel = false

    _startUpload = new SimpleMutation(graphqlUrl, 'StartUpload', [Upload.idUpload])
    _uploadChunk = new SimpleMutation(graphqlUrl, 'UploadChunk', ['currentSize'])
    _finishUpload = new SimpleMutation(graphqlUrl, 'FinishUpload', 
        [{'row': FileTable.regularFieldNames}])
    
    constructor(name, file, idDocumento) {
        this.name = name
        this.file = file
        this.idDocumento = idDocumento
    }   
    
    get progress(){
        return !this.file.size ? 100 : 100 * this.completedBytes / this.file.size
    }
    
    async upload(){
        const result = await this._startUpload.query()
        const idUpload = result[Upload.idUpload]
        while(!this._cancel){
            let size = this.file.size - this.completedBytes
            if (!size) {
                break
            }
            if (size > CHUNK_SIZE) {
                size = CHUNK_SIZE
            }
            const start = this.completedBytes
            const end = this.completedBytes + size
            const chunkBlob = this.file.slice(start, end)
            const buffer = await chunkBlob.arrayBuffer()
            const mime64 = fromByteArray(new Uint8Array(buffer))
            const result = await this._uploadChunk.query({
                [Upload.idUpload]: idUpload,
                start: start,
                data: mime64,
            })
            if (result.currentSize !== end) {
                throw new Error('Upload desynced')
            }
            runInAction(()=>{
                this.completedBytes = end
            })
        }
        if (this._cancel) {
            return undefined
        }
        const finish = await this._finishUpload.query({
            [Upload.idUpload]: idUpload,
            size: this.file.size,
            [Documento.idDocumento]: this.idDocumento,
            'name': this.name
        })
        finish.row[DocFile.idFile] = parseInt(finish.row[DocFile.idFile]) 
        return finish.row
    }
    
    cancel(){
        this._cancel = true
    }
}


export class FileListPiece extends Piece{
    idDocumento

    pickFiles
    
    @observable.shallow
    uploadList = new Map()
    
    @observable.shallow
    fileList = new Map()
    
    @observable
    /** @type {CurrentUpload} */
    currentUpload
    
    _fileDeleter = new RowDeleter(this.world, FileTable, 'DeleteFile')
    
    constructor(world, idDocumento) {
        super(world)
        this.idDocumento = idDocumento

        this.dbSource = new SubsetDbSource(world,
            DocFile.idDocumento,
            new TableGraphql(FileTable),
            [
                FileFld.idFile,
                FileFld.filePath,
            ], {
                defaultGraphqlParams: {
                    sort: calcGraphqlOrder(DocFile.fechaCreacion, OrderDirection.ASC),
                },
                defaultGraphqlFilter: {
                    [DocFile.borrado]: 0,
                }
            }
        )
    }

    @action.bound
    _indexDbSource(){
        const list = new Map()
        for (let file of this.dbSource.data){
            list.set(extractFileName(file[DocFile.filePath]), file)
        }
        this.fileList = list
    }
    
    async _init() {
        await this.dbSource.loadSubset(this.idDocumento)
        await this.validate()
        this._indexDbSource()
    }



    @boundMethod
    async _uploadFiles(){
        if (this.currentUpload) {
            return
        }
        while (this.uploadList.size){
            try{
                const [name, file] = [...this.uploadList.entries()][0]
                runInAction(()=>{
                    this.currentUpload = new CurrentUpload(name, file, this.idDocumento)
                    this.uploadList.delete(name)
                })
                const resultFile = await this.currentUpload.upload()
                runInAction(()=>{
                    if (resultFile) {
                        this.fileList.set(this.currentUpload.name, resultFile)
                    }
                    this.currentUpload = undefined
                })
            }catch(e){
                runInAction(()=> {
                    this.currentUpload = undefined
                })
                throw e
            }
        }
    }
    
    
    @action.bound
    async addFiles(files){
        for (let file of files) {
            const name = getUniqueFileName(file.name, [
                ...this.fileList.keys(), 
                ...this.uploadList.keys(), 
                ...(!this.currentUpload ? [] : [this.currentUpload.name])
            ])
            this.uploadList.set(name, file)
        }

        await this._uploadFiles()         
    }
    __addFiles = this._asyncAction(this.addFiles, {withProgress: false})
    
    
    @boundMethod
    async deleteFile(fileName){
        const file = this.fileList.get(fileName)
        await this._fileDeleter.delete(file[DocFile.idFile])
        runInAction(()=>{
            this.fileList.delete(fileName)
        })
    }
    __deleteFile = this._asyncAction(this.deleteFile)
    
    
    @boundMethod
    async deletePendingFile(fileName){
        this.uploadList.delete(fileName)
    }
    __deletePendingFile = this._asyncAction(this.deletePendingFile)
    
    
    @boundMethod
    async deleteCurrentFile(){
        this.currentUpload.cancel()
    }
    __deleteCurrentFile = this._asyncAction(this.deleteCurrentFile)
    
    
}


const filePadding = '0 0 0 24px'

const useStyles = makeStyles(theme => ({
    fileList: {
        background: sopColors.infoAreaBackground,
        minHeight: 64,
    },

    file: {
        padding: filePadding,
        background: sopColors.dimmedBackgroundStrong,
        
        '&:hover $deleteIcon': {
            visibility: 'inherit',
        }
    },

    pendingFile: {
        padding: filePadding,
        background: sopColors.dimmedBackgroundStrong,
        color: sopColors.green,
        '&:hover $deleteIcon': {
            visibility: 'inherit',
        }
    },
    
    currentFile: {
        padding: filePadding,
        background: sopColors.dimmedBackgroundStrong,
        color: theme.palette.secondary.main,
        '&:hover $deleteIcon': {
            visibility: 'inherit',
        }
    },
    
    progress: {
        width: '100%',    
    },
    
    fileInput: {
        display: 'none',
    },
    
    deleteIcon: {
        visibility: 'hidden',
    },
})) 



export const FileList = getPieceContainer('list', observer(
    /**
     * @param {FileListPiece} list
     */
    ({list}) => {

        const cls = useStyles()
        const files = [...list.fileList.entries()]
        const pendingFiles = [...list.uploadList.entries()]
        const current = list.currentUpload
        
        const inputRef = useRef()
        
        useEffect(() => {
            list.pickFiles = () => {
                inputRef.current.click()    
            }
            
            return () => {
                list.pickFiles = undefined
            }
        }, [list, inputRef])
        
        function filesDropped(e){
            e.stopPropagation();
            e.preventDefault();
            list.__addFiles(e.dataTransfer.files).then()
        }

        function filesSelected(e){
            list.__addFiles([...inputRef.current.files]).then()
            inputRef.current.value = null
        }

        const canDelete = userAuth.isAllowed(Permiso.modificarDocumentos)
        
        return (
            <div onDrop={filesDropped} onDragOver={e => {e.preventDefault()}} >
                <Fields className={cls.fileList} >
                    {files.map(([fileName, file]) => {
                        const idFile = file[DocFile.idFile]
                        return (
                            <Typography key={idFile} className={cls.file} 
                                           variant="caption">
                                <a target="_blank" rel="noopener noreferrer" href={`${uploadUrl}${file[DocFile.filePath]}`} >{fileName}</a>
                                {!canDelete ? null :
                                    <HintIconButton className={cls.deleteIcon} fit mini Icon={icons.Delete}
                                                    title="Eliminar" onClick={() => list.__deleteFile(fileName)}/>
                                }
                            </Typography>
                        )
                    })}
                    {!current ? null :
                        <Section>
                            <Typography key={current.name} className={cls.currentFile} variant="caption" >
                                {current.name}
                                {!canDelete ? null :
                                    <HintIconButton className={cls.deleteIcon} fit mini Icon={icons.Delete} 
                                                title="Eliminar" 
                                                onClick={() => list.__deleteCurrentFile()} />
                                }
                            </Typography>
                            <LinearProgress className={cls.progress} variant="determinate" value={current.progress} />
                        </Section>
                    }
                    {pendingFiles.map(([fileName, file]) => {
                        return (
                            <Typography key={file.name} className={cls.pendingFile}
                                        variant="caption">
                                {fileName}
                                {!canDelete ? null :
                                    <HintIconButton className={cls.deleteIcon} fit mini Icon={icons.Delete}
                                                title="Eliminar"
                                                onClick={() => list.__deletePendingFile(fileName)} />
                                }
                            </Typography>
                        )
                    })}
                    <input ref={inputRef} className={cls.fileInput} type="file" multiple="multiple" 
                           onChange={filesSelected} />
                </Fields>
            </div>
        )
}))
