 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {UploadIds} from './Upload'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {SimpleEditor} from 'sopix/db/dbEditor'

export const UploadEdt = Object.freeze({
    idUpload : new SimpleEditor(UploadIds.idUpload, 11, 11),
    path : new SimpleEditor(UploadIds.path, 40, 16),
})

tableEditors[Tables.Upload] = UploadEdt
