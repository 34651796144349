import {sopAppStage} from 'sop/sop'
import {AppStage} from 'sopix/app/app-utils'


function getOdooUrl(){
    if (process.env.NODE_ENV === 'development'){
        return 'http://localhost:8073'
    } else if (sopAppStage === AppStage.PRODUCTION){
        return 'https://www.polytherm.es'
    } else if (sopAppStage === AppStage.STAGING){
        return 'https://test.polytherm.es'
    } else {
        return 'https://test.polytherm.es'
    }
}

export const odooUrl = getOdooUrl()
