/**
 *
 * @param {PagePiece} page
 * @param {UrlManager} urlManager
 * @param {FormPiece} form
 */
export function linkUrlForm(page, urlManager, form){
    page.onMount.subscribe(urlManager._pageMounted)
    form.onDirty.subscribe(urlManager.rowDirty)
    form.onApply.subscribe(urlManager.rowApplied)
    form.onCreate.subscribe(urlManager.rowCreated)
    form.onRowNotFound.subscribe(urlManager.rowNotFound)
    
    page.onEnable.subscribe(urlManager.enable)
    page.onDisable.subscribe(urlManager.disable)
}