export function getFieldExtractor(fields){

    return function(...path){
        if (!fields) return undefined
        let current = fields
        for(let entry of path){
            current = current[entry]
            if (current === undefined) {
                return undefined
            }
        }
        return current
    }
}


export function shallowEqual(obj1, obj2) {
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key => obj1[key] === obj2[key])
}
