 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SolicitudTareaIds} from './SolicitudTarea'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbInt} from 'sopix/db/dbField'

export const SolicitudTareaTable = new DbTable(
    Tables.SolicitudTarea,
    'SolicitudTareas',
    graphqlUrl,
    'SolicitudTarea',
    [SolicitudTareaIds.None],
    [SolicitudTareaIds.idSolicitud, SolicitudTareaIds.idTarea],
    [],
    [],
    undefined,
    undefined,
    undefined,
    undefined,
    [],
    [],
)


export const SolicitudTareaFld = Object.freeze({
    idSolicitud : new DbInt(Tables.SolicitudTarea, SolicitudTareaIds.idSolicitud, 'Id solicitud'),
    idTarea : new DbInt(Tables.SolicitudTarea, SolicitudTareaIds.idTarea, 'Id tarea'),
})

registerTableFields(Tables.SolicitudTarea, SolicitudTareaFld) 
