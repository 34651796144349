 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SolicitudTareaIds = Object.freeze({
    idSolicitud: Symbol('idSolicitud'),
    idTarea: Symbol('idTarea'),
})

export const SolicitudTarea = Object.freeze({
    idSolicitud: 'idSolicitud',
    idTarea: 'idTarea',
})
