import {DisplayStatus} from 'sopix/piece/piece-enums'
import {action, computed, observable, runInAction} from 'mobx'
import {ObserverHub} from 'sopix/utils/observers'
import {boundMethod} from 'autobind-decorator'
import {getLoggers} from 'sopix/log'
import {FuncState} from 'sopix/func-utils/funcState'
import {CancelError} from 'sopix/db/apiEndPoint'
import {WorldObject} from 'sopix/piece/worldObject'

const {debug} = getLoggers('piece') 


export const STATE_ENABLED = Symbol('STATE_ENABLED')
export const DISCARD_STATE = 'DISCARD_STATE'


export const FrameFlag = Object.freeze({
    SHRINK: 'shrink',
    FIELDS: 'fields',
    MARGIN: 'margin',
    MARGIN2: 'margin2',
    ROW: 'row',
    SCROLL: 'scroll',
    BLOCK: 'block',
})

export class Piece extends WorldObject{
    
    _initialized = false

    _mounted = false
    
    onEnable = new ObserverHub()
    onDisable = new ObserverHub()
    onMount = new ObserverHub()
    onUnmount = new ObserverHub()
    onStateRejected = new ObserverHub()
    onInvalidate = new ObserverHub()

    @observable
    _enabled = false

    @observable
    _valid = false
    
    //** @type {Map<Symbol, FuncState>} */
    _pendingStates = new Map()
    
    //Un state locked ejecuta cambios pero los guarda para ejecutar automaticamente cuando se desbloquee
    _lockedStates = new Set()
    
    //Este es el semáforo para no ejecutar a la vez 2 instancias del mismo estado
    _runningStates = new Set()
    
    /** @type {Set} */
    frameFlags


    _afterConstructor() {
        super._afterConstructor()
        this.frameFlags = new Set()
    }

    get valid(){
        return this._valid
    }
    
    get initialized(){
        return this._initialized
    }

    get displayStatus(){
        if (this._progressCount > 0) {
            if (this.valid) {
                return DisplayStatus.PROGRESS_TRANSPARENT
            } else {
                return DisplayStatus.PROGRESS_OPAQUE
            }
        }
        
        if (this.errorManager.hasFailedStates(this)){
            return DisplayStatus.ERROR
        }
        
        if (this._enabled && this._valid) {
            return DisplayStatus.VALID
        } else {
            return DisplayStatus.EMPTY
        }
    }

    @action.bound
    async _invalidateActions(){
        
    }
    
    @action.bound
    async invalidate(){
        if (!this._valid) return
        runInAction(() => {
            this._valid = false 
        }) 
        this.onInvalidate.notify()
        await this._invalidateActions()
    }
    
    @action.bound
    async validate(){
        if (this._valid) return
        this._valid = true
    }
    
    @computed
    get enabled(){
        return this._enabled
    }
    
    get mounted(){
        return this._mounted
    }
    
    _setInitialized(initialized){        

        this._initialized = initialized
    }


    @boundMethod
    async _disableActions(){
    }


    @boundMethod
    async _enableActions(){
        await this._initialize()
    }


    

    @boundMethod
    async _enable(){
        if (this._enabled) return
        
        debug(()=>`ENABLE ${this.constructor.name}`)
        
        runInAction(()=> {
            this._enabled = true
        })
        
        await this._enableActions()
        
        await this._runPendingStates()
        

        this.onEnable.notify()
    }
    enable = this._state(STATE_ENABLED, this._enable, {runDisabled: true})



    @boundMethod
    async _disable(){
        if (!this._enabled) return

        debug(()=>`DISABLE ${this.constructor.name}`)

        await this._disableActions()

        runInAction(()=>{
            this._enabled = false
        })
        
        const failed = this.errorManager.getFailedStates(this) || []
        this.errorManager.clearFailedStates(this)
        
        this._pendingStates = new Map([
            ...failed,
            ...this._pendingStates,
        ])
        
        this.onDisable.notify()
    }
    disable = this._state(STATE_ENABLED, this._disable, {runDisabled: true})
    
    
    @boundMethod
    async _mountActions(){
        await this.enable()
    }
    
    @boundMethod
    async _unmountActions(){
        await this.disable()
    }
    
    
    @boundMethod
    async _init(){
        await this.validate()
    }
    
    @boundMethod
    async _initialize(){
        if (this.initialized) return
        
        this._startProgress()
        try{
            await this._init()
            this._setInitialized(true)
        }finally {
            this._stopProgress()
        }
    }


    
    async _runPendingStates(stateId = undefined){
        const promises = []
        const states = new Map(this._pendingStates)
        this._pendingStates.clear()
        for (/** @type {FuncState} */ let state of states.values()) {
            if (stateId === undefined || state.stateId === stateId) {
                promises.push(this._retry(state))
            }
        }
        await Promise.all(promises)
    }



    @boundMethod
    async _retry(state){
        await this._state(state.stateId, state.func, state.options)(...state.args)
    }
    
    // Modifica un estado de la piece. Es idempotent. Solo importa la última llamada.
    // Decora una función async que se llama sin esperar resultado y que solo se puede ejecutar una a la vez.
    // Si la pieza está deshabilitada no se ejecuta pero se guarda para ejecutar cuando la pieza se active.
    
    // Si llegan más llamadas del mismo estado antes de que termine la primera, se guarda la última para ejecutarse 
    // en cuanto termine la actual.
    
    // Para cancelar anticipadamente (opcional) guardar el resultado de this._pendingStates.get(stateId) al
    // iniciar la ejecución. Si se llama de nuevo y el número a cambiado, se puede lanzar una excepción para cancelar.
    
    @action.bound
    _state(stateId, func, options = {}){
        
        return action(async(...args) => {
            this.errorManager.deleteState(this, stateId)
            
            const {runDisabled} = options
            
            //Update/add pending state
            let state = new FuncState(this, this._retry, stateId, func, args, options)
            
            //Guarda pendiente para luego
            if ((!this.enabled && !runDisabled) || this._lockedStates.has(stateId) || this._runningStates.has(stateId)) {
                this._pendingStates.set(stateId, state)
                await this._stateRejected(stateId)
                this.onStateRejected.notify(stateId)
                return
            }
            
            const getNext = () => {
                this._runningStates.delete(stateId)
                /** @type {FuncState} */
                const next = this._pendingStates.get(stateId)
                if (next) {
                    this._pendingStates.delete(stateId)
                }
                return next
            }
            
            //Running
            while(true){
                try{
                    //debug(() => `STATE RUN`)
                    this._runningStates.add(stateId)
    
                    this._startProgress()
                    try{
                        await state.func(...state.args)
                    }finally {
                        this._stopProgress()
                    }
    
                    state = getNext()           
                    if (!state) {
                        break
                    }
                }catch(error) {
                    const current = state
                    state = getNext()
                    if (!state){
                        if (!(error instanceof CancelError)) {
                            runInAction(()=>{
                                this.errorManager.add(error)
                                if (!error[DISCARD_STATE]) {
                                    this.errorManager.addState(current)
                                }
                            })
                        }
                        await this.invalidate()
                        break
                    }
                }
            }
        })
    }

    //El estado se queda bloqueado. Al desbloquear se aplica la última actualización pendiente. 
    // Ejemplo en notas.
    _lockState(stateId){
        this._lockedStates.add(stateId)    
    }

    async _unlockState(stateId){
        this._lockedStates.delete(stateId)
        await this._runPendingStates(stateId)
    }
    
    
    @boundMethod
    effect(){
        this.__componentMounted().then()
        
        return () => {
            this.__componentUnmounted().then()
        }
    }

    @boundMethod
    async componentMounted(){
        await this._mountActions()
        this._mounted = true
        this.onMount.notify(this)
    }
    __componentMounted = this._asyncAction(this.componentMounted)

    @boundMethod
    async componentUnmounted(){
        this._mounted = false
        await this._unmountActions()
        this.onUnmount.notify(this)
    }
    __componentUnmounted = this._asyncAction(this.componentUnmounted)

    async _stateRejected(stateId) {
    }
}
