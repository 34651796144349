import {tables} from 'sopix/db/dbTable'

/**
 * @type {Object<DbField>}
 */
export const fields = {}

function registerField(fieldId, field){
    fields[fieldId] = field
}

export class DbField{
    
    fieldName
    
    constructor(tableId, fieldId, title = undefined, size = undefined) {
        this.tableId = tableId
        this.fieldId = fieldId
        this._title = title
        this.size = size
        
        this.table = tables[tableId]
        registerField(fieldId, this)
    }

    _init(fieldName){
        this.fieldName = fieldName
    }
    
    get title(){
        return this._title
    }
    
    get physicalField(){
        return this
    }

}

export class DbInt extends DbField{
    
}

export class DbIntId extends DbInt{

}

export class DbFloat extends DbField{
    
}

export class DbBool extends DbField{
    
}


export class DbText extends DbField{
}

export class DbTextId extends DbText{
    
}


export class DbDate extends DbField{

}

export class DbBinary extends DbField{

}


export class DbDateTime extends DbField{
    
}

export class DbObjectId extends DbField{
    constructor(tableId, fieldId, title, {foreignTableId, foreignKeyFieldId}) {
        super(tableId, fieldId, title)
        this.foreignTableId = foreignTableId
        this.foreignKeyFieldId = foreignKeyFieldId
    }
    
    get foreignTable(){
        return tables[this.foreignTableId]
    }
    
    get foreignKeyField(){
        return fields[this.foreignKeyFieldId]
    }
}

export class DbObject extends DbField {

    constructor(tableId, fieldId, idFieldId, title) {
        super(tableId, fieldId, title)
        this.idFieldId = idFieldId
    }

    /**
     * @returns {DbObjectId}
     */
    get idField() {
        return fields[this.idFieldId]
    }

    get physicalField() {
        return this.idField
    }
}

export class DbObjectList extends DbField{

    constructor(tableId, fieldId, foreignTableId) {
        super(tableId, fieldId)
        this.foreignTableId = foreignTableId
    }

    get foreignTable(){
        return tables[this.foreignTableId]
    }
    
    get title(){
        return this.table.title
    }

    get physicalField() {
        return undefined
    }
}
