import {getFieldFromName} from 'sopix/db/db-utils'

export function fieldErrorsAsSimpleMessage(/** {DbTable} */ table, errors){
    
    let messages = ''
    for (let [fieldName, error] of Object.entries(errors)) {
        const field = getFieldFromName(table, fieldName)
        const title = !field ? '?' : field.title
        messages += messages === '' ? '' : '; '
        messages += `${title}: ${error}`
    }
    
    return `Errores: ${messages}`
}