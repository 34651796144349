import React, {forwardRef, useCallback} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        '&:hover .visible-on-hover': {
            visibility: 'inherit',
        }
    },
    hover: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer',
        }
    },
    selected: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        }
    },
    button: {
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shortest,
        }),
    },
}))

export const BlRow = forwardRef(({data, hover=true, selected, index, rowId, style, onClick, className, children}, ref) => {
    
    const cls = useStyles()
    
    const clickReaction = useCallback((event) => {
        onClick && onClick({data: data, index: index, event: event})
    }, [data, index, onClick])
    
    return (
        <div 
            ref={ref} 
            className={clsx(
                cls.row,
                cls.button,
                selected && cls.selected,
                hover && cls.hover,
                className,
            )} 
            style={style} 
            onClick={clickReaction}
        >
            {children}            
        </div>
    )
})
