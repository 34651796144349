import {SolicitudTable} from 'sop/db/SolicitudTable'
import {FormState} from 'sopix/react-state/formState'
import {sopIcons} from 'sop/sop-icons'
import {SOLICITUD_FIELDS} from 'sop/db-config/fieldSets'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {CuentaFld, CuentaTable} from 'sop/db/CuentaTable'
import {DireccionFld, DireccionTable} from 'sop/db/DireccionTable'
import {ContactoFld, ContactoTable} from 'sop/db/ContactoTable'
import {Cuenta} from 'sop/db/Cuenta'
import {Solicitud} from 'sop/db/Solicitud'
import {Direccion} from 'sop/db/Direccion'
import {getLoggers} from 'sopix/log'
import {action, observable, runInAction} from 'mobx'
import {isEmpty} from 'lodash-es'
import {boundMethod} from 'autobind-decorator'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {TipoTarea} from 'sop/db/TipoTarea'
import {OrderDirection} from 'sopix/data/orderEntry'
import {Contacto} from 'sop/db/Contacto'

const {debug} = getLoggers('SolicitanteFormState')

export class SolicitanteFormState extends FormState{

    cuentaQL = new TableGraphql(CuentaTable)
    direccionQL = new TableGraphql(DireccionTable)
    contactoQL = new TableGraphql(ContactoTable)
    
    cuentaOptions
    
    @observable.shallow
    direccionOptions

    @observable.shallow
    contactoOptions
    
    constructor(idSolicitud, {idList} = {}) {
        super(
            idSolicitud,
            SOLICITUD_FIELDS,
            {
                idField: Solicitud.idSolicitud,
                name: 'Solicitante',
                icon: sopIcons.Solicitante,
                table: SolicitudTable,
                saveMutation: 'SaveSolicitud',
                deleteMutation: 'DeleteSolicitud',
            },
        )
        this.idObra = idList
    }


    async __new() {
        return [
            {[Solicitud.idObra]: this.idObra},
            undefined
        ]
    }

    _getTitle(create) {
        if (create) return 'Añadir solicitante'
        else return `Editar solicitante`
    }

    async __init() {
        await super.__init()
        runInAction(()=>{
            this.cuentaOptions = [this.getField(Solicitud.cuenta)]
            this.direccionOptions = [this.getField(Solicitud.direccion)]
            this.contactoOptions = [this.getField(Solicitud.contacto)]
        })
        
        const direcciones = await this._lookupDireccionOptions()
        runInAction(()=>{this.direccionOptions = direcciones})
        
        const contactos = await this._lookupContactoOptions()
        runInAction(()=>{this.contactoOptions = contactos})
    }

    @boundMethod
    async cuentaOptionsSource(search){
        const list = await this.cuentaQL.query(
            [CuentaFld.idCuenta, CuentaFld.cuenta],
            {first: 50},
            {'cuentaIlike':  `%${search}%`}
        )
        return list.rows
    }

    @action
    async _setDirecciones(direcciones){
        
        this.direccionOptions = direcciones

        //Diferir para que el id se cambie DESPUES de las opciones. En otro caso quedará en blanco
        queueMicrotask(() => {
            const idDireccion = direcciones.length === 0 ? undefined : parseInt(direcciones[0][Solicitud.idDireccion])
            debug(() => `set Direcciones: idDireccion = ${idDireccion}`)
            this.setField(Solicitud.idDireccion, idDireccion)
        })
        
        if (direcciones.length > 0) {
            await this.direccionReaction(direcciones[0])
        } else {
            await this.direccionReaction({})
        }
    }
    
    @action
    _setContactos(contactos){
            
        this.contactoOptions = contactos
        
        //Diferir para que el id se cambie DESPUES de las opciones. En otro caso quedará en blanco
        queueMicrotask(() => {
            const idContacto = contactos.length === 0 ? undefined : parseInt(contactos[0][Solicitud.idContacto])
            debug(()=>`set Contactos: idContacto = ${idContacto}`)
            this.setField(Solicitud.idContacto, idContacto)
        })
    }

    @action.bound
    async cuentaReaction(cuenta){
        debug(()=>`direccion Reaction: ${cuenta === undefined ? undefined : cuenta.idCuenta}`)

        this.setField(Solicitud.cuenta, cuenta)

        //Clear
        await this._setDirecciones([])

        //Update
        await this._setDirecciones(await this._lookupDireccionOptions())
    }
    
    @action.bound
    async direccionReaction(direccion){
        debug(()=>`direccion Reaction: ${direccion === undefined ? undefined : direccion.idDireccion}`)
        
        this.setField(Solicitud.direccion, direccion)
        
        //Clear
        this._setContactos([])
        
        //Update
        this._setContactos(await this._lookupContactoOptions())
    }
    

    async _lookupContactoOptions() {
        
        const direccion = this.getField(Solicitud.direccion)
        
        if (isEmpty(direccion)) return []
        
        return (await this.contactoQL.query(
            [ContactoFld.idContacto, ContactoFld.nombre],
            {sort: calcGraphqlOrder(Contacto.principal, OrderDirection.DESC)},
            {[Direccion.idDireccion]: parseInt(direccion[Direccion.idDireccion])}
        )).rows
    }
    
    async _lookupDireccionOptions() {
        
        const cuenta = this.getField(Solicitud.cuenta)
        
        if (isEmpty(cuenta)) return []
        
        return (await this.direccionQL.query(
            [DireccionFld.idDireccion, DireccionFld.direccion, DireccionFld.localidad, DireccionFld.provincia],
            {sort: calcGraphqlOrder(Direccion.principal, OrderDirection.DESC)},
            {[Direccion.idCuenta]: parseInt(cuenta[Cuenta.idCuenta])}
        )).rows
    }
    
    renderDireccion(obj){
        return `${obj[Direccion.provincia]} - ${obj[Direccion.localidad]} - ${obj[Direccion.direccion]}`
    }
    
}