import {AproFormPiece} from 'sop/componentes/aprobacion/aproFormPiece'
import {AproasignarFld, AproasignarTable} from 'sop/db/AproasignarTable'
import {AprobacionFld} from 'sop/db/AprobacionTable'
import {sopIcons} from 'sop/sop-icons'
import {Aproasignar} from 'sop/db/Aproasignar'
import {boundMethod} from 'autobind-decorator'
import {observer} from 'mobx-react'
import React from 'react'
import {PieceFrame} from 'sopix/piece/PieceFrame'
import {Fields} from 'sopix/formComps/Fields'
import {NumberField} from 'sopix/form/editors/NumberField'
import {AproasignarEdt} from 'sop/db/AproasignarEditors'
import {Field} from 'sopix/form/editors/Field'
import {usePiece} from 'sopix/piece/use-piece'


export class AproasignarFormPiece extends AproFormPiece{

    constructor(world) {

        const FIELDS = [
            ...AproasignarTable.regularFields,
            [AproasignarFld.aprobacion, [AprobacionFld.estado]],
        ]

        const TABLE = AproasignarTable
        super(world,
            FIELDS,
            {
                name: 'Repartir valoraciones con exclusiones',
                icon: sopIcons.ValoracionAsignar,
                table: TABLE,
                idField: Aproasignar.idAproasignar,
                saveMutation: 'SaveAproasignar',
            },
        )
    }

    @boundMethod
    _getTitle(empty) {
        return this.name
    }
}




export const AproasignarForm = observer(
    /**
     * @param {AproasignarFormPiece} form
     */
    ({form}) => {

        usePiece(form)

        return (
            <PieceFrame piece={form} renderPiece={()=> {

                const H = form.fieldHelper
                const fld = form.getField

                return(
                    <Fields margin>
                        <NumberField {...H.obj(AproasignarEdt.valoracionesPorTecnico)} />
                        <NumberField {...H.obj(AproasignarEdt.sorteadas)} />
                        <Field {...H.obj(AproasignarEdt.excluidos)} multiline rows={5} width={1000} />
                        <Field {...H.edt(AproasignarEdt.justificacion)} multiline rows={10} width={1000} />
                    </Fields>
                )
            }} />
        )
    })
