import React from 'react'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {FieldsPiece} from 'sopix/pieces/fieldsPiece'
import {Fields} from 'sopix/formComps/Fields'
import {Field} from 'sopix/form/editors/Field'
import {SageClienteEdt} from 'sop/db/SageClienteEditors'
import {FrameFlag} from 'sopix/piece/piece'


export class SageCuentaFormPiece extends FieldsPiece{
    constructor(world) {
        super(world)
        
        this.fieldManager.setReadOnly(true)
    }
}


export const SageCuentaForm = getPieceContainer('form', observer(
    /**
     * @param {SageCuentaFormPiece} form
     */
    ({form}) => {

        const H = form.fieldHelper
        
        return (
            <Fields>
                <Field {...H.edt(SageClienteEdt.razonSocial)} />
                <Field {...H.edt(SageClienteEdt.domicilio)} />
                <Field {...H.edt(SageClienteEdt.municipio)} />
                <Field {...H.edt(SageClienteEdt.provincia)} />
                <Field {...H.edt(SageClienteEdt.codigoPostal)} />
                <Field {...H.edt(SageClienteEdt.nacion)} />
                <Field {...H.edt(SageClienteEdt.cifDni)} />
                <Field {...H.edt(SageClienteEdt.telefono)} />
                <Field {...H.edt(SageClienteEdt.codigoCliente)} />
            </Fields>
        )
}), {[FrameFlag.MARGIN2]: true, [FrameFlag.SHRINK]: true})
