 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const SageClienteIds = Object.freeze({
    codigoEmpresa: Symbol('codigoEmpresa'),
    codigoCliente: Symbol('codigoCliente'),
    cifDni: Symbol('cifDni'),
    fechaAlta: Symbol('fechaAlta'),
    razonSocial: Symbol('razonSocial'),
    nombre: Symbol('nombre'),
    domicilio: Symbol('domicilio'),
    municipio: Symbol('municipio'),
    provincia: Symbol('provincia'),
    actividad: Symbol('actividad'),
    tipoCliente: Symbol('tipoCliente'),
    codigoPostal: Symbol('codigoPostal'),
    nacion: Symbol('nacion'),
    telefono: Symbol('telefono'),
    comentarios_: Symbol('comentarios_'),
})

export const SageCliente = Object.freeze({
    codigoEmpresa: 'codigoEmpresa',
    codigoCliente: 'codigoCliente',
    cifDni: 'cifDni',
    fechaAlta: 'fechaAlta',
    razonSocial: 'razonSocial',
    nombre: 'nombre',
    domicilio: 'domicilio',
    municipio: 'municipio',
    provincia: 'provincia',
    actividad: 'actividad',
    tipoCliente: 'tipoCliente',
    codigoPostal: 'codigoPostal',
    nacion: 'nacion',
    telefono: 'telefono',
    comentarios_: 'comentarios_',
})
