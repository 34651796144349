import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    Boundless,
    DEFAULT_DISPOSE_SIZE,
    DEFAULT_MAX_REQUEST_SIZE,
    DEFAULT_PRECACHED_SIZE,
    DEFAULT_SCROLL_EXTEND_SIZE,
} from 'sopix/boundless/boundless'
import {defaultTo, isFunction} from 'lodash-es'
import {BoundlessRow} from 'sopix/boundless/BoundlessRow'
import {Rows} from 'sopix/boundless/BoundlessRows'
import {Body} from 'sopix/boundless/BoundlessBody'
import {getLoggers} from 'sopix/log'
import {shallowEqual} from 'sopix/data/data-utils'

const {debug} = getLoggers('BoundlessTable')


export function BoundlessTable({
    className, columns, data: _data, keyColumnId, order, dense, hover, boundlessManager,
    onOrderChange, onColumnsChanged, onClick, selected,
    TableContainer, Header, HeaderCell, renderHeaderContent, TableBody, Row, RowCell, renderCellContent, 
    verticalAlign, global, fitContainer
                               }) {
    
    /** @type {[Boundless]} */
    const [boundless] = useState(() => new Boundless())

    //Colums function => static data 
    const columnData = useMemo(() => isFunction(columns) ? columns() : columns, [columns])

    //Columns array => ObraMap
    /** @type {Map<string, TableColumn>} */
    const columnMap = useMemo(() => {
        if (columnData instanceof Map) return columnData
            else return new Map(columnData.map(col => [col.id, col]))
    }, [columnData])

    const containerRef = useRef()
    const bodyRef = useRef()

    boundless.onRender(_data, boundlessManager, containerRef, bodyRef, {
        precachedSize: DEFAULT_PRECACHED_SIZE,
        disposeSize: DEFAULT_DISPOSE_SIZE,
        maxRequestSize: DEFAULT_MAX_REQUEST_SIZE,
        scrollExtendSize: DEFAULT_SCROLL_EXTEND_SIZE,
        defaultRowHeight: dense ? 25 : 50,
    })

    useEffect(() => {
        boundless.didMount()
        return boundless.willUnmount
    }, [boundless])

    
    
    const renderSimpleCellContent = useCallback(({content}) => content, [])

    
    
    const renderBoundlessHeader = useCallback(() => {
        const renderedColumns = []

        for (let id of columnMap.keys()) {
            const col = columnMap.get(id)
            renderedColumns.push(
                <HeaderCell
                    key={col.id}
                    column={col}
                    order={order}
                    onOrderChange={onOrderChange}
                    dense={dense}
                    global={global}
                    {...col.headerProps}
                >
                    {defaultTo(renderHeaderContent, renderSimpleCellContent)({content: col.title, id: col.id, global})}
                </HeaderCell>,
            )
        }

        return <Header>{renderedColumns}</Header>
    }, [columnMap, dense, global, onOrderChange, order, renderHeaderContent, renderSimpleCellContent])


    const renderBoundlessRow = useCallback(({id, index, data, top, visible, rowSelected}) => {
        return (
            <BoundlessRow
                key={id}
                index={index}
                top={boundless.isStatic ? undefined : top}
                visible={boundless.isStatic ? true : visible}
                onUpdateRow={boundless.rows.updateRow}
                {...{RowCell, Row, columnMap, data, dense, verticalAlign,
                    renderCellContent, renderSimpleCellContent, keyColumnId, hover, onClick, rowSelected, global}}
            />
        )
    }, [Row, RowCell, boundless.isStatic, boundless.rows.updateRow, columnMap, dense, global, hover, keyColumnId, onClick, renderCellContent, renderSimpleCellContent, verticalAlign])


    const rowsRef = useRef(new Map())
    
    const renderBoundlessRows = useCallback(() => {
        
        // Memoiza los componentes para no renderizar todas las rows cada vez
        // Ten en cuenta que React "key" optimiza en la reconciliación pero los componentes se renderizan cada vez. 
        const newRows = new Map()
        
        const rows = []
        let i = -1
        let top = boundless.dataTop
        for (let row of boundless.data) {
            i++
            const info = boundless.rows.rows[i]
            const id = row && row[keyColumnId]
            if (!id || newRows.has(id)) {
                continue
                //throw new Error(`Boundless table: id repetida: ${id}`)
            }
            
            const current = rowsRef.current.get(id)
            let [props, comp] = !current? [{}, undefined] : current
            
            const newProps = {
                id: id,
                index: boundless.dataBlock.index + i,
                data: row,
                top: info.height === undefined ? 0 : top,
                visible: info.visible,
                rowSelected: id === selected,
            }
            
            //Esta comparación solo compara el primer nivel de profundidad
            if (!shallowEqual(props, newProps)) {
                comp = renderBoundlessRow(newProps)
            }
            
            newRows.set(id, [newProps, comp])
            
            rows.push(comp)
            
            if (info.height !== undefined) {
                top += info.height
            }
        }
        rowsRef.current = newRows
        
        return rows

    }, [boundless, keyColumnId, renderBoundlessRow, selected])


    const renderBoundlessBody = useCallback(doRender => {

        return (
            <TableBody
                ref={bodyRef}
                style={boundless.height === undefined ? undefined : {height: boundless.height}}
            >
                <Rows
                    render={renderBoundlessRows}
                    register={boundless.registerRows}
                    unregister={boundless.unregisterRows}
                    afterRender={boundless.afterRowsRendered}
                />
            </TableBody>
        )
    }, [boundless, renderBoundlessRows])


    return (
        <TableContainer ref={containerRef} className={className} onScroll={boundless.scrollObserver} 
            fitContainer={fitContainer} >
            {renderBoundlessHeader()}
            <Body
                bodyRef={bodyRef}
                render={renderBoundlessBody}
                register={boundless.registerBody}
                unregister={boundless.unregisterBody}
                afterRender={boundless.afterBodyRendered}
                onWidthChange={boundless.bodyWidthObserver}
            />
        </TableContainer>
    )
}