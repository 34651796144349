 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {EjecucionCuentaIds} from './EjecucionCuenta'
import {Tables} from './Tables'
import {DbTable} from 'sopix/db/dbTable'
import {registerTableFields} from 'sopix/db/TableFields'
import {graphqlUrl} from '../db-config/db'
import {DbDateTime, DbInt, DbIntId, DbObject, DbObjectId, DbText} from 'sopix/db/dbField'
import {EjecucionIds} from './Ejecucion'
import {CuentaIds} from './Cuenta'
import {ObraIds} from './Obra'
import {UserIds} from './User'

export const EjecucionCuentaTable = new DbTable(
    Tables.EjecucionCuenta,
    'EjecucionesCuentas',
    graphqlUrl,
    'EjecucionCuenta',
    [EjecucionCuentaIds.idEjecucionCuenta],
    [EjecucionCuentaIds.idEjecucionCuenta, EjecucionCuentaIds.idEjecucion, EjecucionCuentaIds.idCuenta, EjecucionCuentaIds.idRol, EjecucionCuentaIds.idObra, EjecucionCuentaIds.comentarios, EjecucionCuentaIds.fechaCreacion, EjecucionCuentaIds.idAutor, EjecucionCuentaIds.odooCambiado],
    [EjecucionCuentaIds.ejecucion, EjecucionCuentaIds.cuenta, EjecucionCuentaIds.obra, EjecucionCuentaIds.autor],
    [],
    ['verEjecuciones'],
    ['modificarEjecuciones'],
    undefined,
    undefined,
    [],
    [],
)


export const EjecucionCuentaFld = Object.freeze({
    ejecucion : new DbObject(Tables.EjecucionCuenta, EjecucionCuentaIds.ejecucion, EjecucionCuentaIds.idEjecucion, 'Ejecucion'),
    cuenta : new DbObject(Tables.EjecucionCuenta, EjecucionCuentaIds.cuenta, EjecucionCuentaIds.idCuenta, 'Cuenta'),
    obra : new DbObject(Tables.EjecucionCuenta, EjecucionCuentaIds.obra, EjecucionCuentaIds.idObra, 'Obra'),
    autor : new DbObject(Tables.EjecucionCuenta, EjecucionCuentaIds.autor, EjecucionCuentaIds.idAutor, 'Autor'),
    idEjecucionCuenta : new DbIntId(Tables.EjecucionCuenta, EjecucionCuentaIds.idEjecucionCuenta, 'Id ejecucion cuenta'),
    idEjecucion : new DbObjectId(Tables.EjecucionCuenta, EjecucionCuentaIds.idEjecucion, 'Id ejecucion', {foreignTableId: Tables.Ejecucion, foreignKeyFieldId: EjecucionIds.idEjecucion}),
    idCuenta : new DbObjectId(Tables.EjecucionCuenta, EjecucionCuentaIds.idCuenta, 'Id cuenta', {foreignTableId: Tables.Cuenta, foreignKeyFieldId: CuentaIds.idCuenta}),
    idRol : new DbInt(Tables.EjecucionCuenta, EjecucionCuentaIds.idRol, 'Id rol'),
    idObra : new DbObjectId(Tables.EjecucionCuenta, EjecucionCuentaIds.idObra, 'Id obra', {foreignTableId: Tables.Obra, foreignKeyFieldId: ObraIds.idObra}),
    comentarios : new DbText(Tables.EjecucionCuenta, EjecucionCuentaIds.comentarios, 'Comentarios', 1000),
    fechaCreacion : new DbDateTime(Tables.EjecucionCuenta, EjecucionCuentaIds.fechaCreacion, 'Fecha creacion'),
    idAutor : new DbObjectId(Tables.EjecucionCuenta, EjecucionCuentaIds.idAutor, 'Creado por', {foreignTableId: Tables.User, foreignKeyFieldId: UserIds.id}),
    odooCambiado : new DbInt(Tables.EjecucionCuenta, EjecucionCuentaIds.odooCambiado, 'Odoo cambiado'),
})

registerTableFields(Tables.EjecucionCuenta, EjecucionCuentaFld) 
