 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

import {SagePedidoIds} from './SagePedido'
import {Tables} from './Tables'
import {Align} from 'sopix/db/db-types'
import {tableEditors} from 'sopix/db/TableEditors'
import {DateEditor, DateTimeEditor, SimpleEditor} from 'sopix/db/dbEditor'

export const SagePedidoEdt = Object.freeze({
    codigoEmpresa : new SimpleEditor(SagePedidoIds.codigoEmpresa, 11, 11),
    ejercicioPedido : new SimpleEditor(SagePedidoIds.ejercicioPedido, 11, 11),
    seriePedido : new SimpleEditor(SagePedidoIds.seriePedido, 10, 10),
    numeroPedido : new SimpleEditor(SagePedidoIds.numeroPedido, 11, 11),
    codigoCliente : new SimpleEditor(SagePedidoIds.codigoCliente, 15, 10),
    suPedido : new SimpleEditor(SagePedidoIds.suPedido, 20, 10),
    razonSocial : new SimpleEditor(SagePedidoIds.razonSocial, 40, 16),
    fechaPedido : new DateTimeEditor(SagePedidoIds.fechaPedido, 16, 16),
    fechaPedidoOnlyDate : new DateEditor(SagePedidoIds.fechaPedido, 11, 11),
    referencia : new SimpleEditor(SagePedidoIds.referencia, 10, 10),
})

tableEditors[Tables.SagePedido] = SagePedidoEdt
