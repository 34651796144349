import {action, computed, observable} from 'mobx'
import {getUniqueId} from 'sopix/unique-id'
import {forEach, intersection, size} from 'lodash-es'
import {computedFn} from 'mobx-utils'

export class Task {

    constructor(store, tags = [], id, description) {
        this.store = store
        this.tags = tags
        this.id = id
        this.description = description
    }
}


export class TaskStore {
    
    /** @member {Object.<Task>} tasks */
    @observable
    tasks = {}

    @action
    taskStarted = (tags = [], {id, description= undefined} = {}) => {
        const finalId = id === undefined ? getUniqueId('task_') : id
        this.tasks[finalId] = new Task(this, tags, finalId, description)
        return finalId
    }

    @action
    taskStopped = id => {
        delete this.tasks[id]
    }

    @computed
    get isRunning() {
        return size(this.tasks) > 0
    }
    
    getFilteredIsRunning = computedFn(function(tags){
        let running = false

        // noinspection JSPotentiallyInvalidUsageOfClassThis
        forEach(this.tasks,
            /** @param {Task} task */
            task => {
                if (intersection(tags, task.tags).length > 0) {
                    running = true
                    return false
                }
            })
        
        return running
    })
}


export const taskStore = new TaskStore()
