import React from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {Direccion} from 'sop/db/Direccion'
import {DireccionFld, DireccionTable} from 'sop/db/DireccionTable'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {DireccionCol} from 'sop/db/DireccionColumns'
import {RowSelection} from 'sopix/piece-objects/rowSelection'
import {boundMethod} from 'autobind-decorator'
import {HintIconButton, ICON_BUTTON_SIZE, ICON_SIZE} from 'sopix/formComps/HintIconButton'
import {icons} from 'sopix/icon/icons'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {DireccionForm, DireccionFormPiece} from 'sop/componentes/cuenta/DireccionForm'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {RowDeleter} from 'sopix/db-access/rowDeleter'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {sopIcons} from 'sop/sop-icons'
import {sopColors} from 'sop/sop-colors'
import {HintIcon} from 'sopix/formComps/HintIcon'
import {SimpleMutation} from 'sopix/db-access/simpleMutation'
import {graphqlUrl} from 'sop/db-config/db'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {ALERT_CANCEL, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'


const LEFT_BUTTONS = 'LEFT_BUTTONS'
const RIGHT_BUTTONS = 'RIGHT_BUTTONS'


const RightIcons = observer(

    /**
     * @param {DireccionListPiece} list
     */
    ({list}) => {
        const asa = list._asyncAction

        return !userAuth.isAllowed(Permiso.modificarCuentas) ? null :
            <HintIconButton Icon={sopIcons.Direccion} badge="+" badgeColor={sopColors.badgeAdd}
                               onClick={asa(list.newClicked)} disabled={!list.dbSource.parentId}
                               title="Añadir nueva dirección"
            />
})



export class DireccionPainter extends TablePainter{

    /**
     * @param {DireccionListPiece} list
     */
    _paintHeader({id, content, global: list}) {
        switch (id){
            case RIGHT_BUTTONS:
                return <RightIcons list={list} />
            default:
                return content
        }
    }

    /**
     * @param {DireccionListPiece} list
     */
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global: list}) {
        const asa = list._asyncAction
        switch (id){
            case LEFT_BUTTONS:
                return !userAuth.isAllowed(Permiso.modificarCuentas) ? null : 
                    <HintIconButton Icon={icons.Edit} title="Editar" onClick={() => asa(list.poper.edit)(rowId)} />
            case RIGHT_BUTTONS:
                return !userAuth.isAllowed(Permiso.modificarCuentas) ? null :
                    <HintIconButton Icon={icons.Delete} title="Eliminar" onClick={() => asa(list.deleteClicked)(rowId)} />
            case Direccion.principal:
                return !value 
                    ? <HintIcon color="primary" Icon={icons.StarDisabled} onClick={
                        !userAuth.isAllowed(Permiso.modificarCuentas) ? undefined :
                            () => asa(list.hacerPrincipal)(rowId)
                    } /> 
                    : <HintIcon color="primary" Icon={icons.Star} />  
            default:    
                return super._paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global})
        }
    }

    _fixColumn(col, id, dbCol) {
        switch(id){
            case LEFT_BUTTONS:
            case RIGHT_BUTTONS:
                col.minWidth = col.maxWidth = ICON_BUTTON_SIZE
                col.props['noMargin'] = true
                col.props['onlyHover'] = true
                col.headerProps['noMargin'] = true
                break
            case Direccion.principal:
                col.minWidth = col.maxWidth = ICON_SIZE
                break
            default:
                super._fixColumn(col, id, dbCol)
        }
    }
}


export class DireccionListPiece extends Piece{
    
    idField = Direccion.idDireccion

    setDireccionPrincipal = new SimpleMutation(graphqlUrl, 'SetDireccionPrincipal')
    
    constructor(world) {
        super(world)
        
        const COLUMNS = [
            LEFT_BUTTONS,
            DireccionCol.principal,
            DireccionCol.direccion,     
            DireccionCol.localidad,
            DireccionCol.provincia,
            RIGHT_BUTTONS,
        ]
        
        const FIELDS = [
            DireccionFld.principal,
            DireccionFld.idDireccion,
            DireccionFld.direccion,
            DireccionFld.localidad,
            DireccionFld.provincia,
        ]

        this.painter = new DireccionPainter(world, COLUMNS)
        
        this.dbSource = new SubsetDbSource(world,
            Direccion.idCuenta,
            new TableGraphql(DireccionTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(Direccion.idDireccion, OrderDirection.DESC)},
            })
        
        this.selection = new RowSelection(world, this.dbSource, Direccion.idDireccion)
        
        this.load = this._state(Symbol('LOAD'), this.dbSource.loadSubset)
        
        this.form = new DireccionFormPiece(this.world.cloneWithOwnErrors())
        this.poper = new FormPoper(this.form)
        
        this.deleter = new RowDeleter(this.world, DireccionTable, 'DeleteDireccion')

        this.form.onSave.subscribe(this.dbSource.refresh)

        this.deleter.onDelete.subscribe(async id => {
            await this.dbSource.refresh()
            this.selection.rowDeleted(id)
        })
    }
    
    @boundMethod
    async deleteClicked(id){
        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            `¿Eliminar dirección?`,
            `Se eliminará la dirección y sus contactos.`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
        ],))) {
            await this.deleter.delete(id)
        }
    }
    
    @boundMethod
    async newClicked(){
        await this.poper.createWith({
            [Direccion.idCuenta]: this.dbSource.parentId,
        })
    }
    
    @boundMethod
    async hacerPrincipal(id){
        await this.setDireccionPrincipal.query({id: id})
        await this.dbSource.refresh()
    }
}


export const DireccionList = getPieceContainer('list', observer(
    /**
     * @param {DireccionListPiece} list
     */
    ({list}) => {

        const asa = list._asyncAction 
        const sya = list._action
        
        return (
            <Frame>
                <BlTable fitContainer dense
                     global={list}
                     verticalAlign="center"
                     data={list.dbSource.data}
                     keyColumnId={list.idField}
                     columns={list.painter.boundlessColumns}
                     renderCellContent={sya(list.painter.boundlessPainter)}
                     renderHeaderContent={sya(list.painter.headerPainter)}
                     onClick={sya(list.selection.boundlessClicked)}
                     selected={list.selection.id}
                />
                <PoperFrame poper={list.poper} >
                    <DireccionForm form={list.form} />
                </PoperFrame>
            </Frame>
        )
}), {})
