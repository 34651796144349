import React from 'react'
import {getColumnWidthStyle} from 'sopix/boundless/boundless-utils'
import {BlCell} from 'sopix/boundless-table/BlCell'


/**
 *
 * @param content
 * @param {TableColumn} column
 * @param CellContent
 * @returns {JSX.Element}
 * @constructor
 */
export function BlRowCell({content, classes, index, column, dense, style, className, verticalAlign, data, 
                              global, noMargin, onlyHover, children}) {
    
    return (
        <BlCell
            style={{...getColumnWidthStyle(column), ...style}}
            classes={classes}
            size={dense ? 'small' : undefined}
            align={column.align}
            className={className}
            verticalAlign={verticalAlign}
            noMargin={noMargin}
            onlyHover={onlyHover}
        >
            {children}
        </BlCell>
    )
}
