import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

export const useFieldStyle = makeStyles(() => ({
    field: {
        margin: small => ({small}) => small ? '4px 8px' : '8px',
        flexBasis: ({width, small}) => small ? `${0.8* width}px` : `${width}px`,
        maxWidth: ({width, small}) => small ? `${0.8* 1.5*width}px` : `${1.5*width}px`,
        flexGrow: 1,
    },
    inputSmall: {
        fontSize: '80%',
    },
    labelSmall: {
        fontSize: '95%',
    },
    pickerButtonDisabled: {
        display: 'none',        
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: 48,
    },
}))

export function getInputLabelProps(fieldCls, small){
    return {
        dense: small,
        classes: {
            ...(!small ? undefined : {root: fieldCls.labelSmall}),
        },
    }
}

export function getInputProps(fieldCls, small){
    return {
        margin: small ? 'dense' : undefined,
        classes: {
            ...(!small ? undefined : {input: fieldCls.inputSmall}),
        },
    }
}