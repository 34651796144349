 // ARCHIVO AUTO-GENERADO. LOS CAMBIOS SE DESTRUIRÁN.

export const TareaEstadoIds = Object.freeze({
    tareas: Symbol('tareas'),
    aproestasEstadoOriginal: Symbol('aproestasEstadoOriginal'),
    aproestasEstado: Symbol('aproestasEstado'),
    idEstado: Symbol('idEstado'),
    estado: Symbol('estado'),
    finalizado: Symbol('finalizado'),
    verbo: Symbol('verbo'),
})

export const TareaEstado = Object.freeze({
    tareas: 'tareas',
    aproestasEstadoOriginal: 'aproestasEstadoOriginal',
    aproestasEstado: 'aproestasEstado',
    idEstado: 'idEstado',
    estado: 'estado',
    finalizado: 'finalizado',
    verbo: 'verbo',
})
